"use strict";
import { Select } from "@alifd/next";
import * as React from "react";
import { locale } from "../../utils/locale";
export const CustomSelect = (props) => {
  const [inputValue, setInputValue] = React.useState("");
  const dataSource = [...props.dataSource || []];
  if (inputValue != "") {
    dataSource.unshift({ label: inputValue, value: inputValue });
  }
  return /* @__PURE__ */ React.createElement(
    Select,
    {
      ...props,
      locale: locale().Select,
      showSearch: true,
      onSearch: (value) => {
        if (props.enableInput) {
          setInputValue(value);
        }
        if (props.onSearch) {
          props.onSearch(value);
        }
      },
      dataSource
    },
    props.children
  );
};
