"use strict";
import { getProjectDetail } from "../api/project";
const project = {
  namespace: "project",
  state: {
    projectDetails: {}
  },
  reducers: {
    updateProjectDetail(state, { payload }) {
      return {
        ...state,
        projectDetails: payload
      };
    }
  },
  effects: {
    *getProjectDetails(action, { call, put }) {
      const result = yield call(getProjectDetail, action.payload);
      yield put({ type: "updateProjectDetail", payload: result || {} });
    }
  }
};
export default project;
