"use strict";
import { Icon, Grid } from "@alifd/next";
import { Link } from "dva/router";
import React, { Component } from "react";
import "./index.less";
class Menu extends Component {
  constructor() {
    super(...arguments);
    this.getMenuItem = () => {
      const { Row, Col } = Grid;
      const { activeType, definitionTypes } = this.props;
      const result = (definitionTypes || []).map((item) => {
        const isActive = activeType === item.type ? "active-menu-item" : "";
        return /* @__PURE__ */ React.createElement("li", { key: item.type }, /* @__PURE__ */ React.createElement(Link, { to: `/definitions/${item.type}/config` }, /* @__PURE__ */ React.createElement(Row, { className: `menu-item-wrapper ${isActive}` }, /* @__PURE__ */ React.createElement(Col, { span: "22" }, /* @__PURE__ */ React.createElement("div", { className: "menu-item-description" }, /* @__PURE__ */ React.createElement("span", { className: "padding-left-15" }, " ", item.name))), /* @__PURE__ */ React.createElement(Col, { span: "2" }, /* @__PURE__ */ React.createElement("div", { className: "menu-item-icon" }, /* @__PURE__ */ React.createElement(Icon, { type: "arrow-right" }))))));
      });
      return result;
    };
  }
  render() {
    const menuItem = this.getMenuItem();
    return /* @__PURE__ */ React.createElement("div", { className: "definitions-menu-content" }, /* @__PURE__ */ React.createElement("ul", null, menuItem));
  }
}
export default Menu;
