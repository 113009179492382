"use strict";
import * as React from "react";
const SvgSvc = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "1em", height: "1em", viewBox: "0 0 18.035 17.5", ...props }, /* @__PURE__ */ React.createElement(
  "path",
  {
    d: "M-6.85 4.272a1.12 1.11 0 0 0-.428.109l-5.852 2.796a1.12 1.11 0 0 0-.606.753l-1.444 6.282a1.12 1.11 0 0 0 .152.85 1.12 1.11 0 0 0 .064.089l4.05 5.037a1.12 1.11 0 0 0 .876.417l6.496-.001a1.12 1.11 0 0 0 .875-.417l4.049-5.038a1.12 1.11 0 0 0 .216-.939L.152 7.93a1.12 1.11 0 0 0-.605-.753L-6.307 4.38a1.12 1.11 0 0 0-.542-.109Z",
    style: {
      fill: "currentColor",
      fillOpacity: 1,
      stroke: "none",
      strokeWidth: 0,
      strokeMiterlimit: 4,
      strokeDasharray: "none",
      strokeOpacity: 1
    },
    transform: "matrix(1.01489 0 0 1.01489 15.91 -3.873)"
  }
), /* @__PURE__ */ React.createElement(
  "path",
  {
    d: "M-6.852 3.818a1.181 1.172 0 0 0-.452.115l-6.18 2.951a1.181 1.172 0 0 0-.638.795l-1.524 6.63a1.181 1.172 0 0 0 .16.9 1.181 1.172 0 0 0 .067.093l4.276 5.317a1.181 1.172 0 0 0 .924.44h6.858a1.181 1.172 0 0 0 .923-.44L1.837 15.3a1.181 1.172 0 0 0 .228-.99L.54 7.677a1.181 1.172 0 0 0-.64-.795l-6.178-2.95a1.181 1.172 0 0 0-.573-.115Zm.003.455a1.12 1.11 0 0 1 .542.108l5.853 2.795a1.12 1.11 0 0 1 .606.753l1.446 6.281a1.12 1.11 0 0 1-.216.94l-4.05 5.037a1.12 1.11 0 0 1-.875.417l-6.496.001a1.12 1.11 0 0 1-.875-.417l-4.05-5.037a1.12 1.11 0 0 1-.064-.088 1.12 1.11 0 0 1-.152-.851l1.444-6.281a1.12 1.11 0 0 1 .605-.753l5.853-2.797a1.12 1.11 0 0 1 .429-.108z",
    style: {
      color: "#000",
      fontStyle: "normal",
      fontVariant: "normal",
      fontWeight: 400,
      fontStretch: "normal",
      fontSize: "medium",
      lineHeight: "normal",
      fontFamily: "Sans",
      textIndent: 0,
      textAlign: "start",
      textDecoration: "none",
      textDecorationLine: "none",
      letterSpacing: "normal",
      wordSpacing: "normal",
      textTransform: "none",
      direction: "ltr",
      baselineShift: "baseline",
      textAnchor: "start",
      display: "inline",
      overflow: "visible",
      visibility: "visible",
      fill: "#fff",
      fillOpacity: 1,
      fillRule: "nonzero",
      stroke: "none",
      strokeWidth: 0,
      strokeMiterlimit: 4,
      strokeDasharray: "none",
      marker: "none"
    },
    transform: "matrix(1.01489 0 0 1.01489 15.91 -3.873)"
  }
), /* @__PURE__ */ React.createElement(
  "text",
  {
    y: 16.812,
    x: 9.98,
    style: {
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "10.58333302px",
      lineHeight: "6.61458349px",
      fontFamily: "Sans",
      letterSpacing: 0,
      wordSpacing: 0,
      fill: "#fff",
      fillOpacity: 1,
      stroke: "none",
      strokeWidth: ".26458332px",
      strokeLinecap: "butt",
      strokeLinejoin: "miter",
      strokeOpacity: 1
    },
    xmlSpace: "preserve",
    transform: "translate(-.993 -1.174)"
  },
  /* @__PURE__ */ React.createElement(
    "tspan",
    {
      style: {
        fontStyle: "normal",
        fontVariant: "normal",
        fontWeight: 400,
        fontStretch: "normal",
        fontSize: "2.82222223px",
        fontFamily: "Arial",
        textAlign: "center",
        textAnchor: "middle",
        fill: "#fff",
        fillOpacity: 1,
        strokeWidth: ".26458332px"
      },
      y: 16.812,
      x: 9.98
    },
    "svc"
  )
), /* @__PURE__ */ React.createElement(
  "path",
  {
    style: {
      fill: "#fff",
      fillRule: "evenodd",
      stroke: "none",
      strokeWidth: 0.26458332,
      strokeLinecap: "square",
      strokeMiterlimit: 10
    },
    d: "M4.495 11.26h2.908v2.042H4.495zM8.464 11.26h2.908v2.042H8.464zM12.432 11.26h2.909v2.042h-2.909zM7.614 5.208h4.608V7.25H7.614z",
    transform: "translate(-.9 -1.174)"
  }
), /* @__PURE__ */ React.createElement(
  "path",
  {
    style: {
      fill: "none",
      fillRule: "evenodd",
      stroke: "#fff",
      strokeWidth: 0.52916664,
      strokeLinecap: "butt",
      strokeLinejoin: "round",
      strokeMiterlimit: 10,
      strokeOpacity: 1
    },
    d: "M9.918 7.25v2.005H5.95v2.003",
    transform: "translate(-.9 -1.174)"
  }
), /* @__PURE__ */ React.createElement(
  "path",
  {
    style: {
      fill: "none",
      fillRule: "evenodd",
      stroke: "#fff",
      strokeWidth: 0.52899998,
      strokeLinecap: "butt",
      strokeLinejoin: "round",
      strokeMiterlimit: 10,
      strokeDasharray: "none",
      strokeOpacity: 1
    },
    d: "M9.918 7.25v2.005h3.967v2.003",
    transform: "translate(-.9 -1.174)"
  }
), /* @__PURE__ */ React.createElement(
  "path",
  {
    style: {
      fill: "none",
      fillRule: "evenodd",
      stroke: "#fff",
      strokeWidth: 0.52916664,
      strokeLinecap: "butt",
      strokeLinejoin: "round",
      strokeMiterlimit: 10,
      strokeOpacity: 1
    },
    d: "M9.91 7.251v2.006h.016v2.003",
    transform: "translate(-.9 -1.174)"
  }
));
export default SvgSvc;
