"use strict";
import { Button, Table } from "@alifd/next";
import { Link } from "dva/router";
import React, { Component, Fragment } from "react";
import { getProjectTargetList } from "../../../../api/project";
import Permission from "../../../../components/Permission";
import { Translation } from "../../../../components/Translation";
import { locale } from "../../../../utils/locale";
import "./index.less";
class Targets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      isLoading: false,
      isEditConfig: false
    };
  }
  componentDidMount() {
    const { projectName } = this.props;
    this.loadTargets(projectName);
  }
  shouldComponentUpdate(nextProps) {
    const change = nextProps.projectName !== this.props.projectName;
    if (change) {
      this.loadTargets(nextProps.projectName);
    }
    return true;
  }
  loadTargets(projectName) {
    this.setState({
      isLoading: true
    });
    getProjectTargetList({ projectName }).then((res) => {
      this.setState({
        list: res && res.targets || []
      });
    }).finally(() => {
      this.setState({
        isLoading: false
      });
    });
  }
  render() {
    const columns = [
      {
        key: "name",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Name(Alias)"),
        dataIndex: "name",
        cell: (v, i, record) => {
          const { alias, name } = record;
          return /* @__PURE__ */ React.createElement("span", null, `${name}(${alias || "-"})`);
        }
      },
      {
        key: "cluster",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Cluster/Namespace"),
        dataIndex: "cluster",
        cell: (v, i, record) => {
          const { cluster = { clusterName: "", namespace: "" } } = record;
          return /* @__PURE__ */ React.createElement("span", null, `${cluster.clusterName}/${cluster.namespace}`);
        }
      },
      {
        key: "description",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Description"),
        dataIndex: "description",
        cell: (v) => {
          return /* @__PURE__ */ React.createElement("span", null, v);
        }
      }
    ];
    const { Column } = Table;
    const { list, isLoading } = this.state;
    const { projectName } = this.props;
    return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "summary-targets-wrapper" }, /* @__PURE__ */ React.createElement("section", { className: "card-title-wrapper" }, /* @__PURE__ */ React.createElement("span", { className: "card-title" }, /* @__PURE__ */ React.createElement(Translation, null, "Targets")), /* @__PURE__ */ React.createElement(Permission, { request: { resource: `target:*`, action: "create" }, project: projectName }, /* @__PURE__ */ React.createElement(Button, { className: "card-button-wrapper" }, /* @__PURE__ */ React.createElement(Link, { to: "/targets", className: "color-setting" }, /* @__PURE__ */ React.createElement(Translation, null, "Add"))))), /* @__PURE__ */ React.createElement("section", { className: "card-content-table" }, /* @__PURE__ */ React.createElement(Table, { locale: locale().Table, dataSource: list, hasBorder: true, loading: isLoading }, columns.map((col, key) => /* @__PURE__ */ React.createElement(Column, { ...col, key, align: "left" }))))));
  }
}
export default Targets;
