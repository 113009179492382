"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Loading, Select } from "@alifd/next";
import { connect } from "dva";
import _ from "lodash";
import React, { Component } from "react";
import { getChartRepos } from "../../api/repository";
import i18n from "../../i18n";
import { locale } from "../../utils/locale";
let HelmRepoSelect = class extends Component {
  constructor(props) {
    super(props);
    this.onLoadRepos = (repoType) => {
      const { project } = this.props;
      const defaultRepos = [{ url: "https://charts.bitnami.com/bitnami", type: "helm" }];
      this.setState({ loading: true, repos: repoType === "helm" ? defaultRepos : [] });
      getChartRepos({ project }).then((res) => {
        let repos = [];
        if (res && res.repos) {
          res.repos.map((repo) => {
            if (repoType == "oci" && repo.url.startsWith("oci://")) {
              repos.push(repo);
            }
            if (repoType == "helm" && !repo.url.startsWith("oci://")) {
              repos.push(repo);
            }
          });
        }
        if (repoType == "helm") {
          repos = repos.concat(defaultRepos);
        }
        this.setState({
          repos,
          loading: false
        });
      });
    };
    this.onSearch = (value) => {
      this.setState({ inputRepo: value });
    };
    this.onChange = (value) => {
      const { onChange, dispatch, onChangeSecretRef } = this.props;
      this.state.repos.map((repo) => {
        if (repo.url == value && repo.secretName != "" && dispatch) {
          dispatch({
            type: "uischema/setHelmRepo",
            payload: repo
          });
        }
      });
      onChange(value);
      const secretName = this.findSecretName(value);
      onChangeSecretRef(secretName);
    };
    this.findSecretName = (value) => {
      const { repos = [] } = this.state;
      const findSecretObj = _.find(repos, (item) => {
        return item.url === value;
      });
      return findSecretObj?.secretName || "";
    };
    this.state = {
      loading: false,
      repos: [],
      inputRepo: ""
    };
  }
  componentDidMount() {
    if (!this.props.helm?.repoType || this.props.helm?.repoType == "helm") {
      this.onLoadRepos(this.props.helm?.repoType || "helm");
    }
  }
  componentWillReceiveProps(nextProps) {
    const repoType = nextProps.helm?.repoType;
    if (repoType !== this.props.helm?.repoType && (repoType == "helm" || repoType == "oci")) {
      this.onLoadRepos(repoType);
    }
  }
  convertHelmRepositoryOptions(data) {
    return (data || []).map((item) => {
      let label = item.url;
      if (item.secretName) {
        label = `(${item.secretName}) ${item.url}`;
      }
      return { label, value: item.url };
    });
  }
  render() {
    const { disabled, value } = this.props;
    const { repos, loading, inputRepo } = this.state;
    const dataSource = repos;
    if (inputRepo) {
      dataSource.unshift({ url: inputRepo, type: "helm" });
    }
    const transDataSource = this.convertHelmRepositoryOptions(dataSource);
    return /* @__PURE__ */ React.createElement(Loading, { visible: loading, style: { width: "100%" } }, /* @__PURE__ */ React.createElement(
      Select,
      {
        placeholder: i18n.t("Please select or input your owner helm repo"),
        onChange: this.onChange,
        inputMode: "url",
        disabled,
        showSearch: true,
        onSearch: this.onSearch,
        followTrigger: true,
        value,
        dataSource: transDataSource,
        locale: locale().Select
      }
    ));
  }
};
HelmRepoSelect = __decorateClass([
  connect((store) => {
    return { ...store.uischema };
  })
], HelmRepoSelect);
export default HelmRepoSelect;
