"use strict";
import { connect } from "dva";
import { checkPermission } from "../../utils/permission";
const Permission = (props) => {
  if (!props.userInfo) {
    return null;
  }
  if (!checkPermission(props.request, props.project, props.userInfo)) {
    return null;
  }
  return props.children;
};
export default connect((store) => {
  return { ...store.user };
})(Permission);
