"use strict";
import { Table, Button, Dialog, Message, Tag, Balloon } from "@alifd/next";
import React, { Component, Fragment } from "react";
import { If } from "../../../../components/If";
import Permission from "../../../../components/Permission";
import { Translation } from "../../../../components/Translation";
import { momentDate } from "../../../../utils/common";
import { locale } from "../../../../utils/locale";
import "./index.less";
import { deleteConfig, getProjectConfigs } from "../../../../api/config";
import CreateConfig from "../../../Configs/components/CreateConfigDialog";
import DistributeConfigDialog from "./config-distribute";
import { HiOutlineRefresh } from "react-icons/hi";
class Configs extends Component {
  constructor(props) {
    super(props);
    this.listConfigs = async (projectName) => {
      this.setState({
        isLoading: true
      });
      getProjectConfigs({ projectName }).then((res) => {
        this.setState({
          configs: Array.isArray(res.configs) ? res.configs : []
        });
      }).finally(() => {
        this.setState({
          isLoading: false
        });
      });
    };
    this.onClick = (int) => {
      this.setState({ showConfig: true, config: int });
    };
    this.onDelete = (record) => {
      const { projectName } = this.props;
      Dialog.confirm({
        content: "Are you sure want to delete this config",
        onOk: () => {
          const { name } = record;
          if (name) {
            deleteConfig(name, projectName).then((res) => {
              if (res) {
                Message.success(/* @__PURE__ */ React.createElement(Translation, null, "Config deleted successfully"));
                this.listConfigs(projectName);
              }
            });
          }
        },
        locale: locale().Dialog
      });
    };
    this.onDistribute = (record) => {
      this.setState({ distribution: record });
    };
    this.state = {
      configs: [],
      isLoading: false
    };
  }
  componentDidMount() {
    const { projectName } = this.props;
    this.listConfigs(projectName);
  }
  shouldComponentUpdate(nextProps) {
    const change = nextProps.projectName !== this.props.projectName;
    if (change) {
      this.listConfigs(nextProps.projectName);
    }
    return true;
  }
  render() {
    const { projectName } = this.props;
    const columns = [
      {
        key: "name",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Name"),
        dataIndex: "name",
        cell: (v, i, config2) => {
          const title = `${v}(${config2.alias || "-"})`;
          if (config2.sensitive || config2.shared) {
            return /* @__PURE__ */ React.createElement("span", null, title);
          }
          return /* @__PURE__ */ React.createElement("a", { onClick: () => this.onClick(config2) }, title);
        }
      },
      {
        key: "template",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Template"),
        dataIndex: "template.name"
      },
      {
        key: "targets",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Distribution"),
        dataIndex: "targets",
        cell: (targets) => {
          return /* @__PURE__ */ React.createElement("div", null, targets?.map((t) => {
            const tag = /* @__PURE__ */ React.createElement(
              Tag,
              {
                animation: true,
                color: t.status === "succeeded" ? "green" : t.status === "failed" ? "red" : "yellow"
              },
              t.clusterName,
              "/",
              t.namespace
            );
            return t.message ? /* @__PURE__ */ React.createElement(Balloon, { trigger: tag }, t.message) : tag;
          }));
        }
      },
      {
        key: "description",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Description"),
        dataIndex: "description"
      },
      {
        key: "createTime",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Create Time"),
        dataIndex: "createdTime",
        cell: (v) => {
          return /* @__PURE__ */ React.createElement("span", null, momentDate(v));
        }
      },
      {
        key: "operation",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Actions"),
        dataIndex: "operation",
        cell: (v, i, record) => {
          return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement(If, { condition: !record.shared }, /* @__PURE__ */ React.createElement(
            Permission,
            {
              request: {
                resource: `project:${projectName}/config:${record.name}`,
                action: "delete"
              },
              project: projectName
            },
            /* @__PURE__ */ React.createElement(
              Button,
              {
                text: true,
                size: "medium",
                component: "a",
                onClick: () => {
                  this.onDelete(record);
                }
              },
              /* @__PURE__ */ React.createElement(Translation, null, "Delete")
            )
          )), /* @__PURE__ */ React.createElement(
            Permission,
            {
              request: {
                resource: `project:${projectName}/config:${record.name}`,
                action: "distribute"
              },
              project: projectName
            },
            /* @__PURE__ */ React.createElement(
              Button,
              {
                text: true,
                size: "medium",
                component: "a",
                onClick: () => {
                  this.onDistribute(record);
                }
              },
              /* @__PURE__ */ React.createElement(Translation, null, "Distribute")
            )
          ));
        }
      }
    ];
    const { Column } = Table;
    const { configs, isLoading, showConfig, config, distribution } = this.state;
    return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "config-wrapper" }, /* @__PURE__ */ React.createElement("section", { className: "card-title-wrapper" }, /* @__PURE__ */ React.createElement("span", { className: "card-title" }, /* @__PURE__ */ React.createElement(Translation, null, "Configs")), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
      Button,
      {
        type: "secondary",
        onClick: () => {
          this.listConfigs(projectName);
        },
        className: "card-button-wrapper"
      },
      /* @__PURE__ */ React.createElement(HiOutlineRefresh, null)
    ), /* @__PURE__ */ React.createElement(
      Permission,
      {
        request: { resource: `project:${projectName}/config:*`, action: "create" },
        project: projectName
      },
      /* @__PURE__ */ React.createElement(
        Button,
        {
          onClick: () => {
            this.setState({ showConfig: true });
          },
          type: "secondary",
          className: "card-button-wrapper"
        },
        /* @__PURE__ */ React.createElement(Translation, null, "Add")
      )
    ))), /* @__PURE__ */ React.createElement("section", { className: "card-content-table" }, /* @__PURE__ */ React.createElement(Table, { locale: locale().Table, dataSource: configs, hasBorder: true, loading: isLoading }, columns.map((col, key) => /* @__PURE__ */ React.createElement(Column, { ...col, key, align: "left" }))))), /* @__PURE__ */ React.createElement(If, { condition: showConfig }, /* @__PURE__ */ React.createElement(
      CreateConfig,
      {
        onSuccess: () => {
          this.listConfigs(projectName);
          this.setState({ showConfig: false, config: void 0 });
        },
        onClose: () => {
          this.setState({ showConfig: false, config: void 0 });
        },
        configName: config?.name,
        project: projectName,
        template: config?.template,
        visible: true
      }
    )), /* @__PURE__ */ React.createElement(If, { condition: distribution }, distribution && /* @__PURE__ */ React.createElement(
      DistributeConfigDialog,
      {
        config: distribution,
        onClose: () => {
          this.setState({ distribution: void 0 });
        },
        onSuccess: () => {
          this.listConfigs(projectName);
          this.setState({ distribution: void 0 });
        },
        targets: distribution?.targets,
        projectName
      }
    )));
  }
}
export default Configs;
