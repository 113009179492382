"use strict";
import { Message, Grid, Dialog, Form, Input, Field, Select, Loading } from "@alifd/next";
import React from "react";
import { listNamespaces } from "../../../../api/observation";
import { getCloudServiceProviderList, getProjectList } from "../../../../api/project";
import { createTarget, updateTarget } from "../../../../api/target";
import { Translation } from "../../../../components/Translation";
import Group from "../../../../extends/Group";
import i18n from "../../../../i18n";
import { checkName } from "../../../../utils/common";
import { locale } from "../../../../utils/locale";
import Namespace from "../Namespace";
class TargetDialog extends React.Component {
  constructor(props) {
    super(props);
    this.listProjects = async () => {
      this.setState({ isLoading: true });
      getProjectList({ page: 0, pageSize: 0 }).then((res) => {
        this.setState({
          projects: res.projects || [],
          isLoading: false
        });
      }).catch(() => {
        this.setState({ isLoading: false });
      });
    };
    this.onClose = () => {
      this.props.onClose();
      this.resetField();
    };
    this.onOk = () => {
      this.field.validate((error, values) => {
        if (error) {
          return;
        }
        const { isEdit, liteMode } = this.props;
        const {
          name,
          alias,
          description,
          clusterName,
          runtimeNamespace,
          var_providerName,
          var_region,
          var_zone,
          var_vpcID,
          project
        } = values;
        const params = {
          name,
          alias,
          description,
          cluster: {
            clusterName,
            namespace: runtimeNamespace
          },
          variable: {
            providerName: var_providerName,
            region: var_region,
            zone: var_zone,
            vpcID: var_vpcID
          },
          project
        };
        if (liteMode) {
          params.name = params.cluster.clusterName + "-" + runtimeNamespace;
          params.project = this.props.project;
        }
        if (isEdit) {
          updateTarget(params).then((res) => {
            if (res) {
              Message.success(/* @__PURE__ */ React.createElement(Translation, null, "Target updated successfully"));
              this.props.onOK(params.name);
              this.onClose();
            }
          });
        } else {
          createTarget(params).then((res) => {
            if (res) {
              Message.success(/* @__PURE__ */ React.createElement(Translation, null, "Target created successfully"));
              this.props.onOK(params.name);
              this.onClose();
            }
          });
        }
      });
    };
    this.transCluster = () => {
      const { clusterList } = this.props;
      return (clusterList || []).map((item) => ({
        label: item.alias || item.name,
        value: item.name
      }));
    };
    this.loadNamespaces = async (cluster) => {
      if (cluster) {
        listNamespaces({ cluster }).then((re) => {
          if (re && re.list) {
            const namespaces = [];
            re.list.map((item) => {
              if (item.metadata.labels && item.metadata.labels["namespace.oam.dev/target"]) {
                return;
              }
              namespaces.push({ label: item.metadata.name, value: item.metadata.name });
            });
            this.setState({ namespaces });
          }
        });
      }
    };
    this.getProviderList = async (projectName) => {
      getCloudServiceProviderList(projectName).then((res) => {
        if (res && Array.isArray(res.providers)) {
          this.setState({
            providerList: res.providers
          });
        } else {
          this.setState({
            providerList: []
          });
        }
      });
    };
    this.field = new Field(this, {
      onChange: (name, value) => {
        if (name == "clusterName") {
          this.setState({ namespaces: [] }, () => {
            this.loadNamespaces(value);
          });
          this.field.setValue("runtimeNamespace", "");
          props.clusterList?.map((cluster) => {
            if (cluster.name == value && cluster.providerInfo) {
              this.field.setValues({
                var_region: cluster.providerInfo.regionID,
                var_zone: cluster.providerInfo.zoneID,
                var_vpcID: cluster.providerInfo.vpcID
              });
              if (cluster.providerInfo.provider == "aliyun") {
                this.field.setValue("var_providerName", "default");
              }
            }
          });
        }
      }
    });
    this.state = {
      namespaces: [],
      providerList: [],
      isLoading: true
    };
  }
  componentDidMount() {
    const { targetItem } = this.props;
    if (targetItem) {
      const {
        name,
        alias,
        description,
        cluster = { clusterName: "", namespace: "" },
        variable = { providerName: "", region: "", zone: "", vpcID: "" },
        project = { name: "", alias: "" }
      } = targetItem;
      this.field.setValues({
        name,
        alias,
        description,
        clusterName: cluster.clusterName,
        runtimeNamespace: cluster.namespace,
        var_providerName: variable.providerName,
        var_region: variable.region,
        var_zone: variable.zone,
        var_vpcID: variable.vpcID,
        project: project.name
      });
      if (cluster) {
        this.loadNamespaces(cluster.clusterName);
      }
      if (project.name) {
        this.getProviderList(project.name);
      }
    }
    this.listProjects();
  }
  resetField() {
    this.field.setValues({
      name: "",
      alias: "",
      description: "",
      project: "",
      clusterName: "",
      namespace: "",
      providerName: "",
      region: "",
      zone: "",
      vpcID: ""
    });
  }
  render() {
    const { Col, Row } = Grid;
    const FormItem = Form.Item;
    const init = this.field.init;
    const formItemLayout = {
      labelCol: {
        fixedSpan: 6
      },
      wrapperCol: {
        span: 18
      }
    };
    const { visible, isEdit, liteMode } = this.props;
    const { namespaces, projects, isLoading } = this.state;
    const cluster = this.field.getValue("clusterName");
    const projectOptions = projects?.map((project) => {
      return {
        label: project.alias ? project.alias : project.name,
        value: project.name
      };
    });
    const { providerList } = this.state;
    const providerListOptions = providerList.map((pro) => {
      return {
        label: `(${pro.provider})${pro.name}`,
        value: pro.name
      };
    });
    return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
      Dialog,
      {
        v2: true,
        locale: locale().Dialog,
        title: isEdit ? /* @__PURE__ */ React.createElement(Translation, null, "Edit Target") : /* @__PURE__ */ React.createElement(Translation, null, "New Target"),
        autoFocus: true,
        overflowScroll: true,
        visible,
        onOk: this.onOk,
        onCancel: this.onClose,
        onClose: this.onClose,
        footerActions: ["ok"]
      },
      /* @__PURE__ */ React.createElement(Form, { ...formItemLayout, field: this.field }, !liteMode && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { m: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Name"), required: true }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "name",
          disabled: isEdit,
          placeholder: i18n.t("Please enter").toString(),
          ...init("name", {
            rules: [
              {
                required: true,
                pattern: checkName,
                message: "Please enter a valid name contains only alphabetical words"
              }
            ]
          })
        }
      ))), /* @__PURE__ */ React.createElement(Col, { m: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Alias") }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "alias",
          placeholder: i18n.t("Please enter").toString(),
          ...init("alias", {
            rules: [
              {
                minLength: 2,
                maxLength: 64,
                message: "Enter a string of 2 to 64 characters."
              }
            ]
          })
        }
      )))), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(Loading, { visible: isLoading, style: { width: "100%" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Project"), required: true }, /* @__PURE__ */ React.createElement(
        Select,
        {
          name: "project",
          hasClear: true,
          showSearch: true,
          disabled: isEdit,
          placeholder: i18n.t("Please select").toString(),
          filterLocal: true,
          dataSource: projectOptions,
          readOnly: this.props.project != void 0,
          style: { width: "100%" },
          ...init("project", {
            rules: [
              {
                required: true,
                message: "Please select project"
              }
            ],
            initValue: this.props.project
          }),
          onChange: (item) => {
            this.field.setValue("var_providerName", "");
            this.field.setValue("project", item);
            this.getProviderList(item);
          }
        }
      ))))), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Description") }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "description",
          placeholder: i18n.t("Please enter").toString(),
          ...init("description", {
            rules: [
              {
                maxLength: 256,
                message: "Enter a description that contains less than 256 characters."
              }
            ]
          })
        }
      ))))), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { m: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Cluster"), required: true }, /* @__PURE__ */ React.createElement(
        Select,
        {
          locale: locale().Select,
          className: "select",
          disabled: isEdit,
          placeholder: i18n.t("Please select").toString(),
          ...init(`clusterName`, {
            rules: [
              {
                required: true,
                message: "Please select"
              }
            ]
          }),
          dataSource: this.transCluster()
        }
      ))), /* @__PURE__ */ React.createElement(Col, { m: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Namespace"), required: true }, /* @__PURE__ */ React.createElement(
        Namespace,
        {
          ...init(`runtimeNamespace`, {
            rules: [
              {
                required: true,
                message: "Please select namesapce"
              }
            ]
          }),
          disabled: isEdit,
          namespaces,
          loadNamespaces: this.loadNamespaces,
          cluster,
          createNamespaceDialog: true,
          targetField: this.field
        }
      )))), !liteMode && /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(
        Group,
        {
          title: /* @__PURE__ */ React.createElement(Translation, null, "Shared Variables"),
          required: false,
          description: /* @__PURE__ */ React.createElement(Translation, null, "You can define parameters such as region or zone"),
          hasToggleIcon: true
        },
        /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(
          FormItem,
          {
            label: /* @__PURE__ */ React.createElement(Translation, null, "Cloud Service Provider"),
            help: i18n.t("Load the options after the project selected").toString()
          },
          /* @__PURE__ */ React.createElement(
            Select,
            {
              className: "select",
              locale: locale().Select,
              placeholder: i18n.t("Please select a terraform provider").toString(),
              ...init(`var_providerName`, {
                rules: [
                  {
                    required: false,
                    message: i18n.t("Please select terraform provider name")
                  }
                ]
              }),
              onChange: (provider) => {
                providerList.map((pro) => {
                  if (pro.name == provider) {
                    this.field.setValue("var_region", pro.region);
                  }
                });
                this.field.setValue("var_providerName", provider);
              },
              dataSource: providerListOptions
            }
          )
        )), /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Region") }, /* @__PURE__ */ React.createElement(
          Input,
          {
            name: "var_region",
            locale: locale().Input,
            placeholder: i18n.t("Please enter").toString(),
            ...init("var_region", {
              rules: [
                {
                  maxLength: 256,
                  message: "Enter a Region."
                }
              ]
            })
          }
        ))))
      ))))
    ));
  }
}
export default TargetDialog;
