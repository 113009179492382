"use strict";
import { Loading } from "@alifd/next";
import { connect } from "dva";
import { routerRedux } from "dva/router";
import React, { useEffect } from "react";
import { getApplicationEnvbinding } from "../../api/application";
const EnvRoute = (props) => {
  useEffect(() => {
    getApplicationEnvbinding({ appName: props.match.params.appName }).then((res) => {
      if (res && Array.isArray(res.envBindings) && res.envBindings.length > 0) {
        props.dispatch(
          routerRedux.push(
            `/applications/${props.match.params.appName}/envbinding/${res.envBindings[0].name}/workflow`
          )
        );
      } else {
        props.dispatch(routerRedux.push(`/applications/${props.match.params.appName}`));
      }
    });
  });
  return /* @__PURE__ */ React.createElement(Loading, { visible: true });
};
export default connect()(EnvRoute);
