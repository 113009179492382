"use strict";
import { Button, Dialog, Message, Radio, Loading, Select, Form } from "@alifd/next";
import React, { Component } from "react";
import _ from "lodash";
import { routerRedux } from "dva/router";
import { dryRunApplication } from "../../../../api/application";
import { ApplicationDryRun } from "../../../../components/ApplicationDryRun";
import { If } from "../../../../components/If";
import { Translation } from "../../../../components/Translation";
import i18n from "../../../../i18n";
import { locale } from "../../../../utils/locale";
import "./index.less";
import { DeployModes } from "@velaux/data";
import { showAlias } from "../../../../utils/common";
import { createWorkflow } from "../../../../api/workflows";
import Permission from "../../../../components/Permission";
const { Group: RadioGroup } = Radio;
function checkCanaryDeployStep(w) {
  if (w.steps?.find((step) => {
    if (step.type === DeployModes.CanaryDeploy) {
      return true;
    }
    if (step.subSteps?.find((sub) => sub.type === DeployModes.CanaryDeploy)) {
      return true;
    }
    return false;
  })) {
    return true;
  }
  return false;
}
class DeployConfigDialog extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = () => {
      if (this.state.workflowName) {
        this.props.onOK(this.state.workflowName);
        this.props.onClose();
      } else {
        Message.notice(i18n.t("Please select a workflow first"));
      }
    };
    this.onDryRun = () => {
      this.setState({ dryRunLoading: true, dryRunResult: void 0, dryRunMessage: void 0 });
      const { appName } = this.props;
      if (this.state.workflowName) {
        dryRunApplication(appName, {
          workflow: this.state.workflowName,
          dryRunType: "APP"
        }).then((res) => {
          this.setState({
            dryRunResult: res,
            dryRunResultState: res.success ? "success" : "failure",
            dryRunMessage: res.message
          });
        }).catch((err) => {
          console.log(err);
          this.setState({ dryRunResultState: "failure", dryRunMessage: err.Message });
        }).finally(() => {
          this.setState({ dryRunLoading: false });
        });
      } else {
        Message.notice(i18n.t("Please select a workflow first"));
      }
    };
    this.onShowDryRunResult = () => {
      this.setState({ showDryRunResult: true });
    };
    this.onChange = (name) => {
      this.setState({ workflowName: name });
    };
    this.onCreateCanaryRolloutWorkflow = () => {
      const { applicationDetail, workflows } = this.props;
      const { env } = this.state;
      const base = workflows?.find((w) => w.envName == env);
      if (!base) {
        Message.warning(i18n.t("There is no workflow belong to the selected environment"));
        return;
      }
      const createReq = _.cloneDeep(base);
      createReq.name = createReq.name + "-canary";
      createReq.alias = createReq.alias?.replace("Workflow", "Canary Workflow");
      createReq.default = false;
      createWorkflow({ appName: applicationDetail.name }, createReq).then((res) => {
        if (res) {
          this.loadApplicationWorkflows();
          this.props.dispatch(
            routerRedux.push(
              `/applications/${applicationDetail.name}/envbinding/${env}/workflow/${createReq.name}/studio?setCanary`
            )
          );
          this.props.onClose();
        }
      });
    };
    this.loadApplicationWorkflows = async () => {
      const { applicationDetail } = this.props;
      this.props.dispatch({
        type: "application/getApplicationWorkflows",
        payload: { appName: applicationDetail.name }
      });
    };
    this.renderDryRunResult = () => {
      const { dryRunResult } = this.state;
      if (dryRunResult) {
        return /* @__PURE__ */ React.createElement(
          ApplicationDryRun,
          {
            onClose: () => {
              this.setState({ showDryRunResult: false });
            },
            title: i18n.t("DryRun Result").toString(),
            dryRun: dryRunResult
          }
        );
      }
      return /* @__PURE__ */ React.createElement("div", null);
    };
    let defaultEnv = props.envName;
    if (!defaultEnv && props.envBindings.length > 0) {
      defaultEnv = props.envBindings[0].name;
    }
    this.state = {
      workflowName: "",
      dryRunLoading: false,
      showDryRunResult: false,
      env: defaultEnv
    };
  }
  componentDidMount() {
    const { workflows } = this.props;
    workflows?.map((w) => {
      if (w.default) {
        this.setState({ workflowName: w.name });
      }
    });
  }
  render() {
    const { workflows, onClose, applicationDetail, applicationAllStatus, envBindings, loading, envName } = this.props;
    const { workflowName, dryRunLoading, showDryRunResult, dryRunResult, dryRunResultState, dryRunMessage, env } = this.state;
    const sourceOfTrust = applicationDetail?.labels && applicationDetail?.labels["app.oam.dev/source-of-truth"];
    const envStatus = {};
    applicationAllStatus?.map((status2) => {
      envStatus[status2.envName] = status2.status;
    });
    const envOptions = envBindings?.map((env2) => {
      return { label: showAlias(env2), value: env2.name };
    }) || [];
    const workflowOptions = workflows?.filter((w) => w.envName === env);
    const status = env && envStatus[env] ? envStatus[env].status : "Undeployed";
    const haveCanaryRollout = workflowOptions?.find((workflow) => checkCanaryDeployStep(workflow)) ? true : false;
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
      Dialog,
      {
        visible: true,
        locale: locale().Dialog,
        className: "deployConfig",
        style: { width: "600px" },
        v2: true,
        overflowScroll: true,
        onClose,
        onCancel: onClose,
        onOk: this.onSubmit,
        title: /* @__PURE__ */ React.createElement(Translation, null, "Select Workflow"),
        footer: /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Button, { loading: dryRunLoading, type: "secondary", onClick: this.onDryRun }, /* @__PURE__ */ React.createElement(Translation, null, "DryRun")), /* @__PURE__ */ React.createElement(Button, { style: { marginLeft: "16px" }, type: "primary", onClick: this.onSubmit }, /* @__PURE__ */ React.createElement(Translation, null, "Deploy")))
      },
      /* @__PURE__ */ React.createElement(If, { condition: sourceOfTrust === "from-k8s-resource" }, /* @__PURE__ */ React.createElement(Message, { type: "warning", style: { marginBottom: "16px" } }, /* @__PURE__ */ React.createElement(Translation, null, "Once deployed, VelaUX hosts this application and no longer syncs the configuration from the cluster."))),
      /* @__PURE__ */ React.createElement(Loading, { visible: loading, style: { width: "100%" } }, /* @__PURE__ */ React.createElement(Form.Item, { labelAlign: "left", label: /* @__PURE__ */ React.createElement(Translation, null, "Environment") }, /* @__PURE__ */ React.createElement(
        Select,
        {
          dataSource: envOptions,
          onChange: (e) => {
            const d = workflows?.find((w) => w.envName === e && w.default);
            this.setState({ env: e, workflowName: d ? d.name : "" });
          },
          defaultValue: envName || (envOptions?.length > 0 ? envOptions[0].value : "")
        }
      )), /* @__PURE__ */ React.createElement("div", { className: "deploy-workflow-select-item" }, /* @__PURE__ */ React.createElement("div", { className: "status" }, /* @__PURE__ */ React.createElement(Translation, null, "Status"), ": ", /* @__PURE__ */ React.createElement(Translation, null, status)), !haveCanaryRollout && /* @__PURE__ */ React.createElement(
        Permission,
        {
          project: applicationDetail.project,
          request: {
            resource: `project:${applicationDetail.project}/application:${applicationDetail.name}/workflow:*`,
            action: "create"
          }
        },
        /* @__PURE__ */ React.createElement("div", { className: "enable-canary-deploy" }, /* @__PURE__ */ React.createElement(Button, { size: "small", type: "secondary", onClick: this.onCreateCanaryRolloutWorkflow }, /* @__PURE__ */ React.createElement(Translation, null, "Enable Canary Rollout")))
      )), haveCanaryRollout && status != "running" && /* @__PURE__ */ React.createElement(Message, { type: "notice" }, /* @__PURE__ */ React.createElement(Translation, null, "The canary rollout workflow is available when the application is running")), /* @__PURE__ */ React.createElement(RadioGroup, { value: workflowName, onChange: this.onChange }, workflowOptions?.map((workflow) => {
        const haveCanaryDeploy = checkCanaryDeployStep(workflow);
        return /* @__PURE__ */ React.createElement(
          Radio,
          {
            key: workflow.name,
            id: workflow.name,
            value: workflow.name,
            disabled: status != "running" && haveCanaryDeploy,
            onClick: () => {
              this.onChange(workflow.name);
            }
          },
          /* @__PURE__ */ React.createElement("div", { className: "deploy-workflow-select-item-title" }, workflow.alias ? workflow.alias : workflow.name, haveCanaryDeploy ? /* @__PURE__ */ React.createElement("div", { className: "deploy-notice" }, /* @__PURE__ */ React.createElement(Translation, null, "Canary Rollout")) : /* @__PURE__ */ React.createElement("div", { className: "deploy-notice" }, /* @__PURE__ */ React.createElement(Translation, null, "Default Rollout")))
        );
      })), /* @__PURE__ */ React.createElement(If, { condition: dryRunResultState == "success" }, /* @__PURE__ */ React.createElement(Message, { type: "success" }, /* @__PURE__ */ React.createElement(Translation, null, "Dry run successfully"), /* @__PURE__ */ React.createElement("a", { style: { marginLeft: "16px" }, onClick: this.onShowDryRunResult }, /* @__PURE__ */ React.createElement(Translation, null, "Review the result")))), /* @__PURE__ */ React.createElement(If, { condition: dryRunResultState == "failure" }, /* @__PURE__ */ React.createElement(Message, { type: "error" }, dryRunMessage, dryRunResult && /* @__PURE__ */ React.createElement("a", { style: { marginLeft: "16px" }, onClick: this.onShowDryRunResult }, /* @__PURE__ */ React.createElement(Translation, null, "Review the result")))), showDryRunResult && this.renderDryRunResult())
    ));
  }
}
export default DeployConfigDialog;
