"use strict";
import { Grid, Form, Input, Field, Checkbox, Button, Message } from "@alifd/next";
import React, { Component, Fragment } from "react";
import { createProjectRoles, updateProjectRoles } from "../../../../api/project";
import Empty from "../../../../components/Empty";
import { If } from "../../../../components/If";
import Permission from "../../../../components/Permission";
import { Translation } from "../../../../components/Translation";
import i18n from "../../../../i18n";
import { checkName } from "../../../../utils/common";
import "./index.less";
const { Group: CheckboxGroup } = Checkbox;
class ProjectPermPoliciesDetail extends Component {
  constructor(props) {
    super(props);
    this.initPermPoliciesStatus = (activeItem) => {
      if (activeItem) {
        return (activeItem.permissions || []).map((item) => item.name);
      } else {
        return [];
      }
    };
    this.onSubmit = () => {
      this.field.validate((error, values) => {
        if (error) {
          return;
        }
        const { isCreateProjectRoles, projectName, activeRoleName } = this.props;
        const { name, alias, permissions } = values;
        const queryData = {
          projectName,
          roleName: activeRoleName
        };
        const param = {
          name,
          alias,
          permissions
        };
        this.setState({ loading: true });
        if (isCreateProjectRoles) {
          createProjectRoles(queryData, param).then((res) => {
            this.setState({ loading: false });
            if (res) {
              Message.success(/* @__PURE__ */ React.createElement(Translation, null, "Create role success"));
              this.props.onCreate(res.name);
            }
          }).finally(() => {
            this.setState({ loading: false });
          });
        } else {
          updateProjectRoles(queryData, param).then((res) => {
            this.setState({ loading: false });
            if (res) {
              Message.success(/* @__PURE__ */ React.createElement(Translation, null, "Update role success"));
              this.props.onCreate(res.name);
            }
          }).finally(() => {
            this.setState({ loading: false });
          });
        }
      });
    };
    this.renderSubmitButton = () => {
      const { isCreateProjectRoles, projectName } = this.props;
      const name = this.field.getValue("name");
      if (isCreateProjectRoles) {
        return /* @__PURE__ */ React.createElement(Permission, { request: { resource: `project:${projectName}/role:*`, action: "create" }, project: projectName }, /* @__PURE__ */ React.createElement(Button, { className: "create-auth-btn", type: "primary", onClick: this.onSubmit }, /* @__PURE__ */ React.createElement(Translation, null, "Create")));
      } else {
        return /* @__PURE__ */ React.createElement(
          Permission,
          {
            request: { resource: `project:${projectName}/role:${name}`, action: "update" },
            project: projectName
          },
          /* @__PURE__ */ React.createElement(Button, { className: "create-auth-btn", type: "primary", onClick: this.onSubmit }, /* @__PURE__ */ React.createElement(Translation, null, "Update"))
        );
      }
    };
    this.field = new Field(this);
    this.state = {
      loading: false
    };
  }
  componentWillReceiveProps(nextProps) {
    const { isCreateProjectRoles } = nextProps;
    if (isCreateProjectRoles) {
      this.field.setValues({
        name: "",
        alias: "",
        permissions: []
      });
    } else {
      if (nextProps.activeRoleItem !== this.props.activeRoleItem) {
        this.field.setValues({
          name: nextProps.activeRoleItem.name || "",
          alias: nextProps.activeRoleItem.alias || "",
          permissions: this.initPermPoliciesStatus(nextProps.activeRoleItem)
        });
      }
    }
  }
  render() {
    const init = this.field.init;
    const { Row, Col } = Grid;
    const FormItem = Form.Item;
    const formItemLayout = {
      labelCol: {
        fixedSpan: 6
      },
      wrapperCol: {
        span: 20
      }
    };
    const { projectRoles, isCreateProjectRoles, projectPermissions } = this.props;
    const permissions = projectPermissions.map((p) => {
      return {
        label: p.alias || p.name,
        value: p.name
      };
    });
    return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement(If, { condition: projectRoles && projectRoles.length === 0 && !isCreateProjectRoles }, /* @__PURE__ */ React.createElement("div", { className: "project-role-empty-wrapper" }, /* @__PURE__ */ React.createElement(Empty, null))), /* @__PURE__ */ React.createElement(If, { condition: projectRoles && projectRoles.length !== 0 || isCreateProjectRoles }, /* @__PURE__ */ React.createElement("div", { className: "auth-list-wrapper" }, /* @__PURE__ */ React.createElement(Form, { ...formItemLayout, field: this.field, className: "auth-form-content" }, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "16px 16px 0 30px" } }, /* @__PURE__ */ React.createElement(
      FormItem,
      {
        label: /* @__PURE__ */ React.createElement(Translation, null, "Name"),
        labelAlign: "left",
        required: true,
        className: "font-weight-400"
      },
      /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "name",
          placeholder: i18n.t("Please input the rule name").toString(),
          maxLength: 32,
          disabled: isCreateProjectRoles ? false : true,
          ...init("name", {
            rules: [
              {
                required: true,
                pattern: checkName,
                message: /* @__PURE__ */ React.createElement(Translation, null, "Please enter a valid name")
              }
            ]
          })
        }
      )
    )), /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "16px 16px 0 30px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Alias"), labelAlign: "left", className: "font-weight-400" }, /* @__PURE__ */ React.createElement(
      Input,
      {
        name: "alias",
        placeholder: i18n.t("Please input the role alias").toString(),
        ...init("alias", {
          rules: [
            {
              minLength: 2,
              maxLength: 64,
              message: "Enter a string of 2 to 64 characters."
            }
          ]
        })
      }
    )))), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 16px 16px 30px" } }, /* @__PURE__ */ React.createElement(
      FormItem,
      {
        label: /* @__PURE__ */ React.createElement(Translation, null, "Permissions"),
        labelAlign: "left",
        className: "font-weight-400 permPolicies-wrapper",
        required: true
      },
      /* @__PURE__ */ React.createElement(
        CheckboxGroup,
        {
          dataSource: permissions,
          ...init("permissions", {
            rules: [
              {
                required: true,
                type: "array",
                message: "Please select at last on permission"
              }
            ]
          })
        }
      )
    )))), this.renderSubmitButton())));
  }
}
export default ProjectPermPoliciesDetail;
