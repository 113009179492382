"use strict";
import { Table, Button, Pagination, Message, Dialog } from "@alifd/next";
import React, { Component, Fragment } from "react";
import { getRoleList } from "../../api/roles";
import { getUserList, deleteUser, changeUserDisable, changeUserEnable } from "../../api/users";
import { If } from "../../components/If";
import { ListTitle } from "../../components/ListTitle";
import Permission from "../../components/Permission";
import { Translation } from "../../components/Translation";
import { momentDate } from "../../utils/common";
import { locale } from "../../utils/locale";
import CreateUser from "./components/CreateUser";
import ResetPassword from "./components/ResetPassword";
import SelectSearch from "./components/SelectSearch";
import "./index.less";
class Users extends Component {
  constructor(props) {
    super(props);
    this.listUser = async () => {
      const { name, alias, email, page, pageSize } = this.state;
      const params = {
        name,
        email,
        alias,
        page,
        pageSize
      };
      this.setState({ isLoading: true });
      getUserList(params).then((res) => {
        if (res) {
          this.setState({
            dataSource: res.users || [],
            total: res.total
          });
        }
      }).finally(() => {
        this.setState({
          isLoading: false
        });
      });
    };
    this.listRoles = async () => {
      getRoleList({}).then((res) => {
        this.setState({
          rolesList: res && res.roles || []
        });
      });
    };
    this.handleChangName = (e) => {
      this.setState(
        {
          name: e
        },
        () => {
          this.listUser();
        }
      );
    };
    this.onEdit = (record) => {
      this.onEditUser(record);
    };
    this.onResetPassword = (record) => {
      this.onEditResetPasswordUser(record);
    };
    this.onCreate = () => {
      this.setState({
        isUserDialogVisible: false
      });
      this.listUser();
    };
    this.onResetPassWord = () => {
      this.setState({
        isResetPasswordDialog: false
      });
      this.listUser();
    };
    this.onChangeStatus = (record) => {
      const { disabled, name } = record;
      if (disabled) {
        changeUserEnable({ name }).then((res) => {
          if (res) {
            Message.success(/* @__PURE__ */ React.createElement(Translation, null, "Update user status success"));
            this.listUser();
          }
        }).catch();
      } else {
        changeUserDisable({ name }).then((res) => {
          if (res) {
            Message.success(/* @__PURE__ */ React.createElement(Translation, null, "Update user status success"));
            this.listUser();
          }
        }).catch();
      }
    };
    this.onDelete = (record) => {
      Dialog.confirm({
        content: "Are you sure you want to delete the user",
        onOk: () => {
          const { name } = record;
          if (name) {
            deleteUser({ name }).then((res) => {
              if (res) {
                Message.success(/* @__PURE__ */ React.createElement(Translation, null, "Delete user success"));
                this.listUser();
              }
            }).catch();
          }
        },
        locale: locale().Dialog
      });
    };
    this.onClose = () => {
      this.setState({
        isUserDialogVisible: false
      });
    };
    this.onResetPassWordClose = () => {
      this.setState({
        isResetPasswordDialog: false
      });
    };
    this.onEditUser = (editUser) => {
      this.setState({
        editUser,
        isEditUser: true,
        isUserDialogVisible: true
      });
    };
    this.onEditResetPasswordUser = (editUser) => {
      this.setState({
        editUser,
        isResetPasswordDialog: true,
        isResetPassword: true
      });
    };
    this.handleClickCreate = () => {
      this.setState({
        isUserDialogVisible: true,
        isEditUser: false
      });
    };
    this.handleChange = (page) => {
      this.setState(
        {
          page
        },
        () => {
          this.listUser();
        }
      );
    };
    this.isDisabledShow = (record) => {
      if (record.disabled) {
        return /* @__PURE__ */ React.createElement(Translation, null, "Enable");
      } else {
        return /* @__PURE__ */ React.createElement(Translation, null, "Disable");
      }
    };
    this.state = {
      isUserDialogVisible: false,
      name: "",
      email: "",
      alias: "",
      page: 1,
      pageSize: 10,
      isLoading: false,
      total: 0,
      dataSource: [],
      rolesList: [],
      isEditUser: false,
      isResetPassword: false,
      isResetPasswordDialog: false,
      editUser: {
        name: "",
        email: "",
        disabled: false
      }
    };
  }
  componentDidMount() {
    this.listUser();
    this.listRoles();
  }
  handleChangAlias(e) {
    this.setState(
      {
        alias: e
      },
      () => {
        this.listUser();
      }
    );
  }
  handleChangEmail(e) {
    this.setState(
      {
        email: e
      },
      () => {
        this.listUser();
      }
    );
  }
  render() {
    const { Column } = Table;
    const columns = [
      {
        key: "name",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Name"),
        dataIndex: "name",
        cell: (v) => {
          return /* @__PURE__ */ React.createElement("span", null, v);
        }
      },
      {
        key: "alias",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Alias"),
        dataIndex: "alias",
        cell: (v) => {
          return /* @__PURE__ */ React.createElement("span", null, v);
        }
      },
      {
        key: "roles",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Platform Roles"),
        dataIndex: "roles",
        cell: (v) => {
          return (v || []).map((item) => /* @__PURE__ */ React.createElement("span", { className: "roles-permPolicies margin-right-5" }, item.alias || item.name));
        }
      },
      {
        key: "email",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Email"),
        dataIndex: "email",
        cell: (v) => {
          return /* @__PURE__ */ React.createElement("span", null, v);
        }
      },
      {
        key: "createTime",
        title: /* @__PURE__ */ React.createElement(Translation, null, "CreateTime"),
        dataIndex: "createTime",
        cell: (v) => {
          if (v) {
            return /* @__PURE__ */ React.createElement("span", null, momentDate(v));
          }
          return "";
        }
      },
      {
        key: "lastLoginTime",
        title: /* @__PURE__ */ React.createElement(Translation, null, "LastLoginTime"),
        dataIndex: "lastLoginTime",
        cell: (v) => {
          if (v) {
            return /* @__PURE__ */ React.createElement("span", null, momentDate(v));
          }
          return "";
        }
      },
      {
        key: "operation",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Actions"),
        dataIndex: "operation",
        cell: (v, i, record) => {
          return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement(Permission, { request: { resource: `user:${record.name}`, action: "update" }, project: "" }, /* @__PURE__ */ React.createElement(
            Button,
            {
              text: true,
              size: "medium",
              component: "a",
              onClick: () => {
                this.onResetPassword(record);
              }
            },
            /* @__PURE__ */ React.createElement(Translation, null, "Reset Password")
          )), /* @__PURE__ */ React.createElement("span", { className: "line" }), /* @__PURE__ */ React.createElement(Permission, { request: { resource: `user:${record.name}`, action: "update" }, project: "" }, /* @__PURE__ */ React.createElement(
            Button,
            {
              text: true,
              size: "medium",
              component: "a",
              onClick: () => {
                this.onEdit(record);
              }
            },
            /* @__PURE__ */ React.createElement(Translation, null, "Edit")
          )), /* @__PURE__ */ React.createElement("span", { className: "line" }), /* @__PURE__ */ React.createElement(
            Permission,
            {
              request: {
                resource: `user:${record.name}`,
                action: record.disabled ? "enable" : "disable"
              },
              project: ""
            },
            /* @__PURE__ */ React.createElement(
              Button,
              {
                text: true,
                size: "medium",
                component: "a",
                onClick: () => {
                  this.onChangeStatus(record);
                }
              },
              this.isDisabledShow(record)
            )
          ), /* @__PURE__ */ React.createElement("span", { className: "line" }), /* @__PURE__ */ React.createElement(Permission, { request: { resource: `user:${record.name}`, action: "delete" }, project: "" }, /* @__PURE__ */ React.createElement(
            Button,
            {
              text: true,
              size: "medium",
              component: "a",
              onClick: () => {
                this.onDelete(record);
              }
            },
            /* @__PURE__ */ React.createElement(Translation, null, "Delete")
          )));
        }
      }
    ];
    const {
      name,
      alias,
      email,
      dataSource,
      isUserDialogVisible,
      isEditUser,
      editUser,
      page,
      pageSize,
      total,
      isLoading,
      isResetPasswordDialog,
      isResetPassword,
      rolesList
    } = this.state;
    return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "user-wrapper" }, /* @__PURE__ */ React.createElement("section", null, /* @__PURE__ */ React.createElement(
      ListTitle,
      {
        title: "Users",
        subTitle: "Basic authorization management is provided for local users by default, but SSO authentication is strongly recommended",
        extButtons: [
          /* @__PURE__ */ React.createElement(Permission, { request: { resource: "user:*", action: "create" }, project: "" }, /* @__PURE__ */ React.createElement(Button, { type: "primary", onClick: this.handleClickCreate }, /* @__PURE__ */ React.createElement(Translation, null, "New User")), ",")
        ]
      }
    )), /* @__PURE__ */ React.createElement("section", null, /* @__PURE__ */ React.createElement(
      SelectSearch,
      {
        name,
        alias,
        email,
        handleChangName: (nameValue) => {
          this.handleChangName(nameValue);
        },
        handleChangAlias: (aliasValue) => {
          this.handleChangAlias(aliasValue);
        },
        handleChangEmail: (emailValue) => {
          this.handleChangEmail(emailValue);
        }
      }
    )), /* @__PURE__ */ React.createElement("section", { className: "margin-top-20  user-list-wrapper" }, /* @__PURE__ */ React.createElement(Table, { locale: locale().Table, dataSource, loading: isLoading }, columns.map((col, key) => /* @__PURE__ */ React.createElement(Column, { ...col, key, align: "left" }))), /* @__PURE__ */ React.createElement(
      Pagination,
      {
        className: "margin-top-20 text-align-right",
        total,
        locale: locale().Pagination,
        size: "medium",
        pageSize,
        current: page,
        onChange: this.handleChange
      }
    ), /* @__PURE__ */ React.createElement(If, { condition: isUserDialogVisible }, /* @__PURE__ */ React.createElement(
      CreateUser,
      {
        visible: isUserDialogVisible,
        isEditUser,
        editUser,
        rolesList,
        onClose: this.onClose,
        onCreate: this.onCreate
      }
    )), /* @__PURE__ */ React.createElement(If, { condition: isResetPasswordDialog }, /* @__PURE__ */ React.createElement(
      ResetPassword,
      {
        visible: isResetPasswordDialog,
        editUser,
        isResetPassword,
        onClose: this.onResetPassWordClose,
        onCreate: this.onResetPassWord
      }
    )))));
  }
}
export default Users;
