"use strict";
import { users, cloudShell } from "./productionLink";
import { post, get, rdelete, put } from "./request";
export function getUserList(params) {
  const url = users;
  return get(url, { params }).then((res) => res);
}
export function createUser(params) {
  const url = users;
  return post(url, params).then((res) => res);
}
export function updateUser(params) {
  const urlPath = users + `/${params.name}`;
  return put(urlPath, params).then((res) => res);
}
export function deleteUser(params) {
  const urlPath = users + `/${params.name}`;
  return rdelete(urlPath, {}).then((res) => res);
}
export function changeUserDisable(params) {
  const urlPath = users + `/${params.name}/disable`;
  return get(urlPath, {}).then((res) => res);
}
export function changeUserEnable(params) {
  const urlPath = users + `/${params.name}/enable`;
  return get(urlPath, {}).then((res) => res);
}
export function prepareCloudShell() {
  const urlPath = cloudShell;
  return post(urlPath, {}).then((res) => res);
}
