"use strict";
import { Grid, Dialog, Message } from "@alifd/next";
import React, { Component } from "react";
import { getProjectRoles, getProjectPermissions, deleteProjectRoles } from "../../api/project";
import { If } from "../../components/If";
import { Translation } from "../../components/Translation";
import { locale } from "../../utils/locale";
import ProjectMenu from "./components/Menu";
import ProjectPermPoliciesDetail from "./components/ProjectPermPoliciesDetail";
import "./index.less";
class ProjectRoles extends Component {
  constructor(props) {
    super(props);
    this.listProjectRoles = async () => {
      const { projectName } = this.state;
      const params = {
        projectName
      };
      this.setState({ isLoading: true });
      getProjectRoles(params).then((res) => {
        this.setState({
          projectRoles: res && res.roles || []
        });
      }).finally(() => {
        this.setState({
          isLoading: false
        });
      });
    };
    this.listProjectPermPolicies = async () => {
      const { projectName } = this.state;
      const param = { projectName };
      getProjectPermissions(param).then((res) => {
        this.setState({
          projectPermissions: res && res || []
        });
      });
    };
    this.getProjectName = () => {
      const { params = { projectName: "" } } = this.props.match;
      return params.projectName || "";
    };
    this.getActiveRoleName = () => {
      const { activeRoleName, projectRoles = [] } = this.state;
      if (!activeRoleName && projectRoles.length != 0) {
        return projectRoles[0] && projectRoles[0].name;
      } else {
        return activeRoleName || "";
      }
    };
    this.getActiveRoleItem = () => {
      const { activeRoleItem, projectRoles = [] } = this.state;
      if (!activeRoleItem.name && projectRoles.length != 0) {
        return projectRoles[0];
      } else {
        return activeRoleItem || [];
      }
    };
    this.addRole = () => {
      this.setState({
        isCreateProjectRoles: true
      });
    };
    this.handleChangeRole = (roleName) => {
      this.setState(
        {
          activeRoleName: roleName
        },
        () => {
          const activeRoleItem = this.findActiveRoleItem();
          this.setState({
            activeRoleItem: activeRoleItem || { name: "" }
          });
        }
      );
    };
    this.findActiveRoleItem = () => {
      const { projectRoles, activeRoleName } = this.state;
      return (projectRoles || [{ name: "" }]).find((item) => item.name === activeRoleName);
    };
    this.onCreate = (activeRoleName) => {
      this.setState({
        isCreateProjectRoles: false,
        activeRoleName
      });
      this.listProjectRoles();
    };
    this.onDeleteProjectRole = (roleName) => {
      const { projectName } = this.state;
      Dialog.confirm({
        content: "Are you sure you want to delete this role",
        onOk: () => {
          if (roleName) {
            deleteProjectRoles({ projectName, roleName }).then((res) => {
              if (res) {
                Message.success(/* @__PURE__ */ React.createElement(Translation, null, "Delete the role success"));
                this.listProjectRoles();
              }
            });
          }
        },
        locale: locale().Dialog
      });
    };
    this.state = {
      projectName: this.getProjectName(),
      projectRoles: [],
      projectPermissions: [],
      activeRoleName: "",
      isLoading: false,
      isAddRole: false,
      activeRoleItem: { name: "" },
      isCreateProjectRoles: false
    };
  }
  componentDidMount() {
    this.listProjectRoles();
    this.listProjectPermPolicies();
  }
  render() {
    const { Row, Col } = Grid;
    const { projectName, projectRoles, isAddRole, projectPermissions, isCreateProjectRoles } = this.state;
    return /* @__PURE__ */ React.createElement(Row, { className: "project-roles-wrapper" }, /* @__PURE__ */ React.createElement(Col, { span: "6" }, /* @__PURE__ */ React.createElement(
      ProjectMenu,
      {
        isCreateProjectRoles,
        isAddRole,
        activeRoleName: this.getActiveRoleName(),
        projectRoles,
        addRole: this.addRole,
        projectName,
        handleChangeRole: (roleName) => {
          this.handleChangeRole(roleName);
        },
        onDeleteProjectRole: (roleName) => {
          this.onDeleteProjectRole(roleName);
        }
      }
    )), /* @__PURE__ */ React.createElement(Col, { span: "18", className: "project-auth-border-left" }, /* @__PURE__ */ React.createElement(If, { condition: !isAddRole }, /* @__PURE__ */ React.createElement(
      ProjectPermPoliciesDetail,
      {
        projectRoles,
        projectName,
        isCreateProjectRoles,
        activeRoleName: this.getActiveRoleName(),
        activeRoleItem: this.getActiveRoleItem(),
        projectPermissions,
        onCreate: (activeRoleName) => {
          this.onCreate(activeRoleName);
        }
      }
    ))));
  }
}
export default ProjectRoles;
