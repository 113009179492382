"use strict";
import { Input } from "@alifd/next";
import React from "react";
class DiskNumber extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = (value) => {
      const { onChange } = this.props;
      if (onChange) {
        onChange(value + "Gi");
      }
    };
    this.state = {};
  }
  render() {
    const { value, id, disabled } = this.props;
    let initValue = void 0;
    if (value) {
      initValue = parseInt(value.replace("Gi", ""), 10);
    }
    return /* @__PURE__ */ React.createElement(
      Input,
      {
        id,
        disabled,
        min: "0",
        addonTextAfter: "Gi",
        htmlType: "number",
        onChange: this.onChange,
        value: initValue
      }
    );
  }
}
export default DiskNumber;
