"use strict";
import { Table, Button, Pagination, Message, Dialog } from "@alifd/next";
import React, { Component, Fragment } from "react";
import { getProjectRoles, getProjectUsers, deleteProjectUser } from "../../api/project";
import { If } from "../../components/If";
import Permission from "../../components/Permission";
import { Translation } from "../../components/Translation";
import { momentDate } from "../../utils/common";
import { locale } from "../../utils/locale";
import "./index.less";
import MemberDialog from "./components/MemberDialog";
class ProjectMembers extends Component {
  constructor(props) {
    super(props);
    this.listMember = async () => {
      const { projectName, page, pageSize } = this.state;
      const params = {
        projectName,
        page,
        pageSize
      };
      this.setState({ isLoading: true });
      getProjectUsers(params).then((res) => {
        this.setState({
          memberList: res && res.users || [],
          total: res && res.total || 0
        });
      }).finally(() => {
        this.setState({
          isLoading: false
        });
      });
    };
    this.listProjectRoles = async () => {
      const { projectName } = this.state;
      const params = {
        projectName
      };
      this.setState({ isLoading: true });
      getProjectRoles(params).then((res) => {
        this.setState({
          projectRoles: res && res.roles || []
        });
      }).finally(() => {
        this.setState({
          isLoading: false
        });
      });
    };
    this.getProjectName = () => {
      const { params = { projectName: "" } } = this.props.match;
      return params.projectName || "";
    };
    this.onEdit = (record) => {
      this.onEditMember(record);
    };
    this.onCreate = () => {
      this.setState({
        isMemberDialogVisible: false
      });
      this.listMember();
    };
    this.onDelete = (record) => {
      Dialog.confirm({
        content: "Are you sure you want to delete the member",
        onOk: () => {
          const { name, userRoles } = record;
          const { projectName } = this.state;
          const query = {
            userName: name,
            projectName
          };
          if (name) {
            deleteProjectUser(query, { userRoles }).then((res) => {
              if (res) {
                Message.success(/* @__PURE__ */ React.createElement(Translation, null, "Delete user success"));
                this.listMember();
              }
            }).catch();
          }
        },
        locale: locale().Dialog
      });
    };
    this.onClose = () => {
      this.setState({
        isMemberDialogVisible: false
      });
    };
    this.onEditMember = (editMember) => {
      this.setState({
        editMember,
        isEditMember: true,
        isMemberDialogVisible: true
      });
    };
    this.handleClickCreate = () => {
      this.setState({
        isMemberDialogVisible: true,
        isEditMember: false
      });
    };
    this.handleChange = (page) => {
      this.setState(
        {
          page
        },
        () => {
          this.listMember();
        }
      );
    };
    this.state = {
      page: 0,
      pageSize: 10,
      total: 0,
      memberList: [],
      projectRoles: [],
      projectName: this.getProjectName(),
      editMember: {
        name: "",
        userRoles: []
      },
      isLoading: false,
      isMemberDialogVisible: false,
      isEditMember: false
    };
  }
  componentDidMount() {
    this.listMember();
    this.listProjectRoles();
  }
  render() {
    const { Column } = Table;
    const {
      memberList,
      projectName,
      isMemberDialogVisible,
      isEditMember,
      editMember,
      projectRoles,
      page,
      pageSize,
      total,
      isLoading
    } = this.state;
    const columns = [
      {
        key: "name",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Name"),
        dataIndex: "name",
        cell: (v, i, item) => {
          return /* @__PURE__ */ React.createElement("span", null, v, "(", item.alias || "-", ")");
        }
      },
      {
        key: "userRoles",
        title: /* @__PURE__ */ React.createElement(Translation, null, "UserRoles"),
        dataIndex: "userRoles",
        cell: (v) => {
          return v.map((item) => /* @__PURE__ */ React.createElement("span", { key: item, className: "roles-permPolicies margin-right-5" }, item));
        }
      },
      {
        key: "createTime",
        title: /* @__PURE__ */ React.createElement(Translation, null, "CreateTime"),
        dataIndex: "createTime",
        cell: (v) => {
          if (v) {
            return /* @__PURE__ */ React.createElement("span", null, momentDate(v));
          }
          return "";
        }
      },
      {
        key: "operation",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Actions"),
        dataIndex: "operation",
        cell: (v, i, record) => {
          return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement(
            Permission,
            {
              request: {
                resource: `project:${projectName}/projectUser:${record.name}`,
                action: "update"
              },
              project: projectName
            },
            /* @__PURE__ */ React.createElement(
              Button,
              {
                text: true,
                size: "medium",
                component: "a",
                onClick: () => {
                  this.onEdit(record);
                }
              },
              /* @__PURE__ */ React.createElement(Translation, null, "Edit")
            )
          ), /* @__PURE__ */ React.createElement(
            Permission,
            {
              request: {
                resource: `project:${projectName}/projectUser:${record.name}`,
                action: "delete"
              },
              project: projectName
            },
            /* @__PURE__ */ React.createElement(
              Button,
              {
                text: true,
                size: "medium",
                component: "a",
                onClick: () => {
                  this.onDelete(record);
                }
              },
              /* @__PURE__ */ React.createElement(Translation, null, "Delete")
            )
          ));
        }
      }
    ];
    return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "member-wrapper" }, /* @__PURE__ */ React.createElement("section", { className: "member-create-btn" }, /* @__PURE__ */ React.createElement(
      Permission,
      {
        request: { resource: `project:${projectName}/projectUser:*`, action: "create" },
        project: projectName
      },
      /* @__PURE__ */ React.createElement(Button, { type: "primary", onClick: this.handleClickCreate }, /* @__PURE__ */ React.createElement(Translation, null, "Add Member"))
    )), /* @__PURE__ */ React.createElement("section", { className: "margin-top-20  member-list-wrapper" }, /* @__PURE__ */ React.createElement(Table, { locale: locale().Table, dataSource: memberList, loading: isLoading }, columns.map((col, key) => /* @__PURE__ */ React.createElement(Column, { ...col, key, align: "left" }))), /* @__PURE__ */ React.createElement(
      Pagination,
      {
        className: "margin-top-20 text-align-right",
        total,
        locale: locale().Pagination,
        size: "medium",
        pageSize,
        current: page,
        onChange: this.handleChange
      }
    ), /* @__PURE__ */ React.createElement(If, { condition: isMemberDialogVisible }, /* @__PURE__ */ React.createElement(
      MemberDialog,
      {
        visible: isMemberDialogVisible,
        projectName,
        projectRoles,
        isEditMember,
        editMember,
        onClose: this.onClose,
        onCreate: this.onCreate
      }
    )))));
  }
}
export default ProjectMembers;
