"use strict";
import { Icon, Loading, Grid, Switch, Dialog } from "@alifd/next";
import React from "react";
import { Translation } from "../../components/Translation";
import { locale } from "../../utils/locale";
import "./index.less";
import { If } from "../../components/If";
const { Col, Row } = Grid;
class Group extends React.Component {
  constructor(props) {
    super(props);
    this.toggleShowClass = () => {
      const { closed } = this.state;
      this.setState({
        closed: !closed
      });
    };
    this.initSwitchState = () => {
      const { jsonKey = "", propertyValue = {}, alwaysShow = false, required, closed, initClose } = this.props;
      const findKey = propertyValue && Object.keys(propertyValue).find((item) => item === jsonKey);
      if (findKey || alwaysShow) {
        this.setState({ enable: true, closed: initClose, checked: true });
      } else if (required) {
        this.setState({ enable: true, closed: initClose, checked: true });
      } else {
        this.setState({ enable: false, closed: closed || initClose, checked: false });
      }
    };
    this.state = {
      closed: props.closed,
      enable: props.required,
      checked: false
    };
  }
  componentDidMount() {
    this.initSwitchState();
  }
  removeJsonKeyValue() {
    const { jsonKey = "", onChange } = this.props;
    const field = this.props.field;
    if (field && onChange) {
      field.remove(jsonKey);
      const values = field.getValues();
      onChange(values);
    }
  }
  render() {
    const { title, description, children, hasToggleIcon, loading, required, disableAddon = false } = this.props;
    const { closed, enable, checked } = this.state;
    return /* @__PURE__ */ React.createElement(Loading, { visible: loading || false, style: { width: "100%" } }, /* @__PURE__ */ React.createElement("div", { className: "group-container" }, /* @__PURE__ */ React.createElement(If, { condition: title }, /* @__PURE__ */ React.createElement("div", { className: "group-title-container" }, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: "21" }, /* @__PURE__ */ React.createElement("span", { className: `group-title ${required && "required"}` }, title), /* @__PURE__ */ React.createElement("div", { className: "group-title-desc" }, description)), /* @__PURE__ */ React.createElement(Col, { span: "3", className: "flexcenter" }, /* @__PURE__ */ React.createElement(If, { condition: !required }, /* @__PURE__ */ React.createElement(
      Switch,
      {
        size: "small",
        defaultChecked: required,
        checked,
        disabled: disableAddon,
        onChange: (event) => {
          if (event === true) {
            this.setState({ enable: event, closed: false, checked: true });
          } else if (event === false) {
            Dialog.confirm({
              type: "confirm",
              content: /* @__PURE__ */ React.createElement(Translation, null, "The configuration will be reset if the switch is turned off. Are you sure want to do this?"),
              onOk: () => {
                this.setState({ enable: event, closed: false, checked: false });
                this.removeJsonKeyValue();
              },
              locale: locale().Dialog
            });
          }
        }
      }
    )), /* @__PURE__ */ React.createElement(If, { condition: enable && hasToggleIcon }, /* @__PURE__ */ React.createElement(Icon, { onClick: this.toggleShowClass, className: "icon", type: closed ? "arrow-down" : "arrow-up" })))))), /* @__PURE__ */ React.createElement(If, { condition: enable }, /* @__PURE__ */ React.createElement("div", { className: `group-box ${closed ? "disable" : ""}` }, children))));
  }
}
export default Group;
