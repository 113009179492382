"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import classNames from "classnames";
import { connect } from "dva";
import _ from "lodash";
import React from "react";
import Draggable from "react-draggable";
import { AiOutlinePlayCircle } from "react-icons/ai";
import { FiStopCircle } from "react-icons/fi";
import { WorkflowEditContext } from "../../context";
import { Edge } from "./edge";
import { Step } from "./step";
import StepForm from "./step-form";
import TypeSelect from "./type-select";
let WorkflowStudio = class extends React.Component {
  constructor(props) {
    super(props);
    this.renderSteps = (props) => {
      const steps = props.steps?.map((step) => {
        const se = Object.assign({ nodeType: "step", width: 260 }, step);
        if (step.subSteps) {
          se.subSteps = _.cloneDeep(step.subSteps);
        }
        return se;
      }) || [];
      steps.unshift({ nodeType: "start", width: 100, name: "start", type: "" });
      steps.push({ nodeType: "end", width: 100, name: "end", type: "" });
      return steps;
    };
    this.onChange = () => {
      const { steps } = this.state;
      const ws = steps.filter((step) => step.nodeType === "step").map((step) => {
        return _.omit(step, ["width", "incomplete", "nodeType"]);
      });
      this.props.onChange(ws);
    };
    this.checkStepName = (name) => {
      const { steps } = this.state;
      let found = false;
      steps.map((step) => {
        if (step.name === name) {
          found = true;
        }
        step.subSteps?.map((subStep) => {
          if (subStep.name === name) {
            found = true;
          }
        });
      });
      return found;
    };
    this.addStep = (step) => {
      const { addIndex, subIndex, steps, subStep } = this.state;
      if (!subStep) {
        steps.splice(addIndex, 0, { ...step, nodeType: "step", width: 260, incomplete: true });
      } else {
        if (steps[addIndex].subSteps) {
          steps[addIndex].subSteps?.splice(subIndex, 0, step);
        } else {
          steps[addIndex].subSteps = [step];
        }
      }
      this.setState(
        {
          steps,
          changed: true,
          addIndex: 0,
          subIndex: 0,
          subStep: false
        },
        this.onChange
      );
      if (step.type != "step-group") {
        this.setState({ showStep: step, subStep });
      }
    };
    this.onUpdateStep = (step) => {
      const { steps } = this.state;
      steps.map((s, index) => {
        if (s.name === step.name) {
          steps[index] = { ...s, ...step, incomplete: false };
        }
        s.subSteps?.map((subStep, j) => {
          if (subStep.name === step.name && s.subSteps) {
            s.subSteps[j] = { ...step };
          }
        });
      });
      this.setState({ steps, showStep: void 0, subStep: false }, this.onChange);
    };
    this.onDeleteStep = (stepName) => {
      const { steps } = this.state;
      const newSteps = _.cloneDeep(steps);
      newSteps.map((s, index) => {
        s.subSteps?.map((subStep, j) => {
          if (subStep.name === stepName && s.subSteps) {
            s.subSteps.splice(j, 1);
          }
        });
        if (s.name === stepName && s.nodeType === "step") {
          newSteps.splice(index, 1);
        }
      });
      this.setState({ steps: newSteps }, this.onChange);
    };
    const steps = this.renderSteps(props);
    this.state = { steps, stepInterval: 100, addIndex: 0, subIndex: 0, changed: false };
  }
  componentDidUpdate(prevProps) {
    if (prevProps.steps != this.props.steps) {
      this.setState({ steps: this.renderSteps(this.props) });
    }
  }
  render() {
    const { steps, stepInterval, addIndex, showStep, subStep } = this.state;
    const { definitions, subMode } = this.props;
    return /* @__PURE__ */ React.createElement(
      "div",
      {
        className: classNames("run-studio"),
        style: {
          paddingLeft: "2rem"
        }
      },
      /* @__PURE__ */ React.createElement("div", { className: "studio" }, /* @__PURE__ */ React.createElement(Draggable, null, /* @__PURE__ */ React.createElement("div", { className: "workflow-graph" }, /* @__PURE__ */ React.createElement(Edge, { steps: steps || [], stepInterval }), steps?.map((step, index) => {
        const addAction = /* @__PURE__ */ React.createElement(
          "div",
          {
            className: "line-action",
            onClick: () => {
              this.setState({ addIndex: index });
            }
          },
          /* @__PURE__ */ React.createElement("span", { className: "line-icon" }, /* @__PURE__ */ React.createElement("svg", { "data-icon": "plus", width: "16", height: "16", viewBox: "0 0 16 16", fill: "currentColor" }, /* @__PURE__ */ React.createElement("desc", null, "plus"), /* @__PURE__ */ React.createElement(
            "path",
            {
              d: "M13 7H9V3c0-.55-.45-1-1-1s-1 .45-1 1v4H3c-.55 0-1 .45-1 1s.45 1 1 1h4v4c0 .55.45 1 1 1s1-.45 1-1V9h4c.55 0 1-.45 1-1s-.45-1-1-1z",
              fillRule: "evenodd"
            }
          )))
        );
        switch (step.nodeType) {
          case "start":
            return /* @__PURE__ */ React.createElement("div", { key: step.nodeType + step.name, className: classNames("step", "step-start") }, /* @__PURE__ */ React.createElement(AiOutlinePlayCircle, { size: 24 }), /* @__PURE__ */ React.createElement("div", { className: "workflow-step-port workflow-step-port-output step-circle" }));
          case "step":
            return /* @__PURE__ */ React.createElement("div", { key: step.nodeType + step.name, style: { position: "relative" } }, /* @__PURE__ */ React.createElement(
              Step,
              {
                subMode,
                probeState: { stepWidth: step.width, stepInterval },
                step,
                group: step.type == "step-group",
                output: true,
                input: true,
                onNodeClick: (s, sub) => {
                  this.setState({ showStep: s, subStep: sub });
                },
                onAddSubStep: (subIndex) => {
                  this.setState({ addIndex: index, subIndex, subStep: true });
                },
                onDelete: this.onDeleteStep
              }
            ), addAction);
          case "end":
            return /* @__PURE__ */ React.createElement("div", { key: step.nodeType + step.name, style: { position: "relative" } }, /* @__PURE__ */ React.createElement("div", { key: step.nodeType + step.name, className: classNames("step", "step-end") }, /* @__PURE__ */ React.createElement(FiStopCircle, { size: 24 }), /* @__PURE__ */ React.createElement("div", { className: "workflow-step-port workflow-step-port-input step-circle" })), addAction);
        }
        return;
      })))),
      addIndex > 0 && /* @__PURE__ */ React.createElement(
        TypeSelect,
        {
          checkStepName: this.checkStepName,
          onClose: () => {
            this.setState({ addIndex: 0, subIndex: 0, subStep: false });
          },
          addSub: subStep,
          addStep: this.addStep,
          definitions
        }
      ),
      showStep && /* @__PURE__ */ React.createElement(
        WorkflowEditContext.Provider,
        {
          value: {
            stepName: showStep.name,
            steps: steps.filter((step) => step.nodeType === "step")
          }
        },
        /* @__PURE__ */ React.createElement(
          StepForm,
          {
            onClose: () => {
              this.setState({ showStep: void 0 });
            },
            isSubStep: subStep,
            onUpdate: this.onUpdateStep,
            step: showStep
          }
        )
      )
    );
  }
};
WorkflowStudio = __decorateClass([
  connect()
], WorkflowStudio);
export default WorkflowStudio;
