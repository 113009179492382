"use strict";
import { getDomain } from "../utils/common";
import { project_mock } from "./devLink";
import { project } from "./productionLink";
import { post, get, rdelete, put } from "./request";
const baseURLOject = getDomain();
const isMock = baseURLOject.MOCK;
const url = isMock ? project_mock : project;
export function getProjectList(params) {
  return get(url, { params }).then((res) => res);
}
export function createProject(params) {
  return post(url, params).then((res) => res);
}
export function updateProject(params) {
  const urlPath = project + `/${params.name}`;
  return put(urlPath, params).then((res) => res);
}
export function deleteProject(params) {
  const urlPath = project + `/${params.name}`;
  return rdelete(urlPath, {}).then((res) => res);
}
export function getProjectDetail(params) {
  const urlPath = project + `/${params.projectName}`;
  return get(urlPath, {}).then((res) => res);
}
export function getProjectTargetList(params) {
  const urlPath = project + `/${params.projectName}/targets`;
  return get(urlPath, {}).then((res) => res);
}
export function getProjectPermissions(params) {
  const urlPath = project + `/${params.projectName}/permissions`;
  return get(urlPath, {}).then((res) => res);
}
export function getProjectRoles(query) {
  const urlPath = project + `/${query.projectName}/roles`;
  return get(urlPath, {}).then((res) => res);
}
export function createProjectRoles(query, params) {
  const urlPath = project + `/${query.projectName}/roles`;
  return post(urlPath, params).then((res) => res);
}
export function updateProjectRoles(query, params) {
  const urlPath = project + `/${query.projectName}/roles/${query.roleName}`;
  return put(urlPath, params).then((res) => res);
}
export function deleteProjectRoles(query) {
  const urlPath = project + `/${query.projectName}/roles/${query.roleName}`;
  return rdelete(urlPath, {}).then((res) => res);
}
export function getProjectUsers(query) {
  const urlPath = project + `/${query.projectName}/users`;
  return get(urlPath, { params: query }).then((res) => res);
}
export function createProjectUsers(query, params) {
  const urlPath = project + `/${query.projectName}/users`;
  return post(urlPath, params).then((res) => res);
}
export function updateProjectUser(query, params) {
  const urlPath = project + `/${query.projectName}/users/${query.userName}`;
  return put(urlPath, params).then((res) => res);
}
export function deleteProjectUser(query, params) {
  const urlPath = project + `/${query.projectName}/users/${query.userName}`;
  return rdelete(urlPath, params).then((res) => res);
}
export function getCloudServiceProviderList(projectName) {
  const urlPath = project + `/${projectName}/providers`;
  return get(urlPath, {}).then((res) => res);
}
