"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Field } from "@alifd/next";
import { connect } from "dva";
import React from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { v4 as uuid } from "uuid";
import { getApplicationComponents } from "../../api/application";
import ComponentPatch from "./component-patch";
let ComponentPatches = class extends React.Component {
  constructor(props) {
    super(props);
    this.componentDidMount = () => {
      this.fetchComponentList();
    };
    this.fetchComponentList = async () => {
      const { appName } = this.props;
      if (appName) {
        getApplicationComponents({
          appName
        }).then((res) => {
          if (res && res.components) {
            const componentTypeOptions = [];
            const componentOptions = (res.components || []).map(
              (item) => {
                componentTypeOptions.push({
                  label: item.componentType,
                  value: item.componentType
                });
                return {
                  label: `${item.name}(${item.alias || "-"})`,
                  value: item.name
                };
              }
            );
            this.setState(
              {
                componentOptions,
                componentTypeOptions,
                components: res.components
              },
              () => {
                this.loadValue();
              }
            );
          } else {
            this.setState({
              componentOptions: [],
              componentTypeOptions: [],
              components: []
            });
          }
        }).catch(() => {
          this.setState({
            componentOptions: [],
            componentTypeOptions: [],
            components: []
          });
        });
      }
    };
    this.addPatch = () => {
      this.setState((prevState) => ({
        componentPatches: [...prevState.componentPatches, { id: uuid() }]
      }));
    };
    this.onRemove = (id) => {
      this.setState((prevState) => ({
        componentPatches: prevState.componentPatches.filter((cp) => cp.id != id) || []
      }));
      this.field.remove(id);
    };
    this.onRegisterForm = (key, form) => {
      this.registerForm[key] = form;
    };
    this.loadValue = () => {
      const { value } = this.props;
      if (Array.isArray(value)) {
        this.setState({
          componentPatches: value.map((v) => {
            return { ...v, id: uuid() };
          })
        });
      }
    };
    this.state = {
      componentOptions: [],
      componentTypeOptions: [],
      componentPatches: [],
      components: []
    };
    this.field = new Field(this, {
      onChange: () => {
        const values = this.field.getValues();
        const data = [];
        Object.keys(values).map((key) => {
          delete values[key].id;
          data.push(values[key]);
        });
        const { onChange } = this.props;
        if (onChange) {
          onChange(data);
        }
      }
    });
    this.props.registerForm(this.field);
    this.registerForm = {};
  }
  render() {
    const { id, disabled, appName, projectName } = this.props;
    const { componentOptions, componentTypeOptions, componentPatches, components } = this.state;
    const { init } = this.field;
    return /* @__PURE__ */ React.createElement("div", { id }, !disabled && /* @__PURE__ */ React.createElement("div", { className: "flexright" }, /* @__PURE__ */ React.createElement("a", { onClick: this.addPatch }, /* @__PURE__ */ React.createElement(AiOutlinePlus, null), " Add a component patch")), componentPatches.map((cp) => {
      if (!cp || !cp.id) {
        return;
      }
      const validator = (rule, v, callback) => {
        if (cp.id && this.registerForm[cp.id]) {
          this.registerForm[cp.id].validate((errors) => {
            if (errors) {
              callback(`The component patch ${cp?.name} validate failure`);
              return;
            }
            callback();
          });
        } else {
          callback();
        }
      };
      return /* @__PURE__ */ React.createElement(
        ComponentPatch,
        {
          key: cp.id,
          appName: appName || "",
          projectName: projectName || "",
          onRemove: this.onRemove,
          disabled: false,
          componentOptions,
          getComponents: (name, type) => {
            return components.filter((com) => {
              return com.name == name || com.componentType == type;
            });
          },
          ...init(cp.id, {
            initValue: cp,
            rules: [{ validator }]
          }),
          componentTypeOptions,
          registerForm: (form) => {
            if (cp.id) {
              this.onRegisterForm(cp.id, form);
            }
          }
        }
      );
    }));
  }
};
ComponentPatches = __decorateClass([
  connect((store) => {
    return { ...store.uischema };
  })
], ComponentPatches);
export default ComponentPatches;
