"use strict";
import { MenuTypes } from "@velaux/data";
import * as React from "react";
import _ from "lodash";
import { FaLayerGroup } from "react-icons/fa";
import {
  AiFillCodeSandboxCircle,
  AiFillEnvironment,
  AiFillProject,
  AiFillSetting,
  AiOutlineCluster
} from "react-icons/ai";
import { BsFileEarmarkPerson, BsFillFileCodeFill, BsHddNetworkFill, BsPlugin } from "react-icons/bs";
import { RiUserSettingsFill } from "react-icons/ri";
import { MdConfirmationNumber } from "react-icons/md";
import { locationService } from "./LocationService";
import { checkPermission } from "../utils/permission";
import { getPluginSrv } from "./PluginService";
const defaultWorkspaces = [
  {
    name: "continuous-delivery",
    label: "Continuous Delivery",
    rootRoute: "/applications",
    icon: /* @__PURE__ */ React.createElement(FaLayerGroup, null)
  },
  {
    name: "extension",
    label: "Extension",
    rootRoute: "/addons",
    icon: /* @__PURE__ */ React.createElement(BsPlugin, null)
  },
  {
    name: "admin",
    label: "Admin Dashboard",
    rootRoute: "/clusters",
    icon: /* @__PURE__ */ React.createElement(AiFillSetting, null)
  }
];
const defaultWorkspaceMenus = [
  {
    workspace: "continuous-delivery",
    type: MenuTypes.Workspace,
    icon: /* @__PURE__ */ React.createElement(FaLayerGroup, null),
    name: "applications",
    label: "Applications",
    to: "/applications",
    permission: { resource: "project:?/application:*", action: "list" },
    relatedRoute: ["/applications"]
  },
  {
    workspace: "continuous-delivery",
    type: MenuTypes.Workspace,
    name: "pipeline-list",
    to: "/pipelines",
    relatedRoute: [/projects\/.*\/pipelines\/.*/, "/pipelines"],
    icon: /* @__PURE__ */ React.createElement(BsHddNetworkFill, null),
    label: "Pipelines",
    permission: { resource: "project:?/pipeline:*", action: "list" }
  },
  {
    workspace: "continuous-delivery",
    to: "/envs",
    type: MenuTypes.Workspace,
    icon: /* @__PURE__ */ React.createElement(AiFillEnvironment, null),
    label: "Environments",
    name: "env-list",
    permission: { resource: "project:?/environment:*", action: "list" },
    relatedRoute: ["/envs"]
  },
  {
    workspace: "continuous-delivery",
    type: MenuTypes.Workspace,
    to: "/targets",
    icon: /* @__PURE__ */ React.createElement(AiFillCodeSandboxCircle, null),
    label: "Targets",
    name: "target-list",
    permission: { resource: "target:*", action: "list" },
    relatedRoute: ["/targets"]
  },
  {
    workspace: "continuous-delivery",
    type: MenuTypes.Workspace,
    icon: /* @__PURE__ */ React.createElement(AiFillProject, null),
    name: "projects",
    label: "Projects",
    to: "/projects",
    relatedRoute: ["/projects"]
  },
  {
    workspace: "extension",
    type: MenuTypes.Workspace,
    to: "/addons",
    icon: /* @__PURE__ */ React.createElement(BsPlugin, null),
    label: "Addons",
    name: "addon-list",
    permission: { resource: "addon:*", action: "list" },
    relatedRoute: ["/addons", /\/manage\/plugins.*/]
  },
  {
    workspace: "extension",
    type: MenuTypes.Workspace,
    to: "/definitions",
    icon: /* @__PURE__ */ React.createElement(BsFillFileCodeFill, null),
    label: "Definitions",
    name: "definition-list",
    permission: { resource: "definition:*", action: "list" },
    relatedRoute: ["/definitions"]
  },
  {
    type: MenuTypes.Workspace,
    workspace: "admin",
    to: "/clusters",
    icon: /* @__PURE__ */ React.createElement(AiOutlineCluster, null),
    label: "Clusters",
    name: "cluster-list",
    permission: { resource: "cluster:*", action: "list" },
    relatedRoute: ["/clusters"]
  },
  {
    workspace: "admin",
    type: MenuTypes.Workspace,
    to: "/configs",
    icon: /* @__PURE__ */ React.createElement(MdConfirmationNumber, null),
    label: "Global Configs",
    name: "configs",
    permission: { resource: "config:*", action: "list" },
    relatedRoute: ["/configs"]
  },
  {
    workspace: "admin",
    type: MenuTypes.Workspace,
    to: "/platform/projects",
    icon: /* @__PURE__ */ React.createElement(AiFillProject, null),
    label: "Projects",
    name: "project-list",
    permission: { resource: "project:*", action: "list" },
    relatedRoute: ["/platform/projects$"]
  },
  {
    workspace: "admin",
    type: MenuTypes.Workspace,
    to: "/users",
    icon: /* @__PURE__ */ React.createElement(RiUserSettingsFill, null),
    label: "Users",
    name: "user-list",
    permission: { resource: "user:*", action: "list" },
    relatedRoute: ["/users"]
  },
  {
    workspace: "admin",
    type: MenuTypes.Workspace,
    to: "/roles",
    icon: /* @__PURE__ */ React.createElement(BsFileEarmarkPerson, null),
    label: "Platform Roles",
    name: "role-list",
    permission: { resource: "role:*", action: "list" },
    relatedRoute: ["^/roles$"]
  },
  {
    workspace: "admin",
    type: MenuTypes.Workspace,
    to: "/settings",
    icon: /* @__PURE__ */ React.createElement(AiFillSetting, null),
    label: "Settings",
    name: "settings",
    permission: { resource: "systemSetting", action: "update" },
    relatedRoute: ["/settings"]
  }
];
export class MenuWrapper {
  constructor() {
    this.loadPluginMenus = () => {
      if (this.pluginLoaded) {
        return Promise.resolve(this.menus);
      }
      return getPluginSrv().listAppPagePlugins().then((plugins) => {
        plugins.map((plugin) => {
          plugin.includes?.map((include) => {
            if (!this.workspaces.find((w) => w.name === include.workspace.name)) {
              include.workspace.rootRoute = include.to;
              this.workspaces.push(include.workspace);
            }
            if (!this.menus.find((m) => m.name == include.name)) {
              const pluginMenu = {
                workspace: include.workspace.name,
                type: include.type,
                name: include.name,
                label: include.label,
                to: include.to,
                relatedRoute: include.relatedRoute,
                permission: include.permission,
                catalog: include.catalog
              };
              this.menus.push(pluginMenu);
            }
          });
        });
        this.pluginLoaded = true;
        return Promise.resolve(this.menus);
      });
    };
    this.resetPluginMenus = () => {
      this.pluginLoaded = false;
      this.menus = _.cloneDeep(defaultWorkspaceMenus);
      this.workspaces = _.cloneDeep(defaultWorkspaces);
    };
    this.menus = _.cloneDeep(defaultWorkspaceMenus);
    this.workspaces = _.cloneDeep(defaultWorkspaces);
    this.pluginLoaded = false;
  }
  getWorkspace(name) {
    return this.workspaces.find((w) => w.name == name);
  }
  // This function should be called after calling the loadPluginMenus function
  loadCurrentWorkspace() {
    let w = void 0;
    this.menus.map((m) => {
      if (!w && this.matchMenu(m)) {
        w = this.getWorkspace(m.workspace);
      }
    });
    return w;
  }
  loadWorkspaces(user) {
    const availableWorkspaces = this.workspaces.filter((ws) => this.loadMenus(ws, user).length > 0);
    return availableWorkspaces;
  }
  // This function should be called after calling the loadPluginMenus function
  loadMenus(workspace, user) {
    let menus = [];
    this.menus.filter((menu) => menu.workspace == workspace.name).map((menu) => {
      if (!checkPermission(menu.permission, "?", user)) {
        return;
      }
      const catalog = menus.filter((m) => m.catalog == menu.catalog);
      const newMenu = Object.assign(menu, { active: this.matchMenu(menu) });
      if (catalog && catalog.length > 0) {
        catalog[0].menus.push(newMenu);
      } else {
        menus.push({ catalog: menu.catalog, menus: [newMenu] });
      }
    });
    return menus;
  }
  matchMenu(menu) {
    const currentPath = locationService.getPathName();
    let matched = false;
    menu.relatedRoute?.map((route) => {
      if (currentPath.match(route)) {
        matched = true;
      }
    });
    return matched;
  }
  loadProjectMenus(p) {
    return [];
  }
  loadApplicationEnvMenus(p, app, env) {
    return [];
  }
}
export let menuService = new MenuWrapper();
