"use strict";
import { Upload, Button, Message, Field } from "@alifd/next";
import * as yaml from "js-yaml";
import React from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { v4 as uuid } from "uuid";
import DefinitionCode from "../../components/DefinitionCode";
import { If } from "../../components/If";
import { Translation } from "../../components/Translation";
class K8sObjectsCode extends React.Component {
  constructor(props) {
    super(props);
    this.componentDidMount = () => {
      const { value } = this.props;
      this.setValues(value);
    };
    this.setValues = (value) => {
      if (value) {
        try {
          let code = "---\n";
          if (value instanceof Array) {
            value.map((res) => {
              if (res) {
                code = code + yaml.dump(res) + "---\n";
              }
            });
          } else {
            code = yaml.dump(value) + "---\n";
          }
          this.form.setValues({ code });
        } catch {
        }
      }
    };
    this.onChange = (v) => {
      const { onChange, value } = this.props;
      if (onChange) {
        try {
          let object = yaml.load(v);
          if (!(object instanceof Array)) {
            object = [object];
          }
          object = object.filter((ob) => ob != null);
          if (yaml.dump(value) != v) {
            onChange(object);
          }
          this.setState({ message: "" });
        } catch (error) {
          if (error.message = "expected a single document in the stream, but found more") {
            try {
              let objects = yaml.loadAll(v);
              if (yaml.dump(value) != v) {
                objects = objects.filter((ob) => ob != null);
                onChange(objects);
              }
              this.setState({
                message: ""
              });
            } catch (err) {
              this.setState({ message: err.message });
            }
          } else {
            this.setState({ message: error.message });
          }
        }
      }
    };
    this.customRequest = (option) => {
      const reader = new FileReader();
      const fileselect = option.file;
      reader.readAsText(fileselect);
      reader.onload = () => {
        this.form.setValue("code", reader.result?.toString() || "");
      };
      return {
        file: File,
        abort() {
        }
      };
    };
    this.onConvert2WebService = () => {
    };
    this.state = {
      message: "",
      containerId: uuid(),
      showButton: false
    };
    this.form = new Field(this, {
      onChange: () => {
        const values = this.form.getValues();
        this.onChange(values.code);
      }
    });
  }
  render() {
    const { id } = this.props;
    const { init } = this.form;
    const { message, containerId, showButton } = this.state;
    return /* @__PURE__ */ React.createElement("div", { id }, /* @__PURE__ */ React.createElement(If, { condition: message }, /* @__PURE__ */ React.createElement("span", { style: { color: "red" } }, message)), /* @__PURE__ */ React.createElement(Message, { type: "notice", style: { marginTop: "16px" } }, /* @__PURE__ */ React.createElement(Translation, null, "The input data will be automatically formatted. Ensure that the input data is a valid k8s resource YAML.")), /* @__PURE__ */ React.createElement(Upload, { request: this.customRequest }, /* @__PURE__ */ React.createElement(Button, { text: true, type: "normal", className: "padding-left-0" }, /* @__PURE__ */ React.createElement(AiOutlineCloudUpload, null), /* @__PURE__ */ React.createElement(Translation, null, "Upload Yaml File"))), /* @__PURE__ */ React.createElement("div", { id: containerId, className: "guide-code" }, /* @__PURE__ */ React.createElement(DefinitionCode, { containerId, language: "yaml", readOnly: false, ...init("code") })), /* @__PURE__ */ React.createElement(If, { condition: showButton }, /* @__PURE__ */ React.createElement("div", { style: { marginTop: "16px" } }, /* @__PURE__ */ React.createElement("span", { style: { fontSize: "14px", color: "#000", marginRight: "16px" } }, /* @__PURE__ */ React.createElement(Translation, null, "Convert the kubernetes resource component to the webservice component?")), /* @__PURE__ */ React.createElement(Button, { type: "secondary", onClick: this.onConvert2WebService }, "Yes"))));
  }
}
export default K8sObjectsCode;
