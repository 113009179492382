"use strict";
import { Button, Message } from "@alifd/next";
import * as React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { detailRevision } from "../../../../api/application";
import DefinitionCode from "../../../../components/DefinitionCode";
import DrawerWithFooter from "../../../../components/Drawer";
import Empty from "../../../../components/Empty";
import { If } from "../../../../components/If";
import { Translation } from "../../../../components/Translation";
import i18n from "../../../../i18n";
function loadRevisionDetail(appName, revision, setDetail) {
  detailRevision({
    appName,
    revision: revision.version
  }).then((res) => {
    if (res) {
      setDetail(res);
    } else {
      setDetail(null);
    }
  });
}
export function nodeKey(resource, sep = "-") {
  return [resource.cluster || "", resource.kind || "", resource.namespace, resource.name].join(sep);
}
export const ShowRevision = (props) => {
  const [detail, setDetail] = React.useState();
  React.useEffect(() => {
    loadRevisionDetail(props.appName, props.revision, setDetail);
  }, [props.appName, props.revision]);
  const containerId = props.revision.version + "detail";
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    DrawerWithFooter,
    {
      title: `${props.appName}-${props.revision.version}`,
      placement: "right",
      width: 600,
      onClose: props.onClose,
      extButtons: /* @__PURE__ */ React.createElement(
        CopyToClipboard,
        {
          text: detail?.applyAppConfig || "",
          onCopy: () => {
            Message.success(i18n.t("The application configuration copied successfully"));
          }
        },
        /* @__PURE__ */ React.createElement(Button, null, /* @__PURE__ */ React.createElement(Translation, null, "Copy"))
      )
    },
    /* @__PURE__ */ React.createElement(If, { condition: detail }, /* @__PURE__ */ React.createElement("div", { id: containerId, style: { height: "calc(100vh - 100px)" } }, /* @__PURE__ */ React.createElement(
      DefinitionCode,
      {
        id: containerId + "content",
        containerId,
        language: "yaml",
        readOnly: true,
        value: detail?.applyAppConfig
      }
    ))),
    /* @__PURE__ */ React.createElement(If, { condition: !detail }, /* @__PURE__ */ React.createElement(Empty, { message: "Loading the revision" }))
  ));
};
