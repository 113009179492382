"use strict";
const definitions = {
  namespace: "definitions",
  state: {
    definitionTypes: [
      { name: "ComponentDefinition", type: "component" },
      { name: "PolicyDefinition", type: "policy" },
      { name: "TraitDefinition", type: "trait" },
      { name: "WorkflowStepDefinition", type: "workflowstep" }
    ]
  },
  reducers: {},
  effects: {}
};
export default definitions;
