"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Dialog, Field, Form, Grid, Input, Loading, Message, Select } from "@alifd/next";
import { connect } from "dva";
import React from "react";
import { createPipeline, createPipelineContext, listPipelineContexts, loadPipeline } from "../../../../api/pipeline";
import { If } from "../../../../components/If";
import { Translation } from "../../../../components/Translation";
import i18n from "../../../../i18n";
import { checkName } from "../../../../utils/common";
import { locale } from "../../../../utils/locale";
import { checkPermission } from "../../../../utils/permission";
const FormItem = Form.Item;
const { Row, Col } = Grid;
let ClonePipeline = class extends React.Component {
  constructor(props) {
    super(props);
    this.onLoadingPipeline = async (pipeline) => {
      loadPipeline({ projectName: pipeline.project.name, pipelineName: pipeline.name }).then((res) => {
        this.setState({ pipelineDetail: res });
      }).finally(() => {
        this.setState({ loading: false });
      });
    };
    this.onLoadingPipelineContexts = async (pipeline) => {
      listPipelineContexts(pipeline.project.name, pipeline.name).then((res) => {
        this.setState({ contexts: res && res.contexts ? res.contexts : {} });
      }).finally(() => {
        this.setState({ loadingContext: false });
      });
    };
    this.onSubmit = () => {
      const { pipelineDetail, contexts } = this.state;
      if (pipelineDetail) {
        this.setState({ cloneLoading: true });
        this.field.validate((errs, values) => {
          if (errs) {
            return;
          }
          const { name, project, alias, description } = values;
          const request = {
            project,
            alias,
            description,
            name,
            spec: pipelineDetail?.spec
          };
          createPipeline(request).then((res) => {
            if (res) {
              if (contexts) {
                Object.keys(contexts).map((key) => {
                  createPipelineContext(project, name, { name: key, values: contexts[key] });
                });
              }
              Message.success(i18n.t("Pipeline cloned successfully"));
              if (this.props.onSuccess) {
                this.props.onSuccess();
              }
            }
          }).catch(() => {
            this.setState({ cloneLoading: false });
          });
        });
      }
    };
    this.state = {
      loading: true,
      loadingContext: true
    };
    this.field = new Field(this);
  }
  componentDidMount() {
    const { pipeline } = this.props;
    if (pipeline) {
      this.onLoadingPipeline(pipeline);
      this.onLoadingPipelineContexts(pipeline);
    }
  }
  render() {
    const { loading, pipelineDetail, loadingContext, contexts, cloneLoading } = this.state;
    const { userInfo } = this.props;
    const { init } = this.field;
    const projectOptions = [];
    (userInfo?.projects || []).map((project) => {
      if (checkPermission({ resource: `project:${project.name}/pipeline:*`, action: "create" }, project.name, userInfo)) {
        projectOptions.push({
          label: project.alias ? `${project.alias}(${project.name})` : project.name,
          value: project.name
        });
      }
    });
    const message = contexts ? i18n.t("Includes") + ` ${Object.keys(contexts).length} ` + i18n.t("contexts") : "";
    return /* @__PURE__ */ React.createElement(
      Dialog,
      {
        onOk: this.onSubmit,
        okProps: {
          loading: cloneLoading
        },
        onClose: this.props.onClose,
        onCancel: this.props.onClose,
        locale: locale().Dialog,
        visible: true,
        v2: true,
        title: "Clone Pipeline"
      },
      /* @__PURE__ */ React.createElement(Loading, { visible: loading || loadingContext }, /* @__PURE__ */ React.createElement(If, { condition: pipelineDetail && contexts }, /* @__PURE__ */ React.createElement(Message, { type: "success", title: i18n.t("Pipeline loaded successfully and is ready to clone.") + message }), /* @__PURE__ */ React.createElement(Form, { field: this.field }, /* @__PURE__ */ React.createElement(Row, { wrap: true }, /* @__PURE__ */ React.createElement(Col, { span: 8, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { required: true, label: /* @__PURE__ */ React.createElement(Translation, null, "Name") }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "name",
          ...init("name", {
            initValue: pipelineDetail?.name && pipelineDetail?.name + "-clone",
            rules: [
              {
                pattern: checkName,
                message: "Please input a valid name"
              },
              {
                required: true,
                message: "Please input a name"
              }
            ]
          })
        }
      ))), /* @__PURE__ */ React.createElement(Col, { span: 8, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Project"), required: true }, /* @__PURE__ */ React.createElement(
        Select,
        {
          name: "project",
          placeholder: i18n.t("Please select a project").toString(),
          dataSource: projectOptions,
          filterLocal: true,
          hasClear: true,
          style: { width: "100%" },
          ...init("project", {
            initValue: pipelineDetail?.project.name,
            rules: [
              {
                required: true,
                message: "Please select a project"
              }
            ]
          })
        }
      ))), /* @__PURE__ */ React.createElement(Col, { span: 8, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Alias") }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "alias",
          placeholder: i18n.t("Give your pipeline a more recognizable name").toString(),
          ...init("alias", {
            initValue: pipelineDetail?.alias,
            rules: [
              {
                minLength: 2,
                maxLength: 64,
                message: "Enter a string of 2 to 64 characters."
              }
            ]
          })
        }
      ))), /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Description") }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "description",
          ...init("description", {
            initValue: pipelineDetail?.description,
            rules: [
              {
                maxLength: 128,
                message: "Enter a description less than 128 characters."
              }
            ]
          })
        }
      )))))), /* @__PURE__ */ React.createElement(If, { condition: !pipelineDetail }, /* @__PURE__ */ React.createElement(Message, { type: "notice", title: i18n.t("Pipeline loading").toString() })))
    );
  }
};
ClonePipeline = __decorateClass([
  connect((store) => {
    return { ...store.user };
  })
], ClonePipeline);
export default ClonePipeline;
