"use strict";
import { Message, Grid, Dialog, Form, Input, Field, Select, Loading, Button, Table } from "@alifd/next";
import React from "react";
import { getClusterList } from "../../../../api/cluster";
import { createEnv, updateEnv } from "../../../../api/env";
import { listNamespaces } from "../../../../api/observation";
import { getProjectTargetList } from "../../../../api/project";
import { If } from "../../../../components/If";
import Permission from "../../../../components/Permission";
import { Translation } from "../../../../components/Translation";
import i18n from "../../../../i18n";
import { checkName } from "../../../../utils/common";
import { locale } from "../../../../utils/locale";
import { checkPermission } from "../../../../utils/permission";
import TargetDialog from "../../../TargetList/components/TargetDialog";
class EnvDialog extends React.Component {
  constructor(props) {
    super(props);
    this.loadClusters = () => {
      if (checkPermission({ resource: "cluster", action: "list" }, "", this.props.userInfo)) {
        getClusterList({}).then((res) => {
          if (res) {
            this.setState({ clusterList: res.clusters });
          }
        });
      }
    };
    this.onClose = () => {
      this.props.onClose();
      this.resetField();
    };
    this.loadProjectTarget = async (projectName, callback) => {
      this.setState({ targetLoading: true });
      getProjectTargetList({ projectName }).then((res) => {
        if (res) {
          this.setState({ targets: res.targets, targetLoading: false }, callback);
        }
      });
    };
    this.makeTargetDataSource = () => {
      const { targets } = this.state;
      const selectTargets = this.field.getValue("targets");
      const targetMap = /* @__PURE__ */ new Map();
      if (!targets || !selectTargets) {
        return [];
      }
      targets?.map((t) => {
        targetMap.set(t.name, t);
      });
      const data = selectTargets.filter((t) => targetMap.get(t)).map((t) => {
        return targetMap.get(t);
      });
      return data;
    };
    this.onOk = () => {
      this.field.validate((error, values) => {
        if (error) {
          return;
        }
        this.setState({ submitLoading: true });
        const { isEdit } = this.props;
        const { name, alias, description, targets, namespace, project } = values;
        const params = {
          name,
          alias,
          description,
          namespace,
          project: project || "default",
          targets
        };
        if (isEdit) {
          updateEnv(params).then((res) => {
            this.setState({ submitLoading: false });
            if (res) {
              Message.success(/* @__PURE__ */ React.createElement(Translation, null, "Environment updated successfully"));
              this.props.onOK();
              this.onClose();
            }
          });
        } else {
          createEnv(params).then((res) => {
            this.setState({ submitLoading: false });
            if (res) {
              Message.success(/* @__PURE__ */ React.createElement(Translation, null, "Environment created successfully"));
              this.props.onOK();
              this.onClose();
            }
          });
        }
      });
    };
    this.convertTarget = () => {
      const { targets } = this.state;
      return (targets || []).map((target) => ({
        label: `${target.alias || target.name}(${target.cluster?.clusterName}/${target.cluster?.namespace})`,
        value: target.name
      }));
    };
    this.loadNamespaces = async (cluster) => {
      if (cluster) {
        listNamespaces({ cluster }).then((re) => {
          if (re && re.list) {
            const namespaces = re.list.map((item) => {
              return { label: item.metadata.name, value: item.metadata.name };
            });
            this.setState({ namespaces });
          }
        });
      }
    };
    this.showNewTarget = () => {
      this.setState({ newTarget: true });
    };
    this.field = new Field(this, {
      onChange: (name, value) => {
        if (name == "project") {
          this.loadProjectTarget(value);
        }
      }
    });
    this.state = {
      targetLoading: false,
      submitLoading: false,
      newTarget: false
    };
  }
  componentDidMount() {
    const { envItem, isEdit } = this.props;
    let projectName = this.props.project;
    if (envItem && isEdit) {
      const { name, alias, description, targets, namespace, project } = envItem;
      const targetNames = targets?.map((target) => {
        return target.name;
      });
      projectName = project.name;
      this.field.setValues({
        name,
        alias,
        namespace,
        description,
        targets: targetNames,
        project: project.name
      });
    }
    if (projectName) {
      this.loadProjectTarget(projectName);
    }
    this.loadClusters();
  }
  resetField() {
    this.field.setValues({
      name: "",
      alias: "",
      description: "",
      project: "",
      targets: [],
      namespace: ""
    });
  }
  render() {
    const { Col, Row } = Grid;
    const FormItem = Form.Item;
    const init = this.field.init;
    const formItemLayout = {
      labelCol: {
        fixedSpan: 6
      },
      wrapperCol: {
        span: 18
      }
    };
    const { visible, isEdit, projects, userInfo } = this.props;
    const { targetLoading, submitLoading, newTarget, clusterList } = this.state;
    const projectOptions = [];
    (projects || []).map((project) => {
      if (checkPermission({ resource: `project:${project.name}/environment:*`, action: "create" }, project.name, userInfo)) {
        projectOptions.push({
          label: project.alias ? `${project.alias}(${project.name})` : project.name,
          value: project.name
        });
      }
    });
    return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
      Dialog,
      {
        v2: true,
        locale: locale().Dialog,
        title: isEdit ? /* @__PURE__ */ React.createElement(Translation, null, "Edit Environment") : /* @__PURE__ */ React.createElement(Translation, null, "New Environment"),
        autoFocus: true,
        overflowScroll: true,
        visible,
        onOk: this.onOk,
        onCancel: this.onClose,
        onClose: this.onClose,
        footerActions: ["cancel", "ok"],
        footer: /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Button, { onClick: this.onOk, type: "primary", loading: submitLoading }, /* @__PURE__ */ React.createElement(Translation, null, "OK")))
      },
      /* @__PURE__ */ React.createElement(Form, { ...formItemLayout, field: this.field }, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Name"), required: true }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "name",
          disabled: isEdit,
          placeholder: i18n.t("Please enter the Environment name").toString(),
          ...init("name", {
            rules: [
              {
                required: true,
                pattern: checkName,
                message: /* @__PURE__ */ React.createElement(Translation, null, "Please enter a valid name contains only alphabetical words")
              }
            ]
          })
        }
      ))), /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Alias") }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "alias",
          placeholder: i18n.t("Please enter the Environment alias").toString(),
          ...init("alias", {
            rules: [
              {
                minLength: 2,
                maxLength: 64,
                message: /* @__PURE__ */ React.createElement(Translation, null, "Enter a string of 2 to 64 characters")
              }
            ]
          })
        }
      )))), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Environment Namespace") }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "namespace",
          disabled: isEdit,
          placeholder: i18n.t("By default, it is the same as the Environment name").toString(),
          ...init("namespace", {
            rules: [
              {
                pattern: checkName,
                message: /* @__PURE__ */ React.createElement(Translation, null, "please enter a valid English name")
              }
            ]
          })
        }
      ))), /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Description") }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "description",
          placeholder: i18n.t("Please enter the Environment description").toString(),
          ...init("description", {
            rules: [
              {
                maxLength: 256,
                message: /* @__PURE__ */ React.createElement(Translation, null, "Enter a description that contains less than 256 characters")
              }
            ]
          })
        }
      )))), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Project"), required: true }, /* @__PURE__ */ React.createElement(
        Select,
        {
          name: "project",
          hasClear: true,
          showSearch: true,
          placeholder: i18n.t("Please select a project").toString(),
          filterLocal: true,
          dataSource: projectOptions,
          style: { width: "100%" },
          ...init("project", {
            rules: [
              {
                required: true,
                message: i18n.t("Please select a project")
              }
            ],
            initValue: this.props.project
          })
        }
      )))), /* @__PURE__ */ React.createElement(If, { condition: this.field.getValue("project") }, /* @__PURE__ */ React.createElement(Row, { wrap: true }, /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(Loading, { visible: targetLoading, style: { width: "100%" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Target"), required: true }, /* @__PURE__ */ React.createElement(
        Select,
        {
          locale: locale().Select,
          className: "select",
          mode: "multiple",
          placeholder: i18n.t("Please select a target").toString(),
          ...init(`targets`, {
            rules: [
              {
                required: true,
                message: "Please select at least one target"
              }
            ],
            initValue: []
          }),
          dataSource: this.convertTarget()
        }
      )))), /* @__PURE__ */ React.createElement(
        Permission,
        {
          request: { resource: "target:*", action: "create" },
          project: this.field.getValue("project")
        },
        /* @__PURE__ */ React.createElement(Col, { className: "flexright" }, /* @__PURE__ */ React.createElement(Button, { onClick: this.showNewTarget, type: "secondary", style: { marginBottom: "16px" } }, /* @__PURE__ */ React.createElement(Translation, null, "New Target")))
      )), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(Table, { locale: locale().Table, dataSource: this.makeTargetDataSource() }, /* @__PURE__ */ React.createElement(
        Table.Column,
        {
          dataIndex: "name",
          title: i18n.t("Name").toString(),
          cell: (v, i, t) => {
            if (t.alias) {
              return `${t.name}(${t.alias})`;
            }
            return t.name;
          }
        }
      ), /* @__PURE__ */ React.createElement(Table.Column, { dataIndex: "cluster.clusterName", title: i18n.t("Cluster").toString() }), /* @__PURE__ */ React.createElement(Table.Column, { dataIndex: "cluster.namespace", title: i18n.t("Namespace").toString() }))))))
    ), /* @__PURE__ */ React.createElement(If, { condition: newTarget }, /* @__PURE__ */ React.createElement(
      TargetDialog,
      {
        visible: newTarget,
        clusterList: clusterList || [],
        isEdit: false,
        liteMode: true,
        project: this.field.getValue("project"),
        onClose: () => {
          this.setState({ newTarget: false });
        },
        onOK: (name) => {
          if (this.field.getValue("project")) {
            this.loadProjectTarget(this.field.getValue("project"), () => {
              const existTarget = this.field.getValue("targets") || [];
              existTarget.push(name);
              this.field.setValue("targets", existTarget);
            });
          }
          this.setState({
            newTarget: false
          });
        }
      }
    )));
  }
}
export default EnvDialog;
