"use strict";
import React from "react";
import { Router, Switch, Redirect } from "dva/router";
import Routes from "./routes";
import AuthRoute from "../components/AuthRoute";
function RouterView(props) {
  const routes = props.routes ? props.routes : Routes;
  const { history } = props;
  const defaultRoute = /* @__PURE__ */ React.createElement(Redirect, { from: "/", to: "/applications", key: "default", exact: true });
  return /* @__PURE__ */ React.createElement(Router, { history }, /* @__PURE__ */ React.createElement(Switch, null, routes.map((item) => {
    const Comp = item.component;
    return /* @__PURE__ */ React.createElement(
      AuthRoute,
      {
        key: item.name,
        path: item.path,
        history,
        component: () => {
          return /* @__PURE__ */ React.createElement(Comp, { history });
        }
      }
    );
  }).concat([defaultRoute])));
}
export default RouterView;
