"use strict";
import { Dialog, Grid, Checkbox, Dropdown, Menu, Button, Icon } from "@alifd/next";
import React, { Component } from "react";
import { If } from "../../../../components/If";
import { Translation } from "../../../../components/Translation";
import { momentDate, momentShortDate } from "../../../../utils/common";
import { downloadStringFile } from "../../../../utils/utils";
import { locale } from "../../../../utils/locale";
import "./index.less";
import { listContainerLog } from "../../../../api/observation";
import Ansi from "ansi-to-react";
import { FaEllipsisV } from "react-icons/fa";
const { Row, Col } = Grid;
class ContainerLog extends Component {
  constructor(props) {
    super(props);
    this.maxTailLine = 3e3;
    this.toLogLines = (logs) => {
      const logLines = logs.split("\n");
      const lines = [];
      logLines.map((line) => {
        if (line) {
          const startsWithDate = 0 <= Number(line[0]) && Number(line[0]) <= 9;
          const idx = line.indexOf(" ");
          if (idx > 0 && startsWithDate) {
            const timestamp = line.substring(0, idx);
            const content = line.substring(idx + 1);
            lines.push({ content, timestamp });
          } else {
            lines.push({ content: line, timestamp: "" });
          }
        }
      });
      return lines;
    };
    this.loadContainerLog = () => {
      const { pod, containerName, clusterName } = this.props;
      const { previous } = this.state;
      listContainerLog({
        cluster: clusterName,
        namespace: pod.metadata.namespace,
        pod: pod.metadata.name,
        container: containerName,
        previous,
        timestamps: true,
        tailLines: this.maxTailLine
      }).then((res) => {
        if (res && res.logs) {
          this.setState({ logs: this.toLogLines(res.logs), info: res.info });
        }
        if (res && res.err) {
          this.setState({ logs: this.toLogLines(res.err) });
        }
        const { autoRefresh, refreshInterval } = this.state;
        if (autoRefresh) {
          this.timeoutID = setTimeout(() => this.loadContainerLog(), refreshInterval);
        }
      }).catch(() => {
      });
    };
    this.setAutoRefresh = (v) => {
      this.setState({ autoRefresh: v });
      if (!v && this.timeoutID) {
        clearTimeout(this.timeoutID);
        this.timeoutID = void 0;
      }
      if (v && !this.timeoutID) {
        this.loadContainerLog();
      }
    };
    this.downloadLog = () => {
      const { pod, containerName = "" } = this.props;
      const { logs } = this.state;
      let logContent = [];
      logs.map((line) => {
        logContent.push(line.content);
      });
      downloadStringFile(logContent.join("\n"), pod?.metadata.name + "-" + containerName);
    };
    this.state = {
      autoRefresh: true,
      refreshInterval: 5e3,
      logs: [],
      showTimestamps: false,
      previous: false
    };
  }
  componentDidMount() {
    this.loadContainerLog();
  }
  componentWillUnmount() {
    if (this.timeoutID) {
      clearTimeout(this.timeoutID);
    }
  }
  render() {
    const { logs, info, showTimestamps, autoRefresh, refreshInterval, previous } = this.state;
    return /* @__PURE__ */ React.createElement(
      Dialog,
      {
        className: "logDialog",
        locale: locale().Dialog,
        visible: true,
        footerActions: [],
        onClose: this.props.onClose,
        overflowScroll: true,
        v2: true,
        title: /* @__PURE__ */ React.createElement(Row, { style: { width: "100%" } }, /* @__PURE__ */ React.createElement(Col, { span: 12 }, /* @__PURE__ */ React.createElement(Translation, null, "Container Log")), /* @__PURE__ */ React.createElement(Col, { span: 12 }, /* @__PURE__ */ React.createElement(Button, { style: { float: "right" }, type: "normal", size: "small", onClick: this.downloadLog }, /* @__PURE__ */ React.createElement(Icon, { type: "download" })))),
        footer: /* @__PURE__ */ React.createElement(Row, { style: { width: "100%" } }, /* @__PURE__ */ React.createElement(Col, { span: 12 }, /* @__PURE__ */ React.createElement("span", { style: { float: "left" } }, /* @__PURE__ */ React.createElement(Translation, null, "Logs Date"), ":", /* @__PURE__ */ React.createElement("span", { style: { marginLeft: "8px" }, className: "logDate", title: momentDate(info?.fromDate) }, momentShortDate(info?.fromDate)), "~", /* @__PURE__ */ React.createElement("span", { className: "logDate", title: momentDate(info?.toDate) }, momentShortDate(info?.toDate)))), /* @__PURE__ */ React.createElement(Col, { span: 12 }, /* @__PURE__ */ React.createElement("div", { className: "logAction" }, /* @__PURE__ */ React.createElement(Checkbox, { checked: showTimestamps, onChange: (v) => this.setState({ showTimestamps: v }) }, /* @__PURE__ */ React.createElement(Translation, null, "Show timestamps")), /* @__PURE__ */ React.createElement(Checkbox, { checked: autoRefresh, onChange: this.setAutoRefresh }, /* @__PURE__ */ React.createElement(Translation, null, "Auto-refresh"), "(every ", refreshInterval / 1e3, " s.)"), /* @__PURE__ */ React.createElement(Dropdown, { trigger: /* @__PURE__ */ React.createElement(FaEllipsisV, null) }, /* @__PURE__ */ React.createElement(Menu, null, /* @__PURE__ */ React.createElement(Menu.Item, null, /* @__PURE__ */ React.createElement(Checkbox, { checked: previous, onChange: (v) => this.setState({ previous: v }) }, /* @__PURE__ */ React.createElement(Translation, null, "Show previous logs"))))))))
      },
      /* @__PURE__ */ React.createElement("div", { className: "logBox" }, logs.map((line) => {
        return /* @__PURE__ */ React.createElement("div", { className: "logLine" }, /* @__PURE__ */ React.createElement("span", { className: "content" }, /* @__PURE__ */ React.createElement(If, { condition: showTimestamps }, momentDate(line.timestamp), " | ", /* @__PURE__ */ React.createElement(Ansi, null, line.content)), /* @__PURE__ */ React.createElement(If, { condition: !showTimestamps }, /* @__PURE__ */ React.createElement(Ansi, null, line.content))));
      }))
    );
  }
}
export default ContainerLog;
