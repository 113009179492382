"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Grid } from "@alifd/next";
import { connect } from "dva";
import React, { Component } from "react";
import Empty from "../../components/Empty";
import { ListTitle } from "../../components/ListTitle";
import { getMatchParamObj } from "../../utils/utils";
import Menu from "./components/Menu";
import "./index.less";
import { If } from "../../components/If";
let DefinitionsLayout = class extends Component {
  constructor(props) {
    super(props);
    this.getDefinitionType = () => {
      return getMatchParamObj(this.props.match, "definitionType");
    };
    this.state = {
      activeType: this.getDefinitionType(),
      definitionTypes: props.definitionTypes
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname != this.props.location.pathname) {
      const nextPropsParams = nextProps.match.params || {};
      this.setState({
        activeType: nextPropsParams.definitionType
      });
    }
  }
  render() {
    const { Row, Col } = Grid;
    const { definitionTypes } = this.state;
    const { activeType } = this.state;
    return /* @__PURE__ */ React.createElement("div", { className: "definitions-wrapper" }, /* @__PURE__ */ React.createElement(
      ListTitle,
      {
        title: "Definitions",
        subTitle: "This is achieved by implementing its application model as programmable entities"
      }
    ), /* @__PURE__ */ React.createElement(If, { condition: !activeType }, /* @__PURE__ */ React.createElement(Empty, { style: { marginTop: "40px" } })), /* @__PURE__ */ React.createElement(If, { condition: activeType }, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: "5" }, /* @__PURE__ */ React.createElement(Menu, { activeType, definitionTypes })), /* @__PURE__ */ React.createElement(Col, { span: "19" }, this.props.children))));
  }
};
DefinitionsLayout = __decorateClass([
  connect((store) => {
    return { ...store.definitions };
  })
], DefinitionsLayout);
export default DefinitionsLayout;
