"use strict";
import { Card } from "@alifd/next";
import React, { Component } from "react";
import { Translation } from "../../../../components/Translation";
import "./index.less";
import { Link } from "dva/router";
import { locale } from "../../../../utils/locale";
class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }
  componentDidMount() {
  }
  render() {
    const { envName, appName, currentPath } = this.props;
    const isEnvPage = envName != void 0;
    const menuItems = {
      configPage: [
        {
          key: "config",
          label: /* @__PURE__ */ React.createElement(Translation, null, "Properties"),
          to: `/applications/${appName}/config`
        },
        {
          key: "revisions",
          label: /* @__PURE__ */ React.createElement(Translation, null, "Revisions"),
          to: `/applications/${appName}/revisions`
        },
        {
          key: "workflows",
          label: /* @__PURE__ */ React.createElement(Translation, null, "Workflows"),
          to: `/applications/${appName}/workflows`
        }
      ],
      envPage: [
        {
          key: "workflow",
          label: /* @__PURE__ */ React.createElement(Translation, null, "Workflow"),
          to: `/applications/${appName}/envbinding/${envName}/workflow`
        },
        {
          key: "status",
          label: /* @__PURE__ */ React.createElement(Translation, null, "Status"),
          to: `/applications/${appName}/envbinding/${envName}/status`
        },
        {
          key: "instances",
          label: /* @__PURE__ */ React.createElement(Translation, null, "Instances"),
          to: `/applications/${appName}/envbinding/${envName}/instances`
        },
        {
          key: "logs",
          label: /* @__PURE__ */ React.createElement(Translation, null, "Logs"),
          to: `/applications/${appName}/envbinding/${envName}/logs`
        }
      ]
    };
    let activeItems = menuItems.configPage;
    if (isEnvPage) {
      activeItems = menuItems.envPage;
    }
    const activeKey = currentPath.substring(currentPath.lastIndexOf("/") + 1);
    return /* @__PURE__ */ React.createElement(Card, { locale: locale().Card, contentHeight: "100px", className: "app-menu" }, activeItems.map((item) => {
      return /* @__PURE__ */ React.createElement(
        Link,
        {
          key: item.key,
          to: item.to,
          className: item.key === activeKey || currentPath.startsWith(item.to) ? "menu-item-active" : "menu-item"
        },
        item.label
      );
    }));
  }
}
export default Menu;
