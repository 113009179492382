"use strict";
import * as dagre from "dagre";
import * as React from "react";
import "dagre-compound";
import kubevela from "../../assets/KubeVela-01.svg";
import kubernetes from "../../assets/kubernetes.svg";
import pod from "../../assets/resources/pod.svg";
import i18n from "../../i18n";
import "./index.less";
import classNames from "classnames";
import { If } from "../If";
import { ComponentNode } from "./component-node";
import {
  describeNode,
  describeCluster,
  treeNodeKey,
  getGraphSize,
  getNodeSize,
  ResourceIcon,
  describeTarget
} from "./utils";
import { Link } from "dva/router";
import { Dropdown, Menu, Tag, Balloon } from "@alifd/next";
import { FaEllipsisV } from "react-icons/fa";
import { HiOutlineNewspaper } from "react-icons/hi";
function renderResourceNode(props, id, node) {
  const graphNode = /* @__PURE__ */ React.createElement(
    "div",
    {
      key: id,
      className: classNames("graph-node", "graph-node-resource", {
        "error-status": node.resource.healthStatus?.statusCode == "UnHealthy",
        "warning-status": node.resource.healthStatus?.statusCode == "Progressing"
      }),
      style: {
        left: node.x,
        top: node.y,
        width: node.width,
        height: node.height,
        transform: `translate(-80px, 0px)`
      }
    },
    /* @__PURE__ */ React.createElement("div", { className: classNames("icon") }, /* @__PURE__ */ React.createElement(ResourceIcon, { kind: node.resource.kind || "" })),
    /* @__PURE__ */ React.createElement("div", { className: classNames("name") }, /* @__PURE__ */ React.createElement("div", null, node.resource.name), /* @__PURE__ */ React.createElement("div", { className: "kind" }, node.resource.kind)),
    /* @__PURE__ */ React.createElement("div", { className: classNames("actions") }, /* @__PURE__ */ React.createElement(Dropdown, { trigger: /* @__PURE__ */ React.createElement(FaEllipsisV, null) }, /* @__PURE__ */ React.createElement(Menu, null, /* @__PURE__ */ React.createElement(Menu.Item, { onClick: () => props.onResourceDetailClick(node.resource) }, "Detail")))),
    /* @__PURE__ */ React.createElement(If, { condition: node.resource.kind === "Service" && node.resource.additionalInfo?.EIP }, /* @__PURE__ */ React.createElement("div", { className: classNames("additional") }, /* @__PURE__ */ React.createElement(Tag, { size: "small", color: "orange" }, "EIP: ", node.resource.additionalInfo?.EIP)))
  );
  return /* @__PURE__ */ React.createElement(Balloon, { trigger: graphNode }, /* @__PURE__ */ React.createElement("div", null, describeNode(node).map((line) => {
    return /* @__PURE__ */ React.createElement("p", { className: "line" }, line);
  })));
}
function renderAppNode(props, id, node) {
  const graphNode = /* @__PURE__ */ React.createElement(
    "div",
    {
      key: id,
      className: classNames("graph-node", "graph-node-app"),
      style: {
        left: node.x,
        top: node.y,
        width: node.width,
        height: node.height,
        transform: `translate(-60px, 0px)`
      }
    },
    /* @__PURE__ */ React.createElement("div", { className: classNames("icon") }, /* @__PURE__ */ React.createElement("img", { src: kubevela })),
    /* @__PURE__ */ React.createElement("div", { className: classNames("name") }, /* @__PURE__ */ React.createElement("span", null, node.resource.name)),
    /* @__PURE__ */ React.createElement("div", { className: classNames("actions") }, /* @__PURE__ */ React.createElement(Dropdown, { trigger: /* @__PURE__ */ React.createElement(FaEllipsisV, null) }, /* @__PURE__ */ React.createElement(Menu, null, /* @__PURE__ */ React.createElement(Menu.Item, { onClick: () => props.onResourceDetailClick(node.resource) }, "Detail"))))
  );
  return /* @__PURE__ */ React.createElement(Balloon, { trigger: graphNode }, /* @__PURE__ */ React.createElement("div", null, describeNode(node).map((line) => {
    return /* @__PURE__ */ React.createElement("p", { className: "line" }, line);
  })));
}
function renderPodNode(props, id, node) {
  const { appName, envName } = props;
  const graphNode = /* @__PURE__ */ React.createElement(
    "div",
    {
      key: id,
      className: classNames("graph-node", "graph-node-pod", {
        "error-status": node.resource.healthStatus?.statusCode == "UnHealthy",
        "warning-status": node.resource.healthStatus?.statusCode == "Progressing"
      }),
      style: {
        left: node.x,
        top: node.y,
        width: node.width,
        height: node.height,
        transform: `translate(-80px, 0px)`
      }
    },
    /* @__PURE__ */ React.createElement("div", { className: classNames("icon") }, /* @__PURE__ */ React.createElement("img", { src: pod }), /* @__PURE__ */ React.createElement("span", null, "Pod")),
    /* @__PURE__ */ React.createElement("div", { className: classNames("name") }, /* @__PURE__ */ React.createElement(Link, { to: `/applications/${appName}/envbinding/${envName}/instances?pod=${node.resource.name}` }, node.resource.name), /* @__PURE__ */ React.createElement("div", { className: classNames("actions") }, /* @__PURE__ */ React.createElement(Link, { to: `/applications/${appName}/envbinding/${envName}/logs?pod=${node.resource.name}` }, /* @__PURE__ */ React.createElement(HiOutlineNewspaper, { title: i18n.t("Logger") })))),
    /* @__PURE__ */ React.createElement("div", { className: classNames("actions") }, /* @__PURE__ */ React.createElement(Dropdown, { trigger: /* @__PURE__ */ React.createElement(FaEllipsisV, null) }, /* @__PURE__ */ React.createElement(Menu, null, /* @__PURE__ */ React.createElement(Menu.Item, { onClick: () => props.onResourceDetailClick(node.resource) }, "Detail")))),
    /* @__PURE__ */ React.createElement("div", { className: classNames("additional") }, /* @__PURE__ */ React.createElement(Tag, { size: "small", color: "orange" }, "Ready: ", node.resource.additionalInfo?.Ready))
  );
  return /* @__PURE__ */ React.createElement(Balloon, { trigger: graphNode }, /* @__PURE__ */ React.createElement("div", null, describeNode(node).map((line) => {
    return /* @__PURE__ */ React.createElement("p", { className: "line" }, line);
  })));
}
function renderClusterNode(props, id, node) {
  const graphNode = /* @__PURE__ */ React.createElement(
    "div",
    {
      className: classNames("graph-node", "graph-node-cluster"),
      style: {
        left: node.x,
        top: node.y,
        width: node.width,
        height: node.height,
        transform: `translate(-40px, 0px)`
      }
    },
    /* @__PURE__ */ React.createElement("div", { className: "icon" }, /* @__PURE__ */ React.createElement("img", { src: kubernetes })),
    /* @__PURE__ */ React.createElement("div", { className: classNames("name") }, /* @__PURE__ */ React.createElement("div", null, node.resource.name), /* @__PURE__ */ React.createElement("div", { className: "kind" }, "Cluster"))
  );
  return /* @__PURE__ */ React.createElement(Balloon, { trigger: graphNode }, /* @__PURE__ */ React.createElement("div", null, describeCluster(node).map((line) => {
    return /* @__PURE__ */ React.createElement("p", { className: "line" }, line);
  })));
}
function renderTargetNode(props, id, node) {
  const graphNode = /* @__PURE__ */ React.createElement(
    "div",
    {
      className: classNames("graph-node", "graph-node-cluster"),
      style: {
        left: node.x - 30,
        top: node.y,
        width: node.width,
        height: node.height,
        transform: `translate(-40px, 0px)`
      }
    },
    /* @__PURE__ */ React.createElement("div", { className: "icon" }, /* @__PURE__ */ React.createElement("img", { src: kubernetes })),
    /* @__PURE__ */ React.createElement("div", { className: classNames("name") }, /* @__PURE__ */ React.createElement("div", null, node.resource.name), /* @__PURE__ */ React.createElement("div", { className: "kind" }, "Target"))
  );
  return /* @__PURE__ */ React.createElement(Balloon, { trigger: graphNode }, /* @__PURE__ */ React.createElement("div", null, describeTarget(node).map((line) => {
    return /* @__PURE__ */ React.createElement("p", { className: "line" }, line);
  })));
}
function setNode(graph, node) {
  const size = getNodeSize(node);
  graph.setNode(treeNodeKey(node), {
    ...node,
    width: size.width,
    height: size.height,
    x: 0,
    y: 0
  });
  node.leafNodes?.map((subNode) => {
    if (treeNodeKey(node) == treeNodeKey(subNode)) {
      return;
    }
    graph.setEdge(treeNodeKey(node), treeNodeKey(subNode), {});
    setNode(graph, subNode);
  });
}
export const TreeGraph = (props) => {
  const graph = new dagre.graphlib.Graph();
  graph.setGraph({
    nodesep: props.nodesep,
    rankdir: "LR"
  });
  setNode(graph, props.node);
  dagre.layout(graph);
  const edges = [];
  graph.edges().forEach((edgeInfo) => {
    const edge = graph.edge(edgeInfo);
    const lines = [];
    if (edge.points && edge.points.length > 1) {
      for (let i = 1; i < edge.points.length; i++) {
        lines.push({
          x1: edge.points[i - 1].x,
          y1: edge.points[i - 1].y,
          x2: edge.points[i].x,
          y2: edge.points[i].y
        });
      }
    }
    edges.push({
      from: edgeInfo.v,
      to: edgeInfo.w,
      lines
    });
  });
  const graphNodes = graph.nodes();
  const size = getGraphSize(graphNodes.map((id) => graph.node(id)));
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: "graph-tree",
      style: {
        width: size.width + 500,
        height: size.height + 150,
        transformOrigin: "0% 0%",
        transform: `scale(${props.zoom})`
      }
    },
    graphNodes.map((key) => {
      const node = graph.node(key);
      const nodeType = node.nodeType;
      switch (nodeType) {
        case "app":
          return /* @__PURE__ */ React.createElement(React.Fragment, { key }, renderAppNode(props, key, node));
        case "cluster":
          return /* @__PURE__ */ React.createElement(React.Fragment, { key }, renderClusterNode(props, key, node));
        case "target":
          return /* @__PURE__ */ React.createElement(React.Fragment, { key }, renderTargetNode(props, key, node));
        case "pod":
          return /* @__PURE__ */ React.createElement(React.Fragment, { key }, renderPodNode(props, key, node));
        case "component":
          return /* @__PURE__ */ React.createElement(ComponentNode, { key, node, showTrait: true });
        default:
          return /* @__PURE__ */ React.createElement(React.Fragment, { key }, renderResourceNode(props, key, node));
      }
    }),
    edges.map((edge) => /* @__PURE__ */ React.createElement("div", { key: `${edge.from}-${edge.to}`, className: "graph-edge" }, edge.lines.map((line) => {
      const distance = Math.sqrt(Math.pow(line.x1 - line.x2, 2) + Math.pow(line.y1 - line.y2, 2));
      const xMid = (line.x1 + line.x2) / 2;
      const yMid = (line.y1 + line.y2) / 2;
      const angle = Math.atan2(line.y1 - line.y2, line.x1 - line.x2) * 180 / Math.PI;
      return /* @__PURE__ */ React.createElement(
        "div",
        {
          className: "graph-edge-line",
          key: "line" + line.x2 + line.y2,
          style: {
            width: distance,
            left: xMid - distance / 2,
            top: yMid,
            transform: `translate(40px, 30px) rotate(${angle}deg)`
          }
        }
      );
    })))
  );
};
