"use strict";
import React from "react";
const Item = (props) => {
  return /* @__PURE__ */ React.createElement("div", { style: { marginBottom: props.marginBottom || "16px", display: "flex" } }, /* @__PURE__ */ React.createElement(
    "div",
    {
      style: {
        width: props.labelWidth || "140px",
        display: "flex",
        justifyItems: "center",
        alignItems: "center"
      }
    },
    /* @__PURE__ */ React.createElement("span", { style: { fontSize: "14px", color: "#a6a6a6" } }, props.label, ":")
  ), /* @__PURE__ */ React.createElement("div", { style: { fontSize: "14px", display: "flex", flex: 1 } }, props.value));
};
export default Item;
