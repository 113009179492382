"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Card, Form, Select, Grid, Field, Balloon, Message } from "@alifd/next";
import classNames from "classnames";
import { connect } from "dva";
import React from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { getApplicationComponent } from "../../api/application";
import { CustomSelect } from "../../components/CustomSelect";
import { If } from "../../components/If";
import { Translation } from "../../components/Translation";
import i18n from "../../i18n";
import { locale } from "../../utils/locale";
const { Col, Row } = Grid;
let ComponentPatch = class extends React.Component {
  constructor(props) {
    super(props);
    this.componentDidMount = () => {
      this.onLoadComponents();
    };
    this.onSubmit = () => {
      this.form.validate((error, res) => {
        if (error) {
          return;
        }
        this.props.onChange({ ...res });
      });
    };
    this.onLoadComponents = () => {
      const name = this.form.getValue("name");
      const type = this.form.getValue("type");
      const components = this.props.getComponents(name, type);
      this.setState({
        components
      });
    };
    this.setFormValue = (name, value) => {
      this.form.setValue(name, value);
      this.onSubmit();
    };
    this.loadAndSetComponentProperties = (com) => {
      const { appName } = this.props;
      if (com) {
        getApplicationComponent(appName, com.name).then((res) => {
          this.form.setValue("properties", res.properties);
          this.onSubmit();
          Message.success("Assign the default values for the properties successfully");
        });
      }
    };
    this.state = {};
    this.form = new Field(this, {
      onChange: (name) => {
        if (name == "name" || name == "type") {
          this.onLoadComponents();
        }
        this.onSubmit();
      },
      values: { ...props.value }
    });
    this.props.registerForm(this.form);
  }
  render() {
    const { value, id, disabled } = this.props;
    const { componentOptions, componentTypeOptions } = this.props;
    const { init } = this.form;
    const { components } = this.state;
    const traits = [];
    const traitKey = /* @__PURE__ */ new Map();
    components?.map((com) => {
      com.traits?.map((t) => {
        if (!traitKey.get(t.type)) {
          traits.push(t);
          traitKey.set(t.type, t.type);
        }
      });
    });
    const traitPatches = this.form.getValue("traits") || [];
    const traitPatchMap = /* @__PURE__ */ new Map();
    traitPatches.map((item) => {
      traitPatchMap.set(item.type, item);
    });
    const validatorName = (rule, v, callback) => {
      if (!v && !this.form.getValue("type")) {
        callback("Please select a component or component type.");
        return;
      }
      callback();
    };
    const validatorType = (rule, v, callback) => {
      if (!v && !this.form.getValue("name")) {
        callback("Please select a component or component type.");
        return;
      }
      callback();
    };
    const notice = "If you want to create the patch for the component or trait properties, let's switch to the coding mode.";
    return /* @__PURE__ */ React.createElement(
      Card,
      {
        id,
        className: "withActions",
        title: "Component patch configuration",
        contentHeight: "auto",
        subTitle: /* @__PURE__ */ React.createElement("a", { onClick: () => this.props.onRemove(id) }, /* @__PURE__ */ React.createElement(AiOutlineDelete, { size: 15 }))
      },
      /* @__PURE__ */ React.createElement(Form, { field: this.form }, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(Form.Item, { label: i18n.t("Name").toString() }, /* @__PURE__ */ React.createElement(
        Select,
        {
          placeholder: i18n.t("You can base a component to set the patch configuration"),
          disabled,
          dataSource: componentOptions,
          hasClear: true,
          ...init("name", {
            initValue: value?.name,
            rules: [{ validator: validatorName }]
          }),
          locale: locale().Select,
          onBlur: this.onSubmit
        }
      ))), /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(Form.Item, { label: i18n.t("Type").toString() }, /* @__PURE__ */ React.createElement(
        CustomSelect,
        {
          placeholder: i18n.t("You can base a component type to set the patch configuration"),
          disabled,
          dataSource: componentTypeOptions,
          hasClear: true,
          enableInput: true,
          ...init("type", {
            initValue: value?.type,
            rules: [{ validator: validatorType }]
          }),
          locale: locale().Select,
          onBlur: this.onSubmit
        }
      )))), /* @__PURE__ */ React.createElement(If, { condition: traits }, /* @__PURE__ */ React.createElement("div", { style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(Form.Item, { label: "Traits", ...init("traits", {}) }, /* @__PURE__ */ React.createElement(Row, { wrap: true }, traits.map((trait) => {
        const label = trait.alias ? trait.alias + "(" + trait.type + ")" : trait.type;
        const disable = traitPatchMap.get(trait.type)?.disable;
        const icon = /* @__PURE__ */ React.createElement(
          AiOutlineDelete,
          {
            onClick: (event) => {
              event.stopPropagation();
              if (disable) {
                this.setFormValue(
                  "traits",
                  traitPatches.filter((t) => t.type != trait.type)
                );
              } else {
                this.setFormValue("traits", [...traitPatches, { type: trait.type, disable: true }]);
              }
            },
            size: 14,
            className: "danger-icon",
            title: disable ? i18n.t("Cancel Disable") : i18n.t("Disable")
          }
        );
        return /* @__PURE__ */ React.createElement(
          "div",
          {
            key: trait.type,
            onClick: () => {
            },
            className: classNames("trait-icon", {
              disable
            }),
            title: disable ? "Disabled" : ""
          },
          /* @__PURE__ */ React.createElement("div", null, label),
          /* @__PURE__ */ React.createElement("div", { className: "trait-actions" }, /* @__PURE__ */ React.createElement(Balloon, { trigger: icon }, disable ? i18n.t("Cancel Disable") : i18n.t("Disable")))
        );
      }))))), /* @__PURE__ */ React.createElement(If, { condition: components }, /* @__PURE__ */ React.createElement("div", { style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(Form.Item, { label: "Properties", ...init("properties", {}) }, /* @__PURE__ */ React.createElement(Message, { type: "notice" }, /* @__PURE__ */ React.createElement(Translation, null, notice), /* @__PURE__ */ React.createElement(If, { condition: components?.length == 1 }, /* @__PURE__ */ React.createElement(
        "a",
        {
          onClick: () => {
            this.loadAndSetComponentProperties(components && components[0]);
          }
        },
        /* @__PURE__ */ React.createElement(Translation, null, "Assign the default properties")
      )))))))
    );
  }
};
ComponentPatch = __decorateClass([
  connect((store) => {
    return { ...store.uischema };
  })
], ComponentPatch);
export default ComponentPatch;
