"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Loading, Button, Table, Dialog, Message, Balloon } from "@alifd/next";
import { connect } from "dva";
import { Link, routerRedux } from "dva/router";
import React, { Component } from "react";
import { AiFillCaretRight, AiFillDelete } from "react-icons/ai";
import { BiCopyAlt } from "react-icons/bi";
import { HiViewList } from "react-icons/hi";
import { deletePipeline, listPipelines } from "../../api/pipeline";
import { If } from "../../components/If";
import { ListTitle as Title } from "../../components/ListTitle";
import Permission from "../../components/Permission";
import RunPipeline from "../../components/RunPipeline";
import { Translation } from "../../components/Translation";
import i18n from "../../i18n";
import { beautifyTime, momentDate } from "../../utils/common";
import { locale } from "../../utils/locale";
import CreatePipeline from "../PipelineListPage/components/CreatePipeline";
import ClonePipeline from "../PipelineListPage/components/PipelineClone";
import SelectSearch from "../PipelineListPage/components/SelectSearch";
import ViewRuns from "../PipelineListPage/components/ViewRuns";
import RunStatusIcon from "../PipelineRunPage/components/RunStatusIcon";
let ProjectPipelines = class extends Component {
  constructor(props) {
    super(props);
    this.getPipelines = async (params) => {
      const {
        match: {
          params: { projectName }
        }
      } = this.props;
      this.setState({ isLoading: true });
      listPipelines({ projectName, query: params.query }).then((res) => {
        this.setState({
          pipelines: res && Array.isArray(res.pipelines) ? res.pipelines : []
        });
      }).finally(() => {
        this.setState({ isLoading: false });
      });
    };
    this.onShowPipelineRuns = (pipeline) => {
      this.setState({ showRuns: true, pipeline });
    };
    this.onRunPipeline = (pipeline) => {
      this.setState({ pipeline, showRunPipeline: true });
    };
    this.onClonePipeline = (pipeline) => {
      this.setState({ pipeline, showClonePipeline: true });
    };
    this.onDeletePipeline = (pipeline) => {
      Dialog.confirm({
        type: "confirm",
        content: /* @__PURE__ */ React.createElement(Translation, null, "Unrecoverable after deletion, are you sure to delete it?"),
        onOk: () => {
          deletePipeline({
            projectName: pipeline.project.name,
            pipelineName: pipeline.name
          }).then((res) => {
            if (res) {
              Message.success(i18n.t("The Pipeline removed successfully"));
              this.getPipelines({});
            }
          });
        },
        locale: locale().Dialog
      });
    };
    this.renderPipelineTable = () => {
      const { pipelines } = this.state;
      return /* @__PURE__ */ React.createElement("div", { className: "table-pipeline-list" }, /* @__PURE__ */ React.createElement(
        Table,
        {
          className: "customTable",
          size: "medium",
          style: { minWidth: "1400px" },
          locale: locale().Table,
          dataSource: pipelines
        },
        /* @__PURE__ */ React.createElement(
          Table.Column,
          {
            key: "name",
            title: i18n.t("Name(Alias)").toString(),
            dataIndex: "name",
            cell: (name, i, pipeline) => {
              let text = name;
              if (pipeline.alias) {
                text = `${name}(${pipeline.alias})`;
              }
              return /* @__PURE__ */ React.createElement("div", { className: "pipeline-name" }, /* @__PURE__ */ React.createElement(Link, { to: `/projects/${pipeline.project.name}/pipelines/${pipeline.name}/studio` }, text), /* @__PURE__ */ React.createElement("span", null, pipeline.description));
            }
          }
        ),
        /* @__PURE__ */ React.createElement(
          Table.Column,
          {
            key: "project",
            title: i18n.t("Project").toString(),
            dataIndex: "project",
            cell: (project) => {
              let text = project.name;
              if (project.alias) {
                text = `${project.name}(${project.alias})`;
              }
              return /* @__PURE__ */ React.createElement(Link, { to: `/projects/${project.name}` }, text);
            }
          }
        ),
        /* @__PURE__ */ React.createElement(
          Table.Column,
          {
            key: "createTime",
            title: i18n.t("CreateTime").toString(),
            dataIndex: "createTime",
            width: 160,
            cell: (v) => {
              return momentDate(v);
            }
          }
        ),
        /* @__PURE__ */ React.createElement(
          Table.Column,
          {
            key: "runs",
            title: i18n.t("Recent Runs(Last 7-Days)").toString(),
            dataIndex: "info.runStat",
            width: "280px",
            cell: (runState) => {
              if (!runState) {
                return "-";
              }
              return /* @__PURE__ */ React.createElement("div", { className: "run-state" }, /* @__PURE__ */ React.createElement("div", { className: "week" }, runState.week?.map((day) => {
                const failure = day.total == 0 ? 0 : Math.floor(day.fail / day.total * 100);
                const success = 100 - failure;
                return /* @__PURE__ */ React.createElement(
                  Balloon,
                  {
                    trigger: /* @__PURE__ */ React.createElement("div", { className: "rectangle" }, /* @__PURE__ */ React.createElement(If, { condition: day.total > 0 }, /* @__PURE__ */ React.createElement("span", { className: "failure", style: { height: `${failure}%` } }), /* @__PURE__ */ React.createElement("span", { className: "success", style: { height: `${success}%` } })), /* @__PURE__ */ React.createElement(If, { condition: day.total == 0 }, /* @__PURE__ */ React.createElement("span", { style: { height: `10px` } })))
                  },
                  /* @__PURE__ */ React.createElement(If, { condition: day.total == 0 }, "No Run"),
                  /* @__PURE__ */ React.createElement(If, { condition: day.total > 0 }, /* @__PURE__ */ React.createElement("p", null, "Total: ", day.total), /* @__PURE__ */ React.createElement("p", null, "Success: ", day.success))
                );
              })), /* @__PURE__ */ React.createElement(If, { condition: runState.total.total > 0 }, /* @__PURE__ */ React.createElement("div", { className: "active" }, /* @__PURE__ */ React.createElement("span", null, runState.total.total))));
            }
          }
        ),
        /* @__PURE__ */ React.createElement(
          Table.Column,
          {
            key: "lastRun",
            title: i18n.t("Last Run").toString(),
            dataIndex: "info.lastRun",
            cell: (run) => {
              if (run) {
                return /* @__PURE__ */ React.createElement("div", { className: "last-run" }, /* @__PURE__ */ React.createElement("div", { className: "metadata" }, /* @__PURE__ */ React.createElement(
                  Link,
                  {
                    to: `/projects/${run.project.name}/pipelines/${run.pipelineName}/runs/${run.pipelineRunName}`
                  },
                  run.pipelineRunName
                ), /* @__PURE__ */ React.createElement("span", null, beautifyTime(run.status?.startTime))), /* @__PURE__ */ React.createElement(RunStatusIcon, { status: run.status?.status }));
              }
              return /* @__PURE__ */ React.createElement("span", { style: { color: "var(--grey-400)" } }, "This Pipeline never ran.");
            }
          }
        ),
        /* @__PURE__ */ React.createElement(
          Table.Column,
          {
            key: "actions",
            title: i18n.t("Actions").toString(),
            dataIndex: "name",
            width: "360px",
            cell: (name, i, pipeline) => {
              return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
                Permission,
                {
                  project: pipeline.project.name,
                  resource: {
                    resource: `project:${pipeline.project.name}/pipeline:${pipeline.name}`,
                    action: "run"
                  }
                },
                /* @__PURE__ */ React.createElement(
                  Button,
                  {
                    text: true,
                    size: "medium",
                    component: "a",
                    onClick: () => {
                      this.onRunPipeline(pipeline);
                    }
                  },
                  /* @__PURE__ */ React.createElement(AiFillCaretRight, null),
                  " ",
                  /* @__PURE__ */ React.createElement(Translation, null, "Run")
                ),
                /* @__PURE__ */ React.createElement("span", { className: "line" })
              ), /* @__PURE__ */ React.createElement(
                Permission,
                {
                  project: pipeline.project.name,
                  resource: {
                    resource: `project:${pipeline.project.name}/pipeline:${pipeline.name}/pipelineRun:*`,
                    action: "list"
                  }
                },
                /* @__PURE__ */ React.createElement(
                  Button,
                  {
                    text: true,
                    size: "medium",
                    component: "a",
                    onClick: () => {
                      this.onShowPipelineRuns(pipeline);
                    }
                  },
                  /* @__PURE__ */ React.createElement(HiViewList, null),
                  /* @__PURE__ */ React.createElement(Translation, null, "View Runs")
                ),
                /* @__PURE__ */ React.createElement("span", { className: "line" })
              ), /* @__PURE__ */ React.createElement(
                Permission,
                {
                  project: pipeline.project.name,
                  resource: {
                    resource: `project:${pipeline.project.name}/pipeline:*`,
                    action: "create"
                  }
                },
                /* @__PURE__ */ React.createElement(
                  Button,
                  {
                    text: true,
                    size: "medium",
                    component: "a",
                    onClick: () => {
                      this.onClonePipeline(pipeline);
                    }
                  },
                  /* @__PURE__ */ React.createElement(BiCopyAlt, null),
                  " ",
                  /* @__PURE__ */ React.createElement(Translation, null, "Clone")
                ),
                /* @__PURE__ */ React.createElement("span", { className: "line" })
              ), /* @__PURE__ */ React.createElement(
                Permission,
                {
                  project: pipeline.project.name,
                  resource: {
                    resource: `project:${pipeline.project.name}/pipeline:${pipeline.name}`,
                    action: "delete"
                  }
                },
                /* @__PURE__ */ React.createElement(
                  Button,
                  {
                    text: true,
                    size: "medium",
                    className: "danger-btn",
                    component: "a",
                    onClick: () => {
                      this.onDeletePipeline(pipeline);
                    }
                  },
                  /* @__PURE__ */ React.createElement(AiFillDelete, null),
                  /* @__PURE__ */ React.createElement(Translation, null, "Remove")
                )
              ));
            }
          }
        )
      ));
    };
    this.state = {
      isLoading: false,
      showMode: "list"
    };
  }
  componentDidMount() {
    this.getPipelines({});
  }
  render() {
    const { userInfo } = this.props;
    const {
      match: {
        params: { projectName }
      }
    } = this.props;
    const { showMode, isLoading, showRunPipeline, pipeline, showRuns, showNewPipeline, showClonePipeline } = this.state;
    const { enabledAddons } = this.props;
    const addonEnabled = enabledAddons?.filter((addon) => addon.name == "vela-workflow").length;
    return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
      Title,
      {
        title: "",
        subTitle: "",
        extButtons: [
          /* @__PURE__ */ React.createElement(
            Permission,
            {
              request: { resource: `project:${projectName}/pipeline:*`, action: "create" },
              project: projectName
            },
            /* @__PURE__ */ React.createElement(If, { condition: addonEnabled }, /* @__PURE__ */ React.createElement(
              Button,
              {
                type: "primary",
                onClick: () => {
                  this.setState({ showNewPipeline: true });
                }
              },
              /* @__PURE__ */ React.createElement(Translation, null, "New Pipeline")
            ))
          )
        ]
      }
    ), /* @__PURE__ */ React.createElement(
      SelectSearch,
      {
        projects: userInfo?.projects,
        disableProject: true,
        showMode,
        setMode: (mode) => {
          this.setState({ showMode: mode });
          if (mode) {
            localStorage.setItem("application-list-mode", mode);
          }
        },
        getPipelines: (params) => {
          this.getPipelines(params);
        }
      }
    ), /* @__PURE__ */ React.createElement(If, { condition: addonEnabled }, /* @__PURE__ */ React.createElement(Loading, { style: { width: "100%" }, visible: isLoading }, this.renderPipelineTable())), /* @__PURE__ */ React.createElement(If, { condition: !addonEnabled }, /* @__PURE__ */ React.createElement(Permission, { request: { resource: "addon", action: "enable" } }, /* @__PURE__ */ React.createElement("div", { className: "addon-notice" }, "Please enable the ", /* @__PURE__ */ React.createElement(Link, { to: "/addons/vela-workflow" }, "vela-workflow"), " addon to power pipeline."))), /* @__PURE__ */ React.createElement(If, { condition: showRunPipeline }, pipeline && /* @__PURE__ */ React.createElement(
      RunPipeline,
      {
        onClose: () => {
          this.setState({ showRunPipeline: false, pipeline: void 0 });
        },
        onSuccess: (runName) => {
          this.props.dispatch(
            routerRedux.push(`/projects/${pipeline.project.name}/pipelines/${pipeline.name}/runs/${runName}`)
          );
        },
        pipeline
      }
    )), /* @__PURE__ */ React.createElement(If, { condition: showRuns }, pipeline && /* @__PURE__ */ React.createElement(
      ViewRuns,
      {
        pipeline,
        onClose: () => {
          this.setState({ showRuns: false, pipeline: void 0 });
        }
      }
    )), /* @__PURE__ */ React.createElement(If, { condition: showNewPipeline }, /* @__PURE__ */ React.createElement(
      CreatePipeline,
      {
        onClose: () => {
          this.setState({ showNewPipeline: false, pipeline: void 0 });
        },
        onSuccess: (p) => {
          this.props.dispatch(routerRedux.push(`/projects/${p.project.name}/pipelines/${p.name}/studio`));
        },
        pipeline
      }
    )), /* @__PURE__ */ React.createElement(If, { condition: showClonePipeline }, /* @__PURE__ */ React.createElement(
      ClonePipeline,
      {
        onClose: () => {
          this.setState({ showClonePipeline: false, pipeline: void 0 });
        },
        onSuccess: () => {
          this.getPipelines({});
          this.setState({ showClonePipeline: false, pipeline: void 0 });
        },
        pipeline
      }
    )));
  }
};
ProjectPipelines = __decorateClass([
  connect((store) => {
    return { ...store.user, ...store.addons };
  })
], ProjectPipelines);
export default ProjectPipelines;
