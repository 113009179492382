"use strict";
import { Table, Message, Dialog, Tag, Button } from "@alifd/next";
import { Link } from "dva/router";
import React, { Component } from "react";
import { AiFillDelete, AiFillSetting } from "react-icons/ai";
import { deleteEnv } from "../../../../api/env";
import { If } from "../../../../components/If";
import Permission from "../../../../components/Permission";
import { Translation } from "../../../../components/Translation";
import "./index.less";
import { locale } from "../../../../utils/locale";
import { checkPermission } from "../../../../utils/permission";
const { Group: TagGroup } = Tag;
class TableList extends Component {
  constructor() {
    super(...arguments);
    this.onDelete = (record) => {
      deleteEnv({ name: record.name || "" }).then((re) => {
        if (re) {
          Message.success("Environment deleted successfully");
          this.props.updateEnvList();
        }
      });
    };
    this.onEdit = (record) => {
      this.props.changeISEdit(true, record);
    };
    this.showEnvAppList = (envName) => {
      this.setState({ showEnvAppList: true, envName });
    };
    this.getColumns = () => {
      return [
        {
          key: "name",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Name(Alias)"),
          dataIndex: "name",
          width: "200px",
          cell: (v, i, env) => {
            return /* @__PURE__ */ React.createElement(
              "a",
              {
                onClick: () => {
                  this.showEnvAppList(v);
                }
              },
              `${env.name}(${env.alias || "-"})`
            );
          }
        },
        {
          key: "project",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Project"),
          dataIndex: "project",
          width: "100px",
          cell: (v) => {
            if (v && v.name) {
              return /* @__PURE__ */ React.createElement(Link, { to: `/projects/${v.name}/summary` }, v.alias || v.name);
            } else {
              return null;
            }
          }
        },
        {
          key: "namespace",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Namespace"),
          dataIndex: "namespace",
          width: "120px",
          cell: (v) => {
            return /* @__PURE__ */ React.createElement("span", null, v);
          }
        },
        {
          key: "description",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Description"),
          dataIndex: "description",
          cell: (v) => {
            return /* @__PURE__ */ React.createElement("span", null, v);
          }
        },
        {
          key: "targets",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Targets"),
          dataIndex: "targets",
          cell: (v, i, record) => {
            return /* @__PURE__ */ React.createElement(TagGroup, null, v?.map((target) => {
              return /* @__PURE__ */ React.createElement(Tag, { color: "green", key: target.name + i }, /* @__PURE__ */ React.createElement(
                If,
                {
                  condition: checkPermission(
                    { resource: `target:*`, action: "list" },
                    record.project.name,
                    this.props.userInfo
                  )
                },
                /* @__PURE__ */ React.createElement(Link, { to: "/targets" }, target.alias ? target.alias : target.name)
              ), /* @__PURE__ */ React.createElement(
                If,
                {
                  condition: !checkPermission(
                    { resource: `target:*`, action: "list" },
                    record.project.name,
                    this.props.userInfo
                  )
                },
                target.alias ? target.alias : target.name
              ));
            }));
          }
        },
        {
          key: "operation",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Actions"),
          dataIndex: "operation",
          width: "200px",
          cell: (v, i, record) => {
            return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
              Permission,
              {
                request: {
                  resource: `project:${record.project.name}/environment:${record.name}`,
                  action: "update"
                },
                project: record.project.name
              },
              /* @__PURE__ */ React.createElement(
                Button,
                {
                  className: "margin-left-10",
                  text: true,
                  component: "a",
                  size: "medium",
                  onClick: () => {
                    this.onEdit(record);
                  }
                },
                /* @__PURE__ */ React.createElement(AiFillSetting, null),
                /* @__PURE__ */ React.createElement(Translation, null, "Edit")
              ),
              /* @__PURE__ */ React.createElement("span", { className: "line" })
            ), /* @__PURE__ */ React.createElement(
              Permission,
              {
                request: {
                  resource: `project:${record.project.name}/environment:${record.name}`,
                  action: "delete"
                },
                project: record.project.name
              },
              /* @__PURE__ */ React.createElement(
                Button,
                {
                  text: true,
                  component: "a",
                  size: "medium",
                  className: "danger-btn",
                  onClick: () => {
                    Dialog.confirm({
                      type: "confirm",
                      content: /* @__PURE__ */ React.createElement(Translation, null, "Unrecoverable after deletion, are you sure to delete it?"),
                      onOk: () => {
                        this.onDelete(record);
                      },
                      locale: locale().Dialog
                    });
                  }
                },
                /* @__PURE__ */ React.createElement(AiFillDelete, null),
                /* @__PURE__ */ React.createElement(Translation, null, "Remove")
              )
            ));
          }
        }
      ];
    };
  }
  render() {
    const { Column } = Table;
    const columns = this.getColumns();
    const { list } = this.props;
    return /* @__PURE__ */ React.createElement("div", { className: "table-env-list margin-top-20" }, /* @__PURE__ */ React.createElement(
      Table,
      {
        locale: locale().Table,
        className: "customTable",
        size: "medium",
        style: { minWidth: "1200px" },
        dataSource: list,
        loading: false
      },
      columns.map((col) => /* @__PURE__ */ React.createElement(Column, { ...col, key: col.key, align: "left" }))
    ));
  }
}
export default TableList;
