"use strict";
import { Grid, Field, Form, Message, Button, Input } from "@alifd/next";
import i18n from "i18next";
import React from "react";
import { updateApplication } from "../../../../api/application";
import DrawerWithFooter from "../../../../components/Drawer";
import { Translation } from "../../../../components/Translation";
import KV from "../../../../extends/KV";
class EditAppDialog extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = () => {
      this.field.validate((error, values) => {
        if (error) {
          return;
        }
        const { description, alias, labels, annotations } = values;
        const { editItem } = this.props;
        if (editItem) {
          const params = {
            name: editItem.name,
            alias,
            description,
            labels,
            annotations
          };
          updateApplication(params).then((res) => {
            if (res) {
              Message.success(/* @__PURE__ */ React.createElement(Translation, null, "Application updated successfully"));
              this.props.onOK();
            }
          });
        }
      });
    };
    this.extButtonList = () => {
      const { onClose } = this.props;
      return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Button, { type: "secondary", onClick: onClose, className: "margin-right-10" }, /* @__PURE__ */ React.createElement(Translation, null, "Cancel")), /* @__PURE__ */ React.createElement(Button, { type: "primary", onClick: this.onSubmit }, /* @__PURE__ */ React.createElement(Translation, null, "Update")));
    };
    this.field = new Field(this);
  }
  render() {
    const init = this.field.init;
    const FormItem = Form.Item;
    const { Row, Col } = Grid;
    const { editItem, onClose } = this.props;
    return /* @__PURE__ */ React.createElement(
      DrawerWithFooter,
      {
        title: /* @__PURE__ */ React.createElement(Translation, null, "Edit Application"),
        placement: "right",
        width: 800,
        onClose,
        extButtons: this.extButtonList()
      },
      editItem && /* @__PURE__ */ React.createElement(Form, { field: this.field }, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Alias") }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "alias",
          placeholder: i18n.t("Give your app a more recognizable name").toString(),
          ...init("alias", {
            initValue: editItem?.alias,
            rules: [
              {
                minLength: 2,
                maxLength: 64,
                message: "Enter a string of 2 to 64 characters."
              }
            ]
          })
        }
      )))), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Description") }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "description",
          ...init("description", {
            initValue: editItem?.description,
            rules: [
              {
                maxLength: 128,
                message: "Enter a description less than 128 characters."
              }
            ]
          })
        }
      )))), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Labels") }, /* @__PURE__ */ React.createElement(
        KV,
        {
          ...init("labels", {
            initValue: editItem?.labels
          }),
          disabled: false
        }
      )))), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Annotations") }, /* @__PURE__ */ React.createElement(
        KV,
        {
          ...init("annotations", {
            initValue: editItem?.annotations
          }),
          disabled: false
        }
      )))))
    );
  }
}
export default EditAppDialog;
