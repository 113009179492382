"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import React from "react";
import { connect } from "dva";
import "./index.less";
import { Grid, Message } from "@alifd/next";
import i18n from "../../../../i18n";
import { If } from "../../../../components/If";
import Empty from "../../../../components/Empty";
import PluginCard from "../plugin-card";
function pluginEnabled(p) {
  return p.enabled;
}
function pluginInstalled(p) {
  return !!p.info;
}
function pluginUninstalled(p) {
  return !pluginInstalled(p);
}
let Plugin = class extends React.Component {
  constructor(props) {
    super(props);
    this.getPluginList = async (params = {}) => {
      this.props.dispatch({
        type: "plugins/getPluginList",
        payload: params
      });
    };
    this.sortedPlugins = () => {
      const { pluginList } = this.props;
      let enabledPlugins = pluginList.filter(pluginEnabled);
      let uninstalledPlugins = pluginList.filter(pluginUninstalled);
      let installedPlugins = pluginList.filter((p) => {
        return pluginInstalled(p) && !pluginEnabled(p);
      });
      return [...enabledPlugins, ...installedPlugins, ...uninstalledPlugins];
    };
    this.state = {};
    this.installPlugin = this.installPlugin.bind(this);
  }
  installPlugin(id, url) {
    this.props.dispatch({
      type: "plugins/installPlugin",
      payload: { id, url },
      callback: () => {
        Message.success(i18n.t("Install Success. Enabled automatically."));
      }
    });
  }
  uninstallPlugin(id) {
    this.props.dispatch({
      type: "plugins/uninstallPlugin",
      payload: { id },
      callback: () => {
        Message.success(i18n.t("Uninstall Success."));
      }
    });
  }
  enablePlugin(id) {
    this.props.dispatch({
      type: "plugins/enablePlugin",
      payload: { id },
      callback: () => {
        Message.success(i18n.t("Enable Success."));
      }
    });
  }
  disablePlugin(id) {
    this.props.dispatch({
      type: "plugins/disablePlugin",
      payload: { id },
      callback: () => {
        Message.success(i18n.t("Disable Success."));
      }
    });
  }
  componentDidMount() {
    this.getPluginList().then(() => {
      this.sortedPlugins();
    });
  }
  render() {
    const { pluginList } = this.props;
    const { Row, Col } = Grid;
    return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(If, { condition: pluginList }, /* @__PURE__ */ React.createElement("div", { style: { marginTop: "20px" } }, /* @__PURE__ */ React.createElement(Row, { wrap: true, gutter: 16 }, pluginList && this.sortedPlugins().map((plugin, index) => {
      return /* @__PURE__ */ React.createElement(Col, { xl: 4, l: 6, m: 8, s: 12, key: index }, /* @__PURE__ */ React.createElement("div", { style: { marginBottom: "20px" } }, /* @__PURE__ */ React.createElement(
        PluginCard,
        {
          id: plugin.id,
          icon: plugin.info?.logos?.small,
          enabled: plugin.enabled,
          installed: !!plugin.info,
          description: plugin.info?.description,
          sourceAddon: plugin.addon,
          tags: [],
          history: this.props.history,
          url: plugin.url,
          onInstall: this.installPlugin
        }
      )));
    })))), /* @__PURE__ */ React.createElement(If, { condition: !pluginList || pluginList.length == 0 }, /* @__PURE__ */ React.createElement(Empty, { style: { minHeight: "400px" } })));
  }
};
Plugin.defaultProps = {
  pluginList: []
};
Plugin = __decorateClass([
  connect((store) => {
    return { ...store.plugins };
  })
], Plugin);
export default Plugin;
