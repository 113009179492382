"use strict";
import { Dialog, Field, Form, Grid, Input, Message } from "@alifd/next";
import React, { Component, Fragment } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { Translation } from "../../../../components/Translation";
import i18n from "../../../../i18n";
import { checkName, checkUserPassword } from "../../../../utils/common";
import { locale } from "../../../../utils/locale";
import { initAdmin } from "../../../../api/authentication";
class EditPlatFormUserDialog extends Component {
  constructor(props) {
    super(props);
    this.onInitAdmin = async () => {
      this.field.validate((error, values) => {
        if (error) {
          return;
        }
        const { name, password, email } = values;
        const params = {
          name,
          email,
          password
        };
        this.setState({
          isLoading: true
        });
        initAdmin(params).then((res) => {
          if (res && res.success) {
            Message.success(/* @__PURE__ */ React.createElement(Translation, null, "Admin User Initialized Successfully"));
            this.props.onClose();
          }
        }).finally(() => {
          this.setState({
            isLoading: false
          });
        });
      });
    };
    this.handleClickLook = () => {
      this.setState({
        isLookPassword: !this.state.isLookPassword
      });
    };
    this.field = new Field(this);
    this.state = {
      isLoading: false,
      isLookPassword: false
    };
  }
  showTitle() {
    return i18n.t("Reset the password and email for the administrator account");
  }
  render() {
    const init = this.field.init;
    const { isLoading } = this.state;
    const { Row, Col } = Grid;
    const FormItem = Form.Item;
    const formItemLayout = {
      labelCol: {
        fixedSpan: 6
      },
      wrapperCol: {
        span: 20
      }
    };
    return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement(
      Dialog,
      {
        v2: true,
        visible: true,
        title: this.showTitle(),
        style: { width: "600px" },
        onOk: this.onInitAdmin,
        locale: locale().Dialog,
        footerActions: ["ok"],
        okProps: {
          loading: isLoading
        }
      },
      /* @__PURE__ */ React.createElement(Form, { loading: isLoading, ...formItemLayout, field: this.field }, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Admin Username"), required: true }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "name",
          htmlType: "",
          placeholder: i18n.t("Please input the admin username").toString(),
          ...init("name", {
            rules: [
              {
                required: true,
                pattern: checkName,
                message: /* @__PURE__ */ React.createElement(Translation, null, "You must input a valid name")
              }
            ]
          })
        }
      )))), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Password"), required: true }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "password",
          htmlType: this.state.isLookPassword ? "passwordInput" : "password",
          addonTextAfter: /* @__PURE__ */ React.createElement(
            AiOutlineEye,
            {
              style: {
                cursor: "pointer"
              },
              onClick: this.handleClickLook
            }
          ),
          placeholder: i18n.t("Please input the password").toString(),
          ...init("password", {
            rules: [
              {
                required: true,
                pattern: checkUserPassword,
                message: /* @__PURE__ */ React.createElement(Translation, null, "Password should be 8-16 bits and contain at least one number and one letter")
              }
            ]
          })
        }
      )))), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Email"), required: true }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "email",
          placeholder: i18n.t("Please input a email").toString(),
          ...init("email", {
            rules: [
              {
                required: true,
                format: "email",
                message: /* @__PURE__ */ React.createElement(Translation, null, "Please input a valid email")
              }
            ]
          })
        }
      )))))
    ));
  }
}
export default EditPlatFormUserDialog;
