"use strict";
import * as H from "history";
function renderUrl(path, query) {
  if (query && Object.keys(query).length > 0) {
    path += "?" + toUrlParams(query);
  }
  return path;
}
function encodeURIComponent2(val, pctEncodeSpaces) {
  return encodeURIComponent(val).replace(/%40/gi, "@").replace(/%3A/gi, ":").replace(/%24/g, "$").replace(/%2C/gi, ",").replace(/%3B/gi, ";").replace(/%20/g, pctEncodeSpaces ? "%20" : "+").replace(/[!'()*]/g, function(c) {
    return "%" + c.charCodeAt(0).toString(16).toUpperCase();
  });
}
function toUrlParams(a) {
  const s = [];
  const rbracket = /\[\]$/;
  const isArray = (obj) => {
    return Object.prototype.toString.call(obj) === "[object Array]";
  };
  const add = (k, v) => {
    v = typeof v === "function" ? v() : v === null ? "" : v === void 0 ? "" : v;
    if (typeof v !== "boolean") {
      s[s.length] = encodeURIComponent2(k, true) + "=" + encodeURIComponent2(v, true);
    } else {
      const valueQueryPart = v ? "" : "=" + encodeURIComponent2("false", true);
      s[s.length] = encodeURIComponent2(k, true) + valueQueryPart;
    }
  };
  const buildParams = (prefix, obj) => {
    let i, len, key;
    if (prefix) {
      if (isArray(obj)) {
        for (i = 0, len = obj.length; i < len; i++) {
          if (rbracket.test(prefix)) {
            add(prefix, obj[i]);
          } else {
            buildParams(prefix, obj[i]);
          }
        }
      } else if (obj && String(obj) === "[object Object]") {
        for (key in obj) {
          buildParams(prefix + "[" + key + "]", obj[key]);
        }
      } else {
        add(prefix, obj);
      }
    } else if (isArray(obj)) {
      for (i = 0, len = obj.length; i < len; i++) {
        add(obj[i].name, obj[i].value);
      }
    } else {
      for (key in obj) {
        buildParams(key, obj[key]);
      }
    }
    return s;
  };
  return buildParams("", a).join("&");
}
export function parseKeyValue(keyValue) {
  const obj = {};
  const parts = (keyValue || "").split("&");
  for (let keyValue2 of parts) {
    let splitPoint;
    let key;
    let val;
    if (keyValue2) {
      key = keyValue2 = keyValue2.replace(/\+/g, "%20");
      splitPoint = keyValue2.indexOf("=");
      if (splitPoint !== -1) {
        key = keyValue2.substring(0, splitPoint);
        val = keyValue2.substring(splitPoint + 1);
      }
      key = tryDecodeURIComponent(key);
      if (key !== void 0) {
        val = val !== void 0 ? tryDecodeURIComponent(val) : true;
        let parsedVal;
        if (typeof val === "string" && val !== "") {
          parsedVal = val === "true" || val === "false" ? val === "true" : val;
        } else {
          parsedVal = val;
        }
        if (!obj.hasOwnProperty(key)) {
          obj[key] = isNaN(parsedVal) ? val : parsedVal;
        } else if (Array.isArray(obj[key])) {
          obj[key].push(val);
        } else {
          obj[key] = [obj[key], isNaN(parsedVal) ? val : parsedVal];
        }
      }
    }
  }
  return obj;
}
function tryDecodeURIComponent(value) {
  try {
    return decodeURIComponent(value);
  } catch (e) {
    return void 0;
  }
}
export class HistoryWrapper {
  constructor(history) {
    this.history = history || (process.env.NODE_ENV === "test" ? H.createMemoryHistory({ initialEntries: ["/"] }) : H.createBrowserHistory({ basename: "/" }));
    this.partial = this.partial.bind(this);
    this.push = this.push.bind(this);
    this.replace = this.replace.bind(this);
    this.getSearch = this.getSearch.bind(this);
    this.getHistory = this.getHistory.bind(this);
    this.getLocation = this.getLocation.bind(this);
  }
  getHistory() {
    return this.history;
  }
  getSearch() {
    return new URLSearchParams(this.history.location.search);
  }
  partial(query, replace) {
    const currentLocation = this.history.location;
    const newQuery = this.getSearchObject();
    for (const key of Object.keys(query)) {
      if (query[key] === null || query[key] === void 0) {
        delete newQuery[key];
      } else {
        newQuery[key] = query[key];
      }
    }
    const updatedUrl = renderUrl(currentLocation.pathname, newQuery);
    if (replace) {
      this.history.replace(updatedUrl, this.history.location.state);
    } else {
      this.history.push(updatedUrl, this.history.location.state);
    }
  }
  push(location) {
    this.history.push(location);
  }
  replace(location) {
    this.history.replace(location);
  }
  reload() {
    const prevState = this.history.location.state?.routeReloadCounter;
    this.history.replace({
      ...this.history.location,
      state: { routeReloadCounter: prevState ? prevState + 1 : 1 }
    });
  }
  getLocation() {
    return this.history.location;
  }
  // The param key include a prefix $
  getSearchObject() {
    return locationSearchToObject(this.history.location.search);
  }
  getPathName() {
    return this.history.location.pathname;
  }
}
export function locationSearchToObject(search) {
  let queryString = typeof search === "number" ? String(search) : search;
  if (queryString.length > 0) {
    if (queryString.startsWith("?")) {
      return parseKeyValue(queryString.substring(1));
    }
    return parseKeyValue(queryString);
  }
  return {};
}
export let locationService = new HistoryWrapper();
