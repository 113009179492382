"use strict";
import { Balloon, Button, Dialog, Dropdown, Menu, Message, Table } from "@alifd/next";
import Empty from "../Empty";
import { Link, routerRedux } from "dva/router";
import React, { Component } from "react";
import "./index.less";
import { compareApplication, rollbackWithRevision } from "../../../../api/application";
import { ApplicationDiff } from "../../../../components/ApplicationDiff";
import { If } from "../../../../components/If";
import Item from "../../../../components/Item";
import Permission from "../../../../components/Permission";
import { Translation } from "../../../../components/Translation";
import { momentDate, showAlias } from "../../../../utils/common";
import { locale } from "../../../../utils/locale";
import { statusList } from "../../constants";
class TableList extends Component {
  constructor(props) {
    super(props);
    this.onRollback = (record, ok) => {
      const { applicationDetail, dispatch } = this.props;
      if (record.status === "terminated" && !ok) {
        Dialog.confirm({
          type: "confirm",
          content: /* @__PURE__ */ React.createElement(Translation, null, "This revision status is terminated, are you sure to rollback to this revision?"),
          onOk: () => {
            this.onRollback(record, true);
          },
          locale: locale().Dialog
        });
        return;
      }
      if (applicationDetail) {
        rollbackWithRevision({ appName: applicationDetail?.name, revision: record.version }).then(
          (res) => {
            if (res) {
              Message.success("Application rollback successfully");
              if (res.record && res.record.name && dispatch) {
                dispatch(
                  routerRedux.push(
                    `/applications/${applicationDetail.name}/envbinding/${record.envName}/workflow/records/${res.record.name}`
                  )
                );
              }
            }
          }
        );
      }
    };
    this.loadChanges = (revision, mode) => {
      const { applicationDetail } = this.props;
      if (!revision || !applicationDetail) {
        this.setState({ compare: void 0 });
        return;
      }
      let params = {
        compareRevisionWithLatest: { revision: revision.version }
      };
      if (mode === "cluster") {
        params = {
          compareRevisionWithRunning: { revision: revision.version }
        };
      }
      compareApplication(applicationDetail?.name, params).then((res) => {
        this.setState({
          revision,
          compare: res,
          visibleApplicationDiff: true,
          diffMode: mode
        });
      });
    };
    this.getColumns = () => {
      const { applicationDetail } = this.props;
      return [
        {
          key: "version",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Revision"),
          dataIndex: "version",
          cell: (v, i, record) => {
            if (record.codeInfo) {
              return /* @__PURE__ */ React.createElement(Balloon, { style: { width: "220px" }, trigger: /* @__PURE__ */ React.createElement("a", null, v) }, /* @__PURE__ */ React.createElement(
                Item,
                {
                  label: "Commit",
                  value: /* @__PURE__ */ React.createElement("a", { title: record.codeInfo.commit }, record.codeInfo.commit?.slice(0, 7))
                }
              ), /* @__PURE__ */ React.createElement(Item, { label: "Branch", value: record.codeInfo.branch }), /* @__PURE__ */ React.createElement(Item, { label: "User", value: record.codeInfo.user }));
            }
            return /* @__PURE__ */ React.createElement("span", null, v);
          }
        },
        {
          key: "deployUser",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Deploy User"),
          dataIndex: "deployUser",
          cell: (v) => {
            return showAlias(v.name, v.alias);
          }
        },
        {
          key: "status",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Status"),
          dataIndex: "status",
          cell: (v, i, record) => {
            const findObj = statusList.find((item) => item.value === v);
            if (findObj) {
              const show = /* @__PURE__ */ React.createElement("div", null, v === "failure" && /* @__PURE__ */ React.createElement("span", { className: "circle circle-failure" }), v === "terminated" && /* @__PURE__ */ React.createElement("span", { className: "circle circle-warning" }), v === "complete" && /* @__PURE__ */ React.createElement("span", { className: "circle circle-success" }), /* @__PURE__ */ React.createElement(Translation, null, findObj.label));
              if (record.reason) {
                return /* @__PURE__ */ React.createElement(Balloon, { trigger: show }, record.reason);
              }
              return show;
            }
            return "";
          }
        },
        {
          key: "triggerType",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Trigger Type"),
          dataIndex: "triggerType"
        },
        {
          key: "createTime",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Publish Time"),
          dataIndex: "createTime",
          cell: (v) => {
            return /* @__PURE__ */ React.createElement("span", null, v && momentDate(v));
          }
        },
        {
          key: "envName",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Environment"),
          dataIndex: "envName",
          cell: (v) => {
            return /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(Link, { to: `/applications/${applicationDetail && applicationDetail.name}/envbinding/${v}/status` }, v));
          }
        },
        {
          key: "operation",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Actions"),
          dataIndex: "operation",
          cell: (v, i, record) => {
            return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
              Permission,
              {
                project: applicationDetail?.project?.name,
                request: {
                  resource: `project:${applicationDetail?.project?.name}/application:${applicationDetail?.name}`,
                  action: "detail"
                }
              },
              /* @__PURE__ */ React.createElement(
                Button,
                {
                  text: true,
                  size: "medium",
                  component: "a",
                  onClick: () => {
                    this.props.onShowAppModel(record);
                  }
                },
                /* @__PURE__ */ React.createElement(Translation, null, "Detail")
              )
            ), /* @__PURE__ */ React.createElement("span", { className: "line" }), /* @__PURE__ */ React.createElement(
              Dropdown,
              {
                trigger: /* @__PURE__ */ React.createElement(Button, { text: true, size: "medium", component: "a" }, /* @__PURE__ */ React.createElement(Translation, null, "Diff"))
              },
              /* @__PURE__ */ React.createElement(Menu, null, /* @__PURE__ */ React.createElement(
                Menu.Item,
                {
                  onClick: () => {
                    this.loadChanges(record, "cluster");
                  }
                },
                /* @__PURE__ */ React.createElement(Translation, null, "Diff With Deployed Application")
              ), /* @__PURE__ */ React.createElement(
                Menu.Item,
                {
                  onClick: () => {
                    this.loadChanges(record, "latest");
                  }
                },
                /* @__PURE__ */ React.createElement(Translation, null, "Diff With Latest Configuration")
              ))
            ), /* @__PURE__ */ React.createElement("span", { className: "line" }), /* @__PURE__ */ React.createElement(If, { condition: record.status === "complete" || record.status == "terminated" }, /* @__PURE__ */ React.createElement(
              Permission,
              {
                project: applicationDetail?.project?.name,
                request: {
                  resource: `project:${applicationDetail?.project?.name}/application:${applicationDetail?.name}/revision:${record.version}`,
                  action: "rollback"
                }
              },
              /* @__PURE__ */ React.createElement(
                Button,
                {
                  text: true,
                  size: "medium",
                  component: "a",
                  onClick: () => {
                    this.onRollback(record);
                  }
                },
                /* @__PURE__ */ React.createElement(Translation, null, "Rollback")
              )
            )));
          }
        }
      ];
    };
    this.state = {
      diffMode: "latest",
      visibleApplicationDiff: false
    };
  }
  render() {
    const { Column } = Table;
    const columns = this.getColumns();
    const { list } = this.props;
    const { visibleApplicationDiff, compare, diffMode, revision } = this.state;
    const baseName = "Current Revision";
    let targetName = "Latest Application Configuration";
    if (diffMode == "cluster") {
      targetName = "Deployed Application";
    }
    return /* @__PURE__ */ React.createElement("div", { className: "table-version-list  margin-top-20", style: { overflow: "auto" } }, /* @__PURE__ */ React.createElement(
      Table,
      {
        locale: locale().Table,
        primaryKey: "version",
        style: { minWidth: "1000px" },
        className: "customTable",
        rowHeight: 40,
        dataSource: list,
        loading: false,
        emptyContent: /* @__PURE__ */ React.createElement(Empty, null)
      },
      columns.map((col) => /* @__PURE__ */ React.createElement(Column, { ...col, key: col.key, align: "left" }))
    ), /* @__PURE__ */ React.createElement(If, { condition: visibleApplicationDiff }, compare && /* @__PURE__ */ React.createElement(
      ApplicationDiff,
      {
        onClose: () => {
          this.setState({
            visibleApplicationDiff: false,
            compare: void 0,
            revision: void 0
          });
        },
        rollback2Revision: diffMode === "cluster" && revision ? () => {
          this.onRollback(revision);
        } : void 0,
        baseName,
        targetName,
        compare
      }
    )));
  }
}
export default TableList;
