"use strict";
import { Grid, Field, Form, Input, Button } from "@alifd/next";
import React, { Component } from "react";
import { detailWorkflowDefinition } from "../../api/workflows";
import { UISchemaContext, WorkflowContext } from "../../context";
import Group from "../../extends/Group";
import "./index.less";
import { StepSelect } from "../../extends/StepSelect";
import i18n from "../../i18n";
import { replaceUrl } from "../../utils/common";
import DrawerWithFooter from "../Drawer";
import { If } from "../If";
import { Translation } from "../Translation";
import UISchema from "../UISchema";
import { InputItems } from "./input-item";
import { OutputItems } from "./output-item";
import { BiCodeBlock, BiLaptop } from "react-icons/bi";
class StepForm extends Component {
  constructor(props) {
    super(props);
    this.componentDidMount = () => {
      const { properties, type } = this.props.step;
      this.field.setValues(this.props.step);
      if (properties && typeof properties === "string") {
        this.field.setValues({ properties: JSON.parse(properties) });
      }
      this.onDetailDefinition(type);
    };
    this.setValues = (values) => {
      if (values) {
        const { consumerData } = values;
        this.field.setValues(consumerData);
      }
    };
    this.onSubmit = () => {
      this.field.validate((error, values) => {
        if (error) {
          return;
        }
        const { step } = this.props;
        this.props.onUpdate({ ...step, ...values });
      });
    };
    this.onDetailDefinition = (value, callback) => {
      this.setState({ definitionLoading: true });
      detailWorkflowDefinition({ name: value }).then((re) => {
        if (re) {
          this.setState({ definitionDetail: re, definitionLoading: false });
          if (callback) {
            callback();
          }
        }
      }).catch(() => this.setState({ definitionLoading: false }));
    };
    this.handleChang = (value) => {
      this.removeProperties();
      this.field.setValues({ type: value });
      this.onDetailDefinition(value);
    };
    this.removeProperties = () => {
      this.field.remove("properties");
      this.setState({ definitionDetail: void 0 });
    };
    this.state = {
      definitionLoading: false,
      propertiesMode: "native"
    };
    this.field = new Field(this, {
      onChange: (name, value) => {
        if (name == "type") {
          this.field.setValue("name", value);
        }
      }
    });
    this.uiSchemaRef = React.createRef();
  }
  render() {
    const { init } = this.field;
    const { Row, Col } = Grid;
    const FormItem = Form.Item;
    const { onClose, isSubStep } = this.props;
    const { definitionDetail, propertiesMode } = this.state;
    const validator = (rule, value, callback) => {
      this.uiSchemaRef.current?.validate(callback);
    };
    const { workflow } = this.context;
    const mode = isSubStep ? workflow.subMode : workflow.mode;
    const groupStep = this.field.getValue("type") == "step-group";
    return /* @__PURE__ */ React.createElement(
      DrawerWithFooter,
      {
        title: /* @__PURE__ */ React.createElement(Translation, null, "Edit Step"),
        placement: "right",
        width: 800,
        onClose,
        onOk: this.onSubmit,
        extButtons: [
          /* @__PURE__ */ React.createElement(Button, { key: "cancel", style: { marginRight: "16px" }, onClick: onClose }, /* @__PURE__ */ React.createElement(Translation, null, "Cancel"))
        ]
      },
      /* @__PURE__ */ React.createElement(Form, { field: this.field }, !groupStep && /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24 }, /* @__PURE__ */ React.createElement(
        Group,
        {
          title: "Properties",
          description: "Set the configuration parameters for the Workflow or Pipeline step.",
          closed: false,
          required: true,
          hasToggleIcon: true
        },
        /* @__PURE__ */ React.createElement(If, { condition: definitionDetail }, /* @__PURE__ */ React.createElement(FormItem, { required: true }, /* @__PURE__ */ React.createElement(If, { condition: definitionDetail && definitionDetail.uiSchema }, /* @__PURE__ */ React.createElement("div", { className: "flexright" }, /* @__PURE__ */ React.createElement(
          Button,
          {
            style: { marginTop: "-12px", alignItems: "center", display: "flex" },
            onClick: () => {
              if (propertiesMode === "native") {
                this.setState({ propertiesMode: "code" });
              } else {
                this.setState({ propertiesMode: "native" });
              }
            }
          },
          propertiesMode === "native" && /* @__PURE__ */ React.createElement(BiCodeBlock, { size: 14, title: i18n.t("Switch to the coding mode") }),
          propertiesMode === "code" && /* @__PURE__ */ React.createElement(BiLaptop, { size: 14, title: i18n.t("Switch to the native mode") })
        ))), /* @__PURE__ */ React.createElement(UISchemaContext.Provider, { value: this.context }, /* @__PURE__ */ React.createElement(
          UISchema,
          {
            ...init(`properties`, {
              rules: [
                {
                  validator,
                  message: i18n.t("Please check the properties of the workflow step")
                }
              ]
            }),
            enableCodeEdit: propertiesMode === "code",
            uiSchema: definitionDetail && definitionDetail.uiSchema,
            definition: {
              type: "workflowstep",
              name: definitionDetail?.name || "",
              description: definitionDetail?.description || ""
            },
            ref: this.uiSchemaRef,
            mode: "edit"
          }
        ))))
      ))), /* @__PURE__ */ React.createElement(
        Group,
        {
          title: i18n.t("Advanced Configs"),
          description: i18n.t("Configure the inputs, outputs, timeout, and dependsOn, etc fields for the step."),
          initClose: true,
          hasToggleIcon: true,
          required: true
        },
        mode === "DAG" && /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(Form.Item, { label: /* @__PURE__ */ React.createElement(Translation, null, "DependsOn") }, /* @__PURE__ */ React.createElement(StepSelect, { disabled: false, ...init("dependsOn") })))),
        /* @__PURE__ */ React.createElement(Row, { wrap: true }, /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(
          FormItem,
          {
            label: /* @__PURE__ */ React.createElement(Translation, null, "If"),
            help: /* @__PURE__ */ React.createElement(
              "div",
              {
                dangerouslySetInnerHTML: {
                  __html: replaceUrl("Reference: http://kubevela.net/docs/end-user/workflow/if-condition")
                }
              }
            )
          },
          /* @__PURE__ */ React.createElement(
            Input,
            {
              name: "if",
              ...init("if", {
                rules: []
              })
            }
          )
        )), !groupStep && /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(
          FormItem,
          {
            label: /* @__PURE__ */ React.createElement(Translation, null, "Inputs"),
            help: /* @__PURE__ */ React.createElement(
              "div",
              {
                dangerouslySetInnerHTML: {
                  __html: replaceUrl("Reference: http://kubevela.net/docs/end-user/workflow/inputs-outputs")
                }
              }
            )
          },
          /* @__PURE__ */ React.createElement(InputItems, { ...init("inputs") })
        )), !groupStep && /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(
          FormItem,
          {
            label: /* @__PURE__ */ React.createElement(Translation, null, "Outputs"),
            help: /* @__PURE__ */ React.createElement(
              "div",
              {
                dangerouslySetInnerHTML: {
                  __html: replaceUrl("Reference: http://kubevela.net/docs/end-user/workflow/inputs-outputs")
                }
              }
            )
          },
          /* @__PURE__ */ React.createElement(OutputItems, { ...init("outputs") })
        ))),
        /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Timeout") }, /* @__PURE__ */ React.createElement(
          Input,
          {
            name: "timeout",
            ...init("timeout", {
              rules: [
                {
                  minLength: 2,
                  maxLength: 64,
                  message: "Enter a string of 2 to 64 characters."
                }
              ]
            })
          }
        ))), /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Alias") }, /* @__PURE__ */ React.createElement(
          Input,
          {
            name: "alias",
            ...init("alias", {
              rules: [
                {
                  minLength: 2,
                  maxLength: 64,
                  message: "Enter a string of 2 to 64 characters."
                }
              ]
            })
          }
        )))),
        /* @__PURE__ */ React.createElement(Row, { wrap: true }, /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Description") }, /* @__PURE__ */ React.createElement(
          Input,
          {
            name: "description",
            ...init("description", {
              rules: [
                {
                  maxLength: 256,
                  message: "Enter a description that contains less than 256 characters."
                }
              ]
            })
          }
        ))))
      ))
    );
  }
}
StepForm.contextType = WorkflowContext;
StepForm.contextType = WorkflowContext;
export default StepForm;
