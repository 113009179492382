"use strict";
import { Balloon, Tag } from "@alifd/next";
import classNames from "classnames";
import * as dagre from "dagre";
import React, { useState } from "react";
import { Translation } from "../Translation";
import { describeComponents, getGraphSize, ResourceIcon } from "./utils";
import "./component-node.less";
import { If } from "../If";
function renderTraitTree(traits) {
  const graph = new dagre.graphlib.Graph();
  graph.setGraph({
    nodesep: 20,
    rankdir: "TB",
    align: "UL",
    ranksep: 26,
    compound: true
  });
  graph.setNode("graph-trait-start", {
    width: 5,
    height: 40,
    x: 0,
    y: 0
  });
  traits.map((trait) => {
    graph.setEdge("graph-trait-start", trait.type, {});
    graph.setNode(trait.type, {
      trait,
      width: 130,
      height: 30,
      x: 0,
      y: 0
    });
  });
  dagre.layout(graph);
  const edges = [];
  graph.edges().forEach((edgeInfo) => {
    const edge = graph.edge(edgeInfo);
    const lines = [];
    if (edge.points && edge.points.length > 1) {
      for (let i = 1; i < edge.points.length; i++) {
        lines.push({
          x1: edge.points[i - 1].x,
          y1: edge.points[i - 1].y - 14,
          x2: edge.points[i].x,
          y2: edge.points[i].y - 14
        });
      }
    }
    edges.push({
      from: edgeInfo.v,
      to: edgeInfo.w,
      lines
    });
  });
  const size = getGraphSize(graph.nodes().map((key) => graph.node(key)));
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: "trait-graph",
      style: {
        width: size.width,
        height: size.height,
        left: 190,
        transformOrigin: "0% 0%",
        transform: `scale(${1})`
      }
    },
    graph.nodes().map((key) => {
      if (key === "graph-trait-start") {
        return;
      }
      const { trait, x, y, width, height } = graph.node(key);
      if (!trait) {
        return;
      }
      const label = trait.type;
      const traitNode = /* @__PURE__ */ React.createElement(
        "div",
        {
          key,
          id: label,
          className: classNames("graph-node", "trait-node"),
          style: {
            left: x + 22,
            top: y - 10,
            width,
            height,
            transform: `translate(-60px, 0px)`
          }
        },
        /* @__PURE__ */ React.createElement("div", { className: classNames("trait") }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
          "span",
          {
            className: classNames("circle", {
              "circle-success": trait.healthy,
              "circle-failure": !trait.healthy
            })
          }
        ), label))
      );
      if (trait.message) {
        return /* @__PURE__ */ React.createElement(Balloon, { trigger: traitNode }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("p", { className: "line" }, `Message: ${trait.message}`)));
      }
      return traitNode;
    }),
    edges.map((edge) => /* @__PURE__ */ React.createElement("div", { key: `${edge.from}-${edge.to}`, className: "graph-edge" }, edge.lines.map((line) => {
      const distance = Math.sqrt(Math.pow(line.x1 - line.x2, 2) + Math.pow(line.y1 - line.y2, 2));
      const xMid = (line.x1 + line.x2) / 2;
      const yMid = (line.y1 + line.y2) / 2;
      const angle = Math.atan2(line.y1 - line.y2, line.x1 - line.x2) * 180 / Math.PI;
      return /* @__PURE__ */ React.createElement(
        "div",
        {
          className: "graph-edge-line",
          key: "line" + line.x2 + line.y2,
          style: {
            width: distance,
            left: xMid - distance / 2,
            top: yMid,
            transform: `translate(40px, 30px) rotate(${angle}deg)`
          }
        }
      );
    })))
  );
}
export const ComponentNode = (props) => {
  const { node } = props;
  const traits = node.resource.service?.traits || [];
  const [showTrait, setShowTrait] = useState(props.showTrait);
  const WithBalloon = (graphNode2) => {
    return /* @__PURE__ */ React.createElement(Balloon, { trigger: graphNode2 }, /* @__PURE__ */ React.createElement("div", null, describeComponents(node).map((line) => {
      return /* @__PURE__ */ React.createElement("p", { className: "line" }, line);
    })));
  };
  const graphNode = /* @__PURE__ */ React.createElement(
    "div",
    {
      className: classNames("graph-node", "graph-node-resource", {
        "warning-status": !node.resource.service?.healthy
      }),
      style: {
        // 50 = (nodeWidth - 220)/2
        left: node.x - 50,
        top: node.y,
        width: node.width,
        height: node.height,
        transform: `translate(-80px, 0px)`
      }
    },
    WithBalloon(
      /* @__PURE__ */ React.createElement("div", { className: classNames("icon") }, /* @__PURE__ */ React.createElement(ResourceIcon, { kind: node.resource.component?.componentType.substring(0, 1).toUpperCase() || "" }))
    ),
    WithBalloon(
      /* @__PURE__ */ React.createElement("div", { className: classNames("name") }, /* @__PURE__ */ React.createElement("div", null, node.resource.name), /* @__PURE__ */ React.createElement("div", { className: classNames("healthy", { success: node.resource.service?.healthy }) }, /* @__PURE__ */ React.createElement(If, { condition: node.resource.service?.healthy }, /* @__PURE__ */ React.createElement("span", { className: "circle circle-success" }), /* @__PURE__ */ React.createElement(Translation, null, "Healthy")), /* @__PURE__ */ React.createElement(If, { condition: !node.resource.service?.healthy }, /* @__PURE__ */ React.createElement("span", { className: "circle circle-warning" }), /* @__PURE__ */ React.createElement(Translation, null, "UnHealthy"))))
    ),
    /* @__PURE__ */ React.createElement(If, { condition: traits.length > 0 }, /* @__PURE__ */ React.createElement("div", { className: classNames("label-traits") }, traits && traits.length > 0 && traits[0] && /* @__PURE__ */ React.createElement(Tag, { animation: true }, /* @__PURE__ */ React.createElement(
      "span",
      {
        className: classNames("circle", {
          "circle-success": traits[0].healthy,
          "circle-failure": !traits[0].healthy
        })
      }
    ), traits[0].type), /* @__PURE__ */ React.createElement(If, { condition: traits?.length > 1 }, /* @__PURE__ */ React.createElement(
      "div",
      {
        className: classNames("trait-num", { active: showTrait }),
        color: "blue",
        style: { marginLeft: "8px" },
        onClick: () => setShowTrait(!showTrait)
      },
      traits?.length > 1 && "+" + (traits?.length - 1)
    )))),
    traits.length > 1 && showTrait && renderTraitTree(traits)
  );
  return graphNode;
};
