"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Grid } from "@alifd/next";
import { connect } from "dva";
import { Link } from "dva/router";
import React, { Component, Fragment } from "react";
import { Translation } from "../../components/Translation";
import { Breadcrumb } from "../../components/Breadcrumb";
import "./index.less";
import { checkPermission } from "../../utils/permission";
import classNames from "classnames";
const { Row, Col } = Grid;
let ProjectLayout = class extends Component {
  constructor() {
    super(...arguments);
    this.loadProjectDetail = async (projectName) => {
      this.props.dispatch({
        type: "project/getProjectDetails",
        payload: { projectName }
      });
    };
    this.getNavList = () => {
      const { params = { projectName: "" } } = this.props.match;
      const { projectName } = params;
      const { userInfo } = this.props;
      const list = [
        {
          id: "summary",
          name: /* @__PURE__ */ React.createElement(Translation, null, "Summary"),
          to: `/projects/${projectName}/summary`
        },
        {
          id: "applications",
          name: /* @__PURE__ */ React.createElement(Translation, null, "Applications"),
          to: `/projects/${projectName}/applications`
        },
        {
          id: "pipelines",
          name: /* @__PURE__ */ React.createElement(Translation, null, "Pipelines"),
          to: `/projects/${projectName}/pipelines`
        }
      ];
      if (checkPermission({ resource: `project:${projectName}/role:*`, action: "list" }, projectName, userInfo)) {
        list.push({
          id: "roles",
          name: /* @__PURE__ */ React.createElement(Translation, null, "Roles"),
          to: `/projects/${projectName}/roles`
        });
      }
      if (checkPermission({ resource: `project:${projectName}/projectUser:*`, action: "list" }, projectName, userInfo)) {
        list.push({
          id: "members",
          name: /* @__PURE__ */ React.createElement(Translation, null, "Members"),
          to: `/projects/${projectName}/members`
        });
      }
      const nav = list.map((item) => {
        const active = this.props.activeId === item.id ? "active" : "";
        return /* @__PURE__ */ React.createElement(Link, { key: item.id, className: active, to: item.to }, item.name);
      });
      return nav;
    };
  }
  componentDidMount() {
    const { params = { projectName: "" } } = this.props.match;
    this.loadProjectDetail(params.projectName);
  }
  shouldComponentUpdate(nextProps) {
    const change = nextProps.match.params.projectName !== this.props.match.params.projectName;
    if (change) {
      const { params = { projectName: "" } } = nextProps.match;
      this.loadProjectDetail(params.projectName);
    }
    return true;
  }
  render() {
    const menu = this.getNavList();
    const { projectDetails } = this.props;
    const projectName = projectDetails?.alias || projectDetails?.name || "";
    return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 6, className: classNames("breadcrumb") }, /* @__PURE__ */ React.createElement(
      Breadcrumb,
      {
        items: [
          {
            to: "/projects",
            title: "Projects"
          },
          {
            title: projectName
          }
        ]
      }
    ))), /* @__PURE__ */ React.createElement("nav", { className: "project-detail-wrapper" }, /* @__PURE__ */ React.createElement("ul", null, menu)), this.props.children);
  }
};
ProjectLayout = __decorateClass([
  connect((store) => {
    return { ...store.project, ...store.user };
  })
], ProjectLayout);
export default ProjectLayout;
