"use strict";
import classNames from "classnames";
import React, { useState } from "react";
import { timeDiff } from "../../../utils/common";
import { If } from "../../If";
import { renderStepStatusIcon } from "./step-icon";
export const Step = (props) => {
  const { step, output, input, onNodeClick, group } = props;
  const { stepWidth, stepInterval } = props.probeState;
  const [isActive, setActive] = useState(false);
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: classNames("step", { active: isActive }, { group }),
      onMouseEnter: () => setActive(true),
      onMouseLeave: () => setActive(false),
      style: { marginRight: stepInterval + "px" },
      onClick: (event) => {
        if (!group) {
          onNodeClick(props.step);
          event.stopPropagation();
        }
      }
    },
    /* @__PURE__ */ React.createElement(If, { condition: group }, /* @__PURE__ */ React.createElement("div", { className: "step-title" }, step.name || step.id), /* @__PURE__ */ React.createElement("div", { className: "groups", style: { width: stepWidth + "px", minHeight: "70px" } }, step.subSteps?.map((subStep, index) => {
      return /* @__PURE__ */ React.createElement(
        "div",
        {
          className: "step-status",
          key: "step-" + (subStep.id || subStep.name) + index,
          onClick: (event) => {
            onNodeClick(subStep);
            event.stopPropagation();
          }
        },
        /* @__PURE__ */ React.createElement("div", null, renderStepStatusIcon(subStep)),
        /* @__PURE__ */ React.createElement("div", { className: "step-name" }, subStep.alias || subStep.name || subStep.id),
        /* @__PURE__ */ React.createElement("div", null, timeDiff(subStep.firstExecuteTime, subStep.lastExecuteTime))
      );
    }))),
    /* @__PURE__ */ React.createElement(If, { condition: !group }, /* @__PURE__ */ React.createElement("div", { className: "groups", style: { width: stepWidth + "px" } }, /* @__PURE__ */ React.createElement("div", { className: "step-status" }, /* @__PURE__ */ React.createElement("div", null, renderStepStatusIcon(step)), /* @__PURE__ */ React.createElement("div", { className: "step-name" }, step.alias || step.name || step.id), /* @__PURE__ */ React.createElement("div", { className: "" }, timeDiff(step.firstExecuteTime, step.lastExecuteTime))))),
    /* @__PURE__ */ React.createElement(If, { condition: output }, /* @__PURE__ */ React.createElement("div", { className: "workflow-step-port workflow-step-port-output step-circle" })),
    /* @__PURE__ */ React.createElement(If, { condition: input }, /* @__PURE__ */ React.createElement("div", { className: "workflow-step-port workflow-step-port-input step-circle" }))
  );
};
