"use strict";
import { Grid, Input, Form, Select, Button } from "@alifd/next";
import _ from "lodash";
import React, { useContext, useState } from "react";
import { IoMdAdd, IoMdRemove } from "react-icons/io";
import { WorkflowEditContext } from "../../context";
import { locale } from "../../utils/locale";
const { Row, Col } = Grid;
export const InputItemForm = (props) => {
  const [from, setFrom] = useState(props.value ? props.value.from : "");
  const [parameterKey, setParameterKey] = useState(props.value?.parameterKey || "");
  const fromChange = (value) => {
    setFrom(value);
    if (value != "" && parameterKey != "") {
      props.onChange({ from: value, parameterKey });
    }
  };
  const parameterKeyChange = (value) => {
    setParameterKey(value);
    if (value != "" && from != "") {
      props.onChange({ from, parameterKey: value });
    }
  };
  const { steps, stepName } = useContext(WorkflowEditContext);
  const fromItemOptions = [];
  const convertOutputs = (outputs) => {
    if (!outputs) {
      return;
    }
    outputs.map((out) => {
      fromItemOptions.push({ value: out.name });
    });
  };
  steps?.map((step) => {
    if (step.name != stepName) {
      convertOutputs(step.outputs);
    }
    step.subSteps?.map((subStep) => {
      if (subStep.name != stepName) {
        convertOutputs(subStep.outputs);
      }
    });
  });
  return /* @__PURE__ */ React.createElement("div", { className: "item-form" }, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { className: "from", span: 12, style: { paddingLeft: "8px" } }, /* @__PURE__ */ React.createElement(Form.Item, { label: "From", labelAlign: "inset" }, /* @__PURE__ */ React.createElement(Select, { dataSource: fromItemOptions, locale: locale().Select, value: from, onChange: fromChange }))), /* @__PURE__ */ React.createElement(Col, { span: 12, style: { paddingRight: "8px" } }, /* @__PURE__ */ React.createElement(Form.Item, { label: "ParameterKey", labelAlign: "inset" }, /* @__PURE__ */ React.createElement(Input, { value: parameterKey, onChange: parameterKeyChange })))));
};
export const InputItems = (props) => {
  const [items, setItems] = useState(props.value || [{ from: "", parameterKey: "" }]);
  const onChange = (update) => {
    props.onChange(update.filter((item) => item.from != "" && item.parameterKey != ""));
  };
  return /* @__PURE__ */ React.createElement("div", { id: props.id, className: "input-items" }, items?.map((item, index) => {
    return /* @__PURE__ */ React.createElement("div", { className: "item", key: "input" + index }, /* @__PURE__ */ React.createElement(
      InputItemForm,
      {
        key: item.from,
        value: item,
        onChange: (input) => {
          items[index].from = input.from;
          items[index].parameterKey = input.parameterKey;
          onChange(items);
        }
      }
    ), index != 0 && /* @__PURE__ */ React.createElement("div", { className: "item-delete" }, /* @__PURE__ */ React.createElement(
      Button,
      {
        onClick: () => {
          const newItems = _.cloneDeep(items);
          newItems.splice(index, 1);
          setItems(newItems);
          onChange(newItems);
        },
        size: "small",
        type: "secondary",
        style: { justifyContent: "center", marginLeft: "8px" }
      },
      /* @__PURE__ */ React.createElement(IoMdRemove, null)
    )));
  }), /* @__PURE__ */ React.createElement(
    Button,
    {
      onClick: () => {
        const newItems = _.cloneDeep(items);
        newItems.push({ from: "", parameterKey: "" });
        setItems(newItems);
      },
      size: "small",
      type: "secondary",
      style: { justifyContent: "center", marginLeft: "8px" }
    },
    /* @__PURE__ */ React.createElement(IoMdAdd, null)
  ));
};
