"use strict";
import { getDomain } from "../utils/common";
import { application } from "./productionLink";
import { post, get, rdelete, put } from "./request";
const baseURLOject = getDomain();
const url = baseURLOject.APIBASE + application;
export function getApplicationList(params) {
  return get(url, { params }).then((res) => res);
}
export function createApplication(params) {
  const createURL = `${url}?project=${params.project}`;
  return post(createURL, params).then((res) => res);
}
export function getApplicationDetails(params) {
  return get(`${url}/${params.name}`, params).then((res) => res);
}
export function getApplicationStatus(params) {
  return get(`${url}/${params.name}/envs/${params.envName}/status`, params).then((res) => res);
}
export function getApplicationAllStatus(params) {
  return get(`${url}/${params.name}/status`, params).then((res) => res);
}
export function deleteApplication(params) {
  return rdelete(url + "/" + params.name, params);
}
export function getApplicationComponents(params) {
  const { appName } = params;
  return get(`${url}/${appName}/components`, {}).then((res) => res);
}
export function createApplicationComponent(params, query) {
  return post(`${url}/${query.appName}/components`, params).then((res) => res);
}
export function getComponentDetails(params) {
  return post(`${url}/${params.name}/components/${params.componentName}`, params).then((res) => res);
}
export function deployApplication(params, customError) {
  return post(`${url}/${params.appName}/deploy`, params, customError);
}
export function getPolicyList(params) {
  return get(`${url}/${params.appName}/policies`, params).then((res) => res);
}
export function createPolicy(appName, params) {
  return post(`${url}/${appName}/policies`, params).then((res) => res);
}
export function updatePolicy(appName, policyName, params) {
  return put(`${url}/${appName}/policies/${policyName}`, params).then((res) => res);
}
export function getPolicyDetail(params) {
  return get(`${url}/${params.appName}/policies/${params.policyName}`, params).then((res) => res);
}
export function deletePolicy(params) {
  const gURL = `${url}/${params.appName}/policies/${params.policyName}`;
  if (params.force) {
    return rdelete(gURL, { params: { force: true } }, true).then((res) => res);
  }
  return rdelete(gURL, {}, true).then((res) => res);
}
export function createApplicationTemplate(params) {
  return post(`${url}/${params.name}/template`, params).then((res) => res);
}
export function createApplicationEnvbinding(params) {
  return post(`${url}/${params.appName}/envs`, params).then((res) => res);
}
export function updateApplicationEnvbinding(params) {
  return put(`${url}/${params.appName}/envs/${params.name}`, params).then((res) => res);
}
export function getApplicationEnvbinding(params) {
  return get(`${url}/${params.appName}/envs`, params).then((res) => res);
}
export function deleteApplicationEnvbinding(params) {
  return rdelete(`${url}/${params.appName}/envs/${params.envName}`, {}).then((res) => res);
}
export function recycleApplicationEnvbinding(params) {
  return post(`${url}/${params.appName}/envs/${params.envName}/recycle`, {}).then((res) => res);
}
export function getApplicationComponent(appName, componentName) {
  return get(`${url}/${appName}/components/${componentName}`, {}).then((res) => res);
}
export function createTrait(params, query) {
  const { appName, componentName } = query;
  return post(`${url}/${appName}/components/${componentName}/traits`, params).then((res) => res);
}
export function updateTrait(params, query) {
  const { appName, componentName, traitType } = query;
  return put(`${url}/${appName}/components/${componentName}/traits/${traitType}`, params).then((res) => res);
}
export function deleteTrait(query) {
  const { appName, componentName, traitType } = query;
  return rdelete(`${url}/${appName}/components/${componentName}/traits/${traitType}`, {}).then((res) => res);
}
export function listRevisions(query) {
  const { appName } = query;
  return get(`${url}/${appName}/revisions`, { params: query }).then((res) => res);
}
export function detailRevision(query) {
  const { appName, revision } = query;
  return get(`${url}/${appName}/revisions/${revision}`, {}).then((res) => res);
}
export function rollbackWithRevision(query) {
  const { appName, revision } = query;
  return post(`${url}/${appName}/revisions/${revision}/rollback`, {}).then((res) => res);
}
export function getApplicationStatistics(params) {
  return get(`${url}/${params.appName}/statistics`, params).then((res) => res);
}
export function getApplicationWorkflowRecord(params) {
  return get(`${url}/${params.appName}/records`, params).then((res) => res);
}
export function getApplicationEnvRecords(params) {
  return get(`${url}/${params.appName}/envs/${params.envName}/records`, {
    params: {
      pageSize: params.pageSize || 20,
      page: params.page || 1
    }
  }).then((res) => res);
}
export function updateComponentProperties(params, query) {
  return put(`${url}/${query.appName}/components/${query.componentName}`, params).then((res) => res);
}
export function resumeApplicationWorkflowRecord(params) {
  const { appName, workflowName, recordName } = params;
  return get(`${url}/${appName}/workflows/${workflowName}/records/${recordName}/resume`, {}).then((res) => res);
}
export function rollbackApplicationWorkflowRecord(params) {
  const { appName, workflowName, recordName } = params;
  return get(`${url}/${appName}/workflows/${workflowName}/records/${recordName}/rollback`, {}).then((res) => res);
}
export function terminateApplicationWorkflowRecord(params) {
  const { appName, workflowName, recordName } = params;
  return get(`${url}/${appName}/workflows/${workflowName}/records/${recordName}/terminate`, {}).then((res) => res);
}
export function getApplicationTriggers(params) {
  const { appName } = params;
  return get(`${url}/${appName}/triggers`, {}).then((res) => res);
}
export function updateApplication(params) {
  return put(`${url}/${params.name}`, params).then((res) => res);
}
export function createTrigger(params, query) {
  const { appName } = query;
  return post(`${url}/${appName}/triggers`, params).then((res) => res);
}
export function updateTrigger(params, query) {
  const { appName, token } = query;
  return put(`${url}/${appName}/triggers/${token}`, params).then((res) => res);
}
export function deleteTrigger(params) {
  const { appName, token } = params;
  return rdelete(`${url}/${appName}/triggers/${token}`, {}).then((res) => res);
}
export function deleteComponent(query) {
  return rdelete(`${url}/${query.appName}/components/${query.componentName}`, {}).then((res) => res);
}
export function compareApplication(appName, params) {
  const _url = `${url}/${appName}/compare`;
  return post(_url, params).then((res) => res);
}
export function dryRunApplication(appName, params) {
  return post(`${url}/${appName}/dry-run`, params, true).then((res) => res);
}
