"use strict";
import { Select } from "@alifd/next";
import React, { useContext } from "react";
import { WorkflowContext, WorkflowEditContext } from "../../context";
import i18n from "../../i18n";
import { showAlias } from "../../utils/common";
import { locale } from "../../utils/locale";
export const StepSelect = (props) => {
  const { value, id, disabled } = props;
  const { stepName, steps } = useContext(WorkflowEditContext);
  const { workflow } = useContext(WorkflowContext);
  const stepOptions = [];
  let inGroup = false;
  let groupStep;
  steps?.map((step) => {
    step.subSteps?.map((subStep) => {
      if (subStep.name === stepName) {
        inGroup = true;
        groupStep = step;
      }
    });
  });
  if (workflow?.mode === "DAG" && (workflow.subMode === "DAG" || workflow.subMode === "StepByStep" && !inGroup)) {
    steps?.filter((s) => s.name !== stepName).map((step) => {
      stepOptions.push({
        label: showAlias(step.name, step.alias),
        value: step.name
      });
      step.subSteps?.filter((s) => s.name !== stepName).map((b) => {
        stepOptions.push({
          label: `${showAlias(step.name, step.alias)}/${showAlias(b.name, b.alias)}`,
          value: b.name
        });
      });
    });
  }
  if (workflow?.mode === "StepByStep" && workflow.subMode === "DAG" && inGroup && groupStep) {
    groupStep.subSteps?.filter((s) => s.name !== stepName).map((step) => {
      stepOptions.push({
        label: showAlias(step.name, step.alias),
        value: step.name
      });
    });
  }
  return /* @__PURE__ */ React.createElement(
    Select,
    {
      placeholder: i18n.t("Please select the steps"),
      onChange: props.onChange,
      id,
      disabled,
      defaultValue: value || [],
      value: value || [],
      dataSource: stepOptions,
      mode: "multiple",
      locale: locale().Select
    }
  );
};
