"use strict";
import { Input } from "@alifd/next";
import React from "react";
class MemoryNumber extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = (value) => {
      const { onChange } = this.props;
      if (onChange) {
        onChange(value + "Mi");
      }
    };
    this.state = {};
  }
  render() {
    const { value, id, disabled } = this.props;
    let initValue = void 0;
    if (value) {
      initValue = parseInt(value.replace("Mi", ""), 10);
    }
    return /* @__PURE__ */ React.createElement(
      Input,
      {
        id,
        min: "0",
        disabled,
        addonTextAfter: "Mi",
        htmlType: "number",
        onChange: this.onChange,
        value: initValue && initValue
      }
    );
  }
}
export default MemoryNumber;
