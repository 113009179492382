"use strict";
import { Grid, Form, Input, Field, Button, Message, Select } from "@alifd/next";
import React from "react";
import { createProjectUsers, updateProjectUser } from "../../../../api/project";
import DrawerWithFooter from "../../../../components/Drawer";
import { Translation } from "../../../../components/Translation";
import i18n from "../../../../i18n";
import { checkName } from "../../../../utils/common";
import { locale } from "../../../../utils/locale";
import { getSelectLabel } from "../../../../utils/utils";
class MemberDialog extends React.Component {
  constructor(props) {
    super(props);
    this.onClose = () => {
      this.props.onClose();
    };
    this.onCreate = () => {
      this.field.validate((error, values) => {
        if (error) {
          return;
        }
        const { isEditMember, projectName } = this.props;
        const { userName, userRoles } = values;
        const params = {
          userRoles,
          userName
        };
        const queryData = {
          projectName,
          userName
        };
        if (isEditMember) {
          updateProjectUser(queryData, params).then((res) => {
            if (res) {
              Message.success(/* @__PURE__ */ React.createElement(Translation, null, "Update member success"));
              this.props.onCreate();
            }
          });
        } else {
          createProjectUsers({ projectName }, params).then((res) => {
            if (res) {
              Message.success(/* @__PURE__ */ React.createElement(Translation, null, "Create member success"));
              this.props.onCreate();
            }
          });
        }
      });
    };
    this.showTitle = () => {
      const { isEditMember } = this.props;
      if (isEditMember) {
        return /* @__PURE__ */ React.createElement(Translation, null, "Edit Member");
      } else {
        return /* @__PURE__ */ React.createElement(Translation, null, "Add Member");
      }
    };
    this.showClickButtons = () => {
      const { isEditMember } = this.props;
      const { loading } = this.state;
      let createEle;
      if (isEditMember) {
        createEle = /* @__PURE__ */ React.createElement(Button, { type: "primary", onClick: this.onCreate, loading }, /* @__PURE__ */ React.createElement(Translation, null, "Update"));
      } else {
        createEle = /* @__PURE__ */ React.createElement(Button, { type: "primary", onClick: this.onCreate, loading }, /* @__PURE__ */ React.createElement(Translation, null, "Create"));
      }
      return [
        /* @__PURE__ */ React.createElement(Button, { type: "secondary", onClick: this.onClose, style: { marginRight: "16px" } }, /* @__PURE__ */ React.createElement(Translation, null, "Cancel")),
        createEle
      ];
    };
    this.field = new Field(this);
    this.state = {
      loading: false
    };
  }
  componentDidMount() {
    const { isEditMember, editMember } = this.props;
    if (isEditMember && editMember) {
      const { name, createTime, userRoles } = editMember;
      this.field.setValues({
        userName: name,
        createTime,
        userRoles
      });
    }
  }
  render() {
    const init = this.field.init;
    const { Row, Col } = Grid;
    const FormItem = Form.Item;
    const { isEditMember, projectRoles } = this.props;
    const formItemLayout = {
      labelCol: {
        fixedSpan: 6
      },
      wrapperCol: {
        span: 20
      }
    };
    const rolesList = getSelectLabel(projectRoles);
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
      DrawerWithFooter,
      {
        title: this.showTitle(),
        placement: "right",
        width: 800,
        onClose: this.onClose,
        extButtons: this.showClickButtons()
      },
      /* @__PURE__ */ React.createElement(Form, { ...formItemLayout, field: this.field }, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Username"), labelTextAlign: "left", required: true }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "userName",
          placeholder: i18n.t("Please enter").toString(),
          maxLength: 32,
          disabled: isEditMember ? true : false,
          ...init("userName", {
            rules: [
              {
                required: true,
                pattern: checkName,
                message: /* @__PURE__ */ React.createElement(Translation, null, "Please enter a valid userName")
              }
            ]
          })
        }
      )))), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "UserRoles"), required: true }, /* @__PURE__ */ React.createElement(
        Select,
        {
          ...init(`userRoles`, {
            rules: [
              {
                required: true,
                message: "Please select userRoles"
              }
            ]
          }),
          locale: locale().Select,
          mode: "tag",
          dataSource: rolesList
        }
      )))))
    ));
  }
}
export default MemberDialog;
