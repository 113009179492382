"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Pagination } from "@alifd/next";
import { connect } from "dva";
import React from "react";
import { listRevisions } from "../../api/application";
import { If } from "../../components/If";
import { locale } from "../../utils/locale";
import { ShowRevision } from "./components/Detail";
import Header from "./components/Header";
import TableList from "./components/List";
import { statusList } from "./constants";
import "./index.less";
let ApplicationRevisionList = class extends React.Component {
  constructor(props) {
    super(props);
    this.getRevisionList = async () => {
      const { appName, page, pageSize, envName, status } = this.state;
      const params = {
        appName,
        envName,
        status,
        page,
        pageSize
      };
      listRevisions(params).then((res) => {
        if (res) {
          this.setState({
            revisionsList: res.revisions || [],
            revisionsListTotal: res.total || 0
          });
        }
      });
    };
    this.updateQuery = (updateQuery) => {
      const { isChangeEnv, isChangeStatus, value } = updateQuery;
      if (isChangeEnv) {
        this.setState({ envName: value }, () => {
          this.getRevisionList();
        });
      } else if (isChangeStatus) {
        this.setState({ status: value }, () => {
          this.getRevisionList();
        });
      }
    };
    this.handleChange = (page) => {
      this.setState(
        {
          page
        },
        () => {
          this.getRevisionList();
        }
      );
    };
    this.showAppModel = (revision) => {
      this.setState({ showAppRevision: true, revision });
    };
    const { params } = props.match;
    this.state = {
      appName: params.appName,
      page: 1,
      pageSize: 10,
      envName: "",
      status: "",
      revisionsList: [],
      revisionsListTotal: 0,
      showAppRevision: false
    };
  }
  componentDidMount() {
    this.getRevisionList();
  }
  render() {
    const { revisionsList, showAppRevision, revision, appName } = this.state;
    const { envbinding, applicationDetail, dispatch } = this.props;
    return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
      Header,
      {
        envBinding: envbinding,
        statusList,
        updateQuery: (params) => {
          this.updateQuery(params);
        }
      }
    ), /* @__PURE__ */ React.createElement(
      TableList,
      {
        applicationDetail,
        list: revisionsList,
        onShowAppModel: this.showAppModel,
        getRevisionList: this.getRevisionList,
        dispatch
      }
    ), /* @__PURE__ */ React.createElement(
      Pagination,
      {
        locale: locale().Pagination,
        className: "revision-pagination text-align-right",
        total: this.state.revisionsListTotal,
        pageSize: this.state.pageSize,
        current: this.state.page,
        onChange: this.handleChange
      }
    ), /* @__PURE__ */ React.createElement(If, { condition: showAppRevision }, revision && /* @__PURE__ */ React.createElement(
      ShowRevision,
      {
        onClose: () => {
          this.setState({ showAppRevision: false, revision: void 0 });
        },
        appName,
        revision
      }
    )));
  }
};
ApplicationRevisionList = __decorateClass([
  connect((store) => {
    return { ...store.application };
  })
], ApplicationRevisionList);
export default ApplicationRevisionList;
