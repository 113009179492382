"use strict";
import React, { Component } from "react";
import { Translation } from "../Translation";
import { Form, Input, Select, Field, Switch, Grid, Divider } from "@alifd/next";
import "./index.less";
import i18n from "i18next";
import * as yaml from "js-yaml";
import { v4 as uuid } from "uuid";
import CPUNumber from "../../extends/CPUNumber";
import CertBase64 from "../../extends/CertBase64";
import ComponentPatches from "../../extends/ComponentPatches";
import ComponentSelect from "../../extends/ComponentSelect";
import DiskNumber from "../../extends/DiskNumber";
import Group from "../../extends/Group";
import HelmChartSelect from "../../extends/HelmChartSelect";
import HelmChartVersionSelect from "../../extends/HelmChartVersionSelect";
import HelmRepoSelect from "../../extends/HelmRepoSelect";
import HelmValues from "../../extends/HelmValues";
import ImageInput from "../../extends/ImageInput";
import K8sObjectsCode from "../../extends/K8sObjectsCode";
import KV from "../../extends/KV";
import MemoryNumber from "../../extends/MemoryNumber";
import PolicySelect from "../../extends/PolicySelect";
import SecretKeySelect from "../../extends/SecretKeySelect";
import SecretSelect from "../../extends/SecretSelect";
import Strings from "../../extends/Strings";
import Numbers from "../../extends/Numbers";
import Structs from "../../extends/Structs";
import { checkImageName, replaceUrl } from "../../utils/common";
import { locale } from "../../utils/locale";
import { getValue } from "../../utils/utils";
import DefinitionCode from "../DefinitionCode";
import { If } from "../If";
const { Col, Row } = Grid;
function convertRule(validate) {
  const rules = [];
  if (!validate) {
    return [];
  }
  if (validate.required) {
    rules.push({
      required: true,
      message: "This field is required."
    });
  }
  if (validate.min != void 0) {
    rules.push({
      min: validate.min,
      message: "Enter a number greater than " + validate.min
    });
  }
  if (validate.max != void 0) {
    rules.push({
      max: validate.max,
      message: "Enter a number less than " + validate.max
    });
  }
  if (validate.minLength != void 0) {
    rules.push({
      minLength: validate.minLength,
      message: `Enter a minimum of ${validate.minLength} characters.`
    });
  }
  if (validate.maxLength != void 0) {
    rules.push({
      maxLength: validate.maxLength,
      message: `Enter a maximum of ${validate.maxLength} characters.`
    });
  }
  if (validate.pattern) {
    rules.push({
      pattern: new RegExp(validate.pattern),
      message: `Please enter a value that conforms to the specification. ` + validate.pattern
    });
  }
  return rules;
}
class UISchema extends Component {
  constructor(props) {
    super(props);
    this.componentDidMount = () => {
      this.setValues();
    };
    this.onRegisterForm = (key, form) => {
      this.registerForm[key] = form;
    };
    this.onChangeAdvanced = (advanced) => {
      this.setState({ advanced });
    };
    // The upper component must set the values before init the UI Schema component.
    this.setValues = () => {
      const { value } = this.props;
      if (value) {
        this.form.setValues(value);
      }
    };
    this.validate = (callback) => {
      this.form.validate((errors) => {
        const { codeError } = this.state;
        if (errors) {
          console.log(errors);
          callback("ui schema validate failure");
          return;
        }
        if (codeError) {
          callback("ui schema validate failure");
          return;
        }
        callback();
      });
    };
    this.conditionAllowRender = (conditions) => {
      if (!conditions || conditions.length == 0) {
        return true;
      }
      const action = {
        disable: 0,
        enable: 0
      };
      let enableConditionCount = 0;
      conditions.map((condition) => {
        const values = this.form.getValues();
        const value = getValue(condition.jsonKey, values);
        if (condition.action == "enable" || !condition.action) {
          enableConditionCount += 1;
        }
        switch (condition.op) {
          case "in":
            if (Array.isArray(condition.value) && condition.value.includes(value)) {
              action[condition.action || "enable"] += 1;
            }
            break;
          case "!=":
            if (condition.value != value) {
              action[condition.action || "enable"] += 1;
            }
            break;
          default:
            if (condition.value == value) {
              action[condition.action || "enable"] += 1;
            }
        }
      });
      if (action.disable > 0) {
        return false;
      }
      if (action.enable > 0 && action.enable == enableConditionCount) {
        return true;
      }
      return false;
    };
    this.renderDocumentURL = () => {
      const { definition } = this.props;
      if (definition) {
        switch (definition.type) {
          case "component":
            return "https://kubevela.net/docs/end-user/components/references#" + definition.name;
          case "trait":
            return "https://kubevela.net/docs/end-user/traits/references#" + definition.name;
          case "policy":
            return "https://kubevela.net/docs/end-user/policies/references#" + definition.name;
          case "workflowstep":
            return "https://kubevela.net/docs/end-user/workflow/built-in-workflow-defs#" + definition.name;
        }
      }
      return;
    };
    this.renderCodeEdit = () => {
      const { value, onChange, definition } = this.props;
      const { codeError } = this.state;
      const codeID = uuid();
      let yamlValue = yaml.dump(value);
      if (yamlValue == "{}\n") {
        yamlValue = "";
      }
      return /* @__PURE__ */ React.createElement("div", { style: { width: "100%" } }, /* @__PURE__ */ React.createElement(If, { condition: codeError }, /* @__PURE__ */ React.createElement("span", { style: { color: "red" } }, codeError)), /* @__PURE__ */ React.createElement(If, { condition: definition }, /* @__PURE__ */ React.createElement("p", null, "Refer to the document:", /* @__PURE__ */ React.createElement("a", { style: { marginLeft: "8px" }, target: "_blank", href: this.renderDocumentURL(), rel: "noopener noreferrer" }, "click here"))), /* @__PURE__ */ React.createElement("div", { id: codeID, className: "guide-code" }, /* @__PURE__ */ React.createElement(
        DefinitionCode,
        {
          value: yamlValue,
          onBlurEditor: (v) => {
            if (onChange) {
              try {
                const valueObj = yaml.load(v);
                onChange(valueObj);
                this.setState({ codeError: "" });
              } catch (err) {
                this.setState({ codeError: "Please input a valid yaml config:" + err });
              }
            }
          },
          id: codeID + "-code",
          containerId: codeID,
          language: "yaml",
          readOnly: false
        }
      )));
    };
    const paramKeyMap = {};
    this.props.uiSchema?.map((param) => {
      paramKeyMap[param.jsonKey] = param;
    });
    this.form = new Field(this, {
      onChange: (name, value) => {
        const values = this.form.getValues();
        if (paramKeyMap[name] && paramKeyMap[name].uiType == "Number" && value === "") {
          delete values[name];
        }
        if (Array.isArray(value) && value.length == 0) {
          delete values[name];
        }
        const { onChange } = this.props;
        if (onChange) {
          onChange(values);
        }
      }
    });
    this.registerForm = {};
    if (this.props.registerForm) {
      this.props.registerForm(this.form);
    }
    this.state = {
      secretKeys: [],
      advanced: props.advanced || false
    };
  }
  render() {
    const { advanced } = this.state;
    const { uiSchema, inline, maxColSpan, disableRenderRow, value, mode, enableCodeEdit } = this.props;
    if (!uiSchema || enableCodeEdit) {
      return this.renderCodeEdit();
    }
    let onlyShowRequired = false;
    let couldShowParamCount = 0;
    uiSchema.map((param) => {
      if (param.disable) {
        return;
      }
      if (!this.conditionAllowRender(param.conditions)) {
        return;
      }
      couldShowParamCount += 1;
    });
    if (couldShowParamCount > 5) {
      onlyShowRequired = true;
    }
    let couldBeDisabledParamCount = 0;
    let requiredParamCount = 0;
    const items = uiSchema.map((param) => {
      const init = this.form.init;
      const required = param.validate && param.validate.required;
      if (param.disable) {
        return;
      }
      if (!this.conditionAllowRender(param.conditions)) {
        return;
      }
      if (!required) {
        couldBeDisabledParamCount += 1;
      } else {
        requiredParamCount += 1;
      }
      if (onlyShowRequired && !required && !advanced) {
        return;
      }
      const validator = (rule, v, callback) => {
        if (this.registerForm[param.jsonKey]) {
          this.registerForm[param.jsonKey].validate((errors) => {
            if (errors) {
              callback(`param ${param.jsonKey} validate failure`);
            }
            callback();
          });
        } else if (required) {
          callback(`param ${param.jsonKey} is required`);
        } else {
          callback();
        }
      };
      let description = param.description;
      if (description && description.indexOf("http") == -1 && description.indexOf(":") == -1) {
        description = i18n.t(description);
      }
      let label = param.label;
      if (label) {
        label = i18n.t(label);
      }
      let initValue = value && value[param.jsonKey];
      if (initValue === void 0) {
        initValue = param.validate?.defaultValue;
      }
      const disableEdit = param.validate?.immutable && mode == "edit" || false;
      const getGroup = (children) => {
        return /* @__PURE__ */ React.createElement(
          Group,
          {
            hasToggleIcon: true,
            description,
            title: label,
            closed: true,
            required,
            field: this.form,
            jsonKey: param.jsonKey || "",
            propertyValue: this.props.value,
            onChange: (values) => {
              if (this.props.onChange) {
                this.props.onChange(values);
              }
            }
          },
          /* @__PURE__ */ React.createElement(Form.Item, { required, disabled: disableEdit, key: param.jsonKey }, /* @__PURE__ */ React.createElement(React.Fragment, null, children))
        );
      };
      const item = () => {
        switch (param.uiType) {
          case "Switch":
            const getDefaultSwitchValue = (validate) => {
              if (validate.required === true) {
                return false;
              }
              return;
            };
            const switchResult = init(param.jsonKey, {
              initValue: initValue || getDefaultSwitchValue(param.validate),
              rules: convertRule(param.validate)
            });
            return /* @__PURE__ */ React.createElement(
              Form.Item,
              {
                className: "switch-container",
                required,
                key: param.jsonKey,
                label: /* @__PURE__ */ React.createElement("span", { title: description }, label),
                help: /* @__PURE__ */ React.createElement("div", { dangerouslySetInnerHTML: { __html: replaceUrl(description || "") } })
              },
              /* @__PURE__ */ React.createElement(
                Switch,
                {
                  disabled: disableEdit,
                  id: switchResult.id,
                  onChange: switchResult.onChange,
                  size: "medium",
                  checked: switchResult.value ? true : false
                }
              )
            );
          case "Input":
            return /* @__PURE__ */ React.createElement(
              Form.Item,
              {
                required,
                labelAlign: inline ? "inset" : "left",
                label,
                key: param.jsonKey,
                help: /* @__PURE__ */ React.createElement("div", { dangerouslySetInnerHTML: { __html: replaceUrl(description || "") } })
              },
              /* @__PURE__ */ React.createElement(
                Input,
                {
                  disabled: disableEdit,
                  autoComplete: "off",
                  ...init(param.jsonKey, {
                    initValue,
                    rules: convertRule(param.validate)
                  })
                }
              )
            );
          case "Password":
            return /* @__PURE__ */ React.createElement(
              Form.Item,
              {
                required,
                labelAlign: inline ? "inset" : "left",
                label,
                key: param.jsonKey,
                help: /* @__PURE__ */ React.createElement("div", { dangerouslySetInnerHTML: { __html: replaceUrl(description || "") } })
              },
              /* @__PURE__ */ React.createElement(
                Input,
                {
                  disabled: disableEdit,
                  htmlType: "password",
                  autoComplete: "off",
                  ...init(param.jsonKey, {
                    initValue,
                    rules: convertRule(param.validate)
                  })
                }
              )
            );
          case "Select":
            return /* @__PURE__ */ React.createElement(
              Form.Item,
              {
                required,
                labelAlign: inline ? "inset" : "left",
                label,
                key: param.jsonKey,
                help: /* @__PURE__ */ React.createElement("div", { dangerouslySetInnerHTML: { __html: replaceUrl(description || "") } })
              },
              /* @__PURE__ */ React.createElement(
                Select,
                {
                  disabled: disableEdit,
                  locale: locale().Select,
                  ...init(param.jsonKey, {
                    initValue,
                    rules: convertRule(param.validate)
                  }),
                  dataSource: param.validate && param.validate.options
                }
              )
            );
          case "Number":
            return /* @__PURE__ */ React.createElement(
              Form.Item,
              {
                labelAlign: inline ? "inset" : "left",
                required,
                label,
                key: param.jsonKey,
                help: /* @__PURE__ */ React.createElement("div", { dangerouslySetInnerHTML: { __html: replaceUrl(description || "") } })
              },
              /* @__PURE__ */ React.createElement(
                Input,
                {
                  disabled: disableEdit,
                  ...init(param.jsonKey, {
                    initValue,
                    rules: convertRule(param.validate)
                  }),
                  htmlType: "number"
                }
              )
            );
          case "ImageInput":
            const imagePullSecretsValue = value && value.imagePullSecrets;
            const initResult = init("imagePullSecrets", {
              initValue: imagePullSecretsValue,
              rules: []
            });
            return /* @__PURE__ */ React.createElement(
              ImageInput,
              {
                label,
                key: param.jsonKey,
                required: required || false,
                disabled: disableEdit,
                ...init(param.jsonKey, {
                  initValue,
                  rules: [
                    {
                      required: true,
                      pattern: checkImageName,
                      message: "Please enter a valid image name"
                    }
                  ]
                }),
                secretValue: initResult.value,
                onSecretChange: initResult.onChange,
                secretID: initResult.id
              }
            );
          case "HelmChartSelect":
            return /* @__PURE__ */ React.createElement(
              Form.Item,
              {
                required,
                label,
                help: /* @__PURE__ */ React.createElement("div", { dangerouslySetInnerHTML: { __html: replaceUrl(description || "") } }),
                key: param.jsonKey
              },
              /* @__PURE__ */ React.createElement(
                HelmChartSelect,
                {
                  disabled: disableEdit,
                  helm: this.props.value,
                  ...init(param.jsonKey, {
                    initValue,
                    rules: [
                      {
                        required: true,
                        pattern: checkImageName,
                        message: "Please select a chart"
                      }
                    ]
                  })
                }
              )
            );
          case "HelmChartVersionSelect":
            return /* @__PURE__ */ React.createElement(
              Form.Item,
              {
                required,
                label,
                help: /* @__PURE__ */ React.createElement("div", { dangerouslySetInnerHTML: { __html: replaceUrl(description || "") } }),
                key: param.jsonKey
              },
              /* @__PURE__ */ React.createElement(
                HelmChartVersionSelect,
                {
                  disabled: disableEdit,
                  helm: this.props.value,
                  ...init(param.jsonKey, {
                    initValue,
                    rules: [
                      {
                        required: true,
                        pattern: checkImageName,
                        message: "Please select a chart version"
                      }
                    ]
                  })
                }
              )
            );
          case "HelmRepoSelect":
            return /* @__PURE__ */ React.createElement(
              Form.Item,
              {
                required,
                label,
                help: /* @__PURE__ */ React.createElement("div", { dangerouslySetInnerHTML: { __html: replaceUrl(description || "") } }),
                key: param.jsonKey
              },
              /* @__PURE__ */ React.createElement(
                HelmRepoSelect,
                {
                  disabled: disableEdit,
                  helm: this.props.value,
                  ...init(param.jsonKey, {
                    initValue,
                    rules: [
                      {
                        required: true,
                        pattern: checkImageName,
                        message: "Please select or input a helm repo"
                      }
                    ]
                  }),
                  onChangeSecretRef: (secretName) => {
                    this.form.setValue("secretRef", secretName);
                  }
                }
              )
            );
          case "KV":
            const children = /* @__PURE__ */ React.createElement(
              KV,
              {
                disabled: disableEdit,
                ...init(param.jsonKey, {
                  initValue,
                  rules: convertRule(param.validate)
                }),
                key: param.jsonKey,
                additional: param.additional,
                additionalParameter: param.additionalParameter
              }
            );
            return getGroup(children);
          case "HelmValues":
            return getGroup(
              /* @__PURE__ */ React.createElement(
                HelmValues,
                {
                  disabled: disableEdit,
                  ...init(param.jsonKey, {
                    initValue,
                    rules: convertRule(param.validate)
                  }),
                  key: param.jsonKey,
                  helm: this.props.value,
                  additional: param.additional,
                  additionalParameter: param.additionalParameter
                }
              )
            );
          case "Strings":
            return getGroup(
              /* @__PURE__ */ React.createElement(
                Strings,
                {
                  disabled: disableEdit,
                  key: param.jsonKey,
                  ...init(param.jsonKey, {
                    initValue,
                    rules: convertRule(param.validate)
                  })
                }
              )
            );
          case "Numbers":
            return getGroup(
              /* @__PURE__ */ React.createElement(
                Numbers,
                {
                  disabled: disableEdit,
                  key: param.jsonKey,
                  ...init(param.jsonKey, {
                    initValue,
                    rules: convertRule(param.validate)
                  })
                }
              )
            );
          case "SecretSelect":
            return /* @__PURE__ */ React.createElement(
              Form.Item,
              {
                labelAlign: inline ? "inset" : "left",
                required,
                label,
                help: /* @__PURE__ */ React.createElement("div", { dangerouslySetInnerHTML: { __html: replaceUrl(description || "") } }),
                disabled: disableEdit,
                key: param.jsonKey
              },
              /* @__PURE__ */ React.createElement(
                SecretSelect,
                {
                  disabled: disableEdit,
                  setKeys: (keys) => {
                    this.setState({ secretKeys: keys });
                  },
                  ...init(param.jsonKey, {
                    initValue: this.props.value?.name || param.validate?.defaultValue,
                    rules: convertRule(param.validate)
                  })
                }
              )
            );
          case "SecretKeySelect":
            return /* @__PURE__ */ React.createElement(
              Form.Item,
              {
                required,
                labelAlign: inline ? "inset" : "left",
                label,
                help: /* @__PURE__ */ React.createElement("div", { dangerouslySetInnerHTML: { __html: replaceUrl(description || "") } }),
                disabled: disableEdit,
                key: param.jsonKey
              },
              /* @__PURE__ */ React.createElement(
                SecretKeySelect,
                {
                  disabled: disableEdit,
                  secretKeys: this.state.secretKeys,
                  ...init(param.jsonKey, {
                    initValue: this.props.value?.key || param.validate?.defaultValue,
                    rules: convertRule(param.validate)
                  })
                }
              )
            );
          case "CPUNumber":
            return /* @__PURE__ */ React.createElement(
              Form.Item,
              {
                required,
                label,
                help: /* @__PURE__ */ React.createElement("div", { dangerouslySetInnerHTML: { __html: replaceUrl(description || "") } }),
                disabled: disableEdit,
                key: param.jsonKey
              },
              /* @__PURE__ */ React.createElement(
                CPUNumber,
                {
                  disabled: disableEdit,
                  ...init(param.jsonKey, {
                    initValue,
                    rules: [
                      {
                        required,
                        min: 0,
                        message: "Please enter a valid cpu request number"
                      }
                    ]
                  })
                }
              )
            );
          case "MemoryNumber":
            return /* @__PURE__ */ React.createElement(
              Form.Item,
              {
                required,
                label,
                help: /* @__PURE__ */ React.createElement("div", { dangerouslySetInnerHTML: { __html: replaceUrl(description || "") } }),
                disabled: disableEdit,
                key: param.jsonKey
              },
              /* @__PURE__ */ React.createElement(
                MemoryNumber,
                {
                  disabled: disableEdit,
                  ...init(param.jsonKey, {
                    initValue,
                    rules: [
                      {
                        required,
                        min: 0,
                        message: "Please enter a valid memory request number"
                      }
                    ]
                  })
                }
              )
            );
          case "DiskNumber":
            return /* @__PURE__ */ React.createElement(
              Form.Item,
              {
                required,
                label,
                help: /* @__PURE__ */ React.createElement("div", { dangerouslySetInnerHTML: { __html: replaceUrl(description || "") } }),
                disabled: disableEdit,
                key: param.jsonKey
              },
              /* @__PURE__ */ React.createElement(
                DiskNumber,
                {
                  disabled: disableEdit,
                  ...init(param.jsonKey, {
                    initValue,
                    rules: [
                      {
                        required,
                        min: 0,
                        message: "Please enter a valid disk size"
                      }
                    ]
                  })
                }
              )
            );
          case "Group":
            if (param.subParameters && param.subParameters.length > 0) {
              return /* @__PURE__ */ React.createElement(
                Group,
                {
                  key: param.jsonKey,
                  hasToggleIcon: true,
                  description: /* @__PURE__ */ React.createElement("div", { dangerouslySetInnerHTML: { __html: replaceUrl(description || "") } }),
                  title: label,
                  closed: true,
                  required,
                  field: this.form,
                  jsonKey: param.jsonKey || "",
                  propertyValue: this.props.value,
                  onChange: (values) => {
                    if (this.props.onChange) {
                      this.props.onChange(values);
                    }
                  }
                },
                /* @__PURE__ */ React.createElement(
                  UISchema,
                  {
                    ...init(param.jsonKey, {
                      initValue,
                      rules: [
                        {
                          validator
                        }
                      ]
                    }),
                    registerForm: (form) => {
                      this.onRegisterForm(param.jsonKey, form);
                    },
                    uiSchema: param.subParameters,
                    mode: this.props.mode
                  }
                )
              );
            }
            return /* @__PURE__ */ React.createElement("div", null);
          case "Structs":
            if (param.subParameters && param.subParameters.length > 0) {
              return getGroup(
                /* @__PURE__ */ React.createElement(
                  Structs,
                  {
                    key: param.jsonKey,
                    label,
                    param: param.subParameters,
                    parameterGroupOption: param.subParameterGroupOption,
                    registerForm: (form) => {
                      this.onRegisterForm(param.jsonKey, form);
                    },
                    mode: this.props.mode,
                    ...init(param.jsonKey, {
                      initValue,
                      rules: [
                        {
                          validator,
                          message: `Please check ${label} config`
                        }
                      ]
                    })
                  }
                )
              );
            }
            return /* @__PURE__ */ React.createElement("div", null);
          case "Ignore":
            if (param.subParameters && param.subParameters.length > 0) {
              const itemCount = param.subParameters?.filter((p) => !p.disable).length || 1;
              return /* @__PURE__ */ React.createElement(
                UISchema,
                {
                  uiSchema: param.subParameters,
                  registerForm: (form) => {
                    this.onRegisterForm(param.jsonKey, form);
                  },
                  inline,
                  maxColSpan: 24 / itemCount,
                  ...init(param.jsonKey, {
                    initValue,
                    rules: [
                      {
                        validator
                      }
                    ]
                  }),
                  mode: this.props.mode
                }
              );
            }
            return /* @__PURE__ */ React.createElement("div", null);
          case "K8sObjectsCode":
            return /* @__PURE__ */ React.createElement(
              Form.Item,
              {
                required,
                label,
                help: /* @__PURE__ */ React.createElement("div", { dangerouslySetInnerHTML: { __html: replaceUrl(description || "") } }),
                disabled: disableEdit,
                key: param.jsonKey
              },
              /* @__PURE__ */ React.createElement(
                K8sObjectsCode,
                {
                  ...init(param.jsonKey, {
                    initValue,
                    rules: [
                      {
                        required,
                        message: "Please enter a valid kubernetes resource yaml code"
                      }
                    ]
                  })
                }
              )
            );
          case "PolicySelect":
            return /* @__PURE__ */ React.createElement(
              Form.Item,
              {
                labelAlign: inline ? "inset" : "left",
                required,
                label,
                help: /* @__PURE__ */ React.createElement("div", { dangerouslySetInnerHTML: { __html: replaceUrl(description || "") } }),
                disabled: disableEdit,
                key: param.jsonKey
              },
              /* @__PURE__ */ React.createElement(
                PolicySelect,
                {
                  disabled: disableEdit,
                  ...init(param.jsonKey, {
                    initValue,
                    rules: convertRule(param.validate)
                  })
                }
              )
            );
          case "ComponentSelect":
            return /* @__PURE__ */ React.createElement(
              Form.Item,
              {
                labelAlign: inline ? "inset" : "left",
                required,
                label,
                help: /* @__PURE__ */ React.createElement("div", { dangerouslySetInnerHTML: { __html: replaceUrl(description || "") } }),
                disabled: disableEdit,
                key: param.jsonKey
              },
              /* @__PURE__ */ React.createElement(
                ComponentSelect,
                {
                  disabled: disableEdit,
                  ...init(param.jsonKey, {
                    initValue,
                    rules: convertRule(param.validate)
                  })
                }
              )
            );
          case "ComponentPatches":
            return /* @__PURE__ */ React.createElement(
              Form.Item,
              {
                labelAlign: inline ? "inset" : "left",
                required,
                label,
                help: /* @__PURE__ */ React.createElement("div", { dangerouslySetInnerHTML: { __html: replaceUrl(description || "") } }),
                disabled: disableEdit,
                key: param.jsonKey
              },
              /* @__PURE__ */ React.createElement(
                ComponentPatches,
                {
                  disabled: disableEdit,
                  registerForm: (form) => {
                    this.onRegisterForm(param.jsonKey, form);
                  },
                  ...init(param.jsonKey, {
                    initValue,
                    rules: [
                      {
                        validator
                      }
                    ]
                  })
                }
              )
            );
          case "CertBase64":
            return /* @__PURE__ */ React.createElement(
              Form.Item,
              {
                labelAlign: inline ? "inset" : "left",
                required,
                label,
                help: /* @__PURE__ */ React.createElement("div", { dangerouslySetInnerHTML: { __html: replaceUrl(description || "") } }),
                disabled: disableEdit,
                key: param.jsonKey
              },
              /* @__PURE__ */ React.createElement(
                CertBase64,
                {
                  disabled: disableEdit,
                  ...init(param.jsonKey, {
                    initValue,
                    rules: convertRule(param.validate)
                  })
                }
              )
            );
          default:
            return;
        }
      };
      let colSpan = 24;
      if (maxColSpan) {
        colSpan = maxColSpan;
      }
      if (param.style?.colSpan) {
        colSpan = param.style?.colSpan;
      }
      return /* @__PURE__ */ React.createElement(Col, { key: param.jsonKey, span: colSpan, style: { padding: "0 4px" } }, item());
    });
    const formItemLayout = {
      labelCol: {
        fixedSpan: 4
      },
      wrapperCol: {
        span: 14
      }
    };
    const showAdvancedButton = couldBeDisabledParamCount != couldShowParamCount || requiredParamCount === 0;
    return /* @__PURE__ */ React.createElement(Form, { field: this.form, className: "ui-schema-container" }, /* @__PURE__ */ React.createElement(If, { condition: disableRenderRow }, items), /* @__PURE__ */ React.createElement(If, { condition: !disableRenderRow }, /* @__PURE__ */ React.createElement(Row, { wrap: true }, items), /* @__PURE__ */ React.createElement(If, { condition: onlyShowRequired }, /* @__PURE__ */ React.createElement(Divider, null), /* @__PURE__ */ React.createElement(If, { condition: showAdvancedButton }, /* @__PURE__ */ React.createElement(Form, { ...formItemLayout, style: { width: "100%" }, fullWidth: true }, /* @__PURE__ */ React.createElement(Form.Item, { labelAlign: "left", colon: true, label: /* @__PURE__ */ React.createElement(Translation, null, "Advanced Parameters") }, /* @__PURE__ */ React.createElement(Switch, { onChange: this.onChangeAdvanced, size: "small", checked: advanced })))))));
  }
}
export default UISchema;
