"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Loading, Message } from "@alifd/next";
import { connect } from "dva";
import React, { Component, Fragment } from "react";
import { getApplicationList, deleteApplication } from "../../api/application";
import { getComponentDefinitions } from "../../api/definitions";
import { getEnvs } from "../../api/env";
import { getProjectTargetList } from "../../api/project";
import { If } from "../../components/If";
import AppDialog from "../ApplicationList/components/AddAppDialog";
import CardContend from "../ApplicationList/components/CardContent";
import EditAppDialog from "../ApplicationList/components/EditAppDialog";
import SelectSearch from "./components/SelectSearch";
let ProjectApplications = class extends Component {
  constructor(props) {
    super(props);
    this.listApplication = async (queryData) => {
      const { params = { projectName: "" } } = this.props.match;
      const { query = "", env = "", targetName = "", labels = "" } = queryData || {};
      const queryParams = {
        query,
        env,
        targetName,
        labels,
        project: params.projectName
      };
      this.setState({ isLoading: true });
      getApplicationList(queryParams).then((res) => {
        if (res) {
          this.setState({ applicationList: res.applications || [] });
        }
      }).catch(() => {
      }).finally(() => {
        this.setState({ isLoading: false });
      });
    };
    this.listProjectTargets = async () => {
      const { params = { projectName: "" } } = this.props.match;
      getProjectTargetList({ projectName: params.projectName }).then((res) => {
        if (res) {
          this.setState({ targets: res.targets || [] });
        }
      });
    };
    this.listEnvs = async () => {
      const { params = { projectName: "" } } = this.props.match;
      getEnvs({ project: params.projectName }).then((res) => {
        if (res) {
          this.setState({ envs: res.envs || [] });
        }
      });
    };
    this.onGetComponentDefinitions = async () => {
      getComponentDefinitions().then((res) => {
        if (res) {
          this.setState({
            componentDefinitions: res && res.definitions
          });
        }
      });
    };
    this.setLabelValue = async (labels) => {
      this.setState({
        labelValue: labels
      });
    };
    this.editApplicationPlan = (editApplicationItem) => {
      this.setState({
        editApplicationItem,
        isEditApplication: true
      });
    };
    this.onDeleteApplicationItem = (name) => {
      deleteApplication({ name }).then((re) => {
        if (re) {
          Message.success("Application deleted successfully");
          this.listApplication();
        }
      });
    };
    this.closeAddApplication = () => {
      this.setState({
        isAddApplication: false
      });
      this.listApplication();
    };
    this.closeEditAppDialog = () => {
      this.setState({
        isEditApplication: false
      });
      this.listApplication();
    };
    this.clickLabelFilter = (label) => {
      let { labelValue } = this.state;
      let existIndex = -1;
      labelValue.map((key, index) => {
        if (key == label) {
          existIndex = index;
          return;
        }
      });
      if (existIndex == -1) {
        labelValue.push(label);
      } else {
        labelValue = labelValue.splice(existIndex, existIndex);
      }
      this.setState({
        labelValue
      });
      this.listApplication({ labels: labelValue.join(",") });
    };
    let mode = localStorage.getItem("application-list-mode");
    if (mode != "table" && mode != "card") {
      mode = "card";
    }
    this.state = {
      isLoading: false,
      applicationList: [],
      editApplicationItem: {
        name: "",
        alias: "",
        icon: "",
        description: "",
        createTime: ""
      },
      envs: [],
      componentDefinitions: [],
      labelValue: [],
      isEditApplication: false,
      isAddApplication: false,
      showMode: mode
    };
  }
  componentDidMount() {
    this.listApplication();
    this.listProjectTargets();
    this.listEnvs();
    this.onGetComponentDefinitions();
  }
  render() {
    const { dispatch, userInfo } = this.props;
    const {
      isLoading,
      applicationList,
      isAddApplication,
      isEditApplication,
      editApplicationItem,
      targets,
      envs,
      componentDefinitions,
      labelValue
    } = this.state;
    const { params = { projectName: "" } } = this.props.match;
    const { projectName } = params;
    let appLabels = [];
    applicationList.map((app) => {
      app.labels && Object.keys(app.labels).map((key) => {
        if (key.indexOf("ux.oam.dev") < 0 && key.indexOf("app.oam.dev")) {
          if (app.labels) {
            appLabels.push(key + "=" + app.labels[key]);
          }
        }
      });
    });
    return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "full-container" }, /* @__PURE__ */ React.createElement(
      SelectSearch,
      {
        targetList: targets,
        envs,
        appLabels,
        setLabelValue: this.setLabelValue,
        labelValue,
        projectName,
        listApplication: (queryData) => {
          this.listApplication(queryData);
        },
        onAddApplication: () => {
          this.setState({ isAddApplication: true });
        },
        setMode: (mode) => {
          this.setState({ showMode: mode });
          if (mode) {
            localStorage.setItem("application-list-mode", mode);
          }
        },
        showMode: this.state.showMode
      }
    ), /* @__PURE__ */ React.createElement(Loading, { visible: isLoading, fullScreen: true }, /* @__PURE__ */ React.createElement(
      CardContend,
      {
        projectName,
        applications: applicationList,
        editAppPlan: (editItem) => {
          this.editApplicationPlan(editItem);
        },
        showMode: this.state.showMode,
        clickLabelFilter: this.clickLabelFilter,
        deleteAppPlan: this.onDeleteApplicationItem,
        setVisible: (visible) => {
          this.setState({ isAddApplication: visible });
        }
      }
    )), /* @__PURE__ */ React.createElement(If, { condition: isAddApplication }, /* @__PURE__ */ React.createElement(
      AppDialog,
      {
        visible: isAddApplication,
        targets,
        projects: userInfo?.projects,
        userInfo,
        isDisableProject: true,
        projectName,
        componentDefinitions,
        setVisible: (visible) => {
          this.setState({ isAddApplication: visible });
        },
        onOK: () => {
          this.setState({ isAddApplication: false });
          this.listApplication();
        },
        onClose: this.closeAddApplication,
        dispatch
      }
    )), /* @__PURE__ */ React.createElement(If, { condition: isEditApplication }, /* @__PURE__ */ React.createElement(
      EditAppDialog,
      {
        editItem: editApplicationItem,
        onOK: this.closeEditAppDialog,
        onClose: this.closeEditAppDialog
      }
    ))));
  }
};
ProjectApplications = __decorateClass([
  connect((store) => {
    return { ...store, ...store.user };
  })
], ProjectApplications);
export default ProjectApplications;
