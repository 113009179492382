"use strict";
import { getDomain } from "../utils/common";
import { get, post, put, rdelete } from "./request";
const baseURLOject = getDomain();
const base = baseURLOject.MOCK || baseURLOject.APIBASE;
export function listPipelines(params) {
  const url = base + "/api/v1/pipelines";
  return get(url, { params }).then((res) => res);
}
export function createPipeline(params) {
  const url = base + `/api/v1/projects/${params.project}/pipelines`;
  return post(url, params).then((res) => res);
}
export function updatePipeline(params) {
  const url = base + `/api/v1/projects/${params.project}/pipelines/${params.name}`;
  return put(url, {
    description: params.description,
    alias: params.alias,
    spec: params.spec
  }).then((res) => res);
}
export function loadPipeline(params) {
  const url = base + `/api/v1/projects/${params.projectName}/pipelines/${params.pipelineName}`;
  return get(url, {}).then((res) => res);
}
export function deletePipeline(params) {
  const url = base + `/api/v1/projects/${params.projectName}/pipelines/${params.pipelineName}`;
  return rdelete(url, {}).then((res) => res);
}
export function loadPipelineRuns(params) {
  const url = base + `/api/v1/projects/${params.projectName}/pipelines/${params.pipelineName}/runs`;
  return get(url, {}).then((res) => res);
}
export function deletePipelineRun(params) {
  const url = base + `/api/v1/projects/${params.projectName}/pipelines/${params.pipelineName}/runs/${params.runName}`;
  return rdelete(url, {}).then((res) => res);
}
export function stopPipelineRun(params) {
  const url = base + `/api/v1/projects/${params.projectName}/pipelines/${params.pipelineName}/runs/${params.runName}/stop`;
  return post(url, {}).then((res) => res);
}
export function loadPipelineRunBase(params) {
  const url = base + `/api/v1/projects/${params.projectName}/pipelines/${params.pipelineName}/runs/${params.runName}`;
  return get(url, {}).then((res) => res);
}
export function loadPipelineRunStatus(params) {
  const url = base + `/api/v1/projects/${params.projectName}/pipelines/${params.pipelineName}/runs/${params.runName}/status`;
  return get(url, {}).then((res) => res);
}
export function loadPipelineRunStepLogs(params) {
  const url = base + `/api/v1/projects/${params.projectName}/pipelines/${params.pipelineName}/runs/${params.runName}/log`;
  return get(url, { params: { step: params.stepName } }).then((res) => res);
}
export function listPipelineContexts(projectName, pipelineName) {
  const url = base + `/api/v1/projects/${projectName}/pipelines/${pipelineName}/contexts`;
  return get(url, {}).then((res) => res);
}
export function deletePipelineContext(projectName, pipelineName, contextName) {
  const url = base + `/api/v1/projects/${projectName}/pipelines/${pipelineName}/contexts/${contextName}`;
  return rdelete(url, {}).then((res) => res);
}
export function updatePipelineContext(projectName, pipelineName, context) {
  const url = base + `/api/v1/projects/${projectName}/pipelines/${pipelineName}/contexts/${context.name}`;
  return put(url, context).then((res) => res);
}
export function loadPipelineRunStepOutputs(params) {
  const url = base + `/api/v1/projects/${params.projectName}/pipelines/${params.pipelineName}/runs/${params.runName}/output`;
  return get(url, { params: { step: params.stepName } }).then((res) => res);
}
export function loadPipelineRunStepInputs(params) {
  const url = base + `/api/v1/projects/${params.projectName}/pipelines/${params.pipelineName}/runs/${params.runName}/input`;
  return get(url, { params: { step: params.stepName } }).then((res) => res);
}
export function createPipelineContext(projectName, pipelineName, context) {
  const url = base + `/api/v1/projects/${projectName}/pipelines/${pipelineName}/contexts`;
  return post(url, context).then((res) => res);
}
export function runPipeline(projectName, pipelineName, contextName) {
  const url = base + `/api/v1/projects/${projectName}/pipelines/${pipelineName}/run`;
  return post(url, contextName ? { contextName } : {}).then((res) => res);
}
export function terminatePipelineRun(params) {
  const url = base + `/api/v1/projects/${params.projectName}/pipelines/${params.pipelineName}/runs/${params.runName}/terminate`;
  return post(url, {}).then((res) => res);
}
export function resumePipelineRun(params) {
  const url = base + `/api/v1/projects/${params.projectName}/pipelines/${params.pipelineName}/runs/${params.runName}/resume`;
  return post(url, {}).then((res) => res);
}
