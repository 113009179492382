"use strict";
import React from "react";
const renderConnector = (startPoint, endPoint, from, to) => {
  return /* @__PURE__ */ React.createElement(
    "path",
    {
      key: from + to,
      className: "workflow-connector",
      "data-from": "step-" + from,
      "data-to": "step-" + to,
      fill: "none",
      d: `M ${startPoint} 38 H ${endPoint}`
    }
  );
};
export const Edge = (props) => {
  const { stepInterval, steps } = props;
  const total = steps?.length || 0;
  if (total == 0) {
    return /* @__PURE__ */ React.createElement(React.Fragment, null);
  }
  let width = (total - 1) * stepInterval;
  steps.map((step) => width += step.width);
  let firstPoint = -stepInterval;
  return /* @__PURE__ */ React.createElement("svg", { width, height: 300, className: "workflow-connectors" }, props.steps && props.steps.map((step, index) => {
    if (index == total - 1) {
      return;
    }
    firstPoint = firstPoint + stepInterval + step.width;
    const endPoint = firstPoint + stepInterval;
    return renderConnector(firstPoint, endPoint, step.name, props.steps[index + 1].name);
  }));
};
