"use strict";
import { Select } from "@alifd/next";
import React from "react";
import { UISchemaContext } from "../../context";
import { getApplicationComponents } from "../../api/application";
import i18n from "../../i18n";
import { locale } from "../../utils/locale";
class ComponentSelect extends React.Component {
  constructor(props) {
    super(props);
    this.componentDidMount = () => {
      this.fetchComponentList();
    };
    this.fetchComponentList = async () => {
      const { appName = "" } = this.context;
      if (appName) {
        getApplicationComponents({
          appName
        }).then((res) => {
          if (res && res.components) {
            const componentOptions = (res.components || []).map((item) => {
              return {
                label: `${item.name}(${item.alias || "-"})`,
                value: item.name
              };
            });
            this.setState({
              componentOptions
            });
          } else {
            this.setState({
              componentOptions: []
            });
          }
        }).catch(() => {
          this.setState({
            componentOptions: []
          });
        });
      }
    };
    this.onChange = (value) => {
      const { onChange } = this.props;
      onChange(value);
    };
    this.state = {
      componentOptions: []
    };
  }
  render() {
    const { value, id, disabled } = this.props;
    const { componentOptions } = this.state;
    return /* @__PURE__ */ React.createElement(
      Select,
      {
        placeholder: i18n.t("Please select the components"),
        onChange: this.onChange,
        id,
        disabled,
        defaultValue: value || [],
        value: value || [],
        dataSource: componentOptions,
        mode: "multiple",
        locale: locale().Select
      }
    );
  }
}
ComponentSelect.contextType = UISchemaContext;
export default ComponentSelect;
