"use strict";
import { Dialog, Select } from "@alifd/next";
import * as React from "react";
import i18n from "../../i18n";
import DefinitionCode from "../DefinitionCode";
import "./index.less";
const HelmValueShow = (props) => {
  const [valueFile, setValueFile] = React.useState("values.yaml");
  return /* @__PURE__ */ React.createElement(
    Dialog,
    {
      className: "helmValueDialog",
      overflowScroll: true,
      visible: true,
      onClose: props.onClose,
      footer: /* @__PURE__ */ React.createElement("div", null),
      title: i18n.t("Show Values File").toString()
    },
    /* @__PURE__ */ React.createElement(
      Select,
      {
        onChange: (name) => {
          setValueFile(name);
        },
        defaultValue: valueFile,
        dataSource: Object.keys(props.valueFiles),
        style: { marginBottom: "8px" }
      }
    ),
    /* @__PURE__ */ React.createElement("div", { id: "chart-" + props.name, className: "diff-box" }, /* @__PURE__ */ React.createElement(
      DefinitionCode,
      {
        language: "yaml",
        containerId: "chart-" + props.name,
        readOnly: true,
        value: props.valueFiles[valueFile]
      }
    ))
  );
};
export default HelmValueShow;
