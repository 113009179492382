"use strict";
import { Grid, Input } from "@alifd/next";
import React from "react";
import { AiOutlineSearch } from "react-icons/ai";
import i18n from "../../../../i18n";
import "./index.less";
const { Row, Col } = Grid;
class SelectSearch extends React.Component {
  constructor() {
    super(...arguments);
    this.handleChangName = (e) => {
      this.props.handleChangName(e);
    };
    this.handleChangAlias = (e) => {
      this.props.handleChangAlias(e);
    };
    this.handleChangEmail = (e) => {
      this.props.handleChangEmail(e);
    };
  }
  render() {
    const { name, alias, email } = this.props;
    return /* @__PURE__ */ React.createElement(Row, { className: "user-select-wrapper border-radius-8", wrap: true }, /* @__PURE__ */ React.createElement(Col, { xl: 6, m: 8, s: 12, xxs: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(
      Input,
      {
        innerAfter: /* @__PURE__ */ React.createElement(AiOutlineSearch, { style: { margin: 4 } }),
        hasClear: true,
        size: "large",
        placeholder: i18n.t("Search by name"),
        onChange: this.handleChangName,
        value: name,
        className: "item"
      }
    )), /* @__PURE__ */ React.createElement(Col, { xl: 6, m: 8, s: 12, xxs: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(
      Input,
      {
        innerAfter: /* @__PURE__ */ React.createElement(AiOutlineSearch, { style: { margin: 4 } }),
        hasClear: true,
        size: "large",
        placeholder: i18n.t("Search by alias"),
        onChange: this.handleChangAlias,
        value: alias,
        className: "item"
      }
    )), /* @__PURE__ */ React.createElement(Col, { xl: 6, m: 8, s: 12, xxs: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(
      Input,
      {
        innerAfter: /* @__PURE__ */ React.createElement(AiOutlineSearch, { style: { margin: 4 } }),
        hasClear: true,
        size: "large",
        placeholder: i18n.t("Search by email"),
        onChange: this.handleChangEmail,
        value: email,
        className: "item"
      }
    )));
  }
}
export default SelectSearch;
