"use strict";
import classNames from "classnames";
import React, { useState } from "react";
import { StepTypeIcon } from "./step-icon";
import "./index.less";
import { showAlias } from "../../utils/common";
import { If } from "../If";
import { AiOutlineDelete } from "react-icons/ai";
import { IoMdAdd } from "react-icons/io";
import { Translation } from "../../components/Translation";
export const Step = (props) => {
  const { step, output, input, onNodeClick, group, onDelete, onAddSubStep, subMode } = props;
  const { stepWidth, stepInterval } = props.probeState;
  const [isActive, setActive] = useState(false);
  const mode = step.mode || subMode || "DAG";
  return /* @__PURE__ */ React.createElement(
    "div",
    {
      className: classNames("step", { active: isActive }, { group }),
      onMouseEnter: () => setActive(true),
      onMouseLeave: () => setActive(false),
      style: { marginRight: stepInterval + "px" },
      onClick: (event) => {
        if (!group) {
          onNodeClick(props.step, false);
          event.stopPropagation();
        }
      }
    },
    /* @__PURE__ */ React.createElement(If, { condition: group }, /* @__PURE__ */ React.createElement(
      "div",
      {
        className: "step-title",
        onClick: (event) => {
          onNodeClick(step, false);
          event.stopPropagation();
        }
      },
      showAlias(step.name, step.alias),
      /* @__PURE__ */ React.createElement("span", { className: "step-delete" }, /* @__PURE__ */ React.createElement(
        AiOutlineDelete,
        {
          size: 14,
          onClick: (event) => {
            onDelete(step.name);
            event.stopPropagation();
          },
          title: "Delete this step group."
        }
      ))
    ), /* @__PURE__ */ React.createElement("div", { className: "groups", style: { width: stepWidth + "px" } }, mode && /* @__PURE__ */ React.createElement("div", { className: "mode" }, /* @__PURE__ */ React.createElement(Translation, null, "Mode"), ":", /* @__PURE__ */ React.createElement(Translation, null, mode)), step.subSteps?.map((subStep, index) => {
      return /* @__PURE__ */ React.createElement("div", { key: subStep.name + "-step" }, /* @__PURE__ */ React.createElement(
        "div",
        {
          key: subStep.name + "-status",
          className: "step-status",
          onClick: (event) => {
            onNodeClick(subStep, true);
            event.stopPropagation();
          }
        },
        /* @__PURE__ */ React.createElement(StepTypeIcon, { type: subStep.type }),
        /* @__PURE__ */ React.createElement("div", { className: "step-name" }, subStep.alias || subStep.name),
        /* @__PURE__ */ React.createElement(
          "div",
          {
            className: "step-delete",
            onClick: (event) => {
              onDelete(subStep.name);
              event.stopPropagation();
            }
          },
          /* @__PURE__ */ React.createElement(AiOutlineDelete, { size: 14 })
        )
      ), mode == "StepByStep" && /* @__PURE__ */ React.createElement("div", { className: "sub-step-add", onClick: () => onAddSubStep(index + 1) }, /* @__PURE__ */ React.createElement(IoMdAdd, { size: 14 })));
    }), (mode === "DAG" || !step.subSteps) && /* @__PURE__ */ React.createElement("div", { className: "step-status sub-step-add", onClick: () => onAddSubStep(step.subSteps?.length || 0) }, /* @__PURE__ */ React.createElement("div", { className: "step-name" }, /* @__PURE__ */ React.createElement(IoMdAdd, { size: 14 }))))),
    /* @__PURE__ */ React.createElement(If, { condition: !group }, /* @__PURE__ */ React.createElement("div", { className: "groups", style: { width: stepWidth + "px" } }, /* @__PURE__ */ React.createElement("div", { className: "step-status" }, /* @__PURE__ */ React.createElement(StepTypeIcon, { type: step.type }), /* @__PURE__ */ React.createElement("div", { className: "step-name" }, step.alias || step.name), /* @__PURE__ */ React.createElement(
      "div",
      {
        className: "step-delete",
        onClick: (event) => {
          onDelete(step.name);
          event.stopPropagation();
        }
      },
      /* @__PURE__ */ React.createElement(AiOutlineDelete, { size: 14 })
    )))),
    /* @__PURE__ */ React.createElement(If, { condition: output }, /* @__PURE__ */ React.createElement("div", { className: "workflow-step-port workflow-step-port-output step-circle" })),
    /* @__PURE__ */ React.createElement(If, { condition: input }, /* @__PURE__ */ React.createElement("div", { className: "workflow-step-port workflow-step-port-input step-circle" }))
  );
};
