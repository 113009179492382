"use strict";
import { DeployModes } from "@velaux/data";
import React from "react";
import { AiOutlineSafetyCertificate } from "react-icons/ai";
import SvgApi from "../Icons/SvgAPI";
import SvgEp from "../Icons/SvgEp";
import SvgKubernetes from "../Icons/SvgKubernetes";
import SvgNotification from "../Icons/SvgNotification";
import SvgSecret from "../Icons/SvgSecret";
import SvgSvc from "../Icons/SvgSvc";
import SvgTerraform from "../Icons/SvgTerraform";
import SvgWorkflow from "../Icons/SvgWorkflow";
export const StepTypeIcon = (props) => {
  switch (props.type) {
    case DeployModes.Deploy:
    case DeployModes.CanaryDeploy:
      return /* @__PURE__ */ React.createElement(SvgKubernetes, { width: "24px", height: "24px" });
    case "deploy-cloud-resource":
      return /* @__PURE__ */ React.createElement(SvgTerraform, { width: "24px", height: "24px" });
    case "export-service":
    case "share-cloud-resource":
      return /* @__PURE__ */ React.createElement(
        SvgSvc,
        {
          width: "24px",
          height: "24px",
          style: {
            color: "var(--primary-color)"
          }
        }
      );
    case "read-config":
    case "list-config":
    case "create-config":
    case "delete-config":
    case "export-data":
      return /* @__PURE__ */ React.createElement(
        SvgSecret,
        {
          width: "24px",
          height: "24px",
          style: {
            color: "var(--primary-color)"
          }
        }
      );
    case "notification":
      return /* @__PURE__ */ React.createElement(SvgNotification, { width: "24px", height: "24px" });
    case "webhook":
      return /* @__PURE__ */ React.createElement(SvgApi, { width: "24px", height: "24px" });
    case "suspend":
      return /* @__PURE__ */ React.createElement(AiOutlineSafetyCertificate, { size: "24" });
    case "collect-service-endpoints":
      return /* @__PURE__ */ React.createElement(
        SvgEp,
        {
          width: "24px",
          height: "24px",
          style: {
            color: "var(--primary-color)"
          }
        }
      );
    default:
      return /* @__PURE__ */ React.createElement(SvgWorkflow, { width: "24px", height: "24px" });
  }
};
