"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Button, Dialog, Message, Table } from "@alifd/next";
import { connect } from "dva";
import { Link } from "dva/router";
import React from "react";
import { AiFillDelete } from "react-icons/ai";
import { deleteWorkflow } from "../../api/workflows";
import { If } from "../../components/If";
import Permission from "../../components/Permission";
import { Translation } from "../../components/Translation";
import i18n from "../../i18n";
import { momentDate } from "../../utils/common";
import { locale } from "../../utils/locale";
let ApplicationWorkflowList = class extends React.Component {
  constructor(props) {
    super(props);
    this.loadApplicationWorkflows = async () => {
      const {
        params: { appName }
      } = this.props.match;
      this.props.dispatch({
        type: "application/getApplicationWorkflows",
        payload: { appName }
      });
    };
    this.onDeleteWorkflow = (name) => {
      const { applicationDetail } = this.props;
      if (applicationDetail) {
        Dialog.confirm({
          type: "confirm",
          content: /* @__PURE__ */ React.createElement(Translation, null, "Unrecoverable after deletion, are you sure to delete it?"),
          onOk: () => {
            deleteWorkflow({
              appName: applicationDetail.name,
              name
            }).then((res) => {
              if (res) {
                Message.success(i18n.t("Workflow removed successfully"));
                this.loadApplicationWorkflows();
              }
            });
          },
          locale: locale().Dialog
        });
      }
    };
    this.state = {};
  }
  componentDidMount() {
  }
  render() {
    const { applicationDetail, workflows } = this.props;
    const projectName = applicationDetail?.project?.name;
    return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Table, { dataSource: workflows }, /* @__PURE__ */ React.createElement(
      Table.Column,
      {
        dataIndex: "name",
        title: i18n.t("Name").toString(),
        cell: (v, i, w) => {
          return /* @__PURE__ */ React.createElement(Link, { to: `/applications/${applicationDetail?.name}/envbinding/${w.envName}/workflow/${v}/studio` }, v);
        }
      }
    ), /* @__PURE__ */ React.createElement(
      Table.Column,
      {
        dataIndex: "envName",
        title: i18n.t("Environment").toString(),
        cell: (v) => {
          return /* @__PURE__ */ React.createElement(Link, { to: `/applications/${applicationDetail?.name}/envbinding/${v}/workflow` }, v);
        }
      }
    ), /* @__PURE__ */ React.createElement(Table.Column, { dataIndex: "mode", title: i18n.t("Mode").toString() }), /* @__PURE__ */ React.createElement(
      Table.Column,
      {
        dataIndex: "steps",
        title: i18n.t("Step Count").toString(),
        cell: (steps) => {
          return steps ? steps.length : 0;
        }
      }
    ), /* @__PURE__ */ React.createElement(
      Table.Column,
      {
        dataIndex: "createTime",
        title: i18n.t("Create Time").toString(),
        cell: (v) => {
          return momentDate(v);
        }
      }
    ), /* @__PURE__ */ React.createElement(
      Table.Column,
      {
        dataIndex: "updateTime",
        title: i18n.t("Update Time").toString(),
        cell: (v) => {
          return momentDate(v);
        }
      }
    ), /* @__PURE__ */ React.createElement(
      Table.Column,
      {
        dataIndex: "name",
        title: i18n.t("Action").toString(),
        cell: (v, i, w) => {
          return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(If, { condition: v != "workflow-" + w.envName }, /* @__PURE__ */ React.createElement(
            Permission,
            {
              project: projectName,
              resource: {
                resource: `project:${projectName}/application:${applicationDetail?.name}/workflow:${v}`,
                action: "delete"
              }
            },
            /* @__PURE__ */ React.createElement(
              Button,
              {
                text: true,
                size: "medium",
                className: "danger-btn",
                component: "a",
                onClick: () => {
                  this.onDeleteWorkflow(v);
                }
              },
              /* @__PURE__ */ React.createElement(AiFillDelete, null),
              /* @__PURE__ */ React.createElement(Translation, null, "Remove")
            )
          )));
        }
      }
    )));
  }
};
ApplicationWorkflowList = __decorateClass([
  connect((store) => {
    return { ...store.application };
  })
], ApplicationWorkflowList);
export default ApplicationWorkflowList;
