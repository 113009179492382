"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Button, Loading } from "@alifd/next";
import { connect } from "dva";
import YAML from "js-yaml";
import React, { Component } from "react";
import { getChartValueFiles } from "../../api/repository";
import HelmValueShow from "../../components/HelmValueShow";
import { Translation } from "../../components/Translation";
import KV from "../KV";
function setValues(target, value, key, keys) {
  if (!keys || keys.length == 0) {
    target[key] = value;
    return target;
  }
  if (target[key]) {
    target[key] = setValues(target[key], value, keys[0], keys.slice(1));
    return target;
  }
  target[key] = setValues({}, value, keys[0], keys.slice(1));
  return target;
}
function getValues(target, key, newValues) {
  if (!target) {
    newValues[key] = target;
    return;
  }
  if (typeof target === "object") {
    const keys = Object.keys(target);
    keys.map((subkey) => {
      getValues(target[subkey], key ? key + "." + subkey : subkey, newValues);
    });
  } else {
    newValues[key] = target;
  }
}
let HelmValues = class extends Component {
  constructor(props) {
    super(props);
    this.loadChartValues = () => {
      const { project } = this.props;
      const { helm, repo } = this.props;
      if (helm?.chart && helm.version && helm.url) {
        getChartValueFiles({ ...helm, secretName: repo?.secretName, project }).then(
          (re) => {
            if (re) {
              try {
                const defaultValueFile = re["values.yaml"];
                const defaultValue = YAML.load(defaultValueFile);
                const newValues = {};
                getValues(defaultValue, "", newValues);
                this.setState({ values: newValues, valueFiles: re, helm, loading: false });
              } catch (err) {
              }
            }
          }
        );
      }
    };
    this.onChange = (values) => {
      let helmValues = {};
      if (values) {
        Object.keys(values).map((key) => {
          const keys = key.split(".");
          helmValues = setValues(helmValues, values[key], keys[0], keys.slice(1));
        });
      }
      this.props.onChange(helmValues);
    };
    this.renderValue = () => {
      const newValues = {};
      getValues(this.props.value, "", newValues);
      return newValues;
    };
    this.renderHelmKey = (params) => {
      if (!params) {
        return "";
      }
      return params.url + params.chart + params.version;
    };
    this.state = {
      loading: true
    };
  }
  componentDidMount() {
    this.loadChartValues();
  }
  render() {
    const { helm } = this.props;
    const { values, loading, helm: stateHelm, showValuesFile, valueFiles } = this.state;
    if (this.renderHelmKey(helm) != this.renderHelmKey(stateHelm)) {
      this.loadChartValues();
    }
    return /* @__PURE__ */ React.createElement(Loading, { visible: loading, style: { width: "100%" } }, /* @__PURE__ */ React.createElement(
      KV,
      {
        disabled: this.props.disabled,
        onChange: this.onChange,
        value: this.renderValue(),
        keyOptions: values,
        additional: this.props.additional,
        additionalParameter: this.props.additionalParameter,
        subParameters: this.props.subParameters,
        id: this.props.id
      }
    ), valueFiles && /* @__PURE__ */ React.createElement(
      Button,
      {
        onClick: () => {
          this.setState({ showValuesFile: true });
        },
        size: "small",
        type: "secondary"
      },
      /* @__PURE__ */ React.createElement(Translation, null, "Show Values File")
    ), showValuesFile && valueFiles && /* @__PURE__ */ React.createElement(
      HelmValueShow,
      {
        name: helm?.chart || "default",
        valueFiles,
        onClose: () => {
          this.setState({ showValuesFile: false });
        }
      }
    ));
  }
};
HelmValues = __decorateClass([
  connect((store) => {
    return { ...store.uischema };
  })
], HelmValues);
export default HelmValues;
