"use strict";
import { getLoginUserInfo } from "../api/authentication";
import { loadSystemInfo } from "../api/system_config";
const user = {
  namespace: "user",
  state: {
    userInfo: {},
    systemInfo: {}
  },
  reducers: {
    updateUserInfo(state, { payload }) {
      return {
        ...state,
        userInfo: payload
      };
    },
    updateSystemInfo(state, { payload }) {
      return {
        ...state,
        systemInfo: payload
      };
    }
  },
  effects: {
    *getLoginUserInfo(action, { call, put }) {
      const result = yield call(getLoginUserInfo, action.payload);
      yield put({ type: "updateUserInfo", payload: result || {} });
      if (action.callback && result) {
        action.callback(result);
      }
    },
    *getSystemInfo(action, { call, put }) {
      const result = yield call(loadSystemInfo, action.payload);
      yield put({ type: "updateSystemInfo", payload: result || {} });
      if (result && action.callback) {
        action.callback(result);
      }
    }
  }
};
export default user;
