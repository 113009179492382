"use strict";
import { getTarget } from "../api/target";
const targets = {
  namespace: "target",
  state: {
    targets: [],
    total: 0
  },
  reducers: {
    updateTargets(state, { payload }) {
      return {
        ...state,
        targets: payload.targets,
        total: payload.total
      };
    }
  },
  effects: {
    *listTargets(action, { call, put }) {
      const result = yield call(getTarget, action.payload);
      yield put({ type: "updateTargets", payload: result || {} });
    }
  }
};
export default targets;
