"use strict";
import { Balloon, Card, Dialog, Field, Form, Grid, Input } from "@alifd/next";
import _ from "lodash";
import React from "react";
import i18n from "../../i18n";
import { checkName, showAlias } from "../../utils/common";
import { locale } from "../../utils/locale";
import Item from "../Item";
import { Translation } from "../Translation";
import { StepTypeIcon } from "./step-icon";
import "./index.less";
const { Row, Col } = Grid;
const defaultCategory = {
  "Application Delivery": {
    title: "Application Delivery",
    description: "Delivery the Application or workloads to the Targets.",
    definitions: [],
    sort: 2
  },
  "Resource Management": {
    title: "Resource Management",
    description: "Steps for Resource Management",
    definitions: [],
    sort: 3
  },
  Terraform: {
    title: "Terraform",
    description: "Terraform workflow steps",
    definitions: [],
    sort: 4
  },
  "Config Management": {
    title: "Config Management",
    description: "Create or read the config.",
    definitions: [],
    sort: 5
  },
  "CI Integration": {
    title: "CI Integration",
    description: "CI integration steps",
    definitions: [],
    sort: 6
  },
  "External Integration": {
    title: "External Integration",
    description: "External Integration steps",
    definitions: [],
    sort: 7
  },
  "Process Control": {
    title: "Process Control",
    description: "Process Control steps",
    definitions: [],
    sort: 1
  },
  "Scripts & Commands": {
    title: "Scripts & Commands",
    description: "Steps for executing Scripts & Commands",
    definitions: [],
    sort: 8
  },
  Custom: {
    title: "Custom",
    description: "Custom Workflow or Pipeline steps",
    definitions: [],
    sort: 1e3
  }
};
const initDefinitionCategory = (defs) => {
  return defs.map((def) => {
    if (!def.category || def.category == "") {
      def.category = "Custom";
    }
    return def;
  });
};
const buildDefinitionCategory = (defs) => {
  const customDefs = initDefinitionCategory(defs);
  const categoryMap = _.cloneDeep(defaultCategory);
  customDefs.map((def) => {
    const category = def.category;
    if (!category) {
      return;
    }
    if (categoryMap[category]) {
      categoryMap[category].definitions.push(def);
    } else {
      categoryMap[category] = { title: category, definitions: [def], sort: 100 };
    }
  });
  return Object.values(categoryMap).sort((a, b) => {
    return a.sort - b.sort;
  });
};
class TypeSelect extends React.Component {
  constructor(props) {
    super(props);
    this.field = new Field(this);
    this.onSubmit = () => {
      const { selectType } = this.state;
      this.field.validate((error, values) => {
        if (error) {
          return;
        }
        if (!selectType) {
          return;
        }
        const { name, alias, description } = values;
        this.props.addStep({
          type: selectType.name,
          name,
          alias,
          description
        });
      });
    };
    this.state = {};
  }
  render() {
    const { definitions, onClose, checkStepName, addSub } = this.props;
    const { selectType } = this.state;
    const categories = buildDefinitionCategory(definitions?.filter((def) => !addSub || def.name != "step-group") || []);
    const { init } = this.field;
    const checkStepNameRule = (rule, value, callback) => {
      if (checkStepName(value)) {
        callback("Name is used.");
        return;
      }
      callback();
    };
    return /* @__PURE__ */ React.createElement(
      Dialog,
      {
        locale: locale().Dialog,
        autoFocus: true,
        overflowScroll: true,
        onClose,
        onCancel: onClose,
        width: 800,
        onOk: this.onSubmit,
        title: selectType ? i18n.t("Set Step Basic Info").toString() : i18n.t("Select Step Type").toString(),
        visible: true,
        v2: true
      },
      /* @__PURE__ */ React.createElement("div", null, !selectType && categories.filter((c) => c.definitions.length > 0).map((category) => {
        return /* @__PURE__ */ React.createElement(
          Card,
          {
            title: category.title,
            contentHeight: "auto",
            key: category.title,
            subTitle: category.description
          },
          /* @__PURE__ */ React.createElement("div", { className: "def-items" }, category.definitions?.map((def) => {
            const item = /* @__PURE__ */ React.createElement("div", { key: def.name, className: "def-item" }, /* @__PURE__ */ React.createElement(
              "div",
              {
                className: "icon",
                onClick: () => {
                  this.setState({ selectType: def });
                }
              },
              /* @__PURE__ */ React.createElement(StepTypeIcon, { type: def.name })
            ), /* @__PURE__ */ React.createElement("div", { className: "name" }, showAlias(def.name, def.alias)));
            if (def.description) {
              return /* @__PURE__ */ React.createElement(Balloon, { key: def.name + "balloon", trigger: item }, def.description);
            }
            return item;
          }))
        );
      }), selectType && /* @__PURE__ */ React.createElement(Form, { field: this.field }, /* @__PURE__ */ React.createElement(Row, { wrap: true }, /* @__PURE__ */ React.createElement(Col, { span: 24 }, /* @__PURE__ */ React.createElement(Item, { label: i18n.t("Select Type"), value: showAlias(selectType.name, selectType.alias) })), /* @__PURE__ */ React.createElement(Col, { span: 24 }, /* @__PURE__ */ React.createElement(Item, { label: i18n.t("Type Description"), value: selectType.description }))), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(Form.Item, { label: /* @__PURE__ */ React.createElement(Translation, null, "Name"), labelTextAlign: "left", required: true }, /* @__PURE__ */ React.createElement(
        Input,
        {
          htmlType: "name",
          name: "name",
          maxLength: 32,
          ...init("name", {
            rules: [
              {
                required: true,
                pattern: checkName,
                message: "Please enter a valid workflow step name"
              },
              {
                validator: checkStepNameRule
              }
            ]
          })
        }
      ))), /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(Form.Item, { label: /* @__PURE__ */ React.createElement(Translation, null, "Alias") }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "alias",
          ...init("alias", {
            rules: [
              {
                minLength: 2,
                maxLength: 64,
                message: "Enter a string of 2 to 64 characters."
              }
            ]
          })
        }
      )))), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(Form.Item, { label: /* @__PURE__ */ React.createElement(Translation, null, "Description") }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "description",
          ...init("description", {
            rules: [
              {
                maxLength: 256,
                message: "Enter a description that contains less than 256 characters."
              }
            ]
          })
        }
      ))))))
    );
  }
}
export default TypeSelect;
