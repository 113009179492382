"use strict";
import * as React from "react";
import { Dialog, Form, Grid, Message, NumberPicker } from "@alifd/next";
import { DeployModes } from "@velaux/data";
import _ from "lodash";
import i18n from "../../../../i18n";
import { locale } from "../../../../utils/locale";
import "./index.less";
import Item from "../../../../components/Item";
import { If } from "../../../../components/If";
import { locationService } from "../../../../services/LocationService";
const { Row, Col } = Grid;
const generateCanaryDeployGroup = (step, batch) => {
  const interval = Math.round(100 / batch);
  const policies = step.properties ? step.properties["policies"] : null;
  const steps = [
    {
      name: "prepare-canary",
      alias: "Prepare Canary",
      type: DeployModes.CanaryDeploy,
      properties: {
        weight: 0,
        policies: _.cloneDeep(policies)
      }
    }
  ];
  for (let i = 1; i <= batch; i++) {
    const batchStep = {
      name: step.name + "-batch-" + i,
      alias: "Batch " + i,
      type: DeployModes.CanaryDeploy,
      properties: {
        weight: i == batch ? 100 : interval * i,
        policies: []
      }
    };
    if (policies && batchStep.properties) {
      batchStep.properties["policies"] = _.cloneDeep(policies);
    }
    const approveStep = { name: step.name + "-approve-" + i, alias: "Approve " + i, type: "suspend" };
    if (i > 0) {
      steps.push(approveStep);
    }
    steps.push(batchStep);
  }
  return {
    type: "step-group",
    name: step.name + "-canary",
    mode: "StepByStep",
    subSteps: steps
  };
};
export const CanarySetting = (props) => {
  const [deployBatchConfig, setDeployBatchConfig] = React.useState({});
  const initBatch = 2;
  const getStepBatch = (stepName) => {
    if (deployBatchConfig[stepName]) {
      return deployBatchConfig[stepName];
    }
    return initBatch;
  };
  const canaryDeployDefinition = props.definitions?.find((d) => d.name === DeployModes.CanaryDeploy);
  const onSubmit = () => {
    if (!canaryDeployDefinition) {
      locationService.push("/addons/kruise-rollout");
      return;
    }
    const newSteps = props.workflow?.steps?.map((step) => {
      if (step.type === DeployModes.Deploy) {
        const batch = getStepBatch(step.name);
        return generateCanaryDeployGroup(step, batch);
      }
      return step;
    });
    if (newSteps) {
      props.onChange(newSteps);
    }
    props.onCancel();
  };
  const deploySteps = props.workflow?.steps?.filter((step) => step.type === DeployModes.Deploy);
  const canSetting = deploySteps && deploySteps.length > 0;
  return /* @__PURE__ */ React.createElement(
    Dialog,
    {
      v2: true,
      locale: locale().Dialog,
      onCancel: props.onCancel,
      onClose: props.onCancel,
      onOk: onSubmit,
      footerActions: canSetting ? ["ok", "cancel"] : ["cancel"],
      title: i18n.t("Canary Rollout Setting").toString(),
      visible: true,
      width: "600px"
    },
    /* @__PURE__ */ React.createElement(If, { condition: !canaryDeployDefinition }, /* @__PURE__ */ React.createElement(Message, { type: "warning" }, i18n.t("The default canary rollout is powered by kruise-rollout addon, let's enable it first."))),
    /* @__PURE__ */ React.createElement(If, { condition: canaryDeployDefinition }, canSetting && /* @__PURE__ */ React.createElement("div", { className: "canary-step-setting" }, /* @__PURE__ */ React.createElement(Message, { type: "help", style: { marginBottom: "var(--spacing-4)" } }, i18n.t("You can also edit the workflow directly to switch to canary deploy mode.")), /* @__PURE__ */ React.createElement(Form, null, deploySteps.map((step) => {
      return /* @__PURE__ */ React.createElement("div", { className: "canary-step", key: step.name }, /* @__PURE__ */ React.createElement("div", { className: "source" }, /* @__PURE__ */ React.createElement(Row, { wrap: true }, /* @__PURE__ */ React.createElement(Col, { span: 24 }, /* @__PURE__ */ React.createElement(Item, { labelWidth: 70, label: "Name", value: step.name })), /* @__PURE__ */ React.createElement(Col, { span: 24 }, /* @__PURE__ */ React.createElement(Item, { labelWidth: 70, label: "Alias", value: step.alias })), /* @__PURE__ */ React.createElement(Col, { span: 24 }, /* @__PURE__ */ React.createElement(
        Item,
        {
          labelWidth: 70,
          label: "Policies",
          value: step.properties && step.properties["policies"] || []
        }
      )))), /* @__PURE__ */ React.createElement("div", { className: "target" }, /* @__PURE__ */ React.createElement(Form.Item, { required: true, label: i18n.t("Batch Setting").toString(), style: { width: "100%" } }, /* @__PURE__ */ React.createElement(Row, { wrap: true }, /* @__PURE__ */ React.createElement(Col, { span: 24 }, /* @__PURE__ */ React.createElement(
        NumberPicker,
        {
          onChange: (v) => {
            deployBatchConfig[step.name] = v;
            setDeployBatchConfig(deployBatchConfig);
          },
          defaultValue: initBatch,
          max: 4,
          min: 1
        }
      ))))));
    }))), !canSetting && /* @__PURE__ */ React.createElement(Message, { type: "warning" }, i18n.t("There is no deploy steps exist.")))
  );
};
