"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Pagination, Button } from "@alifd/next";
import { connect } from "dva";
import React from "react";
import { If } from "../../components/If";
import { ListTitle } from "../../components/ListTitle";
import Permission from "../../components/Permission";
import { Translation } from "../../components/Translation";
import { locale } from "../../utils/locale";
import EnvDialog from "./components/EnvDialog";
import TableList from "./components/List";
import "./index.less";
let EnvList = class extends React.Component {
  constructor(props) {
    super(props);
    this.getEnvList = async () => {
      const { page, pageSize } = this.state;
      this.props.dispatch({
        type: "env/listEnvs",
        payload: {
          page,
          pageSize
        }
      });
    };
    this.updateEnvList = () => {
      this.setState(
        {
          page: 0,
          pageSize: 10
        },
        () => {
          this.getEnvList();
        }
      );
    };
    this.changeISEdit = (isEdit, record) => {
      this.setState({
        isEdit,
        visibleEnvDialog: true,
        envItem: record
      });
    };
    this.onClose = () => {
      this.setState({ visibleEnvDialog: false, isEdit: false });
    };
    this.onOk = () => {
      this.getEnvList();
      this.setState({
        isEdit: false
      });
    };
    this.handleChange = (page) => {
      this.setState(
        {
          page
        },
        () => {
          this.getEnvList();
        }
      );
    };
    this.state = {
      page: 1,
      pageSize: 10,
      showAddTarget: false,
      editTargetName: "",
      visibleEnvDialog: false,
      isEdit: false
    };
  }
  componentDidMount() {
    this.getEnvList();
  }
  render() {
    const { envTotal, envs, userInfo } = this.props;
    const { visibleEnvDialog, isEdit, envItem } = this.state;
    return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
      ListTitle,
      {
        title: "Environments",
        subTitle: "Set up the Environments for your Application based on Target sources",
        extButtons: [
          /* @__PURE__ */ React.createElement(
            Permission,
            {
              key: "new-env",
              request: { resource: "project:?/environment:*", action: "create" },
              project: "?"
            },
            /* @__PURE__ */ React.createElement(
              Button,
              {
                type: "primary",
                onClick: () => {
                  this.setState({ visibleEnvDialog: true, envItem: void 0 });
                }
              },
              /* @__PURE__ */ React.createElement(Translation, null, "New Environment")
            ),
            ","
          )
        ]
      }
    ), /* @__PURE__ */ React.createElement(
      TableList,
      {
        list: envs || [],
        updateEnvList: this.updateEnvList,
        userInfo,
        changeISEdit: (is, record) => {
          this.changeISEdit(is, record);
        }
      }
    ), /* @__PURE__ */ React.createElement(
      Pagination,
      {
        className: "delivery-target-pagination",
        total: envTotal,
        locale: locale().Pagination,
        size: "medium",
        pageSize: this.state.pageSize,
        current: this.state.page,
        onChange: this.handleChange
      }
    ), /* @__PURE__ */ React.createElement(If, { condition: visibleEnvDialog }, /* @__PURE__ */ React.createElement(
      EnvDialog,
      {
        visible: visibleEnvDialog,
        projects: userInfo?.projects || [],
        isEdit,
        userInfo,
        envItem,
        onClose: this.onClose,
        onOK: this.onOk
      }
    )));
  }
};
EnvList = __decorateClass([
  connect((store) => {
    return { ...store.target, ...store.application, ...store.env, ...store.user };
  })
], EnvList);
export default EnvList;
