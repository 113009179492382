"use strict";
import { Icon } from "@alifd/next";
import classNames from "classnames";
import React from "react";
import { AiFillClockCircle } from "react-icons/ai";
import { BiError } from "react-icons/bi";
import { FaStopCircle } from "react-icons/fa";
import { If } from "../../../../components/If";
const RunStatusIcon = (props) => {
  const { status } = props;
  return /* @__PURE__ */ React.createElement("div", { className: classNames("icon", { warning: status == "failed" }, { success: status == "succeeded" }) }, /* @__PURE__ */ React.createElement(If, { condition: status == "failed" || status == "terminated" }, /* @__PURE__ */ React.createElement(BiError, null)), /* @__PURE__ */ React.createElement(If, { condition: status == "executing" }, /* @__PURE__ */ React.createElement(Icon, { type: "loading" })), /* @__PURE__ */ React.createElement(If, { condition: status == "succeeded" }, /* @__PURE__ */ React.createElement(Icon, { type: "success-filling" })), /* @__PURE__ */ React.createElement(If, { condition: status == "initializing" }, /* @__PURE__ */ React.createElement(FaStopCircle, null)), /* @__PURE__ */ React.createElement(If, { condition: status == "suspending" }, /* @__PURE__ */ React.createElement(AiFillClockCircle, null)), /* @__PURE__ */ React.createElement("span", { className: "status-text" }, (status || "pending").toUpperCase()));
};
export default RunStatusIcon;
