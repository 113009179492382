"use strict";
import { Upload, Button, Field } from "@alifd/next";
import { decode, encode } from "base-64";
import React from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { v4 as uuid } from "uuid";
import DefinitionCode from "../../components/DefinitionCode";
import { If } from "../../components/If";
import { Translation } from "../../components/Translation";
class CertBase64 extends React.Component {
  constructor(props) {
    super(props);
    this.componentDidMount = () => {
      const { value } = this.props;
      this.setValues(value);
    };
    this.setValues = (value) => {
      if (value) {
        this.form.setValue("cert", decode(value));
      }
    };
    this.onChange = (v) => {
      const { onChange } = this.props;
      if (onChange) {
        onChange(encode(v));
      }
    };
    this.customRequest = (option) => {
      const reader = new FileReader();
      const fileselect = option.file;
      reader.readAsText(fileselect);
      reader.onload = () => {
        this.form.setValue("cert", reader.result?.toString() || "");
      };
      return {
        file: File,
        abort() {
        }
      };
    };
    this.state = {
      message: "",
      containerId: uuid()
    };
    this.form = new Field(this, {
      onChange: () => {
        const values = this.form.getValues();
        this.onChange(values.cert);
      }
    });
  }
  render() {
    const { id, disabled } = this.props;
    const { init } = this.form;
    const { message, containerId } = this.state;
    return /* @__PURE__ */ React.createElement("div", { id }, /* @__PURE__ */ React.createElement(If, { condition: message }, /* @__PURE__ */ React.createElement("span", { style: { color: "red" } }, message)), /* @__PURE__ */ React.createElement(Upload, { disabled, request: this.customRequest }, /* @__PURE__ */ React.createElement(Button, { text: true, type: "normal", className: "padding-left-0" }, /* @__PURE__ */ React.createElement(AiOutlineCloudUpload, null), /* @__PURE__ */ React.createElement(Translation, null, "Upload File"))), /* @__PURE__ */ React.createElement("div", { id: containerId, className: "guide-code" }, /* @__PURE__ */ React.createElement(DefinitionCode, { containerId, language: "yaml", readOnly: disabled, ...init("cert") })));
  }
}
export default CertBase64;
