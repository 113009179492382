"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Table, Button, Dialog, Message } from "@alifd/next";
import { connect } from "dva";
import React, { Component, Fragment } from "react";
import { getConfigs, deleteConfig } from "../../api/config";
import { If } from "../../components/If";
import Permission from "../../components/Permission";
import { Translation } from "../../components/Translation";
import { momentDate } from "../../utils/common";
import { locale } from "../../utils/locale";
import { getMatchParamObj } from "../../utils/utils";
import "./index.less";
import CreateConfigDialog from "./components/CreateConfigDialog";
import { HiOutlineRefresh } from "react-icons/hi";
let Configs = class extends Component {
  constructor(props) {
    super(props);
    this.getTemplateName = () => {
      return getMatchParamObj(this.props.match, "templateName");
    };
    this.onDelete = (record) => {
      Dialog.confirm({
        content: "Are you sure want to delete this config",
        onOk: () => {
          const { name } = record;
          if (name) {
            deleteConfig(name).then((res) => {
              if (res) {
                Message.success(/* @__PURE__ */ React.createElement(Translation, null, "Config deleted successfully"));
                this.listConfigs();
              }
            });
          }
        },
        locale: locale().Dialog
      });
    };
    this.onSuccess = () => {
      this.setState({ visible: false, configName: "" });
      this.listConfigs();
    };
    this.onCloseCreate = () => {
      this.setState({ visible: false, configName: "" });
    };
    this.handleClickCreate = () => {
      this.setState({
        visible: true
      });
    };
    this.onClick = (configName) => {
      this.setState({
        visible: true,
        configName
      });
    };
    this.state = {
      template: this.getTemplateName(),
      list: [],
      visible: false,
      isLoading: false
    };
  }
  componentDidMount() {
    this.listConfigs();
  }
  componentWillReceiveProps(nextProps) {
    const nextPropsParams = nextProps.match.params || {};
    if (nextPropsParams.templateName && nextPropsParams.templateName !== this.state.template) {
      this.setState(
        {
          template: nextPropsParams.templateName
        },
        () => {
          this.listConfigs();
        }
      );
    }
  }
  listConfigs() {
    const { template } = this.state;
    if (!template) {
      return;
    }
    this.setState({ isLoading: true });
    getConfigs(template).then((res) => {
      if (res) {
        this.setState({
          list: res.configs || []
        });
      } else {
        this.setState({
          list: []
        });
      }
    }).finally(() => {
      this.setState({ isLoading: false });
    });
  }
  render() {
    const columns = [
      {
        key: "name",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Name(Alias)"),
        dataIndex: "name",
        cell: (v, i, config) => {
          const title = `${v}(${config.alias || "-"})`;
          if (config.sensitive) {
            return /* @__PURE__ */ React.createElement("span", null, title);
          }
          return /* @__PURE__ */ React.createElement("a", { onClick: () => this.onClick(config.name) }, title);
        }
      },
      {
        key: "description",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Description"),
        dataIndex: "description",
        cell: (v) => {
          return /* @__PURE__ */ React.createElement("span", null, v);
        }
      },
      {
        key: "createTime",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Create Time"),
        dataIndex: "createdTime",
        cell: (v) => {
          return /* @__PURE__ */ React.createElement("span", null, momentDate(v));
        }
      },
      {
        key: "operation",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Actions"),
        dataIndex: "operation",
        cell: (v, i, record) => {
          return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement(Permission, { request: { resource: `config:${record.name}`, action: "delete" }, project: "" }, /* @__PURE__ */ React.createElement(
            Button,
            {
              text: true,
              size: "medium",
              component: "a",
              onClick: () => {
                this.onDelete(record);
              }
            },
            /* @__PURE__ */ React.createElement(Translation, null, "Delete")
          )));
        }
      }
    ];
    const { Column } = Table;
    const { list, visible, isLoading, template, configName } = this.state;
    return /* @__PURE__ */ React.createElement("div", { className: "list-content" }, /* @__PURE__ */ React.createElement("div", { className: "create-btn" }, /* @__PURE__ */ React.createElement(Button, { type: "secondary", onClick: () => this.listConfigs(), style: { marginRight: "16px" } }, /* @__PURE__ */ React.createElement(HiOutlineRefresh, null)), /* @__PURE__ */ React.createElement(Permission, { request: { resource: `config:*`, action: "create" }, project: "" }, /* @__PURE__ */ React.createElement(Button, { type: "primary", onClick: this.handleClickCreate }, /* @__PURE__ */ React.createElement(Translation, null, "New")))), /* @__PURE__ */ React.createElement(Table, { locale: locale().Table, dataSource: list, loading: isLoading }, columns.map((col, key) => /* @__PURE__ */ React.createElement(Column, { ...col, key, align: "left" }))), /* @__PURE__ */ React.createElement(If, { condition: visible && template }, /* @__PURE__ */ React.createElement(
      CreateConfigDialog,
      {
        visible,
        configName,
        template: { name: template },
        onSuccess: this.onSuccess,
        onClose: this.onCloseCreate
      }
    )));
  }
};
Configs = __decorateClass([
  connect((store) => {
    return { ...store.configs, ...store.user };
  })
], Configs);
export default Configs;
