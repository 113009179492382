"use strict";
import { getDomain } from "../utils/common";
import { application, definition } from "./productionLink";
import { get, rdelete, put, post } from "./request";
const baseURLOject = getDomain();
const base = baseURLOject.APIBASE;
export function listWorkflow(params) {
  const url = base + `${application}/${params.appName}/workflows`;
  return get(url, {}).then((res) => res);
}
export function updateWorkflow(pathParams, params) {
  const url = base + `${application}/${pathParams.appName}/workflows/${pathParams.workflowName}`;
  return put(url, params).then((res) => res);
}
export function createWorkflow(pathParams, params) {
  const url = base + `${application}/${pathParams.appName}/workflows`;
  return post(url, params).then((res) => res);
}
export function getEnvWorkflowRecord(params) {
  const url = base + `${application}/${params.appName}/workflows/${params.workflowName}/records`;
  return get(url, params).then((res) => res);
}
export function detailWorkflow(params) {
  const url = base + `${application}/${params.appName}/workflows/${params.name}`;
  return get(url, {});
}
export function detailWorkflowRecord(params) {
  const url = base + `${application}/${params.appName}/workflows/${params.workflowName}/records/${params.record}`;
  return get(url, {});
}
export function getWorkflowRecordLogs(params) {
  const url = base + `${application}/${params.appName}/workflows/${params.workflowName}/records/${params.record}/logs`;
  return get(url, { params: { step: params.step } });
}
export function getWorkflowRecordInputs(params) {
  const url = base + `${application}/${params.appName}/workflows/${params.workflowName}/records/${params.record}/inputs`;
  return get(url, { params: { step: params.step } });
}
export function getWorkflowRecordOutputs(params) {
  const url = base + `${application}/${params.appName}/workflows/${params.workflowName}/records/${params.record}/outputs`;
  return get(url, { params: { step: params.step } });
}
export function deleteWorkflow(params) {
  const url = base + `${application}/${params.appName}/workflows/${params.name}`;
  return rdelete(url, {});
}
export function getWorkflowDefinitions(scope) {
  const url = base + `${definition}`;
  return get(url, { params: { type: "workflowstep", scope } }).then((res) => res);
}
export function detailWorkflowDefinition(params) {
  const url = base + `${definition}/${params.name}`;
  return get(url, { params: { type: "workflowstep" } }).then((res) => res);
}
