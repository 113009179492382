"use strict";
import { Redirect, Route, Switch } from "dva/router";
import React from "react";
import Addons from "../../pages/Addons/index";
import ApplicationConfig from "../../pages/ApplicationConfig";
import ApplicationEnvRoute from "../../pages/ApplicationEnvRoute";
import ApplicationInstanceList from "../../pages/ApplicationInstanceList";
import Application from "../../pages/ApplicationList";
import ApplicationLog from "../../pages/ApplicationLog";
import ApplicationRevisionList from "../../pages/ApplicationRevisionList";
import ApplicationStatus from "../../pages/ApplicationStatus";
import ApplicationWorkflowList from "../../pages/ApplicationWorkflowList";
import ApplicationWorkflowStatus from "../../pages/ApplicationWorkflowStatus/index";
import ApplicationWorkflowStudio from "../../pages/ApplicationWorkflowStudio";
import Clusters from "../../pages/Cluster/index";
import Configs from "../../pages/Configs";
import Definitions from "../../pages/Definitions";
import EnvPage from "../../pages/EnvPage";
import NotFound from "../../pages/NotFound";
import PipelineListPage from "../../pages/PipelineListPage";
import PipelineRunPage from "../../pages/PipelineRunPage";
import PipelineStudio from "../../pages/PipelineStudio";
import ProjectApplications from "../../pages/ProjectApplications";
import ProjectMembers from "../../pages/ProjectMembers";
import ProjectPipelines from "../../pages/ProjectPipelines";
import ProjectRoles from "../../pages/ProjectRoles";
import ProjectSummary from "../../pages/ProjectSummary";
import Projects from "../../pages/Projects";
import Roles from "../../pages/Roles";
import TargetList from "../../pages/TargetList";
import UiSchema from "../../pages/UiSchema";
import Users from "../../pages/Users";
import ApplicationLayout from "../Application";
import ConfigsLayout from "../Configs";
import DefinitionDetails from "../DefinitionDetails";
import DefinitionsLayout from "../Definitions";
import ProjectLayout from "../Project";
import MyProjectList from "../../pages/MyProjectList";
import PlatformSetting from "../../pages/PlatformSetting";
import { AppConfigPage, AppRootPage } from "../AppRootPage";
export default function Router() {
  return /* @__PURE__ */ React.createElement(Switch, null, /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/",
      render: () => {
        return /* @__PURE__ */ React.createElement(Redirect, { to: "/applications" });
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/projects",
      render: (props) => {
        return /* @__PURE__ */ React.createElement(MyProjectList, { ...props });
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/applications",
      render: (props) => {
        return /* @__PURE__ */ React.createElement(Application, { ...props });
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/applications/:appName",
      render: (props) => {
        return /* @__PURE__ */ React.createElement(Redirect, { to: `/applications/${props.match.params.appName}/config` });
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/applications/:appName/config",
      render: (props) => {
        return /* @__PURE__ */ React.createElement(ApplicationLayout, { ...props }, /* @__PURE__ */ React.createElement(ApplicationConfig, { ...props }));
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/applications/:appName/revisions",
      render: (props) => {
        return /* @__PURE__ */ React.createElement(ApplicationLayout, { ...props }, /* @__PURE__ */ React.createElement(ApplicationRevisionList, { ...props }));
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/applications/:appName/envbinding",
      render: (props) => {
        return /* @__PURE__ */ React.createElement(ApplicationLayout, { ...props }, /* @__PURE__ */ React.createElement(ApplicationEnvRoute, { ...props }), ";");
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/applications/:appName/workflows",
      render: (props) => {
        return /* @__PURE__ */ React.createElement(ApplicationLayout, { ...props }, /* @__PURE__ */ React.createElement(ApplicationWorkflowList, { ...props }));
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/applications/:appName/envbinding/:envName",
      render: (props) => {
        return /* @__PURE__ */ React.createElement(
          Redirect,
          {
            to: `/applications/${props.match.params.appName}/envbinding/${props.match.params.envName}/status`
          }
        );
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/applications/:appName/envbinding/:envName/instances",
      render: (props) => {
        return /* @__PURE__ */ React.createElement(ApplicationLayout, { ...props }, /* @__PURE__ */ React.createElement(ApplicationInstanceList, { ...props }));
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/applications/:appName/envbinding/:envName/status",
      render: (props) => {
        return /* @__PURE__ */ React.createElement(ApplicationLayout, { ...props }, /* @__PURE__ */ React.createElement(ApplicationStatus, { ...props }));
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/applications/:appName/envbinding/:envName/workflow",
      render: (props) => {
        return /* @__PURE__ */ React.createElement(ApplicationLayout, { ...props }, /* @__PURE__ */ React.createElement(ApplicationWorkflowStatus, { ...props }));
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/applications/:appName/envbinding/:envName/workflow/records/:record",
      render: (props) => {
        return /* @__PURE__ */ React.createElement(ApplicationLayout, { ...props }, /* @__PURE__ */ React.createElement(ApplicationWorkflowStatus, { ...props }));
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/applications/:appName/envbinding/:envName/workflow/:workflowName/studio",
      render: (props) => {
        return /* @__PURE__ */ React.createElement(ApplicationLayout, { ...props }, /* @__PURE__ */ React.createElement(ApplicationWorkflowStudio, { ...props }));
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/applications/:appName/envbinding/:envName/logs",
      render: (props) => {
        return /* @__PURE__ */ React.createElement(ApplicationLayout, { ...props }, /* @__PURE__ */ React.createElement(ApplicationLog, { ...props }));
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/envs",
      render: (props) => {
        return /* @__PURE__ */ React.createElement(EnvPage, { ...props });
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/pipelines",
      render: (props) => {
        return /* @__PURE__ */ React.createElement(PipelineListPage, { ...props });
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/projects/:projectName/pipelines/:pipelineName/runs/:runName",
      render: (props) => {
        return /* @__PURE__ */ React.createElement(PipelineRunPage, { ...props });
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/projects/:projectName/pipelines/:pipelineName/studio",
      render: (props) => {
        return /* @__PURE__ */ React.createElement(PipelineStudio, { ...props });
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      path: "/targets",
      render: (props) => {
        return /* @__PURE__ */ React.createElement(TargetList, { ...props });
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      path: "/clusters",
      render: (props) => {
        return /* @__PURE__ */ React.createElement(Clusters, { ...props });
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      path: "/addons/:addonName",
      render: (props) => {
        return /* @__PURE__ */ React.createElement(Addons, { ...props });
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      path: "/addons",
      render: (props) => {
        return /* @__PURE__ */ React.createElement(Addons, { ...props });
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      path: "/users",
      render: (props) => {
        return /* @__PURE__ */ React.createElement(Users, { ...props });
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/platform/projects",
      render: (props) => {
        return /* @__PURE__ */ React.createElement(Projects, { ...props });
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/projects/:projectName",
      render: (props) => {
        return /* @__PURE__ */ React.createElement(Redirect, { to: `/projects/${props.match.params.projectName}/summary` });
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/projects/:projectName/summary",
      render: (props) => {
        const mergeProps = { ...props, ...{ activeId: "summary" } };
        return /* @__PURE__ */ React.createElement(ProjectLayout, { ...mergeProps }, /* @__PURE__ */ React.createElement(ProjectSummary, { ...mergeProps }));
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/projects/:projectName/applications",
      render: (props) => {
        const mergeProps = { ...props, ...{ activeId: "applications" } };
        return /* @__PURE__ */ React.createElement(ProjectLayout, { ...mergeProps }, /* @__PURE__ */ React.createElement(ProjectApplications, { ...mergeProps }));
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/projects/:projectName/pipelines",
      render: (props) => {
        const mergeProps = { ...props, ...{ activeId: "pipelines" } };
        return /* @__PURE__ */ React.createElement(ProjectLayout, { ...mergeProps }, /* @__PURE__ */ React.createElement(ProjectPipelines, { ...mergeProps }));
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/projects/:projectName/roles",
      render: (props) => {
        const mergeProps = { ...props, ...{ activeId: "roles" } };
        return /* @__PURE__ */ React.createElement(ProjectLayout, { ...mergeProps }, /* @__PURE__ */ React.createElement(ProjectRoles, { ...mergeProps }));
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/projects/:projectName/members",
      render: (props) => {
        const mergeProps = { ...props, ...{ activeId: "members" } };
        return /* @__PURE__ */ React.createElement(ProjectLayout, { ...mergeProps }, /* @__PURE__ */ React.createElement(ProjectMembers, { ...mergeProps }));
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/roles",
      render: (props) => {
        return /* @__PURE__ */ React.createElement(Roles, { ...props });
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/configs",
      render: (props) => {
        return /* @__PURE__ */ React.createElement(ConfigsLayout, { ...props });
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/configs/:templateName",
      render: (props) => {
        return /* @__PURE__ */ React.createElement(Redirect, { to: `/configs/${props.match.params.templateName}/config` });
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/configs/:templateName/config",
      render: (props) => {
        return /* @__PURE__ */ React.createElement(ConfigsLayout, { ...props }, /* @__PURE__ */ React.createElement(Configs, { ...props }));
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/definitions",
      render: () => {
        return /* @__PURE__ */ React.createElement(Redirect, { to: `/definitions/component/config` });
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/definitions/:definitionType",
      render: (props) => {
        return /* @__PURE__ */ React.createElement(Redirect, { to: `/definitions/${props.match.params.definitionType}/config` });
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/definitions/:definitionType/config",
      render: (props) => {
        return /* @__PURE__ */ React.createElement(DefinitionsLayout, { ...props }, /* @__PURE__ */ React.createElement(Definitions, { ...props }));
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/definitions/:definitionType/:definitionName/ui-schema",
      render: (props) => {
        const mergeProps = { ...props, ...{ activeId: "uiSchema" } };
        return /* @__PURE__ */ React.createElement(DefinitionDetails, { ...mergeProps }, /* @__PURE__ */ React.createElement(UiSchema, { ...props }));
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      path: "/settings",
      render: (props) => {
        return /* @__PURE__ */ React.createElement(PlatformSetting, { ...props });
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      exact: true,
      path: "/manage/plugins",
      render: (props) => {
        return /* @__PURE__ */ React.createElement(Addons, { plugin: true, ...props });
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      path: "/manage/plugins/:pluginId/config",
      render: (props) => {
        return /* @__PURE__ */ React.createElement(AppConfigPage, { pluginId: props.match.params.pluginId });
      }
    }
  ), /* @__PURE__ */ React.createElement(
    Route,
    {
      path: "/plugins/:pluginId",
      render: (props) => {
        return /* @__PURE__ */ React.createElement(AppRootPage, { pluginId: props.match.params.pluginId });
      }
    }
  ), /* @__PURE__ */ React.createElement(Route, { path: "/notFound", component: NotFound }), /* @__PURE__ */ React.createElement(Redirect, { to: "/notFound" }));
}
