"use strict";
import { Message, Field, Grid, Button, Loading, Card } from "@alifd/next";
import * as yaml from "js-yaml";
import _ from "lodash";
import React, { Component, Fragment } from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { detailDefinition, updateUISchema } from "../../api/definitions";
import DefinitionCode from "../../components/DefinitionCode";
import Empty from "../../components/Empty";
import { If } from "../../components/If";
import Permission from "../../components/Permission";
import { Translation } from "../../components/Translation";
import UISchema from "../../components/UISchema";
import i18n from "../../i18n";
import { locale } from "../../utils/locale";
import "./index.less";
class UiSchema extends Component {
  constructor(props) {
    super(props);
    this.getUISchemaDefinition = async () => {
      const { definitionName, definitionType } = this.state;
      const params = {
        name: definitionName,
        type: definitionType
      };
      this.setState({ isLoading: true });
      detailDefinition(params).then((res) => {
        if (res && res.uiSchema) {
          this.setState({
            uiSchema: res.uiSchema
          });
          this.setYamlValues(res.uiSchema);
        } else {
          this.setState({
            uiSchema: []
          });
          this.setYamlValues(void 0);
        }
      }).finally(() => {
        this.setState({
          isLoading: false
        });
      });
    };
    this.setYamlValues = (value) => {
      if (value) {
        try {
          const yamlValues = yaml.dump(value);
          this.field.setValues({ yamlValues });
        } catch {
        }
      } else {
        this.field.setValues({ yamlValues: "" });
      }
    };
    this.getDefinitionName = () => {
      const { params = { definitionName: "" } } = this.props.match;
      return params.definitionName || "";
    };
    this.getDefinitionType = () => {
      const { params = { definitionType: "" } } = this.props.match;
      return params.definitionType || "";
    };
    this.updateUISchema = () => {
      const { definitionName, definitionType, uiSchema } = this.state;
      const params = {
        name: definitionName,
        definitionType,
        uiSchema
      };
      this.setState({ updateUISchemaLoading: true });
      updateUISchema(params).then((res) => {
        if (res) {
          Message.success(i18n.t("UI schema updated successfully"));
        }
      }).finally(() => {
        this.setState({ updateUISchemaLoading: false });
      });
    };
    this.getYamlHeight = () => {
      const eleUiSchema = document.getElementById("view-ui-schema");
      if (eleUiSchema) {
        return eleUiSchema.clientHeight + "px";
      } else {
        return "600px";
      }
    };
    this.onChangeYaml = (value) => {
      try {
        let object = yaml.load(value);
        if (object && !(object instanceof Array)) {
          object = [object];
        }
        this.setState(
          {
            uiSchema: void 0
          },
          () => {
            this.setState({
              uiSchema: object
            });
          }
        );
      } catch {
      }
    };
    this.sortUISchema = (uiSchema) => {
      if (uiSchema && uiSchema.length !== 0) {
        return _.sortBy(uiSchema, (item) => {
          return item.sort;
        });
      } else {
        return uiSchema;
      }
    };
    this.state = {
      definitionName: this.getDefinitionName(),
      definitionType: this.getDefinitionType(),
      isLoading: false,
      updateUISchemaLoading: false
    };
    this.field = new Field(this);
    this.uiSchemaRef = React.createRef();
    this.DefinitionCodeRef = React.createRef();
  }
  componentDidMount() {
    this.getUISchemaDefinition();
  }
  render() {
    const { Row, Col } = Grid;
    const { uiSchema, definitionName, isLoading, updateUISchemaLoading } = this.state;
    const init = this.field.init;
    const validator = (rule, value, callback) => {
      this.uiSchemaRef.current?.validate(callback);
    };
    const yamlHeight = this.getYamlHeight();
    return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "uiSchema-wrapper" }, /* @__PURE__ */ React.createElement(Loading, { visible: isLoading, inline: false }, /* @__PURE__ */ React.createElement(If, { condition: !uiSchema || uiSchema.length === 0 }, /* @__PURE__ */ React.createElement(Card, { locale: locale().Card }, /* @__PURE__ */ React.createElement(Empty, { message: /* @__PURE__ */ React.createElement(Translation, null, "There is no ui schema definition"), iconWidth: "30px" }))), /* @__PURE__ */ React.createElement(If, { condition: !uiSchema || uiSchema.length !== 0 }, /* @__PURE__ */ React.createElement("section", { className: "margin-top-20", style: { maxWidth: "1520px", margin: "16px auto" } }, /* @__PURE__ */ React.createElement(Message, { type: "notice", style: { margin: "0 8px 8px 16px" } }, /* @__PURE__ */ React.createElement(Translation, null, "Custom the UI schema will preview in right, please refer to the document to get more info"), /* @__PURE__ */ React.createElement(
      "a",
      {
        style: { marginLeft: "8px" },
        target: "_blank",
        href: "https://kubevela.net/docs/reference/ui-schema",
        rel: "noopener noreferrer"
      },
      /* @__PURE__ */ React.createElement(AiOutlineQuestionCircle, { size: 16 })
    )), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: "12", className: "padding-left-10 padding-right-10" }, /* @__PURE__ */ React.createElement(
      Card,
      {
        locale: locale().Card,
        contentHeight: "auto",
        id: "yaml-code",
        className: "yaml-code",
        style: { height: yamlHeight }
      },
      /* @__PURE__ */ React.createElement(
        DefinitionCode,
        {
          containerId: "yaml-code",
          language: "yaml",
          readOnly: false,
          ...init("yamlValues"),
          ref: this.DefinitionCodeRef,
          onChange: this.onChangeYaml
        }
      )
    )), /* @__PURE__ */ React.createElement(Col, { span: "12", className: "padding-left-10 padding-right-10" }, /* @__PURE__ */ React.createElement(If, { condition: uiSchema && uiSchema.length !== 0 }, /* @__PURE__ */ React.createElement("div", { id: "view-ui-schema" }, /* @__PURE__ */ React.createElement("div", { className: "preview" }, /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(Translation, null, "Preview"))), /* @__PURE__ */ React.createElement(Card, { locale: locale().Card, contentHeight: "auto", className: "view-ui-schema" }, /* @__PURE__ */ React.createElement(
      UISchema,
      {
        ...init(`properties`, {
          rules: [
            {
              validator,
              message: "Please check UI Schema properties"
            }
          ]
        }),
        uiSchema: this.sortUISchema(uiSchema),
        ref: this.uiSchemaRef,
        advanced: true,
        value: {},
        mode: "edit"
      }
    ))))))), /* @__PURE__ */ React.createElement("section", { className: "margin-top-20 text-align-center" }, /* @__PURE__ */ React.createElement(
      Permission,
      {
        request: {
          resource: `definition:${definitionName}`,
          action: "update"
        },
        project: ""
      },
      /* @__PURE__ */ React.createElement(Button, { type: "primary", loading: updateUISchemaLoading, onClick: this.updateUISchema }, /* @__PURE__ */ React.createElement(Translation, null, "Save & Online"))
    ))))));
  }
}
export default UiSchema;
