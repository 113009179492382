"use strict";
import { Table, Message, Dialog, Button } from "@alifd/next";
import React, { Component } from "react";
import { AiFillDelete, AiFillSetting } from "react-icons/ai";
import { deleteTarget } from "../../../../api/target";
import Permission from "../../../../components/Permission";
import { Translation } from "../../../../components/Translation";
import "./index.less";
import { locale } from "../../../../utils/locale";
import { Link } from "dva/router";
class TableList extends Component {
  constructor() {
    super(...arguments);
    this.onDelete = (record) => {
      deleteTarget({ name: record.name || "" }).then((re) => {
        if (re) {
          Message.success("target delete success");
          this.props.updateTargetList();
        }
      });
    };
    this.onEdit = (record) => {
      this.props.changeISEdit(true, record);
    };
    this.getColumns = () => {
      return [
        {
          key: "name",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Name"),
          dataIndex: "name",
          cell: (v) => {
            return /* @__PURE__ */ React.createElement("span", null, v);
          }
        },
        {
          key: "alias",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Alias"),
          dataIndex: "alias",
          cell: (v) => {
            return /* @__PURE__ */ React.createElement("span", null, v);
          }
        },
        {
          key: "project",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Project"),
          dataIndex: "project",
          cell: (v) => {
            if (v && v.name) {
              return /* @__PURE__ */ React.createElement(Link, { to: `/projects/${v.name}/summary` }, v && v.name);
            } else {
              return null;
            }
          }
        },
        {
          key: "clusterName/namespace",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Cluster/Namespace"),
          dataIndex: "clusterName/CloudProvider",
          cell: (v, i, record) => {
            return /* @__PURE__ */ React.createElement("span", null, record?.clusterAlias ? record?.clusterAlias : record?.cluster?.clusterName, "/", record?.cluster?.namespace);
          }
        },
        {
          key: "description",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Description"),
          dataIndex: "description",
          cell: (v) => {
            return /* @__PURE__ */ React.createElement("span", null, v);
          }
        },
        {
          key: "operation",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Actions"),
          dataIndex: "operation",
          width: "200px",
          cell: (v, i, record) => {
            return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Permission, { request: { resource: `target:${record.name}`, action: "update" }, project: "" }, /* @__PURE__ */ React.createElement(
              Button,
              {
                text: true,
                component: "a",
                size: "medium",
                className: "margin-left-10",
                onClick: () => {
                  this.onEdit(record);
                }
              },
              /* @__PURE__ */ React.createElement(AiFillSetting, null),
              /* @__PURE__ */ React.createElement(Translation, null, "Edit")
            ), /* @__PURE__ */ React.createElement("span", { className: "line" })), /* @__PURE__ */ React.createElement(Permission, { request: { resource: `target:${record.name}`, action: "delete" }, project: "" }, /* @__PURE__ */ React.createElement(
              Button,
              {
                text: true,
                component: "a",
                size: "medium",
                className: "danger-btn",
                onClick: () => {
                  Dialog.confirm({
                    type: "confirm",
                    content: /* @__PURE__ */ React.createElement(Translation, null, "Unrecoverable after deletion, are you sure to delete it?"),
                    onOk: () => {
                      this.onDelete(record);
                    },
                    locale: locale().Dialog
                  });
                }
              },
              /* @__PURE__ */ React.createElement(AiFillDelete, null),
              /* @__PURE__ */ React.createElement(Translation, null, "Remove")
            )));
          }
        }
      ];
    };
  }
  render() {
    const { Column } = Table;
    const columns = this.getColumns();
    const { list } = this.props;
    return /* @__PURE__ */ React.createElement("div", { className: "table-delivery-list margin-top-20" }, /* @__PURE__ */ React.createElement(
      Table,
      {
        locale: locale().Table,
        className: "customTable",
        size: "medium",
        style: { minWidth: "1200px" },
        dataSource: list,
        loading: false
      },
      columns.map((col) => /* @__PURE__ */ React.createElement(Column, { ...col, key: col.key, align: "left" }))
    ));
  }
}
export default TableList;
