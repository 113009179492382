"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Grid, Form, Input, Field, Button, Message, Card, Loading, Select, Dialog } from "@alifd/next";
import { connect } from "dva";
import React from "react";
import { BiCodeBlock, BiLaptop } from "react-icons/bi";
import { createConfig, detailConfig, detailTemplate, listTemplates, updateConfig } from "../../../../api/config";
import DrawerWithFooter from "../../../../components/Drawer";
import { If } from "../../../../components/If";
import Permission from "../../../../components/Permission";
import { Translation } from "../../../../components/Translation";
import UISchema from "../../../../components/UISchema";
import i18n from "../../../../i18n";
import { checkName } from "../../../../utils/common";
import { locale } from "../../../../utils/locale";
let CreateConfigDialog = class extends React.Component {
  constructor(props) {
    super(props);
    this.loadProjectTemplates = () => {
      const { project } = this.props;
      if (project) {
        listTemplates(project).then((res) => {
          if (res && Array.isArray(res.templates)) {
            this.setState({ templates: res.templates });
          } else {
            this.setState({ templates: [] });
          }
        });
      }
    };
    this.onDetailConfig = (callback) => {
      const { configName, project } = this.props;
      if (configName) {
        detailConfig(configName, project).then((res) => {
          if (res) {
            this.field.setValues({
              name: res.name,
              alias: res.alias,
              description: res.description,
              properties: res.properties
            });
            if (callback) {
              callback();
            }
          }
        });
      }
    };
    this.onDetailTemplate = (template) => {
      this.setState({ templateLoading: true });
      const { project } = this.props;
      detailTemplate(template, project).then((re) => {
        if (re) {
          this.setState({ templateDetail: re });
        }
      }).finally(() => {
        this.setState({ templateLoading: false });
      });
    };
    this.onClose = () => {
      this.props.onClose();
    };
    this.onCreate = () => {
      this.field.validate((error, values) => {
        if (error) {
          return;
        }
        const { template } = this.props;
        let templateName = template?.name;
        let namespace = template?.namespace;
        if (values.template) {
          if (values.template.includes("/")) {
            namespace = values.template.split("/")[0];
            templateName = values.template.split("/")[1];
          } else {
            templateName = values.template;
            namespace = "";
          }
        }
        if (!templateName) {
          return;
        }
        const { name, alias, description, properties } = values;
        const params = {
          alias,
          name,
          description,
          template: {
            name: templateName,
            namespace
          },
          properties: JSON.stringify(properties)
        };
        this.setState({ createLoading: true });
        createConfig(params, this.props.project).then((res) => {
          if (res) {
            Message.success(/* @__PURE__ */ React.createElement(Translation, null, "Config created successfully"));
            if (templateName && ["image-registry", "helm-repository", "tls-certificate"].includes(templateName)) {
              Dialog.confirm({
                content: i18n.t(
                  "This config needs to be distributed, you should go to the project summary page to do it before you want to use it."
                ).toString(),
                locale: locale().Dialog,
                onOk: () => {
                  this.props.onSuccess();
                },
                onCancel: () => {
                  this.props.onSuccess();
                }
              });
            } else {
              this.props.onSuccess();
            }
          }
        }).finally(() => {
          this.setState({ createLoading: false });
        });
      });
    };
    this.onUpdate = () => {
      this.field.validate((error, values) => {
        if (error) {
          return;
        }
        const { name, alias, description, properties } = values;
        const params = {
          alias,
          description,
          properties: JSON.stringify(properties)
        };
        this.setState({ createLoading: true });
        updateConfig(name, params, this.props.project).then((res) => {
          if (res) {
            Message.success(/* @__PURE__ */ React.createElement(Translation, null, "Config updated successfully"));
            this.props.onSuccess();
          }
        }).finally(() => {
          this.setState({ createLoading: false });
        });
      });
    };
    this.field = new Field(this);
    this.state = {
      templateLoading: false,
      createLoading: false,
      propertiesMode: "native"
    };
    this.field = new Field(this);
    this.uiSchemaRef = React.createRef();
  }
  componentDidMount() {
    const { configName, template } = this.props;
    if (configName) {
      this.onDetailConfig(() => {
        if (template) {
          this.onDetailTemplate(template);
        }
      });
    } else {
      if (template) {
        this.onDetailTemplate(template);
      }
    }
    this.loadProjectTemplates();
  }
  render() {
    const { Row, Col } = Grid;
    const FormItem = Form.Item;
    const init = this.field.init;
    const formItemLayout = {
      labelCol: {
        fixedSpan: 6
      },
      wrapperCol: {
        span: 20
      }
    };
    const { configName, project, template } = this.props;
    const { createLoading, templateDetail, templateLoading, propertiesMode, templates } = this.state;
    const edit = configName != "" && configName != void 0;
    const buttons = [
      /* @__PURE__ */ React.createElement(Button, { type: "secondary", onClick: this.onClose, style: { marginRight: "16px" } }, /* @__PURE__ */ React.createElement(Translation, null, "Cancel"))
    ];
    if (!edit) {
      buttons.push(
        /* @__PURE__ */ React.createElement(
          Permission,
          {
            request: {
              resource: project ? `project:${project}/config:${configName}` : `config:${configName}`,
              action: "create"
            },
            project
          },
          /* @__PURE__ */ React.createElement(Button, { type: "primary", onClick: this.onCreate, loading: createLoading }, /* @__PURE__ */ React.createElement(Translation, null, "Create"))
        )
      );
    } else {
      buttons.push(
        /* @__PURE__ */ React.createElement(
          Permission,
          {
            request: {
              resource: project ? `project:${project}/config:${configName}` : `config:${configName}`,
              action: "update"
            },
            project
          },
          /* @__PURE__ */ React.createElement(Button, { type: "primary", onClick: this.onUpdate, loading: createLoading }, /* @__PURE__ */ React.createElement(Translation, null, "Update"))
        )
      );
    }
    const validator = (rule, value, callback) => {
      this.uiSchemaRef.current?.validate(callback);
    };
    const templateOptions = templates?.map((tem) => {
      return {
        label: tem.namespace + "/" + (tem.alias || tem.name),
        value: tem.namespace + "/" + tem.name
      };
    });
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
      DrawerWithFooter,
      {
        title: edit ? i18n.t("Edit a config") : i18n.t("New a config"),
        placement: "right",
        width: 800,
        onClose: this.onClose,
        extButtons: buttons
      },
      /* @__PURE__ */ React.createElement(Form, { ...formItemLayout, field: this.field }, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Name"), labelTextAlign: "left", required: true, disabled: edit }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "name",
          placeholder: i18n.t("Please enter").toString(),
          maxLength: 32,
          ...init("name", {
            rules: [
              {
                required: true,
                pattern: checkName,
                message: /* @__PURE__ */ React.createElement(Translation, null, "Please enter a valid name")
              }
            ]
          })
        }
      ))), /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Alias") }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "alias",
          placeholder: i18n.t("Please enter").toString(),
          ...init("alias", {
            rules: [
              {
                minLength: 2,
                maxLength: 64,
                message: "Enter a string of 2 to 64 characters."
              }
            ]
          })
        }
      )))), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Description") }, /* @__PURE__ */ React.createElement(
        Input,
        {
          locale: locale().Input,
          name: "description",
          placeholder: i18n.t("Please enter").toString(),
          ...init("description")
        }
      )))), project && !template && /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { required: true, label: /* @__PURE__ */ React.createElement(Translation, null, "Template") }, /* @__PURE__ */ React.createElement(
        Select,
        {
          dataSource: templateOptions,
          locale: locale().Select,
          name: "template",
          placeholder: i18n.t("Please select a template").toString(),
          ...init("template", {
            rules: [
              {
                required: true,
                message: /* @__PURE__ */ React.createElement(Translation, null, "Please select a template")
              }
            ]
          }),
          onChange: (value) => {
            let namespace;
            let name = value;
            if (value.includes("/")) {
              namespace = value.split("/")[0];
              name = value.split("/")[1];
            }
            this.field.remove("properties");
            this.setState({ templateDetail: void 0 });
            this.onDetailTemplate({ name, namespace });
            this.field.setValue("template", value);
          }
        }
      )))), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(Loading, { visible: templateLoading, style: { width: "100%" } }, /* @__PURE__ */ React.createElement(
        Card,
        {
          contentHeight: "auto",
          style: { marginTop: "8px" },
          title: i18n.t("Properties").toString(),
          className: "withActions",
          subTitle: /* @__PURE__ */ React.createElement(
            Button,
            {
              style: { alignItems: "center", display: "flex" },
              onClick: () => {
                if (propertiesMode === "native") {
                  this.setState({ propertiesMode: "code" });
                } else {
                  this.setState({ propertiesMode: "native" });
                }
              }
            },
            propertiesMode === "native" && /* @__PURE__ */ React.createElement(BiCodeBlock, { size: 14, title: i18n.t("Switch to the coding mode") }),
            propertiesMode === "code" && /* @__PURE__ */ React.createElement(BiLaptop, { size: 14, title: i18n.t("Switch to the native mode") })
          )
        },
        /* @__PURE__ */ React.createElement(FormItem, { required: true }, /* @__PURE__ */ React.createElement(If, { condition: templateDetail && templateDetail.uiSchema }, /* @__PURE__ */ React.createElement(
          UISchema,
          {
            ...init(`properties`, {
              rules: [
                {
                  validator,
                  message: i18n.t("Please check the config properties")
                }
              ]
            }),
            enableCodeEdit: propertiesMode === "code",
            uiSchema: templateDetail && templateDetail.uiSchema,
            ref: this.uiSchemaRef,
            mode: edit ? "edit" : "new"
          }
        )))
      )), /* @__PURE__ */ React.createElement(If, { condition: !templateDetail }, /* @__PURE__ */ React.createElement(Message, { type: "notice" }, /* @__PURE__ */ React.createElement(Translation, null, "Can not load the template detail"))))))
    ));
  }
};
CreateConfigDialog = __decorateClass([
  connect()
], CreateConfigDialog);
export default CreateConfigDialog;
