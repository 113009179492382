"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Loading, Select } from "@alifd/next";
import { connect } from "dva";
import React, { Component } from "react";
import { getImageRepos } from "../../api/repository";
import i18n from "../../i18n";
import { locale } from "../../utils/locale";
let ImageSecretSelect = class extends Component {
  constructor(props) {
    super(props);
    this.onLoadRepos = () => {
      const { project } = this.props;
      if (project) {
        this.setState({ loading: true });
        getImageRepos({ project }).then((res) => {
          if (res) {
            this.setState({
              registries: res.registries
            });
          }
        }).finally(() => {
          this.setState({ loading: false });
        });
      }
    };
    this.onSearch = (value) => {
      this.setState({ inputRepo: value });
    };
    this.state = {
      loading: false,
      registries: [],
      inputRepo: ""
    };
  }
  componentDidMount() {
    this.onLoadRepos();
  }
  convertImageRegistryOptions(data) {
    return (data || []).map((item) => {
      let label = item.secretName;
      if (item.domain) {
        label = `${item.secretName}(${item.domain})`;
      }
      return { label, value: item.secretName };
    });
  }
  render() {
    const { disabled, onChange, value } = this.props;
    const { registries, loading, inputRepo } = this.state;
    const dataSource = registries || [];
    if (inputRepo) {
      dataSource.unshift({ secretName: inputRepo, name: inputRepo });
    }
    const transDataSource = this.convertImageRegistryOptions(dataSource);
    return /* @__PURE__ */ React.createElement(Loading, { visible: loading, style: { width: "100%" } }, /* @__PURE__ */ React.createElement(
      Select,
      {
        placeholder: i18n.t("Please select or input your owner image registry secret"),
        inputMode: "url",
        mode: "multiple",
        onChange,
        disabled,
        showSearch: true,
        onSearch: this.onSearch,
        followTrigger: true,
        value,
        dataSource: transDataSource,
        locale: locale().Select
      }
    ));
  }
};
ImageSecretSelect = __decorateClass([
  connect((store) => {
    return { ...store.uischema };
  })
], ImageSecretSelect);
export default ImageSecretSelect;
