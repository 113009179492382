"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Loading, Grid } from "@alifd/next";
import { connect } from "dva";
import React, { Component } from "react";
import EnvTabs from "./components/EnvTabs";
import Header from "./components/Header";
import Menus from "./components/Menus";
import "./index.less";
const { Row } = Grid;
let ApplicationLayout = class extends Component {
  constructor(props) {
    super(props);
    this.onGetApplicationDetails = async () => {
      const {
        params: { appName }
      } = this.props.match;
      this.setState({ activeName: appName, loading: true });
      this.props.dispatch({
        type: "application/getApplicationDetail",
        payload: { appName },
        callback: () => {
          this.setState({ loading: false }, () => {
            this.loadApplicationComponents();
            this.loadApplicationEnvbinding();
            this.loadApplicationWorkflows();
            this.loadApplicationPolicies();
            this.loadApplicationStatus();
          });
        }
      });
    };
    this.getNamespaceList = async () => {
      this.props.dispatch({
        type: "application/getNamespaceList",
        payload: {}
      });
    };
    this.loadApplicationEnvbinding = async () => {
      const {
        params: { appName }
      } = this.props.match;
      if (appName) {
        this.props.dispatch({
          type: "application/getApplicationEnvbinding",
          payload: { appName }
        });
      }
    };
    this.loadApplicationComponents = async () => {
      const {
        params: { appName }
      } = this.props.match;
      this.props.dispatch({
        type: "application/getApplicationComponents",
        payload: { appName }
      });
    };
    this.loadApplicationPolicies = async () => {
      const {
        params: { appName }
      } = this.props.match;
      this.props.dispatch({
        type: "application/getApplicationPolicies",
        payload: { appName }
      });
    };
    this.loadApplicationWorkflows = async () => {
      const {
        params: { appName }
      } = this.props.match;
      this.props.dispatch({
        type: "application/getApplicationWorkflows",
        payload: { appName }
      });
    };
    this.loadApplicationStatus = async () => {
      const {
        params: { appName }
      } = this.props.match;
      this.props.dispatch({
        type: "application/getApplicationAllStatus",
        payload: { appName }
      });
    };
    this.state = {
      loading: false,
      activeName: ""
    };
  }
  componentDidMount() {
    this.onGetApplicationDetails();
    this.getNamespaceList();
  }
  shouldComponentUpdate(nextProps) {
    return nextProps.location.pathname !== this.props.location.pathname;
  }
  render() {
    const { activeName } = this.state;
    const { children, dispatch, applicationDetail } = this.props;
    const {
      url,
      params: { appName, envName }
    } = this.props.match;
    const loadingDom = /* @__PURE__ */ React.createElement(Loading, { style: { width: "100%", minHeight: "200px" } });
    if (activeName !== "" && appName != activeName) {
      this.onGetApplicationDetails();
      return loadingDom;
    }
    if (!applicationDetail) {
      return loadingDom;
    }
    return /* @__PURE__ */ React.createElement("div", { className: "app-layout" }, /* @__PURE__ */ React.createElement(Header, { dispatch, appName, envName, currentPath: url }), /* @__PURE__ */ React.createElement(EnvTabs, { dispatch, appName, activeKey: envName ? envName : "basisConfig" }), /* @__PURE__ */ React.createElement(Row, { className: "padding16 main" }, /* @__PURE__ */ React.createElement("div", { className: "menu" }, /* @__PURE__ */ React.createElement(Menus, { currentPath: url, appName, envName })), /* @__PURE__ */ React.createElement("div", { className: "content" }, children)));
  }
};
ApplicationLayout = __decorateClass([
  connect((store) => {
    return { ...store.application };
  })
], ApplicationLayout);
export default ApplicationLayout;
