"use strict";
import { getDomain } from "../utils/common";
import { envURL } from "./productionLink";
import { post, get, rdelete, put } from "./request";
const baseURLOject = getDomain();
const base = baseURLOject.MOCK || baseURLOject.APIBASE;
export function getEnvs(params) {
  const url = base + envURL;
  return get(url, { params }).then((res) => res);
}
export function createEnv(params) {
  const url = base + envURL + "?project=" + params.project;
  return post(url, params);
}
export function deleteEnv(params) {
  const url = base + `${envURL}/${params.name}`;
  return rdelete(url, params);
}
export function updateEnv(params) {
  const url = base + `${envURL}/${params.name}`;
  return put(url, params);
}
