"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Table, Card, Loading, Balloon, Button, Message, Dialog, Tag, Tab } from "@alifd/next";
import { connect } from "dva";
import { Link, routerRedux } from "dva/router";
import React from "react";
import { deployApplication } from "../../api/application";
import { listApplicationResourceTree, listApplicationServiceAppliedResources } from "../../api/observation";
import { If } from "../../components/If";
import { Translation } from "../../components/Translation";
import i18n from "../../i18n";
import { handleError } from "../../utils/errors";
import { locale } from "../../utils/locale";
import { checkPermission } from "../../utils/permission";
import Header from "../ApplicationInstanceList/components/Header";
import "./index.less";
import ApplicationGraph from "./components/ApplicationGraph";
import { AiOutlineQuestionCircle } from "react-icons/ai";
let ApplicationStatusPage = class extends React.Component {
  constructor(props) {
    super(props);
    this.loadApplicationStatus = async () => {
      const {
        params: { appName, envName }
      } = this.props.match;
      if (envName) {
        this.props.dispatch({
          type: "application/getApplicationStatus",
          payload: { appName, envName },
          callback: (res) => {
            if (res.status) {
              this.loadApplicationAppliedResources();
            }
          }
        });
      }
    };
    this.getTargets = () => {
      const { envbinding, match } = this.props;
      const env = envbinding.filter((item) => item.name == match.params.envName);
      if (env.length > 0) {
        return env[0].targets;
      }
      return [];
    };
    this.getEnvbindingByName = () => {
      const { envbinding } = this.props;
      const {
        params: { envName }
      } = this.props.match;
      return envbinding.find((env) => env.name === envName);
    };
    this.loadApplicationAppliedResources = async () => {
      const { mode } = this.state;
      if (mode === "resource-graph") {
        await this.loadResourceTree();
        return;
      }
      const { applicationDetail } = this.props;
      const {
        params: { appName }
      } = this.props.match;
      const { target, componentName } = this.state;
      const env = this.getEnvbindingByName();
      if (applicationDetail && applicationDetail.name && env) {
        const param = {
          appName: env.appDeployName || appName,
          appNs: env.appDeployNamespace,
          componentName,
          cluster: "",
          clusterNs: ""
        };
        if (target) {
          param.cluster = target.cluster?.clusterName || "";
          param.clusterNs = target.cluster?.namespace || "";
        }
        this.setState({ resourceLoading: true });
        listApplicationServiceAppliedResources(param).then((re) => {
          if (re && re.resources) {
            this.setState({ resources: re.resources });
          } else {
            this.setState({ resources: [] });
          }
        }).finally(() => {
          this.setState({ resourceLoading: false });
        });
      }
    };
    this.loadResourceTree = async () => {
      const { applicationDetail } = this.props;
      const env = this.getEnvbindingByName();
      const { target, componentName, resourceLoading } = this.state;
      const {
        params: { appName }
      } = this.props.match;
      if (applicationDetail && applicationDetail.name && env && !resourceLoading) {
        const param = {
          appName: env.appDeployName || appName,
          appNs: env.appDeployNamespace,
          componentName,
          cluster: "",
          clusterNs: ""
        };
        if (target) {
          param.cluster = target.cluster?.clusterName || "";
          param.clusterNs = target.cluster?.namespace || "";
        }
        this.setState({ resourceLoading: true });
        listApplicationResourceTree(param).then((re) => {
          if (re && re.resources) {
            this.setState({ resources: re.resources });
          } else {
            this.setState({ resources: [] });
          }
        }).finally(() => {
          this.setState({ resourceLoading: false });
        });
      }
    };
    this.updateQuery = (params) => {
      const targets = this.getTargets()?.filter((item) => item.name == params.target);
      let target = void 0;
      if (targets && targets.length > 0) {
        target = targets[0];
      }
      this.setState({ target, componentName: params.component }, () => {
        this.loadApplicationAppliedResources();
      });
    };
    this.onDeploy = (force) => {
      const { envbinding, dispatch } = this.props;
      const {
        params: { appName, envName }
      } = this.props.match;
      const envs = envbinding.filter((item) => item.name == envName);
      if (envs) {
        this.setState({ deployLoading: true });
        deployApplication(
          {
            appName,
            workflowName: "workflow-" + envs[0].name,
            triggerType: "web",
            force: force || false
          },
          true
        ).then((re) => {
          if (re) {
            Message.success(i18n.t("Application deployed successfully"));
            this.setState({ deployLoading: false });
            this.loadApplicationStatus();
            if (re.record && re.record.name && dispatch) {
              dispatch(
                routerRedux.push(`/applications/${appName}/envbinding/${re.envName}/workflow/records/${re.record.name}`)
              );
            }
          }
        }).catch((err) => {
          if (err.BusinessCode === 10004) {
            Dialog.confirm({
              content: i18n.t("Workflow is executing. Do you want to force a restart?").toString(),
              onOk: () => {
                this.onDeploy(true);
              },
              onCancel: () => {
                this.setState({ deployLoading: false });
              },
              locale: locale().Dialog
            });
          } else {
            handleError(err);
          }
        });
      } else {
        Message.warning(i18n.t("Please wait"));
      }
    };
    this.onChangeMode = (mode) => {
      this.setState({ mode }, () => {
        if (mode === "overview" || mode === "resource-graph") {
          this.loadApplicationAppliedResources();
        }
      });
    };
    this.state = {
      loading: true,
      deployLoading: false,
      resourceLoading: false,
      endpointLoading: false,
      envName: "",
      mode: "resource-graph",
      resources: []
    };
  }
  componentDidMount() {
    this.loadApplicationStatus();
  }
  componentWillReceiveProps(nextProps) {
    const { params } = nextProps.match;
    if (params.envName !== this.state.envName) {
      this.setState({ envName: params.envName }, () => {
        this.loadApplicationStatus();
      });
      return;
    }
    if (this.props.envbinding.length != nextProps.envbinding.length) {
      this.loadApplicationStatus();
    }
  }
  render() {
    const { applicationStatus, applicationDetail, components, userInfo } = this.props;
    const {
      params: { appName, envName }
    } = this.props.match;
    const { loading, endpointLoading, resourceLoading, resources, componentName, deployLoading, mode } = this.state;
    let componentStatus = applicationStatus?.services;
    if (componentName) {
      componentStatus = componentStatus?.filter((item) => item.name == componentName);
    }
    const env = this.getEnvbindingByName();
    const { Group: TagGroup } = Tag;
    const notDeploy = /* @__PURE__ */ React.createElement("div", { className: "deployNotice" }, /* @__PURE__ */ React.createElement("div", { className: "noticeBox" }, /* @__PURE__ */ React.createElement("h2", null, /* @__PURE__ */ React.createElement(Translation, null, "Not Deploy")), /* @__PURE__ */ React.createElement("div", { className: "desc" }, /* @__PURE__ */ React.createElement(Translation, null, "The current environment has not been deployed.")), /* @__PURE__ */ React.createElement("div", { className: "noticeAction" }, /* @__PURE__ */ React.createElement(
      Button,
      {
        loading: deployLoading,
        disabled: applicationDetail?.readOnly,
        onClick: () => this.onDeploy(),
        type: "primary"
      },
      /* @__PURE__ */ React.createElement(Translation, null, "Deploy")
    ))));
    return /* @__PURE__ */ React.createElement("div", { className: "application-status-wrapper" }, /* @__PURE__ */ React.createElement(Loading, { visible: loading && endpointLoading, style: { width: "100%" } }, /* @__PURE__ */ React.createElement(
      Header,
      {
        userInfo,
        envbinding: this.getEnvbindingByName(),
        targets: this.getTargets(),
        envName,
        appName,
        disableStatusShow: true,
        applicationDetail,
        applicationStatus,
        components,
        updateQuery: (params) => {
          this.updateQuery(params);
        },
        refresh: () => {
          this.loadApplicationStatus();
        },
        dispatch: this.props.dispatch
      }
    )), /* @__PURE__ */ React.createElement(Tab, { onChange: this.onChangeMode, defaultActiveKey: mode, shape: "capsule" }, /* @__PURE__ */ React.createElement(Tab.Item, { title: i18n.t("Overview").toString(), key: "overview" }, /* @__PURE__ */ React.createElement(Loading, { visible: loading && resourceLoading, style: { width: "100%" } }, /* @__PURE__ */ React.createElement(If, { condition: applicationStatus }, /* @__PURE__ */ React.createElement(If, { condition: componentStatus }, /* @__PURE__ */ React.createElement(
      Card,
      {
        locale: locale().Card,
        style: { marginTop: "8px", marginBottom: "16px" },
        contentHeight: "auto",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Component Status")
      },
      /* @__PURE__ */ React.createElement("div", { style: { overflow: "auto" } }, /* @__PURE__ */ React.createElement(
        Table,
        {
          locale: locale().Table,
          className: "customTable",
          dataSource: componentStatus,
          style: { minWidth: "1000px" }
        },
        /* @__PURE__ */ React.createElement(
          Table.Column,
          {
            align: "left",
            dataIndex: "name",
            style: { width: "17%" },
            title: /* @__PURE__ */ React.createElement(Translation, null, "Name")
          }
        ),
        /* @__PURE__ */ React.createElement(
          Table.Column,
          {
            dataIndex: "cluster",
            title: /* @__PURE__ */ React.createElement(Translation, null, "Cluster"),
            width: "200px",
            cell: (v) => {
              let clusterName = v;
              if (!clusterName) {
                clusterName = "Local";
              }
              if (checkPermission(
                { resource: "cluster:*", action: "list" },
                applicationDetail?.project?.name,
                userInfo
              )) {
                return /* @__PURE__ */ React.createElement(Link, { to: "/clusters" }, clusterName);
              }
              return /* @__PURE__ */ React.createElement("span", null, clusterName);
            }
          }
        ),
        /* @__PURE__ */ React.createElement(
          Table.Column,
          {
            align: "left",
            dataIndex: "healthy",
            width: "100px",
            cell: (v) => {
              if (v) {
                return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("span", { className: "circle circle-success" }), /* @__PURE__ */ React.createElement("span", null, "Healthy"));
              }
              return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("span", { className: "circle circle-warning" }), /* @__PURE__ */ React.createElement("span", null, "UnHealthy"));
            },
            title: /* @__PURE__ */ React.createElement(Translation, null, "Healthy")
          }
        ),
        /* @__PURE__ */ React.createElement(
          Table.Column,
          {
            align: "left",
            dataIndex: "trait",
            cell: (v, i, record) => {
              const { traits } = record;
              const Tags = (traits || []).map((item) => {
                if (item.healthy) {
                  return /* @__PURE__ */ React.createElement(Tag, { type: "normal", size: "small" }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("span", { className: "circle circle-success" }), /* @__PURE__ */ React.createElement("span", null, item.type)));
                } else {
                  return /* @__PURE__ */ React.createElement(Tag, { type: "normal", size: "small" }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("span", { className: "circle circle-failure" }), /* @__PURE__ */ React.createElement("span", null, item.type)));
                }
              });
              return /* @__PURE__ */ React.createElement(TagGroup, { className: "tags-content" }, Tags);
            },
            title: /* @__PURE__ */ React.createElement(Translation, null, "Traits")
          }
        ),
        /* @__PURE__ */ React.createElement(
          Table.Column,
          {
            align: "center",
            dataIndex: "message",
            title: /* @__PURE__ */ React.createElement(Translation, null, "Message"),
            cell: (v, i, record) => {
              const { message = "", traits } = record;
              const TraitMessages = (traits || []).map((item) => {
                if (item.message) {
                  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("span", null, item.type, ": "), /* @__PURE__ */ React.createElement("span", null, item.message));
                }
                return;
              });
              return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", null, message), TraitMessages);
            }
          }
        )
      ))
    )), /* @__PURE__ */ React.createElement(Card, { locale: locale().Card, contentHeight: "200px", title: /* @__PURE__ */ React.createElement(Translation, null, "Applied Resources") }, /* @__PURE__ */ React.createElement("div", { style: { overflow: "auto" } }, /* @__PURE__ */ React.createElement(Table, { style: { minWidth: "1000px" }, locale: locale().Table, dataSource: resources }, /* @__PURE__ */ React.createElement(
      Table.Column,
      {
        dataIndex: "name",
        width: "240px",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Namespace/Name"),
        cell: (v, i, row) => {
          return `${row.namespace || "-"}/${row.name}`;
        }
      }
    ), /* @__PURE__ */ React.createElement(
      Table.Column,
      {
        dataIndex: "cluster",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Cluster"),
        width: "200px",
        cell: (v) => {
          let clusterName = v;
          if (!clusterName) {
            clusterName = "Local";
          }
          if (checkPermission(
            { resource: "cluster:*", action: "list" },
            applicationDetail?.project?.name,
            userInfo
          )) {
            return /* @__PURE__ */ React.createElement(Link, { to: "/clusters" }, clusterName);
          }
          return /* @__PURE__ */ React.createElement("span", null, clusterName);
        }
      }
    ), /* @__PURE__ */ React.createElement(Table.Column, { width: "200px", dataIndex: "kind", title: /* @__PURE__ */ React.createElement(Translation, null, "Kind") }), /* @__PURE__ */ React.createElement(Table.Column, { dataIndex: "apiVersion", title: /* @__PURE__ */ React.createElement(Translation, null, "APIVersion") }), /* @__PURE__ */ React.createElement(Table.Column, { dataIndex: "component", title: /* @__PURE__ */ React.createElement(Translation, null, "Component") }), /* @__PURE__ */ React.createElement(
      Table.Column,
      {
        dataIndex: "deployVersion",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Revision"),
        cell: (v, i, row) => {
          if (row.latest) {
            return /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(
              "span",
              {
                style: {
                  background: "var(--success-color)",
                  padding: "4px",
                  fontSize: "12px",
                  color: "#fff",
                  marginRight: "4px"
                }
              },
              "NEW"
            ), /* @__PURE__ */ React.createElement(Link, { to: `/applications/${applicationDetail?.name}/revisions` }, v));
          }
          return /* @__PURE__ */ React.createElement(Link, { to: `/applications/${applicationDetail?.name}/revisions` }, v);
        }
      }
    )))), /* @__PURE__ */ React.createElement(If, { condition: applicationStatus?.conditions }, /* @__PURE__ */ React.createElement(
      Card,
      {
        locale: locale().Card,
        style: { marginTop: "8px" },
        contentHeight: "auto",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Conditions")
      },
      /* @__PURE__ */ React.createElement("div", { style: { overflow: "auto" } }, /* @__PURE__ */ React.createElement(
        Table,
        {
          style: { minWidth: "1000px" },
          locale: locale().Table,
          dataSource: applicationStatus?.conditions
        },
        /* @__PURE__ */ React.createElement(Table.Column, { width: "150px", dataIndex: "type", title: /* @__PURE__ */ React.createElement(Translation, null, "Type") }),
        /* @__PURE__ */ React.createElement(Table.Column, { dataIndex: "status", title: /* @__PURE__ */ React.createElement(Translation, null, "Status") }),
        /* @__PURE__ */ React.createElement(
          Table.Column,
          {
            dataIndex: "lastTransitionTime",
            title: /* @__PURE__ */ React.createElement(Translation, null, "LastTransitionTime")
          }
        ),
        /* @__PURE__ */ React.createElement(
          Table.Column,
          {
            dataIndex: "reason",
            title: /* @__PURE__ */ React.createElement(Translation, null, "Reason"),
            cell: (v, index, row) => {
              if (row.message) {
                return /* @__PURE__ */ React.createElement(
                  Balloon,
                  {
                    trigger: /* @__PURE__ */ React.createElement("span", { style: { color: "red", cursor: "pointer" } }, v, " ", /* @__PURE__ */ React.createElement(AiOutlineQuestionCircle, { size: 14 }))
                  },
                  row.message
                );
              }
              return /* @__PURE__ */ React.createElement("span", null, v);
            }
          }
        )
      ))
    ))), /* @__PURE__ */ React.createElement(If, { condition: !applicationStatus }, notDeploy))), /* @__PURE__ */ React.createElement(Tab.Item, { title: i18n.t("Resource Graph").toString(), key: "resource-graph" }, /* @__PURE__ */ React.createElement(Loading, { visible: loading && resourceLoading, style: { width: "100%" } }, /* @__PURE__ */ React.createElement(If, { condition: applicationStatus }, /* @__PURE__ */ React.createElement(
      ApplicationGraph,
      {
        applicationStatus,
        application: applicationDetail,
        env,
        resources,
        graphType: "resource-graph"
      }
    ))), /* @__PURE__ */ React.createElement(If, { condition: !applicationStatus }, notDeploy)), /* @__PURE__ */ React.createElement(Tab.Item, { title: i18n.t("Application Graph").toString(), key: "application-graph" }, /* @__PURE__ */ React.createElement(Loading, { visible: loading && resourceLoading, style: { width: "100%" } }, /* @__PURE__ */ React.createElement(If, { condition: applicationStatus }, /* @__PURE__ */ React.createElement(
      ApplicationGraph,
      {
        applicationStatus,
        application: applicationDetail,
        env,
        resources,
        components,
        graphType: "application-graph"
      }
    ))), /* @__PURE__ */ React.createElement(If, { condition: !applicationStatus }, notDeploy))));
  }
};
ApplicationStatusPage = __decorateClass([
  connect((store) => {
    return { ...store.application, ...store.user };
  })
], ApplicationStatusPage);
export default ApplicationStatusPage;
