"use strict";
import { Dialog, Table, Card, Loading, Button, Balloon } from "@alifd/next";
import { Link } from "dva/router";
import React from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { Translation } from "../../components/Translation";
import { locale } from "../../utils/locale";
import { If } from "../If";
class StatusShow extends React.Component {
  render() {
    const { applicationStatus, onClose, loading, title } = this.props;
    return /* @__PURE__ */ React.createElement(
      Dialog,
      {
        locale: locale().Dialog,
        visible: true,
        title,
        autoFocus: true,
        overflowScroll: true,
        style: { width: "800px" },
        onClose,
        footer: /* @__PURE__ */ React.createElement("div", { className: "next-dialog-footer" }, /* @__PURE__ */ React.createElement(Button, { onClick: onClose }, /* @__PURE__ */ React.createElement(Translation, null, "Close")), /* @__PURE__ */ React.createElement(Button, { type: "primary", onClick: this.props.loadStatusDetail }, /* @__PURE__ */ React.createElement(Translation, null, "Refresh"))),
        v2: true
      },
      /* @__PURE__ */ React.createElement(Loading, { visible: loading, style: { width: "100%" } }, /* @__PURE__ */ React.createElement(Card, { locale: locale().Card, contentHeight: "200px", title: /* @__PURE__ */ React.createElement(Translation, null, "Applied Resources") }, /* @__PURE__ */ React.createElement(Table, { locale: locale().Table, dataSource: applicationStatus?.appliedResources }, /* @__PURE__ */ React.createElement(
        Table.Column,
        {
          dataIndex: "name",
          width: "150px",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Namespace/Name"),
          cell: (v, i, row) => {
            return `${row.namespace || "-"}/${row.name}`;
          }
        }
      ), /* @__PURE__ */ React.createElement(Table.Column, { width: "150px", dataIndex: "kind", title: /* @__PURE__ */ React.createElement(Translation, null, "Kind") }), /* @__PURE__ */ React.createElement(Table.Column, { dataIndex: "apiVersion", title: /* @__PURE__ */ React.createElement(Translation, null, "APIVersion") }), /* @__PURE__ */ React.createElement(
        Table.Column,
        {
          dataIndex: "cluster",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Cluster"),
          width: "120px",
          cell: (v) => {
            if (!v) {
              return /* @__PURE__ */ React.createElement(Link, { to: "/clusters" }, "Local");
            }
            return /* @__PURE__ */ React.createElement(Link, { to: "/clusters" }, v);
          }
        }
      ))), /* @__PURE__ */ React.createElement(If, { condition: applicationStatus?.conditions }, /* @__PURE__ */ React.createElement(
        Card,
        {
          locale: locale().Card,
          style: { marginTop: "8px" },
          contentHeight: "auto",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Conditions")
        },
        /* @__PURE__ */ React.createElement(Table, { locale: locale().Table, dataSource: applicationStatus?.conditions }, /* @__PURE__ */ React.createElement(Table.Column, { width: "150px", dataIndex: "type", title: /* @__PURE__ */ React.createElement(Translation, null, "Type") }), /* @__PURE__ */ React.createElement(Table.Column, { dataIndex: "status", title: /* @__PURE__ */ React.createElement(Translation, null, "Status") }), /* @__PURE__ */ React.createElement(Table.Column, { dataIndex: "lastTransitionTime", title: /* @__PURE__ */ React.createElement(Translation, null, "LastTransitionTime") }), /* @__PURE__ */ React.createElement(
          Table.Column,
          {
            dataIndex: "reason",
            title: /* @__PURE__ */ React.createElement(Translation, null, "Reason"),
            cell: (v, index, row) => {
              if (row.message) {
                return /* @__PURE__ */ React.createElement(
                  Balloon,
                  {
                    trigger: /* @__PURE__ */ React.createElement("span", { style: { color: "red", cursor: "pointer" } }, v, " ", /* @__PURE__ */ React.createElement(AiOutlineQuestionCircle, null))
                  },
                  row.message
                );
              }
              return /* @__PURE__ */ React.createElement("span", null, v);
            }
          }
        ))
      )), /* @__PURE__ */ React.createElement(If, { condition: applicationStatus?.services }, /* @__PURE__ */ React.createElement(
        Card,
        {
          locale: locale().Card,
          style: { marginTop: "8px", marginBottom: "16px" },
          contentHeight: "auto",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Component Status")
        },
        /* @__PURE__ */ React.createElement(Table, { locale: locale().Table, className: "customTable", dataSource: applicationStatus?.services }, /* @__PURE__ */ React.createElement(Table.Column, { align: "left", dataIndex: "name", width: "200px", title: /* @__PURE__ */ React.createElement(Translation, null, "Name") }), /* @__PURE__ */ React.createElement(
          Table.Column,
          {
            align: "left",
            dataIndex: "healthy",
            width: "100px",
            cell: (v) => {
              if (v) {
                return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("span", { className: "circle circle-success" }), /* @__PURE__ */ React.createElement("span", null, "Healthy"));
              }
              return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("span", { className: "circle circle-warning" }), /* @__PURE__ */ React.createElement("span", null, "UnHealthy"));
            },
            title: /* @__PURE__ */ React.createElement(Translation, null, "Healthy")
          }
        ), /* @__PURE__ */ React.createElement(Table.Column, { align: "center", dataIndex: "message", title: /* @__PURE__ */ React.createElement(Translation, null, "Message") }))
      )))
    );
  }
}
export default StatusShow;
