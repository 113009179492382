"use strict";
import { Input, Button, Field } from "@alifd/next";
import React from "react";
import "./index.less";
import { If } from "../../components/If";
import { Translation } from "../../components/Translation";
import { AiOutlineDelete } from "react-icons/ai";
function InputItem(props) {
  return /* @__PURE__ */ React.createElement("div", { className: "number-container" }, /* @__PURE__ */ React.createElement(
    Input,
    {
      htmlType: "number",
      onChange: props.onChange,
      className: "input",
      disabled: props.disabled,
      value: props.value
    }
  ), /* @__PURE__ */ React.createElement("div", { className: "remove-option-container" }, /* @__PURE__ */ React.createElement(If, { condition: !props.isFirst }, /* @__PURE__ */ React.createElement(
    AiOutlineDelete,
    {
      onClick: () => {
        props.delete(props.id);
      }
    }
  ))));
}
class Numbers extends React.Component {
  constructor(props) {
    super(props);
    this.componentDidMount = async () => {
    };
    this.changeValues = () => {
      const values = this.field.getValues();
      const inputList = this.state.inputList;
      Object.keys(values).map((key) => {
        if (Array.isArray(inputList)) {
          inputList.forEach((item) => {
            if (item.key === key) {
              item.value = values[key];
            }
          });
        }
      });
      const valuesInfo = inputList.map((item) => item.value);
      this.props.onChange(valuesInfo);
    };
    this.addInputItem = () => {
      const { inputList } = this.state;
      const key = Date.now().toString();
      inputList.push({
        key
      });
      this.setState({
        inputList
      });
    };
    this.removeInputItem = (key) => {
      const { inputList } = this.state;
      inputList.forEach((item, i) => {
        if (item.key === key) {
          inputList.splice(i, 1);
          this.field.remove(key);
        }
      });
      this.setState(
        {
          inputList
        },
        () => {
          this.changeValues();
        }
      );
    };
    const inputList = [];
    if (props.value) {
      props.value.map((v, index) => {
        const key = Date.now().toString() + index;
        inputList.push({
          key,
          value: v
        });
      });
    }
    this.state = {
      inputList
    };
    this.field = new Field(this, {
      onChange: () => {
        this.changeValues();
      }
    });
  }
  render() {
    const { inputList } = this.state;
    const { init } = this.field;
    const { label, disabled } = this.props;
    return /* @__PURE__ */ React.createElement("div", null, inputList.map((item) => /* @__PURE__ */ React.createElement(
      InputItem,
      {
        ...init(item.key),
        key: item.key,
        value: item.value,
        delete: (id) => this.removeInputItem(id),
        id: item.key,
        label
      }
    )), /* @__PURE__ */ React.createElement("div", { className: "add-btn-container" }, /* @__PURE__ */ React.createElement(Button, { disabled, size: "small", onClick: this.addInputItem, ghost: "light" }, /* @__PURE__ */ React.createElement(Translation, null, "Add"))));
  }
}
export default Numbers;
