"use strict";
import { Grid, Button, Form, Input, Field, Select, Switch } from "@alifd/next";
import _ from "lodash";
import React, { Component } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { If } from "../../components/If";
import i18n from "../../i18n";
import { locale } from "../../utils/locale";
const { Row, Col } = Grid;
class KV extends Component {
  constructor(props) {
    super(props);
    this.setValues = () => {
      const { value } = this.props;
      const { items } = this.state;
      const newItems = [...items];
      if (value) {
        for (const label in value) {
          const key = Date.now().toString() + label;
          newItems.push({
            key,
            label,
            value: value[label],
            valueType: this.getValueType(value[label])
          });
          this.form.setValue("envKey-" + key, label);
          this.form.setValue("envValue-" + key, value[label]);
        }
      }
      this.setState({ items: newItems });
    };
    this.onSearch = (value) => {
      this.setState({ inputValue: value });
    };
    this.getValueType = (value) => {
      const findValueType = this.matchOutSideValueType();
      const valueTypeAdditionalParam = ["number", "boolean"];
      if (valueTypeAdditionalParam.includes(findValueType)) {
        return findValueType;
      } else {
        if (value != void 0) {
          return typeof value;
        } else {
          return "string";
        }
      }
    };
    this.matchOutSideValueType = () => {
      const { additional, additionalParameter } = this.props;
      const outSideValueType = [
        { uiType: "Number", valueType: "number" },
        { uiType: "Switch", valueType: "boolean" }
      ];
      if (additional && additionalParameter && additionalParameter.uiType) {
        const matchValueTypeObj = _.find(outSideValueType, (item) => {
          return item.uiType === additionalParameter.uiType;
        });
        return matchValueTypeObj && matchValueTypeObj.valueType || "string";
      } else {
        return "string";
      }
    };
    this.state = {
      items: []
    };
    this.form = new Field(this, {
      onChange: (name, value) => {
        const { keyOptions } = this.props;
        if (keyOptions && name.indexOf("envKey-") > -1) {
          const itemKey = name.substring(name.indexOf("-") + 1);
          this.form.setValue("envValue-" + itemKey, keyOptions[value]);
          const { items } = this.state;
          const newItems = items.map((item) => {
            if (item.key == itemKey) {
              item.value = keyOptions[value];
              item.valueType = this.getValueType(keyOptions[value]);
            }
            return item;
          });
          this.setState({ items: newItems });
        }
        this.submit();
      }
    });
  }
  componentDidMount() {
    this.setValues();
  }
  addItem() {
    const { items } = this.state;
    items.push({
      key: Date.now().toString(),
      label: "",
      value: "",
      valueType: this.getValueType("")
    });
    this.setState({ items: [...items] });
  }
  submit() {
    const values = this.form.getValues();
    const items = /* @__PURE__ */ new Map();
    Object.keys(values).map((key) => {
      if (key.startsWith("envKey-")) {
        const index = key.replace("envKey-", "");
        let item = items.get(index);
        if (!item) {
          item = { key: "", label: "", valueType: "string" };
        }
        item.label = values[key];
        items.set(index, item);
      }
      if (key.startsWith("envValue-")) {
        const index = key.replace("envValue-", "");
        let item = items.get(index);
        if (!item) {
          item = { key: "", label: "", valueType: "string" };
        }
        item.value = values[key];
        items.set(index, item);
      }
    });
    const obj = /* @__PURE__ */ Object.create(null);
    items.forEach((item) => {
      obj[item.label] = item.value;
    });
    const { onChange } = this.props;
    if (onChange) {
      onChange(obj);
    }
  }
  remove(key) {
    const { items } = this.state;
    items.forEach((item, i) => {
      if (item.key === key) {
        items.splice(i, 1);
      }
    });
    this.form.remove("envKey-" + key);
    this.form.remove("envValue-" + key);
    this.setState({ items });
    this.submit();
  }
  render() {
    const { items, inputValue } = this.state;
    const { id, keyOptions } = this.props;
    const { init } = this.form;
    const dataSource = keyOptions ? Object.keys(keyOptions) : [];
    if (inputValue) {
      dataSource.push(inputValue);
    }
    return /* @__PURE__ */ React.createElement("div", { id }, items.map((item) => {
      return /* @__PURE__ */ React.createElement(Row, { key: item.key, gutter: "20" }, /* @__PURE__ */ React.createElement(Col, { span: 10 }, /* @__PURE__ */ React.createElement(Form.Item, null, /* @__PURE__ */ React.createElement(If, { condition: dataSource && dataSource.length > 0 }, /* @__PURE__ */ React.createElement(
        Select,
        {
          showSearch: true,
          dataSource,
          disabled: this.props.disabled,
          ...init(`envKey-${item.key}`),
          label: "Key",
          placeholder: i18n.t("Please select"),
          locale: locale().Select,
          onSearch: this.onSearch
        }
      )), /* @__PURE__ */ React.createElement(If, { condition: !keyOptions }, /* @__PURE__ */ React.createElement(
        Input,
        {
          disabled: this.props.disabled,
          ...init(`envKey-${item.key}`),
          label: "Key",
          className: "full-width",
          placeholder: ""
        }
      )))), /* @__PURE__ */ React.createElement(Col, { span: 10 }, /* @__PURE__ */ React.createElement(Form.Item, null, /* @__PURE__ */ React.createElement(If, { condition: item.valueType == "number" || item.valueType == "string" }, /* @__PURE__ */ React.createElement(
        Input,
        {
          disabled: this.props.disabled,
          htmlType: item.valueType == "number" ? "number" : "",
          ...init(`envValue-${item.key}`),
          label: "Value",
          className: "full-width",
          placeholder: i18n.t(
            item.valueType == "number" ? "Please input a number" : "Please input a value"
          )
        }
      )), /* @__PURE__ */ React.createElement(If, { condition: item.valueType == "boolean" }, /* @__PURE__ */ React.createElement("div", { style: { display: "flex", alignItems: "center" } }, /* @__PURE__ */ React.createElement("span", { style: { lineHeight: "36px", marginRight: "16px" } }, "Value:"), /* @__PURE__ */ React.createElement(
        Switch,
        {
          checked: this.form.getValue(`envValue-${item.key}`),
          disabled: this.props.disabled,
          ...init(`envValue-${item.key}`),
          className: "full-width"
        }
      ))))), /* @__PURE__ */ React.createElement(Col, { span: 1 }, /* @__PURE__ */ React.createElement("div", { className: "mt-5", style: { padding: "8px 0" } }, /* @__PURE__ */ React.createElement(AiOutlineDelete, { onClick: () => this.remove(item.key) }))));
    }), /* @__PURE__ */ React.createElement("div", { className: "mb-20 flexright" }, /* @__PURE__ */ React.createElement(Button, { disabled: this.props.disabled, size: "small", type: "secondary", onClick: this.addItem.bind(this) }, "Add")));
  }
}
export default KV;
