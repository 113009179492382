"use strict";
import i18n from "i18next";
import React from "react";
import { CustomSelect } from "../../components/CustomSelect";
import { locale } from "../../utils/locale";
class SecretKeySelect extends React.Component {
  constructor(props) {
    super(props);
    this.componentDidMount = async () => {
    };
    this.state = {
      dataSource: []
    };
  }
  render() {
    const { onChange, value, secretKeys, id, disabled } = this.props;
    return /* @__PURE__ */ React.createElement(
      CustomSelect,
      {
        locale: locale().Select,
        onChange,
        defaultValue: value,
        id,
        disabled,
        value,
        placeholder: i18n.t("Please select or input a secret key").toString(),
        enableInput: true,
        dataSource: secretKeys?.map((item) => {
          return { label: item, value: item };
        }) || []
      }
    );
  }
}
export default SecretKeySelect;
