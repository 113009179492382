"use strict";
import moment from "moment";
export function getLanguage() {
  const navigator = window.navigator;
  const lang = navigator.language || "en";
  return localStorage.getItem("lang") || lang.split("-")[0];
}
export function isMock() {
  return process.env.MOCK ? true : false;
}
export function getDomain() {
  const { MOCK, BASE_DOMAIN } = process.env;
  return {
    MOCK: MOCK || "",
    APIBASE: BASE_DOMAIN || ""
  };
}
export function isApplicationPath(pathname) {
  return (pathname || "").startsWith("/applications");
}
export function isClustersPath(pathname) {
  return (pathname || "").startsWith("/clusters");
}
export function isAddonsPath(pathname) {
  return (pathname || "").startsWith("/addons");
}
export function isOperationPath(pathname) {
  return (pathname || "").startsWith("/operation");
}
export function isModelPath(pathname) {
  return (pathname || "").startsWith("/model");
}
export function isAPPStorePath(pathname) {
  return (pathname || "").startsWith("/appstores");
}
export function isTargetURL(pathname) {
  return (pathname || "").startsWith("/targets");
}
export function isEnvPath(pathname) {
  return (pathname || "").startsWith("/envs");
}
export function isPipelinePath(pathname) {
  if ((pathname || "").startsWith("/pipelines")) {
    return true;
  }
  const re = new RegExp("^/projects/.*./pipelines.*");
  return re.test(pathname);
}
export function isUsersPath(pathname) {
  return (pathname || "").startsWith("/users");
}
export function isProjectPath(pathname) {
  const re = new RegExp("^/projects/.*./pipelines.*");
  if (re.test(pathname)) {
    return false;
  }
  return (pathname || "").startsWith("/projects");
}
export function isRolesPath(pathname) {
  return (pathname || "").startsWith("/roles");
}
export function isConfigPath(pathname) {
  return (pathname || "").startsWith("/config");
}
export function isDefinitionsPath(pathname) {
  return (pathname || "").startsWith("/definitions");
}
export const APPLICATION_PATH = "applications";
export const CLUSTERS_PATH = "clusters";
export const ADDONS_PATH = "addons";
export const WORKFLOWS_PATH = "workflows";
export function momentDate(time) {
  if (!time) {
    return "";
  }
  return moment(time).format("YYYY/MM/DD HH:mm:ss");
}
export function momentShortDate(time) {
  if (!time) {
    return "";
  }
  return moment(time).format("YYYY/MM/DD");
}
export function beautifyTime(time) {
  if (!time || time === "0001-01-01T00:00:00Z") {
    return "";
  }
  const timestamp = moment(time).unix();
  const now = new Date();
  let mistiming = Math.round(now.getTime() / 1e3) - timestamp;
  const postfix = mistiming > 0 ? "ago" : "later";
  mistiming = Math.abs(mistiming);
  const arrr = ["years", "months", "weeks", "days", "hours", "minutes", "seconds"];
  const arrn = [31536e3, 2592e3, 604800, 86400, 3600, 60, 1];
  for (let i = 0; i < 7; i++) {
    const inm = Math.floor(mistiming / arrn[i]);
    if (inm != 0) {
      return inm + " " + arrr[i] + " " + postfix;
    }
  }
  return "";
}
export function timeDiff(start, end) {
  if (!start || start == "0001-01-01T00:00:00Z") {
    return "-";
  }
  let endTime = moment(moment.now());
  if (end && end != "0001-01-01T00:00:00Z") {
    endTime = moment(end);
  }
  const seconds = endTime.diff(moment(start), "seconds");
  if (seconds > 60) {
    return `${Math.floor(seconds / 60)}m ${seconds % 60}s`;
  }
  return `${seconds}s`;
}
export function beautifyBinarySize(value) {
  if (null == value || value == 0) {
    return "0 Bytes";
  }
  const unitArr = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let index = 0;
  index = Math.floor(Math.log(value) / Math.log(1024));
  const size = value / Math.pow(1024, index);
  let sizeStr = "";
  if (size % 1 === 0) {
    sizeStr = size.toFixed(0);
  } else {
    sizeStr = size.toFixed(2);
  }
  return sizeStr + unitArr[index];
}
export const checkName = /^[a-z]([-a-z0-9]*[a-z0-9])?(\\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*$/;
export const urlRegular = /(https|http|ftp|file):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/g;
export const checkImageName = /^[^\u4e00-\u9fa5 ]{0,512}$/;
export const formItemLayout = {
  labelCol: {
    fixedSpan: 6
  },
  wrapperCol: {
    span: 18
  }
};
export const ACKClusterStatus = [
  {
    key: "initial",
    value: "\u96C6\u7FA4\u521B\u5EFA\u4E2D",
    color: "#98af88"
  },
  {
    key: "failed",
    value: "\u96C6\u7FA4\u521B\u5EFA\u5931\u8D25",
    color: "#ef1111"
  },
  {
    key: "running",
    value: "\u96C6\u7FA4\u8FD0\u884C\u4E2D",
    color: "#10e60e"
  },
  {
    key: "updating",
    value: "\u96C6\u7FA4\u5347\u7EA7\u4E2D",
    color: "#10e60e"
  },
  {
    key: "updating_failed",
    value: "\u96C6\u7FA4\u5347\u7EA7\u5931\u8D25",
    color: "#ef1111"
  },
  {
    key: "scaling",
    value: "\u96C6\u7FA4\u4F38\u7F29\u4E2D",
    color: "#10e60e"
  },
  {
    key: "stopped",
    value: "\u96C6\u7FA4\u5DF2\u7ECF\u505C\u6B62\u8FD0\u884C",
    color: "#3a3e3a"
  },
  {
    key: "deleting",
    value: "\u96C6\u7FA4\u5220\u9664\u4E2D",
    color: "#fd940f"
  },
  {
    key: "deleted",
    value: "\u96C6\u7FA4\u5DF2\u7ECF\u88AB\u5220\u9664",
    color: "#3a3e3a"
  },
  {
    key: "delete_failed",
    value: "\u96C6\u7FA4\u5220\u9664\u5931\u8D25",
    color: "#ef1111"
  }
];
export const replaceUrl = function(text) {
  const re = /(http[s]?:\/\/([\w-]+.)+([:\d+])?(\/[\w-\.\/\?%&=]*)?)/gi;
  const str = text.replace(re, function(a) {
    return '<a href="' + a + '" target=_blank>' + a + "</a>";
  });
  return str;
};
export const checkUserPassword = /^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9]{8,16})$/;
export function isMatchBusinessCode(businessCode) {
  const tokenExpiredList = [12002, 12010];
  return tokenExpiredList.includes(businessCode);
}
export function equalArray(a, b) {
  if (a === void 0 && b === void 0) {
    return true;
  }
  if (b === void 0 || a === void 0) {
    return false;
  }
  if (a.length !== b.length) {
    return false;
  } else {
    const sa = a.sort();
    const sb = b.sort();
    for (let i = 0; i < sa.length; i++) {
      if (sa[i] !== sb[i]) {
        return false;
      }
    }
    return true;
  }
}
export function intersectionArray(a, b) {
  if (a == void 0 || b == void 0) {
    return void 0;
  }
  return a.filter((v) => b.indexOf(v) > -1);
}
export function checkEnabledAddon(addonName, enabledAddons) {
  if (!enabledAddons) {
    return false;
  }
  const addonNames = enabledAddons.map((addon) => {
    return addon.name;
  });
  if (addonNames.includes(addonName)) {
    return true;
  }
  return false;
}
export function convertAny(data) {
  if (!data) {
    return "";
  }
  switch (typeof data) {
    case "string":
      return data;
    case "boolean":
      return data === true ? "true" : "false";
    case "object":
      return JSON.stringify(data);
    case "number":
      return data.toString();
    default:
      return "";
  }
}
export function showAlias(item, alias) {
  if (typeof item === "string") {
    if (alias) {
      return `${item}(${alias})`;
    }
    return item;
  }
  if (!item) {
    return "";
  }
  if (item.alias) {
    return `${item.name}(${item.alias})`;
  }
  return item.name;
}
