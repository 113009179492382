"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Button, Loading, Message, Tab } from "@alifd/next";
import { connect } from "dva";
import React from "react";
import { If } from "../../components/If";
import { ListTitle } from "../../components/ListTitle";
import Permission from "../../components/Permission";
import { Translation } from "../../components/Translation";
import CardContend from "./components/card-conten/index";
import AddonDetailDialog from "./components/detail/index";
import RegistryManageDialog from "./components/registry-manage/index";
import SelectSearch from "./components/search/index";
import Plugin from "./components/plugin";
let Addons = class extends React.Component {
  constructor(props) {
    super(props);
    this.getAddonsList = async (params = {}) => {
      this.props.dispatch({
        type: "addons/getAddonsList",
        payload: params,
        callback: () => {
          const { match } = this.props;
          if (match && match.params && match.params.addonName) {
            this.openAddonDetail(match.params.addonName);
          }
          this.generateTagList();
        }
      });
    };
    this.generateTagList = () => {
      const { addonsList } = this.props;
      const tagMap = /* @__PURE__ */ new Map();
      addonsList.map((addon) => {
        addon.tags?.map((tag) => {
          const old = tagMap.get(tag);
          tagMap.set(tag, old ? old + 1 : 1);
        });
      });
      const list = [];
      tagMap.forEach((v, key) => {
        list.push({ tag: key, num: v });
      });
      list.sort((a, b) => {
        return b.num - a.num;
      });
      this.setState({ tagList: list });
    };
    this.getEnabledAddon = async () => {
      this.props.dispatch({
        type: "addons/getEnabledAddons",
        payload: {}
      });
    };
    this.getAddonRegistriesList = async () => {
      this.props.dispatch({
        type: "addons/getAddonRegistriesList",
        payload: {}
      });
    };
    this.openAddonDetail = (addonName) => {
      this.setState({ showAddonDetail: true, addonName });
    };
    this.closeAddonDetail = () => {
      this.setState({ showAddonDetail: false, addonName: "" });
    };
    this.onShowAddon = (addonName) => {
      const { addonsList = [] } = this.props;
      addonsList.map((item) => {
        if (item.name == addonName) {
          this.setState({ showAddonDetail: false, addonName: "" }, () => {
            this.openAddonDetail(addonName);
          });
        }
      });
    };
    this.state = {
      showAddonDetail: false,
      addonName: "",
      showRegistryManage: false,
      selectTags: []
    };
  }
  componentDidMount() {
    this.getAddonsList();
    this.getAddonRegistriesList();
    this.getEnabledAddon();
  }
  render() {
    const {
      addonsList = [],
      registryList = [],
      history,
      dispatch,
      loading,
      addonListMessage,
      enabledAddons,
      plugin
    } = this.props;
    const addonLoading = loading.models.addons;
    const pluginLoading = loading.models.plugins;
    const { showAddonDetail, addonName, showRegistryManage, tagList, selectTags } = this.state;
    return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
      ListTitle,
      {
        title: "Addons and VelaUX Plugins",
        subTitle: "Manages extended platform capabilities for KubeVela and VelaUX."
      }
    ), /* @__PURE__ */ React.createElement(
      Tab,
      {
        defaultActiveKey: plugin ? "plugins" : "addons",
        onChange: (key) => {
          history.push("/" + (key == "plugins" ? "manage/" : "") + key);
        }
      },
      /* @__PURE__ */ React.createElement(Tab.Item, { title: "Addons", key: "addons" }, /* @__PURE__ */ React.createElement(
        SelectSearch,
        {
          dispatch,
          tagList,
          registries: registryList,
          listFunction: this.getAddonsList,
          onTagChange: (tags) => {
            this.setState({ selectTags: tags });
          },
          extButtons: [
            /* @__PURE__ */ React.createElement(Permission, { request: { resource: "addonRegistry:*", action: "list" }, project: "" }, /* @__PURE__ */ React.createElement(
              Button,
              {
                type: "primary",
                onClick: () => {
                  this.setState({ showRegistryManage: true });
                }
              },
              /* @__PURE__ */ React.createElement(Translation, null, "Addon Registries")
            ))
          ]
        }
      ), /* @__PURE__ */ React.createElement(Loading, { visible: addonLoading, style: { width: "100%" } }, /* @__PURE__ */ React.createElement(If, { condition: addonListMessage }, /* @__PURE__ */ React.createElement(Message, { style: { marginBottom: "16px" }, type: "warning" }, addonListMessage)), /* @__PURE__ */ React.createElement(
        CardContend,
        {
          addonLists: addonsList,
          selectTags,
          enabledAddons,
          clickAddon: this.openAddonDetail
        }
      )), /* @__PURE__ */ React.createElement(If, { condition: showAddonDetail }, /* @__PURE__ */ React.createElement(
        AddonDetailDialog,
        {
          onClose: this.closeAddonDetail,
          showAddon: this.onShowAddon,
          dispatch,
          addonName
        }
      )), /* @__PURE__ */ React.createElement(If, { condition: showRegistryManage }, /* @__PURE__ */ React.createElement(
        RegistryManageDialog,
        {
          visible: showRegistryManage,
          onClose: () => {
            this.getAddonsList();
            this.setState({ showRegistryManage: false });
          },
          syncRegistries: this.getAddonRegistriesList,
          registries: registryList,
          dispatch
        }
      ))),
      /* @__PURE__ */ React.createElement(Tab.Item, { title: "VelaUX Plugins", key: "plugins" }, /* @__PURE__ */ React.createElement(Loading, { visible: pluginLoading, style: { width: "100%" } }, /* @__PURE__ */ React.createElement(
        Plugin,
        {
          dispatch,
          history
        }
      )))
    ));
  }
};
Addons = __decorateClass([
  connect((store) => {
    return { ...store.addons, loading: store.loading };
  })
], Addons);
export default Addons;
