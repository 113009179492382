"use strict";
import { Grid, Icon, Select, Input, Button } from "@alifd/next";
import React, { Fragment } from "react";
import Permission from "../../../../components/Permission";
import { Translation } from "../../../../components/Translation";
import i18n from "../../../../i18n";
import { locale } from "../../../../utils/locale";
import "./index.less";
const { Row, Col } = Grid;
class SelectSearch extends React.Component {
  constructor(props) {
    super(props);
    this.onChangeEnv = (e) => {
      this.setState(
        {
          envValue: e
        },
        () => {
          this.getApplications();
        }
      );
    };
    this.handleClickSearch = () => {
      this.getApplications();
    };
    this.getApplications = async () => {
      const { targetValue, inputValue, envValue } = this.state;
      const params = {
        targetName: targetValue,
        query: inputValue,
        env: envValue
      };
      this.props.listApplication(params);
    };
    this.onCreateApplication = () => {
      this.setState({
        targetValue: "",
        envValue: "",
        inputValue: ""
      });
      this.props.onAddApplication();
    };
    this.state = {
      targetValue: "",
      envValue: "",
      inputValue: "",
      labelValue: []
    };
    this.onChangeTarget = this.onChangeTarget.bind(this);
    this.handleChangName = this.handleChangName.bind(this);
    this.handleChangeLabel = this.handleChangeLabel.bind(this);
  }
  onChangeTarget(e) {
    this.setState(
      {
        targetValue: e
      },
      () => {
        this.getApplications();
      }
    );
  }
  handleChangName(e) {
    this.setState({
      inputValue: e
    });
  }
  handleChangeLabel(value) {
    const { setLabelValue } = this.props;
    let label = value ? value : [];
    setLabelValue(label);
    this.setState(
      {
        labelValue: label
      },
      () => {
        this.getApplications();
      }
    );
  }
  render() {
    const { targetList, envs, projectName, showMode, labelValue, appLabels } = this.props;
    const { targetValue, inputValue, envValue } = this.state;
    const targetSource = targetList?.map((item) => {
      return {
        label: item.alias || item.name,
        value: item.name
      };
    });
    const envSource = envs?.map((env) => {
      return {
        label: env.alias || env.name,
        value: env.name
      };
    });
    const labelSource = appLabels?.map((item) => {
      return {
        label: item,
        value: item
      };
    });
    return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement(Row, { className: "project-select-wrapper border-radius-8 margin-top-20" }, /* @__PURE__ */ React.createElement(Col, { span: "20" }, /* @__PURE__ */ React.createElement(Row, { wrap: true }, /* @__PURE__ */ React.createElement(Col, { xl: 4, m: 4, s: 8, xxs: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(
      Select,
      {
        locale: locale().Select,
        mode: "single",
        size: "large",
        onChange: this.onChangeEnv,
        dataSource: envSource,
        placeholder: i18n.t("Search by Environment"),
        className: "item",
        hasClear: true,
        value: envValue
      }
    )), /* @__PURE__ */ React.createElement(Col, { xl: 4, m: 4, s: 8, xxs: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(
      Select,
      {
        locale: locale().Select,
        mode: "single",
        size: "large",
        onChange: this.onChangeTarget,
        dataSource: targetSource,
        placeholder: i18n.t("Search by Target"),
        className: "item",
        hasClear: true,
        value: targetValue
      }
    )), /* @__PURE__ */ React.createElement(Col, { xl: 8, m: 8, s: 16, xxs: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(
      Select,
      {
        hasClear: true,
        size: "large",
        placeholder: i18n.t("Search by Label Selector").toString(),
        onChange: this.handleChangeLabel,
        showSearch: true,
        mode: "multiple",
        value: labelValue,
        className: "item",
        dataSource: labelSource
      }
    )), /* @__PURE__ */ React.createElement(Col, { xl: 4, m: 8, s: 8, xxs: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(
      Input,
      {
        innerAfter: /* @__PURE__ */ React.createElement(Icon, { type: "search", size: "xs", onClick: this.handleClickSearch, style: { margin: 4 } }),
        hasClear: true,
        size: "large",
        placeholder: i18n.t("Search by Name and Description etc"),
        onChange: this.handleChangName,
        onPressEnter: this.handleClickSearch,
        value: inputValue,
        className: "item"
      }
    )), /* @__PURE__ */ React.createElement(Col, { xl: 4, s: 8 }, /* @__PURE__ */ React.createElement("div", { className: "show-mode" }, /* @__PURE__ */ React.createElement(Button.Group, null, /* @__PURE__ */ React.createElement(
      Button,
      {
        type: showMode == "card" ? "primary" : "secondary",
        onClick: () => this.props.setMode("card")
      },
      "Card"
    ), /* @__PURE__ */ React.createElement(
      Button,
      {
        type: showMode == "table" ? "primary" : "secondary",
        onClick: () => this.props.setMode("table")
      },
      "Table"
    )))))), /* @__PURE__ */ React.createElement(Col, { span: "4" }, /* @__PURE__ */ React.createElement("div", { className: "show-mode" }, /* @__PURE__ */ React.createElement(
      Permission,
      {
        request: { resource: `project:${projectName}/application:*`, action: "create" },
        project: projectName
      },
      /* @__PURE__ */ React.createElement(Button, { className: "create-btn-wrapper", type: "primary", onClick: this.onCreateApplication }, /* @__PURE__ */ React.createElement(Translation, null, "New Application"))
    )))));
  }
}
export default SelectSearch;
