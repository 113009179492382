"use strict";
import { Dialog } from "@alifd/next";
import * as React from "react";
import "./index.less";
import { v4 as uuid } from "uuid";
import DefinitionCode from "../DefinitionCode";
export const ApplicationDryRun = (props) => {
  const id = uuid();
  return /* @__PURE__ */ React.createElement(
    Dialog,
    {
      v2: true,
      className: "dryRunDialog",
      overflowScroll: true,
      footer: /* @__PURE__ */ React.createElement("div", null),
      visible: true,
      onClose: props.onClose,
      title: props.title
    },
    /* @__PURE__ */ React.createElement("div", { id }, /* @__PURE__ */ React.createElement(
      DefinitionCode,
      {
        containerId: id,
        language: "yaml",
        readOnly: true,
        value: props.dryRun.yaml
      }
    ))
  );
};
