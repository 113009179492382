"use strict";
export function findSuffix(quantity) {
  let ix = quantity.length - 1;
  while (ix >= 0 && !/[\.0-9]/.test(quantity.charAt(ix))) {
    ix--;
  }
  return ix === -1 ? "" : quantity.substring(ix + 1);
}
export function quantityToScalar(quantity) {
  if (!quantity) {
    return 0;
  }
  const suffix = findSuffix(quantity);
  if (suffix === "") {
    const num = Number(quantity).valueOf();
    if (isNaN(num)) {
      throw new Error("Unknown quantity " + quantity);
    }
    return num;
  }
  switch (suffix) {
    case "n":
      return Number(quantity.substr(0, quantity.length - 1)).valueOf() / 1e8;
    case "m":
      return Number(quantity.substr(0, quantity.length - 1)).valueOf() / 1e3;
    case "Ki":
      return BigInt(quantity.substr(0, quantity.length - 2)) * BigInt(1024);
    case "Mi":
      return BigInt(quantity.substr(0, quantity.length - 2)) * BigInt(1024 * 1024);
    case "Gi":
      return BigInt(quantity.substr(0, quantity.length - 2)) * BigInt(1024 * 1024 * 1024);
    case "Ti":
      return BigInt(quantity.substr(0, quantity.length - 2)) * BigInt(1024 * 1024 * 1024) * BigInt(1024);
    case "Pi":
      return BigInt(quantity.substr(0, quantity.length - 2)) * BigInt(1024 * 1024 * 1024) * BigInt(1024 * 1024);
    case "Ei":
      return BigInt(quantity.substr(0, quantity.length - 2)) * BigInt(1024 * 1024 * 1024) * BigInt(1024 * 1024 * 1024);
    default:
      throw new Error(`Unknown suffix: ${suffix}`);
  }
}
export function transComponentDefinitions(componentDefinitions) {
  const defaultCoreDataSource = ["k8s-objects", "task", "webservice", "worker"];
  const cloud = [
    {
      label: "Cloud",
      children: []
    }
  ];
  const core = [
    {
      label: "Core",
      children: []
    }
  ];
  const custom = [
    {
      label: "Custom",
      children: []
    }
  ];
  (componentDefinitions || []).map((item) => {
    if (item.workloadType === "configurations.terraform.core.oam.dev") {
      cloud[0].children.push({
        label: item.name,
        value: item.name
      });
    } else if (defaultCoreDataSource.includes(item.name)) {
      core[0].children.push({
        label: item.name,
        value: item.name
      });
    } else {
      custom[0].children.push({
        label: item.name,
        value: item.name
      });
    }
  });
  return [...core, ...custom, ...cloud];
}
export function getLink(endpointObj) {
  const { appProtocol, host } = endpointObj.endpoint;
  let { port, protocol = "", path = "" } = endpointObj.endpoint;
  protocol = protocol.toLocaleLowerCase();
  if (appProtocol && appProtocol !== "") {
    protocol = appProtocol;
  }
  if (protocol == "tcp") {
    protocol = "http";
  }
  if (host == "") {
    return path;
  }
  if (path === "/") {
    path = "";
  }
  if (protocol === "https" && port == 443 || protocol === "http" && port === 80) {
    port = "";
  } else {
    port = ":" + port;
  }
  return protocol + "://" + host + port + path;
}
export function getValue(key, value) {
  if (key.indexOf(".") > -1) {
    const currentKey = key.substring(0, key.indexOf("."));
    const nextKey = key.substring(key.indexOf(".") + 1);
    return getValue(nextKey, value[currentKey]);
  }
  if (!value) {
    return null;
  }
  return value[key];
}
export function getSelectLabel(data) {
  return (data || []).map((item) => {
    return { label: item.alias || item.name, value: item.name };
  });
}
export function getMatchParamObj(match, name) {
  return match.params && match.params[name];
}
export function getBrowserNameAndVersion() {
  const agent = navigator.userAgent.toLowerCase();
  const regStr_ie = /msie [\d.]+/gi;
  const regStr_ff = /firefox\/[\d.]+/gi;
  const regStr_chrome = /chrome\/[\d.]+/gi;
  const regStr_saf = /safari\/[\d.]+/gi;
  let browserNV;
  if (agent.indexOf("msie") > 0) {
    browserNV = agent.match(regStr_ie);
  }
  if (agent.indexOf("firefox") > 0) {
    browserNV = agent.match(regStr_ff);
  }
  if (agent.indexOf("chrome") > 0) {
    browserNV = agent.match(regStr_chrome);
  }
  if (agent.indexOf("safari") > 0 && agent.indexOf("chrome") < 0) {
    browserNV = agent.match(regStr_saf);
  }
  browserNV = browserNV.toString();
  if ("" == browserNV) {
    browserNV = "Is not a standard browser";
  }
  if (browserNV.indexOf("firefox") != -1 || browserNV.indexOf("chrome") != -1) {
    browserNV = browserNV.replace("/", "");
  }
  if (browserNV.indexOf("msie") != -1) {
    browserNV = browserNV.replace("msie", "ie").replace(/\s/g, "");
  }
  return browserNV;
}
export function downloadStringFile(content, filename) {
  const element = document.createElement("a");
  const file = new Blob([content], { type: "text/plain" });
  element.href = URL.createObjectURL(file);
  element.download = filename;
  document.body.appendChild(element);
  element.click();
}
