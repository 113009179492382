"use strict";
import { Grid, Form, Input, Select } from "@alifd/next";
import React from "react";
import { checkName } from "../../../../utils/common";
import "./index.less";
import { Translation } from "../../../../components/Translation";
import i18n from "../../../../i18n";
import { checkPermission } from "../../../../utils/permission";
class GeneralConfig extends React.Component {
  resetField() {
    this.props.field.setValues({
      name: "",
      cluster: [],
      describe: ""
    });
  }
  render() {
    const { Row, Col } = Grid;
    const { projects, isDisableProject, userInfo } = this.props;
    let defaultProject = "";
    const projectOptions = [];
    (projects || []).map((project) => {
      if (checkPermission({ resource: `project:${project.name}/application:*`, action: "create" }, project.name, userInfo)) {
        if (project.name === "default") {
          defaultProject = project.name;
        }
        projectOptions.push({
          label: project.alias ? `${project.alias}(${project.name})` : project.name,
          value: project.name
        });
      }
    });
    const FormItem = Form.Item;
    const formItemLayout = {
      labelCol: {
        fixedSpan: 6
      },
      wrapperCol: {
        span: 20
      }
    };
    const init = this.props.field.init;
    return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Form, { ...formItemLayout, field: this.props.field }, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Name"), labelTextAlign: "left", required: true }, /* @__PURE__ */ React.createElement(
      Input,
      {
        name: "name",
        maxLength: 31,
        ...init("name", {
          rules: [
            {
              required: true,
              pattern: checkName,
              message: /* @__PURE__ */ React.createElement(Translation, null, "Please enter a valid application name")
            }
          ]
        })
      }
    ))), /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Alias") }, /* @__PURE__ */ React.createElement(
      Input,
      {
        name: "alias",
        placeholder: i18n.t("Give your app a more recognizable name").toString(),
        ...init("alias", {
          rules: [
            {
              minLength: 2,
              maxLength: 64,
              message: "Enter a string of 2 to 64 characters."
            }
          ]
        })
      }
    )))), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Description") }, /* @__PURE__ */ React.createElement(
      Input,
      {
        name: "description",
        ...init("description", {
          rules: [
            {
              maxLength: 128,
              message: "Enter a description less than 128 characters."
            }
          ]
        })
      }
    )))), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Project"), required: true }, /* @__PURE__ */ React.createElement(
      Select,
      {
        name: "project",
        placeholder: i18n.t("Please select a project").toString(),
        disabled: isDisableProject ? true : false,
        dataSource: projectOptions,
        filterLocal: true,
        hasClear: true,
        style: { width: "100%" },
        ...init("project", {
          initValue: defaultProject,
          rules: [
            {
              required: true,
              message: "Please select project"
            }
          ]
        })
      }
    ))))));
  }
}
export default GeneralConfig;
