"use strict";
import { Button, Dialog, Dropdown, Grid, Menu, Message, Select } from "@alifd/next";
import { Link, routerRedux } from "dva/router";
import i18n from "i18next";
import React, { Component } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { AiOutlineCopy } from "react-icons/ai";
import { HiOutlineRefresh } from "react-icons/hi";
import { listApplicationServiceEndpoints } from "../../../../api/observation";
import {
  compareApplication,
  deleteApplicationEnvbinding,
  recycleApplicationEnvbinding
} from "../../../../api/application";
import { ApplicationDiff } from "../../../../components/ApplicationDiff";
import { If } from "../../../../components/If";
import Permission from "../../../../components/Permission";
import { Translation } from "../../../../components";
import { locale } from "../../../../utils/locale";
import { getLink } from "../../../../utils/utils";
import { checkPermission } from "../../../../utils/permission";
class Header extends Component {
  constructor(props) {
    super(props);
    this.getTarget = () => {
      const { targets } = this.props;
      const { target } = this.state;
      if (targets && target) {
        const t = targets.find((item) => item.name === target);
        return t;
      }
      return;
    };
    this.loadApplicationEndpoints = async () => {
      const { applicationDetail, appName, envbinding } = this.props;
      const { component } = this.state;
      const target = this.getTarget();
      if (applicationDetail && applicationDetail.name && envbinding) {
        const param = {
          appName: envbinding.appDeployName || appName,
          appNs: envbinding.appDeployNamespace,
          componentName: component,
          cluster: "",
          clusterNs: ""
        };
        if (target) {
          param.cluster = target.cluster?.clusterName || "";
          param.clusterNs = target.cluster?.namespace || "";
        }
        this.setState({ loading: true });
        listApplicationServiceEndpoints(param).then((re) => {
          if (re && re.endpoints) {
            this.setState({ endpoints: re.endpoints });
          } else {
            this.setState({ endpoints: [] });
          }
        }).finally(() => {
          this.setState({ loading: false });
        });
      }
    };
    this.loadApplicationWorkflows = async () => {
      const { appName } = this.props;
      this.props.dispatch({
        type: "application/getApplicationWorkflows",
        payload: { appName }
      });
    };
    this.loadApplicationPolicies = async () => {
      const { appName } = this.props;
      this.props.dispatch({
        type: "application/getApplicationPolicies",
        payload: { appName }
      });
    };
    this.loadApplicationEnvbinding = async () => {
      const { appName } = this.props;
      if (appName) {
        this.props.dispatch({
          type: "application/getApplicationEnvbinding",
          payload: { appName }
        });
      }
    };
    this.updateEnvbindingList = () => {
      this.loadApplicationEnvbinding();
      this.loadApplicationWorkflows();
      this.loadApplicationPolicies();
    };
    this.compareCurrentWithCluster = (appName, envName) => {
      const { applicationStatus, applicationDetail, userInfo } = this.props;
      if (!applicationStatus) {
        this.setState({ compare: void 0 });
        return;
      }
      if (!checkPermission(
        {
          resource: `project:${applicationDetail?.project?.name}/application:${applicationDetail?.name}`,
          action: "compare"
        },
        applicationDetail?.project?.name,
        userInfo
      )) {
        return;
      }
      compareApplication(appName, { compareLatestWithRunning: { env: envName } }).then(
        (res) => {
          this.setState({ compare: res });
        }
      );
    };
    this.handleTargetChange = (value) => {
      this.setState({ target: value }, () => {
        if (this.props.updateQuery) {
          this.props.updateQuery({ component: this.state.component, target: this.state.target });
        }
        this.loadApplicationEndpoints();
      });
    };
    this.handleComponentChange = (value) => {
      this.setState({ component: value }, () => {
        if (this.props.updateQuery) {
          this.props.updateQuery({ component: this.state.component, target: this.state.target });
        }
        this.loadApplicationEndpoints();
      });
    };
    this.recycleEnv = async () => {
      const { applicationDetail, envName, refresh, dispatch } = this.props;
      const sourceOfTrust = applicationDetail?.labels && applicationDetail?.labels["app.oam.dev/source-of-truth"];
      let content = "Are you sure you want to recycle the application form this environment?";
      if (sourceOfTrust === "from-k8s-resource") {
        content = "This application is synchronizing from cluster, recycling from this environment means this application will be deleted.";
      }
      Dialog.confirm({
        needWrapper: true,
        content: /* @__PURE__ */ React.createElement("span", null, i18n.t(content)),
        onOk: () => {
          if (applicationDetail) {
            recycleApplicationEnvbinding({
              appName: applicationDetail.name,
              envName
            }).then((re) => {
              if (re) {
                Message.success(i18n.t("Recycle application environment success"));
                if (sourceOfTrust === "from-k8s-resource") {
                  dispatch(routerRedux.push(`/applications`));
                } else {
                  refresh();
                  this.loadApplicationEndpoints();
                }
              }
            });
          }
        },
        locale: locale().Dialog
      });
    };
    this.deleteEnv = async () => {
      Dialog.confirm({
        content: i18n.t("Are you sure you want to delete the current environment binding?").toString(),
        onOk: () => {
          const { applicationDetail, envName, dispatch } = this.props;
          if (applicationDetail) {
            deleteApplicationEnvbinding({ appName: applicationDetail.name, envName }).then((re) => {
              if (re) {
                Message.success(i18n.t("Environment binding deleted successfully"));
                this.updateEnvbindingList();
                dispatch(routerRedux.push(`/applications/${applicationDetail.name}/config`));
              }
            });
          }
        },
        locale: locale().Dialog
      });
    };
    this.refresh = async () => {
      this.props.refresh();
      this.compareCurrentWithCluster(this.props.appName, this.props.envName);
    };
    this.showStatus = () => {
      this.refresh();
      this.setState({ showStatus: true });
    };
    this.showEditDialog = () => {
      this.setState({ visibleEnvEditPlan: true });
    };
    this.showApplicationDiff = () => {
      this.setState({ visibleApplicationDiff: true });
    };
    this.state = {
      recycleLoading: false,
      deleteLoading: false,
      refreshLoading: false,
      showStatus: false,
      visibleEnvEditPlan: false,
      visibleApplicationDiff: false
    };
  }
  componentDidMount() {
    this.compareCurrentWithCluster(this.props.appName, this.props.envName);
    this.loadApplicationEndpoints();
  }
  shouldComponentUpdate(nextProps) {
    if (nextProps.appName + nextProps.envName != this.props.appName + this.props.envName) {
      this.compareCurrentWithCluster(this.props.appName, nextProps.envName);
      this.loadApplicationEndpoints();
    }
    return true;
  }
  render() {
    const { Row, Col } = Grid;
    const { appName, envName, components, applicationDetail } = this.props;
    const { recycleLoading, deleteLoading, refreshLoading, compare, visibleApplicationDiff, endpoints } = this.state;
    const { targets, applicationStatus, disableStatusShow } = this.props;
    const targetOptions = (targets || []).map((item) => ({
      label: item.alias || item.name,
      value: item.name
    }));
    const componentOptions = (components || []).map((item) => ({
      label: item.alias || item.name,
      value: item.name
    }));
    const getAppStatusShowType = (status) => {
      if (!status) {
        return "notice";
      }
      switch (status) {
        case "running":
          return "success";
        case "workflowFinished":
          return "success";
        case "unhealthy":
          return "error";
      }
      return "warning";
    };
    const projectName = applicationDetail && applicationDetail.project?.name;
    const span = 10 + (targetOptions.length > 0 ? 0 : 4) + (componentOptions.length > 0 ? 0 : 4);
    return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Row, { wrap: true, className: "border-radius-8" }, targetOptions.length > 0 && /* @__PURE__ */ React.createElement(Col, { xl: 4, m: 12, xs: 24, style: { marginBottom: "16px", padding: "0 8px" } }, /* @__PURE__ */ React.createElement(
      Select,
      {
        locale: locale().Select,
        mode: "single",
        onChange: this.handleTargetChange,
        dataSource: targetOptions,
        label: i18n.t("Target").toString(),
        placeholder: i18n.t("Target Selector").toString(),
        hasClear: true
      }
    )), componentOptions.length > 0 && /* @__PURE__ */ React.createElement(Col, { xl: 4, m: 12, xs: 24, style: { marginBottom: "16px", padding: "0 8px" } }, /* @__PURE__ */ React.createElement(
      Select,
      {
        locale: locale().Select,
        mode: "single",
        onChange: this.handleComponentChange,
        dataSource: componentOptions,
        label: i18n.t("Component").toString(),
        placeholder: i18n.t("Component Selector").toString(),
        hasClear: true
      }
    )), /* @__PURE__ */ React.createElement(Col, { xl: 6, m: 12, xs: 24, style: { marginBottom: "16px", padding: "0 8px" } }, /* @__PURE__ */ React.createElement(If, { condition: applicationStatus }, /* @__PURE__ */ React.createElement(Message, { type: getAppStatusShowType(applicationStatus?.status), size: "medium", style: { padding: "8px" } }, /* @__PURE__ */ React.createElement(Translation, null, `Application is ${applicationStatus?.status || "Init"}`), /* @__PURE__ */ React.createElement(If, { condition: !disableStatusShow }, /* @__PURE__ */ React.createElement("span", { style: { marginLeft: "16px" } }, /* @__PURE__ */ React.createElement(Link, { to: `/applications/${appName}/envbinding/${envName}/status` }, /* @__PURE__ */ React.createElement(Translation, null, "Check the details"))))))), /* @__PURE__ */ React.createElement(Col, { xl: span, m: 12, xs: 24, className: "flexright", style: { marginBottom: "16px", padding: "0 8px" } }, /* @__PURE__ */ React.createElement(If, { condition: compare && compare.isDiff }, /* @__PURE__ */ React.createElement(Button, { type: "secondary", onClick: this.showApplicationDiff }, /* @__PURE__ */ React.createElement("span", { className: "circle circle-failure" }), "Diff")), /* @__PURE__ */ React.createElement(Button, { type: "secondary", style: { marginLeft: "16px" }, loading: refreshLoading, onClick: this.refresh }, /* @__PURE__ */ React.createElement(HiOutlineRefresh, null)), /* @__PURE__ */ React.createElement(If, { condition: endpoints && endpoints.length > 0 }, /* @__PURE__ */ React.createElement(
      Dropdown,
      {
        trigger: /* @__PURE__ */ React.createElement(Button, { style: { marginLeft: "16px" }, type: "secondary" }, /* @__PURE__ */ React.createElement(Translation, null, "Service Endpoint"))
      },
      /* @__PURE__ */ React.createElement(Menu, null, endpoints?.map((item) => {
        const linkURL = getLink(item);
        if (item && !item.endpoint.inner) {
          return /* @__PURE__ */ React.createElement(Menu.Item, { key: linkURL }, /* @__PURE__ */ React.createElement(If, { condition: item.endpoint.portName }, /* @__PURE__ */ React.createElement("span", { className: "margin-right-5" }, item.endpoint.portName, ":")), /* @__PURE__ */ React.createElement("a", { style: { color: "#1b58f4" }, target: "_blank", href: linkURL, rel: "noopener noreferrer" }, linkURL));
        }
        return;
      }), endpoints?.map((item) => {
        const linkURL = getLink(item);
        if (item && item.endpoint.inner) {
          return /* @__PURE__ */ React.createElement(Menu.Item, { key: linkURL }, /* @__PURE__ */ React.createElement(If, { condition: item.endpoint.portName }, /* @__PURE__ */ React.createElement("span", { className: "margin-right-5" }, item.endpoint.portName, ":")), /* @__PURE__ */ React.createElement("span", null, linkURL, "(Inner)", /* @__PURE__ */ React.createElement(
            CopyToClipboard,
            {
              onCopy: () => {
                Message.success("Copied successfully");
              },
              text: linkURL
            },
            /* @__PURE__ */ React.createElement(AiOutlineCopy, { size: 14 })
          )));
        }
        return;
      }))
    )), /* @__PURE__ */ React.createElement(If, { condition: !applicationStatus || !applicationStatus.status }, /* @__PURE__ */ React.createElement(
      Permission,
      {
        request: {
          resource: `project:${projectName}/application:${applicationDetail?.name}/envBinding:${envName}`,
          action: "delete"
        },
        project: projectName
      },
      /* @__PURE__ */ React.createElement(
        Button,
        {
          style: { marginLeft: "16px" },
          loading: deleteLoading,
          disabled: applicationDetail?.readOnly,
          className: "danger-btn",
          onClick: this.deleteEnv
        },
        /* @__PURE__ */ React.createElement(Translation, null, "Delete")
      )
    )), /* @__PURE__ */ React.createElement(If, { condition: applicationStatus && applicationStatus.status && applicationStatus.status != "deleting" }, /* @__PURE__ */ React.createElement(
      Permission,
      {
        request: {
          resource: `project:${projectName}/application:${applicationDetail?.name}/envBinding:${envName}`,
          action: "recycle"
        },
        project: projectName
      },
      /* @__PURE__ */ React.createElement(
        Button,
        {
          loading: recycleLoading,
          onClick: this.recycleEnv,
          disabled: applicationDetail?.readOnly,
          className: "danger-btn",
          style: { marginLeft: "16px" }
        },
        /* @__PURE__ */ React.createElement(Translation, null, "Recycle")
      )
    )))), /* @__PURE__ */ React.createElement(If, { condition: visibleApplicationDiff }, compare && /* @__PURE__ */ React.createElement(
      ApplicationDiff,
      {
        id: appName + envName,
        baseName: "Deployed Application",
        targetName: "Latest Application Configuration",
        compare,
        onClose: () => {
          this.setState({ visibleApplicationDiff: false });
        }
      }
    )));
  }
}
export default Header;
