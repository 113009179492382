"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Message, Loading, Button } from "@alifd/next";
import { connect } from "dva";
import React, { Component } from "react";
import { deleteApplication } from "../../api/application";
import { getComponentDefinitions } from "../../api/definitions";
import { If } from "../../components/If";
import { ListTitle } from "../../components/ListTitle";
import Permission from "../../components/Permission";
import { Translation } from "../../components/Translation";
import AppDialog from "./components/AddAppDialog";
import CardContend from "./components/CardContent";
import EditAppDialog from "./components/EditAppDialog";
import SelectSearch from "./components/SelectSearch";
let Application = class extends Component {
  constructor(props) {
    super(props);
    this.getApplications = async (params) => {
      this.setState({ isLoading: true });
      this.props.dispatch({
        type: "application/getApplicationList",
        payload: params,
        callback: () => {
          this.setState({
            isLoading: false
          });
        }
      });
    };
    this.getEnvs = async () => {
      this.props.dispatch({
        type: "env/listEnvs",
        payload: {}
      });
    };
    this.setLabelValue = async (labels) => {
      this.setState({
        labelValue: labels
      });
    };
    this.onDeleteApp = (name) => {
      deleteApplication({ name }).then((re) => {
        if (re) {
          Message.success("Application deleted successfully");
          this.getApplications({});
        }
      });
    };
    this.onGetComponentDefinitions = async () => {
      getComponentDefinitions().then((res) => {
        if (res) {
          this.setState({
            componentDefinitions: res && res.definitions
          });
        }
      });
    };
    this.closeAddApplication = () => {
      this.setState({
        showAddApplication: false
      });
      this.getApplications({});
    };
    this.closeEditAppDialog = () => {
      this.setState({
        showEditApplication: false
      });
      this.getApplications({});
    };
    this.editAppPlan = (editItem) => {
      this.setState({
        editItem,
        showEditApplication: true
      });
    };
    this.clickLabelFilter = (label) => {
      let { labelValue } = this.state;
      let existIndex = -1;
      labelValue.map((key, index) => {
        if (key == label) {
          existIndex = index;
          return;
        }
      });
      if (existIndex == -1) {
        labelValue.push(label);
      } else {
        labelValue = labelValue.splice(existIndex, existIndex);
      }
      this.setState({
        labelValue
      });
      this.getApplications({ labels: labelValue.join(",") });
    };
    let mode = localStorage.getItem("application-list-mode");
    if (mode != "table" && mode != "card") {
      mode = "card";
    }
    this.state = {
      showAddApplication: false,
      componentDefinitions: [],
      labelValue: [],
      isLoading: false,
      showEditApplication: false,
      showMode: mode
    };
  }
  componentDidMount() {
    this.getApplications({});
    this.getEnvs();
    this.onGetComponentDefinitions();
  }
  render() {
    const { applicationList, targets, dispatch, envs, userInfo } = this.props;
    const { showAddApplication, componentDefinitions, isLoading, showEditApplication, editItem, labelValue, showMode } = this.state;
    let appLabels = [];
    applicationList?.map((app) => {
      app.labels && Object.keys(app.labels).map((key) => {
        if (key.indexOf("ux.oam.dev") < 0 && key.indexOf("app.oam.dev")) {
          if (app.labels) {
            appLabels.push(key + "=" + app.labels[key]);
          }
        }
      });
    });
    return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
      ListTitle,
      {
        title: "Applications",
        subTitle: "Deploy and manage all your applications",
        extButtons: [
          /* @__PURE__ */ React.createElement(Permission, { request: { resource: "project:?/application:*", action: "create" }, project: "?" }, /* @__PURE__ */ React.createElement(
            Button,
            {
              type: "primary",
              onClick: () => {
                this.setState({ showAddApplication: true });
              }
            },
            /* @__PURE__ */ React.createElement(Translation, null, "New Application")
          ))
        ]
      }
    ), /* @__PURE__ */ React.createElement(
      SelectSearch,
      {
        projects: userInfo?.projects,
        appLabels,
        dispatch,
        setLabelValue: this.setLabelValue,
        labelValue,
        envs,
        showMode,
        setMode: (mode) => {
          this.setState({ showMode: mode });
          if (mode) {
            localStorage.setItem("application-list-mode", mode);
          }
        },
        getApplications: (params) => {
          this.getApplications(params);
        }
      }
    ), /* @__PURE__ */ React.createElement(Loading, { visible: isLoading, fullScreen: true }, /* @__PURE__ */ React.createElement(
      CardContend,
      {
        applications: applicationList,
        editAppPlan: (item) => {
          this.editAppPlan(item);
        },
        clickLabelFilter: this.clickLabelFilter,
        showMode,
        deleteAppPlan: this.onDeleteApp,
        setVisible: (visible) => {
          this.setState({ showAddApplication: visible });
        }
      }
    )), /* @__PURE__ */ React.createElement(If, { condition: showAddApplication }, /* @__PURE__ */ React.createElement(
      AppDialog,
      {
        visible: showAddApplication,
        targets,
        userInfo,
        projects: userInfo?.projects,
        componentDefinitions,
        setVisible: (visible) => {
          this.setState({ showAddApplication: visible });
        },
        onOK: (name) => {
          this.props.history.push(`/applications/${name}/config`);
        },
        onClose: this.closeAddApplication,
        dispatch
      }
    )), /* @__PURE__ */ React.createElement(If, { condition: showEditApplication && editItem }, /* @__PURE__ */ React.createElement(EditAppDialog, { editItem, onOK: this.closeEditAppDialog, onClose: this.closeEditAppDialog })));
  }
};
Application = __decorateClass([
  connect((store) => {
    return { ...store.application, ...store.target, ...store.clusters, ...store.env, ...store.user };
  })
], Application);
export default Application;
