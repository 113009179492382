"use strict";
import { Field, Loading, Button, Form, Grid, Input } from "@alifd/next";
import React from "react";
import { createPipelineContext, updatePipelineContext } from "../../api/pipeline";
import KV from "../../extends/KV";
import i18n from "../../i18n";
import { checkName } from "../../utils/common";
import { Translation } from "../Translation";
const { Row, Col } = Grid;
class NewContext extends React.Component {
  constructor(props) {
    super(props);
    this.submitContext = () => {
      const { context, clone } = this.props;
      const editMode = context && !clone;
      this.field.validate((errs, values) => {
        if (errs) {
          return;
        }
        const { project, name } = this.props.pipeline;
        const keyValues = [];
        Object.keys(values.values).map((key) => {
          keyValues.push({ key, value: values.values[key] });
        });
        if (editMode) {
          updatePipelineContext(project.name, name, { name: values.name, values: keyValues }).then((res) => {
            if (res) {
              this.props.onSuccess();
            }
          });
        } else {
          createPipelineContext(project.name, name, { name: values.name, values: keyValues }).then((res) => {
            if (res) {
              this.props.onSuccess();
            }
          });
        }
      });
    };
    this.field = new Field(this);
  }
  componentDidMount() {
    const { clone, context } = this.props;
    if (context) {
      const editValues = {};
      context.values.map((v) => {
        editValues[v.key] = v.value;
      });
      if (clone) {
        this.field.setValues({
          name: context.name + "-clone",
          values: editValues
        });
      } else {
        this.field.setValues({
          name: context.name,
          values: editValues
        });
      }
    }
  }
  render() {
    const { init } = this.field;
    const { context, clone } = this.props;
    const editMode = context && !clone;
    if (context && Object.keys(this.field.getValues()).length === 0) {
      return /* @__PURE__ */ React.createElement(Loading, { visible: true });
    }
    return /* @__PURE__ */ React.createElement(Form, { field: this.field, style: { width: "100%" } }, /* @__PURE__ */ React.createElement(Row, { style: { width: "100%" }, wrap: true }, /* @__PURE__ */ React.createElement(Col, { span: 24 }, /* @__PURE__ */ React.createElement(Form.Item, { label: "Name", required: true }, /* @__PURE__ */ React.createElement(
      Input,
      {
        ...init("name", {
          rules: [
            {
              required: true,
              message: i18n.t("You must input a context name")
            },
            {
              pattern: checkName,
              message: i18n.t("You must input a valid name")
            }
          ]
        }),
        placeholder: i18n.t("Please input the context name"),
        disabled: editMode
      }
    ))), /* @__PURE__ */ React.createElement(Col, { span: 24 }, /* @__PURE__ */ React.createElement(Form.Item, { label: "Values", required: true }, /* @__PURE__ */ React.createElement(
      KV,
      {
        ...init("values", {
          rules: [
            {
              required: true,
              message: i18n.t("You must input at least one value")
            }
          ]
        }),
        disabled: false
      }
    ))), /* @__PURE__ */ React.createElement(Col, { span: 24 }, /* @__PURE__ */ React.createElement("div", { className: "flexcenter" }, /* @__PURE__ */ React.createElement(Button, { type: "secondary", onClick: this.submitContext, style: { marginRight: "16px" } }, /* @__PURE__ */ React.createElement(Translation, null, "Submit")), /* @__PURE__ */ React.createElement(Button, { onClick: this.props.onCancel }, /* @__PURE__ */ React.createElement(Translation, null, "Cancel"))))));
  }
}
export default NewContext;
