"use strict";
import { Message } from "@alifd/next";
import React, { Component, Fragment } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import Permission from "../../../../components/Permission";
import { Translation } from "../../../../components/Translation";
import "./index.less";
class ProjectMenu extends Component {
  constructor() {
    super(...arguments);
    this.handleChangeRole = (name) => {
      const { isCreateProjectRoles } = this.props;
      if (!isCreateProjectRoles) {
        this.props.handleChangeRole(name);
      } else {
        return Message.warning(
          /* @__PURE__ */ React.createElement(Translation, null, "When adding a project role, you cannot view the details of other project roles")
        );
      }
    };
    this.getMenuList = () => {
      const { projectRoles, activeRoleName, isCreateProjectRoles, projectName } = this.props;
      const menuList = (projectRoles || []).map((item) => {
        const activeRole = activeRoleName === item.name && !isCreateProjectRoles ? "active" : "";
        return /* @__PURE__ */ React.createElement(
          "li",
          {
            className: `menu-role-item ${activeRole}`,
            onClick: () => {
              this.handleChangeRole(item.name);
            }
          },
          /* @__PURE__ */ React.createElement("span", null, " ", item.alias || item.name, " "),
          /* @__PURE__ */ React.createElement(
            Permission,
            {
              request: { resource: `project:${projectName}/role:${item.name}`, action: "delete" },
              project: projectName
            },
            /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(
              AiOutlineDelete,
              {
                onClick: (e) => {
                  this.handleClick(item.name, e);
                }
              }
            ))
          )
        );
      });
      return menuList;
    };
  }
  handleClick(name, e) {
    e.preventDefault();
    this.props.onDeleteProjectRole(name);
  }
  render() {
    const { projectName } = this.props;
    return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement("ul", { className: "project-menu-wrapper" }, this.getMenuList(), /* @__PURE__ */ React.createElement(Permission, { request: { resource: `project:${projectName}/role:*`, action: "create" }, project: projectName }, /* @__PURE__ */ React.createElement("li", { className: "add-roles-btn", onClick: this.props.addRole }, /* @__PURE__ */ React.createElement(Translation, null, "New Role")))));
  }
}
export default ProjectMenu;
