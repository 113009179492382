"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Table, Dialog, Message, Tag } from "@alifd/next";
import { Link } from "dva/router";
import React, { Fragment, Component } from "react";
import { deleteProject } from "../../api/project";
import { ListTitle as Title } from "../../components/ListTitle";
import { Translation } from "../../components/Translation";
import { momentDate } from "../../utils/common";
import { locale } from "../../utils/locale";
import { connect } from "dva";
import i18n from "../../i18n";
let MyProjectList = class extends Component {
  constructor(props) {
    super(props);
    this.loadUserInfo = () => {
      this.setState({ isLoading: true });
      this.props.dispatch({
        type: "user/getLoginUserInfo",
        callback: () => {
          this.setState({ isLoading: false });
        }
      });
    };
    this.onDelete = (record) => {
      Dialog.confirm({
        content: "Are you sure you want to delete the project",
        onOk: () => {
          const { name } = record;
          if (name) {
            deleteProject({ name }).then((res) => {
              if (res) {
                Message.success(/* @__PURE__ */ React.createElement(Translation, null, "Project deleted successfully"));
                this.loadUserInfo();
              }
            }).catch();
          }
        },
        locale: locale().Dialog
      });
    };
    this.state = {
      isLoading: false
    };
  }
  componentDidMount() {
  }
  render() {
    const columns = [
      {
        key: "name",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Name"),
        dataIndex: "name",
        cell: (v) => {
          return /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(Link, { to: `/projects/${v}` }, v));
        }
      },
      {
        key: "alias",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Alias"),
        dataIndex: "alias",
        cell: (v) => {
          return /* @__PURE__ */ React.createElement("span", null, v);
        }
      },
      {
        key: "description",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Description"),
        dataIndex: "description",
        cell: (v) => {
          return /* @__PURE__ */ React.createElement("span", null, v);
        }
      },
      {
        key: "owner",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Owner"),
        dataIndex: "owner",
        cell: (v) => {
          return /* @__PURE__ */ React.createElement("span", null, v && v.alias ? `${v.alias}(${v.name})` : v.name);
        }
      },
      {
        key: "joinTime",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Join Time"),
        dataIndex: "joinTime",
        cell: (v) => {
          return /* @__PURE__ */ React.createElement("span", null, momentDate(v));
        }
      },
      {
        key: "roles",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Roles"),
        dataIndex: "roles",
        cell: (v) => {
          if (!v) {
            return /* @__PURE__ */ React.createElement("span", null);
          }
          return /* @__PURE__ */ React.createElement("div", null, v.map((role) => {
            return /* @__PURE__ */ React.createElement(Tag, { key: role.name }, role.alias || role.name);
          }));
        }
      }
    ];
    const { Column } = Table;
    const { isLoading } = this.state;
    const { userInfo } = this.props;
    return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "project-list-content" }, /* @__PURE__ */ React.createElement(Title, { title: "Projects", subTitle: i18n.t("Project are used for users and applications isolation") }), /* @__PURE__ */ React.createElement(Table, { locale: locale().Table, dataSource: userInfo?.projects, loading: isLoading }, columns.map((col, key) => /* @__PURE__ */ React.createElement(Column, { ...col, key, align: "left" })))));
  }
};
MyProjectList = __decorateClass([
  connect((store) => {
    return { ...store.user };
  })
], MyProjectList);
export default MyProjectList;
