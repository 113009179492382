"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Card, Form, Grid, Message, Button, Select, Input, Field, Loading } from "@alifd/next";
import React from "react";
import {
  AiOutlineFileDone,
  AiOutlineGroup,
  AiOutlineHdd,
  AiOutlineMergeCells,
  AiOutlineOneToOne,
  AiOutlineReconciliation
} from "react-icons/ai";
import { createPolicy, updatePolicy } from "../../../../api/application";
import { detailPolicyDefinition, getPolicyDefinitions } from "../../../../api/definitions";
import DrawerWithFooter from "../../../../components/Drawer";
import { If } from "../../../../components/If";
import Permission from "../../../../components/Permission";
import { Translation } from "../../../../components/Translation";
import i18n from "../../../../i18n";
import "./index.less";
import classNames from "classnames";
import { checkName } from "../../../../utils/common";
import { locale } from "../../../../utils/locale";
import UISchema from "../../../../components/UISchema";
import { connect } from "dva";
import { BiCodeBlock, BiLaptop } from "react-icons/bi";
const { Row, Col } = Grid;
let PolicyDialog = class extends React.Component {
  constructor(props) {
    super(props);
    this.handleTypeChange = (value) => {
      this.removeProperties(() => {
        this.loadPolicyDefinitionDetail(value);
        this.field.setValue("name", value);
        this.field.setValue("alias", value);
      });
    };
    this.removeProperties = (callback) => {
      this.field.remove("properties");
      this.setState({ policyDefinitionDetail: void 0 }, callback);
    };
    this.setUISchemaContext = () => {
      const { dispatch, appName, project } = this.props;
      if (dispatch) {
        dispatch({
          type: "uischema/setAppName",
          payload: appName
        });
        dispatch({
          type: "uischema/setProject",
          payload: project
        });
      }
    };
    this.extButtonList = () => {
      const { appName, project, policy } = this.props;
      const { createPolicyLoading, selectedPolicyItem } = this.state;
      return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
        Permission,
        {
          request: {
            resource: `project:${project}/application:${appName}/policy:*`,
            action: "create"
          },
          project
        },
        /* @__PURE__ */ React.createElement(If, { condition: !policy }, /* @__PURE__ */ React.createElement(
          Button,
          {
            disabled: !selectedPolicyItem,
            type: "primary",
            onClick: this.onSubmitCreate,
            loading: createPolicyLoading
          },
          i18n.t("Create").toString()
        )),
        /* @__PURE__ */ React.createElement(If, { condition: policy }, /* @__PURE__ */ React.createElement(
          Button,
          {
            disabled: !selectedPolicyItem,
            type: "primary",
            onClick: this.onSubmitUpdate,
            loading: createPolicyLoading
          },
          i18n.t("Update").toString()
        ))
      ));
    };
    this.onSubmitCreate = () => {
      const { selectedPolicyItem } = this.state;
      if (!selectedPolicyItem) {
        return;
      }
      this.field.validate((error, values) => {
        if (error) {
          return;
        }
        this.setState({ createPolicyLoading: true });
        const { appName } = this.props;
        if (!appName) {
          return;
        }
        const { properties, name, alias, description, envName, type, workflow, steps } = values;
        let policyType = selectedPolicyItem.type;
        if (type) {
          policyType = type;
        }
        if (!policyType) {
          Message.warning(i18n.t("Please select a policy type first."));
          return;
        }
        let env = envName;
        if (!env && workflow) {
          env = this.getEnvNameFromWorkflow(workflow);
        }
        const params = {
          name,
          type: policyType,
          envName: env,
          description,
          alias,
          properties: JSON.stringify(properties)
        };
        if (workflow && steps && steps.length > 0) {
          params.workflowPolicyBind = [
            {
              name: workflow,
              steps
            }
          ];
        }
        createPolicy(appName, params).then((res) => {
          if (res) {
            Message.success(i18n.t("Policy added successfully"));
            this.props.onOK();
          }
        }).finally(() => {
          this.setState({ createPolicyLoading: false });
        });
      });
    };
    this.onSubmitUpdate = () => {
      this.field.validate((error, values) => {
        if (error) {
          return;
        }
        this.setState({ createPolicyLoading: true });
        const { appName } = this.props;
        if (!appName) {
          return;
        }
        const { properties, name, alias, description, envName, type, workflow, steps } = values;
        let env = envName;
        if (!env && workflow) {
          env = this.getEnvNameFromWorkflow(workflow);
        }
        const params = {
          envName: env,
          description,
          alias,
          type,
          properties: JSON.stringify(properties)
        };
        if (workflow && steps && steps.length > 0) {
          params.workflowPolicyBind = [
            {
              name: workflow,
              steps
            }
          ];
        }
        updatePolicy(appName, name, params).then((res) => {
          if (res) {
            Message.success(i18n.t("Policy updated successfully"));
            this.props.onOK();
          }
        }).finally(() => {
          this.setState({ createPolicyLoading: false });
        });
      });
    };
    this.onSelectPolicy = (item) => {
      this.field.reset();
      this.field.setValues({
        name: item.name,
        description: item.title,
        properties: item.properties,
        type: item.type,
        alias: item.label
      });
      if (item.name == "custom") {
        this.loadPolicyDefinitions();
      }
      if (!item.properties) {
        this.loadPolicyDefinitionDetail(item.type);
      }
      this.setState({ selectedPolicyItem: item });
    };
    this.buildEnvironmentOptions = () => {
      const { envbinding } = this.props;
      return envbinding?.map((env) => {
        return {
          label: env.alias,
          value: env.name
        };
      });
    };
    this.buildWorkflowStepsOptions = () => {
      const workflowName = this.field.getValue("workflow");
      const { workflows } = this.props;
      const stepOptions = [];
      workflows.map((wf) => {
        if (wf.name == workflowName) {
          wf.steps?.map((step) => {
            if (step.type == "deploy") {
              stepOptions.push({
                label: `${step.name}(${step.alias || "-"})`,
                value: step.name
              });
            }
          });
        }
      });
      return stepOptions;
    };
    this.buildWorkflowOptions = () => {
      const { workflows } = this.props;
      return workflows.map((wf) => {
        return {
          label: `${wf.name}(${wf.alias || "-"})`,
          value: wf.name
        };
      });
    };
    this.buildPolicyTypeOptions = () => {
      const { definitions } = this.state;
      const options = [];
      definitions?.map((definition) => {
        if (definition.name != "override" && definition.name != "topology") {
          options.push({
            label: definition.name,
            value: definition.name
          });
        }
      });
      return options;
    };
    this.getEnvNameFromWorkflow = (workflowName) => {
      const { workflows } = this.props;
      let envName = "";
      workflows.map((wf) => {
        if (wf.name == workflowName) {
          envName = wf.envName;
        }
      });
      return envName;
    };
    this.loadPolicyDefinitions = () => {
      getPolicyDefinitions().then((res) => {
        this.setState({ definitions: res.definitions });
      });
    };
    this.loadPolicyDefinitionDetail = (policyType) => {
      this.setState({ definitionDetailLoading: true });
      detailPolicyDefinition({ name: policyType }).then((res) => {
        if (res) {
          this.setState({ policyDefinitionDetail: res });
        }
      }).finally(() => {
        this.setState({ definitionDetailLoading: false });
      });
    };
    this.state = {
      items: [
        {
          label: i18n.t("Override"),
          type: "override",
          name: "override-custom",
          icon: /* @__PURE__ */ React.createElement(AiOutlineMergeCells, { size: 35 }),
          title: i18n.t("Set differentiated configurations of the application in different environments.")
        },
        {
          label: i18n.t("Topology"),
          type: "topology",
          name: "topology",
          icon: /* @__PURE__ */ React.createElement(AiOutlineGroup, { size: 35 }),
          title: i18n.t("This policy allows custom the topology by the target")
        },
        {
          label: i18n.t("Deliver Once"),
          type: "apply-once",
          name: "apply-once-no-rule",
          icon: /* @__PURE__ */ React.createElement(AiOutlineOneToOne, { size: 35 }),
          title: i18n.t(
            "This policy allows direct changes to the resources distributed to the target cluster, the controller won't keep reconciling."
          ),
          properties: {
            enable: true
          }
        },
        {
          label: i18n.t("PVC Retain"),
          type: "garbage-collect",
          name: "garbage-collect-retain-pvc",
          icon: /* @__PURE__ */ React.createElement(AiOutlineHdd, { size: 35 }),
          title: i18n.t("This policy allows retaining the PVC resource after recycling the application."),
          properties: {
            rules: [
              {
                selector: {
                  resourceTypes: ["PersistentVolumeClaim"]
                },
                strategy: "never"
              }
            ]
          }
        },
        {
          label: i18n.t("Replicas Scalable"),
          type: "apply-once",
          name: "apply-once-ignore-replicas",
          icon: /* @__PURE__ */ React.createElement(AiOutlineReconciliation, { size: 35 }),
          title: i18n.t("This policy allows replicas of workloads to be changed, such as working with HPA."),
          properties: {
            rules: [
              {
                enable: true,
                rules: [
                  {
                    selector: {
                      resourceTypes: ["Deployment", "StatefulSet", "Job"]
                    },
                    strategy: {
                      path: ["spec.replicas"]
                    }
                  }
                ]
              }
            ]
          }
        },
        {
          label: i18n.t("Custom"),
          name: "custom",
          type: "",
          icon: /* @__PURE__ */ React.createElement(AiOutlineFileDone, { size: 35 })
        }
      ],
      createPolicyLoading: false,
      definitionDetailLoading: false,
      propertiesMode: "native"
    };
    this.field = new Field(this, {
      onChange: (name, value) => {
        if (name === "type") {
          this.handleTypeChange(value);
        }
      }
    });
    this.uiSchemaRef = React.createRef();
  }
  componentDidMount() {
    this.setUISchemaContext();
    const { policy } = this.props;
    if (policy) {
      let selected = false;
      this.state.items.map((item) => {
        if (item.type == policy.type && item.properties == void 0) {
          this.onSelectPolicy(item);
          selected = true;
        }
      });
      if (!selected) {
        this.onSelectPolicy({
          label: i18n.t("Custom"),
          name: "custom",
          type: "",
          icon: /* @__PURE__ */ React.createElement(AiOutlineFileDone, { size: 35 })
        });
      }
      this.field.setValues({ ...policy });
      if (Array.isArray(policy.workflowPolicyBind) && policy.workflowPolicyBind.length > 0) {
        this.field.setValues({
          workflow: policy.workflowPolicyBind[0].name,
          steps: policy.workflowPolicyBind[0].steps
        });
      }
      this.loadPolicyDefinitionDetail(policy.type);
    }
  }
  render() {
    const { onClose, policy } = this.props;
    const { items, selectedPolicyItem, definitionDetailLoading, policyDefinitionDetail, propertiesMode } = this.state;
    const validator = (rule, value, callback) => {
      this.uiSchemaRef.current?.validate(callback);
    };
    const init = this.field.init;
    const showType = selectedPolicyItem && selectedPolicyItem?.name == "custom" || policy != void 0;
    const span = showType ? 8 : 12;
    return /* @__PURE__ */ React.createElement(
      DrawerWithFooter,
      {
        title: policy ? i18n.t("Update Policy") : i18n.t("New Policy"),
        placement: "right",
        width: 800,
        onClose,
        extButtons: this.extButtonList()
      },
      /* @__PURE__ */ React.createElement(Form, { field: this.field }, /* @__PURE__ */ React.createElement(Card, { contentHeight: "auto", title: i18n.t("Select a policy type").toString() }, !policy && /* @__PURE__ */ React.createElement(Row, { wrap: true }, items.map((item) => {
        return /* @__PURE__ */ React.createElement(Col, { l: 4, title: item.title, key: item.name }, /* @__PURE__ */ React.createElement(
          "div",
          {
            className: classNames("policy-item", {
              active: selectedPolicyItem && selectedPolicyItem.name == item.name
            }),
            onClick: () => this.onSelectPolicy(item)
          },
          /* @__PURE__ */ React.createElement("div", { className: "policy-icon" }, item.icon),
          /* @__PURE__ */ React.createElement("div", { className: "policy-name" }, item.label)
        ));
      })), selectedPolicyItem && /* @__PURE__ */ React.createElement(Row, { wrap: true }, showType && /* @__PURE__ */ React.createElement(Col, { span, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(Form.Item, { label: i18n.t("Policy Type"), required: true }, /* @__PURE__ */ React.createElement(
        Select,
        {
          ...init("type", {
            rules: [
              {
                required: true,
                message: i18n.t("Please must select the policy type.").toString()
              }
            ]
          }),
          hasClear: true,
          disabled: policy != void 0,
          locale: locale().Select,
          dataSource: this.buildPolicyTypeOptions()
        }
      ))), /* @__PURE__ */ React.createElement(Col, { span, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(Form.Item, { label: i18n.t("Name").toString(), required: true }, /* @__PURE__ */ React.createElement(
        Input,
        {
          ...init(`name`, {
            rules: [
              {
                required: true,
                pattern: checkName,
                message: "Please input a valid policy name"
              }
            ]
          }),
          disabled: policy != void 0,
          locale: locale().Input
        }
      ))), /* @__PURE__ */ React.createElement(Col, { span, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(Form.Item, { label: i18n.t("Alias").toString() }, /* @__PURE__ */ React.createElement(
        Input,
        {
          ...init(`alias`, {
            rules: [
              {
                minLength: 2,
                maxLength: 64,
                message: "Input a string of 2 to 64 characters."
              }
            ]
          }),
          locale: locale().Input
        }
      ))), /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(Form.Item, { label: /* @__PURE__ */ React.createElement(Translation, null, "Description") }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "description",
          placeholder: i18n.t("Please input the description").toString(),
          ...init("description", {
            rules: [
              {
                maxLength: 256,
                message: i18n.t("Enter a description that contains less than 256 characters.")
              }
            ]
          })
        }
      )))), /* @__PURE__ */ React.createElement(If, { condition: selectedPolicyItem?.properties }, /* @__PURE__ */ React.createElement(Message, { style: { marginTop: "8px" }, type: "success" }, /* @__PURE__ */ React.createElement(Translation, null, "This policy already have the default properties")))), /* @__PURE__ */ React.createElement(Card, { contentHeight: "auto", style: { marginTop: "8px" }, title: i18n.t("Select an environment").toString() }, !selectedPolicyItem || selectedPolicyItem.type != "override" && /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
        Form.Item,
        {
          help: i18n.t("If select an environment, this policy is only enabled in the selected environment.").toString()
        },
        /* @__PURE__ */ React.createElement(
          Select,
          {
            ...init("envName", {
              rules: []
            }),
            hasClear: true,
            locale: locale().Select,
            dataSource: this.buildEnvironmentOptions()
          }
        )
      )), selectedPolicyItem && selectedPolicyItem.type == "override" && /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(
        Form.Item,
        {
          help: i18n.t("Select the workflow to which the policy should be applied.").toString(),
          required: true,
          label: i18n.t("Workflow").toString()
        },
        /* @__PURE__ */ React.createElement(
          Select,
          {
            ...init("workflow", {
              rules: [
                {
                  required: true
                }
              ]
            }),
            hasClear: true,
            locale: locale().Select,
            dataSource: this.buildWorkflowOptions()
          }
        )
      )), /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(
        Form.Item,
        {
          help: i18n.t("Select the steps to which the policy should be applied.").toString(),
          label: i18n.t("Steps").toString(),
          required: true
        },
        /* @__PURE__ */ React.createElement(
          Select,
          {
            ...init("steps", {
              rules: [
                {
                  required: true
                }
              ]
            }),
            hasClear: true,
            locale: locale().Select,
            mode: "multiple",
            dataSource: this.buildWorkflowStepsOptions()
          }
        )
      ))))), selectedPolicyItem && !selectedPolicyItem?.properties && /* @__PURE__ */ React.createElement(Loading, { visible: definitionDetailLoading, style: { width: "100%" } }, /* @__PURE__ */ React.createElement(
        Card,
        {
          contentHeight: "auto",
          style: { marginTop: "8px" },
          title: i18n.t("Policy Properties").toString(),
          className: "withActions",
          subTitle: /* @__PURE__ */ React.createElement(
            Button,
            {
              style: { alignItems: "center", display: "flex" },
              onClick: () => {
                if (propertiesMode === "native") {
                  this.setState({ propertiesMode: "code" });
                } else {
                  this.setState({ propertiesMode: "native" });
                }
              }
            },
            propertiesMode === "native" && /* @__PURE__ */ React.createElement(BiCodeBlock, { size: 14, title: i18n.t("Switch to the coding mode") }),
            propertiesMode === "code" && /* @__PURE__ */ React.createElement(BiLaptop, { size: 14, title: i18n.t("Switch to the native mode") })
          )
        },
        /* @__PURE__ */ React.createElement(Form.Item, { required: true }, /* @__PURE__ */ React.createElement(
          UISchema,
          {
            key: policyDefinitionDetail?.name,
            ...init(`properties`, {
              rules: [
                {
                  validator,
                  message: i18n.t("Please check the properties of this policy")
                }
              ]
            }),
            enableCodeEdit: propertiesMode === "code",
            uiSchema: policyDefinitionDetail && policyDefinitionDetail.uiSchema,
            definition: {
              type: "policy",
              name: policyDefinitionDetail?.name || "",
              description: policyDefinitionDetail?.description || ""
            },
            ref: this.uiSchemaRef,
            mode: "new"
          }
        )),
        /* @__PURE__ */ React.createElement(If, { condition: !policyDefinitionDetail }, /* @__PURE__ */ React.createElement(Message, { type: "notice" }, /* @__PURE__ */ React.createElement(Translation, null, "Please select policy type first.")))
      )))
    );
  }
};
PolicyDialog = __decorateClass([
  connect()
], PolicyDialog);
export default PolicyDialog;
