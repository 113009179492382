"use strict";
import { getEnvs } from "../api/env";
const envs = {
  namespace: "env",
  state: {
    envs: [],
    envTotal: 0
  },
  reducers: {
    updateEnvs(state, { payload }) {
      return {
        ...state,
        envs: payload.envs,
        envTotal: payload.total || 0
      };
    }
  },
  effects: {
    *listEnvs(action, { call, put }) {
      const result = yield call(getEnvs, action.payload);
      yield put({ type: "updateEnvs", payload: result || {} });
    }
  }
};
export default envs;
