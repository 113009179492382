"use strict";
import { Button, Message, Grid, Dialog, Form, Input, Table, Field, Select } from "@alifd/next";
import React from "react";
import { createAddonRegistry, deleteAddonRegistry } from "../../../../api/addons";
import { If } from "../../../../components/If";
import Permission from "../../../../components/Permission";
import { Translation } from "../../../../components/Translation";
import { checkName } from "../../../../utils/common";
import { handleError } from "../../../../utils/errors";
import { locale } from "../../../../utils/locale";
const { Row, Col } = Grid;
class RegistryManageDialog extends React.Component {
  constructor(props) {
    super(props);
    this.onClose = () => {
      this.props.onClose();
    };
    this.onOk = () => {
      const { syncRegistries } = this.props;
      this.field.validate((error, values) => {
        if (error) {
          return;
        }
        const { name, type, url, path, token, repo } = values;
        let params = {};
        if (type == "OSS") {
          params = {
            name,
            oss: {
              end_point: url,
              bucket: path
            }
          };
        }
        if (type == "Github") {
          params = {
            name,
            git: {
              url,
              path,
              token
            }
          };
        }
        if (type == "Gitee") {
          params = {
            name,
            gitee: {
              url,
              path,
              token
            }
          };
        }
        if (type == "Gitlab") {
          params = {
            name,
            gitlab: {
              url,
              path,
              token,
              repo
            }
          };
        }
        if (type == "Helm") {
          params = {
            name,
            helm: {
              url
            }
          };
        }
        createAddonRegistry(params).then(() => {
          Message.success(/* @__PURE__ */ React.createElement(Translation, null, "add success"));
          this.setState({ showAdd: false });
          syncRegistries();
        }).catch((err) => {
          handleError(err);
        });
      });
    };
    this.onDeleteRegistry = (name) => {
      const { syncRegistries } = this.props;
      deleteAddonRegistry({ name }).then((re) => {
        if (re) {
          Message.success(/* @__PURE__ */ React.createElement(Translation, null, "delete success"));
          syncRegistries();
        }
      });
    };
    this.showAddRegistry = () => {
      this.setState({ showAdd: true });
    };
    this.addExperimental = () => {
      const { syncRegistries } = this.props;
      createAddonRegistry({
        name: "experimental",
        helm: {
          url: "https://addons.kubevela.net/experimental"
        }
      }).then((res) => {
        if (res) {
          Message.success(/* @__PURE__ */ React.createElement(Translation, null, "add success"));
          this.setState({ showAdd: false });
          syncRegistries();
        }
      }).catch((err) => {
        handleError(err);
      });
    };
    this.state = {
      showAdd: false
    };
    this.field = new Field(this, {
      onChange: (name, value) => {
        if (name == "type") {
          this.setState({ selectType: value });
        }
      }
    });
  }
  render() {
    const { visible, registries } = this.props;
    const { showAdd, selectType } = this.state;
    const init = this.field.init;
    const renderAction = (name) => {
      return /* @__PURE__ */ React.createElement(Permission, { request: { resource: `addonRegistry:${name}`, action: "delete" }, project: "" }, /* @__PURE__ */ React.createElement(
        "a",
        {
          onClick: () => {
            Dialog.confirm({
              content: /* @__PURE__ */ React.createElement(Translation, null, "Are you sure to delete?"),
              onOk: () => {
                this.onDeleteRegistry(name);
              },
              locale: locale().Dialog
            });
          }
        },
        /* @__PURE__ */ React.createElement(Translation, null, "Remove")
      ));
    };
    let existExperimental = false;
    const registryDataSource = registries.map((item) => {
      if (item.name == "experimental") {
        existExperimental = true;
      }
      const reItem = {
        name: item.name,
        url: "",
        path: "",
        type: "",
        repo: ""
      };
      if (item.git) {
        reItem.url = item.git.url;
        reItem.path = item.git.path;
        reItem.type = "Github";
      }
      if (item.gitee) {
        reItem.url = item.git.url;
        reItem.path = item.git.path;
        reItem.type = "Gitee";
      }
      if (item.gitlab) {
        reItem.url = item.gitlab.url;
        reItem.path = item.gitlab.path;
        reItem.repo = item.gitlab.repo;
        reItem.type = "Gitlab";
      }
      if (item.oss) {
        reItem.url = item.oss.end_point;
        reItem.path = item.oss.bucket ? `${item.oss.bucket}/${item.oss.path}` : item.oss.path;
        reItem.type = "OSS";
      }
      if (item.helm) {
        reItem.url = item.helm.url;
        reItem.type = "Helm";
      }
      return reItem;
    });
    return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
      Dialog,
      {
        locale: locale().Dialog,
        title: /* @__PURE__ */ React.createElement(Translation, null, "Registry Management"),
        autoFocus: true,
        overflowScroll: true,
        visible,
        style: { width: "1000px" },
        onOk: this.onOk,
        onCancel: this.onClose,
        onClose: this.onClose,
        footerActions: [],
        v2: true
      },
      /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, null, /* @__PURE__ */ React.createElement("div", { className: "tableButton" }, /* @__PURE__ */ React.createElement(If, { condition: !existExperimental }, /* @__PURE__ */ React.createElement(Button, { type: "secondary", onClick: this.addExperimental, style: { marginRight: "16px" } }, /* @__PURE__ */ React.createElement(Translation, null, "Add Experimental Registry"))), /* @__PURE__ */ React.createElement(Permission, { request: { resource: "addonRegistry:*", action: "create" }, project: "" }, /* @__PURE__ */ React.createElement(Button, { type: "secondary", onClick: this.showAddRegistry }, /* @__PURE__ */ React.createElement(Translation, null, "New")))))),
      /* @__PURE__ */ React.createElement(Table, { locale: locale().Table, dataSource: registryDataSource }, /* @__PURE__ */ React.createElement(Table.Column, { width: "150px", title: /* @__PURE__ */ React.createElement(Translation, null, "Name"), dataIndex: "name" }), /* @__PURE__ */ React.createElement(Table.Column, { width: "80px", title: /* @__PURE__ */ React.createElement(Translation, null, "Type"), dataIndex: "type" }), /* @__PURE__ */ React.createElement(Table.Column, { title: /* @__PURE__ */ React.createElement(Translation, null, "URL"), dataIndex: "url" }), registryDataSource.find((item) => item.type === "Gitlab") && /* @__PURE__ */ React.createElement(Table.Column, { width: "100px", title: /* @__PURE__ */ React.createElement(Translation, null, "Repository Name"), dataIndex: "repo" }), /* @__PURE__ */ React.createElement(Table.Column, { width: "160px", title: /* @__PURE__ */ React.createElement(Translation, null, "Path(Bucket)"), dataIndex: "path" }), /* @__PURE__ */ React.createElement(
        Table.Column,
        {
          cell: renderAction,
          width: "100px",
          align: "left",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Actions"),
          dataIndex: "name"
        }
      )),
      /* @__PURE__ */ React.createElement(If, { condition: showAdd }, /* @__PURE__ */ React.createElement(Form, { field: this.field }, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: selectType === "Gitlab" ? 3 : 4, style: { padding: "8px" } }, /* @__PURE__ */ React.createElement(Form.Item, { label: /* @__PURE__ */ React.createElement(Translation, null, "Name"), required: true }, /* @__PURE__ */ React.createElement(
        Input,
        {
          ...init("name", {
            rules: [
              {
                required: true,
                pattern: checkName,
                message: "Please enter a valid name"
              }
            ]
          })
        }
      ))), /* @__PURE__ */ React.createElement(Col, { span: selectType === "Gitlab" ? 3 : 4, style: { padding: "8px" } }, /* @__PURE__ */ React.createElement(
        Form.Item,
        {
          label: /* @__PURE__ */ React.createElement(Translation, null, "Type"),
          help: /* @__PURE__ */ React.createElement(Translation, null, "The addon registry type")
        },
        /* @__PURE__ */ React.createElement(
          Select,
          {
            locale: locale().Select,
            ...init("type", {
              rules: [
                {
                  max: 512,
                  message: "The input string is too long"
                }
              ]
            })
          },
          /* @__PURE__ */ React.createElement(Select.Option, { value: "Helm" }, "Helm Repository"),
          /* @__PURE__ */ React.createElement(Select.Option, { value: "Github" }, "Github"),
          /* @__PURE__ */ React.createElement(Select.Option, { value: "Gitee" }, "Gitee"),
          /* @__PURE__ */ React.createElement(Select.Option, { value: "Gitlab" }, "Gitlab"),
          /* @__PURE__ */ React.createElement(Select.Option, { value: "OSS" }, "Aliyun OSS")
        )
      )), /* @__PURE__ */ React.createElement(Col, { span: 6, style: { padding: "8px" } }, /* @__PURE__ */ React.createElement(Form.Item, { label: /* @__PURE__ */ React.createElement(Translation, null, "URL"), required: true }, /* @__PURE__ */ React.createElement(
        Input,
        {
          ...init("url", {
            rules: [
              {
                required: true,
                format: "url",
                message: "Please enter a valid github repo url"
              }
            ]
          })
        }
      ))), /* @__PURE__ */ React.createElement(If, { condition: selectType === "Gitlab" }, /* @__PURE__ */ React.createElement(Col, { span: 3, style: { padding: "8px" } }, /* @__PURE__ */ React.createElement(
        Form.Item,
        {
          label: /* @__PURE__ */ React.createElement(Translation, null, "Repository Name"),
          required: true,
          help: "Repository name in gitlab"
        },
        /* @__PURE__ */ React.createElement(
          Input,
          {
            ...init("repo", {
              rules: [
                {
                  required: true,
                  message: "Please enter a repository name"
                }
              ]
            })
          }
        )
      ))), /* @__PURE__ */ React.createElement(If, { condition: selectType === "Github" || selectType === "Gitee" || selectType === "Gitlab" }, /* @__PURE__ */ React.createElement(Col, { span: 4, style: { padding: "8px" } }, /* @__PURE__ */ React.createElement(Form.Item, { label: /* @__PURE__ */ React.createElement(Translation, null, "Path"), help: "The addon path in the repo" }, /* @__PURE__ */ React.createElement(
        Input,
        {
          ...init("path", {
            rules: [
              {
                max: 512,
                message: "The input string is too long"
              }
            ]
          })
        }
      ))), /* @__PURE__ */ React.createElement(Col, { span: selectType === "Gitlab" ? 4 : 5, style: { padding: "8px" } }, /* @__PURE__ */ React.createElement(Form.Item, { label: /* @__PURE__ */ React.createElement(Translation, null, "Token"), help: "Github personal access token" }, /* @__PURE__ */ React.createElement(
        Input,
        {
          ...init("token", {
            rules: [
              {
                max: 128,
                message: "The input string is too long"
              }
            ]
          })
        }
      )))), /* @__PURE__ */ React.createElement(If, { condition: selectType === "OSS" }, /* @__PURE__ */ React.createElement(Col, { span: 4, style: { padding: "8px" } }, /* @__PURE__ */ React.createElement(Form.Item, { label: /* @__PURE__ */ React.createElement(Translation, null, "Bucket"), help: "The bucket path in the oss repo" }, /* @__PURE__ */ React.createElement(
        Input,
        {
          ...init("path", {
            rules: [
              {
                max: 512,
                message: "The input string is too long"
              }
            ]
          })
        }
      )))), /* @__PURE__ */ React.createElement(Col, { span: 2, style: { padding: "43px 8px 8px 8px" } }, /* @__PURE__ */ React.createElement(Button, { size: "small", type: "secondary", onClick: this.onOk, style: { height: "36px" } }, /* @__PURE__ */ React.createElement(Translation, null, "Submit"))))))
    ));
  }
}
export default RegistryManageDialog;
