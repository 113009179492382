"use strict";
import { getBackendSrv } from "../BackendService";
import { managePlugin } from "../../api/productionLink";
const cache = {};
const initializedAt = Date.now();
const pluginInfoCache = {};
function formatPluginId(pluginId) {
  return "$" + pluginId;
}
export function getPluginInfo(id) {
  const pluginId = formatPluginId(id);
  const v = pluginInfoCache[pluginId];
  if (v) {
    return Promise.resolve(v);
  }
  return getBackendSrv().get(`${managePlugin}/${id}`).then((settings) => {
    pluginInfoCache[pluginId] = settings;
    return settings;
  }).catch((err) => {
    return Promise.reject(new Error("Unknown Plugin"));
  });
}
export const clearPluginSettingsCache = (id) => {
  const pluginId = formatPluginId(id);
  if (pluginId) {
    return delete pluginInfoCache[pluginId];
  }
  return Object.keys(pluginInfoCache).forEach((key) => delete pluginInfoCache[key]);
};
export function registerPluginInCache({ path, version }) {
  if (!cache[path]) {
    cache[path] = encodeURI(version);
  }
}
export function invalidatePluginInCache(pluginId) {
  const path = `plugins/${pluginId}/module`;
  if (cache[path]) {
    delete cache[path];
  }
  clearPluginSettingsCache(pluginId);
}
export function locateWithCache(load, defaultBust = initializedAt) {
  const { address } = load;
  const path = extractPath(address);
  if (!path) {
    return `${address}?_cache=${defaultBust}`;
  }
  const version = cache[path];
  const bust = version || defaultBust;
  return `${address}?_cache=${bust}`;
}
function extractPath(address) {
  const match = /\/public\/(plugins\/.+\/module)\.js/i.exec(address);
  if (!match) {
    return;
  }
  const [_, path] = match;
  if (!path) {
    return;
  }
  return path;
}
