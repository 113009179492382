"use strict";
import { Grid, Field, Form, Select, Message, Button, Input } from "@alifd/next";
import React from "react";
import { createTrigger, updateTrigger } from "../../../../api/application";
import { detailComponentDefinition } from "../../../../api/definitions";
import { getPayloadType } from "../../../../api/payload";
import DrawerWithFooter from "../../../../components/Drawer";
import { If } from "../../../../components/If";
import { Translation } from "../../../../components/Translation";
import i18n from "../../../../i18n";
import { checkName } from "../../../../utils/common";
import { locale } from "../../../../utils/locale";
class TriggerDialog extends React.Component {
  constructor(props) {
    super(props);
    this.onDetailComponentDefinition = (value) => {
      this.setState({ loading: true });
      detailComponentDefinition({ name: value }).then((res) => {
        if (res) {
          const findImageObj = (res.uiSchema || []).find((item) => item.jsonKey === "image");
          const hasImage = findImageObj ? true : false;
          this.setState({
            hasImage,
            loading: false
          });
        }
      }).catch(() => this.setState({ loading: false }));
    };
    this.onClose = () => {
      this.props.onClose();
    };
    this.onSubmit = () => {
      const { trigger } = this.props;
      const editMode = trigger != void 0;
      this.field.validate((error, values) => {
        if (error) {
          return;
        }
        const { appName = "" } = this.props;
        const {
          name = "",
          alias = "",
          description = "",
          type = "",
          payloadType = "",
          workflowName = "",
          componentName = "",
          registry = ""
        } = values;
        const query = { appName };
        this.setState({ submitLoading: true });
        if (editMode) {
          const params = {
            alias,
            description,
            payloadType,
            workflowName,
            componentName,
            registry
          };
          updateTrigger(params, { appName, token: trigger.token }).then((res) => {
            if (res) {
              Message.success({
                duration: 4e3,
                content: "Trigger updated successfully."
              });
              this.props.onOK(res);
            }
          }).finally(() => {
            this.setState({ submitLoading: false });
          });
        } else {
          const params = {
            name,
            alias,
            description,
            type,
            payloadType,
            workflowName,
            componentName,
            registry
          };
          createTrigger(params, query).then((res) => {
            if (res) {
              Message.success({
                duration: 4e3,
                content: "Trigger created successfully."
              });
              this.props.onOK(res);
            }
          }).finally(() => {
            this.setState({ submitLoading: false });
          });
        }
      });
    };
    this.extButtonList = () => {
      const { onClose, trigger } = this.props;
      const { submitLoading } = this.state;
      const editMode = trigger != void 0;
      return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Button, { type: "secondary", onClick: onClose, className: "margin-right-10" }, /* @__PURE__ */ React.createElement(Translation, null, "Cancel")), /* @__PURE__ */ React.createElement(Button, { loading: submitLoading, type: "primary", onClick: this.onSubmit }, /* @__PURE__ */ React.createElement(Translation, null, editMode ? "Update" : "Create")));
    };
    this.changeType = (value) => {
      this.field.setValue("type", value);
      if (value === "webhook") {
        this.onGetPayloadType();
      }
    };
    this.changeComponentName = (value) => {
      this.field.setValue("componentName", value);
      let componentType = "";
      const { components } = this.props;
      components.map((c) => {
        if (c.name === value) {
          componentType = c.componentType;
          this.setState({ component: c });
        }
      });
      this.onDetailComponentDefinition(componentType);
    };
    this.state = {
      loading: false,
      payloadTypes: ["custom", "dockerHub", "ACR", "harbor", "artifactory"],
      hasImage: false
    };
    this.field = new Field(this);
  }
  componentDidMount() {
    const { trigger } = this.props;
    if (trigger) {
      this.field.setValues(trigger);
    }
    const type = this.field.getValue("type");
    if (type === "webhook") {
      this.onGetPayloadType();
    }
    const { components } = this.props;
    if (components && components.length > 0) {
      this.changeComponentName(components[0].name);
    }
  }
  onGetPayloadType() {
    getPayloadType().then((res) => {
      if (res) {
        this.setState({
          payloadTypes: res
        });
      }
    });
  }
  isShowMessage() {
    const { hasImage, component } = this.state;
    const type = this.field.getValue("type");
    const payloadType = this.field.getValue("payloadType");
    const components = ["webservice", "worker", "task"];
    const isNotInclude = component?.componentType && !components.includes(component?.componentType);
    if (isNotInclude && payloadType !== "custom" && !hasImage && type === "webhook") {
      return true;
    } else {
      return false;
    }
  }
  render() {
    const init = this.field.init;
    const FormItem = Form.Item;
    const { Row, Col } = Grid;
    const { workflows, onClose, components, trigger } = this.props;
    const editMode = trigger != void 0;
    const { payloadTypes } = this.state;
    const workflowOption = workflows?.map((workflow) => {
      return {
        label: workflow.alias ? `${workflow.alias}(${workflow.envName})` : workflow.name,
        value: workflow.name
      };
    });
    const payloadTypeOption = payloadTypes?.map((type) => {
      return {
        label: type,
        value: type
      };
    });
    const componentsOption = components?.map((component) => {
      return {
        label: component.alias ? `${component.alias}(${component.name})` : component.name,
        value: component.name
      };
    });
    return /* @__PURE__ */ React.createElement(
      DrawerWithFooter,
      {
        title: editMode ? i18n.t("Edit Trigger") : i18n.t("Add Trigger"),
        placement: "right",
        width: 800,
        onClose,
        extButtons: this.extButtonList()
      },
      /* @__PURE__ */ React.createElement(Form, { field: this.field }, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Name"), required: true }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "name",
          disabled: editMode,
          placeholder: i18n.t("Please enter the name").toString(),
          ...init("name", {
            rules: [
              {
                required: true,
                pattern: checkName,
                message: "Please enter a valid name contains only alphabetical words"
              }
            ]
          })
        }
      )))), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Alias") }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "alias",
          placeholder: i18n.t("Please enter").toString(),
          ...init("alias", {
            rules: [
              {
                minLength: 2,
                maxLength: 64,
                message: "Enter a string of 2 to 64 characters."
              }
            ]
          })
        }
      ))), /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Description") }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "description",
          placeholder: i18n.t("Please enter").toString(),
          ...init("description", {
            rules: [
              {
                maxLength: 256,
                message: "Enter a description that contains less than 256 characters."
              }
            ]
          })
        }
      )))), /* @__PURE__ */ React.createElement(Row, { wrap: true }, /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Type"), required: true }, /* @__PURE__ */ React.createElement(
        Select,
        {
          name: "type",
          locale: locale().Select,
          disabled: editMode,
          dataSource: [{ label: "On Webhook Event", value: "webhook" }],
          ...init("type", {
            initValue: "webhook",
            rules: [
              {
                required: true,
                message: i18n.t("Please select a type")
              }
            ]
          }),
          onChange: this.changeType
        }
      ))), /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(If, { condition: this.field.getValue("type") === "webhook" }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "PayloadType"), required: true }, /* @__PURE__ */ React.createElement(
        Select,
        {
          name: "payloadType",
          locale: locale().Select,
          dataSource: payloadTypeOption,
          ...init("payloadType", {
            initValue: "custom",
            rules: [
              {
                required: true,
                message: i18n.t("Please select a payloadType")
              }
            ]
          })
        }
      )))), /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(If, { condition: this.field.getValue("type") === "webhook" && this.field.getValue("payloadType") === "acr" }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Registry") }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "registry",
          locale: locale().Input,
          ...init("registry", {
            initValue: "",
            rules: [
              {
                pattern: "^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$",
                message: "This is a invalid domain"
              }
            ]
          }),
          placeholder: i18n.t("For the ACR Enterprise Edition, you should set the domain of the registry.")
        }
      ))))), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Execution workflow"), required: true }, /* @__PURE__ */ React.createElement(
        Select,
        {
          name: "workflowName",
          locale: locale().Select,
          dataSource: workflowOption,
          ...init("workflowName", {
            rules: [
              {
                required: true,
                message: i18n.t("Please select a workflow")
              }
            ]
          })
        }
      ))), /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Component"), required: true }, /* @__PURE__ */ React.createElement(
        Select,
        {
          name: "componentName",
          locale: locale().Select,
          dataSource: componentsOption,
          ...init("componentName", {
            initValue: components.length > 0 && components[0].name,
            rules: [
              {
                required: true,
                message: i18n.t("Please select a component")
              }
            ]
          }),
          onChange: this.changeComponentName
        }
      )))), /* @__PURE__ */ React.createElement(Message, { type: "warning", animation: true, visible: this.isShowMessage(), title: "Warning" }, /* @__PURE__ */ React.createElement(Translation, null, "Your component type does not support the image field, and the image update cannot be performed")))
    );
  }
}
export default TriggerDialog;
