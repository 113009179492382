"use strict";
import BasicLayout from "../layout/index";
export default [
  {
    path: "/",
    name: "basicLayout",
    component: BasicLayout,
    auth: true
  }
];
