"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Grid, Field, Form, Select, Message, Button, Input, Icon, Card, Loading } from "@alifd/next";
import { connect } from "dva";
import { Link } from "dva/router";
import React from "react";
import { BiCodeBlock, BiLaptop } from "react-icons/bi";
import { updateTrait, createTrait, getApplicationComponent } from "../../../../api/application";
import { detailTraitDefinition, getTraitDefinitions } from "../../../../api/definitions";
import DrawerWithFooter from "../../../../components/Drawer";
import { If } from "../../../../components/If";
import { Translation } from "../../../../components/Translation";
import UISchema from "../../../../components/UISchema";
import i18n from "../../../../i18n";
let TraitDialog = class extends React.Component {
  constructor(props) {
    super(props);
    this.onGetTraitDefinitions = async () => {
      const { component } = this.state;
      if (component?.definition) {
        getTraitDefinitions({ appliedWorkload: component?.definition.workload.type }).then(
          (res) => {
            if (res) {
              const podDisruptive = {};
              res.definitions?.map((def) => {
                if (def.trait?.podDisruptive) {
                  podDisruptive[def.name] = true;
                }
              });
              this.setState({
                traitDefinitions: res && res.definitions,
                podDisruptive
              });
            }
          }
        );
      }
    };
    this.onClose = () => {
      this.props.onClose();
    };
    this.onSubmit = () => {
      this.field.validate((error, values) => {
        if (error) {
          return;
        }
        const { appName = "", componentName = "", temporaryTraitList = [] } = this.props;
        const { alias = "", type = "", description = "", properties } = values;
        const query = { appName, componentName, traitType: type };
        const params = {
          alias,
          type,
          description,
          properties: JSON.stringify(properties)
        };
        const { isEditTrait, isEditComponent } = this.props;
        this.setState({ isLoading: true });
        if (isEditComponent) {
          if (isEditTrait) {
            updateTrait(params, query).then((res) => {
              if (res) {
                Message.success({
                  duration: 4e3,
                  title: i18n.t("Trait properties update success.").toString(),
                  content: i18n.t("You need to re-execute the workflow for it to take effect.").toString()
                });
                this.props.onOK();
              }
              this.setState({ isLoading: false });
            });
          } else {
            createTrait(params, query).then((res) => {
              if (res) {
                Message.success({
                  duration: 4e3,
                  title: i18n.t("Trait create success.").toString(),
                  content: i18n.t("You need to re-execute the workflow for it to take effect.").toString()
                });
                this.props.onOK();
              }
              this.setState({ isLoading: false });
            });
          }
        } else {
          const findSameType = temporaryTraitList.find((item) => item.type === type);
          if (!isEditTrait && !findSameType) {
            params.properties = JSON.parse(params.properties);
            this.props.createTemporaryTrait(params);
          } else if (!isEditTrait && findSameType) {
            return Message.warning(i18n.t("A trait with the same trait type exists, please modify it"));
          } else if (isEditTrait) {
            params.properties = JSON.parse(params.properties);
            this.props.upDateTemporaryTrait(params);
          }
        }
      });
    };
    this.onDetailsTraitDefinition = (value, callback) => {
      this.setState({ definitionLoading: true });
      detailTraitDefinition({ name: value }).then((re) => {
        if (re) {
          this.setState({ definitionDetail: re, definitionLoading: false });
          if (callback) {
            callback();
          }
        }
      }).catch(() => this.setState({ definitionLoading: false }));
    };
    this.handleTypeChange = (value) => {
      this.removeProperties();
      this.field.setValues({ type: value });
      this.onDetailsTraitDefinition(value);
      this.setAlias(value);
    };
    this.setAlias = (traitType) => {
      let alias = traitType;
      switch (traitType) {
        case "scaler":
          alias = i18n.t("Manual Scaler");
          break;
        case "http-route":
          alias = i18n.t("HTTP Route");
          break;
        case "https-route":
          alias = i18n.t("HTTPs Route");
          break;
        case "gateway":
          alias = i18n.t("HTTP Route");
          break;
        default:
      }
      this.field.setValue("alias", alias);
    };
    this.extButtonList = () => {
      const { onClose, isEditTrait } = this.props;
      const { isLoading } = this.state;
      return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Button, { type: "secondary", onClick: onClose, className: "margin-right-10" }, /* @__PURE__ */ React.createElement(Translation, null, "Cancel")), /* @__PURE__ */ React.createElement(Button, { type: "primary", onClick: this.onSubmit, loading: isLoading }, /* @__PURE__ */ React.createElement(Translation, null, isEditTrait ? i18n.t("Update") : i18n.t("Create"))));
    };
    this.showTraitTitle = () => {
      const { isEditTrait, onClose } = this.props;
      if (isEditTrait) {
        return /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(Icon, { type: "arrow-left", onClick: onClose, className: "cursor-pointer" }), /* @__PURE__ */ React.createElement("span", null, " ", i18n.t("Edit Trait"), " "));
      } else {
        return /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(Icon, { type: "arrow-left", onClick: onClose, className: "cursor-pointer" }), /* @__PURE__ */ React.createElement("span", null, " ", i18n.t("Add Trait"), " "));
      }
    };
    this.removeProperties = () => {
      this.field.remove("properties");
      this.setState({ definitionDetail: void 0 });
    };
    this.state = {
      definitionLoading: false,
      isLoading: false,
      traitDefinitions: [],
      propertiesMode: "native"
    };
    this.field = new Field(this);
    this.uiSchemaRef = React.createRef();
  }
  componentDidMount() {
    this.onGetComponentInfo(() => {
      this.onGetTraitDefinitions();
      const { isEditTrait, traitItem, appName, project, dispatch } = this.props;
      if (isEditTrait && traitItem) {
        const { alias, type, description, properties } = traitItem;
        this.field.setValues({
          alias,
          type,
          description,
          properties
        });
        if (type) {
          this.onDetailsTraitDefinition(type);
        }
      }
      dispatch({
        type: "uischema/setAppName",
        payload: appName
      });
      dispatch({
        type: "uischema/setProject",
        payload: project
      });
    });
  }
  onGetComponentInfo(callback) {
    const { appName, componentName } = this.props;
    if (appName && componentName) {
      getApplicationComponent(appName, componentName).then((res) => {
        if (res) {
          this.setState(
            {
              component: res
            },
            callback
          );
        }
      });
    }
  }
  transTraitDefinitions() {
    const { traitDefinitions } = this.state;
    return (traitDefinitions || []).map((item) => ({
      label: item.name,
      value: item.name
    }));
  }
  render() {
    const init = this.field.init;
    const FormItem = Form.Item;
    const { Row, Col } = Grid;
    const { onClose, isEditTrait } = this.props;
    const { definitionDetail, definitionLoading, podDisruptive, propertiesMode } = this.state;
    const validator = (rule, value, callback) => {
      this.uiSchemaRef.current?.validate(callback);
    };
    const traitType = this.field.getValue("type");
    return /* @__PURE__ */ React.createElement(
      DrawerWithFooter,
      {
        title: this.showTraitTitle(),
        placement: "right",
        width: 800,
        onClose,
        extButtons: this.extButtonList()
      },
      /* @__PURE__ */ React.createElement(Form, { field: this.field }, /* @__PURE__ */ React.createElement(If, { condition: podDisruptive && traitType && podDisruptive[traitType] }, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24 }, /* @__PURE__ */ React.createElement(
        Message,
        {
          type: "warning",
          title: i18n.t("This trait properties change will cause pod restart after the application deploy").toString()
        }
      )))), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(
        FormItem,
        {
          label: /* @__PURE__ */ React.createElement(Translation, null, "Type"),
          required: true,
          help: /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(Translation, null, "Get more trait type?"), /* @__PURE__ */ React.createElement(Link, { to: "/addons" }, /* @__PURE__ */ React.createElement(Translation, null, "Go to enable addon")))
        },
        /* @__PURE__ */ React.createElement(
          Select,
          {
            className: "select",
            disabled: isEditTrait ? true : false,
            placeholder: i18n.t("Please select").toString(),
            ...init(`type`, {
              rules: [
                {
                  required: true,
                  message: i18n.t("Please select")
                }
              ]
            }),
            dataSource: this.transTraitDefinitions(),
            onChange: this.handleTypeChange
          }
        )
      ))), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Alias") }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "alias",
          placeholder: i18n.t("Please enter").toString(),
          ...init("alias", {
            rules: [
              {
                minLength: 2,
                maxLength: 64,
                message: "Enter a string of 2 to 64 characters."
              }
            ]
          })
        }
      ))), /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Description") }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "description",
          placeholder: i18n.t("Please enter").toString(),
          ...init("description", {
            rules: [
              {
                maxLength: 256,
                message: i18n.t("Enter a description that contains less than 256 characters.")
              }
            ]
          })
        }
      )))), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(
        Card,
        {
          contentHeight: "auto",
          style: { marginTop: "8px" },
          title: i18n.t("Properties").toString(),
          className: "withActions",
          subTitle: /* @__PURE__ */ React.createElement(
            Button,
            {
              style: { alignItems: "center", display: "flex" },
              onClick: () => {
                if (propertiesMode === "native") {
                  this.setState({ propertiesMode: "code" });
                } else {
                  this.setState({ propertiesMode: "native" });
                }
              }
            },
            propertiesMode === "native" && /* @__PURE__ */ React.createElement(BiCodeBlock, { size: 14, title: i18n.t("Switch to the coding mode") }),
            propertiesMode === "code" && /* @__PURE__ */ React.createElement(BiLaptop, { size: 14, title: i18n.t("Switch to the native mode") })
          )
        },
        /* @__PURE__ */ React.createElement(Loading, { visible: definitionLoading }, /* @__PURE__ */ React.createElement(If, { condition: definitionDetail }, /* @__PURE__ */ React.createElement(FormItem, { required: true }, /* @__PURE__ */ React.createElement(
          UISchema,
          {
            key: traitType,
            ...init(`properties`, {
              rules: [
                {
                  validator,
                  message: i18n.t("Please check trait deploy properties")
                }
              ]
            }),
            enableCodeEdit: propertiesMode === "code",
            uiSchema: definitionDetail && definitionDetail.uiSchema,
            definition: {
              type: "trait",
              name: definitionDetail?.name || "",
              description: definitionDetail?.description || ""
            },
            ref: this.uiSchemaRef,
            mode: this.props.isEditTrait ? "edit" : "new"
          }
        ))), /* @__PURE__ */ React.createElement(If, { condition: !definitionDetail }, /* @__PURE__ */ React.createElement(Message, { type: "notice" }, /* @__PURE__ */ React.createElement(Translation, null, "Please select trait type first."))))
      ))))
    );
  }
};
TraitDialog = __decorateClass([
  connect()
], TraitDialog);
export default TraitDialog;
