"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Table, Button, Message } from "@alifd/next";
import { connect } from "dva";
import { Link } from "dva/router";
import _ from "lodash";
import React, { Component, Fragment } from "react";
import { getDefinitionsList, updateDefinitionStatus } from "../../api/definitions";
import Permission from "../../components/Permission";
import { Translation } from "../../components/Translation";
import { locale } from "../../utils/locale";
import { getMatchParamObj } from "../../utils/utils";
import SelectSearch from "./components/SelectSearch";
import "./index.less";
import { checkPermission } from "../../utils/permission";
let Definitions = class extends Component {
  constructor(props) {
    super(props);
    this.getDefinitionType = () => {
      return getMatchParamObj(this.props.match, "definitionType");
    };
    this.showStatus = (record) => {
      if (record.status === "enable") {
        return /* @__PURE__ */ React.createElement(Translation, null, "Disable");
      } else {
        return /* @__PURE__ */ React.createElement(Translation, null, "Enable");
      }
    };
    this.onChangeStatus = (record) => {
      const { definitionType } = this.state;
      const { status, name } = record;
      if (status === "enable") {
        updateDefinitionStatus({ name, hiddenInUI: true, type: definitionType }).then((res) => {
          if (res) {
            Message.success(/* @__PURE__ */ React.createElement(Translation, null, "Update definition status success"));
            this.lisDefinitions();
          }
        }).catch();
      } else {
        updateDefinitionStatus({ name, hiddenInUI: false, type: definitionType }).then((res) => {
          if (res) {
            Message.success(/* @__PURE__ */ React.createElement(Translation, null, "Update definition status success"));
            this.lisDefinitions();
          }
        }).catch();
      }
    };
    this.handleChangName = (value) => {
      const { list } = this.state;
      const newList = list.filter((item) => {
        return item.name && item.name.search(value) != -1;
      });
      this.setState({
        searchValue: value,
        searchList: newList
      });
    };
    this.getDataSource = () => {
      const { list, searchValue, searchList = [] } = this.state;
      if (!searchValue && searchList.length === 0) {
        return list;
      } else {
        return searchList;
      }
    };
    this.state = {
      definitionType: this.getDefinitionType(),
      list: [],
      isLoading: false,
      searchValue: "",
      searchList: []
    };
  }
  componentDidMount() {
    this.lisDefinitions();
  }
  componentWillReceiveProps(nextProps) {
    const nextPropsParams = nextProps.match.params || {};
    if (nextPropsParams.definitionType !== this.state.definitionType) {
      this.setState(
        {
          definitionType: nextPropsParams.definitionType
        },
        () => {
          this.lisDefinitions();
        }
      );
    }
  }
  lisDefinitions() {
    const { userInfo } = this.props;
    const { definitionType } = this.state;
    if (!definitionType) {
      return;
    }
    if (!checkPermission({ resource: "definition:*", action: "list" }, "", userInfo)) {
      return;
    }
    const params = {
      definitionType,
      queryAll: true
    };
    this.setState({ isLoading: true });
    getDefinitionsList(params).then((res) => {
      if (res) {
        this.setState({
          list: res && res.definitions || [],
          searchList: [],
          searchValue: ""
        });
      } else {
        this.setState({
          list: [],
          searchList: [],
          searchValue: ""
        });
      }
    }).finally(() => {
      this.setState({ isLoading: false });
    });
  }
  render() {
    const { definitionType, isLoading, searchValue } = this.state;
    const columns = [
      {
        key: "name",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Name"),
        dataIndex: "name",
        cell: (v) => {
          return /* @__PURE__ */ React.createElement(Link, { to: `/definitions/${definitionType}/${v}/ui-schema` }, v);
        }
      },
      {
        key: "status",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Status"),
        dataIndex: "status",
        cell: (v) => {
          const enumStatusList = [
            { name: "enable", color: "enableStatus", status: "Enabled" },
            { name: "disable", color: "disableStatus", status: "Disabled" }
          ];
          const findStatus = _.find(enumStatusList, (item) => {
            return item.name === v;
          });
          const colorClass = findStatus && findStatus.color || "";
          return /* @__PURE__ */ React.createElement("span", { className: `${colorClass}` }, findStatus && findStatus.status);
        }
      },
      // {
      //   key: 'createTime',
      //   title: <Translation>Create Time</Translation>,
      //   dataIndex: 'createdTime',
      //   cell: (v: string) => {
      //     return <span>{momentDate(v)}</span>;
      //   },
      // },
      {
        key: "operation",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Actions"),
        dataIndex: "operation",
        cell: (v, i, record) => {
          return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement(
            Permission,
            {
              request: {
                resource: `definition:${record.name}`,
                action: "update"
              },
              project: ""
            },
            /* @__PURE__ */ React.createElement(
              Button,
              {
                text: true,
                size: "medium",
                component: "a",
                onClick: () => {
                  this.onChangeStatus(record);
                }
              },
              this.showStatus(record)
            )
          ));
        }
      }
    ];
    const { Column } = Table;
    return /* @__PURE__ */ React.createElement("div", { className: "definitions-list-content" }, /* @__PURE__ */ React.createElement(
      SelectSearch,
      {
        searchValue,
        handleChangName: (value) => {
          this.handleChangName(value);
        }
      }
    ), /* @__PURE__ */ React.createElement(
      Table,
      {
        id: "definitionTable",
        locale: locale().Table,
        dataSource: this.getDataSource(),
        loading: isLoading,
        fixedHeader: true,
        maxBodyHeight: "calc(100vh - 100px)",
        className: "margin-16"
      },
      columns.map((col, key) => /* @__PURE__ */ React.createElement(Column, { ...col, key, align: "left" }))
    ));
  }
};
Definitions = __decorateClass([
  connect((store) => {
    return { ...store.definitions, ...store.user };
  })
], Definitions);
export default Definitions;
