"use strict";
import {
  authenticationAdminConfigured,
  authenticationDexConfig,
  authenticationInitAdmin,
  authenticationLogin,
  authenticationLoginType,
  authenticationSystemInfo,
  authenticationUserInfo
} from "./productionLink";
import { get, post, put } from "./request";
export function loginSSO(params) {
  const url = authenticationLogin;
  return post(url, { ...params }, true).then((res) => res);
}
export function loginLocal(params) {
  const url = authenticationLogin;
  return post(url, { ...params }, true).then((res) => res);
}
export function getDexConfig() {
  const url = authenticationDexConfig;
  return get(url, {}).then((res) => res);
}
export function getLoginType() {
  const url = authenticationLoginType;
  return get(url, {}).then((res) => res);
}
export function updateSystemInfo(params) {
  const url = authenticationSystemInfo;
  return put(url, params).then((res) => res);
}
export function getLoginUserInfo() {
  const url = authenticationUserInfo;
  return get(url, {}).then((res) => res);
}
export function isAdminConfigured() {
  const url = authenticationAdminConfigured;
  return get(url, {}).then((res) => res);
}
export function initAdmin(params) {
  const url = authenticationInitAdmin;
  return put(url, params).then((res) => res);
}
