"use strict";
import React from "react";
import Draggable from "react-draggable";
import "./index.less";
import { Step } from "./components/step";
class PipelineGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stepWidth: 260,
      stepInterval: 56
    };
  }
  renderConnector(index, total, from, to) {
    const { stepInterval, stepWidth } = this.state;
    const startPoint = stepWidth + (index - 1) * (stepWidth + stepInterval);
    const endPoint = startPoint + stepInterval;
    const width = (stepInterval + stepWidth) * total;
    return /* @__PURE__ */ React.createElement("svg", { key: from + to, className: "workflow-connectors", width, height: 300 }, /* @__PURE__ */ React.createElement(
      "path",
      {
        className: "workflow-connector",
        "data-from": "step-" + from,
        "data-to": "step-" + to,
        fill: "none",
        d: `M ${startPoint} 38 H ${endPoint}`
      }
    ));
  }
  render() {
    const { steps, zoom, name } = this.props;
    return /* @__PURE__ */ React.createElement(Draggable, null, /* @__PURE__ */ React.createElement(
      "div",
      {
        className: "workflow-graph",
        style: {
          transform: `scale(${zoom})`
        }
      },
      steps && steps.length > 1 && steps.map((step, i) => {
        if (i < steps.length - 1) {
          return this.renderConnector(i + 1, steps.length, step.id, steps[i + 1].id);
        }
        return;
      }),
      steps && steps.map((step, i) => {
        return /* @__PURE__ */ React.createElement(
          Step,
          {
            key: name + step.name,
            probeState: this.state,
            step,
            group: step.type == "step-group",
            output: i < steps.length - 1,
            input: i !== 0,
            onNodeClick: this.props.onNodeClick
          }
        );
      })
    ));
  }
}
export default PipelineGraph;
