"use strict";
import { Input, Select, Button, Message, Dialog, Form, Field, Grid } from "@alifd/next";
import React from "react";
import { createClusterNamespace } from "../../../../api/cluster";
import { If } from "../../../../components/If";
import { Translation } from "../../../../components/Translation";
import { locale } from "../../../../utils/locale";
class Namespace extends React.Component {
  constructor(props) {
    super(props);
    this.openNamespaceInput = () => {
      const { createNamespaceDialog } = this.props;
      if (createNamespaceDialog) {
        this.field.setValue("namespace", "");
        this.setState({
          visible: true
        });
      }
    };
    this.createNamespace = () => {
      const { cluster, loadNamespaces, targetField } = this.props;
      if (!cluster) {
        Message.warning("Please select a cluster first");
        return;
      }
      const { createNamespace } = this.state;
      const namespace = createNamespace || this.field.getValue("namespace");
      this.setState({ loading: true });
      if (cluster && namespace) {
        createClusterNamespace({ cluster, namespace }).then((re) => {
          if (re) {
            Message.success("Namespace created successfully");
            loadNamespaces(cluster);
          }
          targetField.setValue("runtimeNamespace", namespace);
          this.setState({
            loading: false,
            visible: false
          });
        });
      }
    };
    this.onClose = () => {
      this.setState({
        visible: false
      });
    };
    this.state = {
      inputNamespaceParam: "",
      loading: false,
      visible: false
    };
    this.field = new Field(this);
  }
  render() {
    const { disableNew, onChange, namespaces, value, disabled } = this.props;
    const { visible } = this.state;
    const { Col, Row } = Grid;
    const init = this.field.init;
    const formItemLayout = {
      labelCol: {
        fixedSpan: 10
      },
      wrapperCol: {
        span: 14
      }
    };
    return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", { className: "cluster-container" }, /* @__PURE__ */ React.createElement(
      Select,
      {
        locale: locale().Select,
        className: "cluster-params-input",
        mode: "single",
        disabled,
        dataSource: namespaces,
        onChange,
        placeholder: "",
        value
      }
    ), /* @__PURE__ */ React.createElement(If, { condition: !disableNew }, /* @__PURE__ */ React.createElement(
      Button,
      {
        className: "cluster-option-btn",
        type: "secondary",
        disabled,
        onClick: this.openNamespaceInput
      },
      /* @__PURE__ */ React.createElement(Translation, null, "New")
    ))), /* @__PURE__ */ React.createElement(
      Dialog,
      {
        v2: true,
        locale: locale().Dialog,
        title: /* @__PURE__ */ React.createElement(Translation, null, "Create Namespace"),
        autoFocus: true,
        overflowScroll: true,
        visible,
        onOk: this.createNamespace,
        onCancel: this.onClose,
        onClose: this.onClose,
        footerActions: ["ok"]
      },
      /* @__PURE__ */ React.createElement(Form, { field: this.field, ...formItemLayout }, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(Form.Item, { label: /* @__PURE__ */ React.createElement(Translation, null, "namespace"), required: true }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "namespace",
          placeholder: "Please input the name of namespace",
          ...init("namespace", {
            rules: [
              {
                required: true,
                message: "Please input the name of namespace"
              }
            ]
          })
        }
      )))))
    ));
  }
}
export default Namespace;
