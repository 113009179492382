"use strict";
import { Grid, Form, Input, Field, Button, Message, Select } from "@alifd/next";
import React from "react";
import { createRole, updateRole } from "../../../../api/roles";
import DrawerWithFooter from "../../../../components/Drawer";
import { If } from "../../../../components/If";
import { Translation } from "../../../../components/Translation";
import i18n from "../../../../i18n";
import { checkName } from "../../../../utils/common";
import { locale } from "../../../../utils/locale";
import { getSelectLabel } from "../../../../utils/utils";
class RolesDialog extends React.Component {
  constructor(props) {
    super(props);
    this.onCloseCreate = () => {
      this.props.onCloseCreate();
    };
    this.onCreate = () => {
      this.field.validate((error, values) => {
        if (error) {
          return;
        }
        const { isEditRole } = this.props;
        const { name, alias, permissions } = values;
        const param = {
          name,
          alias,
          permissions
        };
        this.setState({ loading: true });
        if (isEditRole) {
          updateRole(param).then((res) => {
            this.setState({ loading: false });
            if (res) {
              Message.success(/* @__PURE__ */ React.createElement(Translation, null, "Update role success"));
              this.props.onCreate();
            }
          }).catch(() => {
            this.setState({ loading: false });
          });
        } else {
          createRole(param).then((res) => {
            this.setState({ loading: false });
            if (res) {
              Message.success(/* @__PURE__ */ React.createElement(Translation, null, "Create role success"));
              this.props.onCreate();
            }
          }).catch(() => {
            this.setState({ loading: false });
          });
        }
      });
    };
    this.getTitle = () => {
      const { isEditRole } = this.props;
      if (isEditRole) {
        return /* @__PURE__ */ React.createElement(Translation, null, "Edit Role");
      } else {
        return /* @__PURE__ */ React.createElement(Translation, null, "New Role");
      }
    };
    this.field = new Field(this);
    this.state = {
      loading: false
    };
  }
  componentDidMount() {
    const { isEditRole, editRoleItem } = this.props;
    if (isEditRole && editRoleItem) {
      const { name, alias, permissions } = editRoleItem;
      const permissionNames = permissions?.map((item) => {
        return item.name;
      });
      this.field.setValues({
        name,
        alias,
        permissions: permissionNames
      });
    }
  }
  render() {
    const init = this.field.init;
    const { Row, Col } = Grid;
    const FormItem = Form.Item;
    const { loading } = this.state;
    const formItemLayout = {
      labelCol: {
        fixedSpan: 6
      },
      wrapperCol: {
        span: 20
      }
    };
    const { isEditRole, permissions } = this.props;
    const buttons = [
      /* @__PURE__ */ React.createElement(Button, { type: "secondary", onClick: this.onCloseCreate, style: { marginRight: "16px" } }, /* @__PURE__ */ React.createElement(Translation, null, "Cancel")),
      /* @__PURE__ */ React.createElement(Button, { type: "primary", onClick: this.onCreate, loading }, /* @__PURE__ */ React.createElement(If, { condition: isEditRole }, /* @__PURE__ */ React.createElement(Translation, null, "Update")), /* @__PURE__ */ React.createElement(If, { condition: !isEditRole }, /* @__PURE__ */ React.createElement(Translation, null, "Create")))
    ];
    const permPoliciesList = getSelectLabel(permissions);
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
      DrawerWithFooter,
      {
        title: this.getTitle(),
        placement: "right",
        width: 800,
        onClose: this.onCloseCreate,
        extButtons: buttons
      },
      /* @__PURE__ */ React.createElement(Form, { ...formItemLayout, field: this.field }, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Name"), labelTextAlign: "left", required: true }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "name",
          placeholder: i18n.t("Please enter").toString(),
          maxLength: 32,
          disabled: isEditRole ? true : false,
          ...init("name", {
            rules: [
              {
                required: true,
                pattern: checkName,
                message: /* @__PURE__ */ React.createElement(Translation, null, "Please enter a roles name")
              }
            ]
          })
        }
      ))), /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Alias") }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "alias",
          placeholder: i18n.t("Please enter").toString(),
          ...init("alias", {
            rules: [
              {
                minLength: 2,
                maxLength: 64,
                message: "Enter a string of 2 to 64 characters."
              }
            ]
          })
        }
      )))), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Permissions"), required: true }, /* @__PURE__ */ React.createElement(
        Select,
        {
          ...init(`permissions`, {
            rules: [
              {
                required: true,
                message: i18n.t("Please select at last one permission")
              }
            ]
          }),
          locale: locale().Select,
          mode: "tag",
          dataSource: permPoliciesList
        }
      )))))
    ));
  }
}
export default RolesDialog;
