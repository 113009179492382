"use strict";
import { Grid, Form, Input, Field, Button, Message, Select } from "@alifd/next";
import React from "react";
import { updateProject } from "../../../../api/project";
import DrawerWithFooter from "../../../../components/Drawer";
import { Translation } from "../../../../components/Translation";
import i18n from "../../../../i18n";
import { checkName } from "../../../../utils/common";
class GeneralDialog extends React.Component {
  constructor(props) {
    super(props);
    this.onCloseGeneral = () => {
      this.props.onCloseGeneral();
    };
    this.onUpdateGeneral = () => {
      this.field.validate((error, values) => {
        if (error) {
          return;
        }
        const { isEditGeneral } = this.props;
        const { name, alias, description, owner } = values;
        const param = {
          name,
          alias,
          owner,
          description
        };
        this.setState({ loading: true });
        if (isEditGeneral) {
          updateProject(param).then((res) => {
            this.setState({ loading: false });
            if (res) {
              Message.success(/* @__PURE__ */ React.createElement(Translation, null, "Project updated successfully"));
              this.props.onUpdateGeneral();
            }
          }).catch(() => {
            this.setState({ loading: false });
          });
        }
      });
    };
    this.getTitle = () => {
      return /* @__PURE__ */ React.createElement(Translation, null, "Edit General");
    };
    this.field = new Field(this);
    this.state = {
      loading: false
    };
  }
  componentDidMount() {
    const { isEditGeneral, editGeneral } = this.props;
    if (isEditGeneral && editGeneral) {
      const { name, alias, owner = { name: "" }, description } = editGeneral;
      this.field.setValues({
        name,
        alias,
        owner: owner.name,
        description
      });
    }
  }
  render() {
    const init = this.field.init;
    const { Row, Col } = Grid;
    const FormItem = Form.Item;
    const { isEditGeneral, userList } = this.props;
    const { loading } = this.state;
    const formItemLayout = {
      labelCol: {
        fixedSpan: 6
      },
      wrapperCol: {
        span: 20
      }
    };
    const buttons = [
      /* @__PURE__ */ React.createElement(Button, { type: "secondary", onClick: this.onCloseGeneral, style: { marginRight: "16px" } }, /* @__PURE__ */ React.createElement(Translation, null, "Cancel")),
      /* @__PURE__ */ React.createElement(Button, { type: "primary", onClick: this.onUpdateGeneral, loading }, /* @__PURE__ */ React.createElement(Translation, null, "Update"))
    ];
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
      DrawerWithFooter,
      {
        title: this.getTitle(),
        placement: "right",
        width: 800,
        onClose: this.onCloseGeneral,
        extButtons: buttons
      },
      /* @__PURE__ */ React.createElement(Form, { ...formItemLayout, field: this.field }, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Name"), labelTextAlign: "left", required: true }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "name",
          disabled: isEditGeneral ? true : false,
          placeholder: i18n.t("Please enter").toString(),
          maxLength: 32,
          ...init("name", {
            rules: [
              {
                required: true,
                pattern: checkName,
                message: /* @__PURE__ */ React.createElement(Translation, null, "Please enter a project name")
              }
            ]
          })
        }
      ))), /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Alias") }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "alias",
          placeholder: i18n.t("Please enter").toString(),
          ...init("alias", {
            rules: [
              {
                minLength: 2,
                maxLength: 64,
                message: "Enter a string of 2 to 64 characters."
              }
            ]
          })
        }
      )))), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Owner") }, /* @__PURE__ */ React.createElement(
        Select,
        {
          name: "owner",
          hasClear: true,
          showSearch: true,
          placeholder: i18n.t("Please select a owner for this project").toString(),
          filterLocal: true,
          dataSource: userList,
          style: { width: "100%" },
          ...init("owner", {
            rules: [
              {
                required: true,
                pattern: checkName,
                message: /* @__PURE__ */ React.createElement(Translation, null, "Please select a owner for this project")
              }
            ]
          })
        }
      )))), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Description") }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "description",
          placeholder: i18n.t("Please input a description for the project").toString(),
          ...init("description")
        }
      )))))
    ));
  }
}
export default GeneralDialog;
