"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Grid, Button, Dialog, Message, Balloon } from "@alifd/next";
import classNames from "classnames";
import { connect } from "dva";
import { routerRedux } from "dva/router";
import React, { Component } from "react";
import { HiOutlineRefresh } from "react-icons/hi";
import { TiWarningOutline } from "react-icons/ti";
import { resumePipelineRun, runPipeline, stopPipelineRun, terminatePipelineRun } from "../../../../api/pipeline";
import { If } from "../../../../components/If";
import Permission from "../../../../components/Permission";
import { Translation } from "../../../../components/Translation";
import i18n from "../../../../i18n";
import { momentDate, timeDiff } from "../../../../utils/common";
import { locale } from "../../../../utils/locale";
import RunStatusIcon from "../RunStatusIcon";
import { Breadcrumb } from "../../../../components/Breadcrumb";
const { Row, Col } = Grid;
let Header = class extends Component {
  constructor(props) {
    super(props);
    this.onRerun = () => {
      Dialog.confirm({
        type: "alert",
        content: i18n.t("Are you sure to rerun this Pipeline?").toString(),
        locale: locale().Dialog,
        onOk: () => {
          const { pipeline, runBase } = this.props;
          if (runBase && pipeline) {
            this.setState({ reRunLoading: true });
            runPipeline(runBase?.project.name, pipeline?.name, runBase.contextName).then((res) => {
              if (res && res.pipelineRunName && this.props.dispatch) {
                this.props.dispatch(
                  routerRedux.push(
                    `/projects/${pipeline.project.name}/pipelines/${pipeline.name}/runs/${res.pipelineRunName}`
                  )
                );
              }
            }).finally(() => {
              this.setState({ reRunLoading: false });
            });
          }
        }
      });
    };
    this.onStop = () => {
      Dialog.confirm({
        type: "alert",
        content: "Are you sure to stop this Pipeline?",
        locale: locale().Dialog,
        onOk: () => {
          const { pipeline, runBase } = this.props;
          if (runBase && pipeline) {
            this.setState({ stopLoading: true });
            stopPipelineRun({
              pipelineName: pipeline?.name,
              projectName: runBase?.project.name,
              runName: runBase?.pipelineRunName
            }).then((res) => {
              if (res) {
                Message.success("Pipeline stopped successfully.");
              }
            }).finally(() => {
              this.setState({ stopLoading: false });
            });
          }
        }
      });
    };
    this.onTerminatePipelineRun = () => {
      const { pipeline, runBase, loadRunStatus } = this.props;
      if (!pipeline || !runBase) {
        return;
      }
      const params = {
        projectName: pipeline.project.name,
        pipelineName: runBase.pipelineName,
        runName: runBase?.pipelineRunName
      };
      this.setState({ terminateLoading: true });
      terminatePipelineRun(params).then((re) => {
        if (re) {
          Message.success(i18n.t("Pipeline terminated successfully"));
          loadRunStatus();
        }
      }).finally(() => {
        this.setState({ terminateLoading: false });
      });
    };
    this.onResumePipelineRun = () => {
      const { pipeline, runBase, loadRunStatus } = this.props;
      if (!pipeline || !runBase) {
        return;
      }
      const params = {
        projectName: pipeline.project.name,
        pipelineName: runBase.pipelineName,
        runName: runBase?.pipelineRunName
      };
      this.setState({ resumeLoading: true });
      resumePipelineRun(params).then((re) => {
        if (re) {
          Message.success(i18n.t("Pipeline resumed successfully"));
          loadRunStatus();
        }
      }).finally(() => {
        this.setState({ resumeLoading: false });
      });
    };
    this.state = {};
  }
  render() {
    const { pipeline, runBase, runStatus, statusLoading } = this.props;
    const projectName = pipeline && pipeline.project?.name || "";
    const runCondition = runStatus?.conditions?.filter((con) => con.type === "WorkflowRun");
    const { reRunLoading, stopLoading, resumeLoading, terminateLoading } = this.state;
    const message = runStatus?.message;
    return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Row, { style: { marginBottom: "16px" } }, /* @__PURE__ */ React.createElement(Col, { span: 6 }, /* @__PURE__ */ React.createElement(
      Breadcrumb,
      {
        items: [
          {
            to: "/projects/" + projectName + "/pipelines",
            title: projectName
          },
          {
            to: `/projects/${projectName}/pipelines/${pipeline?.name}/studio`,
            title: pipeline && (pipeline.alias || pipeline.name) || ""
          },
          { title: runBase?.pipelineRunName || "" }
        ]
      }
    )), /* @__PURE__ */ React.createElement(Col, { span: 18, className: "flexright", style: { padding: "0 16px" } }, /* @__PURE__ */ React.createElement(
      Permission,
      {
        request: {
          resource: `project:${projectName}/pipeline:${pipeline && pipeline.name}`,
          action: "run"
        },
        project: projectName
      },
      /* @__PURE__ */ React.createElement(
        Button,
        {
          loading: reRunLoading,
          disabled: runStatus?.status != "failed" && runStatus?.status != "succeeded" && runStatus?.status != "terminated",
          style: { marginLeft: "16px" },
          type: "primary",
          onClick: () => this.onRerun()
        },
        /* @__PURE__ */ React.createElement(Translation, null, "Rerun")
      )
    ), /* @__PURE__ */ React.createElement(If, { condition: runStatus?.status == "executing" || runStatus?.status == "suspending" }, /* @__PURE__ */ React.createElement(
      Permission,
      {
        request: {
          resource: `project:${projectName}/pipeline:${pipeline && pipeline.name}/pipelineRun:${runBase?.pipelineRunName}`,
          action: "run"
        },
        project: projectName
      },
      /* @__PURE__ */ React.createElement(
        Button,
        {
          loading: stopLoading,
          style: { marginLeft: "16px" },
          type: "primary",
          onClick: () => this.onStop()
        },
        /* @__PURE__ */ React.createElement(Translation, null, "Stop")
      )
    )))), /* @__PURE__ */ React.createElement(Row, { className: "description", wrap: true }, /* @__PURE__ */ React.createElement(Col, { xl: 16, xs: 24 }, /* @__PURE__ */ React.createElement("div", { className: "name_metadata" }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", { className: "name" }, runBase?.pipelineRunName), /* @__PURE__ */ React.createElement("div", { className: "metadata" }, /* @__PURE__ */ React.createElement("div", { className: "start_at" }, /* @__PURE__ */ React.createElement("span", { className: "label_key" }, "Started at:"), /* @__PURE__ */ React.createElement("time", { className: "label_value" }, momentDate(runStatus?.startTime))), /* @__PURE__ */ React.createElement("div", { className: "duration_time" }, /* @__PURE__ */ React.createElement("span", { className: "label_key" }, "Duration:"), /* @__PURE__ */ React.createElement("time", { className: "label_value" }, timeDiff(runStatus?.startTime, runStatus?.endTime))), /* @__PURE__ */ React.createElement("div", { className: "mode" }, /* @__PURE__ */ React.createElement("span", { className: "label_key" }, "Mode:"), /* @__PURE__ */ React.createElement("time", { className: "label_value" }, runStatus?.mode?.steps || "StepByStep")), /* @__PURE__ */ React.createElement("div", { className: "mode" }, /* @__PURE__ */ React.createElement("span", { className: "label_key" }, "Sub Step Mode:"), /* @__PURE__ */ React.createElement("time", { className: "label_value" }, runStatus?.mode?.subSteps || "DAG")), /* @__PURE__ */ React.createElement(
      Balloon,
      {
        trigger: /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("span", { className: "label_key" }, "Context:"), /* @__PURE__ */ React.createElement("time", { className: "label_value" }, runBase?.contextName || "-"))
      },
      runBase?.contextValues?.map((item) => {
        return /* @__PURE__ */ React.createElement("div", { key: item.key }, /* @__PURE__ */ React.createElement("span", { className: "label_key" }, item.key, "="), /* @__PURE__ */ React.createElement("span", { className: "label_value" }, item.value));
      })
    ))), /* @__PURE__ */ React.createElement("div", { className: "flexright" }, /* @__PURE__ */ React.createElement(Button, { type: "secondary", loading: statusLoading, onClick: this.props.loadRunStatus }, /* @__PURE__ */ React.createElement(HiOutlineRefresh, null))))), /* @__PURE__ */ React.createElement(Col, { xl: 8, xs: 24 }, /* @__PURE__ */ React.createElement(If, { condition: !runCondition || runCondition.length == 0 || runCondition[0].status == "True" }, /* @__PURE__ */ React.createElement(
      "div",
      {
        className: classNames(
          "status",
          { warning: runStatus?.status == "failed" || runStatus?.status === "terminated" },
          { success: runStatus?.status == "succeeded" }
        )
      },
      /* @__PURE__ */ React.createElement(RunStatusIcon, { status: runStatus?.status }),
      /* @__PURE__ */ React.createElement(If, { condition: message }, /* @__PURE__ */ React.createElement("div", { className: "message" }, /* @__PURE__ */ React.createElement("div", { className: "summary" }, runStatus?.status == "failed" ? "Error Summary" : "Summary"), /* @__PURE__ */ React.createElement("p", { className: "text" }, message))),
      /* @__PURE__ */ React.createElement(If, { condition: runStatus?.status === "suspending" }, /* @__PURE__ */ React.createElement("div", { className: classNames("suspend-actions") }, /* @__PURE__ */ React.createElement("div", { className: "desc" }, "This Pipeline need you approve."), /* @__PURE__ */ React.createElement(Button.Group, null, /* @__PURE__ */ React.createElement(
        Button,
        {
          type: "secondary",
          size: "small",
          loading: terminateLoading,
          className: "margin-top-5 margin-left-8",
          title: "Terminate this workflow",
          onClick: this.onTerminatePipelineRun
        },
        /* @__PURE__ */ React.createElement(Translation, null, "Terminate")
      ), /* @__PURE__ */ React.createElement(
        Button,
        {
          type: "primary",
          size: "small",
          loading: resumeLoading,
          className: "margin-top-5 margin-left-8",
          title: "Approve and continue this workflow",
          onClick: this.onResumePipelineRun
        },
        /* @__PURE__ */ React.createElement(Translation, null, "Resume")
      ))))
    )), /* @__PURE__ */ React.createElement(If, { condition: runCondition && runCondition[0].status == "False" }, /* @__PURE__ */ React.createElement("div", { className: classNames("status", { warning: runStatus?.status == "failed" }) }, /* @__PURE__ */ React.createElement("div", { className: "icon" }, /* @__PURE__ */ React.createElement(TiWarningOutline, null), /* @__PURE__ */ React.createElement("span", null, (runStatus?.status || "pending").toUpperCase())), /* @__PURE__ */ React.createElement(If, { condition: runCondition && runCondition[0].message }, /* @__PURE__ */ React.createElement("div", { className: "message" }, /* @__PURE__ */ React.createElement("div", { className: "summary" }, "Error Summary"), /* @__PURE__ */ React.createElement("p", { className: "text" }, runCondition && runCondition[0].message))))))));
  }
};
Header = __decorateClass([
  connect((store) => {
    return { ...store.user };
  })
], Header);
export default Header;
