"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Loading, Select } from "@alifd/next";
import { connect } from "dva";
import React, { Component } from "react";
import { getChartVersions } from "../../api/repository";
import i18n from "../../i18n";
import { locale } from "../../utils/locale";
let HelmChartVersionSelect = class extends Component {
  constructor(props) {
    super(props);
    this.loadChartVersions = () => {
      const { project } = this.props;
      const { helm, repo } = this.props;
      if (helm?.url && helm.chart && !this.state.loading && (helm.url != this.state.helm?.url || helm.chart != this.state.helm.chart)) {
        this.setState({ loading: true, helm });
        if (this.state.helm) {
          this.props.onChange("");
        }
        getChartVersions({
          url: helm.url,
          repoType: helm.repoType,
          chart: helm.chart,
          secretName: repo?.secretName,
          project
        }).then((re) => {
          if (re) {
            this.setState({ versions: re.versions || [], loading: false, helm });
          }
        });
      }
    };
    this.onSearch = (value) => {
      this.setState({ inputChartVersion: value });
    };
    this.state = {
      loading: false,
      inputChartVersion: ""
    };
  }
  componentDidMount() {
    this.loadChartVersions();
  }
  render() {
    const { disabled, value, onChange, helm } = this.props;
    const { versions, loading, helm: stateHelm, inputChartVersion } = this.state;
    if (helm?.url != stateHelm?.url || helm?.chart != stateHelm?.chart) {
      this.loadChartVersions();
    }
    const dataSource = versions?.map((version) => {
      return {
        label: version.version,
        value: version.version
      };
    }) || [];
    if (inputChartVersion) {
      dataSource.unshift({ label: inputChartVersion, value: inputChartVersion });
    }
    return /* @__PURE__ */ React.createElement(Loading, { visible: loading, style: { width: "100%" } }, /* @__PURE__ */ React.createElement(
      Select,
      {
        placeholder: i18n.t("Please select or input a chart version"),
        onChange,
        onSearch: this.onSearch,
        showSearch: true,
        followTrigger: true,
        disabled,
        value,
        dataSource,
        locale: locale().Select
      }
    ));
  }
};
HelmChartVersionSelect = __decorateClass([
  connect((store) => {
    return { ...store.uischema };
  })
], HelmChartVersionSelect);
export default HelmChartVersionSelect;
