"use strict";
import { Button } from "@alifd/next";
import React from "react";
import { Translation } from "../Translation";
import "./index.less";
import { If } from "../If";
export const ListTitle = function(props) {
  const { title, subTitle, extButtons, addButtonTitle, addButtonClick, buttonSize } = props;
  return /* @__PURE__ */ React.createElement("div", { className: "title-wrapper" }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("span", { className: "title font-size-20" }, /* @__PURE__ */ React.createElement(Translation, null, title)), subTitle && /* @__PURE__ */ React.createElement("span", { className: "subTitle font-size-14" }, /* @__PURE__ */ React.createElement(Translation, null, subTitle))), /* @__PURE__ */ React.createElement("div", { className: "float-right" }, extButtons && extButtons.map((item) => {
    return item;
  }), /* @__PURE__ */ React.createElement(If, { condition: addButtonTitle }, /* @__PURE__ */ React.createElement(Button, { size: buttonSize ? buttonSize : "medium", type: "primary", onClick: addButtonClick }, /* @__PURE__ */ React.createElement(Translation, null, addButtonTitle ? addButtonTitle : "")))));
};
