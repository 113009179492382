"use strict";
import { Button, Dialog, Message } from "@alifd/next";
import * as React from "react";
import "./index.less";
import { DiffEditor } from "../DiffEditor";
import { If } from "../If";
import { Translation } from "../Translation";
export const ApplicationDiff = (props) => {
  const { baseName, targetName, compare, rollback2Revision } = props;
  const container = "revision" + baseName + targetName;
  return /* @__PURE__ */ React.createElement(
    Dialog,
    {
      v2: true,
      className: "application-diff",
      width: "800px",
      overflowScroll: true,
      footer: /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(If, { condition: compare.isDiff && rollback2Revision }, /* @__PURE__ */ React.createElement(Button, { type: "primary", onClick: rollback2Revision }, "Rollback To Revision"))),
      id: props.id,
      visible: true,
      onClose: props.onClose,
      title: /* @__PURE__ */ React.createElement("div", { style: { color: "#fff" } }, " Differences between ", /* @__PURE__ */ React.createElement("span", { className: "name" }, props.baseName), " and ", /* @__PURE__ */ React.createElement("span", { className: "name" }, props.targetName))
    },
    /* @__PURE__ */ React.createElement(If, { condition: !compare.isDiff }, /* @__PURE__ */ React.createElement(Message, { type: "success", style: { marginBottom: "8px" } }, /* @__PURE__ */ React.createElement(Translation, null, "There is no change"))),
    /* @__PURE__ */ React.createElement("div", { id: container, className: "diff-box" }, /* @__PURE__ */ React.createElement(DiffEditor, { language: "yaml", id: container, target: compare.targetAppYAML, base: compare.baseAppYAML }))
  );
};
