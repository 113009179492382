"use strict";
import { Button, Grid, Card, Message } from "@alifd/next";
import React, { Component, Fragment } from "react";
import { If } from "../../../../components/If";
import Item from "../../../../components/Item";
import Permission from "../../../../components/Permission";
import { Translation } from "../../../../components/Translation";
import { momentDate } from "../../../../utils/common";
import { locale } from "../../../../utils/locale";
import GeneralDialog from "../GeneralDialog";
import "./index.less";
class General extends Component {
  constructor(props) {
    super(props);
    this.editGeneral = (item) => {
      this.setState({
        editGeneral: item,
        isEditGeneral: true
      });
    };
    this.onCloseGeneral = () => {
      this.setState({
        isEditGeneral: false
      });
    };
    this.onUpdateGeneral = () => {
      this.setState({
        isEditGeneral: false
      });
      this.props.loadProjectDetail();
    };
    this.state = {
      editGeneral: { name: "", alias: "", owner: { name: "", alias: "" }, description: "" },
      isEditGeneral: false
    };
  }
  render() {
    const { Row, Col } = Grid;
    const {
      userList,
      projectDetails = {
        name: "",
        alias: "",
        createTime: "",
        description: "",
        owner: { name: "", alias: "" }
      },
      projectName
    } = this.props;
    const { isEditGeneral, editGeneral } = this.state;
    return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "general-wrapper" }, /* @__PURE__ */ React.createElement(Card, { locale: locale().Card, contentHeight: "auto", className: "card-wrapper" }, /* @__PURE__ */ React.createElement("section", { className: "card-title-wrapper" }, /* @__PURE__ */ React.createElement("span", { className: "card-title" }, /* @__PURE__ */ React.createElement(Translation, null, "General")), /* @__PURE__ */ React.createElement(Permission, { request: { resource: `project:${projectName}`, action: "update" }, project: projectName }, /* @__PURE__ */ React.createElement(
      Button,
      {
        className: "card-button-wrapper",
        onClick: () => {
          this.editGeneral(projectDetails);
        }
      },
      /* @__PURE__ */ React.createElement(Translation, null, "Edit")
    ))), /* @__PURE__ */ React.createElement("section", { className: "card-content-wrapper" }, /* @__PURE__ */ React.createElement(If, { condition: projectDetails.description }, /* @__PURE__ */ React.createElement(Row, { style: { marginBottom: "16px" } }, /* @__PURE__ */ React.createElement(Message, { type: "notice" }, projectDetails.description))), /* @__PURE__ */ React.createElement(Row, { wrap: true }, /* @__PURE__ */ React.createElement(Col, { m: 12, xs: 24 }, /* @__PURE__ */ React.createElement(
      Item,
      {
        label: /* @__PURE__ */ React.createElement(Translation, null, "Name(Alias)"),
        value: `${projectDetails.name}(${projectDetails.alias || "-"})`
      }
    )), /* @__PURE__ */ React.createElement(Col, { m: 12, xs: 24 }, /* @__PURE__ */ React.createElement(
      Item,
      {
        label: /* @__PURE__ */ React.createElement(Translation, null, "Owner"),
        value: projectDetails.owner?.alias ? `${projectDetails.owner?.alias}(${projectDetails.owner?.name})` : projectDetails.owner?.name
      }
    ))), /* @__PURE__ */ React.createElement(Row, { wrap: true }, /* @__PURE__ */ React.createElement(Col, { m: 12, xs: 24 }, /* @__PURE__ */ React.createElement(Item, { label: /* @__PURE__ */ React.createElement(Translation, null, "Create Time"), value: momentDate(projectDetails.createTime) })), /* @__PURE__ */ React.createElement(Col, { m: 12, xs: 24 }, /* @__PURE__ */ React.createElement(Item, { label: /* @__PURE__ */ React.createElement(Translation, null, "Update Time"), value: momentDate(projectDetails.updateTime) })))))), /* @__PURE__ */ React.createElement(If, { condition: isEditGeneral }, /* @__PURE__ */ React.createElement(
      GeneralDialog,
      {
        isEditGeneral,
        editGeneral,
        userList,
        onUpdateGeneral: this.onUpdateGeneral,
        onCloseGeneral: this.onCloseGeneral
      }
    )));
  }
}
export default General;
