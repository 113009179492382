"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Balloon, Button, Card, Form, Grid, Loading, Message, Select, Tag, MenuButton } from "@alifd/next";
import { connect } from "dva";
import _ from "lodash";
import React from "react";
import { detailWorkflow, getWorkflowDefinitions, updateWorkflow } from "../../api/workflows";
import Item from "../../components/Item";
import { Translation } from "../../components/Translation";
import { WorkflowPrompt } from "../../components/WorkflowPrompt";
import WorkflowStudio from "../../components/WorkflowStudio";
import { WorkflowContext } from "../../context";
import { showAlias } from "../../utils/common";
import { locale } from "../../utils/locale";
import "./index.less";
import classNames from "classnames";
import { WorkflowYAML } from "../../components/WorkflowYAML";
import i18n from "../../i18n";
import { CanarySetting } from "./components/CanarySetting";
import { locationService } from "../../services/LocationService";
const { Row, Col } = Grid;
const ButtonGroup = Button.Group;
export const WorkflowModeOptions = [
  {
    value: "DAG",
    label: i18n.t("DAG"),
    description: "Workflows will be executed in parallel in DAG mode based on dependencies."
  },
  {
    value: "StepByStep",
    label: i18n.t("StepByStep"),
    description: "The workflow will be executed serially step by step ."
  }
];
let ApplicationWorkflowStudio = class extends React.Component {
  constructor(props) {
    super(props);
    this.loadWorkflow = () => {
      const {
        params: { appName, workflowName }
      } = this.props.match;
      detailWorkflow({ appName, name: workflowName }).then((res) => {
        this.setState({ workflow: res, mode: res.mode, subMode: res.subMode, steps: res.steps });
      });
    };
    this.loadApplicationWorkflows = async () => {
      const { applicationDetail } = this.props;
      if (applicationDetail) {
        this.props.dispatch({
          type: "application/getApplicationWorkflows",
          payload: { appName: applicationDetail.name }
        });
      }
    };
    this.loadWorkflowDefinitions = () => {
      getWorkflowDefinitions("Application").then((res) => {
        if (res) {
          this.setState({
            definitions: res && res.definitions
          });
        }
      });
    };
    this.getEnvbindingByName = () => {
      const { envbinding } = this.props;
      const {
        params: { envName }
      } = this.props.match;
      return envbinding.find((env) => env.name === envName);
    };
    this.onChange = (steps) => {
      const { workflow } = this.state;
      this.setState({ steps, changed: !_.isEqual(steps, workflow?.steps) });
    };
    this.onSave = () => {
      const { workflow, steps, mode, subMode } = this.state;
      const { applicationDetail } = this.props;
      if (workflow && applicationDetail) {
        this.setState({ saveLoading: true });
        updateWorkflow(
          { appName: applicationDetail.name, workflowName: workflow.name },
          {
            alias: workflow.alias,
            description: workflow.description,
            default: workflow.default,
            mode,
            subMode,
            steps: steps || []
          }
        ).then((res) => {
          if (res) {
            Message.success(i18n.t("Workflow updated successfully"));
            this.loadWorkflow();
            this.loadApplicationWorkflows();
            this.setState({ changed: false });
          }
        }).finally(() => {
          this.setState({ saveLoading: false });
        });
      }
    };
    this.state = {
      changed: false,
      mode: "StepByStep",
      subMode: "DAG",
      editMode: "visual"
    };
  }
  componentDidMount() {
    this.loadWorkflow();
    this.loadWorkflowDefinitions();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.match !== this.props.match || prevProps.envbinding !== this.props.envbinding) {
      this.loadWorkflow();
    }
    const search = locationService.getSearchObject();
    const setCanary = search && search["setCanary"] == true ? true : false;
    if (this.state.setCanary != setCanary) {
      this.setState({ setCanary });
    }
  }
  render() {
    const { workflow, definitions, changed, saveLoading, mode, subMode, editMode, setCanary, steps } = this.state;
    const { applicationDetail, dispatch } = this.props;
    const envbinding = this.getEnvbindingByName();
    return /* @__PURE__ */ React.createElement("div", { className: "run-layout" }, changed && /* @__PURE__ */ React.createElement(
      WorkflowPrompt,
      {
        changed,
        content: "Do you want to save your changes?",
        title: i18n.t("Unsaved changes"),
        onSave: this.onSave,
        dispatch,
        onClearChanged: () => {
          this.setState({ changed: false });
        }
      }
    ), /* @__PURE__ */ React.createElement(Card, { contentHeight: "auto" }, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 10 }, /* @__PURE__ */ React.createElement(
      Item,
      {
        label: i18n.t("Available Targets"),
        value: /* @__PURE__ */ React.createElement("div", null, envbinding?.targets?.map((tar) => {
          return /* @__PURE__ */ React.createElement(
            Balloon,
            {
              key: tar.name,
              trigger: /* @__PURE__ */ React.createElement(Tag, { style: { marginBottom: "4px" }, color: "#85d4ff", type: "primary" }, showAlias(tar.name, tar.alias))
            },
            /* @__PURE__ */ React.createElement("p", null, "Cluster: ", showAlias(tar.cluster?.clusterName || "", tar.clusterAlias)),
            /* @__PURE__ */ React.createElement("p", null, "Namespace: ", tar.cluster?.namespace)
          );
        }))
      }
    )), /* @__PURE__ */ React.createElement(Col, { span: 4 }, /* @__PURE__ */ React.createElement(ButtonGroup, null, /* @__PURE__ */ React.createElement(
      Button,
      {
        onClick: () => {
          this.setState({ editMode: "visual" });
        },
        className: classNames("edit-mode", { active: editMode === "visual" })
      },
      "VISUAL"
    ), /* @__PURE__ */ React.createElement(
      Button,
      {
        onClick: () => {
          this.setState({ editMode: "yaml" });
        },
        className: classNames("edit-mode", "two", { active: editMode === "yaml" })
      },
      "YAML"
    ))), /* @__PURE__ */ React.createElement(
      Col,
      {
        span: 10,
        style: {
          display: "flex",
          justifyContent: "end",
          flexWrap: "wrap"
        }
      },
      /* @__PURE__ */ React.createElement(
        "div",
        {
          style: {
            display: "flex",
            width: "100%",
            justifyContent: "end"
          }
        },
        /* @__PURE__ */ React.createElement(
          MenuButton,
          {
            style: { marginRight: "var(--spacing-4)" },
            autoWidth: false,
            label: i18n.t("More").toString()
          },
          /* @__PURE__ */ React.createElement(
            MenuButton.Item,
            {
              onClick: () => {
                locationService.partial({ setCanary: true });
                this.setState({ setCanary: true });
              }
            },
            /* @__PURE__ */ React.createElement(Translation, null, "Canary Rollout Setting")
          )
        ),
        /* @__PURE__ */ React.createElement(Form.Item, { label: i18n.t("Mode").toString(), labelAlign: "inset", style: { marginRight: "8px" } }, /* @__PURE__ */ React.createElement(
          Select,
          {
            locale: locale().Select,
            defaultValue: "StepByStep",
            value: mode,
            dataSource: WorkflowModeOptions,
            onChange: (value) => {
              this.setState({ mode: value, changed: this.state.mode !== value });
            }
          }
        )),
        /* @__PURE__ */ React.createElement(Form.Item, { label: i18n.t("Sub Mode").toString(), labelAlign: "inset", style: { marginRight: "8px" } }, /* @__PURE__ */ React.createElement(
          Select,
          {
            locale: locale().Select,
            defaultValue: "DAG",
            value: subMode,
            onChange: (value) => {
              this.setState({ subMode: value, changed: this.state.subMode !== value });
            },
            dataSource: WorkflowModeOptions
          }
        )),
        /* @__PURE__ */ React.createElement(Button, { disabled: !changed, loading: saveLoading, type: "primary", onClick: this.onSave }, /* @__PURE__ */ React.createElement(Translation, null, "Save"))
      ),
      changed && /* @__PURE__ */ React.createElement("div", { className: "notice-changes" }, /* @__PURE__ */ React.createElement(Translation, null, "Unsaved changes"))
    ))), !workflow && /* @__PURE__ */ React.createElement(Loading, { visible: true }), workflow && editMode === "visual" && /* @__PURE__ */ React.createElement(
      WorkflowContext.Provider,
      {
        value: {
          appName: applicationDetail?.name,
          projectName: applicationDetail?.project?.name,
          workflow
        }
      },
      /* @__PURE__ */ React.createElement(
        WorkflowStudio,
        {
          subMode: workflow.subMode,
          definitions,
          steps,
          onChange: this.onChange
        }
      )
    ), workflow && editMode === "yaml" && /* @__PURE__ */ React.createElement(WorkflowYAML, { steps, name: workflow.name, onChange: this.onChange }), setCanary && /* @__PURE__ */ React.createElement(
      CanarySetting,
      {
        definitions,
        onCancel: () => {
          locationService.partial({ setCanary: false });
          this.setState({ setCanary: false });
        },
        workflow,
        onChange: this.onChange
      }
    ));
  }
};
ApplicationWorkflowStudio = __decorateClass([
  connect((store) => {
    return { ...store.application };
  })
], ApplicationWorkflowStudio);
export default ApplicationWorkflowStudio;
