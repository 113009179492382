"use strict";
import { getDomain } from "../utils/common";
import { managePlugin } from "./productionLink";
import { get, post } from "./request";
const baseURLOject = getDomain();
const base = baseURLOject.MOCK || baseURLOject.APIBASE;
export function getPluginList(params) {
  return get(base + managePlugin, { params }).then((res) => res);
}
export function detailPlugin(params) {
  return get(`${base + managePlugin}/${params.id}`, { params }).then((res) => res);
}
export function enablePlugin(params) {
  return post(`${base + managePlugin}/${params.id}/enable`, params);
}
export function disablePlugin(params) {
  return post(`${base + managePlugin}/${params.id}/disable`, params);
}
export function setPlugin(params) {
  return post(`${base + managePlugin}/${params.id}/setting`, params);
}
export function installPlugin(params) {
  return post(`${base + managePlugin}/${params.id}/install`, { url: params.url });
}
export function uninstallPlugin(params) {
  return post(`${base + managePlugin}/${params.id}/uninstall`, {});
}
