"use strict";
import { Link } from "dva/router";
import * as React from "react";
import { Breadcrumb as B } from "@alifd/next";
import { AiOutlineHome } from "react-icons/ai";
export const Breadcrumb = (props) => {
  return /* @__PURE__ */ React.createElement("div", { className: "breadcrumb" }, /* @__PURE__ */ React.createElement(Link, { to: "/" }, /* @__PURE__ */ React.createElement(AiOutlineHome, { size: 18 })), /* @__PURE__ */ React.createElement(B, { separator: "/" }, props.items.map((item, i) => {
    return /* @__PURE__ */ React.createElement(B.Item, { key: "breadcrumb" + i }, item.to && /* @__PURE__ */ React.createElement(Link, { to: item.to }, item.title), !item.to && item.title);
  })));
};
