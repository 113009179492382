"use strict";
import { Button, Message, Grid, Dialog, Form, Input, Upload, Field, Loading } from "@alifd/next";
import React from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { getClusterDetails, updateCluster } from "../../../../api/cluster";
import DefinitionCode from "../../../../components/DefinitionCode";
import { Translation } from "../../../../components/Translation";
import { checkName } from "../../../../utils/common";
import { locale } from "../../../../utils/locale";
const { Col, Row } = Grid;
class AddClusterDialog extends React.Component {
  constructor(props) {
    super(props);
    this.loadClusterDetail = () => {
      const { editClusterName } = this.props;
      if (editClusterName) {
        getClusterDetails({ clusterName: editClusterName }).then((re) => {
          this.setState({ cluster: re, editMode: true });
          this.field.setValues(re);
        });
      }
    };
    this.onClose = () => {
      this.props.onClose();
      this.resetField();
    };
    this.onOk = () => {
      const { editMode, cluster } = this.state;
      this.field.validate((error, values) => {
        if (error) {
          return;
        }
        if (editMode) {
          updateCluster({
            name: cluster.name,
            alias: values.alias,
            icon: cluster.icon,
            description: values.description,
            dashboardURL: values.dashboardURL,
            kubeConfig: values.kubeConfig,
            labels: cluster.labels
          }).then((re) => {
            if (re) {
              Message.success(/* @__PURE__ */ React.createElement(Translation, null, "cluster update success"));
              this.resetField();
              this.props.onOK();
            }
          });
        } else {
          this.props.dispatch({
            type: "clusters/createCluster",
            payload: values,
            callback: (re) => {
              if (re) {
                Message.success(/* @__PURE__ */ React.createElement(Translation, null, "cluster add success"));
                this.resetField();
                this.props.onOK();
              }
            }
          });
        }
      });
    };
    this.onError = () => {
    };
    this.customRequest = (option) => {
      const reader = new FileReader();
      const fileselect = option.file;
      reader.readAsText(fileselect);
      reader.onload = () => {
        this.field.setValues({
          kubeConfig: reader.result?.toString() || ""
        });
      };
      return {
        file: File,
        onError: this.onError,
        abort() {
        }
      };
    };
    this.field = new Field(this);
    this.DefinitionCodeRef = React.createRef();
    this.state = {
      editMode: false,
      cluster: {
        name: "",
        kubeConfig: ""
      }
    };
  }
  componentDidMount() {
    this.loadClusterDetail();
  }
  resetField() {
    this.field.setValues({
      name: "",
      description: "",
      dashboardURL: "",
      kubeConfig: ""
    });
  }
  render() {
    const { visible, editClusterName } = this.props;
    const { editMode, cluster } = this.state;
    const FormItem = Form.Item;
    const formItemLayout = {
      labelCol: {
        fixedSpan: 6
      },
      wrapperCol: {
        span: 18
      }
    };
    const init = this.field.init;
    const values = this.field.getValues();
    const valueInfo = cluster.kubeConfig || values.kubeConfig || "";
    return /* @__PURE__ */ React.createElement(
      Dialog,
      {
        locale: locale().Dialog,
        title: editMode ? /* @__PURE__ */ React.createElement(Translation, null, "Edit Cluster Config") : /* @__PURE__ */ React.createElement(Translation, null, "Connect Existing Cluster"),
        overflowScroll: true,
        autoFocus: true,
        width: 800,
        visible,
        onOk: this.onOk,
        onCancel: this.onClose,
        onClose: this.onClose,
        footerActions: ["cancel", "ok"],
        v2: true
      },
      /* @__PURE__ */ React.createElement(Loading, { visible: editClusterName && !editMode ? true : false, style: { width: "100%" } }, /* @__PURE__ */ React.createElement(Form, { ...formItemLayout, field: this.field }, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Name"), required: true }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "name",
          disabled: editMode,
          ...init("name", {
            initValue: cluster.name,
            rules: [
              {
                required: true,
                pattern: checkName,
                message: "Please enter a valid name contains only alphabetical words"
              }
            ]
          })
        }
      ))), /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Alias") }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "alias",
          ...init("alias", {
            initValue: cluster.alias,
            rules: [
              {
                minLength: 2,
                maxLength: 64,
                message: "Enter a string of 2 to 64 characters."
              }
            ]
          })
        }
      )))), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Description") }, /* @__PURE__ */ React.createElement(
        Input,
        {
          defaultValue: cluster.description,
          name: "description",
          ...init("description", {
            initValue: cluster.description,
            rules: [
              {
                maxLength: 256,
                message: "Enter a description that contains less than 256 characters."
              }
            ]
          })
        }
      ))), /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Dashboard URL") }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "dashboardURL",
          ...init("dashboardURL", {
            initValue: cluster.dashboardURL,
            rules: [
              {
                required: false,
                format: "url",
                message: "Input according to URL specification"
              }
            ]
          })
        }
      )))), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "KubeConfig"), labelAlign: "top", required: true }, /* @__PURE__ */ React.createElement(Upload, { request: this.customRequest }, /* @__PURE__ */ React.createElement(Button, { text: true, type: "normal", className: "padding-left-0" }, /* @__PURE__ */ React.createElement(AiOutlineCloudUpload, null), /* @__PURE__ */ React.createElement(Translation, null, "Upload Config File"))), /* @__PURE__ */ React.createElement("div", { id: "guide-code", className: "guide-code" }, /* @__PURE__ */ React.createElement(
        DefinitionCode,
        {
          containerId: "guide-code",
          language: "yaml",
          readOnly: false,
          ...init("kubeConfig", {
            initValue: cluster.kubeConfig,
            rules: [
              {
                required: true,
                message: /* @__PURE__ */ React.createElement(Translation, null, "Please upload or edit kube config")
              }
            ]
          }),
          value: valueInfo,
          ref: this.DefinitionCodeRef
        }
      )))))))
    );
  }
}
export default AddClusterDialog;
