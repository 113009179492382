"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { connect } from "dva";
import i18n from "i18next";
import React from "react";
import { listCloudResourceSecrets } from "../../api/observation";
import { CustomSelect } from "../../components/CustomSelect";
import { locale } from "../../utils/locale";
let SecretSelect = class extends React.Component {
  constructor(props) {
    super(props);
    this.componentDidMount = async () => {
      this.loadSecrets();
    };
    this.getSecretKeys = (name) => {
      const { secrets } = this.state;
      let keys = [];
      secrets?.map((secret) => {
        if (secret.metadata.labels["app.oam.dev/sync-alias"] == name && "data" in secret) {
          keys = Object.keys(secret.data);
        }
      });
      return keys;
    };
    this.onChange = (value) => {
      const { onChange, setKeys } = this.props;
      const keys = this.getSecretKeys(value);
      onChange(value);
      setKeys(keys);
    };
    this.loadSecrets = () => {
      if (this.props.appNamespace) {
        listCloudResourceSecrets({ appNs: this.props.appNamespace }).then((res) => {
          if (res) {
            this.setState({ secrets: res.secrets }, () => {
              const keys = this.getSecretKeys(this.props.value);
              this.props.setKeys(keys);
            });
          }
        });
      }
    };
    this.state = {};
  }
  render() {
    const { value, id, disabled } = this.props;
    const { secrets } = this.state;
    const filters = secrets?.filter((secret) => secret.metadata.labels["app.oam.dev/sync-alias"]);
    const dataSource = filters?.map((secret) => {
      return {
        label: secret.metadata.labels["app.oam.dev/sync-alias"],
        value: secret.metadata.labels["app.oam.dev/sync-alias"]
      };
    }) || [];
    return /* @__PURE__ */ React.createElement(
      CustomSelect,
      {
        locale: locale().Select,
        onChange: this.onChange,
        value,
        id,
        disabled,
        placeholder: i18n.t("Please select or input a secret name").toString(),
        enableInput: true,
        dataSource
      }
    );
  }
};
SecretSelect = __decorateClass([
  connect((store) => {
    return { ...store.uischema };
  })
], SecretSelect);
export default SecretSelect;
