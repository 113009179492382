"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { connect } from "dva";
import React, { Component, Fragment } from "react";
import Permission from "../../components/Permission";
import ConfigDistributionPage from "./components/ConfigDistribution";
import Configs from "./components/Configs";
import General from "./components/General";
import Targets from "./components/Targets";
let Summary = class extends Component {
  constructor(props) {
    super(props);
    this.loadProjectDetail = async () => {
      const { params = { projectName: "" } } = this.props.match;
      this.props.dispatch({
        type: "project/getProjectDetails",
        payload: { projectName: params.projectName }
      });
    };
    this.state = {
      userList: []
    };
  }
  render() {
    const { userList } = this.state;
    const { match, projectDetails } = this.props;
    const { params = { projectName: "" } } = match;
    return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "container", id: params.projectName }, /* @__PURE__ */ React.createElement(
      General,
      {
        projectDetails,
        userList,
        loadProjectDetail: this.loadProjectDetail,
        projectName: params.projectName
      }
    ), /* @__PURE__ */ React.createElement(Targets, { projectName: params.projectName }), /* @__PURE__ */ React.createElement(
      Permission,
      {
        project: params.projectName,
        request: { resource: `project:${params.projectName}/config:*`, action: "list" }
      },
      /* @__PURE__ */ React.createElement(Configs, { projectName: params.projectName })
    ), /* @__PURE__ */ React.createElement(
      Permission,
      {
        project: params.projectName,
        request: { resource: `project:${params.projectName}/config:*`, action: "distribute" }
      },
      /* @__PURE__ */ React.createElement(ConfigDistributionPage, { projectName: params.projectName })
    )));
  }
};
Summary = __decorateClass([
  connect((store) => {
    return { ...store.project };
  })
], Summary);
export default Summary;
