"use strict";
import { Message, Grid, Dialog, Form, Field, Select } from "@alifd/next";
import React from "react";
import "./index.less";
import { applyProjectConfigDistribution } from "../../../../api/config";
import { getProjectTargetList } from "../../../../api/project";
import { Translation } from "../../../../components/Translation";
import { locale } from "../../../../utils/locale";
const { Col, Row } = Grid;
class DistributeConfigDialog extends React.Component {
  constructor(props) {
    super(props);
    this.loadTargets = () => {
      const { projectName } = this.props;
      getProjectTargetList({ projectName }).then((res) => {
        if (res && Array.isArray(res.targets)) {
          this.setState({ targetOptions: res.targets });
        } else {
          this.setState({ targetOptions: [] });
        }
      });
    };
    this.onClose = () => {
      this.props.onClose();
    };
    this.onOk = () => {
      const { projectName, config } = this.props;
      this.field.validate((error, values) => {
        if (error) {
          return;
        }
        const { targets } = values;
        const applyTargets = [];
        targets.map((t) => {
          if (t && t.includes("/")) {
            applyTargets.push({
              clusterName: t.split("/")[0],
              namespace: t.split("/")[1]
            });
          }
        });
        applyProjectConfigDistribution(projectName, {
          name: "distribute-" + config.name,
          targets: applyTargets,
          configs: [config]
        }).then(() => {
          Message.success(/* @__PURE__ */ React.createElement(Translation, null, "Config distributed successfully"));
          this.props.onSuccess();
        });
      });
    };
    this.field = new Field(this);
    this.state = {};
  }
  componentDidMount() {
    this.loadTargets();
  }
  render() {
    const FormItem = Form.Item;
    const formItemLayout = {
      labelCol: {
        fixedSpan: 6
      },
      wrapperCol: {
        span: 18
      }
    };
    const init = this.field.init;
    const { targets } = this.props;
    const { targetOptions } = this.state;
    const options = targetOptions?.map((target) => {
      const key = target.cluster?.clusterName + "/" + target.cluster?.namespace;
      return {
        label: `${target.alias || target.name}(${key})`,
        value: key
      };
    });
    return /* @__PURE__ */ React.createElement(
      Dialog,
      {
        locale: locale().Dialog,
        v2: true,
        title: /* @__PURE__ */ React.createElement(Translation, null, "Distribute the config"),
        overflowScroll: true,
        autoFocus: true,
        visible: true,
        onOk: this.onOk,
        onCancel: this.onClose,
        onClose: this.onClose,
        footerActions: ["ok"],
        footerAlign: "center"
      },
      /* @__PURE__ */ React.createElement(Form, { ...formItemLayout, field: this.field }, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24 }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Targets"), required: true }, /* @__PURE__ */ React.createElement(
        Select,
        {
          multiple: true,
          name: "name",
          locale: locale().Select,
          dataSource: options,
          ...init("targets", {
            initValue: targets?.map((t) => {
              return `${t.clusterName}/${t.namespace}`;
            }),
            rules: [
              {
                required: true
              }
            ]
          })
        }
      )))))
    );
  }
}
export default DistributeConfigDialog;
