"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Grid } from "@alifd/next";
import { connect } from "dva";
import { Link } from "dva/router";
import _ from "lodash";
import React, { Component, Fragment } from "react";
import { Translation } from "../../components/Translation";
import { Breadcrumb } from "../../components/Breadcrumb";
import "./index.less";
import classNames from "classnames";
const { Row, Col } = Grid;
let DefinitionDetailsLayout = class extends Component {
  constructor() {
    super(...arguments);
    this.getNavList = () => {
      const { params = { definitionType: "", definitionName: "" } } = this.props.match;
      const { definitionType, definitionName } = params;
      const list = [
        {
          id: "uiSchema",
          name: /* @__PURE__ */ React.createElement(Translation, null, "UI Schema"),
          to: `/definitions/${definitionType}/${definitionName}/ui-schema`
        }
      ];
      const nav = list.map((item) => {
        const active = this.props.activeId === item.id ? "active" : "";
        return /* @__PURE__ */ React.createElement(Link, { key: item.id, className: active, to: item.to }, item.name);
      });
      return nav;
    };
    this.matchDefinitionName = (definitionType) => {
      const { definitionTypes } = this.props;
      const matchDefinition = _.find(definitionTypes, (item) => {
        return item.type === definitionType;
      });
      return matchDefinition && matchDefinition.name || "";
    };
  }
  render() {
    const menu = this.getNavList();
    const { params = { definitionType: "", definitionName: "" } } = this.props.match;
    const { definitionType, definitionName } = params;
    return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 6, className: classNames("padding16", "breadcrumb") }, /* @__PURE__ */ React.createElement(
      Breadcrumb,
      {
        items: [
          {
            to: `/definitions/${definitionType}/config`,
            title: "Definitions"
          },
          {
            title: definitionName
          }
        ]
      }
    ))), /* @__PURE__ */ React.createElement("nav", { className: "definitions-detail-wrapper" }, /* @__PURE__ */ React.createElement("ul", null, menu)), this.props.children);
  }
};
DefinitionDetailsLayout = __decorateClass([
  connect((store) => {
    return { ...store.definitions, ...store.user };
  })
], DefinitionDetailsLayout);
export default DefinitionDetailsLayout;
