"use strict";
import { Grid, Input, Form, Button } from "@alifd/next";
import _ from "lodash";
import React, { useState } from "react";
import { IoMdAdd, IoMdRemove } from "react-icons/io";
import { locale } from "../../utils/locale";
const { Row, Col } = Grid;
export const OutputItemForm = (props) => {
  const [name, setName] = useState(props.value ? props.value.name : "");
  const [valueFrom, setValueFrom] = useState(props.value?.valueFrom || "");
  const nameChange = (value) => {
    setName(value);
    if (value != "" && valueFrom != "") {
      props.onChange({ name: value, valueFrom });
    }
  };
  const valueFromChange = (value) => {
    setValueFrom(value);
    if (name != "" && value != "") {
      props.onChange({ name, valueFrom: value });
    }
  };
  return /* @__PURE__ */ React.createElement("div", { className: "item-form" }, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { className: "from", span: 12, style: { paddingLeft: "8px" } }, /* @__PURE__ */ React.createElement(Form.Item, { label: "Name", labelAlign: "inset" }, /* @__PURE__ */ React.createElement(Input, { locale: locale().Select, value: name, onChange: nameChange }))), /* @__PURE__ */ React.createElement(Col, { span: 12, style: { paddingRight: "8px" } }, /* @__PURE__ */ React.createElement(Form.Item, { label: "ValueFrom", labelAlign: "inset" }, /* @__PURE__ */ React.createElement(Input, { value: valueFrom, onChange: valueFromChange })))));
};
export const OutputItems = (props) => {
  const [items, setItems] = useState(props.value || [{ name: "", valueFrom: "" }]);
  const onChange = (update) => {
    props.onChange(update.filter((item) => item.name != "" && item.valueFrom != ""));
  };
  return /* @__PURE__ */ React.createElement("div", { id: props.id, className: "input-items" }, items?.map((item, index) => {
    const key = `index${index}`;
    return /* @__PURE__ */ React.createElement("div", { className: "item", key }, /* @__PURE__ */ React.createElement(
      OutputItemForm,
      {
        key: item.name,
        value: item,
        onChange: (output) => {
          items[index].name = output.name;
          items[index].valueFrom = output.valueFrom;
          onChange(items);
        }
      }
    ), index != 0 && /* @__PURE__ */ React.createElement("div", { className: "item-delete" }, /* @__PURE__ */ React.createElement(
      Button,
      {
        onClick: () => {
          const newItems = _.cloneDeep(items);
          newItems.splice(index, 1);
          setItems(newItems);
          onChange(newItems);
        },
        size: "small",
        type: "secondary",
        style: { justifyContent: "center", marginLeft: "8px" }
      },
      /* @__PURE__ */ React.createElement(IoMdRemove, null)
    )));
  }), /* @__PURE__ */ React.createElement(
    Button,
    {
      onClick: () => {
        const newItems = _.cloneDeep(items);
        newItems.push({ name: "", valueFrom: "" });
        setItems(newItems);
      },
      size: "small",
      type: "secondary",
      style: { justifyContent: "center", marginLeft: "8px" }
    },
    /* @__PURE__ */ React.createElement(IoMdAdd, null)
  ));
};
