"use strict";
import { listTemplates } from "../api/config";
const configs = {
  namespace: "configs",
  state: {
    configTemplates: []
  },
  reducers: {
    updateTemplates(state, { payload }) {
      return {
        ...state,
        configTemplates: payload.templates || []
      };
    }
  },
  effects: {
    *loadTemplates(action, { call, put }) {
      const result = yield call(listTemplates);
      if (result) {
        yield put({ type: "updateTemplates", payload: result });
      }
    }
  }
};
export default configs;
