"use strict";
import { VelaUXPlugin } from "./types";
export class AppPagePlugin extends VelaUXPlugin {
  /**
   * Called after the module has loaded, and before the app is used.
   * This function may be called multiple times on the same instance.
   * The first time, `this.meta` will be undefined
   */
  init(meta) {
  }
  /**
   * Set the component displayed under:
   *   /a/${plugin-id}/*
   *
   * If the NavModel is configured, the page will have a managed frame, otheriwse it has full control.
   */
  setRootPage(root) {
    this.root = root;
    return this;
  }
}
