"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Drawer } from "@alifd/next";
import { connect } from "dva";
import React, { Component } from "react";
import { AiOutlineFullscreen } from "react-icons/ai";
import { prepareCloudShell } from "../../api/users";
import { If } from "../../components/If";
import { getToken } from "../../utils/storage";
import "./index.less";
let CloudShell = class extends Component {
  constructor(props) {
    super(props);
    this.prepare = () => {
      prepareCloudShell().then((res) => {
        this.setState({ prepare: res, preparePadding: res.status != "Completed" });
        if (res.status == "Preparing") {
          setTimeout(() => {
            this.prepare();
          }, 2e3);
        }
      });
    };
    this.onClose = () => {
      if (this.props.dispatch) {
        this.props.dispatch({
          type: "cloudshell/close"
        });
      }
    };
    this.loadFullScreenAddress = () => {
      const { BASE_DOMAIN } = process.env;
      let domain = BASE_DOMAIN || "";
      if (!domain) {
        domain = document.location.origin;
      }
      return `${domain}/view/cloudshell?token=${getToken()}`;
    };
    this.state = {
      preparePadding: true
    };
  }
  componentDidMount() {
    this.prepare();
  }
  renderPrepareView() {
    const { prepare } = this.state;
    const message = prepare?.message ? `${prepare?.status}:${prepare?.message}` : prepare?.status;
    return /* @__PURE__ */ React.createElement("div", { className: "prepare" }, /* @__PURE__ */ React.createElement(
      "svg",
      {
        stroke: "currentColor",
        fill: "currentColor",
        strokeWidth: "0",
        viewBox: "0 0 1024 1024",
        color: "#fff",
        height: "60",
        width: "60",
        xmlns: "http://www.w3.org/2000/svg",
        style: { color: "rgb(255, 255, 255)" }
      },
      /* @__PURE__ */ React.createElement("path", { d: "M924.8 625.7l-65.5-56c3.1-19 4.7-38.4 4.7-57.8s-1.6-38.8-4.7-57.8l65.5-56a32.03 32.03 0 0 0 9.3-35.2l-.9-2.6a443.74 443.74 0 0 0-79.7-137.9l-1.8-2.1a32.12 32.12 0 0 0-35.1-9.5l-81.3 28.9c-30-24.6-63.5-44-99.7-57.6l-15.7-85a32.05 32.05 0 0 0-25.8-25.7l-2.7-.5c-52.1-9.4-106.9-9.4-159 0l-2.7.5a32.05 32.05 0 0 0-25.8 25.7l-15.8 85.4a351.86 351.86 0 0 0-99 57.4l-81.9-29.1a32 32 0 0 0-35.1 9.5l-1.8 2.1a446.02 446.02 0 0 0-79.7 137.9l-.9 2.6c-4.5 12.5-.8 26.5 9.3 35.2l66.3 56.6c-3.1 18.8-4.6 38-4.6 57.1 0 19.2 1.5 38.4 4.6 57.1L99 625.5a32.03 32.03 0 0 0-9.3 35.2l.9 2.6c18.1 50.4 44.9 96.9 79.7 137.9l1.8 2.1a32.12 32.12 0 0 0 35.1 9.5l81.9-29.1c29.8 24.5 63.1 43.9 99 57.4l15.8 85.4a32.05 32.05 0 0 0 25.8 25.7l2.7.5a449.4 449.4 0 0 0 159 0l2.7-.5a32.05 32.05 0 0 0 25.8-25.7l15.7-85a350 350 0 0 0 99.7-57.6l81.3 28.9a32 32 0 0 0 35.1-9.5l1.8-2.1c34.8-41.1 61.6-87.5 79.7-137.9l.9-2.6c4.5-12.3.8-26.3-9.3-35zM788.3 465.9c2.5 15.1 3.8 30.6 3.8 46.1s-1.3 31-3.8 46.1l-6.6 40.1 74.7 63.9a370.03 370.03 0 0 1-42.6 73.6L721 702.8l-31.4 25.8c-23.9 19.6-50.5 35-79.3 45.8l-38.1 14.3-17.9 97a377.5 377.5 0 0 1-85 0l-17.9-97.2-37.8-14.5c-28.5-10.8-55-26.2-78.7-45.7l-31.4-25.9-93.4 33.2c-17-22.9-31.2-47.6-42.6-73.6l75.5-64.5-6.5-40c-2.4-14.9-3.7-30.3-3.7-45.5 0-15.3 1.2-30.6 3.7-45.5l6.5-40-75.5-64.5c11.3-26.1 25.6-50.7 42.6-73.6l93.4 33.2 31.4-25.9c23.7-19.5 50.2-34.9 78.7-45.7l37.9-14.3 17.9-97.2c28.1-3.2 56.8-3.2 85 0l17.9 97 38.1 14.3c28.7 10.8 55.4 26.2 79.3 45.8l31.4 25.8 92.8-32.9c17 22.9 31.2 47.6 42.6 73.6L781.8 426l6.5 39.9zM512 326c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm79.2 255.2A111.6 111.6 0 0 1 512 614c-29.9 0-58-11.7-79.2-32.8A111.6 111.6 0 0 1 400 502c0-29.9 11.7-58 32.8-79.2C454 401.6 482.1 390 512 390c29.9 0 58 11.6 79.2 32.8A111.6 111.6 0 0 1 624 502c0 29.9-11.7 58-32.8 79.2z" }),
      /* @__PURE__ */ React.createElement(
        "animateTransform",
        {
          attributeName: "transform",
          type: "rotate",
          from: "0",
          to: "360",
          dur: "3s",
          repeatCount: "indefinite"
        }
      )
    ), /* @__PURE__ */ React.createElement("span", { style: { marginTop: "8px", fontWeight: 500 } }, "Prepare The Cloud Shell"), /* @__PURE__ */ React.createElement("span", { style: { marginTop: "8px" } }, message));
  }
  renderCloudShellView() {
    const { BASE_DOMAIN } = process.env;
    return /* @__PURE__ */ React.createElement(
      "iframe",
      {
        width: "100%",
        height: "100%",
        src: `${BASE_DOMAIN || ""}/view/cloudshell?token=${getToken()}`
      }
    );
  }
  render() {
    const { show } = this.props;
    const { preparePadding } = this.state;
    return /* @__PURE__ */ React.createElement(
      Drawer,
      {
        visible: show,
        closeMode: "close",
        closeable: "close",
        className: "cloudshell-layout",
        placement: "bottom",
        height: "500px",
        width: "100%",
        onClose: this.onClose
      },
      /* @__PURE__ */ React.createElement(If, { condition: !preparePadding }, /* @__PURE__ */ React.createElement(
        "a",
        {
          className: "full-screen",
          target: "_blank",
          href: this.loadFullScreenAddress(),
          rel: "noopener noreferrer",
          title: "Full Screen"
        },
        /* @__PURE__ */ React.createElement(AiOutlineFullscreen, { color: "#fff" })
      )),
      /* @__PURE__ */ React.createElement(If, { condition: preparePadding }, this.renderPrepareView()),
      /* @__PURE__ */ React.createElement(If, { condition: !preparePadding }, this.renderCloudShellView())
    );
  }
};
CloudShell = __decorateClass([
  connect((store) => {
    return { ...store.cloudshell };
  })
], CloudShell);
export default CloudShell;
