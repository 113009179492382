"use strict";
import { getBackendSrv, handleAPIError } from "../services/BackendService";
export const post = (url, params, customError) => {
  return getBackendSrv().getAxiosInstance().post(url, params).then((res) => {
    return res && res.data;
  }).catch((err) => {
    handleAPIError(err, params.customError || customError);
  });
};
export const get = (url, params) => {
  return getBackendSrv().getAxiosInstance().get(url, params).then((res) => {
    return res && res.data;
  }).catch((err) => {
    handleAPIError(err, params.customError);
  });
};
export const rdelete = (url, params, customError) => {
  return getBackendSrv().getAxiosInstance().delete(url, params).then((res) => {
    return res && res.data;
  }).catch((err) => {
    handleAPIError(err, params.customError || customError);
  });
};
export const put = (url, params, customError) => {
  return getBackendSrv().getAxiosInstance().put(url, params).then((res) => {
    return res && res.data;
  }).catch((err) => {
    handleAPIError(err, params.customError || customError);
  });
};
