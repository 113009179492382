"use strict";
import { Card, Dialog, Grid, Message, Tab } from "@alifd/next";
import React, { Component } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { getApplicationComponent } from "../../../../api/application";
import Empty from "../../../../components/Empty";
import { If } from "../../../../components/If";
import Item from "../../../../components/Item";
import Permission from "../../../../components/Permission";
import { Translation } from "../../../../components/Translation";
import { momentDate, showAlias } from "../../../../utils/common";
import "./index.less";
import { locale } from "../../../../utils/locale";
import { AiOutlineDelete } from "react-icons/ai";
class TriggerList extends Component {
  constructor(props) {
    super(props);
    this.showWebhook = (trigger) => {
      const { components } = this.props;
      this.loadComponentDetail(trigger.componentName || (components.length > 0 ? components[0].name : ""));
      this.setState({ showTrigger: trigger });
      this.setState({ customTriggerType: "execute" });
    };
    this.loadComponentDetail = (componentName) => {
      if (!componentName) {
        return;
      }
      const { appName } = this.props;
      getApplicationComponent(appName, componentName).then((res) => {
        if (res) {
          this.setState({
            component: res
          });
        }
      });
    };
    this.closeWebhook = () => {
      this.setState({ showTrigger: void 0 });
    };
    this.handleTriggerDelete = (token) => {
      Dialog.alert({
        content: "Are you sure want to delete this trigger?",
        onOk: () => {
          this.props.onDeleteTrigger(token || "");
        },
        onClose: () => {
        },
        locale: locale().Dialog
      });
    };
    this.handleCustomTriggerTab = (token) => {
      this.setState({ customTriggerType: token });
    };
    this.state = {};
  }
  componentWillReceiveProps(nextProps) {
    const { createTriggerInfo } = nextProps;
    if (createTriggerInfo && createTriggerInfo !== this.props.createTriggerInfo) {
      this.showWebhook(createTriggerInfo);
    }
  }
  render() {
    const { Row, Col } = Grid;
    const { triggers, applicationDetail } = this.props;
    const { showTrigger, component, customTriggerType } = this.state;
    const domain = `${window.location.protocol}//${window.location.host}`;
    const webHookURL = `${domain}/api/v1/webhook/${showTrigger?.token}`;
    let command = `curl -X POST -H 'content-type: application/json' --url ${webHookURL}`;
    if (showTrigger?.payloadType == "custom" && component) {
      const customTriggerBody = {
        execute: {
          action: "execute",
          upgrade: {
            [component.name]: {
              image: component.properties && component.properties.image
            }
          },
          codeInfo: {
            commit: "",
            branch: "",
            user: ""
          }
        },
        approve: {
          action: "approve",
          step: "suspend"
        },
        terminate: {
          action: "terminate",
          step: "suspend"
        },
        rollback: {
          action: "rollback",
          step: "suspend"
        }
      };
      let body = customTriggerType ? customTriggerBody[customTriggerType] : " ";
      command = `curl -X POST -H 'content-type: application/json' --url ${webHookURL} -d '${JSON.stringify(body)}'`;
    }
    const copy = /* @__PURE__ */ React.createElement("span", { style: { lineHeight: "16px", marginLeft: "8px" } }, /* @__PURE__ */ React.createElement(
      "svg",
      {
        viewBox: "0 0 1024 1024",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "p-id": "1982",
        width: "16",
        height: "16"
      },
      /* @__PURE__ */ React.createElement(
        "path",
        {
          d: "M720 192h-544A80.096 80.096 0 0 0 96 272v608C96 924.128 131.904 960 176 960h544c44.128 0 80-35.872 80-80v-608C800 227.904 764.128 192 720 192z m16 688c0 8.8-7.2 16-16 16h-544a16 16 0 0 1-16-16v-608a16 16 0 0 1 16-16h544a16 16 0 0 1 16 16v608z",
          "p-id": "1983"
        }
      ),
      /* @__PURE__ */ React.createElement(
        "path",
        {
          d: "M848 64h-544a32 32 0 0 0 0 64h544a16 16 0 0 1 16 16v608a32 32 0 1 0 64 0v-608C928 99.904 892.128 64 848 64z",
          "p-id": "1984"
        }
      ),
      /* @__PURE__ */ React.createElement(
        "path",
        {
          d: "M608 360H288a32 32 0 0 0 0 64h320a32 32 0 1 0 0-64zM608 520H288a32 32 0 1 0 0 64h320a32 32 0 1 0 0-64zM480 678.656H288a32 32 0 1 0 0 64h192a32 32 0 1 0 0-64z",
          "p-id": "1985"
        }
      )
    ));
    const customTriggerTypes = ["execute", "approve", "terminate", "rollback"];
    const projectName = applicationDetail && applicationDetail.project?.name;
    return /* @__PURE__ */ React.createElement("div", { className: "list-warper" }, /* @__PURE__ */ React.createElement("div", { className: "box" }, (triggers || []).map((item) => /* @__PURE__ */ React.createElement(Row, { wrap: true, key: item.type, className: "box-item" }, /* @__PURE__ */ React.createElement(Col, { span: 24 }, /* @__PURE__ */ React.createElement(Card, { free: true, style: { padding: "16px" }, locale: locale().Card }, /* @__PURE__ */ React.createElement("div", { className: "trigger-list-nav" }, /* @__PURE__ */ React.createElement(
      "div",
      {
        onClick: () => {
          this.props.onEditTrigger(item);
        },
        className: "trigger-list-title"
      },
      showAlias(item)
    ), /* @__PURE__ */ React.createElement("div", { className: "trigger-list-operation" }, /* @__PURE__ */ React.createElement(
      Permission,
      {
        request: {
          resource: `project:${projectName}/application:${applicationDetail?.name}/trigger:${item.name}`,
          action: "delete"
        },
        project: projectName
      },
      /* @__PURE__ */ React.createElement(
        AiOutlineDelete,
        {
          size: 14,
          className: "margin-right-0 cursor-pointer danger-icon",
          onClick: () => {
            this.handleTriggerDelete(item.token || "");
          }
        }
      )
    ))), /* @__PURE__ */ React.createElement("div", { className: "trigger-list-content" }, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24 }, /* @__PURE__ */ React.createElement(
      Item,
      {
        marginBottom: "8px",
        labelWidth: 160,
        label: /* @__PURE__ */ React.createElement(Translation, null, "Type"),
        value: item.type == "webhook" ? /* @__PURE__ */ React.createElement(Translation, null, "On Webhook Event") : item.type
      }
    ))), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24 }, /* @__PURE__ */ React.createElement(
      Item,
      {
        marginBottom: "8px",
        labelWidth: 160,
        label: /* @__PURE__ */ React.createElement(Translation, null, "Execute Workflow"),
        value: item.workflowName
      }
    ))), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24 }, /* @__PURE__ */ React.createElement(
      Item,
      {
        marginBottom: "8px",
        labelWidth: 160,
        label: /* @__PURE__ */ React.createElement(Translation, null, "Create Time"),
        value: momentDate(item.createTime)
      }
    ))), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, null, /* @__PURE__ */ React.createElement("div", { className: "trigger-list-operation" }, /* @__PURE__ */ React.createElement(
      "a",
      {
        onClick: () => {
          this.showWebhook(item);
        }
      },
      /* @__PURE__ */ React.createElement(Translation, null, "Manual Trigger")
    ))))))))), /* @__PURE__ */ React.createElement(If, { condition: !triggers || triggers.length == 0 }, /* @__PURE__ */ React.createElement(
      Empty,
      {
        style: { minHeight: "400px" },
        message: /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(Translation, null, "There are no triggers"))
      }
    ))), /* @__PURE__ */ React.createElement(If, { condition: showTrigger }, /* @__PURE__ */ React.createElement(
      Dialog,
      {
        v2: true,
        locale: locale().Dialog,
        visible: true,
        onClose: this.closeWebhook,
        footer: /* @__PURE__ */ React.createElement("div", null),
        width: 500,
        title: /* @__PURE__ */ React.createElement(Translation, null, "Trigger Webhook")
      },
      /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24 }, /* @__PURE__ */ React.createElement(
        Item,
        {
          labelWidth: 160,
          label: /* @__PURE__ */ React.createElement(Translation, null, "Webhook URL"),
          value: /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("a", { href: webHookURL }, webHookURL), /* @__PURE__ */ React.createElement(
            CopyToClipboard,
            {
              onCopy: () => {
                Message.success("Copy successfully");
              },
              text: webHookURL
            },
            copy
          ))
        }
      ))),
      /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24 }, /* @__PURE__ */ React.createElement(Item, { labelWidth: 160, label: /* @__PURE__ */ React.createElement(Translation, null, "Method"), value: "Post" }))),
      /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24 }, /* @__PURE__ */ React.createElement(
        Item,
        {
          labelWidth: 160,
          label: /* @__PURE__ */ React.createElement(Translation, null, "Header"),
          value: "content-type: application/json"
        }
      ))),
      /* @__PURE__ */ React.createElement("h4", null, /* @__PURE__ */ React.createElement(Translation, null, "Curl Command"), /* @__PURE__ */ React.createElement(
        CopyToClipboard,
        {
          onCopy: () => {
            Message.success("Copy successfully");
          },
          text: command
        },
        copy
      )),
      /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Tab, { size: "small", shape: "wrapped" }, customTriggerTypes.map((item) => /* @__PURE__ */ React.createElement(Tab.Item, { className: "justify-tabs-tab", onClick: () => {
        this.handleCustomTriggerTab(item);
      }, key: item, title: item }, /* @__PURE__ */ React.createElement(Col, { span: 24, className: "curlCode" }, /* @__PURE__ */ React.createElement("code", null, command), /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(Translation, null, "Please set the properties that need to be changed, such as `image`, `step`."), /* @__PURE__ */ React.createElement(Message, { type: "notice" }, " If action is not provided then it will by default execute the workflow.")))))))
    )));
  }
}
export default TriggerList;
