"use strict";
import { Grid, Checkbox, Dropdown, Menu, Loading, Button, Icon } from "@alifd/next";
import Ansi from "ansi-to-react";
import React, { Component, Fragment } from "react";
import { listContainerLog } from "../../../../api/observation";
import { Translation } from "../../../../components/Translation";
import { momentDate, momentShortDate } from "../../../../utils/common";
import { downloadStringFile } from "../../../../utils/utils";
import "./index.less";
import { If } from "../../../../components/If";
import { FaEllipsisV } from "react-icons/fa";
class ContainerLog extends Component {
  constructor(props) {
    super(props);
    this.maxTailLine = 3e3;
    this.toLogLines = (logs) => {
      const logLines = logs.split("\n");
      const lines = [];
      logLines.map((line) => {
        if (line) {
          const startsWithDate = 0 <= Number(line[0]) && Number(line[0]) <= 9;
          const idx = line.indexOf(" ");
          if (idx > 0 && startsWithDate) {
            const timestamp = line.substring(0, idx);
            const content = line.substring(idx + 1);
            lines.push({ content, timestamp });
          } else {
            lines.push({ content: line, timestamp: "" });
          }
        }
      });
      return lines;
    };
    this.loadContainerLog = () => {
      const { pod, activeContainerName = "" } = this.props;
      const { previous } = this.state;
      if (pod && activeContainerName) {
        listContainerLog({
          cluster: pod.cluster,
          namespace: pod.metadata.namespace,
          pod: pod.metadata.name,
          container: activeContainerName,
          previous,
          timestamps: true,
          tailLines: this.maxTailLine
        }).then((res) => {
          if (res && res.logs) {
            this.setState({ logs: this.toLogLines(res.logs), info: res.info });
          }
          if (res && res.err) {
            this.setState({ logs: this.toLogLines(res.err) });
          }
          const { autoRefresh, refreshInterval } = this.state;
          if (autoRefresh) {
            this.timeoutID = setTimeout(() => this.loadContainerLog(), refreshInterval);
          }
        }).catch(() => {
        }).finally(() => {
          this.setState({ loading: false });
        });
      } else {
        this.setState({ loading: false });
      }
    };
    this.setAutoRefresh = (v) => {
      this.setState({ autoRefresh: v });
      if (!v && this.timeoutID) {
        clearTimeout(this.timeoutID);
        this.timeoutID = void 0;
      }
      if (v && !this.timeoutID) {
        this.loadContainerLog();
      }
    };
    this.downloadLog = () => {
      const { pod, activeContainerName = "" } = this.props;
      const { logs } = this.state;
      let logContent = [];
      logs.map((line) => {
        logContent.push(line.content);
      });
      downloadStringFile(logContent.join("\n"), pod?.metadata.name + "-" + activeContainerName);
    };
    this.state = {
      autoRefresh: true,
      refreshInterval: 5e3,
      logs: [],
      showTimestamps: false,
      previous: false,
      loading: false
    };
  }
  componentDidMount() {
    this.loadContainerLog();
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.pod?.metadata.name != this.props.pod?.metadata.name || nextProps.activeContainerName != this.props.activeContainerName) {
      if (this.timeoutID) {
        clearTimeout(this.timeoutID);
      }
      this.setState(
        {
          logs: [],
          loading: true
        },
        () => {
          this.loadContainerLog();
        }
      );
    }
  }
  componentWillUnmount() {
    if (this.timeoutID) {
      clearTimeout(this.timeoutID);
    }
  }
  render() {
    const { Row, Col } = Grid;
    const { logs, info, showTimestamps, autoRefresh, refreshInterval, previous, loading } = this.state;
    return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "application-logs-actions" }, /* @__PURE__ */ React.createElement(Button, { className: "download", type: "normal", size: "small", onClick: this.downloadLog }, /* @__PURE__ */ React.createElement(Icon, { type: "download" })), /* @__PURE__ */ React.createElement(Checkbox, { checked: showTimestamps, onChange: (v) => this.setState({ showTimestamps: v }) }, /* @__PURE__ */ React.createElement(Translation, { className: "font-bold font-size-14" }, "Show timestamps")), /* @__PURE__ */ React.createElement(Checkbox, { checked: autoRefresh, onChange: this.setAutoRefresh }, /* @__PURE__ */ React.createElement(Translation, { className: "font-bold font-size-14" }, "Auto-refresh"), "(every", refreshInterval / 1e3, " s.)"), /* @__PURE__ */ React.createElement(Dropdown, { trigger: /* @__PURE__ */ React.createElement(FaEllipsisV, null) }, /* @__PURE__ */ React.createElement(Menu, null, /* @__PURE__ */ React.createElement(Menu.Item, null, /* @__PURE__ */ React.createElement(Checkbox, { checked: previous, onChange: (v) => this.setState({ previous: v }) }, /* @__PURE__ */ React.createElement(Translation, { className: "font-bold font-size-14" }, "Show previous logs")))))), /* @__PURE__ */ React.createElement(Loading, { visible: loading, inline: false }, /* @__PURE__ */ React.createElement("div", { className: "application-logs-wrapper margin-top-15" }, /* @__PURE__ */ React.createElement("div", { className: "logBox" }, logs.map((line) => {
      return /* @__PURE__ */ React.createElement("div", { className: "logLine" }, /* @__PURE__ */ React.createElement("span", { className: "content" }, /* @__PURE__ */ React.createElement(If, { condition: showTimestamps }, momentDate(line.timestamp), " | ", /* @__PURE__ */ React.createElement(Ansi, null, line.content)), /* @__PURE__ */ React.createElement(If, { condition: !showTimestamps }, /* @__PURE__ */ React.createElement(Ansi, { linkify: true }, line.content))));
    })), /* @__PURE__ */ React.createElement(Row, { style: { width: "100%" }, className: "margin-top-15" }, /* @__PURE__ */ React.createElement(Col, { span: 12 }, /* @__PURE__ */ React.createElement("span", { style: { float: "left", marginLeft: "16px" } }, /* @__PURE__ */ React.createElement(Translation, { className: "font-bold font-size-14" }, "Logs Date"), ":", /* @__PURE__ */ React.createElement(
      "span",
      {
        style: { marginLeft: "8px" },
        className: "logDate font-bold font-size-14",
        title: momentDate(info?.fromDate)
      },
      momentShortDate(info?.fromDate)
    ), "~", /* @__PURE__ */ React.createElement("span", { className: "logDate font-bold font-size-14", title: momentDate(info?.toDate) }, momentShortDate(info?.toDate))))))));
  }
}
export default ContainerLog;
