"use strict";
import { Table, Button, Tag, Balloon, Dialog, Message } from "@alifd/next";
import React, { Component, Fragment } from "react";
import Permission from "../../../../components/Permission";
import { Translation } from "../../../../components/Translation";
import { momentDate } from "../../../../utils/common";
import { locale } from "../../../../utils/locale";
import "./index.less";
import { deleteProjectConfigDistribution, getProjectConfigDistributions } from "../../../../api/config";
import { HiOutlineRefresh } from "react-icons/hi";
class ConfigDistributionPage extends Component {
  constructor(props) {
    super(props);
    this.listConfigDistributions = async (projectName) => {
      this.setState({
        isLoading: true
      });
      getProjectConfigDistributions({ projectName }).then((res) => {
        this.setState({
          distributions: res && Array.isArray(res.distributions) ? res.distributions : []
        });
      }).finally(() => {
        this.setState({
          isLoading: false
        });
      });
    };
    this.onDelete = (record) => {
      const { projectName } = this.props;
      Dialog.confirm({
        type: "confirm",
        content: /* @__PURE__ */ React.createElement(Translation, null, "Are you sure to delete?"),
        onOk: () => {
          deleteProjectConfigDistribution(projectName, record.name).then((res) => {
            if (res) {
              Message.success("Distribution deleted successfully");
              this.listConfigDistributions(projectName);
            }
          });
        },
        locale: locale().Dialog
      });
    };
    this.state = {
      distributions: [],
      isLoading: false
    };
  }
  componentDidMount() {
    const { projectName } = this.props;
    this.listConfigDistributions(projectName);
  }
  shouldComponentUpdate(nextProps) {
    const change = nextProps.projectName !== this.props.projectName;
    if (change) {
      this.listConfigDistributions(nextProps.projectName);
    }
    return true;
  }
  render() {
    const { projectName } = this.props;
    const columns = [
      {
        key: "name",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Name"),
        dataIndex: "name",
        cell: (v) => {
          return /* @__PURE__ */ React.createElement("span", null, v);
        }
      },
      {
        key: "configs",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Configs"),
        dataIndex: "configs",
        cell: (v) => {
          return /* @__PURE__ */ React.createElement("div", null, v.map((i) => {
            return /* @__PURE__ */ React.createElement(Tag, null, i.namespace, "/", i.name);
          }));
        }
      },
      {
        key: "targets",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Targets"),
        dataIndex: "targets",
        cell: (targets, i, record) => {
          const targetStatus = /* @__PURE__ */ new Map();
          record.status?.workflow?.steps?.map((step) => {
            if (step.name) {
              const target = step.name.split("-");
              if (target.length >= 3 && target[0] == "deploy") {
                targetStatus.set(step.name.replace("deploy-", ""), step);
              }
            }
          });
          return /* @__PURE__ */ React.createElement("div", null, targets.map((t) => {
            const step = targetStatus.get(`${t.clusterName}-${t.namespace}`);
            const tag = /* @__PURE__ */ React.createElement(
              Tag,
              {
                animation: true,
                color: step?.phase === "succeeded" ? "green" : step?.phase === "failed" ? "red" : "yellow"
              },
              t.clusterName,
              "/",
              t.namespace,
              "/",
              step?.phase
            );
            return step?.message ? /* @__PURE__ */ React.createElement(Balloon, { trigger: tag }, step.message) : tag;
          }));
        }
      },
      {
        key: "status",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Status"),
        dataIndex: "status.status",
        cell: (v) => {
          if (v == "running") {
            return /* @__PURE__ */ React.createElement("span", { color: "#28a745" }, /* @__PURE__ */ React.createElement(Translation, null, "Completed"));
          }
          return /* @__PURE__ */ React.createElement("span", null, v);
        }
      },
      {
        key: "createdTime",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Create Time"),
        dataIndex: "createdTime",
        cell: (v) => {
          return /* @__PURE__ */ React.createElement("span", null, momentDate(v));
        }
      },
      {
        key: "operation",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Actions"),
        dataIndex: "operation",
        cell: (v, i, record) => {
          return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement(
            Permission,
            {
              request: {
                resource: `project:${projectName}/config:${record.name}`,
                action: "delete"
              },
              project: ""
            },
            /* @__PURE__ */ React.createElement(
              Button,
              {
                text: true,
                size: "medium",
                component: "a",
                onClick: () => {
                  this.onDelete(record);
                }
              },
              /* @__PURE__ */ React.createElement(Translation, null, "Delete")
            )
          ));
        }
      }
    ];
    const { Column } = Table;
    const { distributions, isLoading } = this.state;
    return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "config-wrapper" }, /* @__PURE__ */ React.createElement("section", { className: "card-title-wrapper" }, /* @__PURE__ */ React.createElement("span", { className: "card-title" }, /* @__PURE__ */ React.createElement(Translation, null, "Config Distributions")), /* @__PURE__ */ React.createElement(
      Button,
      {
        type: "secondary",
        onClick: () => {
          this.listConfigDistributions(projectName);
        },
        className: "card-button-wrapper"
      },
      /* @__PURE__ */ React.createElement(HiOutlineRefresh, null)
    )), /* @__PURE__ */ React.createElement("section", { className: "card-content-table" }, /* @__PURE__ */ React.createElement(Table, { locale: locale().Table, dataSource: distributions, hasBorder: true, loading: isLoading }, columns.map((col, key) => /* @__PURE__ */ React.createElement(Column, { ...col, key, align: "left" }))))));
  }
}
export default ConfigDistributionPage;
