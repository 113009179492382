"use strict";
import { Input } from "@alifd/next";
import React from "react";
class CPUNumber extends React.Component {
  constructor(props) {
    super(props);
    this.componentDidMount = async () => {
    };
    this.onChange = (value) => {
      const { onChange } = this.props;
      if (onChange) {
        onChange(`${value}`);
      }
    };
    this.state = {
      dataSource: []
    };
  }
  render() {
    const { value, id, disabled } = this.props;
    let initValue = void 0;
    if (value) {
      initValue = parseFloat(value);
    }
    return /* @__PURE__ */ React.createElement(
      Input,
      {
        id,
        disabled,
        addonTextAfter: "Core",
        htmlType: "number",
        onChange: this.onChange,
        value: initValue && initValue
      }
    );
  }
}
export default CPUNumber;
