"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Loading, Select } from "@alifd/next";
import { connect } from "dva";
import React, { Component } from "react";
import { getCharts } from "../../api/repository";
import i18n from "../../i18n";
import { locale } from "../../utils/locale";
let HelmChartSelect = class extends Component {
  constructor(props) {
    super(props);
    this.loadCharts = () => {
      const { project } = this.props;
      const { helm, repo } = this.props;
      if (helm?.url && (!this.state.loading || this.state.helm?.url != helm.url)) {
        if (this.state.helm) {
          this.props.onChange("");
        }
        this.setState({ loading: true, helm });
        getCharts({ url: helm.url, repoType: helm.repoType, secretName: repo?.secretName, project }).then(
          (re) => {
            this.setState({ charts: re && Array.isArray(re) ? re : [], loading: false, helm });
          }
        );
      }
    };
    this.onSearch = (value) => {
      this.setState({ inputChart: value });
    };
    this.state = {
      loading: false,
      inputChart: ""
    };
  }
  componentDidMount() {
    this.loadCharts();
  }
  render() {
    const { disabled, value, onChange, helm } = this.props;
    const { charts, loading, helm: stateHelm, inputChart } = this.state;
    if (helm?.url != stateHelm?.url) {
      this.loadCharts();
    }
    const dataSource = (charts || []).map((item) => {
      return {
        label: item,
        value: item
      };
    });
    if (inputChart) {
      dataSource.unshift({
        label: inputChart,
        value: inputChart
      });
    }
    return /* @__PURE__ */ React.createElement(Loading, { visible: loading, style: { width: "100%" } }, /* @__PURE__ */ React.createElement(
      Select,
      {
        placeholder: i18n.t("Please select or input a chart name"),
        onChange,
        showSearch: true,
        onSearch: this.onSearch,
        followTrigger: true,
        disabled,
        value,
        dataSource,
        locale: locale().Select
      }
    ));
  }
};
HelmChartSelect = __decorateClass([
  connect((store) => {
    return { ...store.uischema };
  })
], HelmChartSelect);
export default HelmChartSelect;
