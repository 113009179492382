"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Table, Button, Message, Dialog, Card } from "@alifd/next";
import { connect } from "dva";
import { Link, routerRedux } from "dva/router";
import querystring from "query-string";
import React from "react";
import { deployApplication } from "../../api/application";
import { listApplicationPods, listCloudResources } from "../../api/observation";
import { If } from "../../components/If";
import StatusShow from "../../components/StatusShow";
import { Translation } from "../../components/Translation";
import i18n from "../../i18n";
import { momentDate } from "../../utils/common";
import { handleError } from "../../utils/errors";
import { locale } from "../../utils/locale";
import Header from "./components/Header";
import PodDetail from "./components/PodDetail";
const { Column } = Table;
let ApplicationInstanceList = class extends React.Component {
  constructor(props) {
    super(props);
    this.loadApplicationStatus = async () => {
      const {
        params: { appName, envName }
      } = this.props.match;
      if (envName) {
        this.props.dispatch({
          type: "application/getApplicationStatus",
          payload: { appName, envName },
          callback: (re) => {
            this.loadAppInstances();
          }
        });
      }
    };
    this.convertCloudInstance = (configurations) => {
      const instances = [];
      if (Array.isArray(configurations) && configurations.length > 0) {
        configurations.map((configuration) => {
          let url = "";
          let identifierKey = "";
          if (configuration.metadata.annotations) {
            url = configuration.metadata.annotations["cloud-resource/console-url"];
            identifierKey = configuration.metadata.annotations["cloud-resource/identifier"];
          }
          const outputs = configuration.status?.apply?.outputs;
          let instanceName = "";
          const region = configuration.status?.apply?.region || configuration.spec.region || "";
          if (outputs) {
            if (outputs[identifierKey]) {
              instanceName = outputs[identifierKey].value;
            }
            if (url) {
              const params = url.match(/\{(.+?)\}/g);
              if (Array.isArray(params) && params.length > 0) {
                params.map((param) => {
                  const paramKey = param.replace("{", "").replace("}", "");
                  if (paramKey.toLowerCase() == "region" && region) {
                    url = url.replace(param, region);
                  }
                  if (outputs[paramKey]) {
                    url = url.replace(param, outputs[paramKey].value);
                  }
                });
              }
            }
          }
          instances.push({
            instanceName,
            status: configuration.status?.apply?.state || "-",
            url,
            createTime: configuration.metadata.creationTimestamp || "",
            region,
            message: configuration.status?.apply?.message,
            type: configuration.metadata.labels && configuration.metadata.labels["workload.oam.dev/type"]
          });
        });
      }
      this.setState({ cloudInstance: instances });
    };
    this.loadAppInstances = async () => {
      this.setState({ podList: [] });
      const { applicationDetail, envbinding, components } = this.props;
      const {
        params: { appName, envName }
      } = this.props.match;
      const cloudComponents = components?.filter((c) => c.workloadType?.type == "configurations.terraform.core.oam.dev");
      const showCloudInstance = cloudComponents?.length && cloudComponents?.length > 0;
      const queryPod = cloudComponents?.length == void 0 || components?.length && components.length > cloudComponents?.length;
      const { target, componentName } = this.state;
      const envs = envbinding.filter((item) => item.name == envName);
      if (applicationDetail && applicationDetail.name && envs.length > 0) {
        const param = {
          appName: envs[0].appDeployName || appName,
          appNs: envs[0].appDeployNamespace,
          componentName,
          cluster: "",
          clusterNs: ""
        };
        if (target) {
          param.cluster = target.cluster?.clusterName || "";
          param.clusterNs = target.cluster?.namespace || "";
        }
        this.setState({ loading: true });
        if (queryPod) {
          listApplicationPods(param).then((re) => {
            if (re && re.podList) {
              re.podList.map((item) => {
                item.primaryKey = item.metadata.name;
              });
              this.setState({ podList: re.podList });
            } else {
              this.setState({ podList: [] });
            }
          }).finally(() => {
            this.setState({ loading: false });
          });
        }
        if (showCloudInstance) {
          this.setState({ loading: true });
          listCloudResources(param).then((cloudResources) => {
            if (cloudResources) {
              this.convertCloudInstance(cloudResources["cloud-resources"]);
            }
          }).finally(() => {
            this.setState({ loading: false });
          });
        } else {
          this.setState({ loading: false });
        }
      } else {
        setTimeout(() => {
          this.loadAppInstances();
        }, 1e3);
      }
    };
    this.getColumns = () => {
      const { applicationDetail, components } = this.props;
      const getColor = (status) => {
        switch (status) {
          case "Running":
            return "#28a745";
          default:
            return "#000";
        }
      };
      const targets = this.getTargets();
      const targetMap = /* @__PURE__ */ new Map();
      targets?.map((item) => {
        targetMap.set(item.cluster?.clusterName + "-" + item.cluster?.namespace, item);
      });
      const getTarget = (key) => {
        return targetMap.get(key);
      };
      const componentNameAlias = {};
      components?.map((c) => {
        componentNameAlias[c.name] = c.alias || c.name;
      });
      return [
        {
          key: "podName",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Pod Name"),
          dataIndex: "metadata.name",
          cell: (v, index, record) => {
            return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", null, record.metadata.name), /* @__PURE__ */ React.createElement("div", null, record.status.podIP));
          }
        },
        {
          key: "clusterName",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Target"),
          dataIndex: "cluster",
          cell: (v, index, record) => {
            const target = getTarget(record.cluster + "-" + record.metadata.namespace);
            if (target) {
              return /* @__PURE__ */ React.createElement("span", null, target?.alias || target?.name);
            }
            return /* @__PURE__ */ React.createElement("span", null, record.cluster, "/", record.metadata.namespace);
          }
        },
        {
          key: "component",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Component"),
          dataIndex: "component",
          cell: (v) => {
            return componentNameAlias[v];
          }
        },
        {
          key: "status",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Status"),
          dataIndex: "status.phase",
          cell: (v) => {
            return /* @__PURE__ */ React.createElement("span", { style: { color: getColor(v) } }, v);
          }
        },
        {
          key: "createTime",
          title: /* @__PURE__ */ React.createElement(Translation, null, "CreateTime"),
          dataIndex: "metadata.creationTime",
          cell: (v) => {
            return /* @__PURE__ */ React.createElement("span", null, momentDate(v));
          }
        },
        {
          key: "deployVersion",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Revision"),
          dataIndex: "metadata.version.deployVersion",
          cell: (v) => {
            return /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(Link, { to: `/applications/${applicationDetail?.name}/revisions` }, v));
          }
        },
        {
          key: "workload",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Workload Type"),
          dataIndex: "workload.kind",
          cell: (v) => {
            return /* @__PURE__ */ React.createElement("span", null, v);
          }
        },
        {
          key: "nodeName",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Node"),
          dataIndex: "status.nodeName",
          cell: (v, index, record) => {
            return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", null, record.status.nodeName), /* @__PURE__ */ React.createElement("div", null, record.status.hostIP));
          }
        }
      ];
    };
    this.updateQuery = (params) => {
      const targets = this.getTargets()?.filter((item) => item.name == params.target);
      let target = void 0;
      if (targets && targets.length > 0) {
        target = targets[0];
      }
      this.setState({ target, componentName: params.component }, () => {
        this.loadAppInstances();
      });
    };
    this.getTargets = () => {
      const { envbinding, match } = this.props;
      const env = envbinding.filter((item) => item.name == match.params.envName);
      if (env.length > 0) {
        return env[0].targets;
      }
      return [];
    };
    this.onRowOpen = (openRowKeys) => {
      this.setState({ openRowKeys });
    };
    this.onDeploy = (force) => {
      const { envbinding, dispatch } = this.props;
      const {
        params: { appName, envName }
      } = this.props.match;
      const envs = envbinding.filter((item) => item.name == envName);
      if (envs) {
        this.setState({ deployLoading: true });
        deployApplication(
          {
            appName,
            workflowName: "workflow-" + envs[0].name,
            triggerType: "web",
            force: force || false
          },
          true
        ).then((re) => {
          if (re) {
            Message.success(i18n.t("Application deployed successfully"));
            this.setState({ deployLoading: false });
            this.loadApplicationStatus();
            if (re.record && re.record.name && dispatch) {
              dispatch(
                routerRedux.push(`/applications/${appName}/envbinding/${re.envName}/workflow/records/${re.record.name}`)
              );
            }
          }
        }).catch((err) => {
          if (err.BusinessCode === 10004) {
            Dialog.confirm({
              content: "Workflow is executing. Do you want to force a restart?",
              onOk: () => {
                this.onDeploy(true);
              },
              onCancel: () => {
                this.setState({ deployLoading: false });
              },
              locale: locale().Dialog
            });
          } else {
            handleError(err);
          }
        });
      } else {
        Message.warning("Please wait");
      }
    };
    this.getEnvbindingByName = () => {
      const { envbinding } = this.props;
      const {
        params: { envName }
      } = this.props.match;
      return envbinding.find((env) => env.name === envName);
    };
    this.loadStatusDetail = async () => {
      const {
        params: { appName, envName }
      } = this.props.match;
      if (envName) {
        this.setState({ loading: true });
        this.props.dispatch({
          type: "application/getApplicationStatus",
          payload: { appName, envName },
          callback: () => {
            this.setState({ loading: false });
          }
        });
      }
    };
    this.onStatusClose = () => {
      this.setState({ showStatus: false });
    };
    const { params } = props.match;
    this.state = {
      envName: params.envName,
      loading: false,
      openRowKeys: [],
      showStatus: false,
      deployLoading: false
    };
  }
  componentDidMount() {
    this.loadApplicationStatus();
    const query = querystring.parse(location.search);
    if (query && query.pod) {
      this.onRowOpen([query.pod]);
    }
  }
  componentWillReceiveProps(nextProps) {
    const { params } = nextProps.match;
    if (params.envName !== this.state.envName) {
      this.setState({ envName: params.envName }, () => {
        this.loadApplicationStatus();
      });
    }
  }
  render() {
    const { applicationStatus, applicationDetail, components, userInfo } = this.props;
    const { podList, loading, showStatus, cloudInstance, deployLoading } = this.state;
    const columns = this.getColumns();
    const envbinding = this.getEnvbindingByName();
    const expandedRowRender = (record) => {
      return /* @__PURE__ */ React.createElement("div", { style: { margin: "16px 0" } }, /* @__PURE__ */ React.createElement(
        PodDetail,
        {
          env: envbinding,
          userInfo,
          clusterName: record.cluster,
          application: applicationDetail,
          pod: record
        }
      ));
    };
    const {
      params: { envName, appName }
    } = this.props.match;
    const cloudComponents = components?.filter((c) => c.workloadType?.type == "configurations.terraform.core.oam.dev");
    const showCloudInstance = cloudComponents?.length && cloudComponents?.length > 0;
    const onlyShowCloudInstance = showCloudInstance && cloudComponents?.length == components?.length;
    return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
      Header,
      {
        envbinding,
        userInfo,
        targets: this.getTargets(),
        components,
        envName,
        appName,
        applicationDetail,
        applicationStatus,
        updateQuery: (params) => {
          this.updateQuery(params);
        },
        refresh: () => {
          this.loadApplicationStatus();
        },
        dispatch: this.props.dispatch
      }
    ), /* @__PURE__ */ React.createElement(If, { condition: applicationStatus }, /* @__PURE__ */ React.createElement(If, { condition: !onlyShowCloudInstance }, /* @__PURE__ */ React.createElement("div", { style: { overflow: "auto" } }, /* @__PURE__ */ React.createElement(
      Table,
      {
        style: { marginBottom: "32px", minWidth: "1000px" },
        locale: locale().Table,
        className: "podlist-table-wrapper",
        size: "medium",
        primaryKey: "primaryKey",
        loading,
        dataSource: podList || [],
        expandedIndexSimulate: true,
        expandedRowRender,
        openRowKeys: this.state.openRowKeys,
        onRowOpen: (openRowKeys) => {
          this.onRowOpen(openRowKeys);
        }
      },
      columns.map((col) => /* @__PURE__ */ React.createElement(Column, { ...col, key: col.key, align: "left" }))
    ))), /* @__PURE__ */ React.createElement(If, { condition: showCloudInstance }, /* @__PURE__ */ React.createElement(Card, { title: i18n.t("Instances of the cloud service").toString() }, /* @__PURE__ */ React.createElement("div", { style: { overflow: "auto" } }, /* @__PURE__ */ React.createElement(
      Table,
      {
        size: "medium",
        locale: locale().Table,
        style: { minWidth: "1000px" },
        className: "customTable",
        dataSource: cloudInstance,
        primaryKey: "instanceName",
        loading
      },
      /* @__PURE__ */ React.createElement(
        Column,
        {
          align: "left",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Name"),
          dataIndex: "instanceName",
          cell: (value, index, record) => {
            if (record.url) {
              return /* @__PURE__ */ React.createElement("a", { target: "_blank", href: record.url, rel: "noopener noreferrer" }, value);
            }
            return value;
          }
        }
      ),
      /* @__PURE__ */ React.createElement(Column, { align: "left", title: /* @__PURE__ */ React.createElement(Translation, null, "Status"), dataIndex: "status" }),
      /* @__PURE__ */ React.createElement(Column, { align: "left", title: /* @__PURE__ */ React.createElement(Translation, null, "Resource Type"), dataIndex: "type" }),
      /* @__PURE__ */ React.createElement(
        Column,
        {
          align: "left",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Create Time"),
          dataIndex: "createTime",
          cell: (v) => {
            return /* @__PURE__ */ React.createElement("span", null, momentDate(v));
          }
        }
      ),
      /* @__PURE__ */ React.createElement(Column, { align: "left", title: /* @__PURE__ */ React.createElement(Translation, null, "Region"), dataIndex: "region" }),
      /* @__PURE__ */ React.createElement(
        Column,
        {
          align: "left",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Actions"),
          dataIndex: "url",
          cell: (value, index, record) => {
            if (record.instanceName) {
              return /* @__PURE__ */ React.createElement("a", { target: "_blank", href: value, rel: "noopener noreferrer" }, /* @__PURE__ */ React.createElement(Translation, null, "Console"));
            }
            return;
          }
        }
      )
    ))))), /* @__PURE__ */ React.createElement(If, { condition: !applicationStatus }, /* @__PURE__ */ React.createElement("div", { className: "deployNotice" }, /* @__PURE__ */ React.createElement("div", { className: "noticeBox" }, /* @__PURE__ */ React.createElement("h2", null, /* @__PURE__ */ React.createElement(Translation, null, "Not Deploy")), /* @__PURE__ */ React.createElement("div", { className: "desc" }, /* @__PURE__ */ React.createElement(Translation, null, "The current environment has not been deployed.")), /* @__PURE__ */ React.createElement("div", { className: "noticeAction" }, /* @__PURE__ */ React.createElement(
      Button,
      {
        loading: deployLoading,
        disabled: applicationDetail?.readOnly,
        onClick: () => this.onDeploy(),
        type: "primary"
      },
      /* @__PURE__ */ React.createElement(Translation, null, "Deploy")
    ))))), /* @__PURE__ */ React.createElement(If, { condition: showStatus }, /* @__PURE__ */ React.createElement(
      StatusShow,
      {
        loading,
        title: /* @__PURE__ */ React.createElement(Translation, null, "Application Status"),
        applicationStatus,
        loadStatusDetail: this.loadStatusDetail,
        onClose: this.onStatusClose
      }
    )));
  }
};
ApplicationInstanceList = __decorateClass([
  connect((store) => {
    return { ...store.application, ...store.user };
  })
], ApplicationInstanceList);
export default ApplicationInstanceList;
