"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Grid, Field, Form, Message, Button, Input, Select, Card, Loading } from "@alifd/next";
import { connect } from "dva";
import { Link } from "dva/router";
import _ from "lodash";
import React from "react";
import {
  createApplicationComponent,
  updateComponentProperties,
  getApplicationComponent
} from "../../../../api/application";
import { detailComponentDefinition } from "../../../../api/definitions";
import DrawerWithFooter from "../../../../components/Drawer";
import { Translation } from "../../../../components/Translation";
import UISchema from "../../../../components/UISchema";
import i18n from "../../../../i18n";
import { checkName } from "../../../../utils/common";
import { locale } from "../../../../utils/locale";
import { transComponentDefinitions } from "../../../../utils/utils";
import "./index.less";
import Permission from "../../../../components/Permission";
import { If } from "../../../../components/If";
import { BiCodeBlock, BiLaptop } from "react-icons/bi";
let ComponentDialog = class extends React.Component {
  constructor(props) {
    super(props);
    this.onClose = () => {
      this.props.onComponentClose();
    };
    this.onSubmitCreate = () => {
      this.field.validate((error, values) => {
        if (error) {
          return;
        }
        const { appName = "", temporaryTraitList = [] } = this.props;
        const { name, alias = "", description = "", componentType = "", properties, dependsOn = [] } = values;
        const params = {
          name,
          alias,
          description,
          componentType,
          properties: JSON.stringify(properties)
        };
        const traitLists = _.cloneDeep(temporaryTraitList);
        traitLists.forEach((item) => {
          if (item.properties) {
            item.properties = JSON.stringify(item.properties);
          }
        });
        params.name = `${appName}-${name}`;
        params.componentType = componentType;
        params.traits = traitLists;
        params.dependsOn = dependsOn;
        this.setState({ isCreateComponentLoading: true });
        createApplicationComponent(params, { appName }).then((res) => {
          if (res) {
            Message.success({
              duration: 4e3,
              content: i18n.t("Component created successfully").toString()
            });
            this.props.onComponentOK();
          }
          this.setState({ isCreateComponentLoading: false });
        });
      });
    };
    this.onDetailsComponentDefinition = (value, callback) => {
      detailComponentDefinition({ name: value }).then((re) => {
        if (re) {
          this.setState({ definitionDetail: re, loading: false });
          if (callback) {
            callback();
          }
        }
      }).catch();
    };
    this.extButtonList = () => {
      const { onComponentClose, isEditComponent, project, componentName, appName } = this.props;
      const { isCreateComponentLoading, isUpdateComponentLoading } = this.state;
      return /* @__PURE__ */ React.createElement("div", { className: "footer-actions" }, /* @__PURE__ */ React.createElement(Button, { type: "secondary", onClick: onComponentClose, className: "margin-right-10" }, i18n.t("Cancel").toString()), /* @__PURE__ */ React.createElement(If, { condition: !isEditComponent }, /* @__PURE__ */ React.createElement(
        Permission,
        {
          request: {
            resource: `project:${project}/application:${appName}/component:*`,
            action: "create"
          },
          project
        },
        /* @__PURE__ */ React.createElement(Button, { type: "primary", onClick: this.onSubmitCreate, loading: isCreateComponentLoading }, i18n.t("Create").toString())
      )), /* @__PURE__ */ React.createElement(If, { condition: isEditComponent }, /* @__PURE__ */ React.createElement(
        Permission,
        {
          request: {
            resource: `project:${project}/application:${appName}/component:${componentName || "*"}`,
            action: "update"
          },
          project
        },
        /* @__PURE__ */ React.createElement(Button, { type: "primary", onClick: this.onSubmitEditComponent, loading: isUpdateComponentLoading }, i18n.t("Update").toString())
      )));
    };
    this.showComponentTitle = () => {
      const { isEditComponent } = this.props;
      const { editComponent } = this.state;
      if (isEditComponent && editComponent) {
        const { name = "", alias = "" } = editComponent;
        return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("span", null, alias ? `${alias}(${name})` : name));
      } else {
        return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("span", null, i18n.t("New Component"), " "));
      }
    };
    this.getInitName = () => {
      const { isEditComponent, appName } = this.props;
      if (isEditComponent && appName) {
        return "";
      } else {
        return `${appName}-`;
      }
    };
    this.getTraitList = () => {
      const { editComponent } = this.state;
      const { isEditComponent, temporaryTraitList } = this.props;
      if (isEditComponent && editComponent) {
        return [...editComponent.traits || []];
      } else {
        return [...temporaryTraitList];
      }
    };
    this.onSubmitEditComponent = () => {
      this.field.validate((error, values) => {
        if (error) {
          return;
        }
        const { appName = "", componentName = "" } = this.props;
        const { name, alias = "", description = "", componentType = "", properties, dependsOn = [] } = values;
        const params = {
          name,
          alias,
          description,
          componentType,
          properties: JSON.stringify(properties),
          dependsOn
        };
        this.setState({ isUpdateComponentLoading: true });
        updateComponentProperties(params, { appName, componentName }).then((res) => {
          if (res) {
            Message.success({
              duration: 4e3,
              content: i18n.t("Component updated successfully").toString()
            });
            this.props.onComponentOK();
          }
          this.setState({ isUpdateComponentLoading: false });
        });
      });
    };
    this.removeProperties = () => {
      this.field.remove("properties");
      this.setState({ definitionDetail: void 0 });
    };
    this.getDependsOptions = () => {
      const { components, componentName } = this.props;
      const filterComponents = (components || []).filter((component) => {
        if (componentName && (component.name === componentName || component.dependsOn && component.dependsOn.includes(componentName))) {
          return false;
        } else {
          return true;
        }
      });
      const componentOptions = filterComponents?.map((component) => {
        return {
          label: component.alias ? `${component.alias}(${component.name})` : component.name,
          value: component.name
        };
      });
      return componentOptions || [];
    };
    this.field = new Field(this);
    this.state = {
      isCreateComponentLoading: false,
      isUpdateComponentLoading: false,
      loading: true,
      propertiesMode: "native"
    };
    this.uiSchemaRef = React.createRef();
  }
  componentDidMount() {
    const { isEditComponent, dispatch, appName, project } = this.props;
    if (isEditComponent) {
      this.onGetEditComponentInfo(() => {
        if (this.state.editComponent) {
          const { name, alias, type, description, properties, dependsOn } = this.state.editComponent;
          this.field.setValues({
            name,
            alias,
            componentType: type,
            description,
            properties,
            dependsOn
          });
          if (type) {
            this.onDetailsComponentDefinition(type);
          }
        }
      });
    } else {
      const getInitComponentType = this.field.getValue("componentType") || "";
      this.onDetailsComponentDefinition(getInitComponentType);
    }
    dispatch({
      type: "uischema/setAppName",
      payload: appName
    });
    dispatch({
      type: "uischema/setProject",
      payload: project
    });
  }
  onGetEditComponentInfo(callback) {
    const { appName, componentName } = this.props;
    this.setState({ loading: true });
    if (appName && componentName) {
      getApplicationComponent(appName, componentName).then((res) => {
        if (res) {
          this.setState(
            {
              editComponent: res,
              loading: false
            },
            callback
          );
        }
      });
    }
  }
  render() {
    const init = this.field.init;
    const FormItem = Form.Item;
    const { Row, Col } = Grid;
    const { isEditComponent, componentDefinitions, onComponentClose } = this.props;
    const { definitionDetail, loading, propertiesMode } = this.state;
    const validator = (rule, value, callback) => {
      this.uiSchemaRef.current?.validate(callback);
    };
    return /* @__PURE__ */ React.createElement(
      DrawerWithFooter,
      {
        title: this.showComponentTitle(),
        placement: "right",
        width: 800,
        onClose: onComponentClose,
        extButtons: this.extButtonList()
      },
      /* @__PURE__ */ React.createElement(Form, { field: this.field, className: "basic-config-wrapper" }, /* @__PURE__ */ React.createElement(Loading, { visible: loading, style: { width: "100%" } }, /* @__PURE__ */ React.createElement(Card, { contentHeight: "auto", title: "Basic Configuration" }, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 12, style: { paddingRight: "8px" } }, /* @__PURE__ */ React.createElement(
        FormItem,
        {
          label: /* @__PURE__ */ React.createElement(Translation, { className: "font-size-14 font-weight-bold color333" }, "Name"),
          labelTextAlign: "left",
          required: true
        },
        /* @__PURE__ */ React.createElement(
          Input,
          {
            name: "name",
            maxLength: 32,
            disabled: isEditComponent ? true : false,
            addonTextBefore: this.getInitName(),
            ...init("name", {
              rules: [
                {
                  required: true,
                  pattern: checkName,
                  message: "Please enter a valid application name"
                }
              ]
            })
          }
        )
      )), /* @__PURE__ */ React.createElement(Col, { span: 12, style: { paddingLeft: "8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Alias") }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "alias",
          placeholder: i18n.t("Please enter").toString(),
          ...init("alias", {
            rules: [
              {
                minLength: 2,
                maxLength: 64,
                message: "Enter a string of 2 to 64 characters."
              }
            ]
          })
        }
      )))), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24 }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Description") }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "description",
          placeholder: i18n.t("Please enter").toString(),
          ...init("description", {
            rules: [
              {
                maxLength: 256,
                message: "Enter a description that contains less than 256 characters."
              }
            ]
          })
        }
      )))), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 12, style: { paddingRight: "8px" } }, /* @__PURE__ */ React.createElement(
        FormItem,
        {
          label: /* @__PURE__ */ React.createElement(Translation, { className: "font-size-14 font-weight-bold color333" }, "Type"),
          required: true,
          help: /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(Translation, null, "Get more component type?"), /* @__PURE__ */ React.createElement(Link, { to: "/addons" }, /* @__PURE__ */ React.createElement(Translation, null, "Go to enable addon")))
        },
        /* @__PURE__ */ React.createElement(
          Select,
          {
            locale: locale().Select,
            showSearch: true,
            disabled: isEditComponent ? true : false,
            className: "select",
            ...init(`componentType`, {
              initValue: isEditComponent ? "" : "webservice",
              rules: [
                {
                  required: true,
                  message: i18n.t("Please select")
                }
              ]
            }),
            dataSource: transComponentDefinitions(componentDefinitions),
            onChange: (item) => {
              this.removeProperties();
              this.field.setValue("componentType", item);
              this.onDetailsComponentDefinition(item);
            }
          }
        )
      )), /* @__PURE__ */ React.createElement(Col, { span: 12, style: { paddingRight: "8px" } }, /* @__PURE__ */ React.createElement(
        FormItem,
        {
          label: /* @__PURE__ */ React.createElement(Translation, { className: "font-size-14 font-weight-bold color333" }, "Depends On")
        },
        /* @__PURE__ */ React.createElement(
          Select,
          {
            ...init(`dependsOn`, {
              rules: [
                {
                  required: false,
                  message: i18n.t("Please select")
                }
              ]
            }),
            locale: locale().Select,
            mode: "multiple",
            dataSource: this.getDependsOptions()
          }
        )
      ))))), /* @__PURE__ */ React.createElement(
        Card,
        {
          contentHeight: "auto",
          className: "withActions",
          title: "Deployment Properties",
          subTitle: definitionDetail && definitionDetail.uiSchema ? [
            /* @__PURE__ */ React.createElement(
              Button,
              {
                style: { alignItems: "center", display: "flex" },
                onClick: () => {
                  if (propertiesMode === "native") {
                    this.setState({ propertiesMode: "code" });
                  } else {
                    this.setState({ propertiesMode: "native" });
                  }
                }
              },
              propertiesMode === "native" && /* @__PURE__ */ React.createElement(BiCodeBlock, { size: 14, title: i18n.t("Switch to the coding mode") }),
              propertiesMode === "code" && /* @__PURE__ */ React.createElement(BiLaptop, { size: 14, title: i18n.t("Switch to the native mode") })
            )
          ] : []
        },
        /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(If, { condition: definitionDetail }, /* @__PURE__ */ React.createElement(
          UISchema,
          {
            ...init(`properties`, {
              rules: [
                {
                  validator,
                  message: i18n.t("Please check the component properties")
                }
              ]
            }),
            enableCodeEdit: propertiesMode === "code",
            uiSchema: definitionDetail && definitionDetail.uiSchema,
            definition: {
              name: definitionDetail?.name || "",
              type: "component",
              description: definitionDetail?.description || ""
            },
            ref: this.uiSchemaRef,
            mode: isEditComponent ? "edit" : "new"
          }
        )))
      ))
    );
  }
};
ComponentDialog = __decorateClass([
  connect()
], ComponentDialog);
export default ComponentDialog;
