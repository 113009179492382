"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Button, Card, Form, Grid, Loading, Message, Select } from "@alifd/next";
import classNames from "classnames";
import { connect } from "dva";
import { routerRedux } from "dva/router";
import _ from "lodash";
import React from "react";
import { Breadcrumb } from "../../components/Breadcrumb";
import { loadPipeline, updatePipeline } from "../../api/pipeline";
import { getWorkflowDefinitions } from "../../api/workflows";
import { If } from "../../components/If";
import RunPipeline from "../../components/RunPipeline";
import { Translation } from "../../components/Translation";
import { WorkflowPrompt } from "../../components/WorkflowPrompt";
import WorkflowStudio from "../../components/WorkflowStudio";
import { WorkflowYAML } from "../../components/WorkflowYAML";
import { WorkflowContext } from "../../context";
import i18n from "../../i18n";
import { locale } from "../../utils/locale";
import { WorkflowModeOptions } from "../ApplicationWorkflowStudio";
const { Row, Col } = Grid;
const ButtonGroup = Button.Group;
let PipelineStudio = class extends React.Component {
  constructor(props) {
    super(props);
    this.loadPipeline = () => {
      const {
        params: { projectName, pipelineName }
      } = this.props.match;
      loadPipeline({ projectName, pipelineName }).then((res) => {
        this.setState({
          pipeline: res,
          mode: res.spec.mode?.steps || "StepByStep",
          subMode: res.spec.mode?.subSteps || "DAG",
          steps: res.spec.steps
        });
      });
    };
    this.loadWorkflowDefinitions = () => {
      getWorkflowDefinitions("WorkflowRun").then((res) => {
        if (res) {
          this.setState({
            definitions: res && res.definitions
          });
        }
      });
    };
    this.onChange = (steps) => {
      const { pipeline } = this.state;
      this.setState({ steps, changed: !_.isEqual(steps, pipeline?.spec.steps) });
    };
    this.onSave = () => {
      const { pipeline, steps, mode, subMode } = this.state;
      if (pipeline) {
        this.setState({ saveLoading: true });
        updatePipeline({
          alias: pipeline.alias,
          description: pipeline.description,
          name: pipeline.name,
          project: pipeline.project.name,
          spec: {
            steps: steps || [],
            mode: {
              steps: mode,
              subSteps: subMode
            }
          }
        }).then((res) => {
          if (res) {
            Message.success("Pipeline updated successfully");
            this.loadPipeline();
            this.setState({ changed: false });
          }
        }).finally(() => {
          this.setState({ saveLoading: false });
        });
      }
    };
    this.runPipeline = () => {
      const { changed } = this.state;
      if (changed) {
        Message.warning(i18n.t("Please save the changes first."));
        return;
      }
      this.setState({ showRunPipeline: true });
    };
    this.state = {
      changed: false,
      mode: "StepByStep",
      subMode: "DAG",
      editMode: "visual"
    };
  }
  componentDidMount() {
    this.loadPipeline();
    this.loadWorkflowDefinitions();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.match !== this.props.match) {
      this.loadPipeline();
    }
  }
  render() {
    const { pipeline, definitions, changed, saveLoading, mode, subMode, editMode, showRunPipeline } = this.state;
    const { dispatch } = this.props;
    const {
      params: { projectName }
    } = this.props.match;
    return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 12, className: classNames("breadcrumb") }, /* @__PURE__ */ React.createElement(
      Breadcrumb,
      {
        items: [
          {
            to: "/projects/" + projectName + "/pipelines",
            title: projectName
          },
          {
            to: `/projects/${projectName}/pipelines/${pipeline?.name}/studio`,
            title: pipeline && (pipeline.alias || pipeline.name) || ""
          },
          { title: "Studio" }
        ]
      }
    )), /* @__PURE__ */ React.createElement(Col, { span: 12, className: "flexright" }, /* @__PURE__ */ React.createElement(Button, { type: "primary", onClick: this.runPipeline }, /* @__PURE__ */ React.createElement(Translation, null, "Run")))), /* @__PURE__ */ React.createElement("div", { className: "run-layout", style: { marginTop: "16px" } }, changed && /* @__PURE__ */ React.createElement(
      WorkflowPrompt,
      {
        changed,
        content: "Do you want to save your changes?",
        title: i18n.t("Unsaved changes"),
        onSave: this.onSave,
        dispatch,
        onClearChanged: () => {
          this.setState({ changed: false });
        }
      }
    ), /* @__PURE__ */ React.createElement(Card, { contentHeight: "auto" }, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 10 }), /* @__PURE__ */ React.createElement(Col, { span: 4 }, /* @__PURE__ */ React.createElement(ButtonGroup, null, /* @__PURE__ */ React.createElement(
      Button,
      {
        onClick: () => {
          this.setState({ editMode: "visual" });
        },
        className: classNames("edit-mode", { active: editMode === "visual" })
      },
      "VISUAL"
    ), /* @__PURE__ */ React.createElement(
      Button,
      {
        onClick: () => {
          this.setState({ editMode: "yaml" });
        },
        className: classNames("edit-mode", "two", { active: editMode === "yaml" })
      },
      "YAML"
    ))), /* @__PURE__ */ React.createElement(
      Col,
      {
        span: 10,
        style: {
          display: "flex",
          justifyContent: "end"
        }
      },
      changed && /* @__PURE__ */ React.createElement("div", { className: "notice-changes" }, /* @__PURE__ */ React.createElement(Translation, null, "Unsaved changes")),
      /* @__PURE__ */ React.createElement(Form.Item, { label: i18n.t("Mode").toString(), labelAlign: "inset", style: { marginRight: "8px" } }, /* @__PURE__ */ React.createElement(
        Select,
        {
          locale: locale().Select,
          defaultValue: "StepByStep",
          value: mode,
          dataSource: WorkflowModeOptions,
          onChange: (value) => {
            this.setState({ mode: value, changed: this.state.mode !== value });
          }
        }
      )),
      /* @__PURE__ */ React.createElement(Form.Item, { label: i18n.t("Sub Mode").toString(), labelAlign: "inset", style: { marginRight: "8px" } }, /* @__PURE__ */ React.createElement(
        Select,
        {
          locale: locale().Select,
          defaultValue: "DAG",
          value: subMode,
          onChange: (value) => {
            this.setState({ subMode: value, changed: this.state.subMode !== value });
          },
          dataSource: WorkflowModeOptions
        }
      )),
      /* @__PURE__ */ React.createElement(Button, { disabled: !changed, loading: saveLoading, type: "primary", onClick: this.onSave }, /* @__PURE__ */ React.createElement(Translation, null, "Save"))
    ))), !pipeline && /* @__PURE__ */ React.createElement(Loading, { visible: true }), pipeline && editMode === "visual" && /* @__PURE__ */ React.createElement(
      WorkflowContext.Provider,
      {
        value: {
          projectName: pipeline?.project?.name,
          workflow: {
            name: pipeline.name,
            alias: pipeline.alias,
            steps: pipeline.spec.steps,
            mode: pipeline.spec.mode?.steps || "StepByStep",
            subMode: pipeline.spec.mode?.subSteps || "DAG",
            createTime: pipeline.createTime
          }
        }
      },
      /* @__PURE__ */ React.createElement(
        WorkflowStudio,
        {
          subMode: pipeline.spec.mode?.subSteps,
          definitions,
          steps: _.cloneDeep(pipeline.spec.steps),
          onChange: this.onChange
        }
      )
    ), pipeline && editMode === "yaml" && /* @__PURE__ */ React.createElement(WorkflowYAML, { steps: _.cloneDeep(pipeline.spec.steps), name: pipeline.name, onChange: this.onChange })), /* @__PURE__ */ React.createElement(If, { condition: showRunPipeline }, pipeline && /* @__PURE__ */ React.createElement(
      RunPipeline,
      {
        onClose: () => {
          this.setState({ showRunPipeline: false });
        },
        onSuccess: (runName) => {
          this.props.dispatch(
            routerRedux.push(`/projects/${pipeline.project.name}/pipelines/${pipeline.name}/runs/${runName}`)
          );
        },
        pipeline
      }
    )));
  }
};
PipelineStudio = __decorateClass([
  connect(() => {
    return {};
  })
], PipelineStudio);
export default PipelineStudio;
