"use strict";
import {
  Form,
  Button,
  Loading,
  Field,
  Card,
  Dialog,
  Table,
  Message,
  Select,
  Checkbox,
  Grid,
  Dropdown,
  Menu
} from "@alifd/next";
import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { getAddonsDetails, getAddonsStatus, disableAddon, enableAddon, upgradeAddon } from "../../../../api/addons";
import { listApplicationServiceEndpoints } from "../../../../api/observation";
import DrawerWithFooter from "../../../../components/Drawer";
import Empty from "../../../../components/Empty";
import Permission from "../../../../components/Permission";
import StatusShow from "../../../../components/StatusShow";
import { Translation } from "../../../../components/Translation";
import UISchema from "../../../../components/UISchema";
import i18n from "../../../../i18n";
import { locale } from "../../../../utils/locale";
import "github-markdown-css/github-markdown-light.css";
import "./index.less";
import { Link } from "dva/router";
import { getLink } from "../../../../utils/utils";
import { BiCodeBlock, BiLaptop } from "react-icons/bi";
const { Col, Row } = Grid;
class AddonDetailDialog extends React.Component {
  constructor(props) {
    super(props);
    this.loadAddonDetail = async () => {
      const { version } = this.state;
      this.setState({ loading: true });
      getAddonsDetails({ name: this.props.addonName, version }).then((res) => {
        if (res) {
          this.setState({ addonDetailInfo: res, schema: res.uiSchema, loading: false });
          if (!this.state.version && res.version) {
            this.setState({ version: res.version });
          }
        }
      }).finally(() => {
        this.setState({ loading: false });
      });
    };
    this.loadAddonStatus = () => {
      this.setState({ statusLoading: true });
      getAddonsStatus({ name: this.props.addonName }).then((res) => {
        if (!res) {
          return;
        }
        if ((res.phase == "enabling" || res.phase === "disabling") && !this.statusLoop) {
          this.statusLoop = true;
          this.timeout = setTimeout(() => {
            this.statusLoop = false;
            this.loadAddonStatus();
          }, 4e3);
        }
        let clusters = [];
        if (res.args) {
          this.form.setValue("properties", res.args);
          this.setState({ mode: "edit" });
          if (res.args.clusters) {
            clusters = res.args.clusters;
          }
        } else {
          this.setState({ mode: "new" });
        }
        let enabledClusters = [];
        if (res.clusters) {
          enabledClusters = Object.keys(res.clusters);
        }
        if (res.clusters && (!clusters || clusters.length == 0)) {
          clusters = enabledClusters;
        }
        this.setState({
          status: res.phase,
          args: res.args,
          version: res.installedVersion || this.state.version,
          allClusters: res.allClusters,
          statusLoading: false,
          clusters: clusters || ["local"],
          addonsStatus: res.appStatus,
          enabledClusters
        });
      }).catch(() => {
        this.setState({ statusLoading: false });
      });
    };
    this.loadAddonEndpoints = () => {
      const appName = "addon-" + this.props.addonName;
      listApplicationServiceEndpoints({
        appName,
        appNs: "vela-system"
      }).then((re) => {
        if (re && re.endpoints) {
          this.setState({ endpoints: re.endpoints });
        } else {
          this.setState({ endpoints: [] });
        }
      });
    };
    this.onDisable = () => {
      Dialog.confirm({
        content: i18n.t("Please make sure this addon is not used anywhere and related applications recycled."),
        onOk: this.disableAddon,
        locale: locale().Dialog
      });
      return;
    };
    this.onEnable = () => {
      this.form.validate((errors, values) => {
        if (errors) {
          return;
        }
        this.enableAddon(values.properties);
      });
    };
    this.onUpgrade = () => {
      this.form.validate((errors, values) => {
        if (errors) {
          return;
        }
        if (!this.state.version) {
          Message.warning(i18n.t("Please select want to enable version firstly"));
          return;
        }
        if (this.state.addonDetailInfo?.deployTo?.runtimeCluster && (!this.state.clusters || this.state.clusters.length == 0)) {
          Message.warning(i18n.t("Please select at least one cluster firstly."));
          return;
        }
        this.setState({ upgradeLoading: true });
        const params = {
          name: this.props.addonName,
          version: this.state.version,
          properties: values.properties,
          registry: this.state.addonDetailInfo?.registryName
        };
        if (this.state.addonDetailInfo?.deployTo?.runtimeCluster) {
          params.clusters = this.state.clusters;
        }
        upgradeAddon(params).then(() => {
          this.loadAddonStatus();
          this.setState({ upgradeLoading: false });
        });
      });
    };
    this.enableAddon = async (properties) => {
      if (!this.state.version) {
        Message.warning(i18n.t("Please select want to enable version"));
        return;
      }
      if (this.state.addonDetailInfo?.deployTo?.runtimeCluster && (!this.state.clusters || this.state.clusters.length == 0)) {
        Message.warning(i18n.t("Please select at least one cluster firstly."));
        return;
      }
      this.setState({ statusLoading: true, enableLoading: true }, () => {
        if (this.state.version) {
          const params = {
            name: this.props.addonName,
            version: this.state.version,
            properties,
            registry: this.state.addonDetailInfo?.registryName
          };
          if (this.state.addonDetailInfo?.deployTo?.runtimeCluster) {
            params.clusters = this.state.clusters;
          }
          enableAddon(params).then(() => {
            this.loadAddonStatus();
          }).finally(() => {
            this.setState({ enableLoading: false });
          });
        }
      });
    };
    this.disableAddon = async () => {
      this.setState({ statusLoading: true }, () => {
        disableAddon({ name: this.props.addonName }).then(() => {
          this.loadAddonStatus();
        });
      });
    };
    this.onClose = () => {
      this.props.onClose();
    };
    this.updateStatusShow = (show) => {
      this.setState({ showStatusVisible: show });
    };
    this.onStatusClose = () => {
      this.setState({ showStatusVisible: false });
    };
    this.changeVersion = (version) => {
      this.setState({ version }, () => {
        this.setState({ schema: void 0 }, () => {
          this.loadAddonDetail();
        });
      });
    };
    this.changeCluster = (values) => {
      if (!values.includes("local")) {
        Message.warning("The local cluster ie required");
        values.push("local");
      }
      this.setState({ clusters: values });
    };
    this.state = {
      propertiesMode: "native",
      addonDetailInfo: {
        name: ""
      },
      status: "",
      loading: true,
      statusLoading: true,
      upgradeLoading: false,
      showStatusVisible: false
    };
    this.form = new Field(this);
    this.uiSchemaRef = React.createRef();
    this.statusLoop = false;
    this.timeout = null;
  }
  componentDidMount() {
    this.loadAddonDetail();
    this.loadAddonStatus();
    this.loadAddonEndpoints();
  }
  componentWillUnmount() {
    if (this.timeout != null) {
      clearTimeout(this.timeout);
    }
  }
  render() {
    const {
      loading,
      addonDetailInfo,
      status,
      statusLoading,
      upgradeLoading,
      enableLoading,
      addonsStatus,
      showStatusVisible,
      version,
      clusters,
      allClusters,
      enabledClusters,
      endpoints,
      propertiesMode,
      schema
    } = this.state;
    const { showAddon, addonName } = this.props;
    const validator = (rule, value, callback) => {
      this.uiSchemaRef.current?.validate(callback);
    };
    let showName = addonDetailInfo?.name ? addonDetailInfo.name : "Addon Detail";
    showName = `${showName}(${status})`;
    addonDetailInfo?.uiSchema?.map((item) => {
      if (item.jsonKey.indexOf("SECRET_KEY") != -1) {
        item.uiType = "Password";
      }
      if (item.jsonKey.indexOf("ACCESS_KEY") != -1) {
        item.uiType = "Password";
      }
    });
    const buttons = [];
    const workflowStatus = addonsStatus?.status;
    if (status === "enabled" || status === "enabling" || status === "disabling") {
      buttons.push(
        /* @__PURE__ */ React.createElement(
          Permission,
          {
            request: {
              resource: `addon:${addonName}`,
              action: "disable"
            },
            key: "disable",
            project: ""
          },
          /* @__PURE__ */ React.createElement(
            Button,
            {
              type: "secondary",
              onClick: this.onDisable,
              title: status,
              className: "danger-btn",
              loading: status === "disabling",
              disabled: status === "disabling"
            },
            /* @__PURE__ */ React.createElement(Translation, null, "Disable")
          )
        )
      );
    }
    if (status == "enabled" || status == "suspend" || workflowStatus == "workflowFailed") {
      buttons.push(
        /* @__PURE__ */ React.createElement(Permission, { key: "upgrade", request: { resource: `addon:${addonName}`, action: "update" }, project: "" }, /* @__PURE__ */ React.createElement(Button, { loading: upgradeLoading, type: "primary", onClick: this.onUpgrade, style: { marginLeft: "16px" } }, /* @__PURE__ */ React.createElement(Translation, null, "Upgrade")))
      );
    }
    if (status === "disabled" || status === "enabling" && workflowStatus != "workflowFailed") {
      buttons.push(
        /* @__PURE__ */ React.createElement(Permission, { key: "enable", request: { resource: `addon:${addonName}`, action: "enable" }, project: "" }, /* @__PURE__ */ React.createElement(
          Button,
          {
            loading: status === "enabling" || enableLoading,
            type: "primary",
            onClick: this.onEnable,
            style: { marginLeft: "16px" }
          },
          /* @__PURE__ */ React.createElement(Translation, null, "Enable")
        ))
      );
    }
    const getAppStatusShowType = (statusInfo) => {
      if (!statusInfo) {
        return "notice";
      }
      switch (statusInfo) {
        case "running":
          return "success";
        case "workflowFinished":
          return "success";
        case "unhealthy":
          return "error";
      }
      return "warning";
    };
    const clusterOptions = allClusters?.map((cluster) => {
      let label = cluster.alias || cluster.name;
      if (enabledClusters?.includes(cluster.name)) {
        label = /* @__PURE__ */ React.createElement("span", null, label, "(", /* @__PURE__ */ React.createElement("span", { style: { color: "green" } }, "enabled"), ")");
      }
      return {
        label,
        value: cluster.name
      };
    });
    const outerEndpoint = endpoints?.filter((end) => !end.endpoint.inner);
    const notice = "This addon is experimental, please don't use it in production";
    return /* @__PURE__ */ React.createElement("div", { className: "basic" }, /* @__PURE__ */ React.createElement(DrawerWithFooter, { title: showName, width: 800, placement: "right", onClose: this.onClose, extButtons: buttons }, /* @__PURE__ */ React.createElement(Loading, { visible: loading, style: { width: "100%" } }, addonDetailInfo && addonDetailInfo.registryName == "experimental" && /* @__PURE__ */ React.createElement(Message, { type: "warning" }, /* @__PURE__ */ React.createElement(Translation, null, notice)), addonsStatus && addonsStatus.status && /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 16 }, /* @__PURE__ */ React.createElement(
      Message,
      {
        type: getAppStatusShowType(addonsStatus?.status),
        size: "medium",
        style: { padding: "8px", marginBottom: "10px" }
      },
      `${i18n.t("Addon status is ")}${addonsStatus?.status || "Init"}`,
      /* @__PURE__ */ React.createElement(Link, { style: { marginLeft: "16px" }, to: `/applications/addon-${addonDetailInfo?.name}/envbinding` }, /* @__PURE__ */ React.createElement(Translation, null, "Check the details"))
    )), outerEndpoint && outerEndpoint?.length > 0 && /* @__PURE__ */ React.createElement(Col, { span: 8, className: "flexright" }, /* @__PURE__ */ React.createElement(
      Dropdown,
      {
        trigger: /* @__PURE__ */ React.createElement(Button, { style: { marginLeft: "16px" }, type: "secondary" }, /* @__PURE__ */ React.createElement(Translation, null, "Endpoints"))
      },
      /* @__PURE__ */ React.createElement(Menu, null, outerEndpoint?.map((item) => {
        const linkURL = getLink(item);
        return /* @__PURE__ */ React.createElement(Menu.Item, { key: linkURL }, item.endpoint.portName && /* @__PURE__ */ React.createElement("span", { className: "margin-right-5" }, item.endpoint.portName, ":"), /* @__PURE__ */ React.createElement("a", { style: { color: "#1b58f4" }, target: "_blank", href: linkURL, rel: "noopener noreferrer" }, linkURL));
      }))
    ))), /* @__PURE__ */ React.createElement(Card, { title: "", contentHeight: "auto", style: { marginBottom: "16px" } }, addonDetailInfo?.availableVersions && /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Form.Item, { required: true, label: i18n.t("Version").toString() }, /* @__PURE__ */ React.createElement(
      Select,
      {
        onChange: this.changeVersion,
        dataSource: addonDetailInfo?.availableVersions || [],
        value: version
      }
    )), addonDetailInfo?.system && /* @__PURE__ */ React.createElement("span", { className: "warning-text" }, "This version requirements: (", addonDetailInfo?.system.vela ? `KubeVela: ${addonDetailInfo?.system.vela}` : "", addonDetailInfo?.system.kubernetes ? ` Kubernetes: ${addonDetailInfo?.system.kubernetes}` : "", ")")), addonDetailInfo?.deployTo?.runtimeCluster && /* @__PURE__ */ React.createElement(Form.Item, { required: true, label: i18n.t("Deployed Clusters").toString() }, /* @__PURE__ */ React.createElement(
      Checkbox.Group,
      {
        className: "custom-cluster-checkbox",
        onChange: this.changeCluster,
        value: clusters,
        dataSource: clusterOptions
      }
    ))), schema && /* @__PURE__ */ React.createElement(
      Card,
      {
        contentHeight: "auto",
        className: "withActions",
        title: "Properties",
        subTitle: schema ? [
          /* @__PURE__ */ React.createElement(
            Button,
            {
              style: { marginTop: "-12px", alignItems: "center", display: "flex" },
              onClick: () => {
                if (propertiesMode === "native") {
                  this.setState({ propertiesMode: "code" });
                } else {
                  this.setState({ propertiesMode: "native" });
                }
              }
            },
            propertiesMode === "native" && /* @__PURE__ */ React.createElement(BiCodeBlock, { size: 14, title: i18n.t("Switch to the coding mode") }),
            propertiesMode === "code" && /* @__PURE__ */ React.createElement(BiLaptop, { size: 14, title: i18n.t("Switch to the native mode") })
          )
        ] : []
      },
      /* @__PURE__ */ React.createElement(Row, null, this.state.mode && schema && /* @__PURE__ */ React.createElement(
        UISchema,
        {
          ...this.form.init(`properties`, {
            rules: [
              {
                validator,
                message: i18n.t("Please check the addon properties")
              }
            ]
          }),
          enableCodeEdit: propertiesMode === "code",
          uiSchema: schema,
          definition: {
            name: addonDetailInfo?.name || "",
            type: "addon",
            description: addonDetailInfo?.description || ""
          },
          ref: this.uiSchemaRef,
          mode: this.state.mode
        }
      ))
    ), addonDetailInfo?.dependencies && /* @__PURE__ */ React.createElement(Card, { locale: locale().Card, contentHeight: "auto", title: /* @__PURE__ */ React.createElement(Translation, null, "Dependencies") }, /* @__PURE__ */ React.createElement(Message, { type: "notice", style: { marginBottom: "16px" } }, /* @__PURE__ */ React.createElement(Translation, null, "Ensure that dependent addon are enabled first.")), /* @__PURE__ */ React.createElement("ul", null, addonDetailInfo?.dependencies?.map((item) => {
      return /* @__PURE__ */ React.createElement("li", { className: "dependency-item", key: item.name }, /* @__PURE__ */ React.createElement(
        "a",
        {
          onClick: () => {
            if (showAddon) {
              showAddon(item.name);
            }
          }
        },
        item.name
      ));
    }))), addonDetailInfo?.definitions && /* @__PURE__ */ React.createElement(
      Card,
      {
        contentHeight: "auto",
        locale: locale().Card,
        title: /* @__PURE__ */ React.createElement(Translation, null, "Definitions"),
        style: { marginTop: "16px" }
      },
      /* @__PURE__ */ React.createElement(Message, { type: "notice", style: { marginBottom: "16px" } }, /* @__PURE__ */ React.createElement(Translation, null, "Enable the addon to obtain the following extension capabilities")),
      /* @__PURE__ */ React.createElement(Table, { locale: locale().Table, dataSource: addonDetailInfo?.definitions }, /* @__PURE__ */ React.createElement(Table.Column, { dataIndex: "name", align: "left", width: "140px", title: /* @__PURE__ */ React.createElement(Translation, null, "Name") }), /* @__PURE__ */ React.createElement(
        Table.Column,
        {
          dataIndex: "type",
          align: "left",
          width: "160px",
          cell: (v) => {
            return /* @__PURE__ */ React.createElement(Translation, null, v);
          },
          title: /* @__PURE__ */ React.createElement(Translation, null, "Type")
        }
      ), /* @__PURE__ */ React.createElement(Table.Column, { dataIndex: "description", align: "center", title: /* @__PURE__ */ React.createElement(Translation, null, "Description") }))
    ), /* @__PURE__ */ React.createElement(
      Card,
      {
        contentHeight: "auto",
        locale: locale().Card,
        title: /* @__PURE__ */ React.createElement(Translation, null, "README"),
        style: { marginTop: "16px" }
      },
      addonDetailInfo?.detail && /* @__PURE__ */ React.createElement(ReactMarkdown, { className: "markdown-body addon-readme", remarkPlugins: [remarkGfm] }, addonDetailInfo?.detail || ""),
      !addonDetailInfo?.detail && /* @__PURE__ */ React.createElement("div", { className: "readme-empty" }, /* @__PURE__ */ React.createElement(Empty, null))
    ))), showStatusVisible && /* @__PURE__ */ React.createElement(
      StatusShow,
      {
        loading: statusLoading,
        title: /* @__PURE__ */ React.createElement(Translation, null, "Addon Status"),
        applicationStatus: addonsStatus,
        loadStatusDetail: this.loadAddonStatus,
        onClose: this.onStatusClose
      }
    ));
  }
}
export default AddonDetailDialog;
