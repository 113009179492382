"use strict";
import { Balloon, Card, Dialog, Grid } from "@alifd/next";
import React, { Component } from "react";
import { If } from "../../../../components/If";
import Item from "../../../../components/Item";
import Permission from "../../../../components/Permission";
import { beautifyTime, momentDate } from "../../../../utils/common";
import "./index.less";
import Empty from "../../../../components/Empty";
import { Translation } from "../../../../components/Translation";
import { locale } from "../../../../utils/locale";
import { AiOutlineDelete } from "react-icons/ai";
class PolicyList extends Component {
  constructor(props) {
    super(props);
    this.handlePolicyDelete = (name) => {
      Dialog.alert({
        content: "Are you sure want to delete this policy?",
        onOk: () => {
          this.props.onDeletePolicy(name);
        },
        onClose: () => {
        },
        locale: locale().Dialog
      });
    };
    this.state = {};
  }
  render() {
    const { Row, Col } = Grid;
    const { policies, envbinding, applicationDetail } = this.props;
    const envNameAlias = {};
    envNameAlias[""] = "-";
    envbinding?.map((item) => {
      envNameAlias[item.name] = item.alias;
    });
    const projectName = applicationDetail && applicationDetail.project?.name;
    return /* @__PURE__ */ React.createElement("div", { className: "list-warper" }, /* @__PURE__ */ React.createElement("div", { className: "box" }, /* @__PURE__ */ React.createElement(Row, { wrap: true }, (policies || []).map((item) => /* @__PURE__ */ React.createElement(Col, { span: 24, key: item.type + item.name, className: "box-item" }, /* @__PURE__ */ React.createElement(Card, { free: true, style: { padding: "16px" }, hasBorder: true, contentHeight: "auto", locale: locale().Card }, /* @__PURE__ */ React.createElement("div", { className: "policy-list-nav" }, /* @__PURE__ */ React.createElement("div", { className: "policy-list-title" }, /* @__PURE__ */ React.createElement("a", { onClick: () => this.props.onShowPolicy(item.name) }, /* @__PURE__ */ React.createElement(Balloon, { trigger: /* @__PURE__ */ React.createElement("span", null, item.alias ? item.alias : item.name) }, item.description))), /* @__PURE__ */ React.createElement("div", { className: "trigger-list-operation" }, /* @__PURE__ */ React.createElement(
      Permission,
      {
        request: {
          resource: `project:${projectName}/application:${applicationDetail?.name}/policy:${item.name}`,
          action: "delete"
        },
        project: projectName
      },
      /* @__PURE__ */ React.createElement(
        AiOutlineDelete,
        {
          size: 14,
          className: "margin-right-0 cursor-pointer danger-icon",
          onClick: () => {
            this.handlePolicyDelete(item.name);
          }
        }
      )
    ))), /* @__PURE__ */ React.createElement("div", { className: "policy-list-content" }, /* @__PURE__ */ React.createElement(Row, { wrap: true }, /* @__PURE__ */ React.createElement(Col, { span: 24 }, /* @__PURE__ */ React.createElement(
      Item,
      {
        marginBottom: "8px",
        labelWidth: 160,
        label: /* @__PURE__ */ React.createElement(Translation, null, "Type"),
        value: item.type
      }
    )), /* @__PURE__ */ React.createElement(Col, { span: 24 }, /* @__PURE__ */ React.createElement(
      Item,
      {
        marginBottom: "8px",
        labelWidth: 160,
        label: /* @__PURE__ */ React.createElement(Translation, null, "Environment"),
        value: envNameAlias[item.envName || ""]
      }
    )), /* @__PURE__ */ React.createElement(Col, { span: 24 }, /* @__PURE__ */ React.createElement(
      Item,
      {
        marginBottom: "8px",
        labelWidth: 160,
        label: /* @__PURE__ */ React.createElement(Translation, null, "Create Time"),
        value: /* @__PURE__ */ React.createElement("span", { title: momentDate(item.createTime) }, beautifyTime(item.createTime))
      }
    )))))))), /* @__PURE__ */ React.createElement(If, { condition: !policies || policies.length == 0 }, /* @__PURE__ */ React.createElement(
      Empty,
      {
        style: { minHeight: "400px" },
        message: /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(Translation, null, "There are no policies"))
      }
    ))));
  }
}
export default PolicyList;
