"use strict";
import { Dialog } from "@alifd/next";
import { Prompt, routerRedux } from "dva/router";
import React, { useEffect } from "react";
import { locale } from "../../utils/locale";
export const WorkflowPrompt = React.memo(({ changed, content, onSave, dispatch, onClearChanged }) => {
  useEffect(() => {
    const handleUnload = (event) => {
      if (changed) {
        event.preventDefault();
        event.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", handleUnload);
    return () => window.removeEventListener("beforeunload", handleUnload);
  }, [changed]);
  const onHistoryBlock = (location) => {
    if (changed) {
      Dialog.confirm({
        content: /* @__PURE__ */ React.createElement("div", null, content),
        locale: locale().Dialog,
        onOk: () => {
          onSave();
          onClearChanged();
          moveToBlockedLocationAfterReactStateUpdate(dispatch, location);
        },
        onCancel: () => {
          onClearChanged();
          moveToBlockedLocationAfterReactStateUpdate(dispatch, location);
        }
      });
      return false;
    }
    return false;
  };
  return /* @__PURE__ */ React.createElement(Prompt, { when: true, message: onHistoryBlock });
});
WorkflowPrompt.displayName = "WorkflowPrompt";
function moveToBlockedLocationAfterReactStateUpdate(dispatch, location) {
  if (location) {
    setTimeout(() => {
      if (dispatch) {
        dispatch(routerRedux.push(location));
      }
    }, 10);
  }
}
