"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Grid, Button, Card, Message, Dialog, Balloon, Tag, Loading } from "@alifd/next";
import React, { Component } from "react";
import "./index.less";
import { connect } from "dva";
import {
  deleteTrait,
  getApplicationTriggers,
  deleteTrigger,
  deleteComponent,
  deleteApplication,
  deletePolicy,
  getPolicyDetail,
  getApplicationStatistics
} from "../../api/application";
import { getComponentDefinitions } from "../../api/definitions";
import { If } from "../../components/If";
import Item from "../../components/Item";
import NumItem from "../../components/NumItem";
import Permission from "../../components/Permission";
import { Title } from "../../components/Title";
import { Translation } from "../../components/Translation";
import { routerRedux, Link } from "dva/router";
import i18n from "../../i18n";
import { beautifyTime, momentDate, showAlias } from "../../utils/common";
import { handleError } from "../../utils/errors";
import { locale } from "../../utils/locale";
import EditAppDialog from "../ApplicationList/components/EditAppDialog";
import ComponentDialog from "./components/ComponentDialog";
import Components from "./components/Components";
import PolicyDialog from "./components/PolicyDialog";
import PolicyList from "./components/PolicyList";
import TraitDialog from "./components/TraitDialog";
import TriggerDialog from "./components/TriggerDialog";
import TriggerList from "./components/TriggerList";
const { Row, Col } = Grid;
let ApplicationConfig = class extends Component {
  constructor(props) {
    super(props);
    this.loadAppStatistics = async () => {
      const { appName } = this.state;
      if (appName) {
        getApplicationStatistics({ appName }).then((re) => {
          if (re) {
            this.setState({ statistics: re });
          }
        });
      }
    };
    this.onDeleteTrait = async (componentName, traitType) => {
      const { appName } = this.state;
      const params = {
        appName,
        componentName,
        traitType
      };
      Dialog.confirm({
        type: "confirm",
        content: /* @__PURE__ */ React.createElement(Translation, null, "Unrecoverable after deletion, are you sure to delete it?"),
        onOk: () => {
          deleteTrait(params).then((res) => {
            if (res) {
              Message.success({
                duration: 4e3,
                content: i18n.t("Trait deleted successfully").toString()
              });
              this.onLoadApplicationComponents();
            }
          });
        },
        locale: locale().Dialog
      });
    };
    this.onClose = () => {
      this.setState({ visibleTrait: false, isEditTrait: false });
    };
    this.onOk = () => {
      this.onLoadApplicationComponents();
      this.setState({
        isEditTrait: false,
        visibleTrait: false
      });
    };
    this.onAddTrait = (componentName, isEditComponent) => {
      this.setState({
        visibleTrait: true,
        traitItem: { type: "" },
        isEditTrait: false,
        componentName: componentName || "",
        isEditComponent: isEditComponent || false
      });
    };
    this.changeTraitStats = (isEditTrait, traitItem, componentName) => {
      this.setState({
        visibleTrait: true,
        isEditTrait,
        isEditComponent: true,
        traitItem,
        componentName
      });
    };
    this.onAddTrigger = () => {
      this.setState({
        visibleTrigger: true
      });
    };
    this.onTriggerClose = () => {
      this.setState({
        visibleTrigger: false,
        trigger: void 0
      });
      this.onLoadApplicationComponents();
    };
    this.onTriggerOk = (res) => {
      this.onGetApplicationTrigger();
      this.setState({
        visibleTrigger: false,
        trigger: void 0,
        createTriggerInfo: res
      });
    };
    this.onDeleteTrigger = async (token) => {
      const { appName } = this.state;
      const params = {
        appName,
        token
      };
      deleteTrigger(params).then((res) => {
        if (res) {
          Message.success({
            duration: 4e3,
            content: "Trigger deleted successfully."
          });
          this.onGetApplicationTrigger();
        }
      });
    };
    this.editAppPlan = () => {
      const { applicationDetail } = this.props;
      const { alias = "", description = "", name = "", createTime = "", icon = "", labels, annotations } = applicationDetail || {};
      this.setState({
        editItem: {
          name,
          alias,
          description,
          createTime,
          icon,
          labels,
          annotations
        },
        showEditApplication: true
      });
    };
    this.onOkEditAppDialog = () => {
      this.setState({
        showEditApplication: false
      });
      this.onGetApplicationDetails();
    };
    this.onCloseEditAppDialog = () => {
      this.setState({
        showEditApplication: false
      });
    };
    this.editComponent = (component) => {
      this.setState({
        isEditComponent: true,
        visibleComponent: true,
        componentName: component.name
      });
    };
    this.onAddComponent = () => {
      this.setState({
        visibleComponent: true,
        isEditComponent: false,
        componentName: ""
      });
    };
    this.onAddPolicy = () => {
      this.setState({ visiblePolicy: true });
    };
    this.onDeleteComponent = async (componentName) => {
      const { appName } = this.state;
      const params = {
        appName,
        componentName
      };
      deleteComponent(params).then((res) => {
        if (res) {
          Message.success({
            duration: 4e3,
            title: i18n.t("Success").toString(),
            content: i18n.t("Delete component success.").toString()
          });
          this.onLoadApplicationComponents();
        }
      });
    };
    this.createTemporaryTrait = (trait) => {
      this.setState({
        temporaryTraitList: [...this.state.temporaryTraitList, trait],
        visibleTrait: false
      });
    };
    this.upDateTemporaryTrait = (trait) => {
      const { temporaryTraitList } = this.state;
      const updateTraitList = [];
      (temporaryTraitList || []).map((item) => {
        let newTraitItem = { type: "" };
        if (item.type === trait.type) {
          newTraitItem = trait;
        } else {
          newTraitItem = item;
        }
        updateTraitList.push(newTraitItem);
      });
      this.setState({
        temporaryTraitList: updateTraitList,
        visibleTrait: false
      });
    };
    this.onComponentClose = () => {
      this.setState({
        visibleComponent: false,
        temporaryTraitList: []
      });
      this.onLoadApplicationComponents();
    };
    this.onComponentOK = () => {
      this.setState(
        {
          visibleComponent: false,
          temporaryTraitList: []
        },
        () => {
          this.onLoadApplicationComponents();
        }
      );
    };
    this.onGetComponentDefinitions = async () => {
      getComponentDefinitions().then((res) => {
        if (res) {
          this.setState({
            componentDefinitions: res && res.definitions
          });
        }
      });
    };
    this.onGetApplicationDetails = async () => {
      const { appName } = this.state;
      this.props.dispatch({
        type: "application/getApplicationDetail",
        payload: { appName }
      });
    };
    this.onLoadApplicationComponents = async () => {
      const { appName } = this.state;
      this.props.dispatch({
        type: "application/getApplicationComponents",
        payload: { appName }
      });
    };
    this.onDeleteApplication = () => {
      const { appName } = this.state;
      Dialog.confirm({
        type: "confirm",
        content: /* @__PURE__ */ React.createElement(Translation, null, "Unrecoverable after deletion, are you sure to delete it?"),
        onOk: () => {
          deleteApplication({ name: appName }).then((re) => {
            if (re) {
              Message.success("Application deleted successfully");
              this.props.dispatch(routerRedux.push("/applications"));
            }
          });
        },
        locale: locale().Dialog
      });
    };
    this.onDeletePolicy = (policyName) => {
      const { appName } = this.state;
      deletePolicy({ appName, policyName }).then((re) => {
        if (re) {
          Message.success("Application policy deleted successfully");
          this.loadApplicationPolicies();
        }
      }).catch((err) => {
        if (err.BusinessCode === 10026) {
          Dialog.confirm({
            type: "confirm",
            content: /* @__PURE__ */ React.createElement(Translation, null, "This policy is being used by workflow, do you want to force delete it?"),
            onOk: () => {
              deletePolicy({ appName, policyName, force: true }).then((res) => {
                if (res) {
                  Message.success("Application policy deleted successfully");
                  this.loadApplicationPolicies();
                }
              });
            },
            locale: locale().Dialog
          });
        } else {
          handleError(err);
        }
      });
    };
    this.onEditPolicy = (policyName) => {
      const { appName } = this.state;
      getPolicyDetail({ appName, policyName }).then((res) => {
        if (res) {
          this.setState({ policyDetail: res, visiblePolicy: true });
        }
      });
    };
    this.loadApplicationPolicies = async () => {
      const {
        params: { appName }
      } = this.props.match;
      this.props.dispatch({
        type: "application/getApplicationPolicies",
        payload: { appName }
      });
    };
    const { params } = props.match;
    this.state = {
      appName: params.appName,
      componentName: "",
      isEditTrait: false,
      visibleTrait: false,
      traitItem: { type: "" },
      triggers: [],
      visibleTrigger: false,
      createTriggerInfo: { name: "", workflowName: "", type: "webhook", token: "" },
      showEditApplication: false,
      visibleComponent: false,
      temporaryTraitList: [],
      isEditComponent: false,
      componentDefinitions: [],
      visiblePolicy: false
    };
  }
  componentDidMount() {
    this.onGetApplicationTrigger();
    this.onGetComponentDefinitions();
    this.loadAppStatistics();
  }
  onGetApplicationTrigger() {
    const { appName } = this.state;
    const params = {
      appName
    };
    getApplicationTriggers(params).then((res) => {
      if (res) {
        this.setState({
          triggers: res.triggers || []
        });
      }
    });
  }
  render() {
    const { applicationDetail, workflows, components, policies, envbinding } = this.props;
    const {
      visibleTrait,
      isEditTrait,
      appName = "",
      componentName = "",
      traitItem,
      triggers,
      trigger,
      visibleTrigger,
      createTriggerInfo,
      showEditApplication,
      editItem,
      visibleComponent,
      temporaryTraitList,
      isEditComponent,
      componentDefinitions,
      visiblePolicy,
      policyDetail,
      statistics
    } = this.state;
    const projectName = applicationDetail && applicationDetail.project?.name || "";
    if (!applicationDetail) {
      return /* @__PURE__ */ React.createElement(Loading, { visible: true });
    }
    return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Row, { className: "flex-row", wrap: true }, /* @__PURE__ */ React.createElement(Col, { xl: 16, l: 24, s: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(Card, { locale: locale().Card, contentHeight: "auto", subTitle: applicationDetail?.description }, /* @__PURE__ */ React.createElement(Row, { wrap: true }, /* @__PURE__ */ React.createElement(Col, { xxs: 12 }, /* @__PURE__ */ React.createElement("div", { className: "app-name" }, showAlias(applicationDetail?.name, applicationDetail?.alias))), /* @__PURE__ */ React.createElement(Col, { xxs: 12, className: "flexright", style: { marginBottom: "16px" } }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
      Permission,
      {
        request: {
          resource: `project:${projectName}/application/:${appName}`,
          action: "delete"
        },
        project: projectName
      },
      /* @__PURE__ */ React.createElement(
        Button,
        {
          className: "danger-btn",
          style: { marginRight: "16px" },
          onClick: this.onDeleteApplication,
          type: "secondary"
        },
        /* @__PURE__ */ React.createElement(Translation, null, "Remove")
      )
    ), /* @__PURE__ */ React.createElement(
      Permission,
      {
        request: {
          resource: `project:${projectName}/application/:${appName}`,
          action: "update"
        },
        project: projectName
      },
      /* @__PURE__ */ React.createElement(Button, { onClick: this.editAppPlan, type: "secondary" }, /* @__PURE__ */ React.createElement(Translation, null, "Edit"))
    ))), /* @__PURE__ */ React.createElement(Col, { l: 8, xs: 24 }, /* @__PURE__ */ React.createElement(
      Item,
      {
        label: /* @__PURE__ */ React.createElement(Translation, null, "Project"),
        value: /* @__PURE__ */ React.createElement(Link, { to: `/projects/${applicationDetail?.project?.name}` }, applicationDetail?.project?.alias ? applicationDetail?.project?.alias : applicationDetail?.project?.name)
      }
    )), /* @__PURE__ */ React.createElement(Col, { l: 8, xs: 24 }, /* @__PURE__ */ React.createElement(
      Item,
      {
        label: /* @__PURE__ */ React.createElement(Translation, null, "Create Time"),
        value: /* @__PURE__ */ React.createElement(Balloon, { trigger: /* @__PURE__ */ React.createElement("span", null, beautifyTime(applicationDetail.createTime)) }, momentDate(applicationDetail.createTime) || "-")
      }
    )), /* @__PURE__ */ React.createElement(Col, { l: 8, xs: 24 }, /* @__PURE__ */ React.createElement(
      Item,
      {
        label: /* @__PURE__ */ React.createElement(Translation, null, "Update Time"),
        value: /* @__PURE__ */ React.createElement(Balloon, { trigger: /* @__PURE__ */ React.createElement("span", null, beautifyTime(applicationDetail.updateTime)) }, momentDate(applicationDetail.updateTime) || "-")
      }
    )), /* @__PURE__ */ React.createElement(Col, { xxs: 24 }, applicationDetail?.labels && Object.keys(applicationDetail?.labels).map((key) => {
      if (applicationDetail?.labels) {
        return /* @__PURE__ */ React.createElement(
          Tag,
          {
            key,
            style: { margin: "4px" },
            color: "blue"
          },
          `${key}=${applicationDetail?.labels[key]}`
        );
      }
      return;
    }))))), /* @__PURE__ */ React.createElement(Col, { xl: 8, l: 24, s: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(Card, { locale: locale().Card, contentHeight: "auto", style: { height: "100%" } }, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 6, style: { padding: "22px 0" } }, /* @__PURE__ */ React.createElement(NumItem, { number: statistics?.envCount, title: i18n.t("Environment Count").toString() })), /* @__PURE__ */ React.createElement(Col, { span: 6, style: { padding: "22px 0" } }, /* @__PURE__ */ React.createElement(NumItem, { number: statistics?.targetCount, title: i18n.t("Target Count").toString() })), /* @__PURE__ */ React.createElement(Col, { span: 6, style: { padding: "22px 0" } }, /* @__PURE__ */ React.createElement(
      NumItem,
      {
        number: statistics?.revisionCount,
        to: `/applications/${applicationDetail.name}/revisions`,
        title: i18n.t("Revision Count").toString()
      }
    )), /* @__PURE__ */ React.createElement(Col, { span: 6, style: { padding: "22px 0" } }, /* @__PURE__ */ React.createElement(
      NumItem,
      {
        number: statistics?.workflowCount,
        to: `/applications/${applicationDetail.name}/workflows`,
        title: i18n.t("Workflow Count").toString()
      }
    )))))), /* @__PURE__ */ React.createElement(Row, { wrap: true, className: "app-spec" }, /* @__PURE__ */ React.createElement(Col, { xl: 8, xxs: 24, className: "app-spec-item" }, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24, className: "padding16" }, /* @__PURE__ */ React.createElement(
      Title,
      {
        title: /* @__PURE__ */ React.createElement("span", { className: "font-size-16 font-weight-bold" }, /* @__PURE__ */ React.createElement(Translation, null, "Components")),
        actions: !applicationDetail?.readOnly ? [
          /* @__PURE__ */ React.createElement(
            Permission,
            {
              request: {
                resource: `project:${projectName}/application:${applicationDetail?.name}/component:*`,
                action: "create"
              },
              project: projectName
            },
            /* @__PURE__ */ React.createElement("a", { key: "add", onClick: this.onAddComponent, className: "font-size-14 font-weight-400" }, /* @__PURE__ */ React.createElement(Translation, null, "New Component"))
          )
        ] : []
      }
    ))), /* @__PURE__ */ React.createElement(
      Components,
      {
        application: applicationDetail,
        components: components || [],
        editComponent: (component) => {
          this.editComponent(component);
        },
        onDeleteComponent: (component) => {
          this.onDeleteComponent(component);
        },
        onDeleteTrait: this.onDeleteTrait,
        onAddTrait: (name) => {
          this.onAddTrait(name, true);
        },
        onAddComponent: this.onAddComponent,
        changeTraitStats: this.changeTraitStats
      }
    )), /* @__PURE__ */ React.createElement(Col, { xl: 8, xxs: 24, className: "app-spec-item" }, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24, className: "padding16" }, /* @__PURE__ */ React.createElement(
      Title,
      {
        title: /* @__PURE__ */ React.createElement("span", { className: "font-size-16 font-weight-bold" }, /* @__PURE__ */ React.createElement(Translation, null, "Policies")),
        actions: [
          /* @__PURE__ */ React.createElement(
            Permission,
            {
              request: {
                resource: `project:${projectName}/application:${applicationDetail?.name}/policy:*`,
                action: "create"
              },
              project: projectName
            },
            /* @__PURE__ */ React.createElement("a", { key: "add", className: "font-size-14 font-weight-400", onClick: this.onAddPolicy }, /* @__PURE__ */ React.createElement(Translation, null, "New Policy"))
          )
        ]
      }
    ))), /* @__PURE__ */ React.createElement(
      PolicyList,
      {
        policies,
        envbinding,
        applicationDetail,
        onDeletePolicy: (name) => {
          this.onDeletePolicy(name);
        },
        onShowPolicy: (name) => {
          this.onEditPolicy(name);
        }
      }
    )), /* @__PURE__ */ React.createElement(Col, { xl: 8, xxs: 24, className: "app-spec-item" }, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24, className: "padding16" }, /* @__PURE__ */ React.createElement(
      Title,
      {
        actions: [
          /* @__PURE__ */ React.createElement(
            Permission,
            {
              request: {
                resource: `project:${projectName}/application:${applicationDetail?.name}/trigger:*`,
                action: "create"
              },
              project: projectName
            },
            /* @__PURE__ */ React.createElement("a", { key: "add", className: "font-size-14 font-weight-400", onClick: this.onAddTrigger }, /* @__PURE__ */ React.createElement(Translation, null, "New Trigger"))
          )
        ],
        title: /* @__PURE__ */ React.createElement("span", { className: "font-size-16 font-weight-bold" }, /* @__PURE__ */ React.createElement(Translation, null, "Triggers"))
      }
    ))), /* @__PURE__ */ React.createElement(
      TriggerList,
      {
        appName,
        triggers,
        components: components || [],
        onDeleteTrigger: (token) => {
          this.onDeleteTrigger(token);
        },
        createTriggerInfo,
        applicationDetail,
        onEditTrigger: (t) => {
          this.setState({ visibleTrigger: true, trigger: t });
        }
      }
    ))), /* @__PURE__ */ React.createElement(If, { condition: visibleTrait }, /* @__PURE__ */ React.createElement(
      TraitDialog,
      {
        project: applicationDetail?.project?.name || "",
        visible: visibleTrait,
        isEditComponent,
        appName,
        componentName,
        isEditTrait,
        traitItem,
        temporaryTraitList,
        onClose: this.onClose,
        onOK: this.onOk,
        createTemporaryTrait: (trait) => {
          this.createTemporaryTrait(trait);
        },
        upDateTemporaryTrait: (trait) => {
          this.upDateTemporaryTrait(trait);
        }
      }
    )), /* @__PURE__ */ React.createElement(If, { condition: visibleTrigger }, /* @__PURE__ */ React.createElement(
      TriggerDialog,
      {
        visible: visibleTrigger,
        appName,
        trigger,
        workflows,
        components: components || [],
        onClose: this.onTriggerClose,
        onOK: (res) => {
          this.onTriggerOk(res);
        }
      }
    )), /* @__PURE__ */ React.createElement(If, { condition: showEditApplication }, /* @__PURE__ */ React.createElement(EditAppDialog, { editItem, onOK: this.onOkEditAppDialog, onClose: this.onCloseEditAppDialog })), /* @__PURE__ */ React.createElement(If, { condition: visibleComponent }, /* @__PURE__ */ React.createElement(
      ComponentDialog,
      {
        project: applicationDetail?.project?.name || "",
        appName,
        componentName,
        components: components || [],
        isEditComponent,
        temporaryTraitList,
        componentDefinitions,
        onComponentClose: this.onComponentClose,
        onComponentOK: this.onComponentOK
      }
    )), /* @__PURE__ */ React.createElement(If, { condition: visiblePolicy }, /* @__PURE__ */ React.createElement(
      PolicyDialog,
      {
        project: applicationDetail?.project?.name || "",
        visible: visiblePolicy,
        appName,
        policy: policyDetail,
        envbinding: envbinding || [],
        workflows: workflows || [],
        onClose: () => {
          this.setState({ visiblePolicy: false, policyDetail: void 0 });
        },
        onOK: () => {
          this.loadApplicationPolicies();
          this.setState({ visiblePolicy: false, policyDetail: void 0 });
        }
      }
    )));
  }
};
ApplicationConfig = __decorateClass([
  connect((store) => {
    return { ...store.application };
  })
], ApplicationConfig);
export default ApplicationConfig;
