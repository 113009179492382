"use strict";
import { getDomain } from "../utils/common";
import { configs, configTemplates, project } from "./productionLink";
import { get, post, put, rdelete } from "./request";
const baseURLOject = getDomain();
const base = baseURLOject.APIBASE;
export function listTemplates(projectName) {
  let url = base + configTemplates;
  if (projectName) {
    url = base + project + "/" + projectName + "/config_templates";
  }
  return get(url, {}).then((res) => res);
}
export function detailTemplate(queryData, projectName) {
  let url = base + configTemplates + `/${queryData.name}`;
  if (projectName) {
    url = base + project + "/" + projectName + "/config_templates/" + queryData.name;
  }
  return get(url, {
    params: {
      namespace: queryData.namespace
    }
  }).then((res) => res);
}
export function createConfig(data, projectName) {
  let url = base + configs;
  if (projectName) {
    url = base + project + "/" + projectName + "/configs";
  }
  return post(url, data).then((res) => res);
}
export function updateConfig(name, data, projectName) {
  let url = base + configs + `/${name}`;
  if (projectName) {
    url = base + project + "/" + projectName + "/configs/" + name;
  }
  return put(url, data).then((res) => res);
}
export function getConfigs(template) {
  const url = base + configs;
  return get(url, {
    params: { template: template ? template : "" }
  }).then((res) => res);
}
export function detailConfig(name, projectName) {
  let url = base + configs + `/${name}`;
  if (projectName) {
    url = base + project + "/" + projectName + "/configs/" + name;
  }
  return get(url, {}).then((res) => res);
}
export function deleteConfig(name, projectName) {
  let url = base + configs + `/${name}`;
  if (projectName) {
    url = base + project + "/" + projectName + "/configs/" + name;
  }
  return rdelete(url, {}).then((res) => res);
}
export function getProjectConfigs(query) {
  const urlPath = project + `/${query.projectName}/configs`;
  return get(urlPath, {}).then((res) => res);
}
export function getProjectConfigDistributions(query) {
  const urlPath = project + `/${query.projectName}/distributions`;
  return get(urlPath, {}).then((res) => res);
}
export function applyProjectConfigDistribution(projectName, params) {
  const urlPath = project + `/${projectName}/distributions`;
  return post(urlPath, params).then((res) => res);
}
export function deleteProjectConfigDistribution(projectName, name) {
  const urlPath = project + `/${projectName}/distributions/${name}`;
  return rdelete(urlPath, {}).then((res) => res);
}
