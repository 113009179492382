"use strict";
import { Button, Dialog, Message, Pagination, Table } from "@alifd/next";
import React, { Component, Fragment } from "react";
import { getPlatformPermissions } from "../../api/rbac";
import { deleteRole, getRoleList } from "../../api/roles";
import { If } from "../../components/If";
import { ListTitle as Title } from "../../components/ListTitle";
import Permission from "../../components/Permission";
import { Translation } from "../../components";
import { momentDate } from "../../utils/common";
import { locale } from "../../utils/locale";
import "./index.less";
import RolesDialog from "./components/RolesDialog";
class Roles extends Component {
  constructor(props) {
    super(props);
    this.listRoles = async () => {
      this.setState({ isLoading: true });
      const { page, pageSize } = this.state;
      getRoleList({ page, pageSize }).then((res) => {
        this.setState({
          list: res && res.roles || [],
          total: res && res.total || 0,
          isLoading: false
        });
      }).catch(() => {
        this.setState({ isLoading: false });
      });
    };
    this.listPermissions = async () => {
      getPlatformPermissions().then((res) => {
        this.setState({
          permissions: res && res || []
        });
      });
    };
    this.onEdit = (record) => {
      this.setState({
        visible: true,
        isEditRole: true,
        editRoleItem: record
      });
    };
    this.onDelete = (record) => {
      Dialog.confirm({
        content: "Are you sure you want to delete this role",
        onOk: () => {
          const { name } = record;
          if (name) {
            deleteRole({ name }).then((res) => {
              if (res) {
                Message.success(/* @__PURE__ */ React.createElement(Translation, null, "Delete the role success"));
                this.listRoles();
              }
            });
          }
        },
        locale: locale().Dialog
      });
    };
    this.onCreate = () => {
      this.setState({ visible: false });
      this.listRoles();
    };
    this.onCloseCreate = () => {
      this.setState({ visible: false });
    };
    this.handleClickCreate = () => {
      this.setState({
        visible: true,
        isEditRole: false
      });
    };
    this.handleChange = (page) => {
      this.setState(
        {
          page
        },
        () => {
          this.listRoles();
        }
      );
    };
    this.state = {
      list: [],
      total: 0,
      page: 1,
      pageSize: 10,
      permissions: [],
      visible: false,
      isEditRole: false,
      editRoleItem: { name: "" },
      isLoading: false
    };
  }
  componentDidMount() {
    this.listRoles();
    this.listPermissions();
  }
  render() {
    const columns = [
      {
        key: "name",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Name"),
        dataIndex: "name",
        cell: (v) => {
          return /* @__PURE__ */ React.createElement("span", null, v);
        }
      },
      {
        key: "alias",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Alias"),
        dataIndex: "alias",
        cell: (v) => {
          return /* @__PURE__ */ React.createElement("span", null, v);
        }
      },
      {
        key: "createTime",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Create Time"),
        dataIndex: "createTime",
        cell: (v) => {
          return /* @__PURE__ */ React.createElement("span", null, momentDate(v));
        }
      },
      // {
      //   key: 'permPolicies',
      //   title: <Translation>PermPolicies</Translation>,
      //   dataIndex: 'permPolicies',
      //   cell: (v: any) => {
      //     return v.map((item: any) => (<span className='roles-permPolicies margin-right-5'>{item && item.name}</span>))
      //   },
      // },
      {
        key: "operation",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Actions"),
        dataIndex: "operation",
        cell: (v, i, record) => {
          return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement(Permission, { request: { resource: `role:${record.name}`, action: "update" }, project: "" }, /* @__PURE__ */ React.createElement(
            Button,
            {
              text: true,
              size: "medium",
              component: "a",
              onClick: () => {
                this.onEdit(record);
              }
            },
            /* @__PURE__ */ React.createElement(Translation, null, "Edit")
          )), /* @__PURE__ */ React.createElement(Permission, { request: { resource: `role:${record.name}`, action: "delete" }, project: "" }, /* @__PURE__ */ React.createElement(
            Button,
            {
              text: true,
              size: "medium",
              component: "a",
              onClick: () => {
                this.onDelete(record);
              }
            },
            /* @__PURE__ */ React.createElement(Translation, null, "Delete")
          )));
        }
      }
    ];
    const { Column } = Table;
    const { list, page, pageSize, total, visible, isEditRole, editRoleItem, permissions, isLoading } = this.state;
    return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "roles-list-content" }, /* @__PURE__ */ React.createElement(
      Title,
      {
        title: "Platform Roles",
        subTitle: "Assign permissions for resources such as clusters\u3001targets\u3001addons\u3001projects and users",
        extButtons: [
          /* @__PURE__ */ React.createElement(Permission, { request: { resource: "role:*", action: "create" }, project: "" }, /* @__PURE__ */ React.createElement(Button, { type: "primary", onClick: this.handleClickCreate }, /* @__PURE__ */ React.createElement(Translation, null, "New Role")))
        ]
      }
    ), /* @__PURE__ */ React.createElement(Table, { locale: locale().Table, dataSource: list, loading: isLoading }, columns.map((col, key) => /* @__PURE__ */ React.createElement(Column, { ...col, key, align: "left" }))), /* @__PURE__ */ React.createElement(
      Pagination,
      {
        className: "margin-top-20 text-align-right",
        total,
        locale: locale().Pagination,
        size: "medium",
        pageSize,
        current: page,
        onChange: this.handleChange
      }
    ), /* @__PURE__ */ React.createElement(If, { condition: visible }, /* @__PURE__ */ React.createElement(
      RolesDialog,
      {
        visible,
        isEditRole,
        editRoleItem,
        permissions,
        onCreate: this.onCreate,
        onCloseCreate: this.onCloseCreate
      }
    ))));
  }
}
export default Roles;
