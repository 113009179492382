"use strict";
import { Route } from "dva/router";
import React, { Fragment, Component } from "react";
import CallBackPage from "../../pages/CallBackPage";
import LoginPage from "../../pages/Login";
import { hasToken } from "../../utils/storage";
export default class AuthRoute extends Component {
  constructor() {
    super(...arguments);
    this.checkAuth = () => {
      if (!hasToken() && window.location.href.indexOf("/callback?code=") == -1) {
        this.props.history.push("/login");
      }
    };
  }
  componentDidMount() {
    this.checkAuth();
  }
  render() {
    const Components = this.props.component;
    return /* @__PURE__ */ React.createElement(
      Route,
      {
        path: this.props.path,
        render: () => {
          if (hasToken()) {
            return /* @__PURE__ */ React.createElement(Components, null);
          } else {
            return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement(
              Route,
              {
                exact: true,
                path: "/callback",
                render: (props) => {
                  return /* @__PURE__ */ React.createElement(CallBackPage, { ...props });
                }
              }
            ), /* @__PURE__ */ React.createElement(
              Route,
              {
                exact: true,
                path: "/login",
                render: (props) => {
                  return /* @__PURE__ */ React.createElement(LoginPage, { ...props });
                }
              }
            ));
          }
        }
      }
    );
  }
}
