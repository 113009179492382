"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Select, Grid, Card, Loading, Button, MenuButton } from "@alifd/next";
import { connect } from "dva";
import { routerRedux, Link } from "dva/router";
import React from "react";
import { detailWorkflow } from "../../api/workflows";
import { getApplicationEnvRecords } from "../../api/application";
import Empty from "../../components/Empty";
import { If } from "../../components/If";
import Permission from "../../components/Permission";
import { Translation } from "../../components/Translation";
import Header from "../ApplicationInstanceList/components/Header";
import i18n from "../../i18n";
import { beautifyTime } from "../../utils/common";
import { locale } from "../../utils/locale";
import ApplicationWorkflowRecord from "./components/WorkflowRecord";
const { Row, Col } = Grid;
let ApplicationWorkflow = class extends React.Component {
  constructor(props) {
    super(props);
    this.loadApplicationStatus = async () => {
      const {
        params: { appName, envName }
      } = this.props.match;
      if (envName) {
        this.props.dispatch({
          type: "application/getApplicationStatus",
          payload: { appName, envName }
        });
      }
    };
    this.loadWorkflow = () => {
      const {
        params: { appName }
      } = this.props.match;
      const { currentRecord } = this.state;
      if (currentRecord) {
        detailWorkflow({ appName, name: currentRecord.workflowName }).then((res) => {
          this.setState({ workflow: res });
        });
      }
    };
    this.loadWorkflowRecord = () => {
      const {
        params: { appName, record, envName }
      } = this.props.match;
      const { dispatch } = this.props;
      const env = this.getEnvbindingByName();
      if (env) {
        getApplicationEnvRecords({ appName, envName }).then((res) => {
          if (res) {
            const records = res.records;
            this.setState({ records: records || [], showRecordName: "" });
            const currentRecord = res.records.find((re) => re.name === record);
            if (currentRecord) {
              this.setState({ showRecordName: record, currentRecord }, this.loadWorkflow);
            } else if (Array.isArray(records) && records.length > 0) {
              this.setState({ showRecordName: records[0].name });
              dispatch(
                routerRedux.push(`/applications/${appName}/envbinding/${env.name}/workflow/records/${records[0].name}`)
              );
              return;
            }
          }
          return;
        });
      }
    };
    this.getEnvbindingByName = () => {
      const { envbinding } = this.props;
      const {
        params: { envName }
      } = this.props.match;
      return envbinding.find((env) => env.name === envName);
    };
    this.runApplicationWorkflow = () => {
    };
    this.state = {
      zoom: 1,
      showDetail: false,
      activeKey: "detail"
    };
  }
  componentDidMount() {
    this.loadApplicationStatus();
    this.loadWorkflowRecord();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.match !== this.props.match || prevProps.envbinding !== this.props.envbinding) {
      this.setState({ records: [], showRecordName: "" }, () => {
        this.loadWorkflowRecord();
      });
    }
  }
  render() {
    const { applicationDetail, dispatch, applicationStatus, workflows, userInfo } = this.props;
    const {
      params: { record, appName, envName }
    } = this.props.match;
    const { records, showRecordName, stepStatus, workflow } = this.state;
    const envWorkflows = workflows.filter((w) => w.envName === envName);
    if (!applicationDetail || !workflow) {
      return /* @__PURE__ */ React.createElement(Loading, { visible: true });
    }
    const recordOptions = records?.map((re) => {
      return {
        label: `${re.name}${beautifyTime(re.endTime) ? "(" + beautifyTime(re.endTime) + ")" : ""}`,
        value: re.name
      };
    });
    const showRecord = record && recordOptions?.find((re) => re.value === record);
    let stepSpec;
    workflow?.steps?.map((step) => {
      if (stepStatus && step.name == stepStatus.name) {
        stepSpec = step;
      }
      step.subSteps?.map((sub) => {
        if (stepStatus && sub.name == stepStatus.name) {
          stepSpec = sub;
        }
      });
    });
    let properties = stepSpec && stepSpec.properties;
    if (typeof properties === "string") {
      const newProperties = JSON.parse(properties);
      properties = newProperties;
    }
    return /* @__PURE__ */ React.createElement("div", { className: "run-layout" }, /* @__PURE__ */ React.createElement(
      Header,
      {
        userInfo,
        envbinding: this.getEnvbindingByName(),
        envName,
        appName,
        disableStatusShow: true,
        applicationDetail,
        applicationStatus,
        refresh: () => {
          this.loadApplicationStatus();
        },
        dispatch: this.props.dispatch
      }
    ), /* @__PURE__ */ React.createElement(Card, { contentHeight: "auto" }, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 6 }, /* @__PURE__ */ React.createElement(
      Select,
      {
        value: showRecordName,
        locale: locale().Select,
        autoWidth: false,
        placeholder: i18n.t("Switch the workflow record"),
        onChange: (selectRecord) => {
          this.setState({ showRecordName: selectRecord }, () => {
            dispatch(
              routerRedux.push(
                `/applications/${appName}/envbinding/${envName}/workflow/records/${selectRecord}`
              )
            );
          });
        },
        dataSource: recordOptions
      }
    )), /* @__PURE__ */ React.createElement(Col, { span: 12 }), /* @__PURE__ */ React.createElement(
      Col,
      {
        span: 6,
        style: {
          display: "flex",
          justifyContent: "end"
        }
      },
      /* @__PURE__ */ React.createElement(If, { condition: !applicationDetail?.readOnly }, /* @__PURE__ */ React.createElement(
        Permission,
        {
          project: applicationDetail.project?.name,
          request: {
            resource: `project:${applicationDetail.project?.name}/application:${applicationDetail.name}/workflow:*`,
            action: "update"
          }
        },
        envWorkflows.length == 1 && /* @__PURE__ */ React.createElement(Link, { to: `/applications/${appName}/envbinding/${envName}/workflow/${envWorkflows[0].name}/studio` }, /* @__PURE__ */ React.createElement(Button, { type: "primary" }, /* @__PURE__ */ React.createElement(Translation, null, "Launch Workflow Studio"))),
        envWorkflows.length > 1 && /* @__PURE__ */ React.createElement(
          MenuButton,
          {
            autoWidth: false,
            type: "primary",
            label: /* @__PURE__ */ React.createElement(Translation, null, "Launch Workflow Studio")
          },
          envWorkflows.map((w) => {
            return /* @__PURE__ */ React.createElement(MenuButton.Item, { key: w.name }, /* @__PURE__ */ React.createElement(Link, { to: `/applications/${appName}/envbinding/${envName}/workflow/${w.name}/studio` }, w.alias));
          })
        )
      ))
    ))), showRecord && /* @__PURE__ */ React.createElement(
      ApplicationWorkflowRecord,
      {
        workflow,
        applicationDetail,
        recordName: record,
        envName
      }
    ), recordOptions?.length == 0 && /* @__PURE__ */ React.createElement(Empty, { message: "Workflow never ran" }));
  }
};
ApplicationWorkflow = __decorateClass([
  connect((store) => {
    return { ...store.application, ...store.user };
  })
], ApplicationWorkflow);
export default ApplicationWorkflow;
