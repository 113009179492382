"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Field, Form, Grid, Input, Message, Select } from "@alifd/next";
import { connect } from "dva";
import * as yaml from "js-yaml";
import React from "react";
import { v4 as uuid } from "uuid";
import { createPipeline, createPipelineContext, loadPipeline, updatePipeline } from "../../../../api/pipeline";
import DrawerWithFooter from "../../../../components/Drawer";
import { If } from "../../../../components/If";
import { Translation } from "../../../../components/Translation";
import i18n from "../../../../i18n";
import { checkName } from "../../../../utils/common";
import { locale } from "../../../../utils/locale";
import { checkPermission } from "../../../../utils/permission";
import { templates } from "./pipeline-template";
const FormItem = Form.Item;
const { Row, Col } = Grid;
let CreatePipeline = class extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = () => {
      const { pipeline } = this.props;
      this.field.validate((errs, values) => {
        if (errs) {
          if (errs.config && Array.isArray(errs.config.errors) && errs.config.errors.length > 0) {
            this.setState({ configError: errs.config.errors });
          }
          return;
        }
        const { name, steps, project, alias, description, stepMode, subStepMode } = values;
        let stepArray = [];
        if (steps) {
          stepArray = yaml.load(steps) || [];
        }
        const request = {
          project,
          alias,
          description,
          name,
          spec: {
            steps: stepArray,
            mode: {
              steps: stepMode,
              subSteps: subStepMode
            }
          }
        };
        this.setState({ loading: true });
        if (pipeline) {
          updatePipeline(request).then((res) => {
            if (res) {
              Message.success(i18n.t("Pipeline updated successfully"));
              if (this.props.onSuccess) {
                this.props.onSuccess(res);
              }
            }
          }).finally(() => {
            this.setState({ loading: false });
          });
        } else {
          createPipeline(request).then((res) => {
            if (res) {
              const { defaultContext } = this.state;
              if (defaultContext) {
                const contextValues = [];
                Object.keys(defaultContext).map((key) => {
                  contextValues.push({ key, value: defaultContext[key] });
                });
                createPipelineContext(project, name, { name: "default", values: contextValues });
              }
              Message.success(i18n.t("Pipeline created successfully"));
              if (this.props.onSuccess) {
                this.props.onSuccess(res);
              }
            }
          }).finally(() => {
            this.setState({ loading: false });
          });
        }
      });
    };
    this.customRequest = (option) => {
      const reader = new FileReader();
      const fileSelect = option.file;
      reader.readAsText(fileSelect);
      reader.onload = () => {
        this.field.setValues({
          steps: reader.result?.toString() || ""
        });
      };
      return {
        file: File,
        onError: () => {
        },
        abort() {
        }
      };
    };
    this.checkStepConfig = (data) => {
      if (!data || !Array.isArray(data)) {
        return ["The YAML content is not a valid array."];
      }
      const messages = [];
      data.map((step, i) => {
        if (!step) {
          messages.push(`[${i}] Step is invalid.`);
          return;
        }
        if (!step.name) {
          messages.push(`[${i}] Step is not named.`);
          return;
        }
        if (!step.type) {
          messages.push(`[${i}] Step does not specify a type.`);
          return;
        }
      });
      return messages;
    };
    this.field = new Field(this);
    this.DefinitionCodeRef = React.createRef();
    this.state = {
      containerId: uuid()
    };
  }
  componentDidMount() {
    const { pipeline } = this.props;
    if (pipeline) {
      loadPipeline({ projectName: pipeline.project.name, pipelineName: pipeline.name }).then((res) => {
        this.field.setValues({
          name: res.name,
          project: res.project.name,
          alias: res.alias,
          description: res.description,
          steps: yaml.dump(res.spec.steps),
          stepMode: res.spec.mode?.steps,
          subStepMode: res.spec.mode?.subSteps
        });
      });
    }
  }
  render() {
    const { init } = this.field;
    const { userInfo, pipeline } = this.props;
    let defaultProject = "";
    const editMode = pipeline != void 0;
    const projectOptions = [];
    (userInfo?.projects || []).map((project) => {
      if (checkPermission({ resource: `project:${project.name}/pipeline:*`, action: "create" }, project.name, userInfo)) {
        if (project.name === "default") {
          defaultProject = project.name;
        }
        projectOptions.push({
          label: project.alias ? `${project.alias}(${project.name})` : project.name,
          value: project.name
        });
      }
    });
    const modeOptions = [{ value: "StepByStep" }, { value: "DAG" }];
    const { loading } = this.state;
    return /* @__PURE__ */ React.createElement(
      DrawerWithFooter,
      {
        title: i18n.t(!editMode ? "New Pipeline" : "Edit Pipeline"),
        onClose: this.props.onClose,
        onOk: this.onSubmit,
        onOkButtonLoading: loading
      },
      /* @__PURE__ */ React.createElement(Form, { field: this.field }, /* @__PURE__ */ React.createElement(Row, { wrap: true }, /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { required: true, label: /* @__PURE__ */ React.createElement(Translation, null, "Name") }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "name",
          disabled: editMode,
          ...init("name", {
            initValue: "",
            rules: [
              {
                pattern: checkName,
                message: "Please input a valid name"
              },
              {
                required: true,
                message: "Please input a name"
              }
            ]
          })
        }
      ))), /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Alias") }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "alias",
          placeholder: i18n.t("Give your pipeline a more recognizable name").toString(),
          ...init("alias", {
            rules: [
              {
                minLength: 2,
                maxLength: 64,
                message: "Enter a string of 2 to 64 characters."
              }
            ]
          })
        }
      ))), /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Project"), required: true }, /* @__PURE__ */ React.createElement(
        Select,
        {
          name: "project",
          placeholder: i18n.t("Please select a project").toString(),
          dataSource: projectOptions,
          filterLocal: true,
          hasClear: true,
          disabled: editMode,
          style: { width: "100%" },
          ...init("project", {
            initValue: defaultProject,
            rules: [
              {
                required: true,
                message: "Please select a project"
              }
            ]
          })
        }
      ))), /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { required: true, label: /* @__PURE__ */ React.createElement(Translation, null, "Step Mode") }, /* @__PURE__ */ React.createElement(
        Select,
        {
          name: "stepMode",
          ...init("stepMode", {
            initValue: "StepByStep"
          }),
          locale: locale().Select,
          dataSource: modeOptions
        }
      ))), /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { required: true, label: /* @__PURE__ */ React.createElement(Translation, null, "Sub Step Mode") }, /* @__PURE__ */ React.createElement(
        Select,
        {
          name: "subStepMode",
          ...init("subStepMode", {
            initValue: "DAG"
          }),
          locale: locale().Select,
          dataSource: modeOptions
        }
      ))), /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Description") }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "description",
          ...init("description", {
            rules: [
              {
                maxLength: 128,
                message: "Enter a description less than 128 characters."
              }
            ]
          })
        }
      ))), /* @__PURE__ */ React.createElement(If, { condition: !editMode }, /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Template") }, /* @__PURE__ */ React.createElement(
        Select,
        {
          locale: locale().Select,
          name: "template",
          dataSource: templates,
          hasClear: true,
          placeholder: "Select a template",
          onChange: (value) => {
            this.field.setValue("steps", value);
            const template = templates.find((t) => t.value == value);
            this.setState({ defaultContext: template?.defaultContext });
          }
        }
      ))))))
    );
  }
};
CreatePipeline = __decorateClass([
  connect((store) => {
    return { ...store.user };
  })
], CreatePipeline);
export default CreatePipeline;
