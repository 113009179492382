"use strict";
export var PluginType = /* @__PURE__ */ ((PluginType2) => {
  PluginType2["PageApp"] = "page-app";
  PluginType2["Definition"] = "definition";
  return PluginType2;
})(PluginType || {});
export class VelaUXPlugin {
  // Tabs on the plugin page
  addConfigPage(tab) {
    this.configPages = tab;
    return this;
  }
  constructor() {
    this.meta = {};
  }
}
