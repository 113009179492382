"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import React from "react";
import { Table, Progress, Message, Dialog, Button } from "@alifd/next";
import "../../index.less";
import { connect } from "dva";
import { routerRedux } from "dva/router";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { AiOutlineCode, AiOutlineCopy } from "react-icons/ai";
import { listApplicationPodsDetails } from "../../../../api/observation";
import { If } from "../../../../components/If";
import { Translation } from "../../../../components/Translation";
import i18n from "../../../../i18n";
import { checkEnabledAddon } from "../../../../utils/common";
import { locale } from "../../../../utils/locale";
import { quantityToScalar } from "../../../../utils/utils";
import ContainerLog from "../ContainerLog";
import { HiOutlineNewspaper } from "react-icons/hi";
let PodDetail = class extends React.Component {
  constructor(props) {
    super(props);
    this.showStatus = (statu) => {
      const statsuInfo = [
        { name: "running", value: /* @__PURE__ */ React.createElement("div", { style: { color: "green" } }, "Running") },
        { name: "terminated", value: /* @__PURE__ */ React.createElement("div", { style: { color: "red" } }, "Terminated") },
        { name: "waiting", value: /* @__PURE__ */ React.createElement("div", { style: { color: "#e17518" } }, "Waiting") }
      ];
      const findStatus = statsuInfo.find((item) => item.name === statu) || { value: /* @__PURE__ */ React.createElement("div", null) };
      return findStatus && findStatus.value;
    };
    this.getTimes = (record) => {
      const { firstTimestamp, eventTime } = record;
      if (eventTime) {
        const date = (new Date().getTime() - moment(eventTime).valueOf()) / 6e4;
        return date.toFixed(2);
      } else if (firstTimestamp) {
        const date = (new Date().getTime() - moment(firstTimestamp).valueOf()) / 6e4;
        return date.toFixed(2);
      }
      return;
    };
    this.onOpenCloudShell = (containerName) => {
      const { env, pod } = this.props;
      if (!checkEnabledAddon("cloudshell", this.props.enabledAddons)) {
        Dialog.alert({
          title: i18n.t("CloudShell feature is not enabled").toString(),
          content: i18n.t("You must enable the CloudShell addon first").toString(),
          locale: locale().Dialog,
          footer: /* @__PURE__ */ React.createElement(
            Button,
            {
              type: "secondary",
              onClick: () => {
                if (this.props.dispatch) {
                  this.props.dispatch(
                    routerRedux.push({
                      pathname: "/addons/cloudshell"
                    })
                  );
                }
              }
            },
            /* @__PURE__ */ React.createElement(Translation, null, "Go to enable")
          )
        });
        return;
      }
      const shellScript = `vela exec ${env?.appDeployName} -n ${env?.appDeployNamespace} --component ${pod.component} --cluster ${pod.cluster} --pod ${pod.metadata.name} --container ${containerName} -- bash`;
      Dialog.show({
        footer: false,
        style: { width: 600 },
        content: /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("h5", null, "1. ", /* @__PURE__ */ React.createElement(Translation, null, "Copy the command"), ":"), /* @__PURE__ */ React.createElement("code", { className: "code" }, shellScript, " ", /* @__PURE__ */ React.createElement(
          CopyToClipboard,
          {
            onCopy: () => {
              Message.success("Copied successfully");
            },
            text: shellScript
          },
          /* @__PURE__ */ React.createElement(AiOutlineCopy, { size: 14 })
        )), /* @__PURE__ */ React.createElement("h5", null, "2. ", /* @__PURE__ */ React.createElement(Translation, null, "Open Cloud Shell"), ":"), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
          Button,
          {
            size: "small",
            type: "secondary",
            onClick: () => {
              if (this.props.dispatch) {
                this.props.dispatch({
                  type: "cloudshell/open"
                });
              }
            }
          },
          /* @__PURE__ */ React.createElement(Translation, null, "Open Cloud Shell")
        )))
      });
    };
    this.loadPodDetail = async () => {
      listApplicationPodsDetails({
        name: this.props.pod.metadata.name || "",
        namespace: this.props.pod.metadata.namespace || "",
        cluster: this.props.pod.cluster || ""
      }).then((re) => {
        if (re && re.error) {
          Message.warning(re.error);
        } else if (re) {
          this.setState({
            containers: re.containers,
            events: re.events
          });
        }
      }).finally(() => {
        this.setState({ loading: false });
      });
    };
    this.showContainerLog = (containerName) => {
      this.setState({ showContainerLog: true, containerName });
    };
    this.getContainerColumns = () => {
      return [
        {
          key: "name",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Container Name"),
          dataIndex: "name",
          cell: (v) => {
            return /* @__PURE__ */ React.createElement("span", null, v);
          }
        },
        {
          key: "status",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Status"),
          dataIndex: "status",
          cell: (v, i, record) => {
            const { state = {} } = record.status || {};
            const key = Object.keys(state) && Object.keys(state)[0] || "";
            return this.showStatus(key);
          }
        },
        {
          key: "image",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Image"),
          dataIndex: "image",
          cell: (v) => {
            return /* @__PURE__ */ React.createElement("span", null, v);
          }
        },
        {
          key: "memory",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Memory"),
          dataIndex: "memory",
          cell: (v, i, record) => {
            if (record.resources?.requests?.memory && record.resources?.usage?.memory) {
              const requestMemory = quantityToScalar(record.resources?.requests?.memory);
              const useMemory = quantityToScalar(record.resources?.usage?.memory);
              const percent = Number(useMemory).valueOf() / Number(requestMemory).valueOf();
              return /* @__PURE__ */ React.createElement(Progress, { size: "small", percent: percent * 100 });
            }
            if (record.resources?.usage?.memory) {
              return /* @__PURE__ */ React.createElement("span", null, record.resources?.usage?.memory);
            }
            if (record.resources?.requests?.memory) {
              return /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(Translation, null, "Request"), ": ", record.resources?.requests?.memory);
            }
            return /* @__PURE__ */ React.createElement("span", null);
          }
        },
        {
          key: "cpu",
          title: /* @__PURE__ */ React.createElement(Translation, null, "CPU"),
          dataIndex: "cpu",
          cell: (v, i, record) => {
            if (record.resources?.requests?.cpu && record.resources?.usage?.cpu) {
              const requestcpu = quantityToScalar(record.resources?.requests?.cpu);
              const usecpu = quantityToScalar(record.resources?.usage?.cpu);
              const percent = Number(usecpu).valueOf() / Number(requestcpu).valueOf();
              return /* @__PURE__ */ React.createElement(Progress, { size: "small", percent: percent * 100 });
            }
            if (record.resources?.usage?.cpu) {
              return /* @__PURE__ */ React.createElement("span", null, record.resources?.usage?.cpu);
            }
            if (record.resources?.requests?.cpu) {
              return /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(Translation, null, "Request"), ": ", record.resources?.requests?.cpu);
            }
            return /* @__PURE__ */ React.createElement("span", null);
          }
        },
        {
          key: "restarts",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Restarts"),
          dataIndex: "restarts",
          cell: (v, i, record) => {
            if (record.status?.restartCount !== void 0) {
              return /* @__PURE__ */ React.createElement("span", null, record.status?.restartCount);
            }
            return /* @__PURE__ */ React.createElement("span", null);
          }
        },
        {
          key: "operation",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Actions"),
          dataIndex: "operation",
          cell: (v, i, record) => {
            return /* @__PURE__ */ React.createElement("div", { className: "operations" }, /* @__PURE__ */ React.createElement("a", { title: "Log", onClick: () => this.showContainerLog(record.name), className: "actionIcon" }, /* @__PURE__ */ React.createElement(HiOutlineNewspaper, null)), /* @__PURE__ */ React.createElement("a", { title: "Console Shell", onClick: () => this.onOpenCloudShell(record.name), className: "actionIcon" }, /* @__PURE__ */ React.createElement(AiOutlineCode, { size: 20 })));
          }
        }
      ];
    };
    this.getEventColumns = () => {
      return [
        {
          key: "type",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Event Type"),
          dataIndex: "type",
          width: 160,
          cell: (v, i, record) => {
            const { type, reason } = record;
            return /* @__PURE__ */ React.createElement("span", null, type, "/", reason);
          }
        },
        {
          key: "time",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Time"),
          dataIndex: "time",
          width: 100,
          cell: (v, i, record) => {
            return /* @__PURE__ */ React.createElement("span", null, " ", this.getTimes(record), "m");
          }
        },
        {
          key: "message",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Message"),
          dataIndex: "message",
          cell: (v) => {
            return /* @__PURE__ */ React.createElement("span", null, v);
          }
        }
      ];
    };
    this.state = { loading: true, showContainerLog: false, containerName: "" };
  }
  componentDidMount() {
    this.loadPodDetail();
  }
  render() {
    const { Column } = Table;
    const containerColumns = this.getContainerColumns();
    const eventColumns = this.getEventColumns();
    const { events, containers, loading, showContainerLog, containerName } = this.state;
    const { pod, clusterName } = this.props;
    return /* @__PURE__ */ React.createElement("div", { className: "table-podDetails-list  margin-top-20" }, /* @__PURE__ */ React.createElement(
      Table,
      {
        className: "container-table-wrapper margin-top-20",
        dataSource: containers || [],
        primaryKey: "name",
        loading,
        locale: locale().Table
      },
      containerColumns && containerColumns.map((col, key) => /* @__PURE__ */ React.createElement(Column, { ...col, key, align: "left" }))
    ), /* @__PURE__ */ React.createElement(
      Table,
      {
        className: "event-table-wrapper margin-top-20",
        dataSource: events || [],
        loading,
        primaryKey: "time",
        locale: locale().Table
      },
      eventColumns && eventColumns.map((col, key) => /* @__PURE__ */ React.createElement(Column, { ...col, key, align: "left" }))
    ), /* @__PURE__ */ React.createElement(If, { condition: showContainerLog }, /* @__PURE__ */ React.createElement(
      ContainerLog,
      {
        onClose: () => {
          this.setState({ showContainerLog: false, containerName: "" });
        },
        pod,
        containerName,
        clusterName
      }
    )));
  }
};
PodDetail = __decorateClass([
  connect((store) => {
    return { ...store.cloudshell, ...store.addons };
  })
], PodDetail);
export default PodDetail;
