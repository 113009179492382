"use strict";
import { Grid, Input } from "@alifd/next";
import React from "react";
import "./index.less";
import i18n from "../../../../i18n";
import { AiOutlineSearch } from "react-icons/ai";
class InputSearch extends React.Component {
  constructor(props) {
    super(props);
    this.handleChangName = (value) => {
      this.setState({
        inputValue: value
      });
    };
    this.handleClickSearch = () => {
      const { inputValue } = this.state;
      this.props.query(inputValue);
    };
    this.state = {
      inputValue: ""
    };
  }
  render() {
    const { Row, Col } = Grid;
    const { inputValue } = this.state;
    return /* @__PURE__ */ React.createElement(Row, { className: "cluster-input-wrapper" }, /* @__PURE__ */ React.createElement(Col, { span: "24" }, /* @__PURE__ */ React.createElement(
      Input,
      {
        innerAfter: /* @__PURE__ */ React.createElement(AiOutlineSearch, { onClick: this.handleClickSearch, style: { margin: 4 } }),
        placeholder: i18n.t("Search by Name and Description etc").toString(),
        onChange: this.handleChangName,
        onPressEnter: this.handleClickSearch,
        value: inputValue,
        size: "large",
        className: "item"
      }
    )));
  }
}
export default InputSearch;
