"use strict";
import * as monaco from "monaco-editor";
import React, { useEffect } from "react";
import defineTheme from "../DefinitionCode/theme";
export const DiffEditor = (props) => {
  const { language = "yaml", base, target, id } = props;
  useEffect(() => {
    const container = document.getElementById(id);
    if (container) {
      container.innerHTML = "";
    }
    if (defineTheme) {
      monaco.editor.defineTheme("default", defineTheme);
      monaco.editor.setTheme("default");
    }
    const diffEditor = monaco.editor.createDiffEditor(container);
    diffEditor.setModel({
      original: monaco.editor.createModel(base, language),
      modified: monaco.editor.createModel(target, language)
    });
  }, [id, language, base, target]);
  return /* @__PURE__ */ React.createElement(React.Fragment, null);
};
