"use strict";
export const application_mock = "/mock/application.json";
export const addons_mock = "/mock/addonsList.json";
export const cluster_mock = "/mock/cluster.json";
export const workflows_mock = "/mock/workflows.json";
export const project_mock = "/mock/projectlist.json";
export const addonsDetails_mock = "/mock/addonsDetails.json";
export const disabletAddonsCluster_mock = "/mock/disabletAddonsCluster.json";
export const enableAddonsCluster_mock = "/mock/enableAddonsCluster.json";
export const addonsStatus_mock = "/mock/addonsStatus.json";
export const getOneNamespace_mock = "/mock/getOneNamespace.json";
export const getClusterDetails_mock = "/mock/getClusterDetails.json";
export const getApplicationDetails_mock = "/mock/getApplicationDetails.json";
export const getApplicationComponents_mock = "/mock/getApplicationComponents.json";
export const createApplicationComponent_mock = "/mock/createApplicationComponent.json";
export const getComponentDetails_mock = "/mock/getComponentDetails.json";
export const updateApplication_mock = "/mock/updateApplication.json";
export const getPolicyList_mock = "/mock/getPolicyList.json";
export const createPolicy_mock = "/mock/createPolicy.json";
export const getPolicyDetails_mock = "/mock/getPolicyDetails.json";
export const createApplicationTemplate_mock = "/mock/createApplicationTemplate.json";
export const getWorkFlowsDetails_mock = "/mock/getWorkFlowsDetails.json";
export const createWorkFlow_mock = "/mock/createWorkFlow.json";
export const getWorkFlowsRecord_mock = "/mock/getWorkFlowsRecord.json";
export const clusterCloudList_mock = "/mock/clusterCloudList.json";
export const connectClusterCloud_mock = "/mock/connectClusterCloud.json";
export const createApplicationEnv_mock = "/mock/createApplicationEnv.json";
export const gettarget_mock = "/mock/gettarget.json";
export const getTraitDefinitionsDetails_mock = "/mock/getTraitDefinitionsDetails.json";
export const getTraitDefinitions_mock = "/mock/getTraitDefinitions.json";
export const getTrait_mock = "/mock/getTrait.json";
export const listWorkflowDefinition_mock = "/mock/listWorkFlowDefintion.json";
export const listWorkflowDetailsDefinition_mock = "/mock/listWorkFlowDetailsDefintion.json";
export const updateApplicationEnv_mock = `/mock/api/v1/applications/name/envs/envName`;
export const getPayloadType_mock = `/mock/getPayloadTypes.json`;
