"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Grid, Button, Message, Dialog } from "@alifd/next";
import classNames from "classnames";
import { connect } from "dva";
import { routerRedux } from "dva/router";
import i18n from "i18next";
import React, { Component } from "react";
import { Breadcrumb } from "../../../../components/Breadcrumb";
import { deployApplication } from "../../../../api/application";
import { If } from "../../../../components/If";
import Permission from "../../../../components/Permission";
import { Translation } from "../../../../components/Translation";
import { handleError } from "../../../../utils/errors";
import { locale } from "../../../../utils/locale";
import DeployConfig from "../DeployConfig";
const { Row, Col } = Grid;
let ApplicationHeader = class extends Component {
  constructor(props) {
    super(props);
    this.onDeployConfig = () => {
      this.loadApplicationStatus();
      this.setState({ showDeployConfig: true });
    };
    this.loadApplicationStatus = async () => {
      const { appName, dispatch } = this.props;
      this.setState({ loading: true });
      dispatch({
        type: "application/getApplicationAllStatus",
        payload: { appName },
        callback: () => {
          this.setState({ loading: false });
        }
      });
    };
    this.onGetApplicationDetails = async () => {
      const { appName, dispatch } = this.props;
      if (dispatch && appName) {
        dispatch({
          type: "application/getApplicationDetail",
          payload: { appName }
        });
      }
    };
    this.onDeploy = (workflowName, force) => {
      const { applicationDetail, dispatch } = this.props;
      if (applicationDetail) {
        deployApplication(
          {
            appName: applicationDetail.name,
            workflowName,
            triggerType: "web",
            force: force || false
          },
          true
        ).then((re) => {
          if (re) {
            Message.success(i18n.t("Application deployed successfully"));
            this.onGetApplicationDetails();
            if (re.record && re.record.name && dispatch) {
              dispatch(
                routerRedux.push(
                  `/applications/${applicationDetail.name}/envbinding/${re.envName}/workflow/records/${re.record.name}`
                )
              );
            }
          }
        }).catch((err) => {
          if (err.BusinessCode === 10004) {
            Dialog.confirm({
              content: i18n.t("Workflow is executing. Do you want to force a restart?").toString(),
              onOk: () => {
                this.onDeploy(workflowName, true);
              },
              locale: locale().Dialog
            });
          } else {
            handleError(err);
          }
        });
      } else {
        Message.warning("Please wait");
      }
    };
    this.state = {
      loading: false,
      showDeployConfig: false
    };
  }
  componentDidMount() {
  }
  componentWillUnmount() {
  }
  render() {
    const { applicationDetail, applicationAllStatus, currentPath, workflows, envbinding, appName, envName, dispatch } = this.props;
    const { showDeployConfig, loading } = this.state;
    const activeKey = currentPath.substring(currentPath.lastIndexOf("/") + 1);
    let item = /* @__PURE__ */ React.createElement(Translation, null, `app-${activeKey}`);
    const projectName = applicationDetail && applicationDetail.project?.name || "";
    const sourceOfTrust = applicationDetail?.labels && applicationDetail?.labels["app.oam.dev/source-of-truth"];
    const envPage = currentPath.startsWith(`/applications/${appName}/envbinding/`);
    if (envPage) {
      item = /* @__PURE__ */ React.createElement(Translation, null, `Environment`);
    }
    return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 6, className: classNames("padding16") }, /* @__PURE__ */ React.createElement(
      Breadcrumb,
      {
        items: [
          {
            to: "/projects/" + projectName + "/applications",
            title: projectName
          },
          {
            to: `/applications/${applicationDetail?.name || ""}`,
            title: applicationDetail && (applicationDetail.alias || applicationDetail.name) || ""
          },
          {
            title: item
          }
        ]
      }
    )), /* @__PURE__ */ React.createElement(Col, { span: 18, className: "flexright", style: { padding: "0 16px" } }, /* @__PURE__ */ React.createElement(If, { condition: applicationDetail?.readOnly }, /* @__PURE__ */ React.createElement(
      Message,
      {
        type: "notice",
        title: i18n.t("This application is managed by the addon, and it is readonly").toString()
      }
    )), /* @__PURE__ */ React.createElement(If, { condition: sourceOfTrust === "from-k8s-resource" }, /* @__PURE__ */ React.createElement(Message, { type: "warning", title: i18n.t("The application is synchronizing from the cluster.").toString() })), /* @__PURE__ */ React.createElement(
      Permission,
      {
        request: {
          resource: `project:${projectName}/application:${applicationDetail && applicationDetail.name}`,
          action: "deploy"
        },
        project: projectName
      },
      /* @__PURE__ */ React.createElement(
        Button,
        {
          style: { marginLeft: "16px" },
          type: "primary",
          disabled: applicationDetail?.readOnly,
          onClick: () => this.onDeployConfig()
        },
        /* @__PURE__ */ React.createElement(Translation, null, "Deploy")
      )
    ))), /* @__PURE__ */ React.createElement(If, { condition: showDeployConfig }, applicationDetail && envbinding && workflows && /* @__PURE__ */ React.createElement(
      DeployConfig,
      {
        loading,
        envName,
        applicationAllStatus,
        applicationDetail,
        envBindings: envbinding,
        onClose: () => {
          this.setState({ showDeployConfig: false });
        },
        dispatch,
        appName,
        onOK: this.onDeploy,
        workflows
      }
    )));
  }
};
ApplicationHeader = __decorateClass([
  connect((store) => {
    return { ...store.application };
  })
], ApplicationHeader);
export default ApplicationHeader;
