"use strict";
import { Card, Grid, Dialog } from "@alifd/next";
import React, { Component } from "react";
import helm from "../../../../assets/helm.svg";
import kubernetes from "../../../../assets/kubernetes.svg";
import terraform from "../../../../assets/terraform.svg";
import Empty from "../../../../components/Empty";
import { If } from "../../../../components/If";
import Permission from "../../../../components/Permission";
import { Translation } from "../../../../components/Translation";
import i18n from "../../../../i18n";
import "./index.less";
import { showAlias } from "../../../../utils/common";
import { locale } from "../../../../utils/locale";
import { IoMdAdd } from "react-icons/io";
import { AiOutlineDelete } from "react-icons/ai";
class ComponentsList extends Component {
  constructor() {
    super(...arguments);
    this.handleDelete = (name) => {
      Dialog.alert({
        content: i18n.t("Are you sure want to delete this Component?").toString(),
        onOk: () => {
          this.props.onDeleteComponent(name || "");
        },
        onClose: () => {
        },
        locale: locale().Dialog
      });
    };
    this.getComponentTypeIcon = (com) => {
      if (com.workloadType?.type == "configurations.terraform.core.oam.dev") {
        return /* @__PURE__ */ React.createElement("img", { className: "component-icon", src: terraform });
      }
      if (com.componentType.indexOf("helm") > -1) {
        return /* @__PURE__ */ React.createElement("img", { className: "component-icon", src: helm });
      }
      return /* @__PURE__ */ React.createElement("img", { className: "component-icon", src: kubernetes });
    };
  }
  render() {
    const { Row, Col } = Grid;
    const { components, editComponent, application } = this.props;
    const projectName = application && application.project?.name;
    return /* @__PURE__ */ React.createElement("div", { className: "list-warper" }, /* @__PURE__ */ React.createElement("div", { className: "box" }, (components || []).map((item) => /* @__PURE__ */ React.createElement(Row, { key: item.name, wrap: true, className: "box-item" }, /* @__PURE__ */ React.createElement(Col, { span: 24 }, /* @__PURE__ */ React.createElement(Card, { locale: locale().Card, contentHeight: "auto" }, /* @__PURE__ */ React.createElement("div", { className: "components-list-nav" }, /* @__PURE__ */ React.createElement(
      "div",
      {
        className: "components-list-title",
        onClick: () => {
          editComponent(item);
        }
      },
      this.getComponentTypeIcon(item),
      showAlias(item)
    ), /* @__PURE__ */ React.createElement(If, { condition: item.main != true }, /* @__PURE__ */ React.createElement("div", { className: "components-list-operation" }, /* @__PURE__ */ React.createElement(If, { condition: !application?.readOnly }, /* @__PURE__ */ React.createElement(
      Permission,
      {
        request: {
          resource: `project:${projectName}/application:${application?.name}/component:${item.name}`,
          action: "delete"
        },
        project: projectName
      },
      /* @__PURE__ */ React.createElement(
        AiOutlineDelete,
        {
          size: 14,
          className: "cursor-pointer danger-icon",
          onClick: () => {
            this.handleDelete(item.name || "");
          }
        }
      )
    ))))), /* @__PURE__ */ React.createElement(If, { condition: item.description }, /* @__PURE__ */ React.createElement("div", { className: "components-list-content" }, item.description)), /* @__PURE__ */ React.createElement(Row, { wrap: true }, item.traits?.map((trait) => {
      const label = showAlias(trait.type, trait.alias);
      return /* @__PURE__ */ React.createElement(
        "div",
        {
          key: trait.type,
          onClick: () => this.props.changeTraitStats(true, trait, item.name),
          className: "trait-icon",
          title: trait.description || label
        },
        /* @__PURE__ */ React.createElement("div", null, label),
        /* @__PURE__ */ React.createElement("div", { className: "trait-actions" }, /* @__PURE__ */ React.createElement(
          Permission,
          {
            request: {
              resource: `project:${projectName}/application:${application?.name}/component:${item.name}/trait:${trait.type}`,
              action: "delete"
            },
            project: projectName
          },
          /* @__PURE__ */ React.createElement(
            AiOutlineDelete,
            {
              onClick: (event) => {
                event.stopPropagation();
                this.props.onDeleteTrait(item.name, trait.type);
              },
              size: 14,
              className: "danger-icon"
            }
          )
        ))
      );
    }), /* @__PURE__ */ React.createElement(
      Permission,
      {
        request: {
          resource: `project:${projectName}/application:${application?.name}/component:${item.name}/trait:*`,
          action: "create"
        },
        project: projectName
      },
      /* @__PURE__ */ React.createElement(
        "div",
        {
          title: i18n.t("Add a trait"),
          className: "trait-icon",
          onClick: () => this.props.onAddTrait(item.name)
        },
        /* @__PURE__ */ React.createElement(IoMdAdd, null)
      )
    )))))), /* @__PURE__ */ React.createElement(If, { condition: !components || components.length == 0 }, /* @__PURE__ */ React.createElement(
      Empty,
      {
        style: { minHeight: "400px" },
        message: /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(Translation, null, "There are no components"))
      }
    ))));
  }
}
export default ComponentsList;
