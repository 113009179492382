"use strict";
import { Form, Field, Button } from "@alifd/next";
import React from "react";
import UISchema from "../../components/UISchema";
import ArrayItemGroup from "../ArrayItemGroup";
import "./index.less";
import { If } from "../../components/If";
import { AiOutlineDelete } from "react-icons/ai";
class StructItem extends React.Component {
  constructor(props) {
    super(props);
    this.validator = (rule, value, callback) => {
      this.uiRef.current?.validate(callback);
    };
    this.getParamCount = (params) => {
      let count = 0;
      if (!params && !Array.isArray(params)) {
        return count;
      }
      params.map((p) => {
        if (!p.disable && p.uiType != "Ignore" && p.uiType != "InnerGroup") {
          if (["Structs", "Strings", "CertBase64", "Group", "ImageInput", "K8sObjectsCode", "KV"].indexOf(p.uiType) > -1) {
            count += 3;
          } else {
            count += 1;
          }
        }
        if (!p.disable && p.subParameters) {
          count += this.getParamCount(p.subParameters);
        }
      });
      return count;
    };
    this.state = {
      structList: []
    };
    this.uiRef = React.createRef();
  }
  render() {
    const { option, param, id, init, labelTitle } = this.props;
    let uiSchemas = param;
    if (option && option.length > 0) {
      const paramMap = param && param.reduce((pre, next) => {
        pre[next.jsonKey] = next;
        return pre;
      }, {});
      uiSchemas = option.map((key) => paramMap[key]);
    }
    const paramCount = this.getParamCount(uiSchemas);
    const itemCount = uiSchemas?.filter((p) => !p.disable).length || 1;
    return /* @__PURE__ */ React.createElement("div", { className: "struct-item-container" }, /* @__PURE__ */ React.createElement(If, { condition: paramCount > 3 }, /* @__PURE__ */ React.createElement("div", { className: "struct-item-content" }, /* @__PURE__ */ React.createElement(
      ArrayItemGroup,
      {
        id,
        labelTitle,
        delete: (structId) => {
          this.props.delete(structId);
        }
      },
      /* @__PURE__ */ React.createElement(
        UISchema,
        {
          ...init(`struct${id}`, {
            rules: [
              {
                validator: this.validator,
                message: "please check config item"
              }
            ]
          }),
          uiSchema: uiSchemas,
          inline: true,
          ref: this.uiRef,
          mode: this.props.mode
        }
      )
    ))), /* @__PURE__ */ React.createElement(If, { condition: paramCount <= 3 }, /* @__PURE__ */ React.createElement("div", { className: "struct-item-content" }, /* @__PURE__ */ React.createElement(
      UISchema,
      {
        ...init(`struct${id}`, {
          rules: [
            {
              validator: this.validator,
              message: "please check config item"
            }
          ]
        }),
        uiSchema: uiSchemas,
        maxColSpan: 24 / itemCount,
        inline: true,
        ref: this.uiRef,
        mode: this.props.mode
      }
    )), /* @__PURE__ */ React.createElement("div", { className: "remove-option-container" }, /* @__PURE__ */ React.createElement(
      AiOutlineDelete,
      {
        onClick: () => {
          if (this.props.delete) {
            this.props.delete(this.props.id);
          }
        }
      }
    ))));
  }
}
class Structs extends React.Component {
  constructor(props) {
    super(props);
    this.componentDidMount = () => {
      this.initValue();
    };
    this.initValue = () => {
      const { value, parameterGroupOption } = this.props;
      if (value) {
        const keyMap = /* @__PURE__ */ new Map();
        let firstOption = void 0;
        if (parameterGroupOption) {
          parameterGroupOption.map((item) => {
            if (item && item.keys) {
              if (!firstOption) {
                firstOption = item;
              }
              keyMap.set(item.keys.sort().join(), item);
            }
          });
        }
        const structList = [];
        value.map((item, index) => {
          const key = Date.now().toString() + index;
          const valueKeys = [];
          for (const itemkey in item) {
            valueKeys.push(itemkey);
          }
          const option = keyMap.get(valueKeys.sort().join());
          structList.push({
            key,
            option: option?.keys || firstOption?.keys,
            value
          });
          this.field.setValue("struct" + key, item);
        });
        this.setState({ structList });
      }
    };
    this.setValues = () => {
      const values = this.field.getValues();
      const { onChange } = this.props;
      const result = Object.keys(values).map((key) => {
        return values[key];
      });
      if (onChange) {
        onChange(result);
      }
    };
    this.addStructPlanItem = (option, value) => {
      this.field.validate((error) => {
        if (error) {
          return;
        }
        const { structList } = this.state;
        const key = Date.now().toString();
        structList.push({
          key,
          option: option?.keys,
          value
        });
        this.setState({
          structList
        });
      });
    };
    this.removeStructPlanItem = (key) => {
      const { structList } = this.state;
      structList.forEach((item, i) => {
        if (item.key === key) {
          structList.splice(i, 1);
        }
      });
      this.field.remove("struct" + key);
      this.setValues();
      this.setState({
        structList
      });
    };
    this.state = {
      structList: []
    };
    this.field = new Field(this, {
      onChange: () => {
        this.setValues();
      }
    });
    this.props.registerForm(this.field);
  }
  render() {
    const { structList } = this.state;
    const { param, parameterGroupOption = [], label } = this.props;
    const { init } = this.field;
    return /* @__PURE__ */ React.createElement("div", { className: "struct-plan-container" }, /* @__PURE__ */ React.createElement("div", { className: "struct-plan-group" }, /* @__PURE__ */ React.createElement(Form, { field: this.field }, structList.map((struct) => {
      const fieldObj = this.field.getValues();
      const name = fieldObj[`struct${struct.key}`]?.name || "";
      let labelTitle = label;
      if (name) {
        labelTitle = /* @__PURE__ */ React.createElement("span", null, label, ": ", /* @__PURE__ */ React.createElement("span", { style: { marginLeft: "8px" } }, name));
      }
      return /* @__PURE__ */ React.createElement(
        StructItem,
        {
          delete: this.removeStructPlanItem,
          id: struct.key,
          key: struct.key,
          init,
          option: struct.option,
          param,
          labelTitle,
          mode: this.props.mode
        }
      );
    }))), /* @__PURE__ */ React.createElement("div", { className: "struct-plan-option" }, /* @__PURE__ */ React.createElement(If, { condition: parameterGroupOption.length === 0 }, /* @__PURE__ */ React.createElement(
      Button,
      {
        onClick: () => {
          this.addStructPlanItem();
        },
        type: "secondary"
      },
      "Add"
    )), /* @__PURE__ */ React.createElement(If, { condition: parameterGroupOption.length !== 0 }, /* @__PURE__ */ React.createElement(Button.Group, null, parameterGroupOption?.map((item) => /* @__PURE__ */ React.createElement(Button, { type: "secondary", key: item.keys.join(","), onClick: () => this.addStructPlanItem(item) }, item.label || item.keys.join(":")))))));
  }
}
export default Structs;
