"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Grid, Field, Form, Select, Message, Button } from "@alifd/next";
import { connect } from "dva";
import { Link } from "dva/router";
import React from "react";
import { createApplication } from "../../../../api/application";
import { detailComponentDefinition } from "../../../../api/definitions";
import { getEnvs } from "../../../../api/env";
import DrawerWithFooter from "../../../../components/Drawer";
import { Translation } from "../../../../components/Translation";
import UISchema from "../../../../components/UISchema";
import { locale } from "../../../../utils/locale";
import EnvDialog from "../../../EnvPage/components/EnvDialog";
import GeneralConfig from "../GeneralConfig";
let AppDialog = class extends React.Component {
  constructor(props) {
    super(props);
    this.onClose = () => {
      this.props.setVisible(false);
    };
    this.onSubmit = () => {
      this.field.validate((error, values) => {
        if (error) {
          return;
        }
        const { description, alias, name, icon = "", componentType, properties, envBindings, project } = values;
        const envbinding = envBindings?.map((env) => {
          return { name: env };
        });
        const params = {
          alias,
          icon,
          name,
          description,
          project: project || "default",
          envBinding: envbinding,
          component: {
            alias,
            componentType,
            description,
            icon,
            name,
            properties: JSON.stringify(properties)
          }
        };
        this.setState({ createLoading: true });
        createApplication(params).then((res) => {
          if (res && res.name) {
            Message.success(/* @__PURE__ */ React.createElement(Translation, null, "Application created successfully"));
            this.props.onOK(name);
          }
          this.setState({ createLoading: false });
        });
      });
    };
    this.loadEnvs = (callback) => {
      if (this.state.project) {
        getEnvs({ project: this.state.project, page: 0 }).then((res) => {
          if (res) {
            this.setState({ envs: res && res.envs });
            const envOption = (res?.envs || []).map((env) => {
              return {
                label: env.alias ? `${env.alias}(${env.name})` : env.name,
                value: env.name
              };
            });
            if (callback) {
              callback(envOption[0]?.value || "");
            }
          }
        });
      }
    };
    this.onDetailComponentDefinition = (value) => {
      detailComponentDefinition({ name: value }).then((re) => {
        if (re) {
          this.setState({ definitionDetail: re, definitionLoading: false });
        }
      });
    };
    this.changeStatus = (value) => {
      const values = this.field.getValues();
      const { envBindings } = values;
      if (value === "isCreateComponent") {
        this.field.validateCallback(
          ["name", "alias", "description", "project", "componentType", "envBindings"],
          (error) => {
            if (error) {
              return;
            }
            const { dispatch } = this.props;
            if (Array.isArray(envBindings) && envBindings.length > 0) {
              const { envs } = this.state;
              let namespace = "";
              envs?.map((env) => {
                if (envBindings[0] == env.name) {
                  namespace = env.namespace;
                }
              });
              dispatch({
                type: "uischema/setAppNamespace",
                payload: namespace
              });
              dispatch({
                type: "uischema/setProject",
                payload: values.project
              });
            }
            this.setState({
              dialogStats: value
            });
          }
        );
      } else if (value === "isBasic") {
        this.setState({
          dialogStats: value
        });
      }
    };
    this.extButtonList = () => {
      const { dialogStats, createLoading } = this.state;
      const { onClose } = this.props;
      if (dialogStats === "isBasic") {
        return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Button, { type: "secondary", onClick: onClose, className: "margin-right-10" }, /* @__PURE__ */ React.createElement(Translation, null, "Cancel")), /* @__PURE__ */ React.createElement(
          Button,
          {
            type: "primary",
            onClick: () => {
              this.changeStatus("isCreateComponent");
            }
          },
          /* @__PURE__ */ React.createElement(Translation, null, "Next Step")
        ));
      } else if (dialogStats === "isCreateComponent") {
        return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
          Button,
          {
            type: "secondary",
            onClick: () => {
              this.changeStatus("isBasic");
            },
            className: "margin-right-10"
          },
          /* @__PURE__ */ React.createElement(Translation, null, "Previous")
        ), /* @__PURE__ */ React.createElement(Button, { loading: createLoading, type: "primary", onClick: this.onSubmit }, /* @__PURE__ */ React.createElement(Translation, null, "Create")));
      } else {
        return /* @__PURE__ */ React.createElement("div", null);
      }
    };
    this.onCloseEnvDialog = () => {
      this.setState({
        visibleEnvDialog: false
      });
    };
    this.onOKEnvDialog = () => {
      this.setState(
        {
          visibleEnvDialog: false
        },
        () => {
          this.loadEnvs(this.setEnvValue);
        }
      );
    };
    this.changeEnvDialog = (visible) => {
      this.setState({
        visibleEnvDialog: visible
      });
    };
    this.setEnvValue = (envBinding) => {
      const envBindings = this.field.getValue("envBindings");
      (envBindings || []).push(envBinding);
      this.field.setValues({ envBindings });
    };
    this.removeProperties = () => {
      this.field.remove("properties");
      this.setState({ definitionDetail: void 0 });
    };
    this.handleChange = (value) => {
      this.removeProperties();
      this.field.setValues({ componentType: value });
      this.onDetailComponentDefinition(value);
    };
    this.state = {
      definitionLoading: true,
      dialogStats: "isBasic",
      envs: [],
      visibleEnvDialog: false,
      createLoading: false
    };
    this.field = new Field(this, {
      autoUnmount: false,
      onChange: (name, value) => {
        if (name === "project") {
          this.setState({ project: value }, () => {
            this.loadEnvs();
            this.field.setValue("envBindings", []);
          });
        }
      }
    });
    this.uiSchemaRef = React.createRef();
    this.basicRef = React.createRef();
  }
  componentDidMount() {
    const { projects, projectName } = this.props;
    if (projectName) {
      this.field.setValue("project", projectName);
    }
    let defaultProject = "";
    (projects || []).map((item, i) => {
      if (i == 0) {
        defaultProject = item.name;
      }
      if (item.name == "default") {
        defaultProject = item.name;
      }
      return;
    });
    if (projectName || defaultProject) {
      this.setState({ project: projectName ? projectName : defaultProject }, () => {
        this.loadEnvs();
      });
    }
    this.onDetailComponentDefinition("webservice");
  }
  transComponentDefinitions() {
    const { componentDefinitions } = this.props;
    const defaultCoreDataSource = ["k8s-objects", "task", "webservice", "worker"];
    const cloud = [
      {
        label: "Cloud",
        children: []
      }
    ];
    const core = [
      {
        label: "Core",
        children: []
      }
    ];
    const custom = [
      {
        label: "Custom",
        children: []
      }
    ];
    (componentDefinitions || []).map((item) => {
      if (item.workloadType === "configurations.terraform.core.oam.dev") {
        cloud[0].children.push({
          label: item.name,
          value: item.name
        });
      } else if (defaultCoreDataSource.includes(item.name)) {
        core[0].children.push({
          label: item.name,
          value: item.name
        });
      } else {
        custom[0].children.push({
          label: item.name,
          value: item.name
        });
      }
    });
    return [...core, ...custom, ...cloud];
  }
  render() {
    const init = this.field.init;
    const FormItem = Form.Item;
    const { Row, Col } = Grid;
    const { visible, setVisible, dispatch, projects, onClose, isDisableProject, userInfo } = this.props;
    const { definitionDetail, dialogStats, envs, visibleEnvDialog } = this.state;
    const validator = (rule, value, callback) => {
      this.uiSchemaRef.current?.validate(callback);
    };
    const envOptions = envs?.map((env) => {
      return {
        label: env.alias ? `${env.alias}(${env.name})` : env.name,
        value: env.name
      };
    });
    const secondStep = dialogStats === "isCreateComponent" && definitionDetail && definitionDetail.uiSchema ? true : false;
    init("test");
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
      DrawerWithFooter,
      {
        title: /* @__PURE__ */ React.createElement(Translation, null, "New Application"),
        placement: "right",
        width: 800,
        visible,
        onClose,
        extButtons: this.extButtonList()
      },
      /* @__PURE__ */ React.createElement(Form, { field: this.field }, dialogStats === "isBasic" && /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
        GeneralConfig,
        {
          visible,
          setVisible,
          dispatch,
          userInfo,
          projects,
          isDisableProject,
          field: this.field,
          ref: this.basicRef
        }
      ), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(
        FormItem,
        {
          label: /* @__PURE__ */ React.createElement(Translation, { className: "font-size-14 font-weight-bold" }, "Main Component Type"),
          required: true,
          help: /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(Translation, null, "Get more component type?"), /* @__PURE__ */ React.createElement(Link, { to: "/addons" }, /* @__PURE__ */ React.createElement(Translation, null, "Go to enable addon")))
        },
        /* @__PURE__ */ React.createElement(
          Select,
          {
            locale: locale().Select,
            showSearch: true,
            className: "select",
            ...init(`componentType`, {
              initValue: "webservice",
              rules: [
                {
                  required: true,
                  message: "Please select"
                }
              ]
            }),
            dataSource: this.transComponentDefinitions(),
            onChange: this.handleChange
          }
        )
      ))), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(
        FormItem,
        {
          label: /* @__PURE__ */ React.createElement(Translation, { className: "font-size-14 font-weight-bold" }, "Bind Environments"),
          help: /* @__PURE__ */ React.createElement(
            "a",
            {
              onClick: () => {
                this.changeEnvDialog(true);
              }
            },
            /* @__PURE__ */ React.createElement(Translation, null, "New Environment")
          ),
          required: true
        },
        /* @__PURE__ */ React.createElement(
          Select,
          {
            ...init(`envBindings`, {
              rules: [
                {
                  required: true,
                  message: "Please select env"
                }
              ]
            }),
            locale: locale().Select,
            mode: "multiple",
            dataSource: envOptions
          }
        )
      )))), secondStep && /* @__PURE__ */ React.createElement(FormItem, { required: true }, /* @__PURE__ */ React.createElement(
        UISchema,
        {
          ...init(`properties`, {
            rules: [
              {
                validator,
                message: "Please check app deploy properties"
              }
            ]
          }),
          uiSchema: definitionDetail && definitionDetail.uiSchema,
          ref: this.uiSchemaRef,
          mode: "new"
        }
      )))
    ), visibleEnvDialog && /* @__PURE__ */ React.createElement(
      EnvDialog,
      {
        visible: visibleEnvDialog,
        userInfo,
        projects: projects || [],
        project: this.field.getValue("project"),
        isEdit: false,
        onClose: this.onCloseEnvDialog,
        onOK: this.onOKEnvDialog
      }
    ));
  }
};
AppDialog = __decorateClass([
  connect(() => {
    return {};
  })
], AppDialog);
export default AppDialog;
