"use strict";
import { Balloon, Button, Dialog, Table, Message } from "@alifd/next";
import classNames from "classnames";
import { Link } from "dva/router";
import React, { useState, useEffect } from "react";
import { AiFillDelete } from "react-icons/ai";
import { deletePipelineRun, loadPipelineRuns } from "../../../../api/pipeline";
import { If } from "../../../../components/If";
import Permission from "../../../../components/Permission";
import { Translation } from "../../../../components/Translation";
import i18n from "../../../../i18n";
import { momentDate, timeDiff } from "../../../../utils/common";
import { locale } from "../../../../utils/locale";
const ViewRuns = (props) => {
  const [loading, setLoading] = useState(true);
  const [runs, setRuns] = useState([]);
  const { pipeline } = props;
  useEffect(() => {
    if (loading) {
      loadPipelineRuns({ projectName: pipeline.project.name, pipelineName: pipeline.name }).then((res) => {
        setRuns(res && res.runs ? res.runs : []);
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [pipeline, loading]);
  const deleteRun = (name) => {
    if (name) {
      Dialog.confirm({
        type: "confirm",
        content: /* @__PURE__ */ React.createElement(Translation, null, "Unrecoverable after deletion, are you sure to delete it?"),
        onOk: () => {
          deletePipelineRun({
            projectName: pipeline.project.name,
            pipelineName: pipeline.name,
            runName: name
          }).then((res) => {
            if (res) {
              Message.success(i18n.t("The Pipeline Run removed successfully"));
              setLoading(true);
            }
          });
        },
        locale: locale().Dialog
      });
    }
  };
  return /* @__PURE__ */ React.createElement(
    Dialog,
    {
      v2: true,
      visible: true,
      locale: locale().Dialog,
      title: i18n.t("Pipeline Runs").toString(),
      onClose: props.onClose,
      onCancel: props.onClose,
      footerActions: ["cancel"],
      overflowScroll: true,
      style: { width: "1200px" }
    },
    /* @__PURE__ */ React.createElement(Table, { loading, dataSource: runs, locale: locale().Table }, /* @__PURE__ */ React.createElement(
      Table.Column,
      {
        key: "name",
        dataIndex: "pipelineRunName",
        title: i18n.t("Name").toString(),
        cell: (name) => {
          return /* @__PURE__ */ React.createElement(Link, { to: `/projects/${props.pipeline.project.name}/pipelines/${props.pipeline.name}/runs/${name}` }, name);
        }
      }
    ), /* @__PURE__ */ React.createElement(
      Table.Column,
      {
        key: "status",
        dataIndex: "phase",
        title: i18n.t("Status").toString(),
        cell: (phase, i, run) => {
          const show = /* @__PURE__ */ React.createElement(
            "span",
            {
              className: classNames({
                colorRed: phase == "failed",
                colorGreen: phase == "succeeded"
              })
            },
            phase.toUpperCase()
          );
          if (run.message) {
            return /* @__PURE__ */ React.createElement(Balloon, { trigger: show }, run.message);
          }
          return show;
        }
      }
    ), /* @__PURE__ */ React.createElement(
      Table.Column,
      {
        key: "startTime",
        dataIndex: "startTime",
        title: i18n.t("Start Time").toString(),
        cell: (value) => {
          return momentDate(value);
        }
      }
    ), /* @__PURE__ */ React.createElement(
      Table.Column,
      {
        key: "endTime",
        dataIndex: "endTime",
        title: i18n.t("End Time").toString(),
        cell: (value) => {
          return momentDate(value);
        }
      }
    ), /* @__PURE__ */ React.createElement(
      Table.Column,
      {
        key: "endTime",
        dataIndex: "endTime",
        title: i18n.t("Duration").toString(),
        cell: (value, i, run) => {
          return timeDiff(run.startTime, run.endTime);
        }
      }
    ), /* @__PURE__ */ React.createElement(
      Table.Column,
      {
        key: "contextName",
        dataIndex: "contextName",
        title: i18n.t("Context Name").toString(),
        cell: (value) => {
          return value;
        }
      }
    ), /* @__PURE__ */ React.createElement(
      Table.Column,
      {
        key: "actions",
        dataIndex: "pipelineRunName",
        title: i18n.t("Actions").toString(),
        cell: (name, i, run) => {
          return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(If, { condition: run.phase != "executing" }, /* @__PURE__ */ React.createElement(
            Permission,
            {
              project: props.pipeline.project.name,
              resource: {
                resource: `project:${props.pipeline.project.name}/pipeline:${props.pipeline.name}/pipelineRun:${name}`,
                action: "delete"
              }
            },
            /* @__PURE__ */ React.createElement(
              Button,
              {
                text: true,
                size: "small",
                className: "danger-btn",
                component: "a",
                onClick: () => {
                  deleteRun(name);
                }
              },
              /* @__PURE__ */ React.createElement(AiFillDelete, null),
              /* @__PURE__ */ React.createElement(Translation, null, "Remove")
            )
          )));
        }
      }
    ))
  );
};
export default ViewRuns;
