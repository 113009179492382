"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Button, Message } from "@alifd/next";
import { connect } from "dva";
import React, { Fragment } from "react";
import { getEnabledAddons, getAddonsList } from "../../api/addons";
import { deleteCluster } from "../../api/cluster";
import { If } from "../../components/If";
import { ListTitle } from "../../components/ListTitle";
import Permission from "../../components/Permission";
import { Translation } from "../../components/Translation";
import AddClusterDialog from "./components/AddClusterDialog/index";
import CardContend from "./components/CardContent/index";
import CloudServiceDialog from "./components/CloudServiceDialog/index";
import SelectSearch from "./components/ClusterSelectSearch";
let Cluster = class extends React.Component {
  constructor(props) {
    super(props);
    this.getClusterList = async () => {
      const { page, pageSize, query } = this.state;
      this.props.dispatch({
        type: "clusters/getClusterList",
        payload: {
          query,
          page,
          pageSize
        }
      });
    };
    this.query = (query) => {
      this.setState(
        {
          query
        },
        () => {
          this.getClusterList();
        }
      );
    };
    this.onDeleteCluster = (name) => {
      deleteCluster({ clusterName: name }).then((re) => {
        if (re) {
          Message.success("cluster delete success");
          this.getClusterList();
        }
      });
    };
    this.onGetEnabledAddon = async () => {
      getEnabledAddons({}).then((res) => {
        this.onGetAddonsList(res.enabledAddons);
      });
    };
    this.onGetAddonsList = async (enableAddon) => {
      getAddonsList({}).then((res) => {
        const addonMessage = [];
        const addonList = res.addons;
        (enableAddon || []).forEach((ele) => {
          addonList.forEach((item) => {
            const isMatchName = ele.name === item.name;
            const deploy = item.deployTo || { runtimeCluster: false, runtime_cluster: false };
            if (isMatchName && deploy.runtimeCluster) {
              addonMessage.push({ name: item.name, path: item.url || "" });
            }
          });
        });
        if (addonMessage && addonMessage.length !== 0) {
          this.setState({
            isShowAddonMessage: true,
            addonMessage
          });
        }
      });
    };
    this.handleHiddenAddonMessage = () => {
      this.setState({ isShowAddonMessage: false });
    };
    this.state = {
      query: "",
      page: 0,
      pageSize: 10,
      showAddCluster: false,
      showAddCloudCluster: false,
      editClusterName: "",
      addonMessage: [],
      isShowAddonMessage: false
    };
  }
  componentDidMount() {
    this.getClusterList();
  }
  showAddonMessage() {
    const { addonMessage } = this.state;
    return (addonMessage || []).map((item, index, arr) => {
      const lastIndex = arr.length - 1;
      const showSymbol = index === lastIndex ? "" : "\u3001";
      return /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement("a", { href: item.path }, item.name, showSymbol));
    });
  }
  render() {
    const { clusterList = [], dispatch } = this.props;
    const {
      page,
      pageSize,
      query,
      showAddCluster,
      showAddCloudCluster,
      editClusterName,
      isShowAddonMessage,
      addonMessage
    } = this.state;
    return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
      ListTitle,
      {
        title: "Clusters",
        subTitle: "Setup Kubernetes clusters by adding an existing one or creating a new one via cloud provider",
        extButtons: [
          /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement(Permission, { request: { resource: "cluster:*", action: "create" }, project: "" }, /* @__PURE__ */ React.createElement(
            Button,
            {
              type: "secondary",
              style: { marginRight: "16px" },
              onClick: () => {
                this.setState({ showAddCloudCluster: true });
              }
            },
            /* @__PURE__ */ React.createElement(Translation, null, "Connect From Cloud")
          ), /* @__PURE__ */ React.createElement(
            Button,
            {
              type: "primary",
              style: { marginRight: "16px" },
              onClick: () => {
                this.setState({ showAddCluster: true });
              }
            },
            /* @__PURE__ */ React.createElement(Translation, null, "Connect Existing Cluster")
          )))
        ]
      }
    ), /* @__PURE__ */ React.createElement(If, { condition: isShowAddonMessage && addonMessage.length != 0 }, /* @__PURE__ */ React.createElement(Message, { type: "notice", closeable: true, onClose: this.handleHiddenAddonMessage }, "Connect Cluster Success! Please upgrade ", this.showAddonMessage(), " addons, make them take effect in the new cluster.")), /* @__PURE__ */ React.createElement(
      SelectSearch,
      {
        query: (q) => {
          this.query(q);
        }
      }
    ), /* @__PURE__ */ React.createElement(
      CardContend,
      {
        editCluster: (name) => {
          this.setState({ showAddCluster: true, editClusterName: name });
        },
        deleteCluster: this.onDeleteCluster,
        clusters: clusterList
      }
    ), /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(If, { condition: showAddCloudCluster }, /* @__PURE__ */ React.createElement(
      CloudServiceDialog,
      {
        visible: showAddCloudCluster,
        setVisible: (visible) => {
          this.getClusterList();
          this.setState({ showAddCloudCluster: visible });
        },
        setCloudService: (visible) => {
          this.setState({ showAddCloudCluster: visible });
        },
        onPropsOK: () => {
          this.onGetEnabledAddon();
        },
        dispatch
      }
    )), /* @__PURE__ */ React.createElement(If, { condition: showAddCluster }, /* @__PURE__ */ React.createElement(
      AddClusterDialog,
      {
        page,
        pageSize,
        query,
        visible: showAddCluster,
        editClusterName,
        onClose: () => {
          this.getClusterList();
          this.setState({ showAddCluster: false, editClusterName: "" });
        },
        onOK: () => {
          this.getClusterList();
          this.onGetEnabledAddon();
          this.setState({ showAddCluster: false, editClusterName: "" });
        },
        dispatch
      }
    ))));
  }
};
Cluster = __decorateClass([
  connect((store) => {
    return { ...store.clusters };
  })
], Cluster);
export default Cluster;
