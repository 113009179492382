"use strict";
import { Button, Card, Field, Form, Grid, Icon, Input } from "@alifd/next";
import React, { Component, Fragment } from "react";
import { getDexConfig, getLoginType, isAdminConfigured, loginLocal } from "../../api/authentication";
import LogoWhite from "../../assets/kubevela-logo-white.png";
import Logo from "../../assets/kubevela-logo.png";
import { If } from "../../components/If";
import SwitchLanguage from "../../components/SwitchButton/index";
import { Translation } from "../../components/Translation";
import i18n from "../../i18n";
import "./index.less";
import { checkName, checkUserPassword } from "../../utils/common";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import EditPlatFormUserDialog from "../../layout/Header/components/EditPlatFormUserDialog";
export default class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.onGetLoginType = () => {
      getLoginType().then((res) => {
        if (res && res.loginType) {
          this.setState(
            {
              loginType: res.loginType
            },
            () => {
              const { loginType } = this.state;
              if (loginType === "dex") {
                this.ontDexConfig();
              }
            }
          );
        }
      }).catch();
    };
    this.isFirstLogin = () => {
      isAdminConfigured().then((res) => {
        if (res && !res.configured) {
          this.setState({
            firstTimeLogin: true
          });
        }
      });
    };
    this.ontDexConfig = () => {
      getDexConfig().then((res) => {
        if (res) {
          this.setState(
            {
              dexConfig: res
            },
            () => {
              this.onGetDexCode();
            }
          );
        }
      }).catch();
    };
    this.handleSubmit = () => {
      this.field.validate((error, values) => {
        if (error) {
          return;
        }
        this.setState({ loginLoading: true, loginErrorMessage: "" });
        const { username, password } = values;
        const params = {
          username,
          password
        };
        loginLocal(params).then((res) => {
          if (res && res.accessToken) {
            localStorage.setItem("token", res.accessToken);
            localStorage.setItem("refreshToken", res.refreshToken);
            this.props.history.push("/");
          }
        }).catch((err) => {
          let customErrorMessage = "";
          if (err.BusinessCode) {
            customErrorMessage = `${err.Message}(${err.BusinessCode})`;
          } else {
            customErrorMessage = "Please check the network or contact the administrator!";
          }
          this.setState({
            loginErrorMessage: customErrorMessage,
            loginLoading: false
          });
        });
      });
    };
    this.onGetDexCode = () => {
      if (this.state.dexConfig) {
        const { clientID, issuer, redirectURL } = this.state.dexConfig;
        const newRedirectURl = encodeURIComponent(redirectURL);
        const dexClientURL = `${issuer}/auth?client_id=${clientID}&redirect_uri=${newRedirectURl}&response_type=code&scope=openid+profile+email+offline_access&state=velaux`;
        window.location.href = dexClientURL;
      }
    };
    this.onCloseEditAdminUser = () => {
      this.setState({ firstTimeLogin: false });
    };
    this.field = new Field(this);
    this.state = {
      firstTimeLogin: false,
      loginType: "",
      loginErrorMessage: "",
      loginLoading: false
    };
  }
  componentDidMount() {
    this.isFirstLogin();
    this.onGetLoginType();
  }
  render() {
    const FormItem = Form.Item;
    const init = this.field.init;
    const formItemLayout = {
      labelCol: {
        fixedSpan: 6
      },
      wrapperCol: {
        span: 20
      }
    };
    const { loginType, loginErrorMessage, loginLoading, firstTimeLogin } = this.state;
    const { Row, Col } = Grid;
    return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "login-topbar" }, /* @__PURE__ */ React.createElement(Row, { className: "nav-wrapper" }, /* @__PURE__ */ React.createElement(Col, { span: "4", className: "logo" }, /* @__PURE__ */ React.createElement("img", { src: LogoWhite, title: "Make shipping applications more enjoyable." })), /* @__PURE__ */ React.createElement("div", { style: { flex: "1 1 0%" } }), /* @__PURE__ */ React.createElement("div", { className: "right" }, /* @__PURE__ */ React.createElement("div", { className: "vela-item" }, /* @__PURE__ */ React.createElement("a", { title: "KubeVela Documents", href: "https://kubevela.io", target: "_blank", rel: "noopener noreferrer" }, /* @__PURE__ */ React.createElement(AiOutlineQuestionCircle, { size: 18 }))), /* @__PURE__ */ React.createElement("div", { className: "vela-item" }, /* @__PURE__ */ React.createElement(SwitchLanguage, null))))), /* @__PURE__ */ React.createElement("div", { className: "full" }, /* @__PURE__ */ React.createElement(If, { condition: !firstTimeLogin }, /* @__PURE__ */ React.createElement("div", { className: "login-wrapper" }, /* @__PURE__ */ React.createElement(If, { condition: loginType === "dex" }, /* @__PURE__ */ React.createElement("div", null)), /* @__PURE__ */ React.createElement(If, { condition: loginType === "local" }, /* @__PURE__ */ React.createElement("div", { className: "login-card-wrapper" }, /* @__PURE__ */ React.createElement(Card, { contentHeight: "auto" }, /* @__PURE__ */ React.createElement("div", { className: "logo-img-wrapper" }, /* @__PURE__ */ React.createElement("img", { src: Logo })), /* @__PURE__ */ React.createElement("h3", { className: "login-title-description" }, /* @__PURE__ */ React.createElement(Translation, null, "Make shipping applications more enjoyable")), /* @__PURE__ */ React.createElement(
      Form,
      {
        onSubmitCapture: (e) => {
          e.preventDefault();
        },
        onSubmit: this.handleSubmit,
        ...formItemLayout,
        field: this.field
      },
      /* @__PURE__ */ React.createElement(
        FormItem,
        {
          label: /* @__PURE__ */ React.createElement(Translation, { className: "label-title" }, "Username"),
          labelAlign: "top",
          required: true
        },
        /* @__PURE__ */ React.createElement(
          Input,
          {
            name: "username",
            placeholder: i18n.t("Please input the username").toString(),
            ...init("username", {
              rules: [
                {
                  required: true,
                  pattern: checkName,
                  message: /* @__PURE__ */ React.createElement(Translation, null, "Please input the username")
                }
              ]
            })
          }
        )
      ),
      /* @__PURE__ */ React.createElement(
        FormItem,
        {
          label: /* @__PURE__ */ React.createElement(Translation, { className: "label-title" }, "Password"),
          labelAlign: "top",
          required: true
        },
        /* @__PURE__ */ React.createElement(
          Input,
          {
            name: "password",
            htmlType: "password",
            placeholder: i18n.t("Please input the password").toString(),
            ...init("password", {
              rules: [
                {
                  required: true,
                  pattern: checkUserPassword,
                  message: /* @__PURE__ */ React.createElement(Translation, null, "The password should be 8-16 bits and contain at least one number and one letter")
                }
              ]
            })
          }
        )
      ),
      /* @__PURE__ */ React.createElement(Button, { loading: loginLoading, type: "primary", htmlType: "submit", onClick: this.handleSubmit }, /* @__PURE__ */ React.createElement(Translation, null, "Sign in"))
    ), /* @__PURE__ */ React.createElement(If, { condition: loginErrorMessage }, /* @__PURE__ */ React.createElement("div", { className: "logo-error-wrapper" }, /* @__PURE__ */ React.createElement(Icon, { type: "warning1" }), " ", /* @__PURE__ */ React.createElement(Translation, null, loginErrorMessage)))))))), /* @__PURE__ */ React.createElement(If, { condition: firstTimeLogin }, /* @__PURE__ */ React.createElement(EditPlatFormUserDialog, { onClose: this.onCloseEditAdminUser }))));
  }
}
