"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Message, Grid, Select } from "@alifd/next";
import { connect } from "dva";
import querystring from "query-string";
import React, { Fragment } from "react";
import { listApplicationPods, listApplicationPodsDetails } from "../../api/observation";
import { Translation } from "../../components/Translation";
import i18n from "../../i18n";
import LogContainer from "./components/LogContainer";
let ApplicationLog = class extends React.Component {
  constructor(props) {
    super(props);
    this.loadApplicationStatus = async () => {
      const { appName, envName } = this.state;
      if (envName) {
        this.props.dispatch({
          type: "application/getApplicationStatus",
          payload: { appName, envName },
          callback: () => {
            this.loadPodInstance();
          }
        });
      }
    };
    this.loadPodInstance = async () => {
      const { envbinding } = this.props;
      const { appName, envName, activeComponentName } = this.state;
      const envs = envbinding.filter((item) => item.name === envName);
      if (envs.length > 0 && envs[0]) {
        const param = {
          appName: envs[0].appDeployName || appName,
          appNs: envs[0].appDeployNamespace,
          componentName: activeComponentName,
          cluster: "",
          clusterNs: ""
        };
        listApplicationPods(param).then((res) => {
          if (res && res.podList && res.podList.length > 0) {
            this.setState(
              {
                podList: res.podList,
                activePodName: ""
              },
              () => {
                const query = querystring.parse(location.search);
                if (query && query.pod) {
                  this.handlePodNameChange(query.pod);
                } else {
                  this.handlePodNameChange(res.podList[0].metadata.name);
                }
              }
            );
          } else {
            this.setState({
              podList: []
            });
          }
        }).catch(() => {
        });
      }
    };
    this.loadPodDetail = async () => {
      const { pod } = this.state;
      if (pod) {
        listApplicationPodsDetails({
          name: pod?.metadata.name || "",
          namespace: pod?.metadata.namespace || "",
          cluster: pod?.cluster || ""
        }).then((res) => {
          if (res && res.error) {
            Message.warning(res.error);
          } else if (res) {
            const activeContainerName = res.containers?.[0] && res.containers[0]?.name || "";
            this.setState(
              {
                containers: res.containers
              },
              () => {
                this.handleContainerNameChange(activeContainerName);
              }
            );
          }
        }).catch(() => {
        });
      }
    };
    this.handleComponentNameChange = (value) => {
      this.setState({ activeComponentName: value, activePodName: "", activeContainerName: "" }, () => {
        this.loadPodInstance();
      });
    };
    this.handlePodNameChange = (value) => {
      const { podList } = this.state;
      const findPod = (podList || []).filter((item) => item.metadata.name === value);
      this.setState(
        {
          activePodName: value,
          activeContainerName: "",
          pod: findPod[0] || {},
          isActiveContainerNameDisabled: false
        },
        () => {
          this.loadPodDetail();
        }
      );
    };
    this.handleContainerNameChange = (value) => {
      this.setState({ activeContainerName: value });
    };
    this.getComponentNameList = () => {
      const { components } = this.props;
      return components?.map((c) => {
        return { label: c.alias || c.name, value: c.name };
      });
    };
    this.getPodNameList = () => {
      const { podList, activeComponentName } = this.state;
      if (podList && podList.length != 0) {
        const podNameList = [];
        podList.forEach((item) => {
          if (item.metadata && item.metadata.name) {
            if (!activeComponentName || item.component == activeComponentName) {
              podNameList.push(item.metadata.name);
            }
          }
        });
        return podNameList;
      } else {
        return [];
      }
    };
    this.getContainerNameList = () => {
      const { containers } = this.state;
      if (containers && containers.length != 0) {
        const containersNameList = [];
        containers.forEach((item) => {
          if (item) {
            containersNameList.push(item.name);
          }
        });
        return containersNameList;
      } else {
        return [];
      }
    };
    const { params } = props.match;
    this.state = {
      appName: params.appName,
      envName: params.envName,
      podList: [],
      activeComponentName: "",
      activePodName: "",
      activeContainerName: "",
      isActiveContainerNameDisabled: true
    };
  }
  componentDidMount() {
    this.loadApplicationStatus();
  }
  componentWillReceiveProps(nextProps) {
    const { params } = nextProps.match;
    if (params.envName !== this.state.envName || this.props.envbinding != nextProps.envbinding) {
      this.setState({ envName: params.envName }, () => {
        this.loadApplicationStatus();
      });
    }
  }
  render() {
    const { Row, Col } = Grid;
    const { pod, activePodName, activeContainerName, activeComponentName, isActiveContainerNameDisabled } = this.state;
    const podLabel = /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(Translation, null, "Pod"), ":");
    const containerLabel = /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(Translation, null, "Container"), ":");
    return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: "4" }, /* @__PURE__ */ React.createElement(
      Select,
      {
        placeholder: i18n.t("Select Component").toString(),
        label: i18n.t("Component").toString(),
        dataSource: this.getComponentNameList(),
        value: activeComponentName,
        onChange: this.handleComponentNameChange
      }
    )), /* @__PURE__ */ React.createElement(Col, { span: "4", className: "margin-left-10" }, /* @__PURE__ */ React.createElement(
      Select,
      {
        placeholder: i18n.t("Select Pod").toString(),
        label: podLabel,
        dataSource: this.getPodNameList(),
        value: activePodName,
        onChange: this.handlePodNameChange
      }
    )), /* @__PURE__ */ React.createElement(Col, { span: "4", className: "margin-left-10" }, /* @__PURE__ */ React.createElement(
      Select,
      {
        placeholder: i18n.t("Select Container").toString(),
        label: containerLabel,
        dataSource: this.getContainerNameList(),
        value: activeContainerName,
        onChange: this.handleContainerNameChange,
        disabled: isActiveContainerNameDisabled
      }
    ))), /* @__PURE__ */ React.createElement(LogContainer, { pod, activeContainerName }));
  }
};
ApplicationLog = __decorateClass([
  connect((store) => {
    return { ...store.application };
  })
], ApplicationLog);
export default ApplicationLog;
