"use strict";
import { Grid, Form, Input, Field, Button, Message, Select } from "@alifd/next";
import React from "react";
import { createUser, updateUser } from "../../../../api/users";
import DrawerWithFooter from "../../../../components/Drawer";
import { Translation } from "../../../../components/Translation";
import i18n from "../../../../i18n";
import { checkUserPassword, checkName } from "../../../../utils/common";
import { locale } from "../../../../utils/locale";
import { getSelectLabel } from "../../../../utils/utils";
class CreateUser extends React.Component {
  constructor(props) {
    super(props);
    this.onClose = () => {
      this.props.onClose();
    };
    this.onCreate = () => {
      this.field.validate((error, values) => {
        if (error) {
          return;
        }
        const { isEditUser, editUser } = this.props;
        const { name, alias, email, password, roles } = values;
        const params = {
          name,
          alias,
          email,
          password,
          roles
        };
        if (isEditUser && editUser.email) {
          delete params.email;
        }
        if (isEditUser) {
          updateUser(params).then((res) => {
            if (res) {
              Message.success(/* @__PURE__ */ React.createElement(Translation, null, "User updated successfully"));
              this.props.onCreate();
            }
          });
        } else {
          createUser(params).then((res) => {
            if (res) {
              Message.success(/* @__PURE__ */ React.createElement(Translation, null, "User created successfully"));
              this.props.onCreate();
            }
          });
        }
      });
    };
    this.showTitle = () => {
      const { isEditUser } = this.props;
      if (isEditUser) {
        return /* @__PURE__ */ React.createElement(Translation, null, "Edit User");
      } else {
        return /* @__PURE__ */ React.createElement(Translation, null, "New User");
      }
    };
    this.showClickButtons = () => {
      const { isEditUser } = this.props;
      const { loading } = this.state;
      let createEle;
      if (isEditUser) {
        createEle = /* @__PURE__ */ React.createElement(Button, { type: "primary", onClick: this.onCreate, loading }, /* @__PURE__ */ React.createElement(Translation, null, "Update"));
      } else {
        createEle = /* @__PURE__ */ React.createElement(Button, { type: "primary", onClick: this.onCreate, loading }, /* @__PURE__ */ React.createElement(Translation, null, "Create"));
      }
      return [
        /* @__PURE__ */ React.createElement(Button, { type: "secondary", onClick: this.onClose, style: { marginRight: "16px" } }, /* @__PURE__ */ React.createElement(Translation, null, "Cancel")),
        createEle
      ];
    };
    this.field = new Field(this);
    this.state = {
      loading: false
    };
  }
  componentDidMount() {
    const { isEditUser, editUser } = this.props;
    if (isEditUser && editUser) {
      const { alias, createTime, email, lastLoginTime, password, name, roles } = editUser;
      const rolesName = roles?.map((item) => {
        return item.name;
      });
      this.field.setValues({
        name,
        alias,
        createTime,
        password,
        email,
        lastLoginTime,
        roles: rolesName
      });
    }
  }
  render() {
    const init = this.field.init;
    const { Row, Col } = Grid;
    const FormItem = Form.Item;
    const { isEditUser, editUser, rolesList } = this.props;
    const formItemLayout = {
      labelCol: {
        fixedSpan: 6
      },
      wrapperCol: {
        span: 20
      }
    };
    const rolesListSelect = getSelectLabel(rolesList);
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
      DrawerWithFooter,
      {
        title: this.showTitle(),
        placement: "right",
        width: 800,
        onClose: this.onClose,
        extButtons: this.showClickButtons()
      },
      /* @__PURE__ */ React.createElement(Form, { ...formItemLayout, field: this.field }, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Name"), labelTextAlign: "left", required: true }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "name",
          placeholder: i18n.t("Please enter").toString(),
          maxLength: 32,
          disabled: isEditUser ? true : false,
          ...init("name", {
            rules: [
              {
                required: true,
                pattern: checkName,
                message: /* @__PURE__ */ React.createElement(Translation, null, "Please enter a valid name")
              }
            ]
          })
        }
      ))), /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Alias") }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "alias",
          placeholder: i18n.t("Please input the alias").toString(),
          ...init("alias", {
            rules: [
              {
                minLength: 2,
                maxLength: 64,
                message: "Enter a string of 2 to 64 characters."
              }
            ]
          })
        }
      )))), /* @__PURE__ */ React.createElement(Row, null, !isEditUser && /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Password"), required: true }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "password",
          htmlType: "password",
          autoComplete: "new-password",
          placeholder: i18n.t("Please input the password").toString(),
          ...init("password", {
            rules: [
              {
                required: true,
                pattern: checkUserPassword,
                message: /* @__PURE__ */ React.createElement(Translation, null, "Password should be 8-16 bits and contain at least one number and one letter")
              }
            ]
          })
        }
      ))), /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Email"), required: true }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "email",
          placeholder: i18n.t("Please input a email").toString(),
          autoComplete: "off",
          disabled: isEditUser && editUser.email ? true : false,
          ...init("email", {
            rules: [
              {
                required: true,
                format: "email",
                message: /* @__PURE__ */ React.createElement(Translation, null, "Please input a valid email")
              }
            ]
          })
        }
      )))), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(
        FormItem,
        {
          help: i18n.t("Application developers should be assigned project roles instead of platform roles.").toString(),
          label: /* @__PURE__ */ React.createElement(Translation, null, "Platform Roles"),
          labelTextAlign: "left"
        },
        /* @__PURE__ */ React.createElement(
          Select,
          {
            ...init(`roles`, {
              rules: [
                {
                  required: false,
                  message: "Please select roles"
                }
              ]
            }),
            locale: locale().Select,
            mode: "multiple",
            dataSource: rolesListSelect
          }
        )
      ))))
    ));
  }
}
export default CreateUser;
