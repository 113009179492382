"use strict";
import React from "react";
import { HiOutlineExternalLink } from "react-icons/hi";
import "./index.less";
import { Box, Button, Card, Grid, Tag } from "@alifd/next";
import i18n from "i18next";
import { If } from "../../../../components/If";
import { renderIcon } from "@velaux/ui/src/utils/icon";
class PluginCard extends React.Component {
  constructor(props) {
    super(props);
    this.setValid = (valid) => {
      this.setState((preState) => {
        return { ...preState, iconValid: valid };
      });
    };
    this.handleGoToPage = (id) => {
      this.props.history?.push(`/plugins/${id}`);
    };
    this.handleInstall = (id, url) => {
      this.props.onInstall(id, url);
    };
    this.handleGoToPluginConfig = (id) => {
      if (this.props.installed) {
        this.props.history?.push(`/manage/plugins/${id}/config`);
      }
    };
    this.state = {
      iconValid: false
    };
  }
  componentDidMount() {
    if (this.props.icon) {
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.icon !== this.props.icon && this.props.icon) {
    }
  }
  render() {
    const { Row, Col } = Grid;
    const {
      id,
      icon,
      tags,
      description,
      sourceAddon,
      enabled,
      installed,
      url
    } = this.props;
    function getTags() {
      let ts = [...tags, ...sourceAddon?.tags ?? []];
      if (sourceAddon) {
        ts.unshift(`from: ${sourceAddon.name}`);
      }
      return ts;
    }
    return /* @__PURE__ */ React.createElement("div", { className: "plugin-card" }, /* @__PURE__ */ React.createElement("a", { onClick: () => this.handleGoToPluginConfig(id) }, /* @__PURE__ */ React.createElement(Card, { style: { border: "none" }, contentHeight: 180 }, /* @__PURE__ */ React.createElement(Box, { direction: "column" }, /* @__PURE__ */ React.createElement(Box, { align: "flex-start", direction: "row" }, /* @__PURE__ */ React.createElement(Box, { align: "center", spacing: 8, direction: "row" }, /* @__PURE__ */ React.createElement("div", { style: { display: "flex", alignItems: "center" } }, renderIcon(id, icon)), enabled && /* @__PURE__ */ React.createElement("a", { onClick: (e) => {
      e.stopPropagation();
      this.handleGoToPluginConfig(id);
    } }, /* @__PURE__ */ React.createElement("span", { style: { fontSize: "20px" } }, id)), !enabled && /* @__PURE__ */ React.createElement("div", { className: "font-color-keep", style: { fontSize: "20px" } }, id)), /* @__PURE__ */ React.createElement("div", { style: { marginLeft: "auto", marginTop: "4px" } }, enabled && /* @__PURE__ */ React.createElement("a", { onClick: (e) => {
      e.stopPropagation();
      this.handleGoToPage(id);
    } }, /* @__PURE__ */ React.createElement("span", { style: { fontSize: "16px" } }, /* @__PURE__ */ React.createElement(HiOutlineExternalLink, null))))), /* @__PURE__ */ React.createElement(
      Box,
      {
        className: "plugin-card-content",
        direction: "column",
        justify: "space-between",
        style: { height: "100%" }
      },
      /* @__PURE__ */ React.createElement(Box, { id: "desc", className: "plugin-desc" }, /* @__PURE__ */ React.createElement("h4", { className: "font-size-14 font-color-keep" }, description ?? (sourceAddon?.description ?? "No descriptions")))
    ), /* @__PURE__ */ React.createElement(Box, { className: "plugin-card-footer" }, /* @__PURE__ */ React.createElement(Row, { id: "tags", gutter: 1 }, /* @__PURE__ */ React.createElement(Col, { span: 18 }, /* @__PURE__ */ React.createElement(Box, { direction: "row", wrap: true, spacing: [4, 4] }, getTags().map(
      (t) => {
        return /* @__PURE__ */ React.createElement("div", { className: "hover-none" }, /* @__PURE__ */ React.createElement(Tag, { size: "small", className: "tag", type: "normal" }, t));
      }
    ))), /* @__PURE__ */ React.createElement(Col, { span: 6 }, url && url !== "" && !installed && /* @__PURE__ */ React.createElement(Box, { align: "flex-end" }, /* @__PURE__ */ React.createElement(Button, { className: "hover-auto", type: "primary", onClick: (e) => {
      e.stopPropagation();
      this.handleInstall(id, url);
    } }, i18n.t("Install"))), installed && /* @__PURE__ */ React.createElement(Box, { direction: "row", justify: "flex-end", align: "center", style: { marginLeft: "auto" } }, /* @__PURE__ */ React.createElement(If, { condition: enabled }, /* @__PURE__ */ React.createElement("span", { className: "circle circle-success" }), /* @__PURE__ */ React.createElement("span", { className: "font-color-info" }, "Enabled")), /* @__PURE__ */ React.createElement(If, { condition: !enabled }, /* @__PURE__ */ React.createElement("span", { className: "circle circle-success" }), /* @__PURE__ */ React.createElement("span", { className: "font-color-info" }, "Installed"))))))))));
  }
}
PluginCard.defaultProps = {
  tags: []
};
export default PluginCard;
