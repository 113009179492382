"use strict";
import { Grid, Select, Input, Button } from "@alifd/next";
import React from "react";
import "./index.less";
import { If } from "../../../../components/If";
import i18n from "../../../../i18n";
import { locale } from "../../../../utils/locale";
import { AiOutlineSearch } from "react-icons/ai";
import { HiOutlineRefresh } from "react-icons/hi";
const { Row, Col } = Grid;
class SelectSearch extends React.Component {
  constructor(props) {
    super(props);
    this.handleClickSearch = () => {
      this.getPipelines();
    };
    this.getPipelines = async () => {
      const { projectValue, inputValue } = this.state;
      const params = {
        project: projectValue,
        query: inputValue
      };
      this.props.getPipelines(params);
    };
    this.state = {
      projectValue: "",
      inputValue: ""
    };
    this.onChangeProject = this.onChangeProject.bind(this);
    this.handleChangName = this.handleChangName.bind(this);
  }
  onChangeProject(e) {
    this.setState(
      {
        projectValue: e
      },
      () => {
        this.getPipelines();
      }
    );
  }
  handleChangName(e) {
    this.setState({
      inputValue: e
    });
  }
  render() {
    const { projects, disableProject } = this.props;
    const { projectValue, inputValue } = this.state;
    const projectPlaceholder = i18n.t("Search by Project").toString();
    const appPlaceholder = i18n.t("Search by Name and Description etc").toString();
    const projectSource = projects?.map((item) => {
      return {
        label: item.alias || item.name,
        value: item.name
      };
    });
    return /* @__PURE__ */ React.createElement(Row, { className: "app-select-wrapper border-radius-8", wrap: true }, /* @__PURE__ */ React.createElement(If, { condition: !disableProject }, /* @__PURE__ */ React.createElement(Col, { xl: 6, m: 8, s: 12, xxs: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(
      Select,
      {
        locale: locale().Select,
        mode: "single",
        size: "large",
        onChange: this.onChangeProject,
        dataSource: projectSource,
        placeholder: projectPlaceholder,
        className: "item",
        hasClear: true,
        value: projectValue
      }
    ))), /* @__PURE__ */ React.createElement(Col, { xl: 6, m: 8, s: 12, xxs: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(
      Input,
      {
        innerAfter: /* @__PURE__ */ React.createElement(AiOutlineSearch, { onClick: this.handleClickSearch, style: { margin: 4 } }),
        hasClear: true,
        size: "large",
        placeholder: appPlaceholder,
        onChange: this.handleChangName,
        onPressEnter: this.handleClickSearch,
        value: inputValue,
        className: "item"
      }
    )), /* @__PURE__ */ React.createElement(Col, { xl: 6, className: "flexboth" }, /* @__PURE__ */ React.createElement("div", { className: "padding16" }, /* @__PURE__ */ React.createElement(Button, { type: "secondary", onClick: () => this.getPipelines() }, /* @__PURE__ */ React.createElement(HiOutlineRefresh, null)))));
  }
}
export default SelectSearch;
