"use strict";
import { connect } from "dva";
import { Link } from "dva/router";
import React, { useEffect, useState } from "react";
import { locationService } from "../../services/LocationService";
import { Translation } from "../../components/Translation";
import { menuService } from "../../services/MenuService";
import "./index.less";
import { checkPermission } from "../../types";
import { getConfigs } from "../../api/config";
import { MenuTypes } from "@velaux/data";
import { MdOutlineMonitorHeart } from "react-icons/md";
import { If } from "../../components/If";
const LeftMenuModule = (props) => {
  const path = locationService.getPathName();
  const [menus, setMenus] = useState();
  const [grafanaConfigs, setGrafanaConfigs] = useState();
  useEffect(() => {
    if (checkPermission({ resource: "config", action: "list" }, "", props.userInfo)) {
      getConfigs("grafana").then((res) => {
        if (res) {
          setGrafanaConfigs(res.configs || []);
        }
      });
    }
  }, [props.userInfo]);
  useEffect(() => {
    menuService.resetPluginMenus();
    menuService.loadPluginMenus().then(() => {
      const workspace = menuService.loadCurrentWorkspace();
      const menus2 = workspace && props.userInfo ? menuService.loadMenus(workspace, props.userInfo) : [];
      if (grafanaConfigs && workspace?.name === "extension") {
        const grafanaLeftMenu = { catalog: "Grafana", menus: [] };
        grafanaConfigs.map((g) => {
          if (g.properties && g.properties["endpoint"]) {
            grafanaLeftMenu.menus.push({
              name: g.name,
              workspace: "extension",
              label: g.alias || g.name,
              to: "",
              href: g.properties["endpoint"],
              relatedRoute: [],
              type: MenuTypes.Workspace,
              icon: /* @__PURE__ */ React.createElement(MdOutlineMonitorHeart, null)
            });
          }
        });
        menus2.push(grafanaLeftMenu);
      }
      setMenus(menus2);
    });
  }, [props.userInfo, path, grafanaConfigs, props.pluginList]);
  if (!props.userInfo) {
    return /* @__PURE__ */ React.createElement("div", null);
  }
  let fallBackCatalog = 0;
  const childrenSlider = menus?.map((item) => {
    const ele = [];
    if (item.menus && item.menus.length > 0) {
      item.menus.map((childrenItem) => {
        const item2 = /* @__PURE__ */ React.createElement(
          "div",
          {
            style: {
              display: "flex",
              alignItems: "center"
            }
          },
          childrenItem.icon,
          /* @__PURE__ */ React.createElement("span", { className: "menu-item-text" }, /* @__PURE__ */ React.createElement(Translation, null, childrenItem.label))
        );
        const childrenArr = /* @__PURE__ */ React.createElement("li", { className: "nav-item", key: childrenItem.name }, /* @__PURE__ */ React.createElement(If, { condition: childrenItem.href }, /* @__PURE__ */ React.createElement(
          "a",
          {
            rel: "noopener noreferrer",
            target: "_blank",
            className: childrenItem.active ? "menu-item-active" : "menu-item",
            href: childrenItem.href
          },
          item2
        )), /* @__PURE__ */ React.createElement(If, { condition: childrenItem.to && !childrenItem.href }, /* @__PURE__ */ React.createElement(Link, { to: childrenItem.to, className: childrenItem.active ? "menu-item-active" : "menu-item" }, item2)));
        ele.push(childrenArr);
      });
    }
    if (ele.length > 0) {
      return /* @__PURE__ */ React.createElement("li", { className: "nav-container", key: item.catalog ? item.catalog : fallBackCatalog++ }, item.catalog && /* @__PURE__ */ React.createElement("div", { className: "main-nav padding-left-32" }, /* @__PURE__ */ React.createElement(Translation, null, item.catalog)), /* @__PURE__ */ React.createElement("ul", { className: "sub-wrapper" }, ele));
    }
    return null;
  });
  return /* @__PURE__ */ React.createElement("div", { style: { position: "relative", height: "100%" } }, /* @__PURE__ */ React.createElement("div", { className: "slide-wrapper" }, /* @__PURE__ */ React.createElement("ul", { className: "ul-wrapper" }, childrenSlider)));
};
export default connect(
  (store) => {
    return { ...store.user, ...store.plugins };
  },
  void 0,
  void 0,
  { pure: false }
)(LeftMenuModule);
