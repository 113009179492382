"use strict";
import * as React from "react";
import { captureException } from "@sentry/browser";
export class ErrorBoundary extends React.PureComponent {
  constructor() {
    super(...arguments);
    this.state = {
      error: null,
      errorInfo: null
    };
  }
  componentDidCatch(error, errorInfo) {
    captureException(error, { contexts: { react: { componentStack: errorInfo.componentStack } } });
    this.setState({ error, errorInfo });
    if (this.props.onError) {
      this.props.onError(error);
    }
  }
  componentDidUpdate(prevProps) {
    const { dependencies, onRecover } = this.props;
    if (this.state.error) {
      if (dependencies && prevProps.dependencies) {
        for (let i = 0; i < dependencies.length; i++) {
          if (dependencies[i] !== prevProps.dependencies[i]) {
            this.setState({ error: null, errorInfo: null });
            if (onRecover) {
              onRecover();
            }
            break;
          }
        }
      }
    }
  }
  render() {
    const { children } = this.props;
    const { error, errorInfo } = this.state;
    return children({
      error,
      errorInfo
    });
  }
}
