"use strict";
import { Grid, Select } from "@alifd/next";
import React from "react";
import i18n from "../../../../i18n";
import { locale } from "../../../../utils/locale";
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.transEnvBind = () => {
    };
    this.state = {
      envValue: "",
      statusValue: ""
    };
    this.handleChangeEnv = this.handleChangeEnv.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
  }
  handleChangeEnv(value) {
    this.setState({ envValue: value });
    this.props.updateQuery({ isChangeEnv: true, value });
  }
  handleChangeStatus(value) {
    this.setState({ statusValue: value });
    this.props.updateQuery({ isChangeStatus: true, value });
  }
  render() {
    const { Row, Col } = Grid;
    const envPlaceholder = i18n.t("Select Environment").toString();
    const statusPlaceholder = i18n.t("Select Status").toString();
    const { envValue, statusValue } = this.state;
    const { statusList, envBinding } = this.props;
    const envBinds = (envBinding || []).map((item) => ({
      label: item.alias || item.name,
      value: item.name
    }));
    return /* @__PURE__ */ React.createElement(Row, { className: "border-radius-8" }, /* @__PURE__ */ React.createElement(Col, { span: "6", style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(
      Select,
      {
        locale: locale().Select,
        mode: "single",
        onChange: this.handleChangeEnv,
        dataSource: envBinds,
        placeholder: envPlaceholder,
        className: "item",
        hasClear: true,
        value: envValue
      }
    )), /* @__PURE__ */ React.createElement(Col, { span: "6", style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(
      Select,
      {
        locale: locale().Select,
        mode: "single",
        onChange: this.handleChangeStatus,
        dataSource: statusList,
        placeholder: statusPlaceholder,
        className: "item",
        hasClear: true,
        value: statusValue
      }
    )));
  }
}
export default Header;
