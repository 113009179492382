"use strict";
import * as monaco from "monaco-editor";
import React from "react";
import defineTheme from "../DefinitionCode/theme";
class DefinitionCode extends React.Component {
  componentDidMount() {
    const {
      containerId,
      value = "",
      language,
      readOnly,
      onChange,
      onBlurEditor,
      fileUrl = `//b.txt`,
      theme = "default"
    } = this.props;
    const container = document.getElementById(containerId);
    if (container) {
      container.innerHTML = "";
    }
    if (defineTheme) {
      monaco.editor.defineTheme("default", defineTheme);
      monaco.editor.setTheme("default");
    }
    const modelUri = monaco.Uri.parse(`${containerId}:${fileUrl}`);
    let model = monaco.editor.getModel(modelUri);
    if (!model) {
      model = monaco.editor.createModel(value, language, modelUri);
      model.setValue(value);
    }
    this.editor = monaco.editor.create(container, {
      value,
      language,
      readOnly,
      minimap: { enabled: false },
      automaticLayout: true,
      model,
      theme
    });
    const textModel = this.editor.getModel();
    if (textModel) {
      if (onChange) {
        this.editor.onDidChangeModelContent(() => onChange(textModel.getValue()));
      }
      if (onBlurEditor) {
        this.editor.onDidBlurEditorText(() => {
          onBlurEditor(textModel.getValue());
        });
      }
      monaco.editor.setModelLanguage(textModel, language);
    }
  }
  componentWillReceiveProps(nextProps) {
    const { language, value, runtime } = nextProps;
    if (language !== this.props.language || runtime !== this.props.runtime || value !== this.props.value) {
      if (this.editor) {
        this.editor.getModel()?.setValue(value || "");
      }
    }
  }
  componentWillUnmount() {
    if (this.editor) {
      this.editor.dispose();
    }
  }
  shouldComponentUpdate() {
    return false;
  }
  render() {
    return null;
  }
}
export default DefinitionCode;
