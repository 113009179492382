"use strict";
import * as React from "react";
const SvgNotification = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 512 512", xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement(
  "path",
  {
    style: {
      fill: "#ff8a1e"
    },
    d: "M174.74 430.93c0 44.878 36.382 81.07 81.26 81.07l22.261-103.331L174.74 430.93z"
  }
), /* @__PURE__ */ React.createElement(
  "path",
  {
    style: {
      fill: "#ff562b"
    },
    d: "M256 512c44.878 0 81.26-36.192 81.26-81.07L256 408.669V512z"
  }
), /* @__PURE__ */ React.createElement(
  "path",
  {
    style: {
      fill: "#ffa418"
    },
    d: "M34.846 364.142v66.788H256l22.261-89.049z"
  }
), /* @__PURE__ */ React.createElement(
  "path",
  {
    style: {
      fill: "#ffbe11"
    },
    d: "M256 0C176.159 0 111.837 59.674 99.965 136.479c-5.683 36.763-25.84 146.535-25.84 146.535l204.137 22.261L256 0z"
  }
), /* @__PURE__ */ React.createElement(
  "path",
  {
    style: {
      fill: "#ff8a1e"
    },
    d: "M437.877 283.016s-20.158-109.774-25.842-146.537C400.162 59.674 335.841 0 256 0v305.277l181.877-22.261zM256 341.881v89.049h221.154v-66.788z"
  }
), /* @__PURE__ */ React.createElement(
  "path",
  {
    style: {
      fill: "#ffd460"
    },
    d: "M256 283.016H74.123c-23.905 18.925-39.277 48.417-39.277 81.126H256l22.261-40.563L256 283.016z"
  }
), /* @__PURE__ */ React.createElement(
  "path",
  {
    style: {
      fill: "#ffa418"
    },
    d: "M437.877 283.016H256v81.126h221.154c0-32.709-15.373-62.201-39.277-81.126z"
  }
));
export default SvgNotification;
