"use strict";
import { get } from "./request";
export function listApplicationPods(params) {
  let velaQLParams = `appNs=${params.appNs}, appName=${params.appName}`;
  if (params.cluster) {
    velaQLParams = `cluster=${params.cluster}, clusterNs=${params.clusterNs}, ` + velaQLParams;
  }
  if (params.componentName) {
    velaQLParams = `name=${params.componentName}, ` + velaQLParams;
  }
  const urlParams = `component-pod-view{${velaQLParams}}.status`;
  return get("/api/v1/query", {
    params: {
      velaql: urlParams
    }
  });
}
export function listApplicationPodsDetails(params) {
  const urlParams = `pod-view{namespace=${params.namespace},name=${params.name},cluster=${params.cluster}}.status`;
  return get("/api/v1/query", {
    params: {
      velaql: urlParams
    }
  });
}
export function listNamespaces(params) {
  const urlParams = `resource-view{type=ns,cluster=${params.cluster}}.status`;
  return get("/api/v1/query", {
    params: {
      velaql: urlParams
    }
  });
}
export function listCloudResources(params) {
  const urlParams = `cloud-resource-view{appNs=${params.appNs},appName=${params.appName}}`;
  return get("/api/v1/query", {
    params: {
      velaql: urlParams
    }
  });
}
export function listCloudResourceSecrets(params) {
  let urlParams = `cloud-resource-secret-view{appNs=${params.appNs}}`;
  if (params.appName) {
    urlParams = `cloud-resource-secret-view{appNs=${params.appNs},appName=${params.appName}}`;
  }
  return get("/api/v1/query", {
    params: {
      velaql: urlParams
    }
  });
}
export function listApplicationService(params) {
  let urlParams = `service-view{appNs=${params.appNs}, appName=${params.appName}}`;
  if (params.cluster) {
    urlParams = `service-view{appNs=${params.appNs}, appName=${params.appName}, cluster=${params.cluster},clusterNs=${params.clusterNs}}`;
  }
  return get("/api/v1/query", {
    params: {
      velaql: urlParams
    }
  });
}
export function listContainerLog(params) {
  const urlParams = `collect-logs{cluster=${params.cluster}, namespace=${params.namespace}, pod=${params.pod}, container=${params.container}, previous=${params.previous}, timestamps=${params.timestamps}, tailLines=${params.tailLines}}`;
  return get("/api/v1/query", {
    params: {
      velaql: urlParams
    }
  });
}
export function listApplicationServiceEndpoints(params) {
  let velaQLParams = `appNs=${params.appNs}, appName=${params.appName}`;
  if (params.cluster) {
    velaQLParams = `cluster=${params.cluster}, clusterNs=${params.clusterNs}, ` + velaQLParams;
  }
  if (params.componentName) {
    velaQLParams = `name=${params.componentName}, ` + velaQLParams;
  }
  const urlParams = `service-endpoints-view{${velaQLParams}}.status`;
  return get("/api/v1/query", {
    params: {
      velaql: urlParams
    }
  });
}
export function listApplicationServiceAppliedResources(params) {
  let velaQLParams = `appNs=${params.appNs}, appName=${params.appName}`;
  if (params.cluster) {
    velaQLParams = `cluster=${params.cluster}, clusterNs=${params.clusterNs}, ` + velaQLParams;
  }
  if (params.componentName) {
    velaQLParams = `name=${params.componentName}, ` + velaQLParams;
  }
  const urlParams = `service-applied-resources-view{${velaQLParams}}.status`;
  return get("/api/v1/query", {
    params: {
      velaql: urlParams
    }
  });
}
export function listApplicationResourceTree(params) {
  let velaQLParams = `appNs=${params.appNs}, appName=${params.appName}`;
  if (params.cluster) {
    velaQLParams = `cluster=${params.cluster}, clusterNs=${params.clusterNs}, ` + velaQLParams;
  }
  if (params.componentName) {
    velaQLParams = `name=${params.componentName}, ` + velaQLParams;
  }
  const urlParams = `application-resource-tree-view{${velaQLParams}}.status`;
  return get("/api/v1/query", {
    params: {
      velaql: urlParams
    }
  });
}
export function detailResource(params) {
  let velaQLParams = `name=${params.name}, kind=${params.kind}, apiVersion=${params.apiVersion}`;
  if (params.cluster) {
    velaQLParams = `cluster=${params.cluster}, ` + velaQLParams;
  }
  if (params.namespace) {
    velaQLParams = `namespace=${params.namespace}, ` + velaQLParams;
  }
  const urlParams = `application-resource-detail-view{${velaQLParams}}.status`;
  return get("/api/v1/query", {
    params: {
      velaql: urlParams
    }
  });
}
