"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Dialog, Field, Form, Grid, Message, Select, Button } from "@alifd/next";
import { connect } from "dva";
import React, { Component } from "react";
import { createApplicationEnvbinding, updateApplicationEnvbinding } from "../../../../api/application";
import { getEnvs } from "../../../../api/env";
import { If } from "../../../../components/If";
import { Translation } from "../../../../components/Translation";
import EnvDialog from "../../../../pages/EnvPage/components/EnvDialog";
import { showAlias } from "../../../../utils/common";
import { locale } from "../../../../utils/locale";
let EnvBindPlanDialog = class extends Component {
  constructor(props) {
    super(props);
    this.loadEnvs = async (callback) => {
      const { applicationDetail, envbinding } = this.props;
      if (applicationDetail && applicationDetail.project?.name) {
        getEnvs({ project: applicationDetail.project?.name, page: 0 }).then((re) => {
          const existEnvs = envbinding?.map((eb) => {
            return eb.name;
          }) || [];
          const allEnvs = re.envs || [];
          const canAdd = allEnvs.filter((env) => !existEnvs.includes(env.name));
          this.setState({ envs: canAdd });
          const envOption = canAdd?.map((env) => {
            return {
              label: showAlias(env.name, env.alias),
              value: env.name
            };
          });
          if (callback) {
            callback(envOption[0]?.value || "");
          }
        });
      }
    };
    this.onSubmit = () => {
      const { applicationDetail } = this.props;
      if (!applicationDetail) {
        return;
      }
      const { isEdit } = this.state;
      this.field.validate((error, values) => {
        if (error) {
          return;
        }
        this.setState({ loading: true });
        const { name } = values;
        const params = {
          appName: applicationDetail && applicationDetail.name,
          name
        };
        if (isEdit) {
          this.onUpdateApplicationEnv(params);
          return;
        }
        this.onCreateApplicationEnv(params);
      });
    };
    this.getTargets = async () => {
      this.props.dispatch?.({
        type: "target/listTargets",
        payload: {}
      });
    };
    this.onCloseEnvDialog = () => {
      this.setState({
        visibleEnvDialog: false
      });
    };
    this.onOKEnvDialog = () => {
      this.setState(
        {
          visibleEnvDialog: false
        },
        () => {
          this.loadEnvs(this.setEnvValue);
        }
      );
    };
    this.changeEnvDialog = (visible) => {
      this.setState({
        visibleEnvDialog: visible
      });
    };
    this.setEnvValue = (name) => {
      this.field.setValues({ name });
    };
    this.field = new Field(this);
    this.state = {
      loading: false,
      isEdit: false,
      visibleEnvDialog: false
    };
  }
  componentDidMount() {
    this.loadEnvs();
    this.getTargets();
  }
  onCreateApplicationEnv(params) {
    createApplicationEnvbinding(params).then((res) => {
      if (res) {
        Message.success(/* @__PURE__ */ React.createElement(Translation, null, "Environment bound successfully"));
        this.props.onOK();
      }
    }).finally(() => {
      this.setState({ loading: false });
    });
  }
  onUpdateApplicationEnv(params) {
    updateApplicationEnvbinding(params).then((res) => {
      if (res) {
        Message.success(/* @__PURE__ */ React.createElement(Translation, null, "Environment bound successfully"));
        this.props.onOK();
      }
    }).finally(() => {
      this.setState({ loading: false });
    });
  }
  render() {
    const { loading, isEdit, envs, visibleEnvDialog } = this.state;
    const { userInfo } = this.props;
    const { Row, Col } = Grid;
    const FormItem = Form.Item;
    const init = this.field.init;
    const formItemLayout = {
      labelCol: {
        fixedSpan: 6
      },
      wrapperCol: {
        span: 20
      }
    };
    const envOption = envs?.map((env) => {
      return {
        label: env.alias ? `${env.alias}(${env.name})` : env.name,
        value: env.name
      };
    });
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
      Dialog,
      {
        v2: true,
        visible: true,
        locale: locale().Dialog,
        style: { width: "600px" },
        overflowScroll: true,
        footerActions: ["ok"],
        onClose: this.props.onClose,
        footer: /* @__PURE__ */ React.createElement(Button, { type: "primary", onClick: this.onSubmit, loading }, !isEdit ? /* @__PURE__ */ React.createElement(Translation, null, "Submit") : /* @__PURE__ */ React.createElement(Translation, null, "Save")),
        title: /* @__PURE__ */ React.createElement(Translation, null, "Bind Environment")
      },
      /* @__PURE__ */ React.createElement(Form, { ...formItemLayout, field: this.field }, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(
        FormItem,
        {
          label: /* @__PURE__ */ React.createElement(Translation, { className: "font-size-14 font-weight-bold" }, "Environment"),
          help: /* @__PURE__ */ React.createElement(
            "a",
            {
              onClick: () => {
                this.changeEnvDialog(true);
              }
            },
            /* @__PURE__ */ React.createElement(Translation, null, "New Environment")
          ),
          required: true
        },
        /* @__PURE__ */ React.createElement(
          Select,
          {
            name: "name",
            locale: locale().Select,
            disabled: isEdit ? true : false,
            dataSource: envOption,
            maxLength: 32,
            ...init("name", {
              rules: [
                {
                  required: true,
                  message: "Please select an env"
                }
              ]
            })
          }
        )
      ))))
    ), /* @__PURE__ */ React.createElement(If, { condition: visibleEnvDialog }, /* @__PURE__ */ React.createElement(
      EnvDialog,
      {
        visible: visibleEnvDialog,
        projects: userInfo?.projects || [],
        userInfo,
        project: this.props.applicationDetail?.project?.name,
        isEdit: false,
        onClose: this.onCloseEnvDialog,
        onOK: this.onOKEnvDialog
      }
    )));
  }
};
EnvBindPlanDialog = __decorateClass([
  connect((store) => {
    return { ...store.application, ...store.target, ...store.user };
  })
], EnvBindPlanDialog);
export default EnvBindPlanDialog;
