"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Grid } from "@alifd/next";
import { connect } from "dva";
import React, { Component } from "react";
import Empty from "../../components/Empty";
import { ListTitle } from "../../components/ListTitle";
import { getMatchParamObj } from "../../utils/utils";
import Menu from "./components/Menu";
import "./index.less";
import { If } from "../../components/If";
let ConfigsLayout = class extends Component {
  constructor(props) {
    super(props);
    this.listTemplates = () => {
      this.props.dispatch({
        type: "configs/loadTemplates",
        payload: {}
      });
    };
    this.getTemplateName = () => {
      return getMatchParamObj(this.props.match, "templateName");
    };
    this.getConfigsFirstMenuName = (data) => {
      return data && data[0] && data[0].name || "";
    };
    this.initMenuRoute = (pathname) => {
      if (pathname) {
        const link = `/configs/${pathname}`;
        this.props.history.push(link);
      }
    };
    this.state = {
      activeName: this.getTemplateName()
    };
  }
  componentDidMount() {
    this.listTemplates();
  }
  componentWillReceiveProps(nextProps) {
    const { configTemplates } = nextProps;
    if (nextProps.location.pathname != this.props.location.pathname) {
      const nextPropsParams = nextProps.match.params || {};
      this.setState({
        activeName: nextPropsParams.templateName
      });
    }
    if (nextProps.match.path === "/configs" && configTemplates.length != 0) {
      const pathname = this.getConfigsFirstMenuName(configTemplates);
      this.initMenuRoute(pathname);
    }
  }
  render() {
    const { Row, Col } = Grid;
    const { configTemplates } = this.props;
    const { activeName } = this.state;
    return /* @__PURE__ */ React.createElement("div", { className: "configs-wrapper" }, /* @__PURE__ */ React.createElement(
      ListTitle,
      {
        title: "Configs",
        subTitle: "Offering templated and extensible configuration management capabilities."
      }
    ), /* @__PURE__ */ React.createElement(If, { condition: !activeName }, /* @__PURE__ */ React.createElement(Empty, { style: { marginTop: "40px" } })), /* @__PURE__ */ React.createElement(If, { condition: activeName }, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: "5" }, /* @__PURE__ */ React.createElement(Menu, { activeName, menuData: configTemplates })), /* @__PURE__ */ React.createElement(Col, { span: "19" }, this.props.children))));
  }
};
ConfigsLayout = __decorateClass([
  connect((store) => {
    return { ...store.configs };
  })
], ConfigsLayout);
export default ConfigsLayout;
