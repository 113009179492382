"use strict";
import { Grid, Form, Input, Field, Button, Message } from "@alifd/next";
import React from "react";
import { updateUser } from "../../../../api/users";
import DrawerWithFooter from "../../../../components/Drawer";
import { Translation } from "../../../../components/Translation";
import i18n from "../../../../i18n";
import { checkUserPassword } from "../../../../utils/common";
class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.onClose = () => {
      this.props.onClose();
    };
    this.onCreate = () => {
      this.field.validate((error, values) => {
        if (error) {
          return;
        }
        const { isResetPassword, editUser } = this.props;
        const { alias, name } = editUser;
        const { password } = values;
        const params = {
          name,
          alias,
          password
        };
        if (isResetPassword) {
          updateUser(params).then((res) => {
            if (res) {
              Message.success(/* @__PURE__ */ React.createElement(Translation, null, "update user success"));
              this.props.onCreate();
            }
          });
        }
      });
    };
    this.showTitle = () => {
      const { isResetPassword } = this.props;
      if (isResetPassword) {
        return /* @__PURE__ */ React.createElement(Translation, null, "Reset Password");
      }
      return "";
    };
    this.showClickButtons = () => {
      const { loading } = this.state;
      return [
        /* @__PURE__ */ React.createElement(Button, { type: "secondary", onClick: this.onClose, style: { marginRight: "16px" } }, /* @__PURE__ */ React.createElement(Translation, null, "Cancel")),
        /* @__PURE__ */ React.createElement(Button, { type: "primary", onClick: this.onCreate, loading }, /* @__PURE__ */ React.createElement(Translation, null, "Update"))
      ];
    };
    this.field = new Field(this);
    this.state = {
      loading: false
    };
  }
  render() {
    const init = this.field.init;
    const { Row, Col } = Grid;
    const FormItem = Form.Item;
    const formItemLayout = {
      labelCol: {
        fixedSpan: 6
      },
      wrapperCol: {
        span: 20
      }
    };
    return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
      DrawerWithFooter,
      {
        title: this.showTitle(),
        placement: "right",
        width: 800,
        onClose: this.onClose,
        extButtons: this.showClickButtons()
      },
      /* @__PURE__ */ React.createElement(Form, { ...formItemLayout, field: this.field }, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(FormItem, { label: /* @__PURE__ */ React.createElement(Translation, null, "Password"), required: true }, /* @__PURE__ */ React.createElement(
        Input,
        {
          name: "password",
          htmlType: "password",
          placeholder: i18n.t("Please enter").toString(),
          ...init("password", {
            rules: [
              {
                required: true,
                pattern: checkUserPassword,
                message: /* @__PURE__ */ React.createElement(Translation, null, "Password should be 8-16 bits and contain at least one number and one letter")
              }
            ]
          })
        }
      )))))
    ));
  }
}
export default ResetPassword;
