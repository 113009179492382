"use strict";
import { Button } from "@alifd/next";
import React from "react";
import { TreeGraph } from "../../../../components/TreeGraph";
import { ShowResource } from "./resource-show";
import "./index.less";
import classNames from "classnames";
import { If } from "../../../../components/If";
import { IoMdAdd } from "react-icons/io";
import { AiOutlineMinus } from "react-icons/ai";
class ApplicationGraph extends React.Component {
  constructor(props) {
    super(props);
    this.onResourceDetailClick = (resource) => {
      this.setState({ showResource: true, resource });
    };
    this.state = {
      showResource: false,
      zoom: 1
    };
  }
  componentDidMount() {
  }
  convertNodeType(node) {
    switch (node.kind) {
      case "Pod":
        return "pod";
      case "Component":
        return "component";
      default:
        return "resource";
    }
  }
  convertNode(resources) {
    const tree = [];
    resources.map((res) => {
      const node = {
        resource: res,
        nodeType: this.convertNodeType(res)
      };
      if (res.leafNodes) {
        node.leafNodes = this.convertNode(res.leafNodes);
      }
      tree.push(node);
    });
    return tree;
  }
  convertComponentNode(service, component) {
    const node = {
      nodeType: "component",
      resource: {
        name: service.name,
        namespace: service.namespace,
        kind: "Component",
        component,
        cluster: service.cluster,
        service
      }
    };
    return node;
  }
  isLeafNode(componentName, component) {
    return component.dependsOn?.includes(componentName) || false;
  }
  // generate the component tree base the dependencies
  generateTree(tree, components) {
    tree.forEach((node) => {
      const nodeMap = /* @__PURE__ */ new Map();
      node.leafNodes?.map((ln) => {
        nodeMap.set(ln.resource.name, ln);
      });
      const deleteNode = [];
      node.leafNodes?.map((n) => {
        let isTop = true;
        components.map((c) => {
          if (this.isLeafNode(n.resource.name, c)) {
            const parentNode = nodeMap.get(c.name);
            if (parentNode && !parentNode.leafNodes) {
              parentNode.leafNodes = [n];
            } else if (parentNode && parentNode.leafNodes) {
              parentNode.leafNodes.push(n);
            }
            isTop = false;
          }
        });
        if (!isTop) {
          deleteNode.push(n.resource.name);
        }
      });
      node.leafNodes = node.leafNodes?.filter((n) => !deleteNode.includes(n.resource.name));
    });
  }
  buildClusterNode(resources, graphType) {
    const clusterTree = /* @__PURE__ */ new Map();
    if (graphType === "resource-graph") {
      resources.map((res) => {
        const cluster = res.cluster || "local";
        if (!clusterTree.get(cluster)) {
          clusterTree.set(cluster, { resource: { name: cluster }, nodeType: "cluster" });
        }
        const node = clusterTree.get(cluster);
        if (node) {
          if (res.resourceTree) {
            if (!node.leafNodes) {
              node.leafNodes = this.convertNode([res.resourceTree]);
            } else {
              node.leafNodes = node.leafNodes.concat(this.convertNode([res.resourceTree]));
            }
          }
        }
      });
    } else if (graphType === "application-graph") {
      const { applicationStatus, components } = this.props;
      const services = applicationStatus && applicationStatus.services || [];
      const componentMap = /* @__PURE__ */ new Map();
      components?.map((com) => {
        componentMap.set(com.name, com);
      });
      services.map((s) => {
        const cluster = s.cluster || "local";
        const namespace = s.namespace;
        const name = `${cluster}/${namespace}`;
        if (!clusterTree.get(name)) {
          clusterTree.set(name, { resource: { name }, nodeType: "target" });
        }
        const clusterNode = clusterTree.get(name);
        if (clusterNode) {
          const component = componentMap.get(s.name);
          if (!clusterNode.leafNodes) {
            clusterNode.leafNodes = [this.convertComponentNode(s, component)];
          } else {
            clusterNode.leafNodes = clusterNode.leafNodes.concat(this.convertComponentNode(s, component));
          }
        }
      });
    }
    const tree = [];
    clusterTree.forEach((value) => {
      tree.push(value);
    });
    return tree;
  }
  buildTree() {
    const { resources, env, application, graphType } = this.props;
    const root = {
      nodeType: "app",
      resource: {
        name: env?.appDeployName || application?.name || "",
        kind: "Application",
        apiVersion: "core.oam.dev/v1beta1",
        namespace: env?.appDeployNamespace || ""
      },
      leafNodes: this.buildClusterNode(resources, graphType)
    };
    return root;
  }
  render() {
    const { env, application, graphType } = this.props;
    const { showResource, resource, zoom } = this.state;
    const data = this.buildTree();
    return /* @__PURE__ */ React.createElement("div", { className: classNames("graph-container") }, /* @__PURE__ */ React.createElement("div", { className: "operation" }, /* @__PURE__ */ React.createElement(Button.Group, null, /* @__PURE__ */ React.createElement(
      Button,
      {
        onClick: () => {
          this.setState({ zoom: zoom - 0.1 });
        },
        type: "secondary",
        disabled: zoom <= 0.5
      },
      /* @__PURE__ */ React.createElement(AiOutlineMinus, null)
    ), /* @__PURE__ */ React.createElement(
      Button,
      {
        onClick: () => {
          this.setState({ zoom: zoom + 0.1 });
        },
        disabled: zoom >= 2,
        type: "secondary"
      },
      /* @__PURE__ */ React.createElement(IoMdAdd, null)
    ))), /* @__PURE__ */ React.createElement(
      TreeGraph,
      {
        onResourceDetailClick: this.onResourceDetailClick,
        appName: application?.name || "",
        envName: env?.name || "",
        node: data,
        zoom,
        nodesep: graphType === "resource-graph" ? 50 : 80
      }
    ), /* @__PURE__ */ React.createElement(If, { condition: showResource && resource }, resource && /* @__PURE__ */ React.createElement(
      ShowResource,
      {
        onClose: () => {
          this.setState({ showResource: false, resource: void 0 });
        },
        resource
      }
    )));
  }
}
export default ApplicationGraph;
