"use strict";
import System from "systemjs/dist/system.js";
import _ from "lodash";
import moment from "moment";
import react from "react";
import * as velauxData from "@velaux/data";
import { AppPagePlugin, PluginType } from "@velaux/data";
import * as velauxUI from "../types";
import * as ReactDom from "react-dom";
import * as DvaRouter from "dva/router";
import * as Redux from "redux";
import builtInPlugins from "./plugin/BuiltInPlugins";
import { getPluginInfo, locateWithCache, registerPluginInCache } from "./plugin/PluginCache";
import { getBackendSrv } from "./BackendService";
export const SystemJS = System;
SystemJS.registry.set("plugin-loader", SystemJS.newModule({ locate: locateWithCache }));
SystemJS.config({
  baseURL: "/public",
  defaultExtension: "js",
  packages: {
    plugins: {
      defaultExtension: "js"
    }
  },
  meta: {
    "/*": {
      esModule: true,
      authorization: false,
      loader: "plugin-loader"
    }
  }
});
export function exposeToPlugin(name, component) {
  SystemJS.registerDynamic(name, [], true, (require, exports, module) => {
    module.exports = component;
  });
}
exposeToPlugin("lodash", _);
exposeToPlugin("moment", moment);
exposeToPlugin("@velaux/data", velauxData);
exposeToPlugin("@velaux/ui", velauxUI);
exposeToPlugin("react", react);
exposeToPlugin("react-dom", ReactDom);
exposeToPlugin("redux", Redux);
exposeToPlugin("dva/router", DvaRouter);
export async function importPluginModule(path, version) {
  if (version) {
    registerPluginInCache({ path, version });
  }
  const builtIn = builtInPlugins[path];
  if (builtIn) {
    if (typeof builtIn === "function") {
      return await builtIn();
    } else {
      return builtIn;
    }
  }
  return SystemJS.import(path);
}
export function importAppPagePlugin(meta) {
  return importPluginModule(meta.module, meta.info?.version).then((pluginExports) => {
    const plugin = pluginExports.plugin ? pluginExports.plugin : new AppPagePlugin();
    plugin.init(meta);
    plugin.meta = meta;
    return plugin;
  });
}
export class PluginWrapper {
  constructor() {
  }
  listAppPagePlugins() {
    return getBackendSrv().get("/api/v1/plugins").then((res) => {
      if (res) {
        const plugins = res.plugins ? res.plugins : [];
        return Promise.resolve(plugins.filter((p) => p.type === PluginType.PageApp));
      }
      return Promise.reject(new Error("Unknown Plugins"));
    });
  }
  async loadMeta(pluginID) {
    return getPluginInfo(pluginID);
  }
}
let pluginService = new PluginWrapper();
export const getPluginSrv = () => pluginService;
