"use strict";
import { ConfigProvider } from "@alifd/next";
import React, { useEffect, useState } from "react";
import LayoutRouter from "./LayoutRouter";
import LeftMenu from "./LeftMenu";
import Header from "./Header";
import "./index.less";
import { LayoutModes } from "@velaux/data";
import { locationService } from "../services/LocationService";
import { menuService } from "../services/MenuService";
import { ErrorBoundary } from "../components/ErrorBoundary";
import { ErrorShow } from "../components/ErrorShow";
export default function MainLayout(props) {
  const [workspace, setWorkspace] = useState();
  const [mode, setMode] = useState(LayoutModes.Default);
  const query = locationService.getSearchObject();
  const path = locationService.getPathName();
  useEffect(() => {
    const layoutMode = query["layout-mode"];
    if (layoutMode && [LayoutModes.Neat, LayoutModes.NeatPro, LayoutModes.Default].includes(layoutMode)) {
      setMode(layoutMode);
    }
    menuService.loadPluginMenus().then(() => {
      setWorkspace(menuService.loadCurrentWorkspace());
    });
  }, [query, path]);
  return /* @__PURE__ */ React.createElement(ConfigProvider, null, /* @__PURE__ */ React.createElement(ErrorBoundary, null, ({ error, errorInfo }) => {
    if (error) {
      return /* @__PURE__ */ React.createElement(ErrorShow, { error, errorInfo });
    }
    return /* @__PURE__ */ React.createElement("div", { className: "layout" }, mode !== LayoutModes.NeatPro && /* @__PURE__ */ React.createElement(Header, { currentWorkspace: workspace, mode, ...props }), /* @__PURE__ */ React.createElement("div", { className: "layout-shell" }, mode === LayoutModes.Default && /* @__PURE__ */ React.createElement("div", { className: "layout-navigation" }, /* @__PURE__ */ React.createElement(LeftMenu, { ...props })), /* @__PURE__ */ React.createElement("div", { className: "layout-content" }, /* @__PURE__ */ React.createElement(LayoutRouter, null))));
  }));
}
