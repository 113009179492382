"use strict";
import { Grid, Icon, Select, Input, Button } from "@alifd/next";
import React from "react";
import "./index.less";
import { locale } from "../../../../utils/locale";
import i18n from "../../../../i18n";
import { AiOutlineSearch } from "react-icons/ai";
const { Row, Col } = Grid;
class SelectSearch extends React.Component {
  constructor(props) {
    super(props);
    this.onChangeEnv = (e) => {
      this.setState(
        {
          envValue: e
        },
        () => {
          this.getApplications();
        }
      );
    };
    this.handleClickSearch = () => {
      this.getApplications();
    };
    this.getApplications = async () => {
      const { projectValue, inputValue, envValue, labelValue } = this.state;
      const labelSelector = labelValue.join(",");
      const params = {
        project: projectValue,
        query: inputValue,
        env: envValue,
        labels: labelSelector
      };
      this.props.getApplications(params);
    };
    this.state = {
      projectValue: "",
      targetValue: "",
      envValue: "",
      inputValue: "",
      labelValue: []
    };
    this.onChangeProject = this.onChangeProject.bind(this);
    this.onChangeTarget = this.onChangeTarget.bind(this);
    this.handleChangName = this.handleChangName.bind(this);
    this.handleChangeLabel = this.handleChangeLabel.bind(this);
  }
  onChangeProject(e) {
    this.setState(
      {
        projectValue: e
      },
      () => {
        this.getApplications();
      }
    );
  }
  onChangeTarget(e) {
    this.setState(
      {
        targetValue: e
      },
      () => {
        this.getApplications();
      }
    );
  }
  handleChangName(e) {
    this.setState({
      inputValue: e
    });
  }
  handleChangeLabel(value) {
    const { setLabelValue } = this.props;
    let label = value ? value : [];
    setLabelValue(label);
    this.setState(
      {
        labelValue: label
      },
      () => {
        this.getApplications();
      }
    );
  }
  render() {
    const { projects, appLabels, envs, showMode, labelValue } = this.props;
    const { projectValue, inputValue, envValue } = this.state;
    const projectPlaceholder = i18n.t("Search by Project").toString();
    const appPlaceholder = i18n.t("Search by Name and Description etc").toString();
    const envPlaceholder = i18n.t("Search by Environment").toString();
    const labelPlaceholder = i18n.t("Search by Label Selector").toString();
    const projectSource = projects?.map((item) => {
      return {
        label: item.alias || item.name,
        value: item.name
      };
    });
    const labelSource = appLabels?.map((item) => {
      return {
        label: item,
        value: item
      };
    });
    const envSource = envs?.map((env) => {
      return {
        label: env.alias || env.name,
        value: env.name
      };
    });
    return /* @__PURE__ */ React.createElement(Row, { className: "app-select-wrapper border-radius-8", wrap: true }, /* @__PURE__ */ React.createElement(Col, { xl: 4, m: 4, s: 6, xxs: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(
      Select,
      {
        locale: locale().Select,
        mode: "single",
        size: "large",
        onChange: this.onChangeProject,
        dataSource: projectSource,
        placeholder: projectPlaceholder,
        className: "item",
        hasClear: true,
        value: projectValue
      }
    )), /* @__PURE__ */ React.createElement(Col, { xl: 4, m: 4, s: 6, xxs: 12, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(
      Select,
      {
        locale: locale().Select,
        mode: "single",
        size: "large",
        onChange: this.onChangeEnv,
        dataSource: envSource,
        placeholder: envPlaceholder,
        className: "item",
        hasClear: true,
        value: envValue
      }
    )), /* @__PURE__ */ React.createElement(Col, { xl: 4, m: 8, s: 12, xxs: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(
      Input,
      {
        innerAfter: /* @__PURE__ */ React.createElement(AiOutlineSearch, { onClick: this.handleClickSearch, style: { margin: 4 } }),
        hasClear: true,
        size: "large",
        placeholder: appPlaceholder,
        onChange: this.handleChangName,
        onPressEnter: this.handleClickSearch,
        value: inputValue,
        className: "item"
      }
    )), /* @__PURE__ */ React.createElement(Col, { xl: 8, m: 8, s: 12, xxs: 24, style: { padding: "0 8px" } }, /* @__PURE__ */ React.createElement(
      Select,
      {
        hasClear: true,
        size: "large",
        placeholder: labelPlaceholder,
        onChange: this.handleChangeLabel,
        showSearch: true,
        mode: "multiple",
        value: labelValue,
        className: "item",
        dataSource: labelSource
      }
    )), /* @__PURE__ */ React.createElement(Col, { xl: 4, className: "flexboth" }, /* @__PURE__ */ React.createElement("div", { className: "padding16 show-mode" }, /* @__PURE__ */ React.createElement(Button, { type: "secondary", onClick: () => this.getApplications() }, /* @__PURE__ */ React.createElement(Icon, { type: "refresh" }))), /* @__PURE__ */ React.createElement("div", { className: "show-mode padding16" }, /* @__PURE__ */ React.createElement(Button.Group, null, /* @__PURE__ */ React.createElement(Button, { type: showMode == "card" ? "primary" : "secondary", onClick: () => this.props.setMode("card") }, "Card"), /* @__PURE__ */ React.createElement(Button, { type: showMode == "table" ? "primary" : "secondary", onClick: () => this.props.setMode("table") }, "Table")))));
  }
}
export default SelectSearch;
