"use strict";
import { Checkbox, Grid, Input, Select } from "@alifd/next";
import React from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { Translation } from "../../../../components/Translation";
import i18n from "../../../../i18n";
import { locale } from "../../../../utils/locale";
import "./index.less";
class SelectSearch extends React.Component {
  constructor(props) {
    super(props);
    this.handleClickSearch = () => {
      const { listFunction } = this.props;
      const { registryValue, inputValue } = this.state;
      listFunction({ registry: registryValue, query: inputValue });
    };
    this.generateTagList = () => {
      const { tagList } = this.props;
      const data = [];
      tagList?.map((tag) => {
        if (tag.num > 2) {
          data.push({ label: tag.tag, value: tag.tag });
        }
      });
      return data;
    };
    this.state = {
      registryValue: "",
      inputValue: ""
    };
    this.handleChangRegistry = this.handleChangRegistry.bind(this);
    this.handleChangName = this.handleChangName.bind(this);
  }
  handleChangRegistry(e) {
    this.setState(
      {
        registryValue: e
      },
      () => {
        this.handleClickSearch();
      }
    );
  }
  handleChangName(e) {
    this.setState({ inputValue: e });
  }
  render() {
    const { Row, Col } = Grid;
    const { Option } = Select;
    const { registries, extButtons } = this.props;
    const queryPlaceholder = i18n.t("Search by Name and Description etc").toString();
    const { registryValue, inputValue } = this.state;
    return /* @__PURE__ */ React.createElement("div", { className: "border-radius-8 addon-search" }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Row, { wrap: true, gutter: 12 }, /* @__PURE__ */ React.createElement(Col, { l: 8, m: 8, s: 12, xxs: 24 }, /* @__PURE__ */ React.createElement(
      Select,
      {
        locale: locale().Select,
        mode: "single",
        size: "large",
        onChange: this.handleChangRegistry,
        className: "item",
        value: registryValue
      },
      /* @__PURE__ */ React.createElement(Option, { value: "" }, /* @__PURE__ */ React.createElement(Translation, null, "All")),
      registries?.map((item) => {
        return /* @__PURE__ */ React.createElement(Option, { key: item.name, value: item.name }, item.name);
      })
    )), /* @__PURE__ */ React.createElement(Col, { l: 8, m: 8, s: 12, xxs: 24 }, /* @__PURE__ */ React.createElement(
      Input,
      {
        innerAfter: /* @__PURE__ */ React.createElement(AiOutlineSearch, { onClick: this.handleClickSearch, style: { margin: 4 } }),
        placeholder: queryPlaceholder,
        size: "large",
        onChange: this.handleChangName,
        onPressEnter: this.handleClickSearch,
        value: inputValue,
        className: "item"
      }
    )), /* @__PURE__ */ React.createElement(Col, { l: 8, m: 8, s: 12, xxs: 24 }, /* @__PURE__ */ React.createElement("div", { className: "ext-btn" }, extButtons && extButtons.map((item) => {
      return item;
    })))), /* @__PURE__ */ React.createElement("div", { className: "tag-search" }, /* @__PURE__ */ React.createElement("div", { className: "tag-name" }, /* @__PURE__ */ React.createElement(Translation, null, "Tags")), /* @__PURE__ */ React.createElement("div", { className: "tag-list" }, /* @__PURE__ */ React.createElement(
      Checkbox.Group,
      {
        dataSource: this.generateTagList(),
        onChange: (tags) => {
          this.props.onTagChange(tags);
        }
      }
    )))));
  }
}
export default SelectSearch;
