"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import React from "react";
import "./index.less";
import { Dropdown, Menu, Grid, Card, Dialog } from "@alifd/next";
import kubernetesSvg from "../../../../assets/kubernetes.svg";
import { If } from "../../../../components/If";
import { Translation } from "../../../../components/Translation";
import { locale } from "../../../../utils/locale";
import { checkPermission } from "../../../../utils/permission";
import { connect } from "dva";
let CardContent = class extends React.Component {
  constructor(props) {
    super(props);
    this.editCluster = (name) => {
      this.props.editCluster(name);
    };
    this.onDeleteCluster = (name) => {
      this.props.deleteCluster(name);
    };
    this.isEditPermission = (item) => {
      const { userInfo } = this.props;
      const project = "";
      const request = { resource: `cluster:${item.name}`, action: "update" };
      if (checkPermission(request, project, userInfo)) {
        return /* @__PURE__ */ React.createElement(
          Menu.Item,
          {
            onClick: () => {
              this.editCluster(item.name);
            }
          },
          /* @__PURE__ */ React.createElement(Translation, null, "Edit")
        );
      } else {
        return null;
      }
    };
    this.isDeletePermission = (item) => {
      const { userInfo } = this.props;
      const project = "";
      const request = { resource: `cluster:${item.name}`, action: "delete" };
      if (checkPermission(request, project, userInfo)) {
        return /* @__PURE__ */ React.createElement(
          Menu.Item,
          {
            onClick: () => {
              Dialog.confirm({
                type: "confirm",
                content: /* @__PURE__ */ React.createElement(Translation, null, "Are you sure you want the detach cluster?"),
                onOk: () => {
                  this.onDeleteCluster(item.name);
                },
                locale: locale().Dialog
              });
            }
          },
          /* @__PURE__ */ React.createElement(Translation, null, "Detach")
        );
      } else {
        return null;
      }
    };
    this.state = {
      extendDotVisible: false,
      choseIndex: 0
    };
  }
  render() {
    const { Row, Col } = Grid;
    const { clusters } = this.props;
    return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Row, { wrap: true }, clusters.map((item) => {
      const { name, alias, status, icon, description, createTime, dashboardURL = "#" } = item;
      const showName = alias ? alias : name;
      const card = /* @__PURE__ */ React.createElement(Card, { locale, contentHeight: "auto" }, /* @__PURE__ */ React.createElement("div", { className: "cluster-card-top flexcenter" }, /* @__PURE__ */ React.createElement(If, { condition: icon && icon != "none" }, /* @__PURE__ */ React.createElement("img", { src: icon })), /* @__PURE__ */ React.createElement(If, { condition: !icon || icon === "none" }, /* @__PURE__ */ React.createElement("img", { src: kubernetesSvg }))), /* @__PURE__ */ React.createElement("div", { className: "content-wrapper background-F9F8FF" }, /* @__PURE__ */ React.createElement(Row, { className: "content-title" }, /* @__PURE__ */ React.createElement(Col, { span: 16, className: "font-size-16 color1A1A1A" }, /* @__PURE__ */ React.createElement(If, { condition: dashboardURL }, /* @__PURE__ */ React.createElement("a", { title: name, target: "_blank", href: dashboardURL, rel: "noopener noreferrer" }, showName)), /* @__PURE__ */ React.createElement(If, { condition: !dashboardURL }, showName)), /* @__PURE__ */ React.createElement(If, { condition: name != "local" }, /* @__PURE__ */ React.createElement(Col, { span: 8, className: "dot-wrapper" }, /* @__PURE__ */ React.createElement(
        Dropdown,
        {
          trigger: /* @__PURE__ */ React.createElement(
            "svg",
            {
              className: "action",
              viewBox: "0 0 1024 1024",
              version: "1.1",
              xmlns: "http://www.w3.org/2000/svg",
              "p-id": "3448"
            },
            /* @__PURE__ */ React.createElement(
              "path",
              {
                d: "M365.066 197.39c0 0 0 0 0 0 0 58.569 47.479 106.048 106.048 106.048 58.569 0 106.048-47.479 106.048-106.048 0 0 0 0 0 0 0-58.569-47.479-106.048-106.048-106.048-58.569 0-106.048 47.479-106.048 106.048 0 0 0 0 0 0z",
                fill: "#a6a6a6",
                "p-id": "3449"
              }
            ),
            /* @__PURE__ */ React.createElement(
              "path",
              {
                d: "M365.066 512c0 0 0 0 0 0 0 58.569 47.479 106.048 106.048 106.048 58.569 0 106.048-47.479 106.048-106.048 0 0 0 0 0 0 0-58.569-47.479-106.048-106.048-106.048-58.569 0-106.048 47.479-106.048 106.048 0 0 0 0 0 0z",
                fill: "#a6a6a6",
                "p-id": "3450"
              }
            ),
            /* @__PURE__ */ React.createElement(
              "path",
              {
                d: "M365.066 826.61c0 0 0 0 0 0 0 58.569 47.479 106.048 106.048 106.048 58.569 0 106.048-47.479 106.048-106.048 0 0 0 0 0 0 0-58.569-47.479-106.048-106.048-106.048-58.569 0-106.048 47.479-106.048 106.048 0 0 0 0 0 0z",
                fill: "#a6a6a6",
                "p-id": "3451"
              }
            )
          )
        },
        /* @__PURE__ */ React.createElement(Menu, null, this.isEditPermission(item), this.isDeletePermission(item))
      ))), /* @__PURE__ */ React.createElement(If, { condition: name === "local" }, /* @__PURE__ */ React.createElement(Col, { span: 8 }, /* @__PURE__ */ React.createElement(
        "span",
        {
          style: {
            color: "#a6a6a6",
            fontSize: "12px",
            textAlign: "right",
            display: "block",
            width: "100%",
            lineHeight: "24px"
          }
        },
        /* @__PURE__ */ React.createElement(Translation, null, "Local")
      )))), /* @__PURE__ */ React.createElement(Row, { className: "content-main" }, /* @__PURE__ */ React.createElement("h4", { className: "color595959 font-size-14", title: description }, description)), /* @__PURE__ */ React.createElement(Row, { className: "content-foot colorA6A6A6" }, /* @__PURE__ */ React.createElement(Col, { span: "16" }, /* @__PURE__ */ React.createElement("span", null, createTime)), /* @__PURE__ */ React.createElement(Col, { span: "8", className: "text-align-right padding-right-10" }, /* @__PURE__ */ React.createElement(If, { condition: status == "Healthy" }, /* @__PURE__ */ React.createElement("span", { className: "circle circle-success" }), /* @__PURE__ */ React.createElement(Translation, null, "Healthy")), /* @__PURE__ */ React.createElement(If, { condition: status == "Unhealthy" }, /* @__PURE__ */ React.createElement("span", { className: "circle circle-warning" }), /* @__PURE__ */ React.createElement(Translation, null, "UnHealthy"))))));
      return /* @__PURE__ */ React.createElement(Col, { xl: 6, m: 8, s: 12, xxs: 24, className: `card-content-wrapper`, key: item.name }, card);
    })));
  }
};
CardContent = __decorateClass([
  connect((store) => {
    return { ...store.user };
  })
], CardContent);
export default CardContent;
