"use strict";
import React, { useState } from "react";
import "./index.less";
import { Dropdown, Menu } from "@alifd/next";
import { useTranslation } from "react-i18next";
import { TbLanguage } from "react-icons/tb";
import { getLanguage } from "../../utils/common";
const SwitchLanguage = () => {
  const { i18n } = useTranslation();
  const _isEnglish = getLanguage() === "en";
  const [isEnglish, setIsEnglish] = useState(_isEnglish);
  return /* @__PURE__ */ React.createElement(Dropdown, { trigger: /* @__PURE__ */ React.createElement(TbLanguage, { size: "18" }) }, /* @__PURE__ */ React.createElement(Menu, null, /* @__PURE__ */ React.createElement(
    Menu.Item,
    {
      onClick: () => {
        i18n.changeLanguage("en");
        localStorage.setItem("lang", "en");
        setIsEnglish(true);
      }
    },
    "English ",
    isEnglish ? "(Now)" : ""
  ), /* @__PURE__ */ React.createElement(
    Menu.Item,
    {
      onClick: () => {
        i18n.changeLanguage("zh");
        localStorage.setItem("lang", "zh");
        setIsEnglish(false);
      }
    },
    "\u4E2D\u6587",
    !isEnglish ? "(\u5F53\u524D)" : ""
  )));
};
export default SwitchLanguage;
