"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Table, Button, Pagination, Dialog, Message } from "@alifd/next";
import { Link } from "dva/router";
import React, { Fragment, Component } from "react";
import { getProjectList, deleteProject } from "../../api/project";
import { getUserList } from "../../api/users";
import { If } from "../../components/If";
import { ListTitle as Title } from "../../components/ListTitle";
import Permission from "../../components/Permission";
import { Translation } from "../../components/Translation";
import { momentDate } from "../../utils/common";
import { locale } from "../../utils/locale";
import CreateProjectDialog from "./components/CreateProjectDialog";
import "./index.less";
import { connect } from "dva";
let Projects = class extends Component {
  constructor(props) {
    super(props);
    this.listProjects = async () => {
      this.setState({ isLoading: true });
      const { page, pageSize } = this.state;
      getProjectList({ page, pageSize }).then((res) => {
        this.setState({
          list: res.projects || [],
          total: res.total || 0,
          isLoading: false
        });
      }).catch(() => {
        this.setState({ isLoading: false });
      });
    };
    this.listUser = async () => {
      const param = { name: "" };
      getUserList(param).then((res) => {
        if (res && res.users) {
          const userListData = (res.users || []).map((item) => ({
            label: item.name,
            value: item.name
          }));
          this.setState({
            userList: userListData || []
          });
        }
      });
    };
    this.onEdit = (record) => {
      this.setState({
        visible: true,
        isEditProject: true,
        editProjectItem: record
      });
    };
    this.onDelete = (record) => {
      Dialog.confirm({
        content: "Are you sure you want to delete the project",
        onOk: () => {
          const { name } = record;
          if (name) {
            deleteProject({ name }).then((res) => {
              if (res) {
                Message.success(/* @__PURE__ */ React.createElement(Translation, null, "Delete project success"));
                this.listProjects();
                this.onUpdateUserInfo();
              }
            }).catch();
          }
        },
        locale: locale().Dialog
      });
    };
    this.onCreate = () => {
      this.setState({ visible: false });
      this.listProjects();
      this.onUpdateUserInfo();
    };
    this.onUpdateUserInfo = () => {
      this.props.dispatch({
        type: "user/getLoginUserInfo"
      });
    };
    this.onCloseCreate = () => {
      this.setState({ visible: false });
    };
    this.handleClickCreate = () => {
      this.setState({
        visible: true,
        isEditProject: false
      });
    };
    this.handleChange = (page) => {
      this.setState(
        {
          page
        },
        () => {
          this.listProjects();
        }
      );
    };
    this.state = {
      list: [],
      total: 0,
      page: 1,
      pageSize: 10,
      visible: false,
      isEditProject: false,
      editProjectItem: { name: "" },
      isLoading: false,
      userList: []
    };
  }
  componentDidMount() {
    this.listProjects();
    this.listUser();
  }
  render() {
    const columns = [
      {
        key: "name",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Name"),
        dataIndex: "name",
        cell: (v) => {
          return /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(Link, { to: `/projects/${v}` }, v));
        }
      },
      {
        key: "alias",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Alias"),
        dataIndex: "alias",
        cell: (v) => {
          return /* @__PURE__ */ React.createElement("span", null, v);
        }
      },
      {
        key: "description",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Description"),
        dataIndex: "description",
        cell: (v) => {
          return /* @__PURE__ */ React.createElement("span", null, v);
        }
      },
      {
        key: "createTime",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Create Time"),
        dataIndex: "createTime",
        cell: (v) => {
          return /* @__PURE__ */ React.createElement("span", null, momentDate(v));
        }
      },
      {
        key: "owner",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Owner"),
        dataIndex: "owner",
        cell: (v) => {
          return /* @__PURE__ */ React.createElement("span", null, v && v.alias ? `${v.alias}(${v.name})` : v.name);
        }
      },
      {
        key: "operation",
        title: /* @__PURE__ */ React.createElement(Translation, null, "Actions"),
        dataIndex: "operation",
        cell: (v, i, record) => {
          return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement(Permission, { request: { resource: `project:${record.name}`, action: "update" }, project: `${record.name}` }, /* @__PURE__ */ React.createElement(
            Button,
            {
              text: true,
              size: "medium",
              component: "a",
              onClick: () => {
                this.onEdit(record);
              }
            },
            /* @__PURE__ */ React.createElement(Translation, null, "Edit")
          )), /* @__PURE__ */ React.createElement(Permission, { request: { resource: `project:${record.name}`, action: "delete" }, project: `${record.name}` }, /* @__PURE__ */ React.createElement(
            Button,
            {
              text: true,
              size: "medium",
              component: "a",
              onClick: () => {
                this.onDelete(record);
              }
            },
            /* @__PURE__ */ React.createElement(Translation, null, "Delete")
          )));
        }
      }
    ];
    const { Column } = Table;
    const { list, page, pageSize, total, visible, isEditProject, editProjectItem, isLoading, userList } = this.state;
    return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "project-list-content" }, /* @__PURE__ */ React.createElement(
      Title,
      {
        title: "Projects",
        subTitle: "Projects are used to allocate and isolate resources",
        extButtons: [
          /* @__PURE__ */ React.createElement(Permission, { request: { resource: "project:*", action: "create" }, project: "" }, /* @__PURE__ */ React.createElement(Button, { type: "primary", onClick: this.handleClickCreate }, /* @__PURE__ */ React.createElement(Translation, null, "New Project")))
        ]
      }
    ), /* @__PURE__ */ React.createElement(Table, { locale: locale().Table, dataSource: list, loading: isLoading }, columns.map((col, key) => /* @__PURE__ */ React.createElement(Column, { ...col, key, align: "left" }))), /* @__PURE__ */ React.createElement(
      Pagination,
      {
        className: "margin-top-20 text-align-right",
        total,
        locale: locale().Pagination,
        size: "medium",
        pageSize,
        current: page,
        onChange: this.handleChange
      }
    ), /* @__PURE__ */ React.createElement(If, { condition: visible }, /* @__PURE__ */ React.createElement(
      CreateProjectDialog,
      {
        visible,
        userList,
        isEditProject,
        editProjectItem,
        onCreate: this.onCreate,
        onCloseCreate: this.onCloseCreate
      }
    ))));
  }
};
Projects = __decorateClass([
  connect()
], Projects);
export default Projects;
