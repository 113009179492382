"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { connect } from "dva";
import React from "react";
import "./index.less";
import { Link } from "dva/router";
import { Grid, Card, Menu, Dropdown, Dialog, Button, Table, Tag, Icon } from "@alifd/next";
import { AiFillDelete, AiFillSetting } from "react-icons/ai";
import appSvg from "../../../../assets/application.svg";
import Empty from "../../../../components/Empty";
import { If } from "../../../../components/If";
import Permission from "../../../../components/Permission";
import { Translation } from "../../../../components/Translation";
import { momentDate } from "../../../../utils/common";
import { locale } from "../../../../utils/locale";
import { checkPermission } from "../../../../utils/permission";
const { Column } = Table;
let CardContent = class extends React.Component {
  constructor(props) {
    super(props);
    this.onDeleteAppPlan = (name) => {
      this.props.deleteAppPlan(name);
    };
    this.onEditAppPlan = (item) => {
      this.props.editAppPlan(item);
    };
    this.onClickLabelFilter = (label) => {
      if (this.props.clickLabelFilter) {
        this.props.clickLabelFilter(label);
      }
    };
    this.onMoreLabels = (appName) => {
      let { showLabelMode } = this.state;
      let cur = showLabelMode.get(appName);
      showLabelMode.set(appName, cur ? false : true);
      this.setState({
        showLabelMode
      });
    };
    this.isEditPermission = (item, button) => {
      const { userInfo } = this.props;
      const project = item?.project?.name || this.props.projectName || "?";
      const request = { resource: `project:${project}/application:${item.name}`, action: "update" };
      if (checkPermission(request, project, userInfo)) {
        if (button) {
          return /* @__PURE__ */ React.createElement(
            Button,
            {
              text: true,
              size: "medium",
              component: "a",
              onClick: () => {
                this.onEditAppPlan(item);
              }
            },
            /* @__PURE__ */ React.createElement(AiFillSetting, null),
            /* @__PURE__ */ React.createElement(Translation, null, "Edit")
          );
        }
        return /* @__PURE__ */ React.createElement(
          Menu.Item,
          {
            onClick: () => {
              this.onEditAppPlan(item);
            }
          },
          /* @__PURE__ */ React.createElement("div", { className: "dropdown-menu-item inline-center" }, /* @__PURE__ */ React.createElement(AiFillSetting, null), /* @__PURE__ */ React.createElement(Translation, null, "Edit"))
        );
      } else {
        return null;
      }
    };
    this.isDeletePermission = (item, button) => {
      const { userInfo } = this.props;
      const project = item?.project?.name || this.props.projectName || "?";
      const request = { resource: `project:${project}/application:${item.name}`, action: "delete" };
      const onClick = () => {
        Dialog.confirm({
          type: "confirm",
          content: /* @__PURE__ */ React.createElement(Translation, null, "Unrecoverable after deletion, are you sure to delete it?"),
          onOk: () => {
            this.onDeleteAppPlan(item.name);
          },
          locale: locale().Dialog
        });
      };
      if (checkPermission(request, project, userInfo)) {
        if (button) {
          return /* @__PURE__ */ React.createElement(Button, { text: true, size: "medium", className: "danger-btn", component: "a", onClick }, /* @__PURE__ */ React.createElement(AiFillDelete, null), " ", /* @__PURE__ */ React.createElement(Translation, null, "Remove"));
        }
        return /* @__PURE__ */ React.createElement(Menu.Item, { onClick }, /* @__PURE__ */ React.createElement("div", { className: "dropdown-menu-item inline-center" }, /* @__PURE__ */ React.createElement(AiFillDelete, null), " ", /* @__PURE__ */ React.createElement(Translation, null, "Remove")));
      } else {
        return null;
      }
    };
    this.getColumns = () => {
      return [
        {
          key: "name",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Name(Alias)"),
          dataIndex: "name",
          cell: (v, i, app) => {
            const showName = app.name + "(" + (app.alias || "-") + ")";
            return /* @__PURE__ */ React.createElement(Link, { to: `/applications/${v}/config` }, showName);
          }
        },
        {
          key: "project",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Project"),
          dataIndex: "project",
          cell: (v) => {
            if (v && v.name) {
              return /* @__PURE__ */ React.createElement(Link, { to: `/projects/${v.name}/summary` }, v && v.name);
            } else {
              return null;
            }
          }
        },
        {
          key: "description",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Description"),
          dataIndex: "description",
          cell: (v) => {
            return /* @__PURE__ */ React.createElement("span", null, v);
          }
        },
        {
          key: "labels",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Labels"),
          dataIndex: "labels",
          cell: (label, i, v) => {
            const { showLabelMode } = this.state;
            const more = showLabelMode.get(v.name);
            let displayLabels = 0;
            return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", { className: more ? "" : "table-content-label" }, label && Object.keys(label)?.map((key) => {
              if (label && key.indexOf("ux.oam.dev") < 0 && key.indexOf("app.oam.dev") < 0) {
                displayLabels++;
                return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
                  Tag,
                  {
                    onClick: (e) => this.onClickLabelFilter(key + `=${label[key]}`),
                    key: `${key}=${label[key]}`,
                    style: { margin: "2px" },
                    color: "blue",
                    size: "small"
                  },
                  `${key}=${label[key]}`
                ));
              }
              return;
            })), displayLabels > 1 && /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
              Tag,
              {
                onClick: (e) => this.onMoreLabels(v.name),
                key: "showLabelTag",
                style: { margin: "2px" },
                size: "small"
              },
              /* @__PURE__ */ React.createElement(Translation, null, more ? "Hide" : "More"),
              more ? /* @__PURE__ */ React.createElement(Icon, { type: "minus" }) : /* @__PURE__ */ React.createElement(Icon, { type: "add" })
            )));
          }
        },
        {
          key: "operation",
          title: /* @__PURE__ */ React.createElement(Translation, null, "Actions"),
          dataIndex: "operation",
          width: "200px",
          cell: (v, i, record) => {
            return /* @__PURE__ */ React.createElement("div", null, this.isEditPermission(record, true), /* @__PURE__ */ React.createElement("span", { className: "line" }), this.isDeletePermission(record, true));
          }
        }
      ];
    };
    const { applications } = this.props;
    let showLabelMode = /* @__PURE__ */ new Map();
    applications?.map((app) => {
      if (app.labels && Object.keys(app.labels).length > 1) {
        showLabelMode.set(app.name, true);
      } else {
        showLabelMode.set(app.name, false);
      }
    });
    this.state = {
      extendDotVisible: false,
      choseIndex: 0,
      showLabelMode
    };
  }
  render() {
    const { Row, Col } = Grid;
    const { applications, setVisible, showMode } = this.props;
    const projectName = this.props.projectName || "?";
    if (!applications || applications.length === 0) {
      return /* @__PURE__ */ React.createElement(
        Empty,
        {
          message: /* @__PURE__ */ React.createElement("section", { style: { textAlign: "center" } }, /* @__PURE__ */ React.createElement(Translation, null, "There are no applications"), /* @__PURE__ */ React.createElement("main", null, /* @__PURE__ */ React.createElement(
            Permission,
            {
              request: { resource: `project:${projectName}/application:*`, action: "create" },
              project: projectName
            },
            /* @__PURE__ */ React.createElement(
              Button,
              {
                component: "a",
                onClick: () => {
                  setVisible(true);
                }
              },
              /* @__PURE__ */ React.createElement(Translation, null, "New Application")
            )
          ))),
          style: { minHeight: "400px" }
        }
      );
    }
    const columns = this.getColumns();
    if (showMode == "table") {
      return /* @__PURE__ */ React.createElement("div", { style: { overflow: "auto" } }, /* @__PURE__ */ React.createElement(
        Table,
        {
          locale: locale().Table,
          className: "customTable",
          size: "medium",
          style: { minWidth: "1200px" },
          dataSource: applications,
          loading: false
        },
        columns.map((col) => /* @__PURE__ */ React.createElement(Column, { ...col, key: col.key, align: "left" }))
      ));
    }
    return /* @__PURE__ */ React.createElement(Row, { wrap: true }, applications?.map((item) => {
      const { name, alias, icon, description, createTime, readOnly, labels } = item;
      const showName = alias || name;
      return /* @__PURE__ */ React.createElement(Col, { xl: 6, m: 8, s: 12, xxs: 24, className: `card-content-wrapper`, key: `${item.name}` }, /* @__PURE__ */ React.createElement(Card, { locale: locale().Card, contentHeight: "auto" }, /* @__PURE__ */ React.createElement(Link, { to: `/applications/${name}/config` }, /* @__PURE__ */ React.createElement("div", { className: "appplan-card-top flexcenter" }, /* @__PURE__ */ React.createElement(If, { condition: icon && icon != "none" }, /* @__PURE__ */ React.createElement("img", { src: icon })), /* @__PURE__ */ React.createElement(If, { condition: !icon || icon === "none" }, /* @__PURE__ */ React.createElement("img", { src: appSvg })))), /* @__PURE__ */ React.createElement("div", { className: "content-wrapper background-F9F8FF" }, /* @__PURE__ */ React.createElement(Row, { className: "content-title" }, /* @__PURE__ */ React.createElement(Col, { span: "20", className: "font-size-16 color1A1A1A" }, /* @__PURE__ */ React.createElement(Link, { to: `/applications/${name}/config` }, showName)), /* @__PURE__ */ React.createElement(Col, { span: 4, className: "dot-wrapper" }, /* @__PURE__ */ React.createElement(
        Dropdown,
        {
          trigger: /* @__PURE__ */ React.createElement(
            "svg",
            {
              className: "action",
              viewBox: "0 0 1024 1024",
              version: "1.1",
              xmlns: "http://www.w3.org/2000/svg",
              "p-id": "3448"
            },
            /* @__PURE__ */ React.createElement(
              "path",
              {
                d: "M365.066 197.39c0 0 0 0 0 0 0 58.569 47.479 106.048 106.048 106.048 58.569 0 106.048-47.479 106.048-106.048 0 0 0 0 0 0 0-58.569-47.479-106.048-106.048-106.048-58.569 0-106.048 47.479-106.048 106.048 0 0 0 0 0 0z",
                fill: "#a6a6a6",
                "p-id": "3449"
              }
            ),
            /* @__PURE__ */ React.createElement(
              "path",
              {
                d: "M365.066 512c0 0 0 0 0 0 0 58.569 47.479 106.048 106.048 106.048 58.569 0 106.048-47.479 106.048-106.048 0 0 0 0 0 0 0-58.569-47.479-106.048-106.048-106.048-58.569 0-106.048 47.479-106.048 106.048 0 0 0 0 0 0z",
                fill: "#a6a6a6",
                "p-id": "3450"
              }
            ),
            /* @__PURE__ */ React.createElement(
              "path",
              {
                d: "M365.066 826.61c0 0 0 0 0 0 0 58.569 47.479 106.048 106.048 106.048 58.569 0 106.048-47.479 106.048-106.048 0 0 0 0 0 0 0-58.569-47.479-106.048-106.048-106.048-58.569 0-106.048 47.479-106.048 106.048 0 0 0 0 0 0z",
                fill: "#a6a6a6",
                "p-id": "3451"
              }
            )
          )
        },
        /* @__PURE__ */ React.createElement(Menu, null, this.isEditPermission(item), this.isDeletePermission(item))
      ))), /* @__PURE__ */ React.createElement(Row, { className: "content-main" }, /* @__PURE__ */ React.createElement("h4", { className: "color595959 font-size-14", title: description }, description)), /* @__PURE__ */ React.createElement(Row, { className: "content-labels" }, labels && Object.keys(labels).map((key) => {
        if (labels && key.indexOf("ux.oam.dev") < 0 && key.indexOf("app.oam.dev")) {
          return /* @__PURE__ */ React.createElement(
            Tag,
            {
              onClick: (e) => this.onClickLabelFilter(key + `=${labels[key]}`),
              key,
              style: { margin: "4px" },
              color: "blue"
            },
            `${key}=${labels[key]}`
          );
        }
        return null;
      })), /* @__PURE__ */ React.createElement(Row, { className: "content-foot colorA6A6A6" }, /* @__PURE__ */ React.createElement(Col, { span: "16" }, /* @__PURE__ */ React.createElement("span", null, createTime && momentDate(createTime))), /* @__PURE__ */ React.createElement(Col, { span: 8, className: "flexright" }, /* @__PURE__ */ React.createElement(If, { condition: readOnly }, /* @__PURE__ */ React.createElement("span", { className: "circle circle-warning" }), /* @__PURE__ */ React.createElement(Translation, null, "ReadOnly")))))));
    }));
  }
};
CardContent = __decorateClass([
  connect((store) => {
    return { ...store.user };
  })
], CardContent);
export default CardContent;
