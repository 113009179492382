"use strict";
import { getDomain } from "../utils/common";
import { addons, addonRegistries, enabledAddon } from "./productionLink";
import { post, get, rdelete, put } from "./request";
const baseURLOject = getDomain();
const base = baseURLOject.MOCK || baseURLOject.APIBASE;
export function getAddonRegistriesList(params) {
  return get(base + addonRegistries, { params }).then((res) => res);
}
export function createAddonRegistry(params) {
  return post(base + addonRegistries, params);
}
export function deleteAddonRegistry(params) {
  return rdelete(base + addonRegistries + "/" + params.name, params).then((res) => res);
}
export function getAddonsList(params) {
  return get(base + addons, { params }).then((res) => res);
}
export function getAddonsDetails(params) {
  const gurl = `${base + addons}/${params.name}`;
  return get(
    gurl,
    params.version ? {
      params: {
        version: params.version
      }
    } : {}
  ).then((res) => res);
}
export function disableAddon(params) {
  const gurl = `${base + addons}/${params.name}/disable`;
  return post(gurl, params).then((res) => res);
}
export function enableAddon(params) {
  const gurl = `${base + addons}/${params.name}/enable`;
  const req = { args: params.properties, version: params.version };
  if (params.clusters) {
    req.clusters = params.clusters;
  }
  return post(gurl, req).then((res) => res);
}
export function upgradeAddon(params) {
  const gurl = `${base + addons}/${params.name}/update`;
  const req = { args: params.properties, version: params.version };
  if (params.clusters) {
    req.clusters = params.clusters;
  }
  return put(gurl, req).then((res) => res);
}
export function getAddonsStatus(params) {
  const gurl = `${base + addons}/${params.name}/status`;
  return get(gurl, params).then((res) => res);
}
export function getEnabledAddons(params) {
  return get(base + enabledAddon, params);
}
