"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { Field, Grid, Radio, Input, Message, Switch, Select, Dialog, Card, Button, Form } from "@alifd/next";
import { connect } from "dva";
import { routerRedux } from "dva/router";
import React from "react";
import { AiOutlineDelete, AiOutlinePlus } from "react-icons/ai";
import { v4 as uuid } from "uuid";
import { MdOutlineHelpCenter } from "react-icons/md";
import { getDexConfig } from "../../api/authentication";
import { getProjectList, getProjectRoles } from "../../api/project";
import { updateSystemInfo } from "../../api/system_config";
import { Translation } from "../../components/Translation";
import i18n from "../../i18n";
import { locale } from "../../utils/locale";
import { checkPermission } from "../../utils/permission";
import { CustomSelect } from "../../components/CustomSelect";
import { If } from "../../components/If";
import Item from "../../components/Item";
const { Col, Row } = Grid;
let PlatformSetting = class extends React.Component {
  constructor(props) {
    super(props);
    this.onGetDexConfig = () => {
      getDexConfig().then((res) => {
        if (res) {
          this.setState({
            dexConfig: res
          });
        }
      });
    };
    this.loadSystemInfo = () => {
      this.props.dispatch({
        type: "user/getSystemInfo",
        callback: (systemInfo) => {
          this.field.setValues({
            loginType: systemInfo.loginType,
            enableCollection: systemInfo.enableCollection
          });
          const items = [];
          systemInfo.dexUserDefaultProjects?.map((item) => {
            items.push({ id: uuid(), ...item });
          });
          this.setState({ defaultProjectItems: items });
        }
      });
    };
    this.onUpdate = () => {
      this.field.validate((errs, values) => {
        if (errs) {
          return;
        }
        const { enableCollection, loginType, velaAddress } = values;
        const projectMaps = {};
        Object.keys(values).map((key) => {
          if (key.endsWith("-name")) {
            const infos = key.split("-");
            if (!projectMaps[infos[0]]) {
              projectMaps[infos[0]] = { name: values[key], roles: [] };
            } else {
              projectMaps[infos[0]].name = values[key];
            }
          }
          if (key.endsWith("-roles")) {
            const infos = key.split("-");
            if (!projectMaps[infos[0]]) {
              projectMaps[infos[0]] = { roles: values[key], name: "" };
            } else {
              projectMaps[infos[0]].roles = values[key];
            }
          }
        });
        const param = {
          enableCollection,
          loginType,
          velaAddress,
          dexUserDefaultProjects: Object.keys(projectMaps).map((key) => {
            return projectMaps[key];
          })
        };
        updateSystemInfo(param).then((res) => {
          if (res) {
            Message.success(i18n.t("Platform settings updated successfully"));
            this.loadSystemInfo();
          }
        }).catch((err) => {
          const businessGuideCode = err?.BusinessCode || 0;
          this.setState(
            {
              businessGuideCode
            },
            () => {
              if (this.state.businessGuideCode === 12011) {
                this.renderDexGuideDialog();
              } else if (this.state.businessGuideCode === 14010) {
                this.renderUserGuideDialog();
              } else {
                Message.error(err?.Message);
              }
            }
          );
        });
      });
    };
    this.renderUserGuideDialog = () => {
      this.dialogGuideUser = Dialog.alert({
        title: i18n.t("The email address of administrator is empty").toString(),
        content: i18n.t("Please set a email address for the administrator, it must same as the SSO account.").toString(),
        footerActions: ["ok"],
        footer: this.getGuideUserButton()
      });
      return this.dialogGuideUser;
    };
    this.renderDexGuideDialog = () => {
      this.dialogGuideDex = Dialog.alert({
        title: i18n.t("No dex connector configurations").toString(),
        content: i18n.t("Before enabling SSO, you must add at least one dex connector configuration.").toString(),
        footerActions: ["ok"],
        footer: this.getGuideDexButton()
      });
      return this.dialogGuideDex;
    };
    this.getGuideUserButton = () => {
      const { userInfo } = this.props;
      const request = { resource: "user:*", action: "list" };
      const project = "";
      if (checkPermission(request, project, userInfo)) {
        return /* @__PURE__ */ React.createElement(Button, { type: "primary", onClick: this.onOKGuide }, /* @__PURE__ */ React.createElement(Translation, null, "OK"));
      } else {
        return null;
      }
    };
    this.getGuideDexButton = () => {
      const { userInfo } = this.props;
      const request = { resource: "configType:*", action: "list" };
      const project = "";
      if (checkPermission(request, project, userInfo)) {
        return /* @__PURE__ */ React.createElement(Button, { type: "primary", onClick: this.onOKGuide }, /* @__PURE__ */ React.createElement(Translation, null, "OK"));
      } else {
        return null;
      }
    };
    this.onOKGuide = () => {
      const { businessGuideCode } = this.state;
      if (businessGuideCode === 12011) {
        this.dialogGuideDex?.hide();
        this.props.dispatch(
          routerRedux.push({
            pathname: "/configs/dex-connector/config"
          })
        );
      } else if (businessGuideCode === 14010) {
        this.dialogGuideUser?.hide();
        this.props.dispatch(
          routerRedux.push({
            pathname: "/users"
          })
        );
      }
    };
    this.generateDexAddress = () => {
      if (!this.state.dexConfig) {
        return;
      }
      const { clientID, redirectURL } = this.state.dexConfig;
      const newRedirectURl = encodeURIComponent(redirectURL);
      return `${this.field.getValue(
        "velaAddress"
      )}/dex/auth?client_id=${clientID}&redirect_uri=${newRedirectURl}&response_type=code&scope=openid+profile+email+offline_access&state=velaux`;
    };
    this.onAddItem = () => {
      this.setState((prevState) => ({
        defaultProjectItems: [...prevState.defaultProjectItems, { id: uuid() }]
      }));
    };
    this.onRemoveItem = (id) => {
      this.setState((prevState) => ({
        defaultProjectItems: prevState.defaultProjectItems.filter((cp) => cp.id != id) || []
      }));
      this.field.remove(`${id}-name`);
      this.field.remove(`${id}-roles`);
    };
    this.getIssuerDefaultValue = () => {
      const domain = `${window.location.protocol}//${window.location.host}`;
      return domain;
    };
    this.generateProjectOptions = () => {
      return this.state.projects?.map((item) => {
        return {
          label: `${item.name}(${item.alias || "-"})`,
          value: item.name
        };
      });
    };
    this.generateProjectRoleOptions = () => {
      return this.state.roles?.map((item) => {
        return {
          label: `${item.name}(${item.alias || "-"})`,
          value: item.name
        };
      });
    };
    this.listProjects = async () => {
      getProjectList({ page: 0, pageSize: 0 }).then((res) => {
        this.setState({
          projects: res.projects || []
        });
      });
    };
    this.loadProjectRoles = async (name) => {
      getProjectRoles({ projectName: name }).then((res) => {
        this.setState({ roles: res?.roles || [] });
      });
    };
    this.field = new Field(this, {
      onChange: (name, value) => {
        if (name.endsWith("-name")) {
          this.loadProjectRoles(value);
        }
      }
    });
    this.state = {
      businessGuideCode: 0,
      defaultProjectItems: []
    };
  }
  componentDidMount() {
    this.loadSystemInfo();
    this.listProjects();
    this.onGetDexConfig();
  }
  render() {
    const { systemInfo } = this.props;
    const { defaultProjectItems } = this.state;
    return /* @__PURE__ */ React.createElement("div", { className: "container" }, /* @__PURE__ */ React.createElement(Card, { locale: locale().Card, contentHeight: "auto", style: { marginBottom: "var(--spacing-4)" } }, /* @__PURE__ */ React.createElement("div", { className: "setting-version" }, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 12 }, /* @__PURE__ */ React.createElement(Item, { label: "Version", value: systemInfo.systemVersion?.velaVersion })), /* @__PURE__ */ React.createElement(Col, { span: 12 }, /* @__PURE__ */ React.createElement(Item, { label: "GitVersion", value: systemInfo.systemVersion?.gitVersion }))))), /* @__PURE__ */ React.createElement(Form, { field: this.field, labelCol: { fixedSpan: 8 }, wrapperCol: { span: 16 } }, /* @__PURE__ */ React.createElement(
      Card,
      {
        style: { marginBottom: "16px" },
        locale: locale().Card,
        contentHeight: "200px",
        title: /* @__PURE__ */ React.createElement(Translation, null, "User authentication configuration")
      },
      /* @__PURE__ */ React.createElement(Row, { wrap: true }, /* @__PURE__ */ React.createElement(Col, { span: 24 }, /* @__PURE__ */ React.createElement(Form.Item, { required: true, label: i18n.t("User login mode").toString() }, /* @__PURE__ */ React.createElement(
        Radio.Group,
        {
          ...this.field.init("loginType", {
            rules: [{ required: true }]
          })
        },
        /* @__PURE__ */ React.createElement(Radio, { value: "local", id: "local" }, /* @__PURE__ */ React.createElement(Translation, null, "Local")),
        /* @__PURE__ */ React.createElement(Radio, { value: "dex", id: "dex" }, /* @__PURE__ */ React.createElement(Translation, null, "SSO by dex"))
      )), /* @__PURE__ */ React.createElement(If, { condition: this.field.getValue("loginType") == "dex" }, /* @__PURE__ */ React.createElement("a", { href: this.generateDexAddress(), target: "_blank", rel: "noopener noreferrer" }, /* @__PURE__ */ React.createElement(Translation, null, "Click me to open the dex login page")))), /* @__PURE__ */ React.createElement(Col, { span: 24 }, /* @__PURE__ */ React.createElement(
        Form.Item,
        {
          required: true,
          label: i18n.t("VelaUX address").toString(),
          help: i18n.t("There will auto get the domain for access the VelaUX").toString()
        },
        /* @__PURE__ */ React.createElement(
          Input,
          {
            ...this.field.init("velaAddress", {
              initValue: this.getIssuerDefaultValue(),
              rules: [{ required: true }]
            })
          }
        )
      )), /* @__PURE__ */ React.createElement(Col, { span: 24 }, /* @__PURE__ */ React.createElement(Form.Item, { label: i18n.t("Setting the default projects for the dex login user").toString() }, /* @__PURE__ */ React.createElement("div", { className: "flexright", style: { marginBottom: "8px" } }, /* @__PURE__ */ React.createElement(Button, { onClick: this.onAddItem }, /* @__PURE__ */ React.createElement(AiOutlinePlus, null))), defaultProjectItems.map((item) => {
        return /* @__PURE__ */ React.createElement(Row, { id: item.id, key: item.id }, /* @__PURE__ */ React.createElement(Col, { span: 8, style: { padding: "0 4px" } }, /* @__PURE__ */ React.createElement(Form.Item, { labelAlign: "inset", required: true, label: i18n.t("Project").toString() }, /* @__PURE__ */ React.createElement(
          Select,
          {
            locale: locale().Select,
            ...this.field.init(`${item.id}-name`, {
              rules: [{ required: true }],
              initValue: item.name
            }),
            dataSource: this.generateProjectOptions()
          }
        ))), /* @__PURE__ */ React.createElement(Col, { span: 12, style: { padding: "0 4px" } }, /* @__PURE__ */ React.createElement(Form.Item, { labelAlign: "inset", required: true, label: i18n.t("Roles").toString() }, /* @__PURE__ */ React.createElement(
          CustomSelect,
          {
            locale: locale().Select,
            ...this.field.init(`${item.id}-roles`, {
              rules: [{ required: true }],
              initValue: item.roles
            }),
            dataSource: this.generateProjectRoleOptions(),
            mode: "multiple"
          }
        ))), /* @__PURE__ */ React.createElement(Col, { span: 4 }, /* @__PURE__ */ React.createElement("div", { className: "flexright" }, /* @__PURE__ */ React.createElement(Button, { onClick: () => this.onRemoveItem(item.id) }, /* @__PURE__ */ React.createElement(AiOutlineDelete, null)))));
      }))))
    ), /* @__PURE__ */ React.createElement(
      Card,
      {
        style: { marginBottom: "16px" },
        locale: locale().Card,
        contentHeight: "200px",
        title: /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement(Translation, null, "User experience improvement plan"), /* @__PURE__ */ React.createElement(
          "a",
          {
            target: "_blank",
            href: "https://kubevela.io/docs/reference/user-improvement-plan",
            rel: "noopener noreferrer"
          },
          /* @__PURE__ */ React.createElement(MdOutlineHelpCenter, { size: 16, style: { marginLeft: "4px" } })
        ))
      },
      /* @__PURE__ */ React.createElement(
        Item,
        {
          label: i18n.t("Contribution"),
          value: /* @__PURE__ */ React.createElement(
            Switch,
            {
              size: "medium",
              ...this.field.init("enableCollection", {
                rules: [{ required: true }],
                initValue: systemInfo.enableCollection
              }),
              checked: this.field.getValue("enableCollection")
            }
          )
        }
      )
    ), /* @__PURE__ */ React.createElement("div", { className: "flexright" }, /* @__PURE__ */ React.createElement(Button, { type: "primary", onClick: this.onUpdate }, /* @__PURE__ */ React.createElement(Translation, null, "Submit")))));
  }
};
PlatformSetting = __decorateClass([
  connect((store) => {
    return { ...store.user };
  })
], PlatformSetting);
export default PlatformSetting;
