"use strict";
import React from "react";
import "./index.less";
import { Grid, Card, Tag, Balloon } from "@alifd/next";
import Empty from "../../../../components/Empty";
import { If } from "../../../../components/If";
import { Translation } from "../../../../components/Translation";
import { intersectionArray } from "../../../../utils/common";
import { locale } from "../../../../utils/locale";
class CardContent extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = (index, e) => {
      e.preventDefault();
      const { extendDotVisible } = this.state;
      this.setState({
        extendDotVisible: !extendDotVisible,
        choseIndex: index
      });
    };
    this.state = {
      extendDotVisible: false,
      choseIndex: 0
    };
  }
  render() {
    const { Row, Col } = Grid;
    const { addonLists, clickAddon, enabledAddons, selectTags } = this.props;
    const getTagColor = (tag) => {
      switch (tag) {
        case "alpha":
          return "red";
        case "beta":
          return "red";
        case "GA":
          return "green";
        default:
          return "";
      }
    };
    const nameUpper = (name) => {
      return name.split("-").map((sep) => {
        if (sep.length > 0) {
          return sep.toUpperCase()[0];
        }
        return sep;
      }).toString().replace(",", "");
    };
    const orderAddonList = [];
    addonLists.map((addon) => {
      const status = enabledAddons?.filter((addonStatus) => {
        return addonStatus.name == addon.name;
      });
      if (selectTags.length > 0 && !intersectionArray(addon.tags, selectTags)?.length) {
        return;
      }
      if (status && status.length > 0 && status[0].phase == "enabled") {
        orderAddonList.unshift(addon);
      } else {
        orderAddonList.push(addon);
      }
    });
    const notice = "This addon is experimental, please don't use it in production";
    return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(If, { condition: addonLists }, /* @__PURE__ */ React.createElement(Row, { wrap: true }, orderAddonList.map((item) => {
      const { name, icon, version, description, tags, registryName } = item;
      const status = enabledAddons?.filter((addonStatus) => {
        return addonStatus.name == name;
      });
      return /* @__PURE__ */ React.createElement(Col, { xl: 4, l: 6, m: 8, s: 12, xxs: 24, className: `card-content-wraper`, key: name }, /* @__PURE__ */ React.createElement(Card, { locale: locale().Card, contentHeight: "auto" }, /* @__PURE__ */ React.createElement("a", { onClick: () => clickAddon(name) }, /* @__PURE__ */ React.createElement("div", { className: "cluster-card-top flexcenter" }, /* @__PURE__ */ React.createElement(If, { condition: icon && icon != "none" }, /* @__PURE__ */ React.createElement("img", { src: icon })), /* @__PURE__ */ React.createElement(If, { condition: !icon || icon === "none" }, /* @__PURE__ */ React.createElement(
        "div",
        {
          style: {
            display: "inline-block",
            verticalAlign: "middle",
            padding: `2px 4px`,
            width: "60px",
            height: "60px",
            borderRadius: "50%",
            backgroundColor: "#fff",
            textAlign: "center",
            lineHeight: "60px"
          }
        },
        /* @__PURE__ */ React.createElement("span", { style: { color: "#1b58f4", fontSize: `2em` } }, nameUpper(name))
      )))), /* @__PURE__ */ React.createElement("div", { className: "content-wraper background-F9F8FF" }, /* @__PURE__ */ React.createElement(Row, { className: "content-title" }, /* @__PURE__ */ React.createElement(Col, { span: "16", className: "font-size-16" }, /* @__PURE__ */ React.createElement("a", { onClick: () => clickAddon(name) }, name)), /* @__PURE__ */ React.createElement(If, { condition: registryName && registryName == "experimental" }, /* @__PURE__ */ React.createElement(Col, { span: "8", className: "flexright" }, /* @__PURE__ */ React.createElement(Balloon, { trigger: /* @__PURE__ */ React.createElement(Tag, { color: "yellow" }, "Experimental") }, notice)))), /* @__PURE__ */ React.createElement(Row, { className: "content-main" }, /* @__PURE__ */ React.createElement("h4", { className: "color595959 font-size-14", title: description }, description)), /* @__PURE__ */ React.createElement(Row, { className: "content-main-btn" }, tags?.map((tag) => {
        return /* @__PURE__ */ React.createElement(Tag, { title: tag, style: { marginRight: "8px" }, color: getTagColor(tag), key: tag }, tag);
      })), /* @__PURE__ */ React.createElement(Row, { className: "content-foot colorA6A6A6" }, /* @__PURE__ */ React.createElement(Col, { span: "16" }, /* @__PURE__ */ React.createElement("span", null, version || "0.0.0")), /* @__PURE__ */ React.createElement(Col, { span: "8", className: "text-align-right padding-right-10" }, /* @__PURE__ */ React.createElement(If, { condition: status && status.length > 0 && status[0].phase == "enabled" }, /* @__PURE__ */ React.createElement("span", { className: "circle circle-success" }), /* @__PURE__ */ React.createElement(Translation, null, "Enabled")))))));
    }))), /* @__PURE__ */ React.createElement(If, { condition: !addonLists || addonLists.length == 0 }, /* @__PURE__ */ React.createElement(Empty, { style: { minHeight: "400px" } })));
  }
}
export default CardContent;
