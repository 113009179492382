"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import React from "react";
import { Input, Form, Loading, Grid, Tag } from "@alifd/next";
import "./index.less";
import { connect } from "dva";
import { Link } from "dva/router";
import { AiOutlineHdd, AiOutlineExport } from "react-icons/ai";
import { TbBrandDocker } from "react-icons/tb";
import { getImageInfo } from "../../api/repository";
import dockerLogo from "../../assets/docker.svg";
import { If } from "../../components/If";
import { beautifyTime, beautifyBinarySize } from "../../utils/common";
import ImageSecretSelect from "../ImageSecretSelect";
const { Col, Row } = Grid;
let ImageInput = class extends React.Component {
  constructor(props) {
    super(props);
    this.componentDidMount = async () => {
    };
    this.loadImageInfo = () => {
      const { project, onChange, onSecretChange } = this.props;
      const { imageName } = this.state;
      if (project && imageName) {
        onChange(imageName);
        this.setState({ loading: true });
        getImageInfo({ project, name: imageName }).then((res) => {
          if (res) {
            this.setState({ imageInfo: res });
            if (res.secretNames) {
              onSecretChange(res.secretNames);
            } else {
              onSecretChange(void 0);
            }
          }
        }).finally(() => {
          this.setState({ loading: false });
        });
      }
    };
    this.state = {
      imageName: "",
      loading: false
    };
  }
  render() {
    const { value, id, required, label, key, onSecretChange, secretValue, disabled, secretID } = this.props;
    const { loading, imageInfo } = this.state;
    if (!this.state.imageName && value) {
      this.setState({ imageName: value }, () => {
        this.loadImageInfo();
      });
    }
    let secrets = secretValue;
    let secretDisabled = disabled;
    if (imageInfo && imageInfo.secretNames) {
      secrets = imageInfo.secretNames;
      secretDisabled = true;
    }
    return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: 16, style: { padding: "0 16px 0 0" } }, /* @__PURE__ */ React.createElement(
      Form.Item,
      {
        required,
        label,
        key,
        help: /* @__PURE__ */ React.createElement("span", null, "To deploy from a private registry, you need to", " ", /* @__PURE__ */ React.createElement(Link, { to: "/configs/config-image-registry/config" }, "create a config configuration"))
      },
      /* @__PURE__ */ React.createElement(
        Input,
        {
          id,
          onChange: (name) => {
            this.setState({ imageName: name });
          },
          disabled,
          defaultValue: value,
          onBlur: this.loadImageInfo
        }
      )
    )), /* @__PURE__ */ React.createElement(Col, { span: 8 }, /* @__PURE__ */ React.createElement(Form.Item, { label: "Secret" }, /* @__PURE__ */ React.createElement(
      ImageSecretSelect,
      {
        id: secretID,
        disabled: secretDisabled,
        onChange: onSecretChange,
        value: secrets
      }
    )))), /* @__PURE__ */ React.createElement(Loading, { visible: loading, style: { width: "100%" } }, /* @__PURE__ */ React.createElement(If, { condition: imageInfo }, /* @__PURE__ */ React.createElement("div", { className: "image-info" }, /* @__PURE__ */ React.createElement(If, { condition: imageInfo?.info }, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { xl: 24, className: "container-base" }, /* @__PURE__ */ React.createElement("img", { className: "docker-logo", src: dockerLogo }), /* @__PURE__ */ React.createElement("div", { className: "docker-base" }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("span", { className: "name" }, imageInfo?.name)), /* @__PURE__ */ React.createElement("div", { className: "desc" }, /* @__PURE__ */ React.createElement("span", { title: imageInfo?.info?.created }, beautifyTime(imageInfo?.info?.created)), /* @__PURE__ */ React.createElement("span", { style: { marginLeft: "8px" } }, beautifyBinarySize(imageInfo?.size || 0)), /* @__PURE__ */ React.createElement(If, { condition: imageInfo?.info?.architecture }, /* @__PURE__ */ React.createElement("span", { style: { marginLeft: "8px" } }, imageInfo?.info?.architecture)))))), /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { className: "image-item", xl: 8 }, /* @__PURE__ */ React.createElement("div", { className: "image-item-icon" }, /* @__PURE__ */ React.createElement(AiOutlineHdd, { title: "Volume" })), /* @__PURE__ */ React.createElement("div", { className: "name" }, imageInfo?.info?.config?.Volumes ? Object.keys(imageInfo?.info?.config?.Volumes).map((path) => {
      return /* @__PURE__ */ React.createElement(Tag, { color: "green" }, path);
    }) : "No default Volume config")), /* @__PURE__ */ React.createElement(Col, { className: "image-item", xl: 8 }, /* @__PURE__ */ React.createElement("div", { className: "image-item-icon" }, /* @__PURE__ */ React.createElement(AiOutlineExport, { title: "ExposedPorts" })), /* @__PURE__ */ React.createElement("div", { className: "name" }, imageInfo?.info?.config?.ExposedPorts ? Object.keys(imageInfo?.info?.config?.ExposedPorts).map((port) => {
      return /* @__PURE__ */ React.createElement(Tag, { color: "blue" }, port);
    }) : "No default Port config")), /* @__PURE__ */ React.createElement(Col, { className: "image-item", xl: 8 }, /* @__PURE__ */ React.createElement("div", { className: "image-item-icon" }, /* @__PURE__ */ React.createElement(TbBrandDocker, null)), /* @__PURE__ */ React.createElement("div", { className: "name" }, /* @__PURE__ */ React.createElement(Tag, { title: imageInfo?.registry }, imageInfo?.registry))))), /* @__PURE__ */ React.createElement(If, { condition: imageInfo?.message }, /* @__PURE__ */ React.createElement("div", { className: "message" }, imageInfo?.message))))));
  }
};
ImageInput = __decorateClass([
  connect((store) => {
    return { ...store.uischema };
  })
], ImageInput);
export default ImageInput;
