"use strict";
import * as yaml from "js-yaml";
import * as React from "react";
import { v4 as uuid } from "uuid";
import { detailResource } from "../../../../api/observation";
import DefinitionCode from "../../../../components/DefinitionCode";
import DrawerWithFooter from "../../../../components/Drawer";
import Empty from "../../../../components/Empty";
import { If } from "../../../../components/If";
function loadResource(resource, setResource) {
  if (resource.name && resource.kind && resource.apiVersion) {
    detailResource({
      name: resource.name,
      namespace: resource.namespace,
      kind: resource.kind,
      apiVersion: resource.apiVersion,
      cluster: resource.cluster || "local"
    }).then((res) => {
      if (res && res.resource) {
        delete res.resource.metadata.managedFields;
        setResource(res.resource);
      } else {
        setResource(null);
      }
    });
  }
}
export function nodeKey(resource, sep = "-") {
  return [resource.cluster || "", resource.kind || "", resource.namespace, resource.name].join(sep);
}
export const ShowResource = (props) => {
  const [resource, setResource] = React.useState();
  React.useEffect(() => {
    loadResource(props.resource, setResource);
  }, [props.resource]);
  const containerId = uuid();
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(
    DrawerWithFooter,
    {
      title: nodeKey(props.resource, "/"),
      placement: "right",
      width: 600,
      onClose: props.onClose
    },
    /* @__PURE__ */ React.createElement(If, { condition: resource }, /* @__PURE__ */ React.createElement("div", { id: containerId, style: { height: "calc(100vh - 100px)" } }, /* @__PURE__ */ React.createElement(
      DefinitionCode,
      {
        id: containerId + "content",
        containerId,
        language: "yaml",
        readOnly: true,
        value: yaml.dump(resource)
      }
    ))),
    /* @__PURE__ */ React.createElement(If, { condition: !resource }, /* @__PURE__ */ React.createElement(Empty, { message: "Loading the resource" }))
  ));
};
