"use strict";
import { Message } from "@alifd/next";
import * as yaml from "js-yaml";
import React, { useEffect, useState } from "react";
import DefinitionCode from "../DefinitionCode";
import { Translation } from "../Translation";
export const WorkflowYAML = (props) => {
  const id = "workflow:" + props.name;
  const [content, setContent] = useState();
  useEffect(() => {
    try {
      const c = yaml.dump(props.steps);
      setContent(c);
    } catch {
    }
  }, [props.steps]);
  return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Message, { type: "help" }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(Translation, null, "The workflow step spec reference document"), ":", /* @__PURE__ */ React.createElement(
    "a",
    {
      href: "http://kubevela.net/docs/end-user/workflow/built-in-workflow-defs",
      target: "_blank",
      rel: "noopener noreferrer",
      style: { marginLeft: "16px" }
    },
    /* @__PURE__ */ React.createElement(Translation, null, "Click here")
  ))), /* @__PURE__ */ React.createElement("div", { id, style: { height: "calc(100vh - 340px)" } }, /* @__PURE__ */ React.createElement(
    DefinitionCode,
    {
      onChange: (value) => {
        try {
          const newSteps = yaml.load(value);
          props.onChange(newSteps);
        } catch (err) {
          console.log(err);
        }
      },
      value: content,
      language: "yaml",
      theme: "hc-black",
      containerId: id
    }
  )));
};
