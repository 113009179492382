"use strict";
import { Button, Checkbox, Dialog, Loading, Message, Tag } from "@alifd/next";
import React, { useState, useEffect } from "react";
import { AiFillDelete, AiFillSetting } from "react-icons/ai";
import { BiCopyAlt } from "react-icons/bi";
import { deletePipelineContext, listPipelineContexts, runPipeline } from "../../api/pipeline";
import i18n from "../../i18n";
import "./index.less";
import { locale } from "../../utils/locale";
import { If } from "../If";
import { ListTitle } from "../ListTitle";
import classNames from "classnames";
import Permission from "../Permission";
import { Translation } from "../Translation";
import NewContext from "./new-context";
const RunPipeline = (props) => {
  const [loading, setLoading] = useState(true);
  const [contexts, setContexts] = useState({});
  const [context, setContext] = useState();
  const [noContext, setNoContext] = useState();
  const [contextName, setSelectContextName] = useState("");
  const [addContext, showAddContext] = useState(false);
  const { pipeline } = props;
  const onClonePipelineContext = (key) => {
    showAddContext(true);
    setContext({ name: key, values: contexts[key], clone: true });
  };
  const onEditPipelineContext = (key) => {
    showAddContext(true);
    setContext({ name: key, values: contexts[key], clone: false });
  };
  const onDeletePipelineContext = (key) => {
    Dialog.confirm({
      type: "confirm",
      content: /* @__PURE__ */ React.createElement(Translation, null, "Unrecoverable after deletion, are you sure to delete it?"),
      onOk: () => {
        deletePipelineContext(pipeline.project.name, pipeline.name, key).then((res) => {
          if (res) {
            Message.success(i18n.t("The Pipeline context removed successfully"));
            setLoading(true);
          }
        });
      },
      locale: locale().Dialog
    });
  };
  useEffect(() => {
    if (loading) {
      listPipelineContexts(pipeline.project.name, pipeline.name).then((res) => {
        setContexts(res && res.contexts ? res.contexts : {});
      }).finally(() => {
        setLoading(false);
      });
    }
  }, [pipeline, loading]);
  const onRunPipeline = () => {
    if (!contextName && !noContext) {
    }
    runPipeline(pipeline.project.name, pipeline.name, contextName).then((res) => {
      if (res) {
        if (props.onSuccess) {
          props.onSuccess(res.pipelineRunName);
        }
      }
    });
  };
  const okButtonDisable = !contextName && !noContext;
  return /* @__PURE__ */ React.createElement(
    Dialog,
    {
      v2: true,
      visible: true,
      locale: locale().Dialog,
      title: i18n.t("Run Pipeline").toString(),
      onClose: props.onClose,
      onCancel: props.onClose,
      onOk: onRunPipeline,
      overflowScroll: true,
      okProps: {
        disabled: okButtonDisable
      }
    },
    /* @__PURE__ */ React.createElement(Loading, { style: { width: "100%" }, visible: loading }, /* @__PURE__ */ React.createElement("div", { className: "context-box" }, /* @__PURE__ */ React.createElement(
      ListTitle,
      {
        title: i18n.t("Select Contexts"),
        subTitle: i18n.t("The context is the runtime inputs for the Pipeline"),
        buttonSize: "small",
        addButtonTitle: "New Context",
        addButtonClick: () => {
          showAddContext(true);
        }
      }
    ), Object.keys(contexts).map((key) => {
      return /* @__PURE__ */ React.createElement(
        "div",
        {
          key,
          className: classNames("context-item", { active: contextName === key }),
          onClick: () => {
            if (contextName != key) {
              setSelectContextName(key);
            } else {
              setSelectContextName("");
            }
          },
          title: contextName === key ? i18n.t("Click and deselect") : i18n.t("Click and select")
        },
        /* @__PURE__ */ React.createElement("div", { className: "context-name" }, key),
        /* @__PURE__ */ React.createElement("div", { className: "context-values" }, Array.isArray(contexts[key]) && contexts[key].map((item) => {
          return /* @__PURE__ */ React.createElement(Tag, { style: { marginBottom: "8px" }, key: item.key }, `${item.key}=${item.value}`);
        })),
        /* @__PURE__ */ React.createElement("div", { className: "actions" }, /* @__PURE__ */ React.createElement(
          Permission,
          {
            request: {
              resource: `project:${pipeline.project.name}/pipeline:${pipeline.name}/context:${key}`,
              action: "update"
            },
            project: pipeline.project.name
          },
          /* @__PURE__ */ React.createElement(
            Button,
            {
              className: "margin-left-10",
              text: true,
              component: "a",
              size: "medium",
              onClick: (event) => {
                onEditPipelineContext(key);
                event.stopPropagation();
              }
            },
            /* @__PURE__ */ React.createElement(AiFillSetting, null),
            /* @__PURE__ */ React.createElement(Translation, null, "Edit")
          ),
          /* @__PURE__ */ React.createElement("span", { className: "line" })
        ), /* @__PURE__ */ React.createElement(
          Permission,
          {
            project: pipeline.project.name,
            resource: {
              resource: `project:${pipeline.project.name}/pipeline:${pipeline.name}/context:${key}`,
              action: "create"
            }
          },
          /* @__PURE__ */ React.createElement(
            Button,
            {
              text: true,
              size: "medium",
              component: "a",
              onClick: (event) => {
                onClonePipelineContext(key);
                event.stopPropagation();
              }
            },
            /* @__PURE__ */ React.createElement(BiCopyAlt, null),
            " ",
            /* @__PURE__ */ React.createElement(Translation, null, "Clone")
          ),
          /* @__PURE__ */ React.createElement("span", { className: "line" })
        ), /* @__PURE__ */ React.createElement(
          Permission,
          {
            project: pipeline.project.name,
            resource: {
              resource: `project:${pipeline.project.name}/pipeline:${pipeline.name}/context:${key}`,
              action: "delete"
            }
          },
          /* @__PURE__ */ React.createElement(
            Button,
            {
              text: true,
              size: "medium",
              className: "danger-btn",
              component: "a",
              onClick: (event) => {
                onDeletePipelineContext(key);
                event.stopPropagation();
              }
            },
            /* @__PURE__ */ React.createElement(AiFillDelete, null),
            /* @__PURE__ */ React.createElement(Translation, null, "Remove")
          )
        ))
      );
    }), /* @__PURE__ */ React.createElement(If, { condition: addContext }, /* @__PURE__ */ React.createElement("div", { className: "context-item" }, /* @__PURE__ */ React.createElement(
      NewContext,
      {
        clone: context?.clone,
        context,
        pipeline: props.pipeline,
        onCancel: () => showAddContext(false),
        onSuccess: () => {
          setLoading(true);
          showAddContext(false);
        }
      }
    ))), /* @__PURE__ */ React.createElement(If, { condition: Object.keys(contexts).length == 0 && !addContext }, /* @__PURE__ */ React.createElement("div", { style: { width: "100%" } }, /* @__PURE__ */ React.createElement(Message, { type: "notice" }, /* @__PURE__ */ React.createElement(Translation, null, "There is no context option.")))), /* @__PURE__ */ React.createElement(If, { condition: contextName == "" }, /* @__PURE__ */ React.createElement("div", { className: "notice" }, /* @__PURE__ */ React.createElement(
      Checkbox,
      {
        onChange: (v) => {
          setNoContext(v);
        },
        checked: noContext,
        style: { marginRight: "8px" }
      }
    ), /* @__PURE__ */ React.createElement(Translation, null, "No context is required for the execution of this Pipeline"))), /* @__PURE__ */ React.createElement(If, { condition: contextName != "" }, /* @__PURE__ */ React.createElement("span", { className: "notice success" }, "Selected a context: ", contextName))))
  );
};
export default RunPipeline;
