"use strict";
import { Icon, Grid } from "@alifd/next";
import { Link } from "dva/router";
import _ from "lodash";
import React, { Component } from "react";
import aliyunImg from "../../../../assets/aliyun.svg";
import awsImg from "../../../../assets/aws.svg";
import azureImg from "../../../../assets/azure.svg";
import defaultConfigSVG from "../../../../assets/config.svg";
import dockerImg from "../../../../assets/docker.svg";
import gitImg from "../../../../assets/git.svg";
import grafanaImg from "../../../../assets/grafana.svg";
import helmImg from "../../../../assets/helm.svg";
import nacosImg from "../../../../assets/nacos.svg";
import prometheusImg from "../../../../assets/prometheus.svg";
import ssoImg from "../../../../assets/sso.svg";
import terraformImg from "../../../../assets/terraform.svg";
import "./index.less";
const imgDate = [
  {
    id: "helm-repository",
    img: helmImg
  },
  {
    id: "image-registry",
    img: dockerImg
  },
  {
    id: "dex-connector",
    img: ssoImg
  },
  {
    id: "git",
    img: gitImg
  },
  {
    id: "alibaba",
    img: aliyunImg
  },
  {
    id: "aws",
    img: awsImg
  },
  {
    id: "azure",
    img: azureImg
  },
  {
    id: "terraform",
    img: terraformImg
  },
  {
    id: "nacos",
    img: nacosImg
  },
  {
    id: "grafana",
    img: grafanaImg
  },
  {
    id: "loki",
    img: grafanaImg
  },
  {
    id: "prometheus",
    img: prometheusImg
  }
];
class Menu extends Component {
  constructor() {
    super(...arguments);
    this.matchMenuData = (name) => {
      return _.find(imgDate, (item) => {
        if (name.indexOf(item.id) != -1) {
          return item;
        } else {
          return null;
        }
      });
    };
    this.transMenuData = () => {
      const { menuData } = this.props;
      return menuData.map((item) => {
        const findMatchData = this.matchMenuData(item.name);
        if (findMatchData) {
          return Object.assign(item, { iconType: "arrow-right", img: findMatchData.img });
        } else {
          return Object.assign(item, { iconType: "arrow-right", img: defaultConfigSVG });
        }
      });
    };
    this.getMenuItem = () => {
      const { Row, Col } = Grid;
      const { activeName } = this.props;
      const transMenuData = this.transMenuData();
      const result = (transMenuData || []).map((item) => {
        const isActive = activeName === item.name ? "active-menu-item" : "";
        return /* @__PURE__ */ React.createElement("li", { key: item.name }, /* @__PURE__ */ React.createElement(Link, { to: `/configs/${item.name}/config` }, /* @__PURE__ */ React.createElement(Row, { className: `menu-item-wrapper ${isActive}` }, /* @__PURE__ */ React.createElement(Col, { span: "5" }, /* @__PURE__ */ React.createElement("div", { className: "menu-item-img-wrapper" }, /* @__PURE__ */ React.createElement("img", { src: item.img, className: "menu-item-img" }))), /* @__PURE__ */ React.createElement(Col, { span: "17" }, /* @__PURE__ */ React.createElement("div", { className: "menu-item-description" }, item.alias || item.name)), /* @__PURE__ */ React.createElement(Col, { span: "2" }, /* @__PURE__ */ React.createElement("div", { className: "menu-item-icon" }, /* @__PURE__ */ React.createElement(Icon, { type: item.iconType }))))));
      });
      return result;
    };
  }
  render() {
    const menuItem = this.getMenuItem();
    return /* @__PURE__ */ React.createElement("div", { className: "menu-content" }, /* @__PURE__ */ React.createElement("ul", null, menuItem));
  }
}
export default Menu;
