"use strict";
import { Icon, Loading, Grid } from "@alifd/next";
import React from "react";
import { AiOutlineDelete } from "react-icons/ai";
import "./index.less";
class ArrayItemGroup extends React.Component {
  constructor(props) {
    super(props);
    this.toggleShowClass = () => {
      const { closed } = this.state;
      this.setState({
        closed: !closed
      });
    };
    this.state = {
      closed: true
    };
  }
  render() {
    const { children, labelTitle, loading } = this.props;
    const { closed } = this.state;
    const { Col, Row } = Grid;
    return /* @__PURE__ */ React.createElement(Loading, { visible: loading || false, style: { width: "100%" } }, /* @__PURE__ */ React.createElement("div", { className: "spection-group-container" }, /* @__PURE__ */ React.createElement("div", { className: "spection-group-title-container" }, /* @__PURE__ */ React.createElement(Row, null, /* @__PURE__ */ React.createElement(Col, { span: "21" }, /* @__PURE__ */ React.createElement("div", null, labelTitle)), /* @__PURE__ */ React.createElement(Col, { span: "3" }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(
      Icon,
      {
        onClick: this.toggleShowClass,
        className: "icon-toggle",
        type: closed ? "arrow-down" : "arrow-up",
        style: closed ? { top: "-2px" } : { top: "0" }
      }
    ), /* @__PURE__ */ React.createElement(
      AiOutlineDelete,
      {
        className: "icon-delete",
        onClick: () => {
          if (this.props.delete) {
            this.props.delete(this.props.id);
          }
        }
      }
    ))))), /* @__PURE__ */ React.createElement("div", { className: `array-item-group-box ${closed ? "disable" : ""}` }, children)));
  }
}
export default ArrayItemGroup;
