"use strict";
import { Drawer, Button } from "@alifd/next";
import React, { Component } from "react";
import "./index.less";
import { If } from "../If";
import { Translation } from "../Translation";
class DrawerWithFooter extends Component {
  render() {
    const { visible, children, title, placement, width, onOk, onClose, extButtons, onOkButtonText, onOkButtonLoading } = this.props;
    return /* @__PURE__ */ React.createElement(
      Drawer,
      {
        title: /* @__PURE__ */ React.createElement(React.Fragment, null, title),
        closeMode: "close",
        className: "customDrawer",
        closeable: "close",
        onClose,
        visible: visible || true,
        autoFocus: true,
        placement: placement || "right",
        width: width || "600px"
      },
      /* @__PURE__ */ React.createElement("div", { style: { paddingBottom: "60px" } }, children, /* @__PURE__ */ React.createElement("div", { className: "drawer-footer" }, extButtons, /* @__PURE__ */ React.createElement(If, { condition: onOk }, /* @__PURE__ */ React.createElement(Button, { loading: onOkButtonLoading, type: "primary", onClick: onOk }, /* @__PURE__ */ React.createElement(Translation, null, onOkButtonText ? onOkButtonText : "Submit")))))
    );
  }
}
export default DrawerWithFooter;
