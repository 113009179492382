"use strict";
var __defProp = Object.defineProperty;
var __getOwnPropDesc = Object.getOwnPropertyDescriptor;
var __decorateClass = (decorators, target, key, kind) => {
  var result = kind > 1 ? void 0 : kind ? __getOwnPropDesc(target, key) : target;
  for (var i = decorators.length - 1, decorator; i >= 0; i--)
    if (decorator = decorators[i])
      result = (kind ? decorator(target, key, result) : decorator(result)) || result;
  if (kind && result)
    __defProp(target, key, result);
  return result;
};
import { connect } from "dva";
import React, { Component } from "react";
import "./index.less";
import { If } from "../../../../components/If";
import Permission from "../../../../components/Permission";
import { Translation } from "../../../../components/Translation";
import { beautifyTime } from "../../../../utils/common";
import AddAndEditEnvBind from "../AddAndEditEnvBind";
import { Link } from "dva/router";
import classNames from "classnames";
import { Balloon } from "@alifd/next";
import { IoMdAdd } from "react-icons/io";
let TabsContent = class extends Component {
  constructor(props) {
    super(props);
    this.handleChange = () => {
    };
    this.loadEnvbinding = async () => {
      const { applicationDetail } = this.props;
      if (applicationDetail) {
        this.props.dispatch({
          type: "application/getApplicationEnvbinding",
          payload: { appName: applicationDetail.name }
        });
      }
    };
    this.loadApplicationWorkflows = async () => {
      const { appName } = this.props;
      this.props.dispatch({
        type: "application/getApplicationWorkflows",
        payload: { appName }
      });
    };
    this.loadApplicationPolicies = async () => {
      const { appName } = this.props;
      this.props.dispatch({
        type: "application/getApplicationPolicies",
        payload: { appName }
      });
    };
    this.state = {
      visibleEnvPlan: false
    };
  }
  render() {
    const { activeKey, applicationDetail, envbinding } = this.props;
    const { visibleEnvPlan } = this.state;
    const projectName = applicationDetail && applicationDetail.project?.name;
    return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", { className: "top-menus" }, /* @__PURE__ */ React.createElement(
      Link,
      {
        className: classNames("top-menu-item", { active: activeKey === "basisConfig" }),
        to: `/applications/${applicationDetail?.name}/config`
      },
      /* @__PURE__ */ React.createElement(Translation, null, "Application Config")
    ), /* @__PURE__ */ React.createElement("div", { className: "env-box" }, /* @__PURE__ */ React.createElement("div", { className: "env-name" }, /* @__PURE__ */ React.createElement(Translation, null, "Environments")), envbinding?.map((item) => {
      return /* @__PURE__ */ React.createElement(
        Balloon,
        {
          key: item.name,
          trigger: /* @__PURE__ */ React.createElement(
            Link,
            {
              key: item.name + "link",
              className: classNames("top-menu-item", "item-env", {
                active: activeKey === item.name
              }),
              to: `/applications/${applicationDetail?.name}/envbinding/${item.name}/workflow`
            },
            /* @__PURE__ */ React.createElement("span", { title: item.description }, item.alias ? item.alias : item.name)
          )
        },
        item.description,
        /* @__PURE__ */ React.createElement("p", null, "Name: ", item.name),
        /* @__PURE__ */ React.createElement("p", null, "Bind Time: ", beautifyTime(item.createTime))
      );
    }), /* @__PURE__ */ React.createElement(If, { condition: !applicationDetail?.readOnly }, /* @__PURE__ */ React.createElement(
      Permission,
      {
        request: {
          resource: `project:${projectName}/application:${applicationDetail?.name}/envBinding:*`,
          action: "create"
        },
        project: projectName
      },
      /* @__PURE__ */ React.createElement(
        "a",
        {
          className: classNames("top-menu-item"),
          style: { width: "50px" },
          onClick: () => {
            this.setState({ visibleEnvPlan: true });
          }
        },
        /* @__PURE__ */ React.createElement(IoMdAdd, null)
      )
    )))), /* @__PURE__ */ React.createElement(If, { condition: visibleEnvPlan }, /* @__PURE__ */ React.createElement(
      AddAndEditEnvBind,
      {
        envbinding,
        onClose: () => {
          this.setState({ visibleEnvPlan: false });
        },
        onOK: () => {
          this.loadEnvbinding();
          this.loadApplicationWorkflows();
          this.loadApplicationPolicies();
          this.setState({ visibleEnvPlan: false });
        }
      }
    )));
  }
};
TabsContent = __decorateClass([
  connect((store) => {
    return { ...store.application };
  })
], TabsContent);
export default TabsContent;
