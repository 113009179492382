"use strict";
import { getLanguage } from "../utils/common";
const localeData = {
  en: {
    Timeline: {
      expand: "Expand",
      fold: "Fold"
    },
    Balloon: {
      close: "Close"
    },
    Card: {
      expand: "Expand",
      fold: "Fold"
    },
    Dialog: {
      close: "Close",
      ok: "OK",
      cancel: "Cancel"
    },
    Drawer: {
      close: "Close"
    },
    Message: {
      closeAriaLabel: "Close"
    },
    Pagination: {
      prev: "Prev",
      next: "Next",
      goTo: "Go To",
      page: "Page",
      go: "Go",
      total: "Page {current} of {total} pages.",
      labelPrev: "Prev page, current page {current}",
      labelNext: "Next page, current page {current}",
      inputAriaLabel: "Please enter the page to jump to",
      selectAriaLabel: "Please select page size",
      pageSize: "Page Size:"
    },
    Input: {
      clear: "Clear"
    },
    List: {
      empty: "No Data"
    },
    Select: {
      selectPlaceholder: "Please select",
      autoCompletePlaceholder: "Please enter",
      notFoundContent: "No Options",
      maxTagPlaceholder: "{selected}/{total} items have been selected.",
      selectAll: "Select All"
    },
    Table: {
      empty: "No Data",
      ok: "OK",
      reset: "Reset",
      asc: "Asc",
      desc: "Desc",
      expanded: "Expanded",
      folded: "Folded",
      filter: "Filter",
      selectAll: "Select All"
    },
    Upload: {
      card: {
        cancel: "Cancel",
        delete: "Delete"
      },
      upload: {
        delete: "Delete"
      }
    },
    Search: {
      buttonText: "Search"
    },
    Tag: {
      delete: "Delete"
    },
    Switch: {
      on: "On",
      off: "Off"
    },
    Tab: {
      closeAriaLabel: "Close"
    }
  },
  zh: {
    Timeline: {
      expand: "\u5C55\u5F00",
      fold: "\u6536\u8D77"
    },
    Balloon: {
      close: "\u5173\u95ED"
    },
    Card: {
      expand: "\u5C55\u5F00",
      fold: "\u6536\u8D77"
    },
    Dialog: {
      close: "\u5173\u95ED",
      ok: "\u786E\u8BA4",
      cancel: "\u53D6\u6D88"
    },
    Drawer: {
      close: "\u5173\u95ED"
    },
    Message: {
      closeAriaLabel: "\u5173\u95ED\u6807\u7B7E"
    },
    Pagination: {
      prev: "\u524D\u4E00\u9875",
      next: "\u4E0B\u4E00\u9875",
      goTo: "\u53BB\u5F80",
      page: "\u5206\u9875",
      go: "\u53BB",
      total: "Page {current} of {total} pages.",
      labelPrev: "\u524D\u4E00\u9875, \u5F53\u524D\u9875 {current}",
      labelNext: "\u4E0B\u4E00\u9875, \u5F53\u524D\u9875 {current}",
      inputAriaLabel: "\u8BF7\u8F93\u5165\u8981\u8DF3\u8F6C\u5230\u7684\u9875\u9762",
      selectAriaLabel: "\u8BF7\u9009\u62E9\u9875\u9762\u5C55\u793A\u7684\u6570\u91CF",
      pageSize: "\u6BCF\u9875\u663E\u793A\u591A\u5C11\u6761:"
    },
    Input: {
      clear: "\u6E05\u7A7A"
    },
    List: {
      empty: "\u6CA1\u6709\u6570\u636E"
    },
    Select: {
      selectPlaceholder: "\u8BF7\u9009\u62E9",
      autoCompletePlaceholder: "\u8BF7\u8F93\u5165",
      notFoundContent: "\u6CA1\u6709\u53EF\u9009\u9879",
      maxTagPlaceholder: "{selected}/{total} \u6761\u76EE\u5DF2\u9009\u62E9.",
      selectAll: "\u5168\u9009"
    },
    Table: {
      empty: "\u6CA1\u6709\u6570\u636E",
      ok: "\u786E\u8BA4",
      reset: "\u91CD\u7F6E",
      asc: "\u751F\u5E8F",
      desc: "\u964D\u5E8F",
      expanded: "\u5C55\u5F00",
      folded: "\u6536\u8D77",
      filter: "\u8FC7\u6EE4",
      selectAll: "\u5168\u9009"
    },
    Upload: {
      card: {
        cancel: "\u53D6\u6D88",
        delete: "\u5220\u9664"
      },
      upload: {
        delete: "\u5220\u9664"
      }
    },
    Search: {
      buttonText: "\u641C\u7D22"
    },
    Tag: {
      delete: "\u5220\u9664"
    },
    Switch: {
      on: "\u6253\u5F00",
      off: "\u5173\u95ED"
    },
    Tab: {
      closeAriaLabel: "\u5173\u95ED"
    }
  }
};
const _SingletonLocal = class {
  constructor() {
  }
  static getInstance() {
    this.instance = this.instance || new _SingletonLocal();
    return this.instance;
  }
  setLocal(value) {
    this.local = value;
  }
  getLocal() {
    return () => {
      const language = getLanguage();
      return this.local[language] ?? this.local.en;
    };
  }
};
let SingletonLocal = _SingletonLocal;
SingletonLocal.instance = null;
SingletonLocal.getInstance().setLocal(localeData);
export const locale = SingletonLocal.getInstance().getLocal();
